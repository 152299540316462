import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setReportDetails
} from "../../../actions/setpersondetailsaction";
import './outpatient-care.css';
import {serverLink, hospitalCode} from "../../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import { Link } from 'react-router-dom';
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";
import {formatDateAndTime} from "../../../resources/constants";

class PhysiotherapyOutpatient extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            isLoading: true,
            doctorID: this.props.doctorData.userID,
            columns: [
                {
                    name: "Patient Name",
                    selector: row => `${row.patientName}`,
                    sortable: true
                },
                {
                    name: "Patient No",
                    selector: row => `${row.patientSerial}`,
                    sortable: true
                },
                {
                    name: "Doctor Name",
                    selector: row => `${row.doctorName}`,
                    sortable: true
                },
                {
                    name: "Doctor ID",
                    selector: row => `${row.doctorID}`,
                    sortable: true
                },
                {
                    name: "Department",
                    selector: row => `${row.appointmentDepartment}`,
                    sortable: true
                },
                {
                    name: "Appointment Date",
                    selector: row => `${formatDateAndTime(row.appointmentDate, "date")}`,
                    sortable: true
                },
                {
                    name: "Appointment Time",
                    selector: row => `${row.appointmentTime}`,
                    sortable: true
                },
                {
                    name: "Checked In On",
                    selector: row => `${row.checkInTime}`,
                    sortable: true
                },
                {
                    name: "Status",
                    selector: row => `${row.appointmentStatus}`,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to={'/doctor/consultations/'+row.id}>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-notes-medical" aria-hidden="true"/>
                            </button>
                        </Link>
                    ),
                }
            ],
            data: []
        };
    }
    componentDidMount() {
        this.getConsultationHistory();
    }

    async getConsultationHistory () {
        await axios.get(`${serverLink}doctor/consultation/physiotherapy/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.length > 0) {
                    let result_set = result.data;
                    result_set.map(val => {
                        this.setState({
                            data: [...this.state.data,
                                {
                                    id: val._id,
                                    patientName: val.patientName,
                                    patientSerial: val.patientSerial,
                                    doctorID: val.doctorID,
                                    doctorName: val.doctorName,
                                    appointmentDate: val.appointmentDate,
                                    appointmentDepartment: val.appointmentDepartment,
                                    appointmentTime: val.appointmentTime,
                                    checkInTime: val.checkInTime,
                                    appointmentStatus: val.appointmentStatus,
                                    rowData: val
                                }
                            ]
                        })
                    })
                }
                this.setState({
                    isLoading: false
                })
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search...'

        };


        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">

                    <div className="topheader">
                        <Navigator title="Outpatient Care" />
                    </div>
                    <hr />
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setReportDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        doctorData: state.personDetails,
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysiotherapyOutpatient);
