import axios from "axios";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { setpersonDetails } from "../../../actions/setpersondetailsaction";
import StateData from "../../../resources/state_and_lga.json";
import { serverLink, hospitalCode } from "../../../resources/url";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import PatientForm from "../patientform/patientform";
import CountryData from "../../../resources/country.json";
import {toast} from "react-toastify";

class UpdatePatient extends Component {
    constructor(props) {
        super(props);

        let state_array = [];
        let lga_array = [];
        StateData.map(state => {
            state_array.push(state.state)

            if(state.state === this.props.patientDetails.state) {
                state.lgas.map(lga => {
                    lga_array.push(lga)
                })
            }
        });

        this.state = {
            serverLink: serverLink+"patient/",
            hospitalCode: hospitalCode,
            imageAvatar: "",
            imagefile: "",
            date: this.props.patientDetails.dateOfBirth,
            isLoading: false,
            htmlelement: `${serverLink}public/uploads/patient_passport/${this.props.patientDetails.passport}`,
            patientEntryID: this.props.patientDetails._id,
            formData: {
                    title: this.props.patientDetails.title,
                    firstName: this.props.patientDetails.firstName,
                    middleName: this.props.patientDetails.middleName,
                    surname: this.props.patientDetails.surname,
                    gender: this.props.patientDetails.gender,
                    phoneNumber: this.props.patientDetails.phoneNumber,
                    altPhoneNumber: this.props.patientDetails.altPhoneNumber,
                    dateOfBirth: this.props.patientDetails.dateOfBirth,
                    bloodGroup: this.props.patientDetails.bloodGroup,
                    emailAddress: this.props.patientDetails.emailAddress,
                    maritalStatus: this.props.patientDetails.maritalStatus,
                    religion: this.props.patientDetails.religion,
                    occupation: this.props.patientDetails.occupation,
                    residentialAddress: this.props.patientDetails.residentialAddress,
                    status: this.props.patientDetails.status,
                    nationality: this.props.patientDetails.nationality,
                    state: this.props.patientDetails.state,
                    lga: this.props.patientDetails.lga,
                    ward: this.props.patientDetails.ward,
                    zipCode: this.props.patientDetails.zipCode,
                    officeAddress: this.props.patientDetails.officeAddress,
                    passport: this.props.patientDetails.passport,
                    nextOfKinName: this.props.patientDetails.nextOfKinName,
                    nextOfKinPhone: this.props.patientDetails.nextOfKinPhone,
                    nextOfKinRelationship: this.props.patientDetails.nextOfKinRelationship,
                    image_name: this.props.patientDetails.passport
            },

            stateList: state_array,
            lgaList: lga_array,

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    stateData = () => {
        StateData.map(state => {
            if (state.state !== 'Non-Nigerian')
                this.state.stateList.push(state.state)
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdatePatient();
    };

    async onUpdatePatient() {
        if(this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Title"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.firstName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient First Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.surname === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient Surname"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.gender === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Gender"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.phoneNumber === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient Phone Number"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.dateOfBirth === "" || this.state.formData.dateOfBirth === null) {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Date of Birth"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.bloodGroup === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Blood Group"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.maritalStatus === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Marital Status"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.religion === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Religion"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.nextOfKinName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient Next of Kin Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.nextOfKinPhone === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient Next of Kin Phone Number"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.nextOfKinRelationship === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Next of Kin Relationship"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.residentialAddress === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Residential Address"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.residentialAddress === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Residential Address"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.state === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient State"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.lga === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient LGA"
            this.handleErrorDialog();
            return false;
        }

        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            id: this.state.patientEntryID
        };

        axios.patch(`${this.state.serverLink}update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('passport', this.state.imagefile);
                        formData.append('entry_id', this.state.patientEntryID)
                        axios.patch(`${this.state.serverLink}uploadPatientPassport`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.state.messageDescription = "Patient record updated successfully";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = response;
                    this.handleErrorDialog();
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = err;
                this.handleErrorDialog();
            });
    }

    handleChange = (date) => {
        if (date !== null) {
            const birthDate = new Date(date);

            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfBirth: `
                    ${birthDate.getFullYear()}-
                    ${birthDate.getMonth() + 1}-
                    ${birthDate.getDate()}
                    `,
                },

                startDate: date,
            });
        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfBirth: date,
                },

                startDate: date,
            });
        }
    };

    onImageChange = (event) => {
        const formData = this.state.formData;
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" ) {
            } else {
                toast.error("Only  .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 1000000) {
                toast.error("max file size is 1mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: file,
                    htmlelement: e.target.result,
                    formData: {
                        ...formData,
                        image_name: file.name,
                    }
                });
            };
        }
    };
    onImageRemove = () => {
        this.setState({
            imagefile: "",
            imageAvatar: "",
            htmlelement: (
                <div className="addpersonpage">
                    <i className="fa fa-user fa-8x" aria-hidden="true"></i>
                </div>
            ),
        });
    };
    onEdit = (e) => {
        const formData = this.state.formData;

        if (e.target.name === 'nationality') {
            this.state.stateList = [];
            this.state.lgaList = [];
            this.state.formData.state = '';
            if (e.target.value === 'Nigeria') {
                this.stateData();
            } else {
                StateData.map(state => {
                    if (state.state === 'Non-Nigerian')
                        this.state.stateList.push(state.state)
                });
            }
        }

        if(e.target.name === 'state') {
            this.state.lgaList = [];
            StateData.map(state => {
                if(state.state === e.target.value) {
                    state.lgas.map(lga => {
                        this.state.lgaList.push(lga)
                    })
                }
            })
        }
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        this.props.patientDetails.isFront === true ?
        window.location.href = '/service-desk/patient/list' : window.location.href = '/patient/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        const today = new Date();
        const dateOfBirth = `${today.getFullYear()}-${(today.getMonth()+1) < 10 ? '0'+(today.getMonth()+1) : (today.getMonth()+1)}-${today.getDate() < 10 ? '0'+today.getDate() : today.getDate()}`;
        return this.state.isLoading ? (
            <div className="addpersonpage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="addpersonpage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Patient Updated"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <PatientForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Patient"
                                date={this.state.date}
                                htmlelement={this.state.htmlelement}
                                handleChange={this.handleChange}
                                onImageRemove={this.onImageRemove}
                                onImageChange={this.onImageChange}
                                stateList={this.state.stateList}
                                lgaList={this.state.lgaList}
                                nationalityList={CountryData}
                                dateOfBirth={dateOfBirth}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        patientDetails: state.updatePatientDetails,
        doctorDetails: state.loginDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnpersonDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePatient);
