import React, { useState, useEffect } from "react";
import "./nursedashboard.css";
import { serverLink, hospitalCode } from "../../../resources/url";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import axios from "axios";
import { setpersonDetails } from "../../../actions/setpersondetailsaction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loginValidation } from "../../../context/loginvalidation";
import MenuIcon from "../../Dashboard/utils/menu-icon";
import {FaCalendarPlus, FaHospitalUser, FaUserNurse} from "react-icons/fa";
import {FaBedPulse} from "react-icons/fa6";
import Chart from "react-google-charts";

const NurseDashboard = ({ permissionData, doctorDetails, setOnUserDetails }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [doctorData, setDoctorData] = useState({});
    const [roomStatus, setRoomStatus] = useState([])
    const [admissionStatus, setAdmissionStatus] = useState([])
    const [appointmentStatus, setAppointmentStatus] = useState([])
    const [nurseGender, setNurseGender] = useState([])
    const [freeRooms, setFreeRooms] = useState(0)
    const [inpatient, setInpatient] = useState(0)
    const [appointments, setAppointment] = useState(0)
    const [nurses, setNurses] = useState(0)
    const [doctorID] = useState(localStorage.getItem('userID'));

    useEffect(() => {

        if (loginValidation(window.location.pathname, permissionData) !== 'permitted') {
            window.location.href = '/';
        }
        const fetchData = async () => {
            try {
                const response = await axios.get(`${serverLink}doctor/profile/${hospitalCode}/${doctorID}`, doctorDetails.token);
                const data = response.data;
                const dashboard_response = await axios.get(`${serverLink}report/nurse/dashboard/${hospitalCode}`, doctorDetails.token);

                const admission = dashboard_response.data.admission;
                const rooms = dashboard_response.data.room;
                const appointment = dashboard_response.data.appointments;
                const nurse = dashboard_response.data.nurse;

                let appointment_status = ['Scheduled', 'Checked In', 'Vital Taken', 'Completed', 'Missed'];
                let admission_status = ['Active', 'Discharged'];
                let room_status = ['Available', 'Occupied', 'Under Maintenance'];
                let nurse_gender = ['Male', 'Female'];

                //Set Count
                setInpatient(admission.filter(item => item.status === 'active').length)
                setAppointment(appointment.filter(item => item.appointmentStatus === 'Scheduled' || item.appointmentStatus === 'Checked In' || item.appointmentStatus === 'Vital Taken').length)
                setFreeRooms(rooms.filter(item => item.status === 'available').length)
                setNurses(nurse.length)

                //Initialize Data
                let appointmentStatusData =  [];
                let admissionStatusData = [];
                let roomStatusData = [];
                let nurseData = [];

                //Appointment trend by status
                appointment_status.map((e, i) => {
                    let count = appointment.filter(item => item.appointmentStatus === e).length
                    appointmentStatusData.push([e, count]);
                })

                //Admission trend by status
                admission_status.map((e, i) => {
                    let count = admission.filter(item => item.status === e.toLowerCase()).length
                    admissionStatusData.push([e, count]);
                })

                //Room trend by status
                room_status.map((e, i) => {
                    let count = rooms.filter(item => item.status === e.toLowerCase()).length
                    roomStatusData.push([e, count]);
                })

                //Nurse trend by gender
                nurse_gender.map((e, i) => {
                    let count = nurse.filter(item => item.gender === e).length
                    nurseData.push([e, count]);
                })

                if (data.length === 0) {
                    window.location.href = '/';
                }

                //Set Chart Data
                setAppointmentStatus(appointmentStatusData)
                setAdmissionStatus(admissionStatusData)
                setRoomStatus(roomStatusData)
                setNurseGender(nurseData)
                setDoctorData(data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const appointmentAnalyticsData = [["Status", "Count"], ...appointmentStatus];
    const appointmentAnalyticsOptions = {
        title: "Appointment Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const admissionAnalyticsData = [["Status", "Count"], ...admissionStatus];
    const admissionAnalyticsOptions = {
        title: "Admission Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const roomAnalyticsData = [["Status", "Count"], ...roomStatus];
    const roomAnalyticsOptions = {
        title: "Room Trend By Status",
        is3D: true,
    };

    const genderAnalyticsData = [["Gender", "Count"], ...nurseGender];
    const genderAnalyticsOptions = {
        title: "Nurse Trend By Gender",
        pieHole: 0.3,
        is3D: false,
    };

    return isLoading ? (
        <div className="dashboardpage">
            <i className="fas fa-spinner fa-pulse fa-2x " />
        </div>
    ) : (
        <div className="dashboardpage">
            <div className="topheader">
                <ul>
                    <li>
                        <i
                            className="fa fa-user-nurse fa-2x"
                            aria-hidden="true"
                        />
                    </li>
                    <li>
                        <span>{doctorData.title} {doctorData.firstName}  {doctorData.middleName}  {doctorData.surname}'s Dashboard</span>
                    </li>
                </ul>
            </div>

            <div className="first_section" style={{marginTop: '-30px'}}>
                <div className="row">
                    <div className="col-md-3">
                            <MenuIcon name="Inpatients" icon={<FaHospitalUser size={40}/>} count={inpatient}/>
                    </div>
                    <div className="col-md-3">
                            <MenuIcon name="Outpatients" icon={<FaCalendarPlus size={40}/>} count={appointments}/>
                    </div>
                    <div className="col-md-3">
                            <MenuIcon name="Nurses" icon={<FaUserNurse size={40}/>} count={nurses}/>
                    </div>
                    <div className="col-md-3">
                            <MenuIcon name="Available Rooms" icon={<FaBedPulse size={40}/>} count={freeRooms}/>
                    </div>

                    {/*Chart Section*/}
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    appointmentStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="250px"
                                        data={appointmentAnalyticsData}
                                        options={appointmentAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    admissionStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="250px"
                                        data={admissionAnalyticsData}
                                        options={admissionAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    roomStatus.length > 0 &&
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="250px"
                                        data={roomAnalyticsData}
                                        options={roomAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    nurseGender.length > 0 &&
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="250px"
                                        data={genderAnalyticsData}
                                        options={genderAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="col-md-12 mt-3 mb-0" style={{borderBottom: '1px solid #CCCCCC', borderTop: '1px solid #CCCCCC', padding: '5px'}}>
                            <h2><b>Menu</b></h2>
                        </div>
                    </div>

                    {permissionData &&
                        permissionData.map((perm, index) => {
                            if (perm.mainMenuName === 'Nurses' && perm.subMenuName !== null) {
                                return (
                                    <div key={index} className="col-sm-4">
                                        {" "}
                                        <Link
                                            to={perm.subMenuLink}
                                            onClick={async () => {
                                                const sendData = { ...doctorData };
                                                setOnUserDetails(sendData);
                                            }}
                                        >
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnUserDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NurseDashboard);
