import React, { Component } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./allergyform.css";


class AllergyForm extends Component {

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="">
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientSerial">Patient ID</label>
                            <input
                                name="patientSerial"
                                type="text"
                                className="form-control"
                                id="patientSerial"
                                disabled
                                value={this.props.patientSerial}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="appointmentName">Patient Name</label>
                            <input
                                name="appointmentName"
                                type="text"
                                className="form-control"
                                id="appointmentName"
                                disabled
                                value={this.props.patientName}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="allergyName">Allergy Name <span className="text-danger">*</span></label>
                            <input
                                name="allergyName"
                                type="text"
                                className="form-control"
                                id="allergyName"
                                value={this.props.data.allergyName}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="allergyMedication">Allergy Medication <span className="text-danger">*</span></label>
                            <input
                                name="allergyMedication"
                                type="text"
                                className="form-control"
                                id="allergyMedication"
                                value={this.props.data.allergyMedication}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="lastAttackDate">Last Attack Date <span className="text-danger">*</span></label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    style={{
                                        padding: "0px 10px",
                                        border: "1px solid rgb(197, 197, 197)",
                                    }}
                                    name="lastAttackDate"
                                    className="  form-control"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    value={this.props.date}
                                    onChange={this.props.handleChange}
                                    autoComplete="off"
                                    format="dd/MM/yyyy"
                                    required
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="durationBeforeSubsiding">Duration Before Subsiding</label>
                            <input
                                name="durationBeforeSubsiding"
                                type="text"
                                className="form-control"
                                id="durationBeforeSubsiding"
                                value={this.props.data.durationBeforeSubsiding}
                                onChange={this.props.onEdit}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="allergyDescription">Allergy Description</label>
                        <textarea
                            name="allergyDescription"
                            className="form-control"
                            id="allergyDescription"
                            defaultValue={this.props.data.allergyDescription}
                            onChange={this.props.onEdit}
                            rows="3"
                        />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="mb-3" >
                        <label htmlFor="imageFiles" className="selectimage">Upload Reaction Image </label>
                        <div className="mb-3">
                            <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                    document.getElementById("photo").click()
                                }}>
                                    <div className="row">
                                        <i className="bi bi-image text-primary fs-3x"/>
                                        <div className="ms-4 col-md-9">
                                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click To Upload Reaction Image.</h3>
                                            <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .pdf, .jpg, .png, .jpeg are allowed"}</span>
                                        </div>
                                        <div className="col-md-2">{this.props.htmlelement !== "" ? <img className="img-thumbnail" width={350} height={350} srcSet={this.props.htmlelement !== "" ? this.props.htmlelement : ""}/> : ""}</div>
                                    </div>
                                </div>
                            </div>
                            <span className="alert-danger"> Max of 1MB file is allowed!</span>
                        </div>
                        <input type="file"  id="photo" name={"photo"}  accept={"image/*, .pdf"} onChange={this.props.onImageChange} hidden />
                    </div>
                </div>

                <button className="btn btn-success btn-block savebtn" id="SubmissionBtn" type="submit">
                    Save
                </button>
            </form>
        );
    }
}

export default AllergyForm;
