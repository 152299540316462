import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    setpersonDetails
} from "../../../../actions/setpersondetailsaction";
import { hospitalCode, serverLink } from "../../../../resources/url";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import "./inventorylist.css";
import {loginValidation} from "../../../../context/loginvalidation";
import axios from "axios";
import DataTable from "../../../Common/data-table/data-table";
import Navigator from "../../../Settings/navigation/navigation";

import {currentDate, formatDateAndTime} from "../../../../resources/constants";

class DrugInventoryList extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
        this.state = {
            serverLink: serverLink+"pharmacy/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            isLoadMoreData: false,

            totalNumOfDrugInventory: null,
            access: window.location.href.split("/")[window.location.href.split("/").length - 1],
            noMoreDataText: "",

            drugInventoryList: [],
            prescribedDrugs: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedDrugInventoryName: "",
            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchPrescribedDrugs();
        this.fetchDrugsInventory();
    }

    fetchPrescribedDrugs = async () => {
        await axios.get(`${this.state.serverLink}prescription/patient/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(data => {
                data.data.map((data, index) => {
                    data.prescriptionDetail.map((item, i) => {
                        this.state.prescribedDrugs.push(item)
                    })
                })
                this.setState({
                    isLoading: false,
                });
            });
    }

    async fetchDrugsInventory() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}drug/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugInventoryList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    drugQuantityUsed = (drugName) => {
        let filter = this.state.prescribedDrugs.filter((item) =>
            item.drugName === drugName && item.status === 'paid'
        );

        let drugQuantityUsed = filter.map((element)=> element.drugQuantity)

        if (drugQuantityUsed.length > 0){
            drugQuantityUsed = drugQuantityUsed.reduce((a, b) => Number(a) + Number(b));
            return drugQuantityUsed !== 0  ? drugQuantityUsed : 0;
        }else {return 0}
    }
    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            return `${user_date.getDate()}/${monthNames[user_date.getMonth()]}/${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    showTable = () => {
        try {
            return this.state.drugInventoryList.map((drugInventory, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{drugInventory.drugName}</td>
                        <td>{drugInventory.manufacturerName}</td>
                        <td>{drugInventory.drugQuantity === "" ? "N/A" : drugInventory.drugQuantity }</td>
                        <td>{drugInventory.drugPrice === "" ? "N/A" : drugInventory.drugPrice.toLocaleString('en-NG')}   </td>
                        <td>{drugInventory.drugPrice*drugInventory.drugQuantity === "" ? "N/A" : (drugInventory.drugPrice*drugInventory.drugQuantity).toLocaleString('en-NG')}</td>
                        <td>{drugInventory.drugStockPrice === "" ? "N/A" : drugInventory.drugStockPrice.toLocaleString('en-NG')}</td>
                        <td>{drugInventory.drugStockPrice*drugInventory.drugQuantity === "" ? "N/A" : (drugInventory.drugStockPrice*drugInventory.drugQuantity).toLocaleString('en-NG')}</td>
                        <td>{drugInventory.drugStockPrice === "" ? "N/A" :(drugInventory.drugPrice - drugInventory.drugStockPrice).toLocaleString('en-NG')}</td>
                        <td>{drugInventory.submittedOn === "" ? "N/A" : this.formatDate(drugInventory.submittedOn )}</td>
                        <td>
                            {this.state.access === 'list' ?
                                <Link to="/pharamcy/inventory/update">
                                    <button
                                        onClick={async () => {
                                            const drugInventoryData = {
                                                ...drugInventory,
                                                drugInventoryId: drugInventory._id,
                                            };
                                            this.props.setOnPatientDetails(drugInventoryData);
                                        }}
                                        type="button"
                                        className="btn btn-success"
                                    >
                                        <i className="fa fa-edit" aria-hidden="true"/>
                                    </button>
                                </Link>
                             : <></> }
                        </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };


    render() {
        const header = ["S/N", "Drug",  "Manufacturer", "Drug Quantity", "Drug Price (₦)", "Total Price (₦)", "Sales Price (₦)", "Total Sales Price (₦)", "Expected Profit", "Date of Entry", "Option"];
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Drug Inventory"/>
                    </div>
                    <hr/>
                </div>
                <ConfirmDialogBox
                    openDailog={this.state.openConfirmDialog}
                    onSetOpenDailog={this.closeConfirmDialog}
                    handleConfirmOkBtn={this.deleteSpecialisation}
                    isLoading={this.state.isDeleting}
                    title="Delete"
                    des={
                        "Are you sure to delete " +
                        this.state.selectedDrugInventoryName +
                        " " +
                        "details"
                    }
                />

                <ErrorDialogBox
                    openDialog={this.state.openErrorDialog}
                    onSetOpenDialog={this.closeErrorDialog}
                    title="Record Not Found"
                    des="Drug Inventory record not found. Please try again!"
                />
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="page-pretitle">
                                    </div>
                                    <h3 className="card-title">Drug Inventory List</h3>
                                </div>
                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">

                                    </div>
                                </div>
                            </div>
                            <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')}
                                       body={this.showTable()} title={`Drug Inventory Report`}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugInventoryList);
