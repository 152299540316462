import React, {Component} from "react";
import "./prescriptionpage.css";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import {connect} from "react-redux";
import 'react-tabs/style/react-tabs.css';
import { serverLink, hospitalCode } from "../../../../resources/url";
import FormPrompt from "../../../DialogBoxes/formprompt";
import ChoosePayment from "../../../Finance/choosepayment/choosepayment";
import Navigator from "../../../Settings/navigation/navigation";
import {showConfirm} from "../../../Common/sweetalert/sweetalert";
import {setpersonDetails} from "../../../../actions/setpersondetailsaction";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import DashboardCard from "../../../Dashboard/utils/dashboardcard";
import PatientChart from "../../../Chart/patientchart";
import PatientBloodPressureChart from "../../../Chart/patientbloodpressurechart";
import {formatDateAndTime} from "../../../../resources/constants";
import PatientVitalList from "../../../Patients/utils/patientvitals";

class PrescriptionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: true,

            IsLoading: true,
            OpenClearPaymentModal: false,
            openClearPaymentModal: false,

            searchText: "",
            isLoadMoreData: false,
            totalNumOfDrugs: null,
            noMoreDataText: "",
            isSearching: false,
            isSearchDataShow: false,
            patientDetails: [],
            drugInventoryList: [],
            patientAllergy: [],
            vitalList: [],
            financeStatus: "pending",

            patientSerial: this.props.prescriptionData.patientSerial,
            patientName: this.props.prescriptionData.patientName,
            prescriptionAmount: this.props.prescriptionData.prescriptionAmount,
            prescriptionDetail: this.props.prescriptionData.prescriptionDataList,
            amountPaid: this.props.prescriptionData.amountPaid,
            appointmentID: this.props.prescriptionData.appointmentID,
            totalChecked: 0,
            sum: 0,

            formData: {
                id: this.props.prescriptionData._id,
                appointmentID: this.props.prescriptionData.appointmentID,
                patientSerial: this.props.prescriptionData.patientSerial,
                patientName: this.props.prescriptionData.patientName,
                prescriptionDetail: this.props.prescriptionData.prescriptionDataList,
                prescriptionItems: this.props.prescriptionData.prescriptionDetail,
                cartItems: [],
                prescriptionAmount: this.props.prescriptionData.prescriptionAmount,
                amountPaid: this.props.prescriptionData.amountPaid,
                totalAmountPaid: 0,
                hospitalCode: hospitalCode,
                paymentDate: "",
                receivedBy: "",
                paymentStatus: "paid",
                updatedOn: "",
                updatedBy: ""
            },

            updateData: {
                id: this.props.prescriptionData._id,
                appointmentID: this.props.prescriptionData.appointmentID,
                patientSerial: this.props.prescriptionData.patientSerial,
                patientName: this.props.prescriptionData.patientName,
                prescriptionDetail: this.props.prescriptionData.prescriptionDataList,
                prescriptionItems: this.props.prescriptionData.prescriptionDetail,
                cartItems: [],
                prescriptionAmount: this.props.prescriptionData.prescriptionAmount,
                paymentStatus: this.props.prescriptionData.paymentStatus,
                updatedBy:  this.props.loginDetails.fullName,
                hospitalCode: hospitalCode,
            },

            prescriptionItems: {
                drugName: "",
                drugQuantity: "",
                drugAmount: "",
                drugDose: "",
                doseFrequency: "",
                prescription: "",
                status: "",
                stockDrugName: "",
                stockDrugQuantity: "",
                stockPrice: "",
            },

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            openActionModal: false,
            isCloseDialogAppear: true,


            openPrescriptionModal: false,
            openAddDrugModal: false,

            prescriptionSubmitting: false,
            prescriptionSuccess: false,
            prescriptionError: false,
            openFinishDialog: false,

            messageTitle: "",
            messageDescription: "",

            visitNotes: [],
            tasks: [],
            vitals: [],
            allergy: [],
            prescription: [],
            visitNoteList: [],
            graph_label: [],
            temp_val: [],
            bp_systolic_val: [],
            bp_diastolic_val: [],
            height_val: [],
            weight_val: [],
        };
    }

    componentDidMount() {
        this.state.formData.updatedBy =  this.props.loginDetails.fullName;
        this.state.formData.receivedBy =  this.props.loginDetails.fullName;
        this.fetchPatient();
        this.fetchDrugsInventory();
        this.fetchPatientAllergy();
        this.getPatientVitals();
        this.getAppontmentData();
    }

    resetData = () => {
        const itemData = this.state.prescriptionItems;
        const updateItemData = this.state.updateData;

        this.setState({
            updateData: {
                ...updateItemData,
                cartItems: [],
            },
            prescriptionItems: {
                ...itemData,
                drugName: "",
                drugQuantity: "",
                drugAmount: "",
                drugDose: "",
                doseFrequency: "",
                prescription: "",
                status: "",
                stockDrugName: "",
                stockDrugQuantity: "",
                stockPrice: "",
            },
        });
    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    async getAppontmentData() {
        //PATIENT ALLERGEN
        await axios.get(`${serverLink}allergy/${hospitalCode}/${this.state.patientSerial}`, this.props.loginDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    allergy: result
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        await axios.get(`${serverLink}pharmacy/prescription/patient/${this.state.patientSerial}/${hospitalCode}`, this.props.loginDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    prescription: result
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR PRESCRIPTION FETCH', error)
            })

        await axios.get(`${serverLink}vital/${hospitalCode}/${this.state.patientSerial}`, this.props.loginDetails.token)
            .then(data => {
                const result = data.data;

                this.setState({
                    vitals: result
                })

                if (result.length > 0) {
                    let graph_label = [];
                    let temp_val = [];
                    let weight_val = [];
                    let height_val = [];
                    let bp_systolic_val = [];
                    let bp_diastolic_val = [];
                    result.map((v, index) => {
                        if (index < 5) {
                            graph_label.push(this.formatDate(v.submittedOn))

                            //TEMPERATURE GRAPH GENERATOR
                            if (v.temperature !== '') {
                                if (v.temperature_unit !== 'C') {
                                    let fToCel = (parseFloat(v.temperature) - 32) * 5 / 9;
                                    temp_val.push(fToCel.toFixed(1));
                                } else {
                                    temp_val.push(parseFloat(v.temperature));
                                }
                            } else {
                                temp_val.push(0);
                            }

                            //HEIGHT GRAPH GENERATOR
                            if (v.height !== '') {
                                let height = parseFloat(v.height)
                                if (v.height_unit !== 'M') {
                                    let CMTom = height * 0.01;
                                    height_val.push(parseFloat(CMTom.toFixed(2)));
                                } else {
                                    height_val.push(parseFloat(height.toFixed(2)));
                                }
                            } else {
                                height_val.push(0);
                            }

                            //WEIGHT GRAPH GENERATOR
                            if (v.weight !== '') {
                                if (v.weight_unit !== 'KG') {
                                    let LBToKG = parseFloat(v.weight) * 0.453592;
                                    weight_val.push(parseFloat(LBToKG.toFixed(1)));
                                } else {
                                    weight_val.push(parseFloat(v.weight));
                                }
                            } else {
                                weight_val.push(0);
                            }

                            //BLOOD PRESSURE GRAPH GENERATOR
                            if (v.bloodPressure !== '') {
                                const bp = v.bloodPressure.split('/');
                                bp_systolic_val.push(parseInt(bp[0]));
                                bp_diastolic_val.push(parseInt(bp[1]));
                            } else {
                                bp_systolic_val.push(0);
                                bp_diastolic_val.push(0);
                            }

                        }
                    })

                    this.setState({
                        graph_label: graph_label.reverse(),
                        temp_val: temp_val.reverse(),
                        height_val: height_val.reverse(),
                        weight_val: weight_val.reverse(),
                        bp_systolic_val: bp_systolic_val.reverse(),
                        bp_diastolic_val: bp_diastolic_val.reverse()
                    })

                }
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        await axios.get(`${serverLink}appointment/consultation/doctorNote/${hospitalCode}/${this.state.patientSerial}`, this.props.loginDetails.token)
            .then(result => {
                const note = result.data;
                this.setState({
                    visitNotes: note,
                    doctorNote: note.filter(item => item.appointmentID === this.state.appointmentID),
                });
            })

    }

    async fetchPatient() {
        await fetch(`${this.state.serverLink}patient/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientDetails: data,
                });
            });
    }

    async fetchDrugsInventory() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}pharmacy/drug/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugInventoryList: data,
                    isLoadMoreData: false,
                });
            });

    }

    async fetchPatientAllergy() {
        await fetch(`${this.state.serverLink}allergy/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientAllergy: data,
                });
            });
    }

    getPatientVitals = async() => {
        axios.get(`${this.state.serverLink}vital/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    vitalList: result.data[result.data.length-1],
                    isLoading: false,
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    async fetchPatientPrescriptions() {
        await fetch(`${this.state.serverLink}pharmacy/prescription/${this.state.updateData.id}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                console.log(data)
                const sendData = {
                    ...data,
                    prescriptionDataList: data.prescriptionDetail.filter(e => e.status === 'unpaid')
                };
                this.props.setOnPatientDetails(sendData);
                // this.setState({
                //     isLoading: false,
                // });
            });
    }


    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            return `${user_date.getDate()}/${monthNames[user_date.getMonth()]}/${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

     calculateAge = (birthday) => {
        let getAge = Date.now() - new Date(birthday).getTime();
        let ageDate = new Date(getAge); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970) ;
    }


    checkAll = (e) => {
         const formData = this.state.formData;
         const checkboxes = document.getElementsByTagName('input');
         if(e.target.checked){
             for (var i=0; i < checkboxes.length; i++) {
                 if (!checkboxes[i].checked){
                     checkboxes[i].checked = true;
                     let selected = checkboxes[i].value;
                     let drug = checkboxes[i].getAttribute('data');
                     this.checkStockMultiple(checkboxes[i]);
                     selected = this.state.totalChecked +=+ selected;
                     formData.prescriptionDetail.filter(item => {
                         if (item.drugName === drug){
                             item.status = 'paid'
                         }

                     })
                     formData.amountPaid = selected
                     this.setState({
                         totalChecked: selected
                     });
                 }
             }
         }else{
             for (var i=0; i < checkboxes.length; i++) {
                 if (checkboxes[i].checked){
                     checkboxes[i].checked = false;
                     let selected = checkboxes[i].value;
                     let drug = checkboxes[i].getAttribute('data');
                     selected = this.state.totalChecked -= selected;
                     formData.prescriptionDetail.filter(item => {
                         if (item.drugName === drug){
                             item.status = 'unpaid'
                         }

                     })
                     formData.amountPaid = selected
                     this.setState({
                         totalChecked: selected
                     });
                 }

             }
         }

    }

    onChecked = (e) => {
        const formData = this.state.formData;
        let selected = e.target.value;
        const inventory = this.state.drugInventoryList;
         if (e.target.name === 'checkAll'){
             this.checkAll(e);
         }
        if (e.target.name === 'checkItem'){
            let drug = e.target.getAttribute('data');
            if (inventory.filter(e => e.drugName === drug).length < 1) {
                e.target.checked = false
                this.state.messageDescription = "The selected drug is not available in the stock.";
                this.handleErrorDialog();
                return false;
            }
            this.checkStock(e);
            if (e.target.checked){
                selected = this.state.totalChecked +=+ selected;
                formData.prescriptionDetail.filter(item => {
                    if (item.drugName === drug){
                        item.status = 'paid'
                    }

                })
                formData.amountPaid = selected

                this.setState({
                    totalChecked: selected
                });
            }else{
                selected = this.state.totalChecked -= selected;
                formData.prescriptionDetail.filter(item => {
                    if (item.drugName === drug){
                      return   item.status = 'unpaid'
                    }
                    return item;
                })
                formData.amountPaid = selected
                this.setState({
                    totalChecked: selected
                });
            }

        }
    };

     checkStock = (e) => {
         const inventory = this.state.drugInventoryList;
         let drug = e.target.getAttribute('data');
         let drugQuantity = e.target.getAttribute('quantity');
         let inventoryQuantity =  inventory.filter(e => e.drugName === drug)[0].drugQuantity
         if (parseInt(inventoryQuantity) < parseInt(drugQuantity)){
             e.target.checked = false
             this.state.messageDescription = "The selected drug is out of stock.";
             this.handleErrorDialog();
             return false;
         }
     }

     checkStockMultiple = (e) => {
         const inventory = this.state.drugInventoryList;
         let drug = e.getAttribute('data');
         let drugQuantity = e.getAttribute('quantity');

         let inventoryQuantity =  inventory.filter(item => item.drugName === drug)[0].drugQuantity
         if (parseInt(inventoryQuantity) < parseInt(drugQuantity)){
             e.checked = false
             this.state.messageDescription = "The selected drug is out of stock.";
             this.handleErrorDialog();
             return false;
         }
     }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state.formData)
        this.onOpenClearPaymentModal();
    };

    async onUpdatePayment() {
        const sendData = this.state.formData;
        const prescriptionItems = this.getDifference(sendData.prescriptionItems, sendData.prescriptionDetail);
        prescriptionItems.map(e => { sendData.cartItems.push(e) })
        sendData.prescriptionDetail.map(e => { sendData.cartItems.push(e) })

        this.setState({
            ...sendData,
            totalAmountPaid: this.state.totalChecked,
            amountPaid: this.state.totalChecked
        })

        axios.patch(`${this.state.serverLink}pharmacy/prescription/payment/update`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "PAYMENT SUCCESSFUL";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your payment.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }

    onCloseModal = () => {
        this.setState({
            IsLoading: true,
            OpenClearPaymentModal: false,
        })
         if (this.state.financeStatus === "paid"){
             this.onUpdatePayment();
         }
    }

     moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    handleSuccessDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openAlertDialog: true,
        });
    };

     onOpenClearPaymentModal = () => {
         if(this.state.totalChecked <= 0) {
             this.setState({
                 isLoading: false,
             });
             this.state.messageDescription = "Please check at least one item"
             this.handleErrorDialog();
             return false;
         }

         this.setState({
             OpenClearPaymentModal: true
         })

     }

     getDifference = (items1, items2) => {
         return items1.filter(object1 => {
             return !items2.some(object2 => {
                 return object1.drugName === object2.drugName;
             })
         })
     }

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog = async () => {
       await this.fetchPatientPrescriptions();
        this.setState({
            openAlertDialog: false,
        });
        window.location.reload();
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
            onOpenCancelModal: false,
            onOpenCancelPrescriptionModal: false
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    closeActionDialog = () => {
        this.setState({
            openActionModal: false,
        });
    };

    closeAddDrugDialog = () => {
        this.setState({
            openAddDrugModal: false,
            openActionModal: true
        });
    };

    handleDrugQuantityChange = (e) => {
        this.setState({
            drugDose: parseInt(e.target.value)
        })
    }

    handleItemClicked = (e) => {
        const itemData = this.state.prescriptionItems;
        let drugStock = this.state.drugInventoryList;
        let filteredDrug = drugStock.filter((item, index)=> item.drugName === e.drugName)
        let id = filteredDrug.length > 0 ?  filteredDrug[0]._id : "";
        let name = filteredDrug.length > 0 ?  filteredDrug[0].drugName : "";
        let qty =  filteredDrug.length > 0 ?  filteredDrug[0].drugQuantity : "";
        let prc =  filteredDrug.length > 0 ?  filteredDrug[0].drugPrice : 0;
        let amount = parseInt(e.drugQuantity) * parseInt(e.drugPrice);

        this.setState({
            prescriptionItems: {
                ...itemData,
                drugName: e.drugName,
                drugQuantity: e.drugQuantity,
                drugAmount: e.drugAmount,
                drugDose: parseInt(e.drugDose),
                doseFrequency: e.doseFrequency,
                prescription: e.prescription,
                status: e.status,
                stockDrugName: name,
                stockDrugQuantity: qty,
                stockPrice: prc,
            }

        });
    };

    handleItemChanged = (e) => {
        const itemData = this.state.prescriptionItems;
        let drugStock = this.state.drugInventoryList;
        let filteredDrug = drugStock.filter((item, index)=> item.drugName === itemData.drugName)

        if (e.target.name === "drugQuantity"){
            if (e.target.value !== ""){
                if (parseInt(e.target.value) > parseInt(filteredDrug[0].drugQuantity)){
                    this.state.messageDescription = "The selected drug is out of stock.";
                    this.handleErrorDialog();
                    return false;
                }
            }
        }
        this.setState({
            prescriptionItems: {
                ...itemData,
                [e.target.name]: e.target.value,
            },
        });
    }

     onModifyPrescriptionItems = async() => {
        const itemData = this.state.prescriptionItems;
        const sendData = this.state.updateData;
        let filteredItems = sendData.prescriptionDetail.filter(e => e.drugName !== itemData.drugName);
        let paidItems = sendData.prescriptionItems.filter(e => e.status !== "unpaid");
        let prescriptionAmount = 0;

         if (itemData.drugQuantity.toString().trim() === ""){
                 this.state.messageDescription = "Please enter drug quantity";
                 this.handleErrorDialog();
                 return false;
         }

         if (itemData.drugDose.toString().trim() === ""){
                 this.state.messageDescription = "Please enter drug dose";
                 this.handleErrorDialog();
                 return false;
         }

         if (itemData.doseFrequency.toString().trim() === ""){
                 this.state.messageDescription = "Please enter drug frequency";
                 this.handleErrorDialog();
                 return false;
         }

         this.setState({
             updateData: {
                 ...sendData,
                 cartItems: [],
             },
             isLoading: true,
         });

        filteredItems.push({
            drugName: itemData.drugName,
            drugQuantity: parseInt(itemData.drugQuantity),
            drugAmount: itemData.drugAmount,
            drugDose: itemData.drugDose,
            doseFrequency: itemData.doseFrequency,
            prescription: itemData.prescription,
            status: itemData.status,
        })
         filteredItems.map(e => { sendData.cartItems.push(e) })
         paidItems.map(e => { sendData.cartItems.push(e) })
         sendData.cartItems.filter(e=> e.status !== "canceled").map((item, index) => {
             prescriptionAmount +=+ parseFloat(item.drugAmount) * parseInt(item.drugQuantity);
         })

        let fd = {  ...sendData, prescriptionAmount: prescriptionAmount,}

        axios.patch(`${this.state.serverLink}pharmacy/prescription/items/update`, fd, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "PRESCRIPTION UPDATED SUCCESSFUL";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating prescription.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }

    onCancelPrescriptionItems = async() => {
        const itemData = this.state.prescriptionItems;
        const sendData = this.state.updateData;
        let filteredItems = sendData.prescriptionDetail.filter(e => e.drugName !== itemData.drugName);
        let paidItems = sendData.prescriptionItems.filter(e => e.status !== "unpaid");
        let prescriptionAmount = 0;

        this.setState({
            updateData: {
                ...sendData,
                cartItems: [],
            },
            isLoading: true,
        });

        filteredItems.push({
            drugName: itemData.drugName,
            drugQuantity: parseInt(itemData.drugQuantity),
            drugAmount: itemData.drugAmount,
            drugDose: itemData.drugDose,
            doseFrequency: itemData.doseFrequency,
            prescription: itemData.prescription,
            status: "canceled",
        })
        filteredItems.map(e => { sendData.cartItems.push(e) })
        paidItems.map(e => { sendData.cartItems.push(e) })
        sendData.cartItems.filter(e=>e.status !== "canceled").map((item, index) => {
            prescriptionAmount +=+ parseFloat(item.drugAmount) * parseInt(item.drugQuantity);
        })

        let fd = {  ...sendData, prescriptionAmount: prescriptionAmount, paymentStatus: prescriptionAmount === 0 ? "canceled" : "unpaid"}

        showConfirm(
            "CONFIRM",
            `Are you sure you want to cancel this prescription?`,
            "warning"
        ).then( async (IsConfirmed) => {
            if (IsConfirmed)  {
               await axios.patch(`${this.state.serverLink}pharmacy/prescription/items/update`, fd, this.props.loginDetails.token)
                    .then(result => {
                        this.setState({
                            isLoading: false,
                        });
                        const response = result.data.message;
                        if (response === "success"){
                            this.state.messageDescription = "PRESCRIPTION CANCELED SUCCESSFULLY";
                            this.handleSuccessDialog();
                        } else {
                            this.state.messageDescription = "Something went wrong canceling prescription.";
                            this.handleErrorDialog();
                        }
                    })
                    .catch(err => {
                        this.setState({
                            isLoading: false,
                        });
                        this.state.messageDescription = "Request not submitted. Check your console for more info"
                        this.handleErrorDialog();
                        console.error('ERROR', err);
                    });
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        });
    }



    render() {
        let count = 0;
        const prescribedData = this.state.prescriptionDetail;
        const PatientWalletAmount = this.state.patientDetails.walletAmount;
        const itemAmount = this.state.totalChecked;
        const prescriptionID = this.props.prescriptionData._id;

        return this.state.isLoading ? (
            <div className="prescriptionpage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="prescriptionpage">
                <div className="main_section">

                    <FormPrompt
                        style={{ position: 'relative', zIndex: '3' }}
                        openDialog={this.state.openActionModal}
                        title="Action Dialog"
                        onSetOpenDialog={this.closeActionDialog}
                        isCloseBtnAppear={true}
                    >
                      <div className="col-md-12">
                          <button className="btn btn-primary btn-sm form-control text-dark mb-3" onClick={()=>{this.setState({openAddDrugModal: true})}}>Edit Prescription</button>
                          <button className="btn btn-danger btn-sm form-control text-dark mb-3" onClick={()=>{this.onCancelPrescriptionItems();}}>Cancel Prescription</button>
                      </div>
                    </FormPrompt>

                    <FormPrompt
                        style={{ position: 'relative', zIndex: '3' }}
                        openDialog={this.state.openAddDrugModal}
                        title="Prescribe Drug Form"
                        onSetOpenDialog={this.closeAddDrugDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <form id="PrescriptionForm">
                            {
                                this.state.prescriptionSuccess === true ?
                                    <p className="alert alert-success">Drug Prescribed Successfully</p> : ''
                            }
                            {
                                this.state.prescriptionError === true ?
                                    <p className="alert alert-danger">Drug Not Prescribed. Please Try Again!</p> : ''
                            }
                            {
                                this.state.prescriptionSubmitting === true ?
                                    <p className="alert alert-info">Processing. Please wait!</p> : ''
                            }
                            <div className="form-row">
                                <label htmlFor="drugName">Drug Name</label>
                                <input type="text" className="form-control" readOnly={true} value={this.state.prescriptionItems.stockDrugName}/>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label htmlFor="drugPrice">Drug Price</label>
                                    <input type="number" className="form-control" readOnly={true} value={this.state.prescriptionItems.stockPrice}/>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="drugQuantity">Drug Quantity</label>
                                    <input type="number" className="form-control" readOnly={true} value={this.state.prescriptionItems.stockDrugQuantity}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="drugQuantity">Prescription Quantity</label>
                                    <input type="number" required id="drugQuantity" name="drugQuantity"  value={this.state.prescriptionItems.drugQuantity} onChange={this.handleItemChanged} className="form-control" min={1} max={this.state.prescriptionItems.stockDrugQuantity} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label htmlFor="drugDose">Dose</label>
                                    <select required name="drugDose" id="drugDose"  onChange={this.handleItemChanged}  value={parseInt(this.state.prescriptionItems.drugDose)} className="custom-select">
                                        <option value="">Select Option</option>
                                        <option value="1/2">1/2</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>

                                <div className="col-6">
                                    <label htmlFor="doseFrequency">Frequency</label>
                                    <select required name="doseFrequency" id="doseFrequency"  onChange={this.handleItemChanged}  value={this.state.prescriptionItems.doseFrequency} className="custom-select">
                                        <option value="">Select Option</option>
                                        <option value="Every 4 Hours">Every 4 Hours</option>
                                        <option value="Every 6 Hours">Every 6 Hours</option>
                                        <option value="Every 8 Hours">Every 8 Hours</option>
                                        <option value="Every 12 Hours">Every 12 Hours</option>
                                        <option value="Once">Once</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Annually">Annually</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="prescription">Prescription Details</label>
                                    <input type="text" id="prescription" placeholder="1 tablet a day, duration, etc."  onChange={this.handleItemChanged}  name="prescription" className="form-control" defaultValue={this.state.prescriptionItems.prescription}/>
                                </div>
                            </div>
                            <hr/>

                            <button type="button" onClick={this.onModifyPrescriptionItems} className="btn btn-primary btn-block">Save</button>
                        </form>

                    </FormPrompt>

                    <FormPrompt
                        style={{ position: 'relative', zIndex: '3' }}
                        openDialog={this.state.OpenClearPaymentModal}
                        title="Clear Payment"
                        onSetOpenDialog={this.onCloseModal}
                        isCloseBtnAppear={true}
                    >
                        <ChoosePayment
                            patientID={this.state.patientSerial}
                            patientName={this.state.patientName}
                            walletAmount={PatientWalletAmount}
                            MoneyConverter={this.moneyFormat}
                            paymentFor="Pharmacy"
                            itemID={prescriptionID}
                            itemName="Prescription"
                            itemAmount={itemAmount}
                            changeStatus={()=>this.setState({financeStatus: "paid"})}
                            handlePaymentSuccess={this.onCloseModal}
                        />

                    </FormPrompt>

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Payment Status"
                        des={this.state.messageDescription}
                    />

                    <div className="topheader">
                        <Navigator title="Patient Prescription" />
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h4>Patient Details</h4>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <table className="table table-bordered table-responsive-md">
                                                <tbody>
                                                <tr>
                                                    <th>Patient ID</th>
                                                    <td>{this.state.patientDetails.patientSerial  ? this.state.patientDetails.patientSerial : 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Patient Name</th>
                                                    <td>
                                                        {
                                                            this.state.patientDetails.patientSerial ?
                                                                `${this.state.patientDetails.firstName} ${this.state.patientDetails.middleName} ${this.state.patientDetails.surname}`
                                                                : 'N/A'
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6">
                                            <table className="table table-bordered table-responsive-md">
                                                <tbody>
                                                <tr>
                                                    <th>Sex</th>
                                                    <td>{this.state.patientDetails.gender ? this.state.patientDetails.gender : 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Age</th>
                                                    <td>{this.state.patientDetails.dateOfBirth ? this.formatDate(this.state.patientDetails.dateOfBirth) +  `  (${this.calculateAge(this.state.patientDetails.dateOfBirth)}  ${this.calculateAge(this.state.patientDetails.dateOfBirth) > 1 ?  "Years Old" : "Year Old"})` : 'N/A'}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="">
                        <Tabs>
                            <TabList>
                                <Tab>Pending Prescription</Tab>
                                <Tab>Doctor Note</Tab>
                                <Tab>Allergens</Tab>
                                <Tab>Vitals Report</Tab>
                                <Tab>Prescription History</Tab>
                            </TabList>

                            {/*Active Prescription*/}
                            <TabPanel>
                                <table className="table table-striped">
                                    <thead className="thead tablehead">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Drug Name</th>
                                        <th scope="col">Drug Dosage</th>
                                        <th scope="col">Dose Frequency</th>
                                        <th scope="col">Prescription</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Unit Price</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">
                                            Check
                                            {/*<input*/}
                                            {/*    name="checkAll"*/}
                                            {/*    type="checkbox"*/}
                                            {/*    className="form-control"*/}
                                            {/*    id="checkAll"*/}
                                            {/*    value={0}*/}
                                            {/*    onChange={this.onChecked}*/}
                                            {/*/>*/}
                                        </th>
                                    </tr>
                                    </thead>
                                    {this.state.isSearching ? (
                                        <i className="fas fa-spinner fa-pulse fa-2x "/>
                                    ) : prescribedData.length === 0 ? (
                                        <tbody>
                                        <tr>
                                            <td>No Prescription Found</td>
                                        </tr>
                                        </tbody>
                                    ) : (
                                        <tbody className="tablebody">
                                        {prescribedData &&
                                        prescribedData.map((p, i) => {
                                            count++;
                                            let totalAmount = p.drugQuantity * p.drugAmount;
                                            let status = p.status === 'paid' ? 'text-success align-middle' : 'text-danger align-middle';
                                            return (

                                                <tr key={i}>
                                                    <td className="align-middle">{count}</td>
                                                    <td className="align-middle"  style={{width: '300px'}}>{p.drugName}</td>
                                                    <td className="align-middle"> {p.drugDose}</td>
                                                    <td className="align-middle" style={{width: '150px'}}> {p.doseFrequency}</td>
                                                    <td className="align-middle" style={{width: '200px'}}> {p.prescription} </td>
                                                    <td className="align-middle"> {p.drugQuantity}</td>
                                                    <td className="align-middle">{p.drugAmount.toLocaleString('en-NG')}</td>
                                                    <td className="align-middle">{totalAmount.toLocaleString('en-NG')}</td>
                                                    <td className="align-middle"><button className="btn btn-primary btn-sm" onClick={() => {this.resetData(); this.handleItemClicked(p); this.setState({ openActionModal: true });}}> <i className="fa fa-edit"/></button></td>
                                                    <td className="align-middle" style={{width: '50px'}}>
                                                        <input
                                                            name="checkItem"
                                                            type="checkbox"
                                                            className={`form-control`}
                                                            id="checkItem"
                                                            value={totalAmount}
                                                            data={p.drugName}
                                                            quantity={p.drugQuantity}
                                                            onChange={this.onChecked}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    )}
                                </table>
                                <div className="col-sm-6 offset-sm-6">
                                    <div className="row" style={{justifyContent: 'space-around'}}>
                                        <div className=" col-md-5 text-center text-uppercase " style={{backgroundColor: '#eeeeee', borderLeft: '3px solid black'}}>
                                            <span  className="text-success" style={{fontSize: '20px', fontWeight: 'bold'}}>Amount to pay</span><br/>
                                            <span style={{fontSize: '18px', fontWeight: 'bold'}}>{this.currencyConverter(this.state.totalChecked)}</span>
                                        </div>
                                        <div className="col-md-5 text-center text-uppercase" style={{backgroundColor: '#eeeeee', borderLeft: '3px solid black'}}>
                                            <span className="text-danger" style={{fontSize: '20px', fontWeight: 'bold'}}>Total</span><br/>
                                            <span style={{fontSize: '18px', fontWeight: 'bold'}}>{this.currencyConverter(this.state.prescriptionAmount)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-sm-4 offset-sm-4 mt-4">
                                    <form onSubmit={this.handleSubmit}>
                                        <button className="btn btn-block savebtn btnPay" type="submit">PAY NOW</button>
                                    </form>
                                </div>
                            </TabPanel>


                            {/*Visit Note*/}
                            <TabPanel>
                                <div>
                                    {
                                        this.state.visitNotes.length > 0 ?

                                            <Accordion>
                                                {
                                                    this.state.visitNotes.map((p, index) => {
                                                        return <AccordionItem key={index}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Doctor: {p.doctorName} => {formatDateAndTime(p.submittedOn, 'date')}
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <h5>
                                                                    Doctor: {p.doctorName}
                                                                    {/*<Link to={`#`}>*/}
                                                                    {/*    <span className="float-right">view</span>*/}
                                                                    {/*</Link>*/}
                                                                </h5>
                                                                <h4>NOTE</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Subjective</th>
                                                                            <th>Objective</th>
                                                                            <th>Assessment</th>
                                                                            <th>Plan</th>
                                                                            <th>Other 1</th>
                                                                            <th>Other 2</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note1}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note2}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note3}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note4}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note5}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note6}} /></td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    })
                                                }

                                            </Accordion>
                                            :
                                            <div className="p-3">
                                                <span className="alert alert-info">No Previous Note Found</span>
                                            </div>
                                    }
                                </div>

                            </TabPanel>

                            {/*Allergens*/}
                            <TabPanel>
                                {
                                    this.state.allergy.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Name</th>
                                                <th>Last Attack Date</th>
                                                <th>Medication</th>
                                                <th>Duration Before Subsiding</th>
                                                <th>Description</th>
                                                <th>Image</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.allergy.map((allergy, index) => {
                                                    return <tr key={allergy._id}>
                                                        <td>{index+1}</td>
                                                        <td>{allergy.allergyName}</td>
                                                        <td>{this.formatDate(allergy.lastAttackDate)}</td>
                                                        <td>{allergy.allergyMedication}</td>
                                                        <td>{allergy.durationBeforeSubsiding}</td>
                                                        <td>{allergy.allergyDescription}</td>
                                                        <td>
                                                            {allergy.reactionImage === "" ? (
                                                                <i className="fa fa-file-excel fa-2x"></i>
                                                            ) : (
                                                                <img
                                                                    className="image img-fluid"
                                                                    width="50px"
                                                                    alt=""
                                                                    srcSet={!allergy.reactionImage || allergy.reactionImage?.includes('simplefileupload') ? allergy.reactionImage : serverLink + 'public/uploads/patient_allergy/'+allergy.reactionImage}
                                                                    // onClick={this.handleViewAllergyImageClicked}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Record Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Vitals Report*/}
                            <TabPanel>
                                <PatientVitalList vitalList={this.state.vitals.sort((a, b) => new Date(b.submittedOn) - new Date(a.submittedOn))} formatDate={this.formatDate}/>
                            </TabPanel>

                            {/*Prescription*/}
                            <TabPanel>
                                {
                                    this.state.prescription.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.prescription.map((p, index) => {
                                                    let prescriptionTotal = 0;
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                Doctor: {p.doctorName} ~ Date: {formatDateAndTime(p.submittedDate, "date")}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>S/N</th>
                                                                    <th>Drug Name</th>
                                                                    <th>Quantity</th>
                                                                    <th>Dose</th>
                                                                    <th>Prescription</th>
                                                                    <th>Status</th>
                                                                    <th>Unit Price</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    p.prescriptionDetail.map((d, index) => {
                                                                        let sumTotal = 0;
                                                                        if (d.status !== "canceled"){
                                                                            sumTotal += d.drugAmount*d.drugQuantity;
                                                                            prescriptionTotal += d.drugAmount*d.drugQuantity;
                                                                        }

                                                                        return <tr key={index}>
                                                                            <td>{index+1}</td>
                                                                            <td>{d.drugName}</td>
                                                                            <td>{d.drugQuantity}</td>
                                                                            <td>{d.drugDose} / {d.doseFrequency}</td>
                                                                            <td>{d.prescription}</td>
                                                                            <td>{d.status}</td>
                                                                            <td>{this.moneyFormat(d.drugAmount)}</td>
                                                                            <td>{this.moneyFormat(sumTotal)}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                                <tfoot>
                                                                <tr>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th>{this.moneyFormat(prescriptionTotal)}</th>
                                                                </tr>
                                                                </tfoot>
                                                            </table>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>

                                                    prescriptionTotal = 0;
                                                })

                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Prescription Found</span>
                                        </div>
                                }
                            </TabPanel>
                        </Tabs>
                    </div>
                    <hr/>
                    </div>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        prescriptionData: state.personDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionDetails);