import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./addappointment.css";
import AppointmentForm from "../appointmentform/appointmentform";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";

class AddAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: 'Save',
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            date: null,
            isLoading: false,
            patientSerial: this.props.patientData.patientSerial,
            patientFullName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                this.props.patientData.surname,
            walletAmount: 0,
            patientPackage: [],

            formData: {
                patientSerial: this.props.patientData.patientSerial,
                patientName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                    this.props.patientData.surname,
                deductWalletFromPatient: this.props.patientData.patientSerial,
                appointmentDetail: "",
                appointmentDay: "",
                appointmentDate: "",
                appointmentTime: "",
                doctorID: "",
                doctorName: "",
                appointmentStatus: "",
                appointmentDepartment: "",
                appointmentFee: "",
                hospitalCode: hospitalCode,
                isNotifiedPatient: false,
                notifiedOn: "",
                submittedBy: props.doctorDetails.userID,
                consultationAmount: 0,
                serviceItemName: "",
                serviceID: ""
            },
            departmentList: "",
            doctorList: [{value: "", label: "Select Specialisation First"}],
            serviceList: "",
            serviceData: "",
            serviceDataSelect: [{value: "", label: "Select Consultation Type"}],
            doctorSlots: "",
            errorMessage: "",
            paymentAmount: "",
            paymentMethod: "",
            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,

            selectedItemID: "",
            selectedItemName: "",
            isPaymentSuccessful: false
        };
    }

    componentDidMount() {
        this.onGetDepartments();
        this.getPatientPackage();
        this.getPatientCurrentWallet();
        this.getServiceList();
        this.getServiceData();
    }

    //============ GET PATIENT PACKAGES STARTS ====================
    async getPatientPackage () {
        await axios.get(`${serverLink}hospital_package/patient_active_package/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0)
                    this.setState({
                        patientPackage: data
                    })
            })
            .catch(error => {
                this.setState({
                    messageType: 'error',
                    errorMessage: 'Error connecting to server to fetch package details'
                });
            })
    }
    //============ GET PATIENT PACKAGES END ====================


    //============ GET PATIENT SERVICE LIST STARTS ====================
    async getServiceList () {
        await axios.get(`${serverLink}service/all/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                const result_set = data.filter(item => item.serviceName.includes('Consultation'));
                if (data.length > 0) {
                    let spec = [];
                    result_set.map((sp) => {
                        spec.push({value: sp._id, label: sp.serviceName})
                    })

                    this.setState({
                        serviceList: spec
                    })
                }
            })
            .catch(error => {
                this.setState({
                    messageType: 'error',
                    errorMessage: 'Error connecting to server to fetch service list'
                });
            })
    }
    //============ GET PATIENT SERVICE LIST END ====================

    //============ GET PATIENT SERVICE DATA STARTS ====================
    async getServiceData () {
        await axios.get(`${serverLink}service/service_data_list`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0)
                    this.setState({
                        serviceData: data
                    })
            })
            .catch(error => {
                this.setState({
                    messageType: 'error',
                    errorMessage: 'Error connecting to server to fetch service list'
                });
            })
    }

    onServiceChange = (e) => {
        const service_type = e.value;
        const service_data = this.state.serviceData;
        if (service_data.length > 0) {
            let record = service_data.filter(item => item.serviceID === service_type);
            this.setState({
                ...this.state.formData,
                serviceType: service_type
            });
            if (record.length > 0) {
                let spec = [];
                record.map((sp) => {
                    spec.push({value: sp._id, data: sp.serviceAmount, label: sp.serviceItemName})
                })
                this.setState({
                    serviceDataSelect: spec
                })
            }
        }
    }

    onServiceItemChange = (e) => {
        const formData = this.state.formData;
        const service_item_id = e.value;
        const service_amount = e.data;
        const service_item_name = e.label;

        if (service_item_id === "")
            return false;

        this.setState({
            formData: {
                ...formData,
                appointmentFee: service_amount,
                consultationAmount: this.currencyConverter(service_amount),
                serviceItemName: service_item_name,
                serviceID: service_item_id
            },
        });

    }

    onSpecialisationChange = (e) => {
        const formData = this.state.formData;
        let specialisation = e.value;
        let appointment_amount = e.data;

        this.setState({
            doctorSlots: ""
        })

        if(specialisation !== "" && specialisation !== 'N/A') {
            //GET ALL THE DOCTORS UNDER THE DEPARTMENT
            axios.get(`${this.state.serverLink}doctor/department_doctors/${this.state.hospitalCode}/${specialisation}`, this.props.doctorDetails.token)
                .then(doctors => {
                    let spec = [];
                    if (doctors.data.length > 0) {
                        doctors.data.map((sp) => {
                            spec.push({value: sp.userID, label: `${sp.firstName} ${sp.middleName} ${sp.surname}`})
                        })
                        this.setState({
                            doctorList: spec
                        })
                    } else {
                        this.setState({
                            doctorList: []
                        })
                    }

                    // let select = document.getElementById('doctorID');
                    // let length = select.options.length;
                    // for (let i = length-1; i >= 1; i--) {
                    //     select.options[i] = null;
                    // }
                    //
                    // if(doctors.data.length > 0) {
                    //     doctors.data.map((doctor) => {
                    //         let doctor_name = `${doctor.firstName} ${doctor.middleName} ${doctor.surname}`;
                    //         let opt = document.createElement('option');
                    //         opt.value = doctor.userID
                    //         opt.innerHTML = doctor_name
                    //         select.appendChild(opt)
                    //     });
                    // }
                })
                .catch(err => {
                    console.log("ERROR: ",err);
                })
        }else {
            this.setState({
                doctorList: [],
            })
        }

        this.setState({
            formData: {
                ...formData,
                appointmentDepartment: specialisation
            },
        });

    }

    onDoctorChange = (e) => {
        this.setState({
            doctorSlots: ""
        })
        if (e.value === '')
            return false;

        const formData = this.state.formData;

        this.setState({
            formData: {
                ...formData,
                doctorID: e.value,
                doctorName: e.label
            },
        });
    }
    //============ GET PATIENT SERVICE LIST END ====================

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: 'submitting'
        });

        this.onAddAppointment();
        this.getPatientCurrentWallet();
    };

    async getPatientCurrentWallet () {
        await axios.get(`${serverLink}patient/current_wallet/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(wallet => {
                this.setState({
                    walletAmount: wallet.data.walletAmount
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    async onAddAppointment() {
        let sendData = {
            ...this.state.formData
        };
        console.log(this.state.formData.selectedItemID)

        if(this.state.formData.serviceID === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'

            });
            this.props.handleErrorDialog('ERROR', 'Please Select the Consultation Type');
            return false;
        }

        if(this.state.formData.serviceItemName === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'

            });
            this.props.handleErrorDialog('ERROR', 'Please Select the Consultation');
            return false;
        }

        if(this.state.formData.appointmentDepartment === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'

            });
            this.props.handleErrorDialog('ERROR', 'Please Select the Appointment Department/Specialisation');
            return false;
        }

        if(this.state.formData.doctorID === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'
            });
            this.props.handleErrorDialog('ERROR', 'Please Select the Appointment Doctor');
            return false;
        }

        if(this.state.formData.appointmentDate === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'
            });
            this.props.handleErrorDialog('ERROR', 'Please Select the Appointment Date');
            return false;
        }

        if(this.state.formData.appointmentTime === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'
            });
            this.props.handleErrorDialog('ERROR', 'Please Select the Appointment Time');
            return false;
        }

        await this.handlePayment(sendData);

    };

    handlePayment = (sendAppointmentData) => {
        const item_amount = parseInt(this.state.formData.appointmentFee);
        const paymentMethod = this.state.paymentMethod;
        let paymentAmount = 0;

        if (this.state.paymentAmount === '') {
            this.props.handleErrorDialog('ERROR', 'Please select the payment option before submitting');
            return false;
        }

        if (paymentMethod === 'Wallet') {
            if (item_amount > parseInt(this.state.paymentAmount))
            {
                this.props.handleErrorDialog('ERROR', 'Insufficient funds in wallet!!!');
                return false;
            } else {
                paymentAmount = item_amount;
            }
        }

        const sendData = {
            patientSerial: this.state.patientSerial,
            patientName: this.state.formData.patientName,
            paymentFor: 'Appointment',
            itemName: this.state.formData.appointmentDepartment,
            paymentAmount: paymentAmount,
            paymentStatus: 'paid',
            paymentType: 'Debit',
            hospitalCode: hospitalCode,
            amountDue: item_amount,
            paymentMethod: this.state.paymentMethod,
            submittedBy: this.props.doctorDetails.userID,
            submittedByName: this.props.doctorDetails.fullName,
            appointmentID: '',
        }

        if (paymentMethod === 'Wallet') {
            sendData.deductionFromID = 'Wallet'
            sendData.deductionFromName = 'Wallet'
        }else if (paymentMethod === 'package') {
            sendData.deductionFromID = this.state.selectedItemID //Package ID
            sendData.deductionFromName = this.state.selectedItemName //Package Name
        }

        axios.post(`${serverLink}payment/process_payment`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    axios.post(`${this.state.serverLink}appointment/add`, sendAppointmentData, this.props.doctorDetails.token)
                        .then(result => {
                            this.setState({
                                isSubmitting: 'Save'
                            });

                            this.setState({
                                isLoading: false,
                            });
                            const response = result.data.message;
                            if(response === "success") {
                                this.props.handleSuccessDialog('ADDED', 'Appointment Added Successfully');
                            } else if (response === "failed") {
                                this.props.handleErrorDialog('FAILED', 'Something went wrong saving the appointment. Please try again!');
                            } else if (response === "error"){
                                this.props.handleErrorDialog('ERROR', 'An error occur adding the appointment. Please try again!');
                            } else {
                                this.props.handleErrorDialog('ERROR', response);
                            }
                        })
                        .catch(err => {

                            this.setState({
                                isLoading: false,
                            });
                            this.props.handleErrorDialog('ERROR', err);
                        });
                }
                else {
                    this.props.handleErrorDialog('ERROR', 'Something went wrong processing payment!!!');
                    return false;
                }
            })
            .catch(error => {
                console.log("Payment failed", error)
                this.props.handleErrorDialog('ERROR', 'Payment failed. Connection to the server was lost! Please try again.');
                return false;
            })
    }

    onGetDepartments() {
        axios.get(`${this.state.serverLink}specialisation/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(specialisation => {
                if(specialisation.data.length < 1) {
                    this.setState({
                        departmentList: ""
                    })

                } else {
                    let spec = [];
                    specialisation.data.map((sp) => {
                        spec.push({value: sp.specialisationName, data: sp.consultationFee, label: sp.specialisationName})
                    })
                    this.setState({
                        departmentList: spec
                    })
                }
            })
            .catch(err => {
                this.setState({
                    departmentList: null
                })
            })
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            return `${user_date.getDate()}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    handleChange = (date) => {
        if (date !== null) {
            const appointmentDate = new Date(date);

            this.setState({
                date: appointmentDate,
                formData: {
                    ...this.state.formData,
                    appointmentDate: `${appointmentDate.getFullYear()}-${appointmentDate.getMonth() + 1 < 10 ? '0'+(appointmentDate.getMonth() + 1) : appointmentDate.getMonth() + 1}-${appointmentDate.getDate() < 10 ? '0'+appointmentDate.getDate() : appointmentDate.getDate()}`,
                },
            });

            let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const appointment_day = days[appointmentDate.getDay()]

            axios.get(`${this.state.serverLink}doctor_schedule/${this.state.hospitalCode}/${this.state.formData.doctorID}`, this.props.doctorDetails.token)
                .then(result => {
                    let schedules = result.data;

                    if(schedules === null) {
                        this.setState({
                            errorMessage: "No Schedule Found for the selected doctor",
                            doctorSlots: ""
                        })
                    } else {
                        this.setState({
                            errorMessage: ""
                        })

                        let slots = schedules.scheduleData[0][appointment_day];

                        if(typeof slots !== "undefined") {
                            this.setState({
                                errorMessage: "",
                                doctorSlots: slots.slots,
                            })
                            this.state.formData.appointmentDay = appointment_day

                        } else {
                            this.setState({
                                errorMessage: "The Selected Doctor doesn't have a schedule on "+appointment_day.toUpperCase(),
                                doctorSlots: ""
                            })
                        }

                    }

                    this.setState({
                        isLoading: false
                    })
                })
                .catch(error => {
                    this.setState({
                        isLoading: false
                    })
                })

        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    appointmentDate: date,
                },
            });
        }
    };

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    onEdit = (e) => {
        const formData = this.state.formData;

        // if(e.target.name === "doctorID") {
        //     this.setState({
        //         doctorSlots: ""
        //     })
        //     let index = e.nativeEvent.target.selectedIndex;
        //     this.state.formData.doctorName = e.nativeEvent.target[index].text
        // }

        if(e.target.name === "paymentSelectOption") {
            let amount = e.target.value;
            let index = e.nativeEvent.target.selectedIndex;
            let payment_method = e.nativeEvent.target[index].getAttribute("data");
            let description = e.nativeEvent.target[index].getAttribute("description");

            if (amount !== '') {
                this.setState({
                    paymentAmount: amount,
                    paymentMethod: payment_method
                })
            } else {
                this.setState({
                    paymentAmount: '',
                    paymentMethod: ''
                })
            }

            if (payment_method === 'package')
                this.setState({
                    selectedItemID: amount,
                    selectedItemName: e.nativeEvent.target[index].getAttribute("package_name")
                })
        }

        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="addappointmentpage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="addappointmentpage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <AppointmentForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                onSpecialisationChange={this.onSpecialisationChange}
                                date={this.state.date}
                                handleChange={this.handleChange}
                                patientSerial={this.state.patientSerial}
                                appointmentName={this.state.patientFullName}
                                appointmentData={this.state.formData}
                                departmentList={this.state.departmentList}
                                serviceList={this.state.serviceList}
                                serviceData={this.state.serviceDataSelect}
                                serviceDataLoading={this.state.serviceData === ''}
                                onServiceChange={this.onServiceChange}
                                onServiceItemChange={this.onServiceItemChange}
                                doctorList={this.state.doctorList}
                                onDoctorChange={this.onDoctorChange}
                                doctorSlots={this.state.doctorSlots}
                                appointmentDate={this.state.formData.appointmentDate}
                                errorMessage={this.state.errorMessage}
                                formType="add"
                                walletAmount={this.state.walletAmount}
                                MoneyConverter={this.currencyConverter}
                                patientPackage={this.state.patientPackage}
                                isSubmitting={this.state.isSubmitting}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AddAppointment);
