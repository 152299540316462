import React, {Component} from "react";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import axios from "axios";
import { serverLink} from "../../../resources/url";
import {connect} from "react-redux";

class BirthAndDeathCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            openAreYouSure: false,
            openAreYouSure2: false,
            imagingMessage: "",
            imagingID: "",
            patientSerial: "",
        }
    }

    DeleteBirthCert = (e) => {
        this.setState({
            imagingMessage: "Are you sure you want delete this record?",
            openAreYouSure: true,
            imagingID: e._id,
            patientSerial: e.patientSerial
        })
    }

    DeleteDeathCert = (e) => {
        this.setState({
            imagingMessage: "Are you sure you want delete this record?",
            openAreYouSure2: true,
            imagingID: e._id,
            patientSerial: e.patientSerial
        })
    }

    handleDelete = () => {
        axios.delete(`${this.state.serverLink}patient/birth-certificate/delete/${this.state.patientSerial}/${this.state.imagingID}`, this.props.doctorDetails.token)
            .then(result => {
                if(result.data.deletedCount === 1) {
                    this.setState({
                        openAreYouSure: false
                    })
                    window.location.reload();
                }
            })
    }

    handleDeathDelete = () => {
        axios.delete(`${this.state.serverLink}patient/death-certificate/delete/${this.state.patientSerial}/${this.state.imagingID}`, this.props.doctorDetails.token)
            .then(result => {
                if(result.data.deletedCount === 1) {
                    this.setState({
                        openAreYouSure2: false
                    })
                    window.location.reload();
                }
            })
    }

    closeDeleteDialog = () => {
        this.setState({
            openAreYouSure: false,
            openAreYouSure2: false,
        });
    };

    render() {
        let count = 0;
        return <div className="card mb-3">
            <div className="card-header">
            </div>
            <ConfirmDialogBox
                openDailog={this.state.openAreYouSure}
                onSetOpenDialog={this.closeDeleteDialog}
                title="Are you sure"
                des={this.state.imagingMessage}
                handleConfirmOkBtn={this.handleDelete}
            >

            </ConfirmDialogBox>

            <ConfirmDialogBox
                openDailog={this.state.openAreYouSure2}
                onSetOpenDialog={this.closeDeleteDialog}
                title="Are you sure"
                des={this.state.imagingMessage}
                handleConfirmOkBtn={this.handleDeathDelete}
            >

            </ConfirmDialogBox>
            <div className="card-body">
                <h3>Birth Certificate</h3>
                <table className="table table-striped mb-3">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Baby Name</th>
                        <th>Mother Name</th>
                        <th>Father Name</th>
                        <th>Date Of Birth</th>
                        <th>Time Of Birth</th>
                        <th>Place Of Birth</th>
                        <th>Weight At Birth</th>
                        <th>Delivered By</th>
                        <th>File</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    { this.props.birthCertificate.length === 0 ?
                        (
                            <tbody>
                            <tr><td colSpan={11} className="text-center">No Record Found</td></tr>
                            </tbody>
                        )
                        :
                        (
                            <tbody>
                            {this.props.birthCertificate.map((r) => {
                                count++;
                                return (
                                    <tr key={r._id}>
                                        <td>{count}</td>
                                        <td>{r.babyName}</td>
                                        <td>{r.mother}</td>
                                        <td>{r.father}</td>
                                        <td>{this.props.formatDate(r.dateOfBirth)}</td>
                                        <td>{r.timeOfBirth}</td>
                                        <td>{r.placeOfBirth}</td>
                                        <td>{r.weightAtBirth} kg</td>
                                        <td>{r.deliveredBy}</td>
                                        <td className="align-middle">
                                            {r.certificateFile === "" ? (
                                                <div className="userbg">
                                                    <i className="fa fa-file-excel fa-2x"/>
                                                </div>
                                            ) : ( r.certificateFile ?
                                                    <a className="btn btn-primary btn-sm" href={serverLink + 'public/uploads/birth_certificates/'+r.certificateFile}>
                                                        View Certificate
                                                    </a>
                                                    : "--"
                                            )}
                                        </td>
                                        <td><i onClick={() => this.DeleteBirthCert(r)} className="fa fa-trash text-danger"/></td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        )
                    }
                </table>

                <h3>Death Certificate</h3>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Deceased Name</th>
                        <th>Date Of Death</th>
                        <th>Cause Of Death</th>
                        <th>Place Of Deceased</th>
                        <th>Pronounced By</th>
                        <th>Certificate File</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    { this.props.deathCertificate.length === 0 ?
                        (
                            <tbody>
                            <tr><td colSpan={9} className="text-center">No Record Found</td></tr>
                            </tbody>
                        )
                        :
                        (
                            <tbody>
                            {this.props.deathCertificate.map((r) => {
                                count++;
                                return (
                                    <tr key={r._id}>
                                        <td>{count}</td>
                                        <td>{r.deceasedName}</td>
                                        <td>{this.props.formatDate(r.dateOfDeath)}</td>
                                        <td>{r.causeOfDeath}</td>
                                        <td>{r.placeOfDeceased}</td>
                                        <td>{r.pronouncedBy}</td>
                                        <td className="align-middle">
                                            {r.certificateFile === "" ? (
                                                <div className="userbg">
                                                    <i className="fa fa-file-excel fa-2x"/>
                                                </div>
                                            ) : ( r.certificateFile ?
                                                    <a className="btn btn-primary btn-sm" href={serverLink + 'public/uploads/death_certificates/'+r.certificateFile }>
                                                        View Certificate
                                                    </a>
                                                    : "--"
                                            )}
                                        </td>
                                        <td><i onClick={() => this.DeleteDeathCert(r)} className="fa fa-trash text-danger"/></td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        )
                    }
                </table>
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(BirthAndDeathCertificate);
