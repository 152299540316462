import React, { Component } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./appointmentform";
import Select from "react-select";

class AppointmentForm extends Component {

        render() {
            let slotChecker = 0;
            const appointment_date = this.props.appointmentDate;

        return (
            this.props.serviceDataLoading ?
                <div className="alert alert-info">Loading...</div> :
            <form onSubmit={this.props.handleSubmit}>
                <div className="">

                    {
                        this.props.errorMessage !== "" ?
                            <div>
                                <span className="alert alert-danger">{this.props.errorMessage}</span>
                                <hr/>
                            </div> : ""
                    }

                    {
                        this.props.appointmentData.appointmentFee !== "" ?
                            <div className="text-center">
                                <span className="alert alert-primary">APPOINTMENT FEE: {this.props.appointmentData.consultationAmount}</span>
                                <hr/>
                            </div>
                            : ""
                    }
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientSerial">Patient ID</label>
                            <input
                                name="patientSerial"
                                type="text"
                                className="form-control"
                                id="patientSerial"
                                disabled="disabled"
                                value={this.props.patientSerial}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="appointmentName">Patient Name</label>
                            <input
                                name="appointmentName"
                                type="text"
                                className="form-control"
                                id="appointmentName"
                                disabled="disabled"
                                value={this.props.appointmentName}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="serviceType">Consultation Type</label>
                            <Select
                                name="serviceType"
                                id="serviceType"
                                isSearchable
                                defaultValue={this.props.appointmentData.serviceType}
                                onChange={this.props.onServiceChange}
                                options={this.props.serviceList}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="doctorID">Consultation</label>
                            <Select
                                name="serviceType"
                                id="serviceType"
                                isSearchable
                                defaultValue={this.props.appointmentData.serviceID}
                                onChange={this.props.onServiceItemChange}
                                options={this.props.serviceData}
                            />

                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="appointmentDepartment">Specialisation</label>
                            <Select
                                name="appointmentDepartment"
                                id="appointmentDepartment"
                                isSearchable
                                defaultValue={this.props.appointmentData.appointmentDepartment}
                                onChange={this.props.onSpecialisationChange}
                                options={this.props.departmentList}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="doctorID">Select Doctor</label>
                            <Select
                                name="appointmentDepartment"
                                id="appointmentDepartment"
                                isSearchable
                                defaultValue={this.props.appointmentData.doctorID}
                                onChange={this.props.onDoctorChange}
                                options={this.props.doctorList}
                            />

                            {/*<select*/}
                            {/*    name="doctorID"*/}
                            {/*    className="custom-select doctorID"*/}
                            {/*    id="doctorID"*/}
                            {/*    onChange={this.props.onEdit}*/}
                            {/*    value={this.props.appointmentData.doctorID}*/}
                            {/*>*/}
                            {/*    {this.props.appointmentData.doctorName !== '' ? <option value={this.props.appointmentData.doctorID}>{this.props.appointmentData.doctorName}</option> : ''}*/}
                            {/*    <option value="">Select Doctor</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label htmlFor="appointmentDate">Appointment Date</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    style={{
                                        padding: "0px 10px",
                                        border: "1px solid rgb(197, 197, 197)",
                                    }}
                                    name="appointmentDate"
                                    className="  form-control"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    value={this.props.date}
                                    onChange={this.props.handleChange}
                                    autoComplete="off"
                                    minDate={new Date()}
                                    disabled={this.props.appointmentData.doctorName === ''}
                                    format="dd/MM/yyyy"
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>

                    {
                        this.props.doctorSlots === "" ?
                            <div className="form-row"><span className="alert alert-info">SELECT THE DOCTOR AND APPOINTMENT DATE TO VIEW THE SCHEDULE SLOTS</span></div> :

                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="appointmentTime">Select Appointment Slot</label>

                                    <div className="form-group">
                                        {
                                            this.props.doctorSlots.map((slot, index) => {
                                                if(slot.status === 'available' || slot.slot === this.props.appointmentData.appointmentTime) {

                                                    const today = new Date();
                                                    const currentDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                                    const currentTime = today.getHours()+ ":"+ today.getMinutes();

                                                    let slotArray = slot.slot.split(' - ');

                                                    if (Date.parse(`${currentDate} ${currentTime}:00`) < Date.parse(`${appointment_date} ${slotArray[0]}:00`)) {
                                                        slotChecker = 1;
                                                        return (
                                                            <span key={index+111111111111}>
                                                                <div className="form-check form-check-inline" key={`slot-key-main-${index}`}>
                                                                    <input
                                                                        key={`slot1-key-input-${index}`}
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        checked={this.props.appointmentData.appointmentTime === slot.slot ? 'checked' : ''}
                                                                        onChange={this.props.onEdit}
                                                                        name="appointmentTime"
                                                                        id={`slot-${index}`} value={slot.slot}/>
                                                                    <label key={`slot2-key-label-${index}`} className="form-check-label btn btn-info btn-sm" htmlFor={`slot-${index}`}>{slot.slot}</label>
                                                                </div>
                                                                { Number.isInteger((index+1) / 5) ? <p/> : '' }
                                                            </span>
                                                        )
                                                    }
                                                }

                                            })
                                        }
                                        {
                                            slotChecker === 0 ? <div className="alert alert-warning">NO APPOINTMENT SLOT AVAILABLE</div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                    }

                    <div className="form-group">
                        <label htmlFor="appointmentDetail">Appointment Description</label>
                        <textarea
                            name="appointmentDetail"
                            className="form-control"
                            id="appointmentDetail"
                            defaultValue={this.props.appointmentData.appointmentDetail}
                            onChange={this.props.onEdit}
                            rows="3"
                        ></textarea>
                    </div>

                    {
                        this.props.formType === 'add' ?
                            <div className="form-row">
                                <label htmlFor="paymentSelectOption">Select Payment Option</label>
                                <select name="paymentSelectOption" id="paymentSelectOption" className="custom-select" onChange={this.props.onEdit}>
                                    <option value="">Select Payment Option</option>
                                    <option value={this.props.walletAmount} data="Wallet">Wallet ({this.props.MoneyConverter(this.props.walletAmount)})</option>
                                    {
                                        this.props.patientPackage.length > 0 ?
                                            this.props.patientPackage.map((p, index) => {
                                                return <option value={p.packageID} package_name={p.packageName}  data="package" description={p.packageDescription} key={index}>{p.packageName} ({this.props.MoneyConverter(p.amount)})</option>
                                            }) : ''
                                    }
                                </select>
                            </div>
                            : ''
                    }
                </div>

                {
                    this.props.isSubmitting !== 'Save' &&
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="alert alert-info">Submitting... Please wait!</div>
                        </div>
                    </div>

                }


                <div className="form-row">
                    <button className="btn btn-success btn-block savebtn" type="submit">
                        Save
                    </button>
                </div>


            </form>
        );
    }
}

export default AppointmentForm;
