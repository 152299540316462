import axios from "axios";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { hospitalCode, serverLink } from "../../../../resources/url";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import DrugSubCategoryForm from "../../drugsubcategory/drugsubcategoryform/drugsubcategoryform";
import "./adddrugsubcategory.css";
import {connect} from "react-redux";


class AddDrugSubCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                categoryName: "",
                subCategoryName: "",
                description: "",
                hospitalCode: "",
                submittedBy: ""
            },

            drugCategoryList: [],
            messageTitle: "",
            messageDescription: "",
            

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

   

    componentDidMount() {
        this.state.submittedBy = this.props.loginDetails.fullName;
        this.fetchDrugCategory();
       
        
    }

    async fetchDrugCategory() {
        await fetch(`${this.state.serverLink}pharmacy/drug/category/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugCategoryList: data,
                });
            });
    }

    





     handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddDrugSubCategory();
    };

   async onAddDrugSubCategory() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy
        };

        if(this.state.formData.subCategoryName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Sub-Category Name"
            this.handleErrorDialog();
            return false;
        }

        axios.post(`${this.state.serverLink}pharmacy/drug/category/sub/add`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "drug category exist") {
                    this.state.messageDescription = "A drug category with the same name already exist";
                    this.handleErrorDialog();
                } else if (response === "error") {
                    this.state.messageDescription = "Something went wrong adding the drug category. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.state.messageDescription = "DRUG CATEGORY ADDED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to upload the drug category details!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/pharmacy/drug/subcategory/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Drug Sub Category Added"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <DrugSubCategoryForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Add Drug Sub-Category"
                                drugCategoryList={this.state.drugCategoryList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(AddDrugSubCategory);