import React, {Component} from "react";
import "./updatehospital.css";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import HospitalForm from "../hospitalform/hospitalform";
import { serverLink, hospitalCode } from "../../../resources/url";
import StateData from "../../../resources/state_and_lga.json"
import { connect } from "react-redux";
import {loginValidation} from "../../../context/loginvalidation";

class UpdateHospital extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"hospital/update",
            hospitalCode: hospitalCode,
            isLoading: false,
            logoAvatar: this.props.hospitalData.logo,
            imagefile: "",
            htmlelement: <div className="addpersonpage">
                                <img
                                    className="netimage"
                                    srcSet={serverLink+'public/uploads/hospital/'+this.props.hospitalData.logo}
                                    alt="profileImage"
                                />
                            </div>,
            formData: {
                hospitalName: this.props.hospitalData.hospitalName,
                hospitalCode: this.props.hospitalData.hospitalCode,
                hospitalState: this.props.hospitalData.hospitalState,
                hospitalLGA: this.props.hospitalData.hospitalLGA,
                stateCode: this.props.hospitalData.hospitalStateCode,
                hospitalAddress: this.props.hospitalData.hospitalAddress,
                logo: this.props.hospitalData.logo,
                color: this.props.hospitalData.color,
                emailAddress: this.props.hospitalData.emailAddress,
                phoneNumber: this.props.hospitalData.phoneNumber,
                geoLat: this.props.hospitalData.geoLat,
                geoLong: this.props.hospitalData.geoLong,
                hospitalHeadID: this.props.hospitalData.hospitalHeadID,
                hospitalHeadName: this.props.hospitalData.hospitalHeadName,
                updatedBy: ""
            },

            stateList: [],
            lgaList: [],

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: localStorage.getItem("userID")
        });
        this.onGetStateList();
    }

    async onGetStateList () {
        let state_data = [];
        let lga_data = [];
        await StateData.map((state) => {
            state_data.push({state: state.state, code: state.code});
            if(state.code === this.state.formData.stateCode) {
                state.lgas.map(lga => {
                    lga_data.push(lga);
                })
            }
        });

        this.setState({
            stateList: state_data,
            lgaList: lga_data
        });

    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            //isLoading: true,
        });
        this.onAddHospital();
    };

    async onAddHospital() {
        if(this.state.formData.hospitalName.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Hospital Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.hospitalAddress.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Hospital Address"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.phoneNumber.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Hospital Phone Number"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.emailAddress.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Hospital Email Address"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.hospitalState.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Hospital State"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.hospitalLGA.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Hospital LGA"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.logo === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Upload the Hospital Logo"
            this.handleErrorDialog();
            return false;
        }

        // if (this.state.imagefile !== "") {
        //     const formData = new FormData();
        //     formData.append('logo', this.state.imagefile);
        //
        //     await axios.post(`${serverLink}hospital/uploadLogo`, formData, this.props.doctorDetails.token)
        //         .then(res => { // then print response status
        //             if(res.status === 200) {
        //                 const formData = this.state.formData;
        //                 this.setState({
        //                     formData: {
        //                         ...formData,
        //                         logo: res.data.logo
        //                     }
        //                 });
        //             }
        //         })
        //         .catch(error => {
        //             this.setState({
        //                 isLoading: false,
        //             });
        //             this.state.messageDescription = "Hospital logo is not uploaded. Please try again!"
        //             this.handleErrorDialog();
        //             return false;
        //         })
        // }
        // else {
        //     if(this.state.formData.logo === "") {
        //         this.setState({
        //             isLoading: false,
        //         });
        //         this.state.messageDescription = "Please Upload the Hospital Logo"
        //         this.handleErrorDialog();
        //         return false;
        //     }
        // }

        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy
        };


        axios.patch(`${this.state.serverLink}`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                 if (response === "success"){
                    this.state.messageDescription = "HOSPITAL UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        if(e.target.name === 'hospitalState') {
            let index = e.nativeEvent.target.selectedIndex;
            const state_name = e.nativeEvent.target[index].text;
            const state_code = e.nativeEvent.target[index].getAttribute('state_code');

            let lga_data = [];
            StateData.map(state => {
                if(state.code === state_code) {
                    state.lgas.map(lga => {
                        lga_data.push(lga);
                    })
                }
            })
            this.setState({
                lgaList: lga_data,
                formData: {
                    ...formData,
                    [e.target.name]: state_name,
                    stateCode: state_code
                },
            })
        }
        else {
            this.setState({
                formData: {
                    ...formData,
                    [e.target.name]: e.target.value,
                },
            });
        }
    };

    onImageChange = (event) => {
        if (event !== '') {
            const formData = this.state.formData;
            this.setState({
                formData: {
                    ...formData,
                    logo: event
                }
            });
        }
        // const formData = this.state.formData;
        // if (event.target.files && event.target.files[0]) {
        //     this.setState({
        //         imagefile: event.target.files[0],
        //     });
        //     let reader = new FileReader();
        //     reader.onload = (e) => {
        //         this.setState({
        //             logoAvatar: e.target.result,
        //             htmlelement: (
        //                 <div className="addpersonpage">
        //                     <img
        //                         className="netimage"
        //                         srcSet={e.target.result}
        //                         alt="profileImage"
        //                     />
        //                 </div>
        //             ),
        //         });
        //     };
        //     reader.readAsDataURL(event.target.files[0]);
        //     this.setState({});
        // }
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/hospital/dashboard';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Hospital Updated"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <HospitalForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Hospital"
                                htmlelement={this.state.htmlelement}
                                onImageChange={this.onImageChange}
                                stateList={this.state.stateList}
                                lgaList={this.state.lgaList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hospitalData: state.hospitalDetails,
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateHospital);
