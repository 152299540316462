import React, { Component } from "react";
import "./drugsubcategoryform.css";
import Navigator from "../../../Settings/navigation/navigation";

class DrugSubCategoryForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="subCategoryName"> Drug Sub-Category Name <span className="text-danger">*</span></label>
                                            <input
                                                name="subCategoryName"
                                                type="text"
                                                className="form-control"
                                                id="subCategoryName"
                                                value={this.props.data.subCategoryName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="categoryName">Drug Category</label>
                                            <select
                            
                                                name="categoryName"
                                                id="categoryName"
                                                className="custom-select"
                                                value={this.props.data.categoryName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Drug Category</option>
                                                {
                                                    this.props.drugCategoryList.map((category, index) => {
                                                        return <option key={index} data={category._id} value={category.categoryName}>{category.categoryName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6  offset-sm-3 mb-3">
                                            <label htmlFor="description">Description </label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                                id="description"
                                                value={this.props.data.description}
                                                onChange={this.props.onEdit}
                                                rows="3"
                                            >{this.props.data.description}</textarea>
                                        </div>
                                    </div>

                                   <div className="">
                                   
                                   </div>
                                    <div className="col-md-6 offset-sm-3 mb-3">
                                        <button className="btn btn-success btn-block update_btn" type="submit">
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DrugSubCategoryForm;
