import React, {Component, useState} from "react";
import "./login.css";
import axios from "axios";
import { serverLink, hospitalCode } from "../../resources/url";
import {setLogInDetails, setPermissionDetails} from "../../actions/setpersondetailsaction";
import {connect} from "react-redux";

class ForgottenPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disableBtn: "",
            username: "",
            password: "",
            invalid: "invalid",
            userRecord: "",
            permissionGroup: [],
            showSuccess: false,
            showError: false,
        };
    }

    onForgotPassword = async (e) => {
        this.setState({
            disableBtn: "disable",
            invalid: "invalid",
            showSuccess: false,
            showError: false,
        });
        e.preventDefault();

        const sendData = {
            username: this.state.username
        }

        await axios.post(`${serverLink}user/forgotten-password`, sendData)
            .then(login => {
                if (login.data.message === 'success') {
                    this.setState({
                        showSuccess: true,
                        disableBtn: "",
                        invalid: "",
                    });                } else if(login.data.message === 'not exist'){
                    this.setState({
                        showError: true,
                        disableBtn: "",
                        invalid: "",
                    });
                }else {
                    this.setState({
                        disableBtn: "",
                        invalid: "",
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    disableBtn: "",
                    invalid: "",
                });
            });
    };
    onEdit = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {
        return (
            <div className="login_page">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-12 p-0 first_section">
                            <div className="box">
                                <div className="from_section">
                                    {
                                        this.props.isHospitalParent === false ? <img srcSet={this.props.hospitalLogo} width={50} alt=""/> : <i className="fa fa-user-md" aria-hidden="true"/>
                                    }
                                    <ul>

                                        <h3 style={{ color: "white" }}>
                                            Forgotten Password?
                                        </h3>
                                    </ul>
                                    <small className="text-white">Please enter your email address to regain access to your account.</small>
                                    <div className="col-lg-6">
                                        <form onSubmit={this.onForgotPassword}>
                                            <input
                                                name="username"
                                                className="form-control form-control-lg"
                                                type="email"
                                                placeholder="Enter your Email Address"
                                                onChange={this.onEdit}
                                                autoComplete="off"
                                                required
                                            />

                                            {
                                                this.state.showSuccess ? <div className="alert alert-success">A new password has been sent to your email</div> : <></>
                                            }
                                            {
                                                this.state.showError ? <div className="alert alert-danger">Sorry, we couldn't recognize the email address you entered</div> : <></>
                                            }
                                            <button
                                                type="submit"
                                                className="btn btn-info"
                                                disabled={this.state.disableBtn}
                                            >
                                                Continue
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLogInDetails: (p) => {
            dispatch(setLogInDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);
