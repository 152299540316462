import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./finance-dashboard.css";
import {connect} from "react-redux";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";

class FinanceDashboard extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
    }

    render() {
        return (
            <div className="dashboardpage">
                <div className="topheader">
                    <Navigator title="Finance Dashboard" />
                </div>


                <div className="first_section">
                    <div className="row">
                        {
                            this.props.permissionData && this.props.permissionData.map((perm, index) => {
                                if (perm.mainMenuName === 'Finance' && perm.mainMenuLink === null) {
                                    return <div key={index} className="col-sm-4">
                                        {" "}
                                        <Link to={perm.subMenuLink} >
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                }

                            })
                        }

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails
    };
};

export default connect(mapStateToProps, null)(FinanceDashboard);

