import React, { Component } from "react";
import "./druginventoryform.css";
import Navigator from "../../../Settings/navigation/navigation";

class DrugInventoryForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="manufacturerName">Manufacturer<span className="text-danger">*</span> </label>
                                            <input
                                                name="ManufacturerName"
                                                type="text"
                                                className="form-control"
                                                id=""
                                                value={this.props.data.manufacturerName}
                                                onChange={this.props.onEdit}
                                                disabled = "disabled"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="manufacturerName">Manufacturer<span className="text-danger">*</span> </label>
                                            <input
                                                name="DrugName"
                                                type="text"
                                                className="form-control"
                                                id=""
                                                value={this.props.data.drugName}
                                                onChange={this.props.onEdit}
                                                disabled = "disabled"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                             <label htmlFor="drugQuantity">Drug Quantity<span className="text-danger">*</span></label>
                                            <input
                                                name="drugQuantity"
                                                type="number"
                                                className="form-control"
                                                id="drugQuantity"
                                                value={this.props.data.drugQuantity}
                                                onChange={this.props.onEdit}
                                            />
                                        
                                        </div>                                    
                                        <div className="col-md-6 mb-3">
                                             <label htmlFor="drugQuantityUsed">Drug Quantity Used<span className="text-danger">*</span></label>
                                            <input
                                                name="drugQuantityUsed"
                                                type="number"
                                                className="form-control"
                                                id="drugQuantityUsed"
                                                value={this.props.data.drugQuantityUsed}
                                                onChange={this.props.onEdit}
                                            />
                                        
                                        </div>                                    
                                        <div className="col-md-6 mb-3">
                                             <label htmlFor="drugPrice">Drug Cost Price<span className="text-danger">*</span></label>
                                            <input
                                                name="drugPrice"
                                                type="number"
                                                className="form-control"
                                                id="drugPrice"
                                                value={this.props.data.drugPrice}
                                                onChange={this.props.onEdit}
                                            />
                                        
                                        </div>                                    
                                        
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="entryDate">Entry Date</label>
                                            <input
                                                name="entryDate"
                                                type="date"
                                                className="form-control"
                                                id="entryDate"
                                                value={this.props.data.entryDate}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                      
                                    </div>
                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DrugInventoryForm;
