import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "./servicerequest.css"
import ChoosePayment from "../../Finance/choosepayment/choosepayment";
import randomToken from "random-token";


function ServiceRequest(props) {
    // props values
    // patient_id, patient_name, patient_wallet, money_format
    const [serviceData, setServiceData] = useState([]);
    const [selectedService] = useState({serviceID: "", serviceName: ""});
    const [isLoading, setIsLoading] = useState(true);
    const [data, setServiceItem] = useState([]);
    const [columns] = useState([
        {
            name: "Service Item Name",
            width: "70%",
            cell: (row) => (
                <>
                    <p>{row.serviceItemName}</p> {row.serviceDescription !== "" && <p><span className="badge badge-info">{row.serviceDescription}</span></p> }
                </>
            ),
            sortable: true
        },
        {
            name: "Amount",
            cell: (row) => (
                <>
                    {row.serviceAmount !== "" ? props.money_format(row.serviceAmount) : '--' }
                    {
                        parseFloat(row.serviceAmount) === 0 && <input type="number" step="0.01" placeholder={"Enter Amount"} id={row._id}/>
                    }
                </>
            ),
            sortable: true
        },
        {
            name: "Qty",
            width:"5%",
            cell: (row) => (
                <>
                    <input type="number" className="qty" style={{width: '40px'}} item_id={row._id} item_name={row.serviceItemName} item_price={row.serviceAmount} item_description={row.serviceDescription} onChange={qtyChange} size={2}/>
                </>
            ),
        }
    ]);
    const [itemList, setItemList] = useState([]);
    const [requestID] = useState(randomToken(16));
    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState(false);


    const getServiceList = async () => {
        await axios.get(`${serverLink}service/all/${hospitalCode}`, props.doctorDetails.token)
            .then(response => {
                setServiceData(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log("SERVICES NETWORK ERROR", error)
            })

    }

    useEffect(()=>{
        getServiceList();
    }, [])


    const getServiceItemList = async (service_id) => {
        await axios.get(`${serverLink}service/service_data/active/${service_id}`, props.doctorDetails.token)
            .then(response => {
                if (response.data.length > 0)
                    setServiceItem(response.data);
                else
                    setServiceItem([])
                setIsLoading(false)
            })
            .catch(error => {
                console.log("SERVICES NETWORK ERROR", error)
            })
    }


    const onServiceChange = (e) => {
        const service_id = e.target.value;
        const service_name = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text

        selectedService.serviceID = service_id;
        selectedService.serviceName = service_name;

        if (service_id === "")
            return false;
        setIsLoading(true);
        getServiceItemList(service_id)

    }

    const qtyChange = (row) => {
        let price = parseFloat(row.target.getAttribute('item_price'));
        if (price === 0) {
            const price_input = document.getElementById(row.target.getAttribute('item_id')).value;
            if (parseFloat(price_input) < 1 || price_input === '') {
                return false
            }
            price = parseFloat(price_input)
        }
        const sendData = {
            requestID: requestID,
            hospitalCode: hospitalCode,
            submittedBy: props.doctorDetails.fullName,
            patientSerial: props.patient_id,
            patientName: props.patient_name,
            service_id: selectedService.serviceID,
            service_name: selectedService.serviceName,
            item_id: row.target.getAttribute('item_id'),
            item_name: row.target.getAttribute('item_name'),
            item_price: price,
            item_description: row.target.getAttribute('item_description'),
            item_qty: parseInt(row.target.value),
            item_total: price*parseInt(row.target.value),
            payment_status: 'paid'
        }

        setItemList(itemList => itemList.filter(item => item.item_id !== sendData.item_id));
        if (sendData.item_qty > 0) {
            setItemList(itemList => [...itemList, sendData]);
        }

    }

    const handlePaymentResponse = () => {
        setProcessing(true)
        itemList.map((record, index) => {
            axios.post(`${serverLink}service/add_service_request`, record, props.doctorDetails.token)
                .then(response => {
                    if (response.data.message === 'success') {
                        setMessage("success")
                    } else
                        setMessage("error")
                })
                .catch(error => {
                    console.log("NETWORK ERROR", error)
                })
            if ((index+1) === itemList.length)
                setProcessing(false)
        })
    }

    const tableData = {
        columns,
        data,
        print: false,
        exportHeaders: true,
        filterPlaceholder: 'Search service item'
    };

    let totalAmount = 0
    let totalQty = 0;

    return isLoading ? (
            <div className="text-center">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) :
        (
        <>
            <div className="form-row">
                <label htmlFor="">Select Service Category</label>
                <select name="ServiceCategory" className="form-control" value="" onChange={onServiceChange}>
                    <option value="">Select Option</option>
                    {
                        serviceData.length > 0 &&
                        serviceData.map((service, index) => {
                            return <option key={index} value={service._id}>{service.serviceName}</option>
                        })
                    }
                </select>
            </div>

            {
                data.length > 0 &&
                <>
                    <hr/>
                    <h4 className="text-center">{selectedService.serviceName}</h4>
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>

                    <hr/>

                    <h4 className="text-center">Item Selected</h4>
                    {
                        itemList.length > 0 ?
                            <>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Item</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                        <th>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        itemList.map((item, index) => {
                                            totalQty += item.item_qty;
                                            totalAmount += item.item_total;
                                            return <tr key={index}>
                                                <td>{(index+1)}</td>
                                                <td>{item.item_name}</td>
                                                <td>{props.money_format(item.item_price)}</td>
                                                <td>{item.item_qty}</td>
                                                <td>{props.money_format(item.item_total)}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th>TOTAL</th>
                                        <th>--</th>
                                        <th>--</th>
                                        <th>{totalQty}</th>
                                        <th>{props.money_format(totalAmount)}</th>
                                    </tr>
                                    </tfoot>
                                </table>
                                {
                                    processing === true &&
                                    <div className="alert alert-info">UPLOADING REQUEST. PLEASE WAIT...</div>
                                }
                                {
                                    message === "success" &&
                                    <div className="alert alert-success">REQUEST SUBMITTED</div>
                                }

                                {
                                    message === "error" &&
                                    <div className="alert alert-danger">REQUEST NOT SUBMITTED</div>
                                }

                                <ChoosePayment
                                    patientID={props.patient_id}
                                    patientName={props.patient_name}
                                    walletAmount={props.patient_wallet}
                                    MoneyConverter={props.money_format}
                                    paymentFor="Service"
                                    itemID={requestID}
                                    itemName={"Service Request"}
                                    itemAmount={totalAmount}
                                    handlePaymentSuccess={handlePaymentResponse}
                                />

                            </>
                            : <div className="alert alert-info">NO ITEM SELECTED YET!</div>
                    }
                </>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(ServiceRequest);
