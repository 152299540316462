import React, { Component } from "react";
import "./imagingform.css";
import Navigator from "../../../Settings/navigation/navigation";

class ImagingForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="imageName"> Image Name <span className="text-danger">*</span></label>
                                            <input
                                                name="imageName"
                                                type="text"
                                                className="form-control"
                                                id="imageName"
                                                value={this.props.data.imageName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="labName">Lab Name <span className="text-danger">*</span></label>
                                            <select

                                                name="labName"
                                                id="labName"
                                                className="custom-select"
                                                value={this.props.data.labName}
                                                onChange={this.props.onEdit}>
                                                <option>Select lab name</option>
                                                {
                                                    this.props.labList.map((category, index) => {
                                                        return <option key={index} data={category._id} value={category.labName}>{category.labName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="price"> Price <span className="text-danger">*</span></label>
                                            <input
                                                name="price"
                                                type="text"
                                                className="form-control"
                                                id="price"
                                                value={this.props.data.price}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >

                                        <div className="col-md-6  offset-sm-3 mb-3">
                                            <label htmlFor="description">Description </label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                                id="description"
                                                value={this.props.data.description}
                                                onChange={this.props.onEdit}
                                                rows="3"
                                            >{this.props.data.description}</textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6 offset-sm-3 mb-3">
                                        <button className="btn btn-success btn-block update_btn" type="submit">
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImagingForm;
