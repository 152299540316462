import React, { Component } from "react";
import "./blockform.css";
import Navigator from "../../../navigation/navigation";

class BlockForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="blockName">Block Name <span className="text-danger">*</span></label>
                                            <input
                                                name="blockName"
                                                type="text"
                                                className="form-control"
                                                id="blockName"
                                                value={this.props.data.blockName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="address">Block Address <span className="text-danger">*</span></label>
                                            <input
                                                name="address"
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                value={this.props.data.address}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="extensionNo">Extension Number <span className="text-danger">*</span></label>
                                            <input
                                                name="extensionNo"
                                                type="text"
                                                className="form-control"
                                                id="extensionNo"
                                                value={this.props.data.extensionNo}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="description">Block Description <span className="text-danger">*</span></label>
                                            <input
                                                name="description"
                                                type="text"
                                                className="form-control"
                                                id="description"
                                                value={this.props.data.description || ''}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="phoneNumber">Phone Number </label>
                                            <input
                                                name="phoneNumber"
                                                type="tel"
                                                className="form-control"
                                                id="phoneNumber"
                                                value={this.props.data.phoneNumber}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="emailAddress">Email Address </label>
                                            <input
                                                name="emailAddress"
                                                type="email"
                                                className="form-control"
                                                id="emailAddress"
                                                value={this.props.data.emailAddress}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="geoLong">Block Longitude</label>
                                            <input
                                                name="geoLong"
                                                type="text"
                                                className="form-control"
                                                id="geoLong"
                                                value={this.props.data.geoLong}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="geoLat">Block Latitude </label>
                                            <input
                                                name="geoLat"
                                                type="text"
                                                className="form-control"
                                                id="geoLat"
                                                value={this.props.data.geoLat}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                   
                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlockForm;
