import React, {useState} from "react";
import "./servicepage.css";
import Navigator from "../../navigation/navigation";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import axios from "axios";
import {serverLink, hospitalCode} from "../../../../resources/url";
import FormPrompt from "../../../DialogBoxes/formprompt";
import {connect} from "react-redux";

function ServicePage(props) {
    if (props.serviceData.serviceName === "" || typeof props.serviceData === "undefined")
        window.location.href = "/";

    const [serviceData] = useState(props.serviceData);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [columns] = useState([
        {
            name: "Service Item Name",
            selector: row => `${row.serviceItemName}`,
            width: '45%',
            sortable: true
        },
        {
            name: "Amount",
            selector: row => `${row.serviceAmount !== "" ? moneyFormat(row.serviceAmount) : '--' }`,
            sortable: true
        },
        {
            name: "Description",
            selector: row => `${row.serviceDescription !== "" ? row.serviceDescription : '--' }`,
            sortable: true
        },
        {
            name: "Status",
            cell: (row) => (
                row.serviceStatus === 'active' ?
                    <i className="fa fa-check text-primary"/> :
                    <i className="fa fa-times text-danger"/>
            ),
            sortable: true
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <button
                        onClick={() => onOpenUpdateModal(row)}
                        type="button"
                        className="btn btn-info btn-sm"
                    >
                        <i className="fa fa-pencil" aria-hidden="true"/>
                    </button>
                </>
            ),
        }
    ]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        serviceItemName: "",
        serviceAmount: "",
        serviceMinAmount: 0,
        serviceDescription: "",
        hospitalCode: hospitalCode,
        serviceID: props.serviceData._id,
        serviceItemID: "",
        serviceStatus: "active",
        submittedBy: props.doctorDetails.fullName
    });
    const [message, setMessage] = useState({
        type: "",
        message: ""
    });

    const formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const day = user_date.getDate() < 10 ? "0"+user_date.getDate() : user_date.getDate();
            const hours = user_date.getHours() < 10 ? "0"+user_date.getHours() : user_date.getHours();
            const min = user_date.getMinutes() < 10 ? "0"+user_date.getMinutes() : user_date.getMinutes();
            const second = user_date.getSeconds() < 10 ? "0"+user_date.getSeconds() : user_date.getSeconds();

            return `${day}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} : ${hours}:${min}:${second}`
        } else {
            return '--'
        }
    };

    const moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount);
    }

    const getServiceItemList = async () => {
        await axios.get(`${serverLink}service/service_data/${serviceData._id}`, props.doctorDetails.token)
            .then(response => {
                if (isLoading) {
                    setData(response.data);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.log("SERVICES NETWORK ERROR", error)
            })
    }
    getServiceItemList();

    const onOpenModal = () => {
        setMessage({type: "", message: ""});
        setFormData({
            ...formData,
            serviceItemName: "",
            serviceAmount: "",
            serviceMinAmount: 0,
            serviceDescription: "",
            serviceStatus: "active",
            serviceItemID: ""
        });
        console.log(formData)
        setOpenModal(true);

    }

    const onOpenUpdateModal = (row) => {
        setMessage({type: "", message: ""});
        setFormData({
            ...formData,
            serviceItemName: row.serviceItemName,
            serviceAmount: row.serviceAmount,
            serviceMinAmount: row.serviceMinAmount,
            serviceStatus: row.serviceStatus,
            serviceDescription: row.serviceDescription,
            serviceItemID: row._id
        })
        setOpenModal(true);
    }

    const closeModal = () => {
        setOpenModal(false);
    }

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value
        });
    }

    const onSubmit = () => {
        setMessage({
            type: "info",
            message: "Submitting..."
        });

        if (formData.serviceItemName.trim() === "") {
            setMessage({
                type: "danger",
                message: "Service Item Name Can't be Empty"
            });
            return false;
        }

        if (formData.serviceAmount === "") {
            setMessage({
                type: "danger",
                message: "Service Amount Can't be Empty"
            });
            return false;
        }

        if (formData.serviceMinAmount === "") {
            setFormData({
                ...formData,
                serviceMinAmount: 0
            });
            return false;
        }

        if (formData.serviceItemID === "") {
            axios.post(`${serverLink}service/add_service_data`, formData, props.doctorDetails.token)
                .then(response => {
                    const message = response.data.message;
                    if (message === 'exist')
                        setMessage({type: "danger", message: "Service Item Already Exist"});
                    else if (message === 'success') {
                        setMessage({type: "success", message: "Service Item Added"});
                        setTimeout(() => {
                            setOpenModal(false)
                            setIsLoading(true);
                            getServiceItemList();
                        }, 2000)
                    }
                    else
                        setMessage({type: "danger", message: "Error occurred. Please try again!"});
                })
                .catch(error => {
                    console.log("NETWORK ERROR", error);
                    setMessage({type: "danger", message: "Network error occurred. Please try again!"});
                })
        } else {
            axios.patch(`${serverLink}service/update_service_data`, formData, props.doctorDetails.token)
                .then(result => {
                    const message = result.data.message;
                    if (message === 'success') {
                        setMessage({type: "success", message: "Service Item Updated"});
                        setTimeout(() => {
                            setOpenModal(false)
                            setIsLoading(true);
                            getServiceItemList();
                        }, 2000)
                    }
                    else
                        setMessage({type: "danger", message: "Error occurred. Please try again!"});
                })
                .catch(error => {
                    console.log("NETWORK ERROR", error);
                    setMessage({type: "danger", message: "Network error occurred. Please try again!"});
                })
        }
    }

    const tableData = {
        columns,
        data,
        print: false,
        exportHeaders: true,
        filterPlaceholder: 'Search service item'
    };

    return isLoading ? (
        <div className="basepage">
            <i className="fas fa-spinner fa-pulse fa-2x "/>
        </div>
    ) : (
        <div className="basepage">
            <div className="main_section">

                <FormPrompt
                    style={{ position: 'relative', zIndex: '3' }}
                    openDialog={openModal}
                    title="Service Form"
                    onSetOpenDialog={closeModal}
                    isCloseBtnAppear={true}
                >
                    {
                        message.message !== "" &&
                        <div className={`alert alert-${message.type}`}>{message.message}</div>
                    }
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="serviceItemName">Service Item Name</label>
                                <input type="text" className={"form-control"} placeholder={"Service Item Name"} id={"serviceItemName"} onChange={onEdit} value={formData.serviceItemName}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="serviceAmount">Service Amount</label>
                                <input type="number" className={"form-control"} placeholder={"Service Amount"} id={"serviceAmount"} onChange={onEdit} value={formData.serviceAmount}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="serviceStatus">Service Status</label>
                                <select id="serviceStatus" className="form-control" value={formData.serviceStatus} onChange={onEdit}>
                                    <option value="active">active</option>
                                    <option value="inactive">inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="serviceDescription">Service Description</label>
                                <input type="text" className={"form-control"} placeholder={"Service Description"} id={"serviceDescription"} onChange={onEdit} value={formData.serviceDescription}/>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" id={"serviceItemID"} value={formData.serviceItemID}/>

                    <button className={"btn btn-block btn-primary"} onClick={onSubmit}>Submit</button>

                </FormPrompt>

                <div className="topheader">
                    <Navigator title={serviceData.serviceName} />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>

                    <button type="button" className="btn btn-warning" onClick={onOpenModal}>+ Add</button>
                </div>
                <hr />
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover/>
                </DataTableExtensions>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
        serviceData: state.serviceDetails
    };
};

export default connect(mapStateToProps, null)(ServicePage);
