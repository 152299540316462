import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import "../base.css";
import Navigator from "../../Settings/navigation/navigation";
import {hospitalCode, serverLink} from "../../../resources/url";
import FormPrompt from "../../DialogBoxes/formprompt";
import { toast } from "react-toastify";
import ReportTable from "../../ReusableComponents/ReportTable";

function GalleryCategory(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const columns = ["S/N", "Category Name", "InsertedBy", "Edit"];
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({id: "",  categoryName: "",  oldCategoryName: "",  hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})

    const toggleModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const toggleEditModal = () => {
        setOpenModal(true)
    }

    const closeModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const resetFormData = () => {
        setFormData({id: "",  categoryName: "",  oldCategoryName: "",  hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})
    }

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value
        });
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();

        if (formData.categoryName === '') {
            toast.error("Please select the category")
            return false;
        }

        toast.dark("Submitting...")

        if (formData.id === "") {
            await axios.post(`${serverLink}website/gallery/category/add`, formData, props.loginDetails.token).then(res => {
                if (res.data.message === 'success'){
                    toast.success("Gallery Category Added Successfully");
                    closeModal();
                    getData();
                }
                else if (res.data.message === 'exist')
                    toast.error("Gallery Category  Already Exist")
                else
                    toast.error("Network issue. Please check your network and try again!")

            }).catch(err => {
                toast.error("Connection failure. Please check your network and try again!")
            })
        } else {
            const sendData = {
                ...formData,
                oldItemName: "oldItemName"
            }
            await axios.patch(`${serverLink}website/gallery/category/update`, sendData, props.loginDetails.token).then(res => {
                if (res.data.message === 'success') {
                    toast.success("Gallery Category Updated Successfully")
                    closeModal();
                    getData();
                }
                else if (res.data.message === 'exist')
                    toast.error("Gallery Category  Already Exist")
                else
                    toast.error("Network issue. Please check your network and try again!")

            }).catch(err => {
                toast.error("Connection failure. Please check your network and try again!")
            })
        }

    }

    const getData = async () => {

        //CATEGORY LIST
        await axios.get(`${serverLink}website/gallery/category/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            let rows = []
            if (res.data.length > 0) {
                res.data.map((r, index) => {
                    rows.push([(index+1), r.categoryName, r.submittedBy,
                        <button className={"btn btn-sm btn-info"} onClick={() => handleOnEdit(r)}>Edit</button>])
                })
            }
            setData(rows)
            setIsLoading(false)
        }).catch(error => {
            console.log("ERROR FETCHING ITEMS")
        })


    }

    useEffect(() => {
        getData();
    },[])

    const handleOnEdit = (dt) => {
        setFormData({
            ...formData,
            id: dt._id,
            categoryName: dt.categoryName,
            oldCategoryName: dt.categoryName,
        })
        toggleEditModal();

    }


    return isLoading ? (<div className="baselistpage">
        <i className="fas fa-spinner fa-pulse fa-2x "/>
    </div>) : (
        <div className="baselistpage">
            <FormPrompt
                openDialog={openModal}
                title="Manage Category Form"
                onSetOpenDialog={closeModal}
                isCloseBtnAppear={true}
            >
                <form onSubmit={onSubmitForm}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="categoryName">Enter Category Name</label>
                                <input type="text" className="form-control" id="categoryName" value={formData.categoryName} onChange={onEdit}/>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary btn-block">Submit</button>
                    </div>
                </form>
            </FormPrompt>
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Gallery Category" />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>
                    <button type="button" className="btn btn-warning mb-2" onClick={toggleModal}>  + Add Gallery Category</button>
                </div>
                <ReportTable columns={columns} data={data} title={"Gallery Category"} height={"600px"} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(GalleryCategory);
