import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./settingsdashboard.css";
import { loginValidation } from "../../../context/loginvalidation";
import { connect } from "react-redux";
import Navigator from "../navigation/navigation";
import MenuIcon from "../../Dashboard/utils/menu-icon";
import {
    FaHospital,
    FaHospitalUser,
    FaRegHospital, FaServicestack,
} from "react-icons/fa";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";

const SettingsDashboard = ({ permissionData, doctorDetails }) => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(0);
    const [hospital, setHospital] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [services, setServices] = useState(0);

    useEffect(() => {
        if (loginValidation(window.location.pathname, permissionData) !== 'permitted') {
            window.location.href = '/';
        }
        fetchDetails();
    }, []);

    const fetchDetails = async () => {
        try {
            const dashboard_response = await axios.get(`${serverLink}report/settings/dashboard/${hospitalCode}`, doctorDetails.token);
            const doctors = dashboard_response.data.doctors;
            const services = dashboard_response.data.services;
            const insuranceCompany = dashboard_response.data.insuranceCompany;

            //Set Count
            setUsers(doctors.length)
            setServices(services.length)
            setInsurance(insuranceCompany.length)

            setLoading(false)
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    }

    return (loading ? (
                <div className="dashboardpage">
                    <i className="fas fa-spinner fa-pulse fa-2x "/>
                </div>
            ) :
        <div className="dashboardpage">
            <div className="topheader">
                <Navigator title="Settings Dashboard" />
            </div>

            <div className="first_section" style={{marginTop: '-30px'}}>
                <div className="row">
                    <div className="col-md-3">
                        <MenuIcon name="Hospitals" icon={<FaHospital size={40} />} count={1} />
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="System Users" icon={<FaHospitalUser size={40} />} count={users} />
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Insurance Schemes" icon={<FaRegHospital size={40} />} count={insurance} />
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Services" icon={<FaServicestack size={40} />} count={services} />
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="col-md-12 mt-3 mb-0" style={{ borderBottom: '1px solid #CCCCCC', borderTop: '1px solid #CCCCCC', padding: '5px' }}>
                            <h2><b>Menu</b></h2>
                        </div>
                    </div>

                    {permissionData &&
                        permissionData.map((perm, index) => {
                            if (perm.mainMenuName === 'Settings' && perm.mainMenuLink === null) {
                                return (
                                    <div key={index} className="col-md-4">
                                        {" "}
                                        <Link to={perm.subMenuLink}>
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        hospitalData: state.hospitalDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(SettingsDashboard);
