import axios from "axios";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { hospitalCode, serverLink } from "../../../../../resources/url";
import "./add-item-sub-category.css";
import {connect} from "react-redux";
import {showAlert} from "../../../../Common/sweetalert/sweetalert";
import ItemSubCategoryForm from "../item-sub-category-form/item-sub-category-form";


class AddItemSubCategory extends Component {
    constructor() {
        super();
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                subCategoryName: "",
                categoryName: "",
                description: "",
                hospitalCode: "",
                submittedBy: ""
            },
            submittedBy: ""
        };
    }

    componentDidMount() {
        this.state.submittedBy = this.props.loginDetails.fullName;
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddCategory();
    };


    async onAddCategory() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy
        };

        if(this.state.formData.subCategoryName === "") {
            this.setState({
                isLoading: false,
            });
            showAlert("EMPTY FIELD", "Please Enter the Item Sub Category Name", "error");
            return false;
        }

        if(this.state.formData.categoryName === "") {
            this.setState({
                isLoading: false,
            });
            showAlert("EMPTY FIELD", "Please Enter the Item Category Name", "error");
            return false;
        }

        axios.post(`${this.state.serverLink}finance/item/category/sub/add`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "exist") {
                    showAlert("ERROR", "A item sub category with the same name already exist", "error");
                    return false;
                } else if (response === "error") {
                    showAlert("ERROR", "Something went wrong adding the item category. Please try again!", "error");
                    return false;
                } else if (response === "success"){
                    showAlert("SUB CATEGORY ADDED ", "ITEM SUB CATEGORY ADDED SUCCESSFULLY", "success").then(()=>{
                        window.location.href = '/finance/inventory/item/category/sub';
                    });
                    return false;
                } else {
                    showAlert("ERROR", "Failed to upload the item sub category details!", "error");
                    return false;
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <ItemSubCategoryForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Add Item Sub Category"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(AddItemSubCategory);