import React, {Component, useRef, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./consultationpage.css";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {serverLink, hospitalCode, calculateAge} from "../../../resources/url";
import axios from "axios";
import {Link} from "react-router-dom";
import PatientActionButton from "../../Patients/utils/patientaction";
import PatientChart from "../../Chart/patientchart";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import FormPrompt from "../../DialogBoxes/formprompt";
import PatientBloodPressureChart from "../../Chart/patientbloodpressurechart";
import JoditEditor from "jodit-react";
import {connect} from "react-redux";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import ReactToPrint, { useReactToPrint } from "react-to-print";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import PatientImagingRequest from "../../Imaging/patientimagingrequest/patientimagingrequest";
import Navigator from "../../Settings/navigation/navigation";
import ServiceRequest from "../../Services/servicerequest/servicerequest";
import PatientServiceList from "../../Services/patientservicelist/patientservicelist";
import OnlineConsultationForm from "./online-consultation-form";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import ECTReactComponent from "./ECTReactComponent";
import IcdComponent from "./icdcomponent";
import ICDtable from "./view/icdtable";
import PatientVitalList from "../../Patients/utils/patientvitals";
import {
    checkImageURL,
    currencyConverter,
    defaultImage,
    formatDateAndTime,
    imageExists
} from "../../../resources/constants";
import AddAllergy from "../../Patients/addpatientallergy/addpatientallergy";
import CaptureVital from "../../Appointment/capturevital/capturevital";
import {Rating} from "react-simple-star-rating";
import {FaFrown, FaRegLaughBeam, FaRegMeh, FaSadCry, FaSmile} from "react-icons/fa";
import doctor from "../../../Images/doctor.jpg";
import woman_doctor from "../../../Images/woman-doctor.jpg";

class ConsultationPage extends Component {
    constructor(props) {
        super(props);
        let page_url = window.location.pathname;
        let page_id = page_url.split('/');
        let id = page_id[3];
        this.componentRef = React.createRef(); // Create a ref
        this.printComponent = React.createRef();

        this.state = {
            isLoading: true,
            printPage: false,
            appointment_id: id,
            appointmentData: '',
            patientData: '',
            patientSerial: '',
            patientName: '',
            patientEmail: '',
            patient: '',
            drugList: [],
            date: null,
            columns: [
                {
                    name: "Drug Name",
                    selector: row => `${row.drugName}`,
                    sortable: true
                },
                {
                    name: "Category",
                    selector: row => `${row.categoryName}`,
                    sortable: true
                },
                {
                    name: "Manufacturer",
                    selector: row => `${row.manufacturerName}`,
                    sortable: true
                },
                {
                    name: "Expired Date",
                    selector: row => `${row.expiryDate}`,
                    sortable: true
                },
                {
                    name: "Price",
                    selector: row => `${row.drugPrice}`,
                    sortable: true
                },
                {
                    name: "Quantity",
                    selector: row => `${row.drugQuantity}`,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <button
                            onClick={() => {this.addDrug(row.rowData)}}
                            type="button"
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-notes-medical" aria-hidden="true"/>
                        </button>
                    ),
                }
            ],
            encounters: [],
            visitNotes: [],
            nurseObservationNotes: [],
            tasks: [],
            vitals: '',
            allergy: [],
            prescription: [],
            vitalList: [],
            visitNoteList: [],
            note1: '',
            note2: '',
            note3: '',
            note4: '',
            note5: '',
            note6: '',
            allergyImageSrc: '',
            labTestList: [],
            labTestColumns: [
                {
                    name: "Test Name",
                    selector: row => `${row.testName}`,
                    sortable: true
                },
                {
                    name: "Specimen",
                    selector: row => row.specimen.map((item, index) => { return <div key={index}>{item.specimenName} (Amount => {item.testAmount})</div> }),
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => row.specimen.map((item, index) => {
                        return <div key={index}>
                            <button
                                onClick={() => {this.addLabTest(row.rowData, index)}}
                                type="button"
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-vial" aria-hidden="true"> {item.specimenName} </i>
                            </button>
                        </div>


                    }),
                }
            ],
            labTestMessage: false,
            patientLabTestList: [],

            openPainAssessmentModal: false,
            painRating: 1,
            painRatingName: 'No Pain',
            painComment: "",
            painAssessmentList: [],

            openShowAllergyImageDialog: false,
            isCloseDialogAppear: true,
            openVitalDialog: false,

            openDoctorNote: false,
            doctorNoteID: '',
            doctorNote1: '',
            doctorNote2: '',
            doctorNote3: '',
            doctorNote4: '',
            doctorNote5: '',
            doctorNote6: '',

            openNurseObservationNote: false,
            isNewNurseObservationNote: false,
            nurseObservationNote1: "",
            nurseObservationNote2: "",
            nurseObservationNote3: "",
            nurseObservationNote4: "",

            openDoctorTask: false,
            doctorTask: '',

            openNurseAssessmentModal: false,
            openNurseAssessmentSubmissionModal: false,
            nurseSelectedDrug: "",
            nurseSelectedDrugIndex: 0,
            nurseSelectedDrugTimeTaken: "",
            nurseSelectedDrugDoseGiven: "",

            openPrescriptionModal: false,
            openErrorDialog: false,
            messageDescription: '',
            messageTitle: '',

            openAddDrugModal: false,
            appointmentPrescription: '',
            prescriptionAmount: 0,
            drugDose: 0,
            drugName: '',
            drugQuantity: '',
            drugPrice: '',
            selectedDrug: '',
            prescriptionDetail: '',
            prescriptionQuantity: 1,
            prescriptionSubmitting: false,
            prescriptionSuccess: false,
            prescriptionError: false,
            openFinishDialog: false,
            openAllergyDialog: false,

            openLabTestModal: false,
            labTestSubmitting: false,
            labTestSuccess: false,
            labTestError: false,

            graph_label: [],
            temp_val: [],
            bp_systolic_val: [],
            bp_diastolic_val: [],
            height_val: [],
            weight_val: [],

            imagingList: [],
            linkList: [],
            openImageModal: false,

            openServiceDialog: false,
            servicesList: [],
            walletAmount: 0,
            openAreYouSure: false,
            deleteMessage: "",
            deleteID: "",
            openAlertDialog: false,
            openOnlineConsultationDialog: false,
            formData: {
                patientSerial: '',
                patientName: '',
                doctorID: this.props.doctorDetails.userID,
                doctorName: this.props.doctorDetails.fullName,
                consultationLink: '',
                consultationDate: null,
                consultationTime: '',
                hospitalCode: '',
                submittedBy: this.props.doctorDetails.fullName,
            },
            openICD11Note: false,
            icd11List: [],
            isNewNote: true,
            onOpenCancelModal: false,
            cancelData: "",
            cancelMessage: "Are you sure you want to cancel the selected test request?",

            onOpenCancelPrescriptionModal: false,
            cancelPrescriptionData: "",
            cancelPrescriptionItem: "",
            cancelPrescriptionMessage: "Are you sure you want to cancel the selected prescription?",
            onOpenImagingCancelModal: false,
            cancelImagingData: "",
            cancelImagingMessage: "Are you sure you want to cancel the selected Imaging Request?",

            openReferralModal: false,
            doctorList: [],
            referralDoctorID: "",
            referralDoctorName: "",
            referralDoctorNote: ""
        }
    }

    componentWillMount() {
        this.getAppointmentData();
    }

    async getAppointmentData () {
        //APPOINTMENT RECORDS
        await axios.get(`${serverLink}appointment/${this.state.appointment_id}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                if (result.length < 1) {
                    window.location.href = '/doctor/consultation'
                }

                this.setState({
                    appointmentData: result,
                    patientSerial: result.patientSerial,
                    patient: result.patientName
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR APPOINTMENT FETCH',error)
            })

        await axios.get(`${serverLink}appointment/consultation/online/link/${hospitalCode}/${this.state.appointment_id}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    linkList: result,
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR PATIENT FETCH',error)
            })


        await axios.get(`${serverLink}patient/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    patientEmail: result.emailAddress,
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR PATIENT FETCH',error)
            })


        //PATIENT RECORDS
        await axios.get(`${serverLink}patient/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    patientData: result
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR PATIENT FETCH', error)
            })

        //PATIENT ENCOUNTERS
        await axios.get(`${serverLink}appointment/encounter/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    encounters: result,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR ENCOUNTER FETCH', error)
            })


        //PATIENT VISIT NOTES
        this.getAppointmentNote();

        //NOTE OBSERVATION NOTES
        this.getNurseObservationNote();

        //PatientPainAssessment
        this.getPatientPainAssessment();

        //Prescriptions
        this.getPatientPrescription();

        //Tasks
        this.getPatientTasks();

        //Lab Tests
        this.getPatientLabTest();

        //Imaging
        this.getPatientImaging();


        //PATIENT ALLERGEN
        await axios.get(`${serverLink}allergy/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    allergy: result
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        await axios.get(`${serverLink}vital/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;

                this.setState({
                    vitals: result,
                    vitalList: result
                })

                if (result.length > 0) {
                    let graph_label = [];
                    let temp_val = [];
                    let weight_val = [];
                    let height_val = [];
                    let bp_systolic_val = [];
                    let bp_diastolic_val = [];
                    result.map((v, index) => {
                        if (index < 5) {
                            graph_label.push(this.formatDate(v.submittedOn))

                            //TEMPERATURE GRAPH GENERATOR
                            if (v.temperature !== '') {
                                if (v.temperature_unit !== 'C') {
                                    let fToCel = (parseFloat(v.temperature) - 32) * 5 / 9;
                                    temp_val.push(fToCel.toFixed(1));
                                } else {
                                    temp_val.push(parseFloat(v.temperature));
                                }
                            } else {
                                temp_val.push(0);
                            }

                            //HEIGHT GRAPH GENERATOR
                            if (v.height !== '') {
                                let height = parseFloat(v.height)
                                if (v.height_unit !== 'M') {
                                    let CMTom = height * 0.01;
                                    height_val.push(parseFloat(CMTom.toFixed(2)));
                                } else {
                                    height_val.push(parseFloat(height.toFixed(2)));
                                }
                            } else {
                                height_val.push(0);
                            }

                            //WEIGHT GRAPH GENERATOR
                            if (v.weight !== '') {
                                if (v.weight_unit !== 'KG') {
                                    let LBToKG = parseFloat(v.weight) * 0.453592;
                                    weight_val.push(parseFloat(LBToKG.toFixed(1)));
                                } else {
                                    weight_val.push(parseFloat(v.weight));
                                }
                            } else {
                                weight_val.push(0);
                            }

                            //BLOOD PRESSURE GRAPH GENERATOR
                            if (v.bloodPressure !== '') {
                                const bp = v.bloodPressure.split('/');
                                bp_systolic_val.push(parseInt(bp[0]));
                                bp_diastolic_val.push(parseInt(bp[1]));
                            } else {
                                bp_systolic_val.push(0);
                                bp_diastolic_val.push(0);
                            }

                        }
                    })

                    this.setState({
                        graph_label: graph_label.reverse(),
                        temp_val: temp_val.reverse(),
                        height_val: height_val.reverse(),
                        weight_val: weight_val.reverse(),
                        bp_systolic_val: bp_systolic_val.reverse(),
                        bp_diastolic_val: bp_diastolic_val.reverse()
                    })

                }
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        await axios.get(`${serverLink}patient/current_wallet/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(wallet => {
                this.setState({
                    walletAmount: wallet.data.walletAmount
                })
            })
            .catch(err => {
                console.log(err)
            })

        await axios.get(`${serverLink}patient/current_wallet/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(wallet => {
                this.setState({
                    walletAmount: wallet.data.walletAmount
                })
            })
            .catch(err => {
                console.log(err)
            })

        await axios.get(`${serverLink}doctor/all/${hospitalCode}`, this.props.doctorDetails.token)
            .then(doctors => {
                this.setState({
                    doctorList: doctors.data
                })
            })
            .catch(err => {
                console.log(err)
            })

        this.getServicesList();

        this.getPatientICDCodes();
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            return `${user_date.getDate()}/${monthNames[user_date.getMonth()]}/${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()} : 
                    ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}
                    
                    `
        } else {
            return '--'
        }
    };

    moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    handleViewAllergyImageClicked = (e) => {

        this.setState({
            allergyImageSrc: e.target.srcset,
            openShowAllergyImageDialog: true
        });
    }

    // =================== PAIN ASSESSMENT START =================================
    onOpenPainAssessment = () => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openPainAssessmentModal: true,
                prescriptionSubmitting: false,
                prescriptionError: false,
                prescriptionSuccess: false
            })
        }

    }
    onClosePainAssessment = () => {
        this.setState({
            painRating: 1,
            painRatingName: 'No Pain',
            openPainAssessmentModal: false,
            painComment: ""
        })
    }

    handlePainAssessmentRating = (e) => {
        let pain_rating = '';
        if (e === 1)
            pain_rating = 'No Pain';
        else if (e === 2)
            pain_rating = 'Mild Pain';
        else if (e === 3)
            pain_rating = 'Moderate Pain';
        else if (e === 4)
            pain_rating = 'Severe Pain';
        else
            pain_rating = 'Very Severe Pain';

        this.setState({
            painRating: e,
            painRatingName: pain_rating
        })
    }

    onEditPainComment = (e) => {
        this.setState({
            painComment: e.target.value
        })
    }

    onSubmitPainAssessment = (e) => {
        e.preventDefault();
        if (this.state.painRating > 3 && this.state.painComment.trim() === "") {
            this.setState({
                prescriptionError: true,
                prescriptionSubmitting: false
            });
            return false;
        }

        this.setState({
            prescriptionSubmitting: true,
            prescriptionError: false
        });

        const sendData = {
            patientSerial: this.state.patientData.patientSerial,
            hospitalCode: hospitalCode,
            appointmentID: this.state.appointment_id,
            painRating: this.state.painRating/1,
            painRatingName: this.state.painRatingName,
            painComment: this.state.painComment,
            nurseID: this.props.doctorDetails.userID,
            nurseName: this.props.doctorDetails.fullName,
            submittedBy: this.props.doctorDetails.userID
        }

        axios.post(`${serverLink}patient_admission/addPainAssessment`, sendData, this.props.doctorDetails.token)
            .then(res => {
                const response = res.data.message;
                if (response === 'success') {
                    this.setState({
                        prescriptionSubmitting: false,
                        prescriptionError: false,
                        prescriptionSuccess: true
                    });
                    this.getPatientPainAssessment();
                } else {
                    this.setState({
                        prescriptionSubmitting: false,
                        prescriptionError: true,
                        prescriptionSuccess: false
                    });
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    prescriptionSubmitting: false,
                    prescriptionError: true,
                    prescriptionSuccess: false
                });
            })
    }

    async getPatientPainAssessment () {
        await axios.get(`${serverLink}patient_admission/painAssessment/appointment/${hospitalCode}/${this.state.appointment_id}`, this.props.doctorDetails.token)
            .then(result => {
                const assessment = result.data;
                this.setState({
                    isLoading: false,
                    painAssessmentList: assessment,
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH CONSULTATION PAIN ASSESSMENT:", error)
            })
    }

    // =================== PAIN ASSESSMENT ENDS ==============================


    // ================== NURSE ASSESSMENT START ================================
    onOpenNurseAssessment = () => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openNurseAssessmentModal: true
            })
        }
    }

    onOpenNurseAssessmentSubmission = (item, index) => {
        this.setState({
            openNurseAssessmentModal: false,
            openNurseAssessmentSubmissionModal: true,
            nurseSelectedDrug: item,
            nurseSelectedDrugIndex: index,
            nurseSelectedDrugTimeTaken: "",
            nurseSelectedDrugDoseGiven: "",
            prescriptionSuccess: false,
            prescriptionError: false,
            prescriptionSubmitting: false
        });
    }

    onEditNurseAssessment = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        if (name === 'timeTaken')
            this.setState({ nurseSelectedDrugTimeTaken: value })
        else
            this.setState({ nurseSelectedDrugDoseGiven: value })
    }

    onSubmitNurseAssessment = (e) => {
        e.preventDefault();
        this.setState({
            prescriptionSuccess: false,
            prescriptionError: false,
            prescriptionSubmitting: true
        })
        const time_taken = this.state.nurseSelectedDrugTimeTaken;
        const dose_given = this.state.nurseSelectedDrugDoseGiven;
        let prescription_data = this.state.prescription[0].prescriptionDetail[this.state.nurseSelectedDrugIndex];
        if (typeof  prescription_data.assessment === "undefined")
            prescription_data.assessment = [];

        prescription_data.assessment.push(
            {
                timeTaken: time_taken, doseGive:  dose_given,
                submittedBy: this.props.doctorDetails.fullName, submittedDate: this.formatDateAndTime(new Date())
            }
        );

        const sendData = {
            _id: this.state.prescription[0]._id,
            prescriptionDetail: this.state.prescription[0].prescriptionDetail
        }

        axios.patch(`${serverLink}pharmacy/admission/prescription_assessment`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    this.setState({
                        prescriptionSuccess: true,
                        prescriptionError: false,
                        prescriptionSubmitting: false
                    })
                    this.getPatientPrescription();
                    setTimeout(() => {
                        this.setState({
                            openNurseAssessmentModal: true,
                            openNurseAssessmentSubmissionModal: false,
                        })
                    },1000)
                }
                else
                    this.setState({
                        prescriptionSuccess: false,
                        prescriptionError: true,
                        prescriptionSubmitting: false
                    })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    prescriptionSuccess: false,
                    prescriptionError: true,
                    prescriptionSubmitting: false
                })
            })

    }

    onCloseNurseAssessment = () => {
        this.setState({
            openNurseAssessmentModal: false,
        })
    }
    onCloseNurseAssessmentSubmission = () => {
        this.setState({
            openNurseAssessmentModal: true,
            openNurseAssessmentSubmissionModal: false
        })
    }
    // ================= NURSE ASSESSMENT END ==================================


    // ===================== DRUG FUNCTIONS START ==================================

    onLoadDrugs = () => {
        axios.get(`${serverLink}pharmacy/drugs/active/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    data.map(val => {
                        this.setState({
                            drugList: [...this.state.drugList,
                                {
                                    id: val._id,
                                    drugName: val.drugName,
                                    categoryName: val.categoryName,
                                    manufacturerName: val.manufacturerName,
                                    expiryDate: this.formatDate(val.expiryDate),
                                    drugPrice: this.moneyFormat(val.drugPrice),
                                    drugQuantity: val.drugQuantity,
                                    rowData: val
                                }
                            ]
                        })
                    })
                    this.setState({
                        openPrescriptionModal: true,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        messageTitle: 'NO DRUGS FOUND',
                        messageDescription: 'Drugs cannot be found in the pharmacy',
                        isLoading: false,
                        openErrorDialog: true
                    })
                }
            })
            .catch(error => {
                console.log('NETWORK ERROR FETCH DRUG DATA', error)
            })
    }

    onOpenPrescriptionModal =() => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                isLoading: true
            })

            if (this.state.drugList.length > 0) {
                this.setState({
                    isLoading: false,
                    openPrescriptionModal: true
                })
            } else {
                this.onLoadDrugs();
            }

        }
    }

    addDrug = (drug) => {
        this.setState({
            openPrescriptionModal: false,
            openAddDrugModal: true,
            drugName: drug.drugName,
            drugPrice: drug.drugPrice,
            drugQuantity: drug.drugQuantity,

            prescriptionSubmitting: false,
            prescriptionSuccess: false,
            prescriptionError: false,
            prescriptionDetail: '',
            prescriptionQuantity: 1
        })
    }

    prescribeDrug = (e) => {
        e.preventDefault();
        this.setState({
            prescriptionSubmitting: true,
            prescriptionSuccess: false,
            prescriptionError: false
        })
        const prescription = document.getElementById('prescriptionDetail').value;
        const dose = document.getElementById('prescriptionDose').value;
        const frequency = document.getElementById('prescriptionFrequency').value;
        const quantity = parseInt(document.getElementById('prescriptionQuantity').value);
        const drugName = this.state.drugName;
        const drugPrice = this.state.drugPrice;
        const prescriptionAmount = quantity*drugPrice;

        const drugData = {drugName: drugName, drugQuantity: quantity, drugAmount: drugPrice, drugDose: dose, doseFrequency: frequency, prescription: prescription, status: 'unpaid'}

        if (this.state.appointmentPrescription.length < 1) {
            //ADD
            const sendData = {
                patientSerial: this.state.appointmentData.patientSerial,
                patientName: this.state.appointmentData.patientName,
                doctorID: this.props.doctorDetails.userID,
                doctorName: this.props.doctorDetails.fullName,
                prescriptionDetail: [drugData],
                prescriptionAmount: prescriptionAmount,
                hospitalCode: hospitalCode,
                appointmentID: this.state.appointment_id
            }
            axios.post(`${serverLink}pharmacy/prescription/add`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    if (result.data.message === 'success') {
                        document.getElementById('PrescriptionForm').reset()
                        this.getPatientPrescription();
                        this.setState({
                            prescriptionSubmitting: false,
                            prescriptionSuccess: true,
                            prescriptionError: false,
                            prescriptionDetail: '',
                            prescriptionQuantity: 1
                        })

                        let object = this
                        setTimeout(function () {
                            object.closeAddDrugDialog();
                        },2000)
                    } else {
                        this.setState({
                            prescriptionSubmitting: false,
                            prescriptionSuccess: false,
                            prescriptionError: true
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        prescriptionSubmitting: false,
                        prescriptionSuccess: false,
                        prescriptionError: true
                    })
                })
        } else  {
            //UPDATE
            const sendData = {
                patientSerial: this.state.appointmentData.patientSerial,
                updatedBy: this.props.doctorDetails.userID,
                prescriptionDetail: [...this.state.appointmentPrescription, drugData],
                prescriptionAmount: prescriptionAmount+this.state.prescriptionAmount,
                hospitalCode: hospitalCode,
                appointmentID: this.state.appointment_id
            }

            axios.patch(`${serverLink}pharmacy/prescription/update`, sendData, this.props.doctorDetails.token)
                .then(data => {
                    if (data.data.message === 'success') {
                        document.getElementById('PrescriptionForm').reset()
                        this.getPatientPrescription();
                        this.setState({
                            prescriptionSubmitting: false,
                            prescriptionSuccess: true,
                            prescriptionError: false,
                            prescriptionDetail: '',
                            prescriptionQuantity: 1
                        })

                        let object = this
                        setTimeout(function () {
                            object.closeAddDrugDialog()
                        },2000)
                    } else {
                        this.setState({
                            prescriptionSubmitting: false,
                            prescriptionSuccess: false,
                            prescriptionError: true
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        prescriptionSubmitting: false,
                        prescriptionSuccess: false,
                        prescriptionError: true
                    })
                })
        }

    }

    async getPatientPrescription () {
        await axios.get(`${serverLink}pharmacy/prescription/patient/limit/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                this.setState({
                    isLoading: false,
                    prescription: data,
                });

                if (data.length > 0) {
                    data.map(v => {
                        if (v.appointmentID === this.state.appointment_id) {
                            this.setState({
                                appointmentPrescription: v.prescriptionDetail,
                                prescriptionAmount: v.prescriptionAmount
                            })
                        }
                    })
                }
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH PRESCRIPTION:", error)
            })
    }

    // ===================== DRUG FUNCTIONS ENDS ====================================

    // ====================== LAB FUNCTIONS START ================================

    onLoadLabTest = () => {
        axios.get(`${serverLink}laboratory/test/active/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    data.map(val => {
                        this.setState({
                            labTestList: [...this.state.labTestList,
                                {
                                    id: val._id,
                                    labName: val.laboratoryName,
                                    testName: val.testName,
                                    specimen: val.specimen,
                                    rowData: val
                                }
                            ]
                        })
                    })
                    this.setState({
                        openLabTestModal: true,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        messageTitle: 'NO TEST FOUND',
                        messageDescription: 'Test cannot be found in the lab',
                        isLoading: false,
                        openErrorDialog: true
                    })
                }
            })
            .catch(error => {
                console.log('NETWORK ERROR FETCH TEST DATA', error)
            })
    }

    onOpenLabTestModal =() => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                isLoading: true
            })

            if (this.state.labTestList.length > 0) {
                this.setState({
                    isLoading: false,
                    openLabTestModal: true
                })
            } else {
                this.onLoadLabTest();
            }
        }
    }

    addLabTest = (test, index) => {

        this.setState({
            labTestMessage: 'submitting'
        });
        const sendData = {
            requestSerial: Math.floor(100000 + Math.random() * 9000000000000000),
            requestID: this.state.appointment_id,
            laboratoryName: test.laboratoryName,
            testID: test._id,
            specimen: test.specimen[index],
            testName: test.testName,
            testAmount: test.specimen[index].testAmount,
            patientSerial: this.state.appointmentData.patientSerial,
            patientName: this.state.appointmentData.patientName,
            doctorID: this.state.appointmentData.doctorID,
            doctorName: this.state.appointmentData.doctorName,
            submittedBy: this.props.doctorDetails.userID,
            submittedByName: this.props.doctorDetails.fullName,
            hospitalCode: hospitalCode
        }

        axios.post(`${serverLink}laboratory/test/request/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    labTestMessage: result.data.message
                })

                const object = this
                this.getAppointmentData();
                setTimeout(function () {
                    object.setState({
                        labTestMessage: false
                    })
                }, 3000)
            })
            .catch(error => {
                this.setState({
                    labTestMessage: 'failed'
                })
            })
    }

    async getPatientLabTest () {
        await axios.get(`${serverLink}laboratory/test/request/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                this.setState({
                    patientLabTestList: data,
                    isLoading: false
                });

            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH PATIENT TESTS:", error)
            })
    }

    // ====================== LAB  FUNCTIONS ENDS ===============================

    // ====================== DOCTOR NOTE FUNCTIONS START =======================
    onOpenDoctorNote = () => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openDoctorNote: true
            });
        }
    }
    async getAppointmentNote () {
        await axios.get(`${serverLink}appointment/consultation/doctorNote/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                const note = result.data;
                this.setState({
                    isLoading: false,
                    visitNotes: note,
                    doctorNote: note.filter(item => item.appointmentID === this.state.appointment_id)
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH CONSULTATION NOTE:", error)
            })
    }
    doctorNote1Handler = (event) => {this.setState({ doctorNote1: event})}
    doctorNote2Handler = (event) => {this.setState({ doctorNote2: event})}
    doctorNote3Handler = (event) => {this.setState({ doctorNote3: event})}
    doctorNote4Handler = (event) => {this.setState({ doctorNote4: event})}
    doctorNote5Handler = (event) => {this.setState({ doctorNote5: event})}
    doctorNote6Handler = (event) => {this.setState({ doctorNote6: event})}
    submitConsultationNote = () => {
        this.setState({
            openDoctorNote: false
        })


        if (!this.state.isNewNote) {
            //UPDATE APPOINTMENT NOTE
            const sendData = {
                doctorNoteID: this.state.doctorNoteID,
                doctorNote1: this.state.doctorNote1,
                doctorNote2: this.state.doctorNote2,
                doctorNote3: this.state.doctorNote3,
                doctorNote4: this.state.doctorNote4,
                doctorNote5: this.state.doctorNote5,
                doctorNote6: this.state.doctorNote6,
                updatedBy: this.props.doctorDetails.userID,
                appointmentID: this.state.appointment_id,
                hospitalCode: hospitalCode,
            }
            axios.patch(`${serverLink}appointment/consultation/updateNote`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.setState({
                            doctorNoteID: '', doctorNote1:'', doctorNote2:'', doctorNote3:'', doctorNote4:'',
                            doctorNote5:'', doctorNote6:'', isNewNote: true
                        })
                        this.getAppointmentNote();
                    }
                })
                .catch(error => {
                    console.log('CONSULTATION NOTE NOT UPDATED', error)
                })
        }
        else {
            //ADD APPOINTMENT NOTE
            const sendData = {
                patientSerial: this.state.patientSerial,
                hospitalCode: hospitalCode,
                appointmentID: this.state.appointment_id,
                doctorNote1: this.state.doctorNote1,
                doctorNote2: this.state.doctorNote2,
                doctorNote3: this.state.doctorNote3,
                doctorNote4: this.state.doctorNote4,
                doctorNote5: this.state.doctorNote5,
                doctorNote6: this.state.doctorNote6,
                doctorID: this.props.doctorDetails.userID,
                doctorName: this.props.doctorDetails.fullName,
                submittedBy: this.props.doctorDetails.userID
            }
            const removeHTMLTag = (string) => {
                return string.replace(/<\/?[^>]+(>|$)/g, "")
            }
            //cleanText = strInputCode.replace(/<\/?[^>]+(>|$)/g, "");
            if (removeHTMLTag(sendData.doctorNote1).length !== 0 || removeHTMLTag(sendData.doctorNote2).length !== 0
                || removeHTMLTag(sendData.doctorNote3).length !== 0 || removeHTMLTag(sendData.doctorNote4).length !== 0
                || removeHTMLTag(sendData.doctorNote5).length !== 0 || removeHTMLTag(sendData.doctorNote6).length !== 0
            ) {
                axios.post(`${serverLink}appointment/consultation/addNote`, sendData, this.props.doctorDetails.token)
                    .then(result => {
                        const response = result.data.message;
                        if (response === 'success') {
                            this.getAppointmentNote()
                        }
                    })
                    .catch(error => {
                        console.log('CONSULTATION NOTE NOT ADDED', error)
                    })
            }



        }
    }
    // ====================== DOCTOR NOTE FUNCTIONS END ==========================

    // ====================== Nurse OBSERVATION FUNCTIONS START =======================
    onOpenNurseObservationNote = () => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openNurseObservationNote: true,
                isNewNurseObservationNote: true,
                nurseObservationNote1: "",
                nurseObservationNote2: "",
                nurseObservationNote3: "",
                nurseObservationNote4: "",
            });
        }
    }
    onOpenUpdateNurseObservationNote = (note) => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openNurseObservationNote: true,
                isNewNurseObservationNote: false,
                nurseObservationNote1: note.note1,
                nurseObservationNote2: note.note2,
                nurseObservationNote3: note.note3,
                nurseObservationNote4: note.note4,
                observationNoteUpdateID: note._id
            })
        }
    }
    async getNurseObservationNote () {
        await axios.get(`${serverLink}patient_admission/consultation/nurse/note/${hospitalCode}/${this.state.appointment_id}`, this.props.doctorDetails.token)
            .then(result => {
                const note = result.data;
                this.setState({
                    isLoading: false,
                    nurseObservationNotes: note
                });

            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH NURSE NOTE:", error)
            })
    }

    nurseObservationNote1Handler = (event) => {
        this.setState({ nurseObservationNote1: event})
    }
    nurseObservationNote2Handler = (event) => {
        this.setState({ nurseObservationNote2: event})
    }
    nurseObservationNote3Handler = (event) => {
        this.setState({ nurseObservationNote3: event})
    }
    nurseObservationNote4Handler = (event) => {
        this.setState({ nurseObservationNote4: event})
    }

    submitNurseObservationNote = () => {
        this.closeDialog();
        if (this.state.nurseObservationNote1 === "" && this.state.nurseObservationNote2 === "" && this.state.nurseObservationNote3 === "" && this.state.nurseObservationNote4 === "") {
            return false;
        }

        if (!this.state.isNewNurseObservationNote) {
            //UPDATE NURSE OBSERVATION NOTE
            const sendData = {
                nurseNote1: this.state.nurseObservationNote1,
                nurseNote2: this.state.nurseObservationNote2,
                nurseNote3: this.state.nurseObservationNote3,
                nurseNote4: this.state.nurseObservationNote4,
                updatedBy: this.props.doctorDetails.userID,
                _id: this.state.observationNoteUpdateID,
                hospitalCode: hospitalCode,
            }
            axios.patch(`${serverLink}patient_admission/nurse/note/update`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.getNurseObservationNote()
                    }
                })
                .catch(error => {
                    console.log('NURSE OBSERVATION NOTE NOT UPDATED', error)
                })
        }
        else {
            //ADD NURSE OBSERVATION NOTE
            const sendData = {
                patientSerial: this.state.patientSerial,
                hospitalCode: hospitalCode,
                appointmentID: this.state.appointment_id,
                nurseNote1: this.state.nurseObservationNote1,
                nurseNote2: this.state.nurseObservationNote2,
                nurseNote3: this.state.nurseObservationNote3,
                nurseNote4: this.state.nurseObservationNote4,
                doctorID: this.props.doctorDetails.userID,
                doctorName: this.props.doctorDetails.fullName,
                submittedBy: this.props.doctorDetails.userID
            }

            axios.post(`${serverLink}patient_admission/nurse/note/add`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.getNurseObservationNote()
                    }
                })
                .catch(error => {
                    console.log('NURSE OBSERVATION NOTE NOT ADDED', error)
                })
        }
    }
    // ====================== NURSE OBSERVATION FUNCTIONS END ==========================

    // ======================== DOCTOR TASK START ================================
    onOpenDoctorTask = () => {
        document.getElementById('AddTaskForm').reset();
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openDoctorTask: true,
                prescriptionError: false,
                prescriptionSuccess: false,
                prescriptionSubmitting: false,
            });
        }
    }
    async getPatientTasks () {
        await axios.get(`${serverLink}appointment/consultation/doctorTask/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                const task = result.data;
                this.setState({
                    isLoading: false,
                    tasks: task,
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH CONSULTATION TASK:", error)
            })
    }
    submitConsultationTask = (event) => {
        event.preventDefault();
        const task = document.getElementById('doctorTask').value;
        this.setState({
            prescriptionError: false,
            prescriptionSuccess: false,
            prescriptionSubmitting: true,
        })
        const sendData = {
            patientSerial: this.state.patientSerial,
            hospitalCode: hospitalCode,
            appointmentID: this.state.appointment_id,
            task: task,
            doctorID: this.props.doctorDetails.userID,
            doctorName: this.props.doctorDetails.fullName,
            submittedBy: this.props.doctorDetails.userID
        }

        axios.post(`${serverLink}appointment/consultation/addTask`, sendData, this.props.doctorDetails.token)
            .then(result => {
                const response = result.data.message;
                if (response === 'success') {
                    this.getPatientTasks();
                    this.setState({
                        prescriptionSuccess: true,
                        prescriptionSubmitting: false,
                    });

                    const object = this;
                    setTimeout(function () {
                        object.setState({
                            openDoctorTask: false
                        })

                    },2000)

                }
            })
            .catch(error => {
                console.log('CONSULTATION TASK NOT ADDED', error)
                this.setState({
                    prescriptionError: true,
                    prescriptionSubmitting: false,
                })
            })
    }

    onChangeClinicalTaskStatus = (task) => {
        const sendData = {
            taskStatus: task.taskStatus === 'incomplete' ? 'completed' : 'incomplete',
            submittedBy: this.props.doctorDetails.userID,
            id: task._id
        }

        axios.patch(`${serverLink}appointment/consultation/updateTask`, sendData, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.message === 'success') {
                    this.getPatientTasks();
                }
            })
            .catch(error => {
                console.log("NETWORK ERROR UPDATE CLINICAL TASK:", error)
            })

    }
    // ====================== DOCTOR TASK END ====================================

    // ===================== IMAGING START ======================================
    async getPatientImaging () {
        await axios.get(`${serverLink}imaging/patient/request/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const imaging = result.data;
                this.setState({
                    isLoading: false,
                    imagingList: imaging,
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH CONSULTATION IMAGING:", error)
            })
    }

    onOpenImageModal = () => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openImageModal: true
            });
        }

    }

    onImageModalClose = () => {
        this.setState({
            openImageModal: false
        });
        this.getPatientImaging();

    }
    // ==================== IMAGING END =========================================
    closeDialog = () => {
        this.setState({
            openShowAllergyImageDialog: false,
            openDoctorNote: false,
            openNurseObservationNote: false,
            openPrescriptionModal: false,
            openLabTestModal: false,
            openErrorDialog: false,
            openDoctorTask: false,
            openFinishDialog: false,
            labTestMessage: false,
            openImageModal: false,
            openOnlineConsultationDialog: false,
            openICD11Note: false,
            openReferralModal: false
        });
    };

    async getPatientICDCodes () {
        await axios.get(`${serverLink}icd/patient/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    icd11List: result.data,
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH PATIENT ICD CODES:", error)
            })
    }
    closeICDCodeDialog = () => {
        this.getPatientICDCodes();
        this.setState({
            openICD11Note: false
        })
    }

    closeAddDrugDialog = () => {
        this.setState({
            openAddDrugModal: false,
            openPrescriptionModal: true
        });
    };

    openFinishDialog = () => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openFinishDialog: true
            });
        }
    }

    onFinishConsultation = () => {
        const sendData = {
            appointmentID: this.state.appointment_id,
            updatedBy: this.props.doctorDetails.userID
        }
        axios.patch(`${serverLink}appointment/consultationFinish`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    window.location.href = '/doctor/consultation'
                }
            })
            .catch(error => {
                console.log('NETWORK ERROR FINISH CONSULTATION', error)
            })
    }

    handleDrugQuantityChange = (e) => {
        this.setState({
            drugDose: parseInt(e.target.value)
        })
    }

    //================= SERVICES =====================
    onOpenServiceModal = () => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openServiceDialog: true
            });
        }

    }

    closeServiceDialog = () => {
        this.setState({
            openServiceDialog: false,
        });
        window.location.reload();
    };

    getServicesList = () => {
        axios.get(`${serverLink}service/request/patient/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    servicesList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    //=============== END SERVICES ==================


    openOnlineConsultationDialog  = () => {
        this.setState({
            openOnlineConsultationDialog: true,
        });
    }

    closeOnlineConsultationDialog = () => {
        this.setState({
            openOnlineConsultationDialog: false,
        });
        // window.location.reload();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleChange = (date) => {
        if (date !== null) {
            const ConsultationDate = new Date(date);

            this.setState({
                formData: {
                    ...this.state.formData,
                    consultationDate: `${ConsultationDate.getFullYear()}-${ConsultationDate.getMonth() + 1}-${ConsultationDate.getDate()}`,
                },
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    consultationDate: date,
                },
            });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        let sendData = {
            ...this.state.formData,
            hospitalCode: hospitalCode,
            patientSerial: this.state.patientSerial,
            patientName: this.state.patient,
            patientEmail: this.state.patientEmail,
            appointmentID: this.state.appointment_id,
            submittedBy: this.props.doctorDetails.userID
        };


        if(this.state.formData.consultationLink === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the consultation link"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.consultationDate === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please select the consultation date"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.consultationTime === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the consultation time"
            this.handleErrorDialog();
            return false;
        }

        axios.post(`${serverLink}appointment/consultation/online/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "error") {
                    this.state.messageDescription = "Something went wrong sending the consultation link. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.closeOnlineConsultationDialog();
                    this.state.messageDescription = "Online Consultation Link Added";
                    this.state.messageDescription = "CONSULTATION LINK WAS SENT SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else if (response === "exist"){
                    this.closeOnlineConsultationDialog();
                    this.state.messageDescription = "Consultation Link Has Already Sent For This Appointment";
                    this.handleErrorDialog();
                } else {
                    this.state.messageDescription = "Failed to send the consultation link!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    DeleteLink = (e) => {
        this.setState({
            deleteMessage: "Are you sure you want delete this consultation link?",
            openAreYouSure: true,
            deleteID: e.appointmentID
        })
    }

    handleDelete = () => {
        const sendData = {
            patientSerial: this.state.patientSerial,
            appointmentID: this.state.deleteID
        };

        axios.post(`${serverLink}appointment/delete-consultation-link`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if(result.data.message === 'success') {
                    this.setState({
                        openAreYouSure: false
                    })
                    this.getAppointmentData();

                }
            })
    }

    closeDeleteDialog = () => {
        this.setState({
            openAreYouSure: false,
        });
    };

    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
        this.getAppointmentData();
    };

    //=============== CAPTURE VITALS==================
    handleVitalActionClicked = () => {
        this.setState({
            openVitalDialog: true
        })
    }
    closeVitalDialog = () => {
        this.setState({
            openVitalDialog: false,
        });
    };

    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
    };

    closeErrorDialog = () => {
        this.setState({
            openOnlineConsultationDialog: false,
            openErrorDialog: false,
            onOpenCancelModal: false,
            onOpenCancelPrescriptionModal: false,
            onOpenImagingCancelModal: false
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openOnlineConsultationDialog: false,
            openErrorDialog: true,
            openAllergyDialog: false,
        });
    };

    onOpenICD11Note = () => {
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openICD11Note: true
            });
        }
    }

    handleAllergyActionClicked = () => {
        this.setState({
            openAllergyDialog: true
        });
    }

    closeAllergyImageDialog = () => {
        this.setState({
            openShowAllergyImageDialog: false,
        });
    };

    closeAllergyDialog = () => {
        this.setState({
            openAllergyDialog: false,
        });
    };

    handleSuccessDialogPopup = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAllergyDialog: false,
            openAlertDialog: true,
        });
        this.getAppointmentData();
    };

    handleOnCancelRequestClick = (row) => {
        this.setState({
            onOpenCancelModal: true,
            cancelData: row
        })
    }

    handleOnCancelRequest = async () => {
        this.setState({
            cancelMessage: <span className={"alert alert-info"}>Cancelling Request... Please wait!</span>
        });

        const sendData = {id: this.state.cancelData, updatedBy: this.props.doctorDetails.userID}
        await axios.patch(`${serverLink}laboratory/test/request/cancel`, sendData, this.props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        cancelMessage: <span className={"alert alert-success"}>Request Cancelled Successfully</span>
                    });
                    this.getPatientLabTest();
                    const object = this;
                    setTimeout(function () {
                        object.closeErrorDialog();
                    }, 1000)

                } else {
                    this.setState({
                        cancelMessage: <span className={"alert alert-danger"}>Something went wrong cancelling request. Please check your network and try again!</span>
                    });
                }
            })
            .catch()
    }


    handleOnCancelPrescriptionRequestClick = (prescription, item) => {
        this.setState({
            onOpenCancelPrescriptionModal: true,
            cancelPrescriptionData: prescription,
            cancelPrescriptionItem: item,
        })
    }
    handleOnCancelPrescriptionRequest = async () => {
        const sendData = this.state.cancelPrescriptionData;
        const itemData = this.state.cancelPrescriptionItem;
        let filteredItems = sendData.prescriptionDetail.filter(e => e.drugName !== itemData.drugName);
        let prescriptionAmount = 0;
        let formData = {
            ...sendData,
            id: sendData._id,
            cartItems: [],
            updatedBy: this.props.doctorDetails.userID
        };

        filteredItems.push({
            drugName: itemData.drugName,
            drugQuantity: parseInt(itemData.drugQuantity),
            drugAmount: itemData.drugAmount,
            drugDose: itemData.drugDose,
            doseFrequency: itemData.doseFrequency,
            prescription: itemData.prescription,
            status: "canceled",
        })
        filteredItems.map(e => { formData.cartItems.push(e) })
        filteredItems.filter(e=>e.status !== "canceled").map((item, index) => {
            prescriptionAmount +=+ parseFloat(item.drugAmount) * parseInt(item.drugQuantity);
        })

        let fd = {  ...formData, prescriptionAmount: prescriptionAmount, paymentStatus: prescriptionAmount === 0 ? "canceled" : "unpaid"}

        this.setState({
            cancelPrescriptionMessage: <span className={"alert alert-info"}>Cancelling Prescription... Please wait!</span>
        });

        await axios.patch(`${serverLink}pharmacy/prescription/items/update`, fd, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.setState({cancelPrescriptionMessage: <span className={"alert alert-success"}>Prescription Cancelled Successfully</span>});
                    this.getPatientPrescription();
                    const object = this;
                    setTimeout(function () {
                        object.closeErrorDialog();
                    }, 1000)
                } else {
                    this.setState({
                        cancelPrescriptionMessage: <span className={"alert alert-danger"}>Something went wrong cancelling prescription.</span>
                    });
                }
            })
            .catch(err => {
                this.setState({
                    cancelPrescriptionMessage: <span className={"alert alert-danger"}>Something went wrong cancelling prescription. Please check your network and try again!</span>
                });
                console.error('ERROR', err);
            });

    }

    handleOnCancelImagingRequestClick = (row) => {
        this.setState({
            onOpenImagingCancelModal: true,
            cancelImagingData: row
        })
    }

    handleOnCancelImagingRequest = async () => {
        this.setState({
            cancelImagingMessage: <span className={"alert alert-info"}>Cancelling Request... Please wait!</span>
        });

        const sendData = {id: this.state.cancelImagingData._id, imageAmount: this.state.cancelImagingData.price,
            patientSerial: this.state.cancelImagingData.patientSerial,
            paymentStatus: typeof this.state.cancelImagingData.paymentStatus !== "undefined" ? this.state.cancelImagingData.paymentStatus : 'unpaid',
            updatedBy: this.props.doctorDetails.userID}
        await axios.patch(`${serverLink}imaging/request/cancel`, sendData, this.props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        cancelImagingMessage: <span className={"alert alert-success"}>Imaging Request Cancelled Successfully</span>
                    });
                    this.fetchImagingRequest();
                    const object = this;
                    setTimeout(function () {
                        object.closeErrorDialog();
                        object.setState({
                            cancelImagingMessage: "Are you sure you want to cancel the selected image request?",
                            cancelImagingData: ""

                        })
                    }, 1000)

                } else {
                    this.setState({
                        cancelImagingMessage: <span className={"alert alert-danger"}>Something went wrong cancelling request. Please check your network and try again!</span>
                    });
                }
            })
            .catch()
    }

    handlePrint = () => {
        if (this.printComponent.current) {
            this.printComponent.current.print(); // Check if printComponent is defined
        }
    };

    onPrintPage = () => {
        this.setState({ printPage: true }, () => {
            setTimeout(() => {
                this.handlePrint();
                document.getElementById('printing').click();
                this.setState({ printPage: false });
            }, 1000);
        });
    };

    // REFERRAL =========================================
    onOpenReferral = () => {
        document.getElementById('ReferralForm').reset();
        if (this.state.appointmentData.appointmentStatus !== 'Completed') {
            this.setState({
                openReferralModal: true,
                prescriptionError: false,
                prescriptionSuccess: false,
                prescriptionSubmitting: false,
            });
        }
    }

    onDoctorReferralChange = (event) => {
        const ref_id = event.target.id;
        const val = event.target.value;
        if (ref_id === 'doctorID') {
            const doctor_filter = this.state.doctorList.filter(r => r.userID === val);
            const doctor_name = doctor_filter.length > 0 ? `${doctor_filter[0].firstName} ${doctor_filter[0].middleName} ${doctor_filter[0].surname}` : '';
            this.setState({referralDoctorID: val, referralDoctorName: doctor_name})
        }
        else
            this.setState({referralDoctorNote: val})
    }
    submitReferral = (event) => {
        event.preventDefault();
        this.setState({
            prescriptionError: false,
            prescriptionSuccess: false,
            prescriptionSubmitting: true,
        })
        const note = document.getElementById('referralNote').value;

        const checker = typeof this.state.appointmentData.referralDoctorID1 === 'undefined' ||
            this.state.appointmentData.referralDoctorID1 === '' ? 1 : 2
        const sendData = {
            appointmentID: this.state.appointment_id,
            refID: checker,
            referralBy: this.props.doctorDetails.fullName,
            referralDoctorID: this.state.referralDoctorID,
            referralDoctorName: this.state.referralDoctorName,
            referralNote: note
        }

        axios.patch(`${serverLink}appointment/referral/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                const response = result.data.message;
                if (response === 'success') {
                    this.setState({
                        prescriptionSuccess: true,
                        prescriptionSubmitting: false,
                    });

                    const object = this;
                    setTimeout(function () {
                        window.location.reload();
                    },2000)

                } else {
                    this.setState({
                        prescriptionError: true,
                        prescriptionSuccess: false,
                        prescriptionSubmitting: false,
                    });
                }
            })
            .catch(error => {
                console.log('REFERRAL NOT ADDED', error)
                this.setState({
                    prescriptionError: true,
                    prescriptionSubmitting: false,
                })
            })
    }
    onApproveReferral = (status, item) => {
        const sendData = {
            appointmentID: this.state.appointment_id,
            refID: item,
            referralStatus: status
        }

        axios.patch(`${serverLink}appointment/referral/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                const response = result.data.message;
                if (response === 'success') {
                    this.setState({
                        prescriptionSuccess: true,
                        prescriptionSubmitting: false,
                    });

                    const object = this;
                    setTimeout(function () {
                        window.location.reload();
                    },2000)

                } else {
                    this.setState({
                        prescriptionError: true,
                        prescriptionSuccess: false,
                        prescriptionSubmitting: false,
                    });
                }
            })
            .catch(error => {
                console.log('CONSULTATION TASK NOT ADDED', error)
                this.setState({
                    prescriptionError: true,
                    prescriptionSubmitting: false,
                })
            })
    }


    render() {
        let labIncurredTotal = 0;
        let prescriptionIncurredTotal = 0;
        let imagingIncurredTotal = 0;
        let appointmentDate = this.state.appointmentData.appointmentDate;
        let patientData = {patientSerial: this.state.patientSerial, firstName: this.state.patient, middleName: '', surname: ''}

        const editorConfig = {
            readonly: false,
            height: 200
        };

        const editorConfigNurseObservation = {
            readonly: false,
            height: 200
        };

        let columns = this.state.columns;
        let data = this.state.drugList;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search drugs',
            "export": false
        };

        columns = this.state.labTestColumns;
        data = this.state.labTestList;
        const labTableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search Test',
            "export": false
        };

        let referral_blocker = typeof this.state.appointmentData.referralDoctorID1 === 'undefined' || typeof this.state.appointmentData.referralDoctorID2 === 'undefined' ? true :
            this.state.appointmentData.referralStatus1 !== 'approved' || this.state.appointmentData.referralStatus2 !== 'approved'
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <FormPrompt
                    openDialog={this.state.openReferralModal}
                    title="REFERRAL"
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                    isFullWidth={true}
                >
                    <form onSubmit={this.submitReferral} id="ReferralForm">
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Successful</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Please Try Again!</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="doctorID">Select Doctor</label>
                                <select name="doctorID" id="doctorID" className="form-control" required={true}
                                        defaultValue={this.state.referralDoctorID}
                                        onChange={this.onDoctorReferralChange}>
                                    <option value="">Select Doctor</option>
                                    {
                                        this.state.doctorList.length > 0 &&
                                        this.state.doctorList.map((r, i) => {
                                            return <option key={i}
                                                           value={r.userID}>{`${r.firstName} ${r.middleName} ${r.surname} (${r.userID})`}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="referralNote">Write a note</label>
                                <input type="text" className="form-control" placeholder="Write a referral note here" required={true}
                                       id="referralNote"/>
                            </div>
                        </div>
                        <hr/>
                        {
                            referral_blocker ?
                                <button className="btn btn-primary btn-block">Add Referral</button> :
                                <p className="alert alert-danger">You have exhausted the referral limit</p>
                        }
                    </form>
                    <hr/>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Doctor</th>
                                    <th>Note</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                typeof this.state.appointmentData.referralDoctorName1 !== 'undefined' && this.state.appointmentData.referralDoctorName1 !== '' &&
                                <tr>
                                    <td>1</td>
                                    <td> { this.state.appointmentData.referralDoctorName1 } ({this.state.appointmentData.referralDoctorID1})</td>
                                    <td>{this.state.appointmentData.referralNote1}</td>
                                    <td>{this.state.appointmentData.referralStatus1}</td>
                                    <td>
                                        {
                                            this.props.permissionDetails[0].groupName !== 'Doctor' &&
                                            this.state.appointmentData.referralStatus1 !== 'approved' ?
                                            <>
                                            <button type="button" className="btn btn-success btn-sm" onClick={() => this.onApproveReferral('approved',1)}>Approve</button>
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => this.onApproveReferral('rejected', 1)}>Rejected</button>
                                            </> : '--'
                                        }
                                    </td>
                                </tr>
                            }
                            {
                                typeof this.state.appointmentData.referralDoctorName2 !== 'undefined' && this.state.appointmentData.referralDoctorName2 !== '' &&
                                <tr>
                                    <td>2</td>
                                    <td> { this.state.appointmentData.referralDoctorName2 } ({this.state.appointmentData.referralDoctorID2})</td>
                                    <td>{this.state.appointmentData.referralNote2}</td>
                                    <td>{this.state.appointmentData.referralStatus2}</td>
                                    <td>
                                        {
                                            this.props.permissionDetails[0].groupName !== 'Doctor' &&
                                            this.state.appointmentData.referralStatus2 !== 'approved' ?
                                                <>
                                                <button type="button" className="btn btn-success btn-sm" onClick={() => this.onApproveReferral('approved',2)}>Approve</button>
                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => this.onApproveReferral('rejected', 2)}>Reject</button>
                                                </> : '--'
                                        }
                                    </td>
                                </tr>
                            }

                            </tbody>
                        </table>
                    </div>

                </FormPrompt>

                <ConfirmDialogBox
                    openDailog={this.state.onOpenImagingCancelModal}
                    onSetOpenDialog={this.closeErrorDialog}
                    handleConfirmOkBtn={this.handleOnCancelImagingRequest}
                    isLoading={false}
                    isDecision={true}
                    title="CANCEL IMAGING REQUEST?"
                    des={this.state.cancelImagingMessage}
                />
                <ConfirmDialogBox
                    openDailog={this.state.onOpenCancelPrescriptionModal}
                    onSetOpenDialog={this.closeErrorDialog}
                    handleConfirmOkBtn={this.handleOnCancelPrescriptionRequest}
                    isLoading={false}
                    isDecision={true}
                    title="CANCEL PRESCRIPTION?"
                    des={this.state.cancelPrescriptionMessage}
                />
                <ConfirmDialogBox
                    openDailog={this.state.onOpenCancelModal}
                    onSetOpenDialog={this.closeErrorDialog}
                    handleConfirmOkBtn={this.handleOnCancelRequest}
                    isLoading={false}
                    isDecision={true}
                    title="CANCEL REQUEST?"
                    des={this.state.cancelMessage}
                />

                <ConfirmDialogBox
                    openDailog={this.state.openAreYouSure}
                    onSetOpenDialog={this.closeDeleteDialog}
                    title="Are you sure"
                    des={this.state.deleteMessage}
                    handleConfirmOkBtn={this.handleDelete}
                >

                </ConfirmDialogBox>

                <AlertDialogBox
                    openDailog={this.state.openAlertDialog}
                    onSetOpenDailog={this.closeAlertDialog}
                    title={this.state.messageTitle}
                    des={this.state.messageDescription}
                />


                <ErrorDialogBox
                    openDialog={this.state.openErrorDialog}
                    onSetOpenDialog={this.closeDialog}
                    title={this.state.messageTitle}
                    des={this.state.messageDescription}
                />

                <FormPrompt
                    openDialog={this.state.openVitalDialog}
                    title="CAPTURE VITALS"
                    onSetOpenDialog={this.closeVitalDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <CaptureVital
                        setCloseBtnAppear={this.setCloseBtnAppear}
                        handleSuccessDialog={this.handleSuccessDialog}
                        handleErrorDialog={this.handleErrorDialog}
                        patientData={this.state.patientData}
                        patientAppointment={[]}
                    />
                </FormPrompt>

                <ConfirmDialogBox
                    openDailog={this.state.openFinishDialog}
                    onSetOpenDialog={this.closeDialog}
                    handleConfirmOkBtn={this.onFinishConsultation}
                    isLoading={false}
                    title="CLOSE CASE?"
                    des={ "Are you sure you want to mark this consultation as closed?" }
                />

                <FormPrompt
                    openDialog={this.state.openOnlineConsultationDialog}
                    title="Online Consultation"
                    onSetOpenDialog={this.closeOnlineConsultationDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <OnlineConsultationForm
                        handleSubmit={this.handleSubmit}
                        onEdit={this.onEdit}
                        date={this.state.date}
                        patientSerial={this.state.patientSerial}
                        patientName={this.state.patient}
                        data={this.state.formData}
                        handleChange={this.handleChange}
                    />
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openShowAllergyImageDialog}
                    title="Allergy Reaction Image"
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <img srcSet={this.state.allergyImageSrc} alt="Allergy Image"/>
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openPainAssessmentModal}
                    title="PAIN ASSESSMENT FORM"
                    onSetOpenDialog={this.onClosePainAssessment}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <form onSubmit={this.onSubmitPainAssessment} id="AddPainAssessmentForm" className="text-center">
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Pain Assessment Taken Successfully</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Please add a comment and try again! / Something went wrong processing...</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }

                        <Rating
                            onClick={this.handlePainAssessmentRating}
                            ratingValue={this.state.painRating}
                            fillColorArray={['#02b324', '#8cbf96','#f5e342', '#e04a4a','#c40202']}
                            customIcons={[
                                {icon: <FaRegLaughBeam size={100} /> },
                                {icon: <FaSmile size={100} /> },
                                {icon: <FaRegMeh size={100} /> },
                                {icon: <FaFrown size={100} /> },
                                {icon: <FaSadCry size={100} /> }
                            ]}
                        />

                        <h3>{this.state.painRatingName}</h3>

                        <div className="form-group">
                            <label htmlFor="">Comment (Optional)</label>
                            <input type="text" className="form-control" onChange={this.onEditPainComment} placeholder={"Enter patient comment here"} />
                        </div>

                        <hr/>

                        <button className="btn btn-primary btn-block" type={"submit"}>Submit</button>
                    </form>
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openNurseAssessmentModal}
                    title="NURSE ASSESSMENT FORM"
                    onSetOpenDialog={this.onCloseNurseAssessment}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                    isFullWidth={true}
                >
                    <h4>Prescriptions</h4>
                    {
                        this.state.prescription.length > 0 ?
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Drug Name</th>
                                    <th>Quantity</th>
                                    <th>Dose</th>
                                    <th>Prescription</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.prescription[0].prescriptionDetail.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{item.drugName}</td>
                                            <td>{item.drugQuantity}</td>
                                            <td>{item.drugDose} / {item.doseFrequency}</td>
                                            <td>{item.prescription !== '' ? item.prescription : '--' }</td>
                                            <td>
                                                <button
                                                    onClick={() => {this.onOpenNurseAssessmentSubmission(item, index)}}
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    <i className="fa fa-plus" aria-hidden="true" />
                                                </button>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table> :
                            <div className="p-3">
                                <span className="alert alert-info">No Prescription Found</span>
                            </div>
                    }


                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openNurseAssessmentSubmissionModal}
                    title={this.state.nurseSelectedDrug.drugName}
                    onSetOpenDialog={this.onCloseNurseAssessmentSubmission}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <table className="table table-hover">
                        <tbody>
                        <tr>
                            <th>Drug</th>
                            <td>{this.state.nurseSelectedDrug.drugName}</td>
                        </tr>
                        <tr>
                            <th>Quantity</th>
                            <td>{this.state.nurseSelectedDrug.drugQuantity}</td>
                        </tr>
                        <tr>
                            <th>Dose</th>
                            <td>{this.state.nurseSelectedDrug.drugDose} / {this.state.nurseSelectedDrug.doseFrequency}</td>
                        </tr>
                        <tr>
                            <th>Prescription</th>
                            <td>
                                {
                                    this.state.nurseSelectedDrug.prescription !== '' ?
                                        this.state.nurseSelectedDrug.prescription :
                                        '--'
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <form onSubmit={this.onSubmitNurseAssessment}>
                        <div className="form-row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Time taken</label>
                                    <select className="custom-select" value={this.state.nurseSelectedDrugTimeTaken} required name={"timeTaken"} onChange={this.onEditNurseAssessment}>
                                        <option value="">Select Option</option>
                                        <option value="Morning">Morning</option>
                                        <option value="Afternoon">Afternoon</option>
                                        <option value="Evening">Evening</option>
                                        <option value="Night">Night</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Dose Given</label>
                                    <select required name="doseGiven" value={this.state.nurseSelectedDrugDoseGiven} className="custom-select" onChange={this.onEditNurseAssessment}>
                                        <option value="">Select Option</option>
                                        <option value="0.5">1/2</option>
                                        {
                                            this.state.nurseSelectedDrug.drugDose > 0 ?
                                                [...Array(this.state.nurseSelectedDrug.drugDose)].map((dose, index) => {
                                                    return <option key={index} value={index+1}>{index+1}</option>
                                                })
                                                : ''
                                        }
                                        <option value="0.2ML">0.2ML</option>
                                        <option value="0.5ML">0.5ML</option>
                                        <option value="1ML">1ML</option>
                                        <option value="1.5ML">1.5ML</option>
                                        <option value="2ML">2ML</option>
                                        <option value="5ML">5ML</option>
                                        <option value="10ML">10ML</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Assessment Taken Successfully</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Something went wrong processing...</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }
                        <div className="form-row">
                            <button className="btn btn-block btn-primary" type="submit">Submit</button>
                        </div>
                    </form>
                    {
                        this.state.prescription.length > 0 ?
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Time Given</th>
                                    <th>Dose Given</th>
                                    <th>Administered By</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    typeof this.state.prescription[0].prescriptionDetail[this.state.nurseSelectedDrugIndex].assessment !== "undefined" &&
                                    this.state.prescription[0].prescriptionDetail[this.state.nurseSelectedDrugIndex].assessment.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{item.timeTaken}</td>
                                            <td>{item.doseGive}</td>
                                            <td>{item.submittedBy}</td>
                                            <td>{item.submittedDate }</td>

                                        </tr>
                                    })
                                }
                                </tbody>
                            </table> :
                            <div className="p-3">
                                <span className="alert alert-info">No Assessment Found</span>
                            </div>
                    }


                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openDoctorNote}
                    title="Doctor Note"
                    isFullWidth={true}
                    onSetOpenDialog={this.submitConsultationNote}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <h3>Subjective</h3>
                    <JoditEditor
                        value={this.state.doctorNote1}
                        config={editorConfig}
                        onBlur={this.doctorNote1Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>
                    <h3>Objective</h3>
                    <JoditEditor
                        value={this.state.doctorNote2}
                        config={editorConfig}
                        onBlur={this.doctorNote2Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>
                    <h3>Assessment</h3>
                    <JoditEditor
                        value={this.state.doctorNote3}
                        config={editorConfig}
                        onBlur={this.doctorNote3Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>
                    <h3>Other 1</h3>
                    <JoditEditor
                        value={this.state.doctorNote4}
                        config={editorConfig}
                        onBlur={this.doctorNote4Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>
                    <h3>Other 2</h3>
                    <JoditEditor
                        value={this.state.doctorNote5}
                        config={editorConfig}
                        onBlur={this.doctorNote5Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>Other 3</h3>
                    <JoditEditor
                        value={this.state.doctorNote6}
                        config={editorConfig}
                        onBlur={this.doctorNote6Handler}
                        onChange={(newContent) => {}}
                    />
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openNurseObservationNote}
                    title="Nurse Note"
                    isFullWidth={true}
                    onSetOpenDialog={this.submitNurseObservationNote}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <h3>OBSERVATION NOTE</h3>
                    <JoditEditor
                        value={this.state.nurseObservationNote1}
                        config={editorConfigNurseObservation}
                        onBlur={this.nurseObservationNote1Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>MORNING SHIFT REPORT</h3>
                    <JoditEditor
                        value={this.state.nurseObservationNote2}
                        config={editorConfigNurseObservation}
                        onBlur={this.nurseObservationNote2Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>AFTERNOON SHIFT REPORT</h3>
                    <JoditEditor
                        value={this.state.nurseObservationNote3}
                        config={editorConfigNurseObservation}
                        onBlur={this.nurseObservationNote3Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>NIGHT SHIFT REPORT</h3>
                    <JoditEditor
                        value={this.state.nurseObservationNote4}
                        config={editorConfigNurseObservation}
                        onBlur={this.nurseObservationNote4Handler}
                        onChange={(newContent) => {}}
                    />
                </FormPrompt>

                <FormPrompt
                    style={{ position: 'relative', zIndex: '3' }}
                    openDialog={this.state.openAddDrugModal}
                    title="Prescribe Drug Form"
                    onSetOpenDialog={this.closeAddDrugDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <form onSubmit={this.prescribeDrug} id="PrescriptionForm">
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Drug Prescribed Successfully</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Drug Not Prescribed. Please Try Again!</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }
                        <div className="form-row">
                            <label htmlFor="drugName">Drug Name</label>
                            <input type="text" className="form-control" readOnly={true} value={this.state.drugName}/>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="drugPrice">Drug Price</label>
                                <input type="number" className="form-control" readOnly={true} value={this.state.drugPrice}/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="drugQuantity">Drug Quantity</label>
                                <input type="number" className="form-control" readOnly={true} value={this.state.drugQuantity}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="prescriptionQuantity">Prescription Quantity</label>
                                <input type="number" required id="prescriptionQuantity" name="prescriptionQuantity" onChange={this.handleDrugQuantityChange} className="form-control" min={1} max={this.state.drugQuantity} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="prescriptionDose">Dose</label>
                                <select required name="prescriptionDose" id="prescriptionDose" className="custom-select">
                                    <option value="">Select Option</option>
                                    <option value="1/2">1/2</option>
                                    {
                                        this.state.drugDose > 0 ?
                                            [...Array(this.state.drugDose)].map((dose, index) => {
                                                return <option key={index} value={index+1}>{index+1}</option>
                                            })
                                            : ''
                                    }
                                </select>
                            </div>

                            <div className="col-6">
                                <label htmlFor="prescriptionFrequency">Frequency</label>
                                <select required name="prescriptionFrequency" id="prescriptionFrequency" className="custom-select">
                                    <option value="">Select Option</option>
                                    <option value="Every 4 Hours">Every 4 Hours</option>
                                    <option value="Every 6 Hours">Every 6 Hours</option>
                                    <option value="Every 8 Hours">Every 8 Hours</option>
                                    <option value="Every 12 Hours">Every 12 Hours</option>
                                    <option value="Once">Once</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Annually">Annually</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="prescriptionDetail">Prescription Details</label>
                                <input type="text" id="prescriptionDetail" placeholder="1 tablet a day, duration, etc." name="prescriptionDetail" className="form-control" defaultValue={this.state.prescriptionDetail}/>
                            </div>
                        </div>
                        <hr/>

                        <button className="btn btn-primary btn-block">Prescribe</button>
                    </form>

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openPrescriptionModal}
                    title="Drug List"
                    isFullWidth={true}
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="drugName"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openLabTestModal}
                    title="Lab Test List"
                    isFullWidth={true}
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >

                    {
                        this.state.labTestMessage === 'success' ?
                            <p className="text-center alert alert-success font-weight-bold">Test Added Successfully</p> : ''
                    }

                    {
                        this.state.labTestMessage === 'submitting' ?
                            <p className="text-center alert alert-info font-weight-bold">Please wait... Adding test to patient!</p> : ''
                    }

                    {
                        this.state.labTestMessage === 'failed' ?
                            <p className="text-center alert alert-danger font-weight-bold">Something went wrong adding the test. Please try again!</p> : ''
                    }

                    {
                        this.state.labTestMessage === 'exist' ?
                            <p className="text-center alert alert-warning font-weight-bold">The test you're trying to add was already added</p> : ''
                    }

                    <DataTableExtensions
                        {...labTableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="testName"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openDoctorTask}
                    title="Add TASK"
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <form onSubmit={this.submitConsultationTask} id="AddTaskForm">
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Task Added Successfully</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Task Not Added. Please Try Again!</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }
                        <div className="form-row">
                            <label htmlFor="doctorTask">Add Clinical Task</label>
                            <input type="text" id="doctorTask" required className="form-control" placeholder="Enter the clinical task here" defaultValue={this.state.doctorTask}/>
                        </div>
                        <hr/>

                        <button className="btn btn-primary btn-block">Add Task</button>
                    </form>
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openImageModal}
                    title="IMAGING REQUEST"
                    onSetOpenDialog={this.onImageModalClose}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <PatientImagingRequest patientSerial={this.state.patientData.patientSerial} patientName={`${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`} requestType={"consultation"} requestID={this.state.appointment_id} />
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openServiceDialog}
                    title="SERVICES"
                    onSetOpenDialog={this.closeServiceDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                    isFullWidth={true}
                >
                    <ServiceRequest patient_id={this.state.appointmentData.patientSerial} patient_name={this.state.appointmentData.patientName} patient_wallet={this.state.walletAmount} money_format={this.moneyFormat} />

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openICD11Note}
                    title="Diagnosis (ICD 11)"
                    onSetOpenDialog={this.closeICDCodeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                    // isFullWidth={true}
                >
                    {/*<IcdComponent />*/}
                    <ECTReactComponent
                        patientSerial={this.state.patientSerial}
                        patientName={`${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`}
                        action_id={this.state.appointment_id}
                        type={'consultation'}
                    />
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openAllergyDialog}
                    title="Add New Allergy"
                    onSetOpenDialog={this.closeAllergyDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <AddAllergy
                        setCloseBtnAppear={this.setCloseBtnAppear}
                        handleSuccessDialog={this.handleSuccessDialogPopup}
                        handleErrorDialog={this.handleErrorDialog}
                        patientData={patientData}
                    />
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openAllergyDialog}
                    title="Add New Allergy"
                    onSetOpenDialog={this.closeAllergyDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <AddAllergy
                        setCloseBtnAppear={this.setCloseBtnAppear}
                        handleSuccessDialog={this.handleSuccessDialogPopup}
                        handleErrorDialog={this.handleErrorDialog}
                        patientData={patientData}
                    />
                </FormPrompt>

                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Consultation Page"/>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="card">
                                <div className="card-header">
                                    <strong
                                        className="text-secondary mb-1">{this.state.patientData.patientSerial}</strong>
                                </div>
                                <div className="d-flex flex-column align-items-center text-center">
                                    <div className="profileimage">
                                        <div className="patientlistpage mt-2">
                                            <img
                                                className="patient_passport img-fluid"
                                                srcSet={imageExists(`${serverLink}public/uploads/patient_passport/${this.state.patientData.passport}`) ? `${serverLink}public/uploads/patient_passport/${this.state.patientData.passport}` : this.state.patientData.gender === 'Male' ? doctor : woman_doctor}
                                                alt="profileImage"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <h4>{this.state.patientData.firstName} {this.state.patientData.middleName} {this.state.patientData.surname}</h4>
                                        <p className="text-muted font-size-sm">Last visit: {
                                            this.formatDate(this.state.patientData.lastVisit)
                                        }</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={this.props.userType === "service-desk" ? 'col-md-10' : `col-sm-4`}>
                            <div className="card mb-3">
                                <div className="card-header">Appointment</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Department</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.appointmentData.appointmentDepartment}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Date</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.formatDate(this.state.appointmentData.appointmentDate)}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Slot</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.appointmentData.appointmentTime}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Date of Birth</h6>
                                        </div>
                                        <div className="col-sm-6 text-secondary">
                                            {this.state.patientData.dateOfBirth ? this.formatDate(this.state.patientData.dateOfBirth) +  `  (${calculateAge(this.state.patientData.dateOfBirth)}  ${calculateAge(this.state.patientData.dateOfBirth ) > 1 ?  "Years Old" : "Year Old"})` : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Blood Group</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.patientData.bloodGroup ? this.state.patientData.bloodGroup : 'N/A'}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {
                            this.props.userType === "service-desk" ?
                                <></>
                                :
                                <div className="col-sm-6">
                                    <div className="card mb-3">
                                        <div className="card-header">
                                            Request
                                        </div>
                                        <div className="row">
                                            {
                                                this.props.userType === "nurse" ||  this.props.userType === "service-desk" ?
                                                    <></>
                                                    :
                                                    <>
                                                        <div className="col-6">
                                                            <PatientActionButton
                                                                handleClick={this.onOpenDoctorNote}
                                                                actionName="Doctor Note"
                                                                actionIcon="fa fa-pencil fa-4x text-secondary"
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <PatientActionButton
                                                                handleClick={this.onOpenICD11Note}
                                                                actionName="Diagnosis (ICD-11)"
                                                                actionIcon="fa fa-search fa-4x text-secondary"
                                                            />
                                                        </div>
                                                    </>
                                            }
                                            {this.props.userType === "doctor" || this.props.userType === "service-desk"  ? <></> : <div className="col-6">
                                                <PatientActionButton
                                                    handleClick={this.onOpenNurseObservationNote}
                                                    actionName="Nurse Note"
                                                    actionIcon="fa fa-edit fa-4x text-secondary"
                                                />
                                            </div> }
                                            {
                                                this.props.userType === "nurse" ?
                                                    <></>
                                                    :
                                                    <>
                                                        <div className="col-6">
                                                            <PatientActionButton
                                                                handleClick={this.onOpenPrescriptionModal}
                                                                actionName="Prescription"
                                                                actionIcon="fa fa-prescription-bottle-alt fa-4x text-success"
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <PatientActionButton
                                                                handleClick={this.onOpenLabTestModal}
                                                                actionName="Lab"
                                                                actionIcon="fa fa-vial fa-4x text-success"
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <PatientActionButton
                                                                handleClick={this.onOpenImageModal}
                                                                actionName="Imaging"
                                                                actionIcon="fa fa-photo fa-4x text-primary"
                                                            />
                                                        </div>
                                                    </>
                                            }

                                            <div className="col-md-6">
                                                <PatientActionButton
                                                    handleClick={this.handleAllergyActionClicked}
                                                    actionName="Add Allergy"
                                                    actionIcon="fa fa-medkit fa-2x text-info"
                                                />
                                            </div>

                                            <div className="col-6">
                                                <PatientActionButton
                                                    handleClick={this.onOpenDoctorTask}
                                                    actionName="Task"
                                                    actionIcon="fa fa-tasks fa-4x text-primary"
                                                />
                                            </div>
                                            {
                                                this.props.userType === "nurse" ?
                                                    <></>
                                                    :
                                                    <div className="col-6">
                                                        <Link to={`/admission/patient/${this.state.patientData._id}`} style={{ textDecoration: 'none' }}>
                                                            <PatientActionButton
                                                                actionName="Admit Patient"
                                                                actionIcon="fa fa-bed fa-4x text-info"
                                                            />
                                                        </Link>
                                                    </div>
                                            }
                                            <div className="col-6">
                                                <PatientActionButton
                                                    handleClick={this.onOpenServiceModal}
                                                    actionName="Service Request"
                                                    actionIcon="fa fa-cog fa-4x text-info"
                                                />
                                            </div>
                                            {this.props.userType === "doctor" || this.props.userType === "service-desk"   ? <></> :  <div className="col-md-6">
                                                <PatientActionButton
                                                    handleClick={this.handleVitalActionClicked}
                                                    actionName="Capture Vitals"
                                                    actionIcon="fa fa-vial fa-2x text-info"
                                                />
                                            </div> }
                                            {this.props.userType === "doctor" || this.props.userType === "service-desk"   ? <></> :  <div className="col-6">
                                                <PatientActionButton
                                                    handleClick={this.onOpenNurseAssessment}
                                                    actionName="Prescription Assessment"
                                                    actionIcon="fa fa-check fa-4x text-success"
                                                />
                                            </div> }
                                            {this.props.userType === "doctor" || this.props.userType === "service-desk"   ? <></> :  <div className="col-6">
                                                <PatientActionButton
                                                    handleClick={this.onOpenPainAssessment}
                                                    actionName="Pain Assessment"
                                                    actionIcon="fa fa-sad-tear fa-4x text-warning"
                                                />
                                            </div> }
                                            {
                                                this.props.userType === "nurse" ?
                                                    <></>
                                                    :
                                                    <>
                                                        <div className="col-6">
                                                            <PatientActionButton
                                                                handleClick={this.openOnlineConsultationDialog}
                                                                actionName="Online Consultation"
                                                                actionIcon="fa fa-user-md fa-4x text-warning"
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <PatientActionButton
                                                                handleClick={this.onOpenReferral}
                                                                actionName="Referral"
                                                                actionIcon="fa fa-user-md fa-4x text-secondary"
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <PatientActionButton
                                                                handleClick={this.openFinishDialog}
                                                                actionName={this.state.appointmentData.appointmentDepartment === "Accident and Emergency" ? "Discharge" : "Close Case"}
                                                                actionIcon="fa fa-clipboard-check fa-4x text-secondary"
                                                            />
                                                        </div>
                                                    </>
                                            }

                                        </div>
                                    </div>

                                </div>
                        }

                    </div>

                    <hr/>
                    <div className="second_section">
                        <Tabs>
                            <TabList>
                                <Tab>Encounters</Tab>
                                <Tab>Diagnosis</Tab>
                                <Tab>Doctor Note</Tab>
                                <Tab>Nurse Note</Tab>
                                <Tab>Allergens</Tab>
                                <Tab>Vitals</Tab>
                                <Tab>Vitals Report</Tab>
                                <Tab>Lab</Tab>
                                <Tab>Prescription</Tab>
                                <Tab>Nurse Assessment</Tab>
                                <Tab>Pain Assessment</Tab>
                                <Tab>Imaging</Tab>
                                <Tab>Clinical Tasks</Tab>
                                <Tab>Services</Tab>
                                <Tab>Amount Incurred</Tab>
                                <Tab>Online Consultation Link</Tab>
                            </TabList>

                            {/*Encounters*/}
                            <TabPanel>
                                {
                                    this.state.encounters.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Doctor</th>
                                                <th>Department</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.encounters.map((encounter, index) => {
                                                    return <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{this.formatDate(encounter.appointmentDate)}</td>
                                                        <td>{encounter.appointmentTime}</td>
                                                        <td>{encounter.doctorName}</td>
                                                        <td>{encounter.appointmentDepartment}</td>
                                                        <td>
                                                            <a href={`/${this.props.userType}/consultations/`+encounter._id} target="_blank">
                                                                <i className="fa fa-eye"/>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Previous Encounter Found</span>
                                        </div>
                                }

                            </TabPanel>

                            {/*ICD Code*/}
                            <TabPanel>
                                <ICDtable data={this.state.icd11List} userType={this.props.userType}  />
                            </TabPanel>

                            {/*Doctor Note*/}
                            <TabPanel>
                                <div>
                                    {
                                        this.state.visitNotes.length > 0 ?

                                            <Accordion>
                                                {
                                                    this.state.visitNotes.map((p, index) => {
                                                        return <AccordionItem key={index}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    {this.formatDateAndTime(p.submittedOn)}
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <h5>
                                                                    Doctor: {p.doctorName}
                                                                    {
                                                                        this.props.doctorDetails.userID === p.doctorID &&
                                                                        <button className="btn btn-sm btn-info float-right" onClick={()=> { this.setState({
                                                                            openDoctorNote: true,
                                                                            isNewNote: false,
                                                                            doctorNote1: p.note1, doctorNote2: p.note2, doctorNote3: p.note3,
                                                                            doctorNote4: p.note4, doctorNote5: p.note5, doctorNote6: p.note6,
                                                                            doctorNoteID: p._id
                                                                        }) }}>
                                                                            <span className="float-right">edit</span>
                                                                        </button>
                                                                    }
                                                                </h5>
                                                                <h4>NOTE</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Subjective</th>
                                                                            <th>Objective</th>
                                                                            <th>Assessment</th>
                                                                            <th>Other 1</th>
                                                                            <th>Other 2</th>
                                                                            <th>Other 3</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note1}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note2}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note3}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note4}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note5}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note6}} /></td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    })
                                                }

                                            </Accordion>
                                            :
                                            <div className="p-3">
                                                <span className="alert alert-info">No Previous Note Found</span>
                                            </div>
                                    }
                                </div>

                            </TabPanel>

                            {/*Nurse Observation Note*/}
                            <TabPanel>
                                {
                                    this.state.nurseObservationNotes.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.nurseObservationNotes.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                {this.formatDateAndTime(p.submittedOn)}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <h5>
                                                                Nurse: {p.doctorName}
                                                                {
                                                                    this.state.userType === "doctor" || this.state.userType === "service-desk"  ? <></> :
                                                                        <span className="float-right"><i className="fa fa-pencil" onClick={() => this.onOpenUpdateNurseObservationNote(p)} /></span>

                                                                }
                                                            </h5>
                                                            <h4>NOTE</h4>
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Observation Note</th>
                                                                        <th>Morning Shift Report</th>
                                                                        <th>Afternoon Shift Report</th>
                                                                        <th>Night Shift Report</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note1}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note2}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note3}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note4}} /></td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                })
                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Previous Note Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Allergens*/}
                            <TabPanel>
                                {
                                    this.state.allergy.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Name</th>
                                                <th>Last Attack Date</th>
                                                <th>Medication</th>
                                                <th>Duration Before Subsiding</th>
                                                <th>Description</th>
                                                <th>Image</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.allergy.map((allergy, index) => {
                                                    return <tr key={allergy._id}>
                                                        <td>{index+1}</td>
                                                        <td>{allergy.allergyName}</td>
                                                        <td>{this.formatDate(allergy.lastAttackDate)}</td>
                                                        <td>{allergy.allergyMedication}</td>
                                                        <td>{allergy.durationBeforeSubsiding}</td>
                                                        <td>{allergy.allergyDescription}</td>
                                                        <td>
                                                            {allergy.reactionImage === "" ? (
                                                                <i className="fa fa-file-excel fa-2x"></i>
                                                            ) : (
                                                                <img
                                                                    className="image img-fluid"
                                                                    width="50px"
                                                                    alt=""
                                                                    srcSet={!allergy.reactionImage || allergy.reactionImage?.includes('simplefileupload') ? allergy.reactionImage : serverLink + 'public/uploads/patient_allergy/'+allergy.reactionImage}
                                                                    onClick={this.handleViewAllergyImageClicked}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Previous Encounter Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Vitals*/}
                            <TabPanel>
                                {
                                    this.state.vitals.length > 0 ?
                                        <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <span className="badge badge-info">Last Vitals were taken on: {this.formatDateAndTime(this.state.vitals[0].submittedOn)}</span>
                                                </div>
                                                <div className="col-4">
                                                    <DashboardCard
                                                        name="Temperature"
                                                        icon="fa fa-thermometer-half fa-4x "
                                                        count={this.state.vitals[0].temperature +'°'+ this.state.vitals[0].temperature_unit} />
                                                </div>
                                                <div className="col-4">
                                                    <DashboardCard
                                                        name="BP"
                                                        icon="fa fa-heartbeat fa-4x "
                                                        count={this.state.vitals[0].bloodPressure} />
                                                </div>
                                                <div className="col-4">
                                                    <DashboardCard
                                                        name="Weight"
                                                        icon="fa fa-weight fa-4x "
                                                        count={this.state.vitals[0].weight + this.state.vitals[0].weight_unit} />
                                                </div>
                                                <div className="col-6">
                                                    <DashboardCard
                                                        name="Height"
                                                        icon="fa fa-balance-scale fa-4x "
                                                        count={this.state.vitals[0].height + this.state.vitals[0].height_unit} />
                                                </div>
                                                <div className="col-6">
                                                    <DashboardCard
                                                        name="Pulse"
                                                        icon="fa fa-balance-scale fa-4x "
                                                        count={this.state.vitals[0].pulse} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="wrap_chart">
                                                        <PatientChart title="Temperature" description="Patient Temperature Trend (C)" label={this.state.graph_label} data={this.state.temp_val}></PatientChart>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="wrap_chart">
                                                        <PatientBloodPressureChart title="Blood Pressure" description="Patient BP Trend" label={this.state.graph_label} data1_label="Systolic" data1={this.state.bp_systolic_val} data2_label="Diastolic" data2={this.state.bp_diastolic_val}></PatientBloodPressureChart>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="wrap_chart">
                                                        <PatientChart title="Height" description="Patient Height Trend (Meters)" label={this.state.graph_label} data={this.state.height_val}></PatientChart>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="wrap_chart">
                                                        <PatientChart title="Weight" description="Patient Weight Trend (KG)" label={this.state.graph_label} data={this.state.weight_val}></PatientChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Vital Was Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Vital List*/}
                            <TabPanel>
                                <PatientVitalList vitalList={this.state.vitalList} formatDate={this.formatDate}/>
                            </TabPanel>

                            {/*Lab*/}
                            <TabPanel>
                                {
                                    this.state.patientLabTestList.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.patientLabTestList.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                Test: {p.testName} ~ Date: {this.formatDateAndTime(p.requestDate)} ~ Status:
                                                                {
                                                                    <span className={p.testStatus === 'requested' ? 'badge badge-warning' :
                                                                        p.testStatus === 'specimen taken' ? 'badger badge-info' : p.testStatus === 'result entered' || p.testStatus === 'result confirmed'  ? 'badge badge-success' : 'badge badge-danger'
                                                                    }>{p.testStatus}</span>

                                                                }
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>Test ID</th>
                                                                    <td>{p.testID} => {p._id}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Test Name</th>
                                                                    <td>{p.testName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Test Status</th>
                                                                    <td>{p.testStatus}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Status</th>
                                                                    <td>{p.paymentStatus}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Requested By</th>
                                                                    <td>{p.doctorName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Requested Date</th>
                                                                    <td>{this.formatDateAndTime(p.requestDate)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Test Specimen</th>
                                                                    <td>{p.specimen[0].specimenName}</td>
                                                                </tr>
                                                                {
                                                                    p.testStatus !== "result confirmed" ?
                                                                        <tr>
                                                                            <th>Test Result</th>
                                                                            <td><span
                                                                                className="alert alert-info text-center">Result is not confirmed yet</span>
                                                                            </td>
                                                                        </tr> :
                                                                        <>
                                                                            <tr>
                                                                                <th>Test Result</th>
                                                                                <td>
                                                                                    {
                                                                                        p.resultEnteredName !== "" && typeof p.resultEnteredName !== "undefined" &&
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                <tr>
                                                                                                    <th>S/N</th>
                                                                                                    <th>Item</th>
                                                                                                    <th>SI Unit</th>
                                                                                                    <th>Reference Interval</th>
                                                                                                    <th>Result</th>
                                                                                                    <th>Decision</th>
                                                                                                </tr>
                                                                                                </thead>

                                                                                                <tbody>
                                                                                                {
                                                                                                    p.specimen[0].items.map((item, index) => {
                                                                                                        return <tr key={index}>
                                                                                                            <td>{(index+1)}</td>
                                                                                                            {
                                                                                                                item.ref_interval === 'single' ?
                                                                                                                    <>
                                                                                                                        <td>{item.item_name}</td>
                                                                                                                        <td>{item.si_unit}</td>
                                                                                                                        <td>{item.ref_interval_min} - {item.ref_interval_max}</td>
                                                                                                                        <td>{item.result}</td>
                                                                                                                        <td>
                                                                                                                            {
                                                                                                                                item.result_type === 'integer' ?
                                                                                                                                    item.result >= item.ref_interval_min && item.result <= item.ref_interval_max  ?
                                                                                                                                        <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                                        <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                                                    : '--'
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                    </> :
                                                                                                                    <>
                                                                                                                        <td>{item.item_name}</td>
                                                                                                                        <td>{item.si_unit}</td>
                                                                                                                        <td colSpan={3}>
                                                                                                                            {
                                                                                                                                item.ref_data.map((ref, index_val) => {
                                                                                                                                    if (ref.ref_counter === 'single') {
                                                                                                                                        return <div key={index_val} className="table-responsive"><table className="table table-striped">
                                                                                                                                            <thead>
                                                                                                                                            <tr>
                                                                                                                                                <th>Item</th>
                                                                                                                                                <th>Reference Interval</th>
                                                                                                                                                <th>Result</th>
                                                                                                                                                <th>Decision</th>
                                                                                                                                            </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td>{ref.ref_name}</td>
                                                                                                                                                <td>{ref.ref_interval_min} - {ref.ref_interval_max}</td>
                                                                                                                                                <td>{ref.result}</td>
                                                                                                                                                <td>
                                                                                                                                                    {
                                                                                                                                                        ref.result_type === 'integer' ?
                                                                                                                                                            ref.result >= ref.ref_interval_min && ref.result <= ref.ref_interval_max  ?
                                                                                                                                                                <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                                                                <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                                                                            : '--'
                                                                                                                                                    }
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table></div>

                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        return <div key={index_val}>
                                                                                                                                            <h4>{ref.ref_title}</h4>
                                                                                                                                            <div className="table-responsive"><table className="table table-striped">
                                                                                                                                                <thead>
                                                                                                                                                <tr>
                                                                                                                                                    <th>Item</th>
                                                                                                                                                    <th>Reference Interval</th>
                                                                                                                                                    <th>Result</th>
                                                                                                                                                    <th>Decision</th>
                                                                                                                                                </tr>
                                                                                                                                                </thead>
                                                                                                                                                <tbody>
                                                                                                                                                {
                                                                                                                                                    ref.ref_item.map((pp, ii) => {
                                                                                                                                                        return <tr key={ii}>
                                                                                                                                                            <td>{pp.ref_name}</td>
                                                                                                                                                            <td>{pp.ref_interval_min} - {pp.ref_interval_max}</td>
                                                                                                                                                            <td>{pp.result}</td>
                                                                                                                                                            <td>
                                                                                                                                                                {
                                                                                                                                                                    pp.result_type === 'integer' ?
                                                                                                                                                                        pp.result >= pp.ref_interval_min && pp.result <= pp.ref_interval_max  ?
                                                                                                                                                                            <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                                                                            <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                                                                                        : '--'
                                                                                                                                                                }
                                                                                                                                                            </td>

                                                                                                                                                        </tr>
                                                                                                                                                    })
                                                                                                                                                }

                                                                                                                                                </tbody>
                                                                                                                                            </table></div>
                                                                                                                                        </div>
                                                                                                                                    }

                                                                                                                                })
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                        <td></td>
                                                                                                                        <td></td>
                                                                                                                    </>
                                                                                                            }

                                                                                                        </tr>
                                                                                                    })
                                                                                                }
                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Consultant Comment</th>
                                                                                <td>{!p.description ?  '--' : <span dangerouslySetInnerHTML={{__html: p.description}}></span>}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Result Attachment</th>
                                                                                <td>{p.testAttachment ? <a className="btn btn-sm btn-primary" target="_blank" href={p.testAttachment}>View</a> : '--'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Result Date</th>
                                                                                <td>{p.resultEnteredDate ? this.formatDateAndTime(p.resultEnteredDate) : '--'}</td>
                                                                            </tr>
                                                                        </>
                                                                }
                                                                {
                                                                    this.props.userType === "nurse" ||  this.props.userType === "service-desk" ?
                                                                        <></>
                                                                        :
                                                                        p.testStatus === 'cancelled' || p.testStatus === 'result confirmed' || p.testStatus === 'result entered' ?
                                                                            <></>
                                                                            :
                                                                            <tr>
                                                                                <th>Action</th>
                                                                                <td><button className="btn btn-danger btn-sm"  onClick={() => {this.handleOnCancelRequestClick(p._id)}}>Cancel Request</button></td>
                                                                            </tr>
                                                                }

                                                                </thead>
                                                            </table>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>

                                                })

                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Lab Request Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Prescription*/}
                            <TabPanel>
                                {
                                    this.state.prescription.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.prescription.map((p, index) => {
                                                    let prescriptionTotal = 0;
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                Doctor: {p.doctorName} ~ Date: {this.formatDateAndTime(p.submittedDate)}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>S/N</th>
                                                                    <th>Drug Name</th>
                                                                    <th>Quantity</th>
                                                                    <th>Dose</th>
                                                                    <th>Prescription</th>
                                                                    <th>Status</th>
                                                                    <th>Unit Price</th>
                                                                    <th>Total</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    p.prescriptionDetail.map((d, index) => {
                                                                        if (d.status !== "canceled"){
                                                                            prescriptionTotal += d.drugAmount*d.drugQuantity;
                                                                        }
                                                                        return <tr key={index}>
                                                                            <td>{index+1}</td>
                                                                            <td>{d.drugName}</td>
                                                                            <td>{d.drugQuantity}</td>
                                                                            <td>{d.drugDose} / {d.doseFrequency}</td>
                                                                            <td>{d.prescription}</td>
                                                                            <td>{d.status}</td>
                                                                            <td>{this.moneyFormat(d.status === "canceled" ? 0 : d.drugAmount)}</td>
                                                                            <td>{this.moneyFormat(d.status === "canceled" ? 0 : d.drugAmount*d.drugQuantity)}</td>
                                                                            <td>{this.props.userType === "nurse" ||  this.props.userType === "service-desk" ?
                                                                                <>--</>
                                                                                : d.status === "canceled" || d.status === "paid"  ? <>--</> : <button className="btn btn-danger btn-sm"  onClick={() => {this.handleOnCancelPrescriptionRequestClick(p, d)}}>Cancel Prescription</button>}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                                <tfoot>
                                                                <tr>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th>{this.moneyFormat(prescriptionTotal)}</th>
                                                                    <th></th>
                                                                </tr>
                                                                </tfoot>
                                                            </table>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>

                                                    prescriptionTotal = 0;
                                                })

                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Prescription Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Nurse Assessment*/}
                            <TabPanel>
                                {
                                    this.state.prescription.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>Drug</th>
                                                <th>Qty</th>
                                                <th>Dose</th>
                                                <th>Assessment</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.prescription[0].prescriptionDetail.map((item, index) => {
                                                    return <tr key={item}>
                                                        <td>{item.drugName}</td>
                                                        <td>{item.drugQuantity}</td>
                                                        <td>{item.drugDose} / {item.doseFrequency}</td>
                                                        <td>
                                                            {
                                                                typeof item.assessment !== "undefined" ?
                                                                    <table className="table table-hover">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Time Given</th>
                                                                            <th>Dose Given</th>
                                                                            <th>Administered By</th>
                                                                            <th>Date</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            item.assessment.map((item, index) => {
                                                                                return <tr key={index}>
                                                                                    <td>{item.timeTaken}</td>
                                                                                    <td>{item.doseGive}</td>
                                                                                    <td>{item.submittedBy}</td>
                                                                                    <td>{item.submittedDate }</td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    <div className="p-3">
                                                                        <span className="alert alert-info">No Assessment Found</span>
                                                                    </div>
                                                            }


                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Prescription Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Pain Assessment*/}
                            <TabPanel>
                                {
                                    this.state.painAssessmentList.length > 0 ?
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Rating</th>
                                                    <th>Comment</th>
                                                    <th>Taken By</th>
                                                    <th>Taken On</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.painAssessmentList.map((item, index) => {
                                                        return <tr key={index}>
                                                            <td>{(index+1)}</td>
                                                            <td>{item.painRatingName}</td>
                                                            <td>{item.painComment}</td>
                                                            <td>{item.nurseName}</td>
                                                            <td>{this.formatDateAndTime(item.submittedOn)}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Pain Assessment Record Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Imaging*/}
                            <TabPanel>
                                {
                                    this.state.imagingList.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.imagingList.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                Image: {p.imageName} ~ Date: {this.formatDateAndTime(p.submittedOn)} ~ Status:
                                                                {
                                                                    <span className={p.status === 'pending' ? 'badge badge-warning' :  'badge badge-success'}>{p.status}</span>
                                                                }
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>Imaging ID</th>
                                                                    <td>{p._id}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Imaging Name</th>
                                                                    <td>{p.imageName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Test Status</th>
                                                                    <td>{p.status}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Status</th>
                                                                    <td>{p.paymentStatus}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Requested By</th>
                                                                    <td>{p.doctorName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Requested Date</th>
                                                                    <td>{this.formatDateAndTime(p.submittedOn)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>Image Result</th>
                                                                    <td>
                                                                        {
                                                                            p.result ? <a className="btn btn-sm btn-primary" target="_blank" href={!p.result || p.result?.includes("simplefileupload") ? p.result : `${serverLink}public/uploads/patient_imaging/${p.result}`}>View</a> : '--'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Result Added By</th>
                                                                    <td>{p.resultEnteredBy ? p.resultEnteredBy : '--'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Result Added Date</th>
                                                                    <td>{p.resultEnteredBy ? this.formatDateAndTime(p.resultEnteredDate) : '--'}</td>
                                                                </tr>
                                                                {
                                                                    this.props.userType === "nurse" ||  this.props.userType === "service-desk" ? <></> :
                                                                        p.paymentStatus !== 'paid' && (p.status !== 'cancelled' && p.status !== 'result entered') ? (
                                                                            <tr>
                                                                                <th>Action</th>
                                                                                <td><button className="btn btn-danger btn-sm" onClick={() => { this.handleOnCancelImagingRequestClick(p) }}>Cancel Request</button></td>
                                                                            </tr>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                }
                                                                </thead>
                                                            </table>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>

                                                })

                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Image Request Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Clinical Tasks*/}
                            <TabPanel>
                                {
                                    this.state.tasks.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Task</th>
                                                <th>Added By</th>
                                                <th>Added Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.tasks.map((task, index) => {
                                                    return <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{task.task}</td>
                                                        <td>{task.doctorName}</td>
                                                        <td>{this.formatDate(task.submittedOn)}</td>
                                                        <td>
                                                            {
                                                                task.taskStatus === 'incomplete' ?
                                                                    <span className="badge badge-danger">{task.taskStatus}</span> :
                                                                    <span className="badge badge-success">{task.taskStatus}</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                this.props.userType === "doctor" ?
                                                                    <label>
                                                                        {task.taskStatus === 'incomplete' ? 'Mark Complete' : 'Mark Incomplete'}
                                                                        <input
                                                                            name="isGoing"
                                                                            type="checkbox"
                                                                            checked={true}
                                                                            onChange={() => {this.onChangeClinicalTaskStatus(task)}}
                                                                        />
                                                                    </label>
                                                                    : "--"
                                                            }
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Task Found</span>
                                        </div>
                                }
                            </TabPanel>

                            <TabPanel>
                                <PatientServiceList service_list={this.state.servicesList} money_format={this.moneyFormat} />
                            </TabPanel>

                            {/*Amount Incurred*/}
                            {
                                <TabPanel>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary float-right"
                                        style={{ marginBottom: "15px" }}
                                        onClick={this.onPrintPage}
                                    >
                                        Print Invoice
                                    </button>
                                    <h3>Lab Fees</h3>
                                    {
                                        this.state.patientLabTestList.filter(e=> this.formatDate(e.requestDate) === this.formatDate(appointmentDate)).length > 0 ?
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Item Name</th>
                                                    <th>Request Date</th>
                                                    <th>Test Status</th>
                                                    <th>Requested By</th>
                                                    <th>Payment Status</th>
                                                    <th>Test Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {

                                                    this.state.patientLabTestList.filter(e=> this.formatDate(e.requestDate) === this.formatDate(appointmentDate)).map((lab, index) => {
                                                        if (lab.paymentStatus !== 'paid' && (lab.testStatus !== "result rejected" || lab.testStatus !== "cancelled")){
                                                            labIncurredTotal += lab.testAmount;
                                                        }
                                                        return <tr key={index}>
                                                            <td>{lab.testName}</td>
                                                            <td>{this.formatDate(lab.requestDate)}</td>
                                                            <td>{lab.testStatus}</td>
                                                            <td>{lab.doctorName}</td>
                                                            <td>{lab.paymentStatus}</td>
                                                            <td>{this.moneyFormat(lab.testAmount)}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <th/><th/><th/><th/>
                                                    <th>Amount Due </th>
                                                    <th>{this.moneyFormat(labIncurredTotal)}</th>
                                                </tr>
                                                </tfoot>
                                            </table>  :
                                            <div className="p-3">
                                                <span className="alert alert-info">No Lab Request Found</span>
                                            </div>

                                    }

                                    <h3>Prescription Fees</h3>
                                    {
                                        this.state.prescription.filter(e=> this.formatDate(e.submittedDate) === this.formatDate(appointmentDate)).length > 0 ?
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Item Name</th>
                                                    <th>Quantity</th>
                                                    <th>Dose</th>
                                                    <th>Other Instruction</th>
                                                    <th>Prescription Date</th>
                                                    <th>Payment Status</th>
                                                    <th>Drug Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.prescription.filter(e=> this.formatDate(e.submittedDate) === this.formatDate(appointmentDate))[0].prescriptionDetail.map((drug, index) => {
                                                        if (drug.status !== "canceled" && drug.status !== "paid"){
                                                            prescriptionIncurredTotal += drug.drugQuantity*drug.drugAmount;
                                                        }

                                                        return <tr key={index}>
                                                            <td>{drug.drugName}</td>
                                                            <td>{drug.drugQuantity}</td>
                                                            <td>{`${drug.drugDose} ${drug.doseFrequency}`}</td>
                                                            <td>{drug.prescription}</td>
                                                            <td>{this.formatDateAndTime(appointmentDate)}</td>
                                                            <td>{drug.status}</td>
                                                            <td>{this.moneyFormat(drug.status !== "canceled" ? drug.drugAmount*drug.drugQuantity : 0)}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <th/><th/><th/><th/>
                                                    <th>Amount Due</th>
                                                    <th>{this.moneyFormat(prescriptionIncurredTotal)}</th>
                                                </tr>
                                                </tfoot>
                                            </table>  :
                                            <div className="p-3">
                                                <span className="alert alert-info">No Prescription Found</span>
                                            </div>

                                    }

                                    <h3>Imaging Fees</h3>
                                    {
                                        this.state.imagingList.filter(e=> this.formatDate(e.submittedOn) === this.formatDate(appointmentDate)).length > 0 ?
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Imaging Name</th>
                                                    <th>Requested By</th>
                                                    <th>Requested Date</th>
                                                    <th>Status</th>
                                                    <th>Payment Status</th>
                                                    <th>Request Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.imagingList.filter(e=> this.formatDate(e.submittedOn) === this.formatDate(appointmentDate)).map((imaging, index) => {
                                                        if (imaging.status !=="cancelled" && imaging.paymentStatus !== 'paid'){
                                                            imagingIncurredTotal += imaging.price;
                                                        }
                                                        return <tr key={index}>
                                                            <td>{imaging.imageName}</td>
                                                            <td>{imaging.doctorName}</td>
                                                            <td>{this.formatDateAndTime(imaging.submittedOn)}</td>
                                                            <td>{imaging.status}</td>
                                                            <td>{imaging.paymentStatus}</td>
                                                            <td>{this.moneyFormat(imaging.status !=="cancelled" ? imaging.price : 0)}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <th/><th/><th/><th/>
                                                    <th>Amount Due</th>
                                                    <th>{this.moneyFormat(imagingIncurredTotal)}</th>
                                                </tr>
                                                </tfoot>
                                            </table>  :
                                            <div className="p-3">
                                                <span className="alert alert-info">No Imaging Found</span>
                                            </div>

                                    }

                                    <h1>TOTAL</h1>
                                    <div className="alert alert-danger font-weight-bold text-center">
                                        TOTAL AMOUNT DUE: {this.moneyFormat(labIncurredTotal+prescriptionIncurredTotal+imagingIncurredTotal)}
                                    </div>
                                    {this.state.printPage && (
                                        <ReactToPrint
                                            trigger={() => <button id="printing" style={{visibility: 'hidden'}}>Please wait ...</button>}
                                            content={() => this.componentRef.current}
                                            ref={el => (this.printComponent = el)}
                                        />
                                    )}
                                    <div className="col-md-12 view-div" ref={this.componentRef}  style={{visibility: 'hidden'}}>
                                        <div className="card card-body printableArea">
                                            <div className="mb-5">
                                                <center><img src={require('../../../Images/BUH Logo.png')} className=" mt-2 text-center" width="140" height="120"/></center>
                                                <h3 align="center" style={{fontWeight: 'bold', fontSize: '35px'}}> BAZE UNIVERSITY HOSPITAL </h3>
                                                <h5 align="center" style={{fontWeight: 'bold'}}> Plot 686 Cadastral Zone C 00, Kuchigoro,
                                                    Behind National Judicial Institute, Abuja, Nigeria</h5>
                                                <h5 align="center" style={{fontWeight: 'bold'}}>info@bazeuniversityhospital.com</h5>
                                                <h5 align="center" style={{fontWeight: 'bold'}}>Tel: +234 (0) 803 704 2486
                                                </h5>
                                                <p align="center" className="mb-2" style={{fontWeight: 'bold'}}><i><u> Payment Invoice</u></i></p>
                                                <hr/>
                                            </div>
                                            <h3><b>INVOICE</b> <span className="pull-right">#43299-4344</span></h3>
                                            <hr/>
                                            {
                                                this.state.patientLabTestList.filter(e=> this.formatDate(e.requestDate) === this.formatDate(appointmentDate)).length > 0 ?
                                                    <>
                                                        <h3>Lab Fees</h3>
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Item Name</th>
                                                                <th>Quantity</th>
                                                                <th>Test Amount</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {

                                                                this.state.patientLabTestList.filter(e=> this.formatDate(e.requestDate) === this.formatDate(appointmentDate)).map((lab, index) => {
                                                                    if (lab.paymentStatus !== 'paid' && (lab.testStatus !== "result rejected" || lab.testStatus !== "cancelled")){
                                                                        labIncurredTotal += lab.testAmount;
                                                                    }
                                                                    return <tr key={index}>
                                                                        <td>{lab.testName}</td>
                                                                        <td>1</td>
                                                                        <td>{this.moneyFormat(lab.testAmount)}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                            <tfoot>
                                                            <tr>
                                                                <th>--</th>
                                                                <th>Amount Due </th>
                                                                <th>{this.moneyFormat(labIncurredTotal)}</th>
                                                            </tr>
                                                            </tfoot>
                                                        </table>
                                                    </> :  <></>

                                            }

                                            {
                                                this.state.prescription.filter(e=> this.formatDate(e.submittedDate) === this.formatDate(appointmentDate)).length > 0 ?
                                                    <>
                                                        <h3>Prescription Fees</h3>
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Item Name</th>
                                                                <th>Quantity</th>
                                                                <th>Drug Amount</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                this.state.prescription.filter(e=> this.formatDate(e.submittedDate) === this.formatDate(appointmentDate))[0].prescriptionDetail.map((drug, index) => {
                                                                    if (drug.status !== "canceled" && drug.status !== "paid"){
                                                                        prescriptionIncurredTotal += drug.drugQuantity*drug.drugAmount;
                                                                    }

                                                                    return <tr key={index}>
                                                                        <td>{drug.drugName}</td>
                                                                        <td>{drug.drugQuantity}</td>
                                                                        <td>{this.moneyFormat(drug.status !== "canceled" ? drug.drugAmount*drug.drugQuantity : 0)}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                            <tfoot>
                                                            <tr>
                                                                <th>--</th>
                                                                <th>Amount Due</th>
                                                                <th>{this.moneyFormat(prescriptionIncurredTotal)}</th>
                                                            </tr>
                                                            </tfoot>
                                                        </table>
                                                    </> : <></>

                                            }


                                            {
                                                this.state.imagingList.filter(e=> this.formatDate(e.submittedOn) === this.formatDate(appointmentDate)).length > 0 ?
                                                    <>
                                                        <h3>Imaging Fees</h3>
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Imaging Name</th>
                                                                <th>Quantity</th>
                                                                <th>Request Amount</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                this.state.imagingList.filter(e=> this.formatDate(e.submittedOn) === this.formatDate(appointmentDate)).map((imaging, index) => {
                                                                    if (imaging.status !=="cancelled" && imaging.paymentStatus !== 'paid'){
                                                                        imagingIncurredTotal += imaging.price;
                                                                    }
                                                                    return <tr key={index}>
                                                                        <td>{imaging.imageName}</td>
                                                                        <td>1</td>
                                                                        <td>{this.moneyFormat(imaging.status !=="cancelled" ? imaging.price : 0)}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                            <tfoot>
                                                            <tr>
                                                                <th></th>
                                                                <th>Amount Due</th>
                                                                <th>{this.moneyFormat(imagingIncurredTotal)}</th>
                                                            </tr>
                                                            </tfoot>
                                                        </table>
                                                    </> :   <></>

                                            }

                                            <div className="row">
                                                <div className="col-md-12" style={{marginTop: '40px'}}>
                                                    <div className="pull-right m-t-30 text-right">
                                                        <hr/>
                                                        <h3><b>TOTAL AMOUNT DUE :</b> {currencyConverter(labIncurredTotal+prescriptionIncurredTotal+imagingIncurredTotal)}</h3>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            }

                            {/*Consultation Link*/}
                            <TabPanel>
                                {
                                    this.state.linkList.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.linkList.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                Doctor: {p.doctorName} ~ Date: {this.formatDateAndTime(p.submittedOn)}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>S/N</th>
                                                                    <th>Link</th>
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    {/*<th>Status</th>*/}
                                                                    <th>Action</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td><a href={p.consultationLink} target="_blank">Visit link</a></td>
                                                                        <td>{this.formatDate(p.consultationDate)}</td>
                                                                        <td>{p.consultationTime}</td>
                                                                        {/*<td>{p.consultationStatus}</td>*/}
                                                                        <td>
                                                                            {
                                                                                this.props.userType === "doctor" ?
                                                                                    <button className="btn btn-danger btn-sm" onClick={() => this.DeleteLink(p)}><i className="fa fa-trash"></i></button>
                                                                                    : "--"
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                </tbody>

                                                            </table>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                })

                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Consultation Link</span>
                                        </div>
                                }
                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
        permissionDetails: state.permissionDetails
    };
};

export default connect(mapStateToProps, null)(ConsultationPage);