import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
  consultationListDetailsReducer,
  patientListDetailsReducer,
  personDetailsReducer,
  reportDetailsReducer,
} from "./petientdetailsReducer";
import { loginDetailsReducer, permissionDetailsReducer } from "./loginDetailsReducer";
import storage from "redux-persist/lib/storage";
import {
  generalDetailsReducer,
  hospitalDetailsReducer, patientProfileDetailsReducer,
  serviceDetailsReducer,
  updatePatientDetailsReducer
} from "./hospitaldetailsReducer";
import {hospitalCode} from "../resources/url";
const rootReducer = combineReducers({
  patientDetails: patientProfileDetailsReducer,
  personDetails: personDetailsReducer,
  reportDetails: reportDetailsReducer,
  loginDetails: loginDetailsReducer,
  hospitalDetails: hospitalDetailsReducer,
  generalDetails: generalDetailsReducer,
  permissionDetails: permissionDetailsReducer,
  patientListDetails: patientListDetailsReducer,
  consultationListDetails: consultationListDetailsReducer,
  serviceDetails: serviceDetailsReducer,
  updatePatientDetails: updatePatientDetailsReducer
});

const persistConfig = {
  key: hospitalCode,
  storage,
  // whitelist: ["personDetails", "reportDetails"],
};

export default persistReducer(persistConfig, rootReducer);
