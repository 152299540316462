import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./userdashboard.css";
import {loginValidation} from "../../../context/loginvalidation";
import {connect} from "react-redux";
import Navigator from "../navigation/navigation";

class UserDashboard extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
    }

    render() {
        return (
            <div className="dashboardpage">
                <div className="topheader">
                    <Navigator title={"User Dashboard"} />
                </div>


                <div className="first_section">
                    <div className="row">
                        <div className="col-sm-4">
                            {" "}
                            <Link to="/settings/user/system/add">
                                <DashboardCard name="Add User" icon="fa fa-plus fa-4x" count="" />
                            </Link>
                        </div>

                        <div className="col-sm-4">
                            {" "}
                            <Link to="/settings/user/system/find">
                                <DashboardCard name="Find User" icon="fa fa-search fa-4x" count="" />
                            </Link>
                        </div>

                        <div className="col-sm-4">
                            {" "}
                            <Link to="/settings/user/system/users">
                                <DashboardCard name="System Users" icon="fa fa-users fa-4x" count="" />
                            </Link>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-sm">
                            {" "}
                            <Link to="/department/list">
                                <DashboardCard name="Departments" icon="fa fa-warehouse fa-4x" count={""} />
                            </Link>
                        </div>

                        <div className="col-sm">
                            {" "}
                            <Link to="/specialisation/list">
                                <DashboardCard name="Specialisations" icon="fa fa-exchange fa-4x" count={""} />
                            </Link>
                        </div>

                        <div className="col-sm">
                            {" "}
                            <Link to="/schedule/consultation/doctors">
                                <DashboardCard name="Doctor Scheduler" icon="fa fa-calendar fa-4x" count={""} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails
    };
};
export default connect(mapStateToProps, null)(UserDashboard);
