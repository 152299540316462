import React, { Component } from "react";
import "./roomform.css";
import Navigator from "../../../navigation/navigation";

class RoomForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                    <div className="col-md-4 mb-3">
                                            <label htmlFor="roomName">Room Name <span className="text-danger">*</span></label>
                                            <input
                                                name="roomName"
                                                type="text"
                                                className="form-control"
                                                id="roomName"
                                                value={this.props.data.roomName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="typeName">Room Type <span className="text-danger">*</span> </label>
                                            <select
                                                name="typeName"
                                                id="typeName"
                                                className="custom-select"
                                                value={this.props.data.typeName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Room Type</option>
                                                {
                                                    this.props.roomTypesList.map((roomtype, index) => {
                                                        return <option key={index} data={roomtype._id} value={roomtype.typeName}>{roomtype.typeName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="roomAmount">Room Amount <span className="text-danger">*</span></label>
                                            <input
                                                name="roomAmount"
                                                type="number"
                                                className="form-control"
                                                id="roomAmount"
                                                value={this.props.data.roomAmount}
                                                onChange={this.props.onEdit}
                                            />
                                            <small id="emailHelp" className="form-text text-muted">Room amount for patient ward. Else, leave the default zero</small>
                                        </div>
                                       
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="bedCapacity">Bed Capacity <span className="text-danger">*</span></label>
                                            <input
                                                name="bedCapacity"
                                                type="number"
                                                className="form-control"
                                                id="bedCapacity"
                                                value={this.props.data.bedCapacity}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="roomManager">Manager Name<span className="text-danger">*</span></label>
                                            <input
                                                name="roomManager"
                                                type="text"
                                                className="form-control"
                                                id="roomManager"
                                                value={this.props.data.roomManager}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="extensionNo">Extension Number <span className="text-danger">*</span></label>
                                            <input
                                                name="extensionNo"
                                                type="number"
                                                className="form-control"
                                                id="extensionNo"
                                                value={this.props.data.extensionNo}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="blockName">Select Room Block <span className="text-danger">*</span> </label>
                                            <select
                                                name="blockName"
                                                id="blockName"
                                                className="custom-select"
                                                value={this.props.data.blockName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Block</option>
                                                {
                                                    this.props.blockList.map((block, index) => {
                                                        return <option key={index} data={block._id} value={block.blockName}>{block.blockName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                   
                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RoomForm;
