import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hospitalCode, serverLink } from "../../../../../resources/url";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import PlanForm from "../planform/planform";
import "./updateplan.css";


class UpdatePlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.planData._id,
                companyName: this.props.planData.companyName,
                planName: this.props.planData.planName,
                oldPlanName: this.props.planData.planName,
                amount: this.props.planData.amount,
                duration: this.props.planData.duration,
                planDescription: this.props.planData.planDescription,
                planType: this.props.planData.planType,
                specialistType: this.props.planData.specialistType,
                updatedBy: "",
            },

            
            insuranceCompanyList:[],
            specialistsList: [],
            messageDescription: "",
            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                updatedBy: localStorage.getItem('userID')
            },
        });
        this.fetchInsuranceCompany()
        this.onGetSpecialists()
    }
    async fetchInsuranceCompany() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}insurance/company/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    insuranceCompanyList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }
    async onGetSpecialists() {
        axios.get(`${this.state.serverLink}specialisation/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(specialisation => {
                if(specialisation.data.length < 1) {
                    this.setState({
                        specialistsList: ""
                    })

                } else {
                    const specialisations = [];
                    specialisation.data.map(items => specialisations.push({value: items.specialisationName, label: items.specialisationName}))
                    this.setState({
                        specialistsList: specialisations,
                    })
                }
            })
            .catch(err => {
                this.setState({
                    specialistsList: null
                })
            })
    }


    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });

    };

    onSpecialisationChange = (e) => {
        const formData = this.state.formData;
        let data = [];
        e.map((spec) => {
            data.push(spec.value)
        })
        this.setState({
            formData: { ...formData, specialistType: data }
        });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateInsurancePlan();
    };


    async onUpdateInsurancePlan() {
        const sendData = {
            ...this.state.formData,
          
        };

        if(this.state.formData.planName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Insurance Plan"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.companyName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Choose an Insurance Company"
            this.handleErrorDialog();
            return false;
        }


        if(this.state.formData.amount === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Insurance Amount"
            this.handleErrorDialog();
            return false;
        }
        if(this.state.formData.duration === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Insurance Duration"
            this.handleErrorDialog();
            return false;
        }

        

        axios.patch(`${this.state.serverLink}insurance/plan/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "INSURANCE UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                    console.log(this.state.formData)
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
                
            });
    }


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/hospital/insurance/plans/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Insurance Updated"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <PlanForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                onSpecialisationChange={this.onSpecialisationChange}
                                data={this.state.formData}
                                title="Update Insurance Plan"
                                specialistsList={this.state.specialistsList}
                                insuranceCompanyList={this.state.insuranceCompanyList}
                                btnMessage = "Update Plan"
                            ></PlanForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    
    return {
        planData: state.personDetails,
        doctorDetails: state.loginDetails,
    };
    
};
export default connect(mapStateToProps, null)(UpdatePlan);
