import React, {Component} from "react";
import {Link} from "react-router-dom";
import { serverLink, hospitalCode } from "../../../resources/url";
import DataTable from "../../Common/data-table/data-table";
import {formatDateAndTime, currencyConverter, currentDate} from "../../../resources/constants";

class PatientListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            passportLink: serverLink+'public/uploads/patient_passport/'
        }
    }

  showTable = () => {

        try {
            return this.props.patientlist.map((item, index) => {
                let dateOfBirthDate = new Date(item.dateOfBirth);
                const dob = `${dateOfBirthDate.getDate()}/${
                    dateOfBirthDate.getMonth() + 1
                }/${dateOfBirthDate.getFullYear()}`;
                const formatter = new Intl.NumberFormat('en-NG');
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>
                            {
                                !item.passport ?  (
                                <div className="userbg">
                                    <i className="fa fa-user fa-2x"/>
                                </div>
                            ) : (
                                <img className="image" alt="" width={50} height={50} srcSet={item.passport?.includes("simplefileupload") ? item.passport : this.state.passportLink+item.passport} />
                            )
                            }
                        </td>
                        <td>{item.patientSerial}</td>
                        <td>{item.firstName + " "+ item.middleName + " " + item.surname}</td>
                        <td>{item.gender}</td>
                        <td> {item.dateOfBirth === "" ? "N/A" : dob}</td>
                        <td> {item.phoneNumber === "" ? "N/A" : item.phoneNumber}</td>
                        <td>{item.emailAddress === "" ? "N/A" : item.emailAddress}</td>
                        <td>{item.walletAmount === "" ? "N/A" : formatter.format(item.walletAmount)}</td>
                        <td>{ item.bloodGroup }</td>
                        <td>
                            <Link to={this.props.isFront ? `/service-desk/patient/profile` : `/patient/profile`}>
                            <button
                                onClick={async () => {
                                    const sendData = {
                                        ...item,
                                        personId: item._id,
                                    };
                                    this.props.setOnPatientDetails(sendData);
                                }}
                                type="button"
                                className="btn btn-success"
                            >
                                <i className="fa fa-edit" aria-hidden="true"/>
                            </button>
                        </Link>
                        </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    render() {
        const header = ["S/N", "Profile",  "ID", "Name", "Sex", "Date of Birth",  "Mobile",  "Email",  "Wallet (₦)", "BG", "Option"];
        return <div className="">
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <h3 className="card-title">Patient List</h3>
                        <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')} body={this.showTable()} title={`Patient Report`} />
                    </div>
                </div>
            </div>
        </div>
    }
}

export default PatientListTable;
