import React, {Component} from "react";
import { Link } from "react-router-dom";
import './patientaction.css'

 class PatientActionButton extends Component {
    render() {
        return <div className="patientaction">
            <div className="col-sm" onClick={this.props.handleClick}>
                <div className="box">
                    {" "}
                    <div className="box_containt">
                        <span
                            style={{
                                fontWeight: "700",
                                color: "#000",
                            }}
                        >
                                {this.props.actionName}
                              </span>
                    </div>
                    <i
                        className={this.props.actionIcon}
                        aria-hidden="true"/>
                </div>
            </div>
        </div>
    }
 }

export default PatientActionButton;
