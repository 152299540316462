import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    setGeneralDetails,
    setpersonDetails
} from "../../../../../actions/setpersondetailsaction";
import { hospitalCode, serverLink } from "../../../../../resources/url";
import "./item-category-list.css";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Navigator from "../../../../Settings/navigation/navigation";

class ItemCategoryList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"finance/",
            hospitalCode: hospitalCode,
            isLoading: true,
            columns: [
                {
                    name: "S/N",
                    selector: row => `${row.count}`,
                    sortable: true,
                    width: '10%'
                },
                {
                    name: "Item Category Name",
                    selector: row => `${row.rawData.categoryName}`,
                    sortable: true,
                    width: '30%'
                },
                {
                    name: "Description",
                    selector: row => `${row.rawData.description === '' ? 'N/A' : row.rawData.description}`,
                    sortable: true,
                    width: '45%'
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to="/finance/inventory/item/category/update">
                            <button
                                type="button"
                                onClick={async () => {
                                    const itemData = {
                                        ...row.rawData,
                                    };
                                    this.props.setOnPatientDetails(itemData);
                                }}
                                className="btn btn-primary btn-sm"
                            >

                                <i className="fa fa-edit" aria-hidden="true"/>
                            </button>
                        </Link>
                    ),
                    width: '15%',
                },

            ],
            data: []
        };
    }
    componentDidMount() {
        this.fetchCategory();
    }

     fetchCategory = async () => {
        await axios.get(`${this.state.serverLink}item/category/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(data => {
                data.data.map((data, index) => {
                    this.setState({
                        data: [...this.state.data,
                            {
                                count: index+1,
                                rawData: data,
                            }
                        ],
                    });
                })
                this.setState({
                    isLoading: false,
                });
            });
    }


    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search Item Category',

        };
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Item Category" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                        </div>

                        <Link to="/finance/inventory/item/category/add">
                            <button
                                type="button"
                                className="btn btn-warning"
                            >
                                + Add Item Category
                            </button>
                        </Link>
                    </div>
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            className="tablehead"
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCategoryList);
