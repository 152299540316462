import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./patient-profile.css";
import {setpersonDetails, setUpdatePatientDetails} from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import FormPrompt from "../../DialogBoxes/formprompt";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import AddAppointment from "../../Appointment/addappointment/addappointment";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AppointmentCheckIn from "../../Appointment/appointmentcheckin/appointmentcheckin";
import CaptureVital from "../../Appointment/capturevital/capturevital";
import {serverLink, hospitalCode, calculateAge} from "../../../resources/url";

import Navigator from "../../Settings/navigation/navigation";
import ServiceRequest from "../../Services/servicerequest/servicerequest";
import PatientServiceList from "../../Services/patientservicelist/patientservicelist";
import AddMedicalRecord from "../../Patients/addmedicalrecord/addmedicalrecord";
import AddPatientOtherInfo from "../../Patients/addpatientotherinfo/addpatientotherinfo";
import AddAllergy from "../../Patients/addpatientallergy/addpatientallergy";
import PatientActionButton from "../../Patients/utils/patientaction";
import PatientAppointmentList from "../../Patients/utils/patientappointment";
import PatientAllergyList from "../../Patients/utils/patientallergies";
import PatientVitalList from "../../Patients/utils/patientvitals";
import PatientDependantList from "../../Patients/utils/patientdependantlist";
import PatientPaymentList from "../../Patients/utils/patientpayments";
import PatientMedicalRecordList from "../../Patients/utils/patientpreviousmedicalrecord";
import PatientOtherInfo from "../../Patients/utils/patientotherinfo";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";

class FrontDeskPatientProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+ "patient/",
            generalServerLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,
            appointmentLimit: 10,
            passport: !this.props.patientDetails.passport  || this.props.patientDetails.passport?.includes("simplefileupload")  ?
                <div className="patientlistpage">
                    <img
                        className="patient_passport img-fluid"
                        srcSet={this.props.patientDetails.passport?.includes("simplefileupload") ? this.props.patientDetails.passport : serverLink+'public/uploads/patient_passport/'+this.props.patientDetails.passport}
                        alt="profileImage"
                    />
                </div>
                : <i className="fa fa-user fa-8x" aria-hidden="true"/>,

            patientEntryID: this.props.patientDetails._id,
            patientSerial: this.props.patientDetails.patientSerial,
            lastVisit: this.props.patientDetails.lastVisit,
            firstName: this.props.patientDetails.firstName,
            middleName: this.props.patientDetails.middleName,
            surname: this.props.patientDetails.surname,
            patientName: `${this.props.patientDetails.firstName} ${this.props.patientDetails.middleName} ${this.props.patientDetails.surname}`,
            emailAddress: this.props.patientDetails.emailAddress,
            phoneNumber: this.props.patientDetails.phoneNumber,
            altPhoneNumber: this.props.patientDetails.altPhoneNumber,
            dateOfBirth: this.props.patientDetails.dateOfBirth,
            bloodGroup: this.props.patientDetails.bloodGroup,
            gender: this.props.patientDetails.gender,
            maritalStatus: this.props.patientDetails.maritalStatus,
            occupation: this.props.patientDetails.occupation,
            religion: this.props.patientDetails.religion,
            residentialAddress: this.props.patientDetails.residentialAddress,
            status: this.props.patientDetails.status,
            state: this.props.patientDetails.state,
            lga: this.props.patientDetails.lga,
            ward: this.props.patientDetails.ward,
            zipCode: this.props.patientDetails.zipCode,
            officeAddress: this.props.patientDetails.officeAddress,
            nextOfKinPhone: this.props.patientDetails.nextOfKinPhone,
            nextOfKinName: this.props.patientDetails.nextOfKinName,
            nextOfKinRelationship: this.props.patientDetails.nextOfKinRelationship,
            walletAmount: this.props.patientDetails.walletAmount,
            walletAmountConverter: this.currencyConverter(0),

            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openShowAllergyImageDialog: false,
            openShowHospitalRecordDialog: false,
            openShowImagingDialog: false,
            openAllergyDialog: false,
            openHospitalRecordDialog: false,
            openServiceDialog: false,
            openOtherInfoDialog: false,
            openVitalDialog: false,
            openCheckinDialog: false,

            allergyImageSrc: "",
            ImageSrc: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            isCloseDialogAppear: true,

            messageTitle: "",
            messageDescription: "",

            appointmentPrescription: '',
            prescriptionAmount: 0,

            prescription: [],

            labTestList: [],
            labTestColumns: [
                {
                    name: "Test Name",
                    selector: row => `${row.testName}`,
                    sortable: true
                },
                {
                    name: "Specimen",
                    selector: row => row.specimen.map((item, index) => { return <div key={index}>{item.specimenName} (Amount => {item.testAmount})</div> }),
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => row.specimen.map((item, index) => {
                        return <div key={index}>
                            <button
                                onClick={() => {this.addLabTest(row.rowData, index)}}
                                type="button"
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-vial" aria-hidden="true"> {item.specimenName} </i>
                            </button>
                        </div>


                    }),
                }
            ],
            labTestMessage: false,
            patientLabTestList: [],

            patientDetails: this.props.patientDetails,

            allergicList: [],
            medicalRecordList: [],
            appointmentList: [],
            allotmentList: [],
            vitalList: [],
            paymentList: [],
            servicesList: [],
            dependantList: this.props.patientDetails.dependantList
        };
    }

    componentDidMount() {
        this.getCurrentWallet();
        this.getPatientAppointment();
        this.getPatientAllergies();
        this.getPatientVitals();
        this.getPatientPayments();
        this.getPatientPreviousMedicalRecord();
        this.getServicesList();
        this.getPatientPrescription();
        this.getPatientLabTest();
    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()} : 
                    ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}
                    
                    `
        } else {
            return '--'
        }
    };

    getCurrentWallet = () => {
        axios.get(`${this.state.serverLink}current_wallet/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(wallet => {
                this.setState({
                    walletAmount: wallet.data.walletAmount,
                    walletAmountConverter: wallet.data.walletAmount ?
                        this.currencyConverter(wallet.data.walletAmount) : 0.00
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientAppointment = () => {
        axios.get(`${this.state.generalServerLink}appointment/patient/${this.state.hospitalCode}/${this.state.patientSerial}/${this.state.appointmentLimit}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    appointmentList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientVitals = () => {
        axios.get(`${this.state.generalServerLink}vital/${this.state.hospitalCode}/${this.state.patientSerial}/${this.state.appointmentLimit}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    vitalList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientAllergies = () => {
        axios.get(`${this.state.generalServerLink}allergy/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    allergicList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientPreviousMedicalRecord = () => {
        axios.get(`${this.state.generalServerLink}patient/previous_medical_record/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    medicalRecordList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientPayments = () => {
        axios.get(`${this.state.generalServerLink}payment/patient_payments/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    paymentList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getServicesList = () => {
        axios.get(`${this.state.generalServerLink}service/request/patient/${this.state.patientSerial}/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    servicesList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

     getPatientPrescription = () => {
         axios.get(`${serverLink}pharmacy/prescription/patient/limit/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                this.setState({
                    prescription: data,
                });

                if (data.length > 0) {
                    data.map(v => {
                            this.setState({
                                appointmentPrescription: v.prescriptionDetail,
                                prescriptionAmount: v.prescriptionAmount
                            })
                    })
                }
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH PRESCRIPTION:", error)
            })
    }

     getPatientLabTest = () => {
         axios.get(`${serverLink}laboratory/test/request/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                this.setState({
                    patientLabTestList: data,
                });

            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH PATIENT TESTS:", error)
            })
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()}
                    
                    `
        } else {
            return '--'
        }
    };

    handleAppointmentActionClicked = () => {
        const consultation_fee = localStorage.getItem('consultationFee');
        if (parseInt(consultation_fee) > this.state.walletAmount) {
            this.state.messageTitle = "INSUFFICIENT FUNDS"
            this.state.messageDescription = "Insufficient amount in the patient wallet. Direct the patient to the finance department to make payment!"
            this.handleErrorDialog();
        } else {
            this.setState({
                openAddAppointmentDialog: true
            });
        }
    }

    handleUpdateAppointmentActionClicked = () => {
        this.setState({
            openUpdateAppointmentDialog: true
        });

    }

    handleViewAllergyImageClicked = (e) => {

        this.setState({
            allergyImageSrc: e.target.srcset,
            openShowAllergyImageDialog: true
        });

    }

    handleViewImageClicked = (e) => {

        this.setState({
            ImageSrc: e.target.srcset,
            openShowHospitalRecordDialog: true
        });
    }

    handleCheckinActionClicked = () => {
        this.setState({
            openCheckinDialog: true,
        });
    }

    handleVitalActionClicked = () => {
        this.setState({
            openVitalDialog: true
        })
    }

    handleAllergyActionClicked = () => {
        this.setState({
            openAllergyDialog: true
        });
    }

    handlehospitalRecordActionClicked = () => {
        this.setState({
            openHospitalRecordDialog: true
        });
    }

    handlePatientServiceActionClicked = () => {
        this.setState({
            openServiceDialog: true
        });
    }

    handlePatientOtherInfoClicked = () => {
        this.setState({
            openOtherInfoDialog: true
        });
    }

    handleSuccessDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openOtherInfoDialog: false,
            openAlertDialog: true,
        });
    };

    handleErrorDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAllergyDialog: false,
            openImageDialog: false,
            openAddAppointmentDialog: false,
            openOtherInfoDialog: false,
            openUpdateAppointmentDialog: false,
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    closeAddAppointmentDialog = () => {
        this.setState({
            openAddAppointmentDialog: false,
        });
    };
    closeCheckInDialog = () => {
        this.setState({
            openCheckinDialog: false,
        });
    };
    closeVitalDialog = () => {
        this.setState({
            openVitalDialog: false,
        });
    };
    closeAllergyImageDialog = () => {
        this.setState({
            openShowAllergyImageDialog: false,
        });
    };
    closeHospitalRecordViewDialog = () => {
        this.setState({
            openShowHospitalRecordDialog: false,
        });
    };

    closeAllergyDialog = () => {
        this.setState({
            openAllergyDialog: false,
        });
    };
    closeHospitalRecordDialog = () => {
        this.setState({
            openHospitalRecordDialog: false,
        });
    };

    closeServiceDialog = () => {
        this.setState({
            openServiceDialog: false,
        });
        window.location.reload();
    };

    closeOtherInfoDialog = () => {
        this.setState({
            openOtherInfoDialog: false,
        });
    };

    closeAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.reload();
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };
    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    render() {
        let prescriptionTotal = 0;
        let count = 0;
        return this.state.isLoading ? (
            <div className="patientprofilepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="patientprofilepage">
                <div className="main_section">
                    <FormPrompt
                        openDialog={this.state.openAddAppointmentDialog}
                        title="Add New Appointment"
                        onSetOpenDialog={this.closeAddAppointmentDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AddAppointment
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                            patientWallet={this.state.walletAmount}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openAllergyDialog}
                        title="Add New Allergy"
                        onSetOpenDialog={this.closeAllergyDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AddAllergy
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                        />
                    </FormPrompt>


                    <FormPrompt
                        openDialog={this.state.openHospitalRecordDialog}
                        title="Add Previous Hospital Record"
                        onSetOpenDialog={this.closeHospitalRecordDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AddMedicalRecord
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openOtherInfoDialog}
                        title="Add Patient Other Info"
                        onSetOpenDialog={this.closeOtherInfoDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AddPatientOtherInfo
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openShowAllergyImageDialog}
                        title="Allergy Reaction Image"
                        onSetOpenDialog={this.closeAllergyImageDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <img srcSet={this.state.allergyImageSrc} alt="Allergy Image"/>
                    </FormPrompt>


                    <FormPrompt
                        openDialog={this.state.openShowHospitalRecordDialog}
                        title="Patient Imaging"
                        onSetOpenDialog={this.closeHospitalRecordViewDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <img srcSet={this.state.ImageSrc} alt="Imaging"/>
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openCheckinDialog}
                        title="CHECK IN PATIENT"
                        onSetOpenDialog={this.closeCheckInDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AppointmentCheckIn
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                            patientAppointment={this.state.appointmentList}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openCheckinDialog}
                        title="CHECK IN PATIENT"
                        onSetOpenDialog={this.closeCheckInDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AppointmentCheckIn
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                            patientAppointment={this.state.appointmentList}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openVitalDialog}
                        title="CAPTURE VITALS"
                        onSetOpenDialog={this.closeVitalDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <CaptureVital
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                            patientAppointment={this.state.appointmentList}
                        />
                    </FormPrompt>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <FormPrompt
                        openDialog={this.state.openServiceDialog}
                        title="SERVICES"
                        onSetOpenDialog={this.closeServiceDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                        isFullWidth={true}
                    >
                        <ServiceRequest patient_id={this.state.patientSerial} patient_name={this.state.patientName} patient_wallet={this.state.walletAmount} money_format={this.currencyConverter} />

                    </FormPrompt>

                    <div className="topheader">
                        <Navigator title="Patient Profile" />
                    </div>

                    <div className="row">
                        <div className="col-sm-2">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="text-secondary mb-1">{this.state.patientSerial}</strong>
                                </div>
                                <div className="d-flex flex-column align-items-center text-center">
                                    <div className="profileimage">{this.state.passport}</div>
                                    <div className="mt-3">
                                        <h4>{this.state.firstName} {this.state.middleName} {this.state.surname}</h4>
                                        <p className="text-muted font-size-sm">Last visit: {
                                            this.formatDate(this.state.lastVisit)
                                        }</p>
                                        <p>
                                            <span className="text-danger mb-3"><b>WALLET: {this.state.walletAmountConverter}</b> </span>
                                        </p>
                                        <Link to="/patient/update">
                                            <button
                                                onClick={async () => {
                                                    const sendData = {
                                                        ...this.props.patientDetails,
                                                        personId: this.state.patientEntryID,
                                                        isFront: true,
                                                    };
                                                    this.props.setOnUpdatePatientDetails(sendData);
                                                }}
                                                type="button"
                                                className="btn btn-outline-primary"
                                            >
                                                Update Info <i className="fa fa-edit" aria-hidden="true"/>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-10">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Phone Number</h6>
                                        </div>
                                        <div className="col-sm-3 text-secondary">
                                            {this.state.phoneNumber} {this.state.altPhoneNumber ? ' | ' + this.state.altPhoneNumber : ''}
                                        </div>

                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Date of Birth</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.dateOfBirth ? this.formatDate(this.state.dateOfBirth) +  `  (${calculateAge(this.state.dateOfBirth)}  ${calculateAge(this.state.dateOfBirth) > 1 ?  "Years Old" : "Year Old"})` : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Blood Group</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.bloodGroup ? this.state.bloodGroup : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Gender</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.gender ? this.state.gender : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Marital Status</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.maritalStatus ? this.state.maritalStatus : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Next of Kin</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            Name: {this.state.nextOfKinName ? this.state.nextOfKinName : 'N/A'}
                                            <br/>
                                            Phone: {this.state.nextOfKinPhone ? this.state.nextOfKinPhone : 'N/A'}
                                            <br/>
                                            Relationship: {this.state.nextOfKinRelationship ? this.state.nextOfKinRelationship : 'N/A'}
                                            <br/>

                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Address</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.residentialAddress ? this.state.residentialAddress : 'N/A'}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    <Tabs>
                        <TabList>
                            <Tab>Appointments</Tab>
                            <Tab>Allergens</Tab>
                            <Tab>Vitals</Tab>
                            <Tab>Dependants</Tab>
                            <Tab>Lab</Tab>
                            <Tab>Prescription</Tab>
                            <Tab>Payments</Tab>
                            <Tab>Services</Tab>

                        </TabList>

                        <TabPanel>
                            <PatientAppointmentList isFront openUpdateModal={this.handleUpdateAppointmentActionClicked} appointmentList={this.state.appointmentList}
                                                    formatDate={this.formatDate}/>
                        </TabPanel>
                        <TabPanel>
                            <PatientAllergyList isFront allergicList={this.state.allergicList} handleOnClick={this.handleViewAllergyImageClicked} formatDate={this.formatDate}/>
                        </TabPanel>
                        <TabPanel>
                            <PatientVitalList vitalList={this.state.vitalList} formatDate={this.formatDate}/>
                        </TabPanel>

                        <TabPanel>
                            <PatientDependantList isFront patientDetail={this.state.patientDetails} dependantList={this.state.dependantList} formatDate={this.formatDate} />
                        </TabPanel>
                        {/*Lab*/}
                        <TabPanel>
                            {
                                this.state.patientLabTestList.length > 0 ?
                                    <Accordion>
                                        {
                                            this.state.patientLabTestList.map((p, index) => {
                                                return <AccordionItem key={index}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Requested By: {p.doctorName} Date: {this.formatDateAndTime(p.requestDate)} ~ Status:
                                                            {
                                                                <span className={p.testStatus === 'requested' ? 'badge badge-warning' :
                                                                    p.testStatus === 'specimen taken' ? 'badger badge-info' : p.testStatus === 'result entered' || p.testStatus === 'result confirmed'  ? 'badge badge-success' : 'badge badge-danger'
                                                                }>{p.testStatus}</span>

                                                            }
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Test ID</th>
                                                                <td>{p.testID}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Test Status</th>
                                                                <td>{p.testStatus}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Test Amount</th>
                                                                <td>{this.moneyFormat(p.testAmount)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Payment Status</th>
                                                                <td>{p.paymentStatus}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Requested By</th>
                                                                <td>{p.doctorName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Requested Date</th>
                                                                <td>{this.formatDateAndTime(p.requestDate)}</td>
                                                            </tr>
                                                            </thead>
                                                        </table>
                                                    </AccordionItemPanel>
                                                </AccordionItem>

                                            })

                                        }

                                    </Accordion>
                                    :
                                    <div className="p-3">
                                        <span className="alert alert-info">No Lab Request Found</span>
                                    </div>
                            }
                        </TabPanel>

                        {/*Prescription*/}
                        <TabPanel>
                            {
                                this.state.prescription.length > 0 ?
                                    <Accordion>
                                        {
                                            this.state.prescription.map((p, index) => {
                                                return <AccordionItem key={index}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Doctor: {p.doctorName} ~ Date: {this.formatDateAndTime(p.submittedDate)}
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>S/N</th>
                                                                <th>Quantity</th>
                                                                <th>Status</th>
                                                                <th>Unit Price</th>
                                                                <th>Total</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                p.prescriptionDetail.map((d, index) => {
                                                                    prescriptionTotal += d.drugAmount*d.drugQuantity;
                                                                    return <tr key={index}>
                                                                        <td>{index+1}</td>
                                                                        <td>{d.drugQuantity}</td>
                                                                        <td>{d.status}</td>
                                                                        <td>{this.moneyFormat(d.drugAmount)}</td>
                                                                        <td>{this.moneyFormat(d.drugAmount*d.drugQuantity)}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                            </tbody>
                                                            <tfoot>
                                                            <tr>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>{this.moneyFormat(prescriptionTotal)}</th>
                                                            </tr>
                                                            </tfoot>
                                                        </table>
                                                    </AccordionItemPanel>
                                                </AccordionItem>

                                                prescriptionTotal = 0;
                                            })

                                        }

                                    </Accordion>
                                    :
                                    <div className="p-3">
                                        <span className="alert alert-info">No Prescription Found</span>
                                    </div>
                            }
                        </TabPanel>

                        <TabPanel>
                            <PatientPaymentList patientDetail={this.state.patientDetails} paymentList={this.state.paymentList} moneyFormat={this.currencyConverter} formatDate={this.formatDate} />
                        </TabPanel>
                        <TabPanel>
                            <PatientServiceList service_list={this.state.servicesList} money_format={this.currencyConverter} />
                        </TabPanel>

                    </Tabs>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        patientDetails: state.patientDetails,
        doctorDetails: state.loginDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
        setOnUpdatePatientDetails: (p) => {
            dispatch(setUpdatePatientDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontDeskPatientProfile);
