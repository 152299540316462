import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./admissiondashboard.css";
import { connect } from "react-redux";
import { loginValidation } from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";
import MenuIcon from "../../Dashboard/utils/menu-icon";
import {FaBan, FaBed, FaCalendarPlus, FaHospitalUser, FaUserNurse} from "react-icons/fa";
import {FaBedPulse, FaTrowelBricks} from "react-icons/fa6";
import Chart from "react-google-charts";

const AdmissionDashboard = ({ permissionData, doctorDetails }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [roomStatus, setRoomStatus] = useState([])
    const [admissionStatus, setAdmissionStatus] = useState([])
    const [freeRooms, setFreeRooms] = useState(0)
    const [occupiedRooms, setOccupiedRooms] = useState(0)
    const [unavailableRooms, setUnavailableRooms] = useState(0)
    const [inpatient, setInpatient] = useState(0)

    useEffect(() => {
        if (loginValidation(window.location.pathname, permissionData) !== 'permitted') {
            window.location.href = '/';
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const dashboard_response = await axios.get(`${serverLink}report/admission/dashboard/${hospitalCode}`, doctorDetails.token);

            const admission = dashboard_response.data.admission;
            const rooms = dashboard_response.data.room;

            let admission_status = ['Active', 'Discharged'];
            let room_status = ['Available', 'Occupied', 'Under Maintenance'];

            //Set Count
            setInpatient(admission.filter(item => item.status === 'active').length)
            setFreeRooms(rooms.filter(item => item.status === 'available').length)
            setUnavailableRooms(rooms.filter(item => item.status === 'under maintenance').length)
            setOccupiedRooms(rooms.filter(item => item.status === 'occupied').length)

            //Initialize Data
            let admissionStatusData = [];
            let roomStatusData = [];

            //Admission trend by status
            admission_status.map((e, i) => {
                let count = admission.filter(item => item.status === e.toLowerCase()).length
                admissionStatusData.push([e, count]);
            })

            //Room trend by status
            room_status.map((e, i) => {
                let count = rooms.filter(item => item.status === e.toLowerCase()).length
                roomStatusData.push([e, count]);
            })

            //Set Chart Data
            setAdmissionStatus(admissionStatusData)
            setRoomStatus(roomStatusData)
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const admissionAnalyticsData = [["Status", "Count"], ...admissionStatus];
    const admissionAnalyticsOptions = {
        title: "Admission Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const roomAnalyticsData = [["Status", "Count"], ...roomStatus];
    const roomAnalyticsOptions = {
        title: "Room Trend By Status",
        is3D: true,
    };


    return (isLoading ? (
                <div className="dashboardpage">
                    <i className="fas fa-spinner fa-pulse fa-2x " />
                </div>
            ) :
        <div className="dashboardpage">
            <div className="topheader">
                <Navigator title="Admission Dashboard" />
            </div>

            <div className="first_section">
                <div className="row">
                    <div className="col-md-3">
                            <MenuIcon name="Inpatients" icon={<FaHospitalUser size={40}/>} count={inpatient}/>
                    </div>

                    <div className="col-md-3">
                            <MenuIcon name="Available Rooms" icon={<FaBed size={40}/>} count={freeRooms}/>
                    </div>

                    <div className="col-md-3">
                            <MenuIcon name="Occupied Rooms" icon={<FaBedPulse size={40}/>} count={occupiedRooms}/>
                    </div>

                    <div className="col-md-3">
                            <MenuIcon name="Rooms Under Maintenance" icon={<FaBan size={40}/>} count={unavailableRooms}/>
                    </div>

                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    admissionStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="310px"
                                        data={admissionAnalyticsData}
                                        options={admissionAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    roomStatus.length > 0 &&
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="310px"
                                        data={roomAnalyticsData}
                                        options={roomAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="col-md-12 mt-3 mb-0" style={{borderBottom: '1px solid #CCCCCC', borderTop: '1px solid #CCCCCC', padding: '5px'}}>
                            <h2><b>Menu</b></h2>
                        </div>
                    </div>

                    {permissionData &&
                        permissionData.map((perm, index) => {
                            if (perm.mainMenuName === 'Admission' && perm.mainMenuLink === null) {
                                return (
                                    <div key={index} className="col-sm-4">
                                        {" "}
                                        <Link to={perm.subMenuLink}>
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AdmissionDashboard);
