import React, { Component } from "react";
import "./birth-certificate-form.css";

class BirthCertificateForm extends Component {
    render() {
        return (
            <form onSubmit={this.props.onHandleSubmit}>
                <div className="">
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientSerial">Patient ID</label>
                            <input
                                name="patientSerial"
                                type="text"
                                className="form-control"
                                id="patientSerial"
                                disabled
                                value={this.props.patientSerial}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientName">Baby Name</label>
                            <input
                                name="babyName"
                                type="text"
                                className="form-control"
                                id="babyName"
                                disabled
                                value={this.props.patientName}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="title">Mother's Name <span className="text-danger">*</span></label>
                            <input
                                name="mother"
                                type="text"
                                className="form-control"
                                id="mother"
                                value={this.props.data.mother}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="title">Father's Name <span className="text-danger">*</span></label>
                            <input
                                name="father"
                                type="text"
                                className="form-control"
                                id="father"
                                value={this.props.data.father}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="title">Date Of Birth <span className="text-danger">*</span></label>
                            <input
                                name="dateOfBirth"
                                type="date"
                                className="form-control"
                                id="dateOfBirth"
                                max={new Date().toISOString().split('T')[0]}
                                value={this.props.data.dateOfBirth}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="title">Time Of Birth <span className="text-danger">*</span></label>
                            <input
                                name="timeOfBirth"
                                type="time"
                                className="form-control"
                                id="timeOfBirth"
                                value={this.props.data.timeOfBirth}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="title">Place Of Birth <span className="text-danger">*</span></label>
                            <input
                                name="placeOfBirth"
                                type="text"
                                className="form-control"
                                id="placeOfBirth"
                                value={this.props.data.placeOfBirth}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="title">Weight At Birth (in KG)<span className="text-danger">*</span></label>
                            <input
                                name="weightAtBirth"
                                type="number"
                                className="form-control"
                                id="weightAtBirth"
                                value={this.props.data.weightAtBirth}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-12 mb-3">
                            <label htmlFor="title">Delivered By <span className="text-danger">*</span></label>
                            <input
                                name="deliveredBy"
                                type="text"
                                className="form-control"
                                id="deliveredBy"
                                value={this.props.data.deliveredBy}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>

                </div>
                <div className="col-md-12">
                    <div className="mb-3" >
                        <label htmlFor="imageFiles" className="selectimage">Upload Certificate </label>
                        <div className="mb-3">
                            <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                    document.getElementById("photos").click()
                                }}>
                                    <div className="row">
                                        <i className="bi bi-image text-primary fs-3x"/>
                                        <div className="ms-4 col-md-9">
                                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                            <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .pdf, .jpg, .png, .jpeg are allowed"}</span>
                                        </div>
                                        <div className="col-md-2">{this.props.htmlelement !== "" ? <img className="img-thumbnail" width={350} height={350} srcSet={this.props.htmlelement !== "" ? this.props.htmlelement : ""}/> : ""}</div>
                                    </div>
                                </div>
                            </div>
                            <span className="alert-danger"> Max of 1MB file is allowed!</span>
                        </div>
                        <input type="file"  id="photos" name={"photos"}  accept={"image/*, .pdf"} onChange={this.props.onImageChanges} hidden />
                    </div>
                </div>
                <button className="btn btn-success btn-block savebtn" id="SubmissionBtn" type="submit">
                    Save
                </button>
            </form>
        );
    }
}

export default BirthCertificateForm;
