import React, {Component} from "react";
import "./updateroomtypes.css";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import AddRoomTypesForm from "../roomtypeform/roomtypesform";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import { connect } from "react-redux";


class UpdateRoomTypes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"hospital_room_type/update",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.roomTypesData._id,
                typeName:  this.props.roomTypesData.typeName,
                oldTypeName: this.props.roomTypesData.typeName,
                updatedBy: ""
            },


            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: localStorage.getItem("userID")
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdate();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdate() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy
        };

        if(this.state.formData.typeName.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Room Type Name"
            this.handleErrorDialog();
            return false;
        }
    

      
        axios.patch(`${this.state.serverLink}`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                 if (response === "success"){
                    this.state.messageDescription = "ROOM TYPE UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/hospital/room/type/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Room Type Updated"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <AddRoomTypesForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Room Type"
                            ></AddRoomTypesForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roomTypesData: state.personDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateRoomTypes);
