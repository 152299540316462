import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./addpatientdependant.css";
import Navigator from "../../../Settings/navigation/navigation";

class PatientDependantForm extends Component {
    render() {
        return (
            <div className="editd_person_details_formpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="title">Title <span className="text-danger">*</span></label>
                                            <select
                                                name="title"
                                                className="custom-select"
                                                id="title"
                                                value={this.props.data.title}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>Mr</option>
                                                <option>Mrs</option>
                                                <option>Mss</option>
                                                <option>Ms</option>
                                                <option>Dr</option>
                                                <option>Capt</option>
                                                <option>Prof</option>
                                                <option>HRH</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="firstName">First Name <span className="text-danger">*</span></label>
                                            <input
                                                name="firstName"
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                value={this.props.data.firstName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="middleName">Middle Name</label>
                                            <input
                                                name="middleName"
                                                type="text"
                                                className="form-control"
                                                id="middleName"
                                                value={this.props.data.middleName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="surname">Surname <span className="text-danger">*</span></label>
                                            <input
                                                name="surname"
                                                type="text"
                                                className="form-control"
                                                id="surname"
                                                value={this.props.data.surname}
                                                onChange={this.props.onEdit}

                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="gender">Gender <span className="text-danger">*</span></label>
                                            <select
                                                name="gender"
                                                className="custom-select"
                                                id="gender"
                                                value={this.props.data.gender}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>Male</option>
                                                <option>Female</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="phoneNumber">Phone Number <span className="text-danger">*</span></label>
                                            <input
                                                name="phoneNumber"
                                                type="text"
                                                className="form-control"
                                                id="phoneNumber"
                                                value={this.props.data.phoneNumber}
                                                onChange={this.props.onEdit}

                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="altPhoneNumber">Alt Phone Number</label>
                                            <input
                                                name="altPhoneNumber"
                                                type="text"
                                                className="form-control"
                                                id="altPhoneNumber"
                                                value={this.props.data.altPhoneNumber}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="dateOfBirth">Date of Birth <span className="text-danger">*</span></label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    style={{
                                                        padding: "0px 10px",
                                                        border: "1px solid rgb(197, 197, 197)",
                                                    }}
                                                    name="dateOfBirth"
                                                    id="dateOfBirth"
                                                    className="  form-control"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    value={this.props.date}
                                                    onChange={this.props.handleChange}
                                                    autoComplete="off"
                                                    maxDate={new Date(this.props.dateOfBirth)}
                                                    format="dd/MM/yyyy"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="bloodGroup">Blood Group <span className="text-danger">*</span></label>
                                            <select
                                                name="bloodGroup"
                                                type="text"
                                                className="form-control"
                                                id="bloodGroup"
                                                value={this.props.data.bloodGroup}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>A+</option>
                                                <option>A-</option>
                                                <option>AB+</option>
                                                <option>AB-</option>
                                                <option>B+</option>
                                                <option>B-</option>
                                                <option>O+</option>
                                                <option>O-</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="emailAddress">Email Address</label>
                                            <input
                                                name="emailAddress"
                                                type="email"
                                                className="form-control"
                                                id="emailAddress"
                                                value={this.props.data.emailAddress}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="maritalStatus">Marital Status <span className="text-danger">*</span></label>
                                            <select
                                                name="maritalStatus"
                                                className="custom-select"
                                                id="maritalStatus"
                                                value={this.props.data.maritalStatus}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>Single</option>
                                                <option>Married</option>
                                                <option>Divorced</option>
                                                <option>Widowed</option>
                                                <option>Separated</option>
                                            </select>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="religion">Religion <span className="text-danger">*</span></label>
                                            <select
                                                name="religion"
                                                className="custom-select"
                                                id="religion"
                                                value={this.props.data.religion}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>Christianity</option>
                                                <option>Islam</option>
                                                <option>Others</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="nextOfKinName">Next of Kin Name <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                name="nextOfKinName"
                                                className="form-control"
                                                id="nextOfKinName"
                                                value={this.props.data.nextOfKinName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="nextOfKinPhone">Next of Kin Phone <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                name="nextOfKinPhone"
                                                className="form-control"
                                                id="nextOfKinPhone"
                                                value={this.props.data.nextOfKinPhone}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="nextOfKinRelationship">Next of Kin Relationship <span className="text-danger">*</span></label>
                                            <select
                                                name="nextOfKinRelationship"
                                                className="custom-select"
                                                id="nextOfKinRelationship"
                                                value={this.props.data.nextOfKinRelationship}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>Father</option>
                                                <option>Mother</option>
                                                <option>Husband</option>
                                                <option>Wife</option>
                                                <option>Brother</option>
                                                <option>Sister</option>
                                                <option>Son</option>
                                                <option>Daughter</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="occupation">Occupation</label>
                                            <input
                                                type="text"
                                                name="occupation"
                                                className="form-control"
                                                id="occupation"
                                                value={this.props.data.occupation}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="residentialAddress">Residential Address <span className="text-danger">*</span></label>
                                            <input
                                                name="residentialAddress"
                                                type="text"
                                                className="form-control"
                                                id="residentialAddress"
                                                value={this.props.data.residentialAddress}
                                                onChange={this.props.onEdit}

                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="nationality">Nationality <span className="text-danger">*</span></label>
                                            <select
                                                name="nationality"
                                                id="nationality"
                                                className="form-control"
                                                value={this.props.data.nationality}
                                                onChange={this.props.onEdit}>
                                                <option></option>
                                                {
                                                    this.props.nationalityList.map((country, index) => {
                                                        return <option key={index} value={country}>{country}</option>
                                                    })
                                                }
                                            </select>
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="state">State <span className="text-danger">*</span></label>
                                            <select
                                                name="state"
                                                id="state"
                                                className="custom-select"
                                                value={this.props.data.state}
                                                onChange={this.props.onEdit}>
                                                <option></option>
                                                {
                                                    this.props.stateList.map((state, index) => {
                                                        return <option key={index} value={state}>{state}</option>
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="lga">LGA <span className="text-danger">*</span></label>
                                            <select
                                                name="lga"
                                                id="lga"
                                                className="custom-select"
                                                value={this.props.data.lga}
                                                onChange={this.props.onEdit}>
                                                <option></option>
                                                {
                                                    this.props.lgaList.map((lga, index) => {
                                                        return <option key={index} value={lga}>{lga}</option>
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="ward">Ward</label>
                                            <input
                                                name="ward"
                                                type="text"
                                                className="form-control"
                                                id="ward"
                                                value={this.props.data.ward}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="zipCode">Zip</label>
                                            <input
                                                name="zipCode"
                                                type="text"
                                                className="form-control"
                                                id="zipCode"
                                                value={this.props.data.zipCode}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="officeAddress">Office Address</label>
                                                <textarea
                                                    name="officeAddress"
                                                    className="form-control"
                                                    id="officeAddress"
                                                    defaultValue={this.props.data.officeAddress}
                                                    onChange={this.props.onEdit}
                                                    rows="3"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="container">
                                    <div className="row second_section">
                                        <div className="clo-sm-6">
                                            <div className="profileimage">{this.props.htmlelement}</div>
                                        </div>

                                        <div className="col-sm-6 btn_section">
                                            <label htmlFor="files" className="selectimage">
                                                Upload Image
                                            </label>
                                            <input
                                                name="avatarimage"
                                                id="files"
                                                type="file"
                                                accept="image/*"
                                                onChange={this.props.onImageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PatientDependantForm;
