import React, { Component } from "react";
import "./hospitallist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import { serverLink, hospitalCode } from "../../../resources/url";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";

class HospitalList extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"hospital/",
            searchText: "",
            isLoading: true,
            limit: 50,

            isLoadMoreData: false,

            totalNumOfHospitals: null,
            noMoreDataText: "",

            openErrorDialog: false,

            hospitalList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedHospitalName: "",
            selectedHospitalId: "",
        };
    }
    componentDidMount() {
        this.onSetTotalNumOfHospitals();
        this.fetchHospitals(this.state.limit);
    }

    async fetchHospitals(limit) {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}list/${limit}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    hospitalList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    showMoreHospitals = () => {
        if (this.state.limit <= this.state.totalNumOfHospitals) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchHospitals(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Hospitals",
            });
        }
    };

    async onSetTotalNumOfHospitals() {
        this.setState({ isLoading: true });
        await fetch(`${this.state.serverLink}count`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({ totalNumOfHospitals: data, isLoading: false });
            });
    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");

            await fetch(`${this.state.serverLink}search/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            hospitalList: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Hospital record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Hospital List" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search hospital by code, or name"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"/>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.hospitalList.length}{" "}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Hospital Name</th>
                            <th scope="col">Hospital Code</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email</th>
                            <th scope="col">Address</th>
                            <th scope="col">LGA</th>
                            <th scope="col">State</th>
                            <th scope="col">Option</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "/>
                        ) : this.state.hospitalList.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Hospital Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.hospitalList &&
                                this.state.hospitalList.map((p) => {
                                    count++;
                                    return (
                                        <tr key={p._id}>
                                            <td className="align-middle">{count}</td>
                                            <td className="align-middle">
                                                {p.hospitalName}
                                            </td>
                                            <td className="align-middle">
                                                {p.hospitalCode}
                                            </td>
                                            <td className="align-middle">
                                                {" "}
                                                {p.phoneNumber === "" ? "N/A" : p.phoneNumber}
                                            </td>
                                            {<td className="align-middle">
                                                {" "}
                                                {p.emailAddress === "" ? "N/A" : p.emailAddress}
                                            </td>}
                                            <td className="align-middle">
                                                {p.hospitalAddress === "" ? "N/A" : p.hospitalAddress}
                                            </td>
                                            <td className="align-middle">
                                                {p.hospitalLGA}
                                            </td>
                                            <td className="align-middle">
                                                {p.hospitalState}
                                            </td>
                                            <td className="align-middle">
                                                <Link to="profile">
                                                    <button
                                                        onClick={async () => {
                                                            const sendData = {
                                                                ...p,
                                                                personId: p._id,
                                                            };
                                                            this.props.setOnPatientDetails(sendData);
                                                        }}
                                                        type="button"
                                                        className="btn btn-success"
                                                    >
                                                        <i className="fa fa-eye" aria-hidden="true"/>
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>

                    <div className="loadmoredatasection">
                        {this.state.isLoadMoreData ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"/>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.hospitalList.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMoreHospitals()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(HospitalList);
