import React, { Component } from "react";
import "./testlist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setServiceDetails
} from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import { serverLink, hospitalCode } from "../../../../resources/url";
import {loginValidation} from "../../../../context/loginvalidation";
import Navigator from "../../../Settings/navigation/navigation";
import axios from "axios";
import DataTable from "../../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../../resources/constants";
class LabTestList extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"laboratory/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            access: window.location.href.split("/")[window.location.href.split("/").length - 1],

            isLoadMoreData: false,

            totalNumOfLabTest: null,
            noMoreDataText: "",

            labTestList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedLabTestID: "",
            selectedLabTestName: "",

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchLabTest();
    }

    async fetchLabTest() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}test/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    labTestList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    onHandleDelete = (e) => {
        this.setState({
            selectedLabTestName: e.testName,
            selectedLabTestID: e._id,
            openConfirmDialog: true
        });
    }

    deleteTest = async () => {
        await axios.delete(`${serverLink}laboratory/test/delete/${this.state.selectedLabTestID}`, this.props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    this.closeConfirmDialog();
                    this.fetchLabTest();

                }
            })
            .catch(error => {
                console.log('NETWORK ISSUE', error)
            })
    }

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    showTable = () => {

        try {
            return this.state.labTestList.map((lab, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td> {lab.testName}</td>
                        <td>{lab.laboratoryName}</td>
                        <td>{lab.specimen.map((spec, index) => {
                            return <div key={index}>{spec.specimenName} ({this.currencyConverter(spec.testAmount)} )<br/></div>
                        })}</td>
                        <td>
                            {this.state.access === 'list' ?
                                <div className="d-flex">
                                    <button
                                        onClick={() => {
                                            this.onHandleDelete(lab)
                                        }}
                                        type="button"
                                        className="btn btn-danger"
                                    >
                                        <i className="fa fa-trash" title={`Delete ${lab.testName}`} aria-hidden="true"/>
                                    </button>
                                    <Link to="/lab/test/update">
                                        <button
                                            onClick={async () => {
                                                this.props.setOnLabDetails(lab);
                                            }}
                                            type="button"
                                            className="btn btn-success"
                                        >
                                            <i className="fa fa-edit" aria-hidden="true"/>
                                        </button>
                                    </Link></div>
                                : <></>}
                        </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    render() {
        const header = ["S/N", "Test Name", "Laboratory Name", "Specimen", "Option"];

        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Laboratory Test"/>
                    </div>
                    <hr/>
                </div>
                <ConfirmDialogBox
                    openDailog={this.state.openConfirmDialog}
                    onSetOpenDialog={this.closeConfirmDialog}
                    handleConfirmOkBtn={this.deleteTest}
                    isLoading={this.state.isDeleting}
                    title="Delete"
                    des={
                        "Are you sure to delete " +
                        this.state.selectedLabTestName
                    }
                />

                <ErrorDialogBox
                    openDialog={this.state.openErrorDialog}
                    onSetOpenDialog={this.closeErrorDialog}
                    title="Record Not Found"
                    des="lab test not found. Please try again!"
                />
                <div className="card">
                    <div className="card-body">
                        <div className="">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="page-pretitle">
                                    </div>
                                    <h3 className="card-title">Laboratory Test List</h3>
                                </div>
                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">
                                        {this.state.access === 'list' ?  <Link to="/lab/test/add">
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                            >
                                                + Add Test
                                            </button>
                                        </Link> : <></> }
                                    </div>
                                </div>
                            </div>
                            <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')}
                                       body={this.showTable()} title={`Laboratory Test Report`}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLabDetails: (p) => {
            dispatch(setServiceDetails(p));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LabTestList);
