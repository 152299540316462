import React, {useEffect} from "react";

// import the ECT package and style
import * as ECT from "@whoicd/icd11ect";
import "@whoicd/icd11ect/style.css";
import "./styles.css";
import {connect} from "react-redux";
import {showAlert, showConfirm} from "../../Common/sweetalert/sweetalert";
import axios from "axios";
import {serverLink, hospitalCode} from "../../../resources/url";

function ECTReactComponent ({patientSerial, patientName, type, action_id, doctorDetails}) {
    const iNo = 1;

    const configuration = () => {
        // configure the ECT
        const settings = {
            // the API located at the URL below should be used only for software development and testing
            apiServerUrl: "https://icd11restapi-developer-test.azurewebsites.net",
            autoBind: false // in React we recommend using the manual binding
        };
        const callbacks = {
            selectedEntityFunction: (selectedEntity) => {
                // shows an alert with the code selected by the user and then clears the search results
                handleSubmit(selectedEntity)
                //ECT.Handler.clear(this.iNo);
            }
        };
        ECT.Handler.configure(settings, callbacks);
    }

    const handleSubmit = async (selectedItem) => {
        const sendData = {
            patientSerial: patientSerial,
            patientName: patientName,
            actionID: action_id,
            actionType: type,
            doctorID: doctorDetails.userID,
            doctorName: doctorDetails.fullName,
            icdCode: selectedItem.code,
            icdTitle: selectedItem.title,
            hospitalCode: hospitalCode
        }

        showConfirm(
            "CONFIRM",
            `Are you sure you want to add the ${sendData.icdTitle}: ${sendData.icdCode}?`,
            "warning"
        ).then( async (IsConfirmed) => {
            if (IsConfirmed)  {
                await axios.post(`${serverLink}icd/add`, sendData, doctorDetails.token)
                    .then( res => {
                        if (res.data.message === 'success')
                            showAlert("ADDED", "Code added successfully", "success");
                        else
                            showAlert("ERROR", "Something went wrong adding code. Please try again", "error");
                    })
                    .catch(err => {
                        showAlert("ERROR", "Something went wrong adding code. Please try again", "error");
                    })
            } else {
                showAlert("CANCELLED", "Cancelled Successfully", "error");
            }
        });
    }

    useEffect(() => {
        configuration();
        ECT.Handler.bind(iNo);
    }, [])

    return (
        <div className="row">
            <div className="col-12">
                Type for starting search:
                {/* input element used for typing the search */}
                <input
                    type="text"
                    className="ctw-input form-control"
                    autoComplete="off"
                    data-ctw-ino={iNo}
                />
            </div>
            <div className="col-12">
                {/* div element used for showing the search results */}
                <div className="ctw-window" data-ctw-ino={iNo} />
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(ECTReactComponent);