import React, { Component } from "react";
import "./packageform.css";
import Navigator from "../../../Settings/navigation/navigation";

class AddPackageForm extends Component {
    constructor() {
        super();
        this.state = {
            months:  Array(25 - 1).fill().map((d, i) => i + 1),
        }
    }
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="packageName"> Package Name <span className="text-danger">*</span></label>
                                            <input
                                                name="packageName"
                                                type="text"
                                                className="form-control"
                                                id="packageName"
                                                value={this.props.data.packageName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="amount"> Package Amount <span className="text-danger">*</span></label>
                                            <input
                                                name="amount"
                                                type="number"
                                                className="form-control"
                                                id="amount"
                                                value={this.props.data.amount}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="duration"> Package Duration (in months)<span className="text-danger">*</span></label>
                                            <select
                                                name="duration"
                                                className="custom-select"
                                                id="duration"
                                                value={this.props.data.duration}
                                                onChange={this.props.onEdit}
                                            >
                                                <option value="">Select Duration</option>
                                                {
                                                    this.state.months && this.state.months.map((duration, key) => {
                                                        if(duration !== [])
                                                            return (
                                                                <option key={key} value={duration}>{duration}</option>
                                                            )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="status"> Package Status<span className="text-danger">*</span></label>
                                            <select
                                                name="status"
                                                className="custom-select"
                                                id="status"
                                                value={this.props.data.status}
                                                onChange={this.props.onEdit}
                                            >
                                                <option value="">Select Status</option>
                                                <option value="active">active</option>
                                                <option value="inactive">inactive</option>
                                            </select>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="description">Package Description </label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                                id="description"
                                                value={this.props.data.description}
                                                onChange={this.props.onEdit}
                                                rows="10"
                                            >{this.props.data.description}</textarea>
                                        </div>
                                    </div>

                                    <div className="">

                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <button className="btn btn-success btn-block update_btn" type="submit">
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddPackageForm;
