import React, { Component } from "react";
import "./findpatient.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import {serverLink, hospitalCode} from "../../../resources/url";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";
import DataTable from "../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../resources/constants";
class FindPatients extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 50,
            passportLink: serverLink+'public/uploads/patient_passport/',

            isLoadMoredata: false,

            totalNumOfPatient: null,
            noMoreDataText: "",

            openErrorDialog: false,

            patientlist: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedPatientName: "",
            selectedPatientId: "",
        };
    }
    componentDidMount() {
        this.onSetTotalNumOfPatients();
    }

    async fetchPatient(limit) {
        this.setState({ isLoadMoredata: true });
        await fetch(`${this.state.serverLink}patient/all/${this.state.hospitalCode}/${limit}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientlist: data,
                    isLoadMoredata: false,
                    isLoading: false,
                });
            });
    }

    showMorePatient = () => {
        if (this.state.limit <= this.state.totalNumOfPatient) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchPatient(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Patients",
            });
        }
    };

    async onSetTotalNumOfPatients() {
        this.setState({ isLoading: true });
        await fetch(`${this.state.serverLink}patient/count/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({ totalNumOfPatient: data, isLoading: false });
            });
    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");
            await fetch(`${this.state.serverLink}patient/search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            patientlist: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });
        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    showTable = () => {

        try {
            return this.state.patientlist.map((item, index) => {
                let dateOfBirthDate = new Date(item.dateOfBirth);
                const dob = `${dateOfBirthDate.getDate()}/${
                    dateOfBirthDate.getMonth() + 1
                }/${dateOfBirthDate.getFullYear()}`;
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.firstName + " " + item.middleName + " " + item.surname}</td>
                        <td>{item.gender}</td>
                        <td> {item.dateOfBirth === "" ? "N/A" : dob}</td>
                        <td> {item.phoneNumber === "" ? "N/A" : item.phoneNumber}</td>
                        <td>{item.emailAddress === "" ? "N/A" : item.emailAddress}</td>
                        <td>{item.state === "" ? "N/A" : item.state}</td>
                        <td> {item.lga === "" ? "N/A" : item.lga}</td>
                        <td>{item.bloodGroup}</td>
                        <td>
                            <Link to={"patient/" + item._id}>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    <i className="fa fa-edit" aria-hidden="true"/>
                                </button>
                            </Link>
                        </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    render() {
        const header = ["S/N", "ID", "Name", "Sex", "Date of Birth",  "Mobile",  "Email",  "State", "LGA", "BG", "Option"];
        return this.state.isLoading ? (
            <div className="patientlistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Find Patients"/>
                    </div>
                    <hr/>
                </div>
                <ErrorDialogBox
                    openDialog={this.state.openErrorDialog}
                    onSetOpenDialog={this.closeErrorDialog}
                    title="Record Not Found"
                    des="Patient record not found. Please try again!"
                />
                <div className="card">
                    <div className="card-body">
                        <div className="search mt-3">
                            <input
                                type="text"
                                className="searchTerm"
                                placeholder="Search patient by first, middle, surname, email, phone number or patient ID"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        this.handleSearch();
                                    }
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        searchText: e.target.value,
                                    });
                                }}
                            />

                            <button
                                onClick={this.handleSearch}
                                type="submit"
                                className="searchButton"
                            >
                                <i className="fa fa-search"/>
                            </button>
                        </div>
                        <div className="">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="page-pretitle">
                                    </div>
                                    <h3 className="card-title">Find Patients</h3>
                                </div>
                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">

                                    </div>
                                </div>
                            </div>
                            <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')}
                                       body={this.showTable()} title={`Find Patients Report`}/>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FindPatients);
