import React from "react";
import {serverLink} from "../../../../resources/url";
import {formatDateAndTime} from "../../../../resources/constants";
import {connect} from "react-redux";
import {showAlert, showConfirm} from "../../../Common/sweetalert/sweetalert";
import axios from "axios";

function ICDtable({data, doctorDetails, userType}) {

    const handleDelete = async (item) => {
        showConfirm(
            "CONFIRM",
            `Are you sure you want to delete the ${item.icdTitle}: ${item.icdCode}?`,
            "warning"
        ).then( async (IsConfirmed) => {
            if (IsConfirmed)  {
                await axios.delete(`${serverLink}icd/delete/${item._id}`, doctorDetails.token)
                    .then( res => {
                        if (res.data.message === 'success') {
                            showAlert("DELETED", "Code delete successfully", "success")
                                .then((okay) => {
                                    if (okay) {
                                        window.location.reload()
                                    }
                                });
                        }
                        else
                            showAlert("ERROR", "Something went wrong deleting code. Please try again", "error");
                    })
                    .catch(err => {
                        showAlert("ERROR", "Something went wrong deleting code. Please try again", "error");
                    })
            } else {
                showAlert("CANCELLED", "Cancelled Successfully", "error");
            }
        });
    }
    return (
        data.length > 0 ?
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Code</th>
                        <th>Title</th>
                        <th>Added By</th>
                        <th>Added Date</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item, index) => {
                            return <tr key={item._id}>
                                <td>{index+1}</td>
                                <td>{item.icdCode}</td>
                                <td>{item.icdTitle}</td>
                                <td>{item.doctorName}</td>
                                <td>{formatDateAndTime(item.submittedOn, 'date')}</td>
                                <td>
                                    {
                                        userType === "nurse" || userType === "service-desk"  ? <></> :
                                        doctorDetails.userID === item.doctorID &&
                                        <button className="btn btn-danger btn-sm" onClick={() => handleDelete(item)}><i className="fa fa-trash" /></button>
                                    }
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                :
                <div className="p-3">
                    <span className="alert alert-info">No Code Found</span>
                </div>
    )
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(ICDtable);
