import React, { Component } from "react";
import "./imaginglabform.css";
import Navigator from "../../../Settings/navigation/navigation";

class ImagingLabForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="labName"> Lab Name <span className="text-danger">*</span></label>
                                            <input
                                                name="labName"
                                                type="text"
                                                className="form-control"
                                                id="labName"
                                                value={this.props.data.labName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 offset-sm-3 mb-3">
                                        <button className="btn btn-success btn-block update_btn" type="submit">
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImagingLabForm;
