import React, {Component} from "react";
import "./updatemenu.css";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import { connect } from "react-redux";
import AddMenuForm from "../addmenuform/addmenuform";


class UpdateMainMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"settings/",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.menuData._id,
                oldMenuName: this.props.menuData.menuName,
                menuName: this.props.menuData.menuName,
                menuIcon: this.props.menuData.menuIcon,
                menuLink: this.props.menuData.menuLink,
                submittedBy: ""
            },

            messageDescription: "",
            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: localStorage.getItem("userID")
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateLab();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdateLab() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy,
            hospitalCode: hospitalCode
        };

        if(this.state.formData.menuName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Main Menu Name"
            this.handleErrorDialog();
            return false;
        }


        axios.patch(`${this.state.serverLink}main_menu/update`, sendData, this.props.loginData.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "MAIN MENU UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/settings/menu/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Main Menu Updated"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <AddMenuForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Main Menu"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menuData: state.personDetails,
        loginData: state.loginDetails
    };
};
export default connect(mapStateToProps, null)(UpdateMainMenu);
