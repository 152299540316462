import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./patientprofile.css";
import {setpersonDetails, setUpdatePatientDetails} from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import FormPrompt from "../../DialogBoxes/formprompt";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PatientActionButton from "../utils/patientaction";
import AddAppointment from "../../Appointment/addappointment/addappointment";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PatientAppointmentList from "../utils/patientappointment";
import PatientAllergyList from "../utils/patientallergies";
import AddAllergy from "../addpatientallergy/addpatientallergy";
import AppointmentCheckIn from "../../Appointment/appointmentcheckin/appointmentcheckin";
import CaptureVital from "../../Appointment/capturevital/capturevital";
import PatientVitalList from "../utils/patientvitals";
import {serverLink, hospitalCode, calculateAge} from "../../../resources/url";
import PatientDependantList from "../utils/patientdependantlist";
import PatientPaymentList from "../utils/patientpayments";
import AddMedicalRecord from "../addmedicalrecord/addmedicalrecord";
import PatientMedicalRecordList from "../utils/patientpreviousmedicalrecord";
import AddPatientOtherInfo from "../addpatientotherinfo/addpatientotherinfo";
import PatientOtherInfo from "../utils/patientotherinfo";
import Navigator from "../../Settings/navigation/navigation";
import ServiceRequest from "../../Services/servicerequest/servicerequest";
import PatientServiceList from "../../Services/patientservicelist/patientservicelist";
import Birth from "../birth-certificate/birth-certificate";
import DeathCert from "../death-certificate/death-certificate";
import BirthAndDeathCertificate from "../utils/birth-and-death-certificate";
import {checkImageURL, defaultImage, imageExists} from "../../../resources/constants";
import doctor from '../../../Images/doctor.jpg'
import woman_doctor from '../../../Images/woman-doctor.jpg'

class PatientProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+ "patient/",
            generalServerLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,
            appointmentLimit: 10,
            passport: imageExists(`${serverLink}public/uploads/patient_passport/${this.props.patientDetails.passport}`) ? `${serverLink}public/uploads/patient_passport/${this.props.patientDetails.passport}` : this.props.patientDetails.gender === 'Male' ? doctor : woman_doctor,
            patientEntryID: this.props.patientDetails._id,
            patientSerial: this.props.patientDetails.patientSerial,
            lastVisit: this.props.patientDetails.lastVisit,
            firstName: this.props.patientDetails.firstName,
            middleName: this.props.patientDetails.middleName,
            surname: this.props.patientDetails.surname,
            patientName: `${this.props.patientDetails.firstName} ${this.props.patientDetails.middleName} ${this.props.patientDetails.surname}`,
            emailAddress: this.props.patientDetails.emailAddress,
            phoneNumber: this.props.patientDetails.phoneNumber,
            altPhoneNumber: this.props.patientDetails.altPhoneNumber,
            dateOfBirth: this.props.patientDetails.dateOfBirth,
            bloodGroup: this.props.patientDetails.bloodGroup,
            gender: this.props.patientDetails.gender,
            maritalStatus: this.props.patientDetails.maritalStatus,
            occupation: this.props.patientDetails.occupation,
            religion: this.props.patientDetails.religion,
            residentialAddress: this.props.patientDetails.residentialAddress,
            status: this.props.patientDetails.status,
            state: this.props.patientDetails.state,
            lga: this.props.patientDetails.lga,
            ward: this.props.patientDetails.ward,
            zipCode: this.props.patientDetails.zipCode,
            officeAddress: this.props.patientDetails.officeAddress,
            nextOfKinPhone: this.props.patientDetails.nextOfKinPhone,
            nextOfKinName: this.props.patientDetails.nextOfKinName,
            nextOfKinRelationship: this.props.patientDetails.nextOfKinRelationship,
            walletAmount: this.props.patientDetails.walletAmount,
            walletAmountConverter: this.currencyConverter(0),

            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openShowAllergyImageDialog: false,
            openShowHospitalRecordDialog: false,
            openShowImagingDialog: false,
            openAllergyDialog: false,
            openHospitalRecordDialog: false,
            openServiceDialog: false,
            openOtherInfoDialog: false,
            openBirthDialog: false,
            openDeathDialog: false,
            openVitalDialog: false,
            openCheckinDialog: false,

            allergyImageSrc: "",
            ImageSrc: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            isCloseDialogAppear: true,

            messageTitle: "",
            messageDescription: "",

            patientDetails: this.props.patientDetails,

            allergicList: [],
            medicalRecordList: [],
            appointmentList: [],
            allotmentList: [],
            vitalList: [],
            paymentList: [],
            servicesList: [],
            birthCertificate: [],
            deathCertificate: [],
            dependantList: this.props.patientDetails.dependantList
        };
    }

    componentDidMount() {
        this.isComponentMounted = true
        this.getCurrentWallet();
        this.getPatientAppointment();
        this.getPatientAllergies();
        this.getPatientVitals();
        this.getPatientPayments();
        this.getPatientPreviousMedicalRecord();
        this.getServicesList();
        this.getBirthCertificate();
        this.getDeathCertificate();
    }

    // componentWillUnmount() {
    //     this.isComponentMounted = false; // Set the flag to indicate component unmounting
    //     if (this.getCurrentWallet) {
    //         this.getCurrentWallet.cancel(); // Cancel the promise (if supported by your promise library)
    //     }
    //     if (this.getPatientAppointment) {
    //         this.getPatientAppointment.cancel();
    //     }
    //     if (this.getPatientAllergies) {
    //         this.getPatientAllergies.cancel();
    //     }
    //     if (this.getPatientVitals) {
    //         this.getPatientVitals.cancel();
    //     }
    //     if (this.getPatientPayments) {
    //         this.getPatientPayments.cancel();
    //     }
    //     if (this.getPatientPreviousMedicalRecord) {
    //         this.getPatientPreviousMedicalRecord.cancel();
    //     }
    //     if (this.getServicesList) {
    //         this.getServicesList.cancel();
    //     }
    //     if (this.getBirthCertificate) {
    //         this.getBirthCertificate.cancel();
    //     }
    //     if (this.getDeathCertificate) {
    //         this.getDeathCertificate.cancel();
    //     }
    //
    // }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    getCurrentWallet = () => {
        axios.get(`${this.state.serverLink}current_wallet/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(wallet => {
                this.setState({
                    walletAmount: wallet.data.walletAmount,
                    walletAmountConverter: wallet.data.walletAmount ?
                        this.currencyConverter(wallet.data.walletAmount) : 0.00
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientAppointment = () => {
        axios.get(`${this.state.generalServerLink}appointment/patient/${this.state.hospitalCode}/${this.state.patientSerial}/${this.state.appointmentLimit}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    appointmentList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientVitals = () => {
        axios.get(`${this.state.generalServerLink}vital/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    vitalList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientAllergies = () => {
        axios.get(`${this.state.generalServerLink}allergy/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    allergicList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientPreviousMedicalRecord = () => {
        axios.get(`${this.state.generalServerLink}patient/previous_medical_record/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    medicalRecordList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getPatientPayments = () => {
        axios.get(`${this.state.generalServerLink}payment/patient_payments/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    paymentList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getServicesList = () => {
        axios.get(`${this.state.generalServerLink}service/request/patient/${this.state.patientSerial}/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    servicesList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getBirthCertificate = () => {
        axios.get(`${this.state.generalServerLink}patient/birth-certificate/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    birthCertificate: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    getDeathCertificate = () => {
        axios.get(`${this.state.generalServerLink}patient/death-certificate/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    deathCertificate: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()}
                    
                    `
        } else {
            return '--'
        }
    };

    handleAppointmentActionClicked = () => {
        const consultation_fee = localStorage.getItem('consultationFee');
        if (parseInt(consultation_fee) > this.state.walletAmount) {
            this.state.messageTitle = "INSUFFICIENT FUNDS"
            this.state.messageDescription = "Insufficient amount in the patient wallet. Direct the patient to the finance department to make payment!"
            this.handleErrorDialog();
        } else {
            this.setState({
                openAddAppointmentDialog: true
            });
        }
    }

    handleUpdateAppointmentActionClicked = () => {
        this.setState({
            openUpdateAppointmentDialog: true
        });

    }

    handleViewAllergyImageClicked = (e) => {

        this.setState({
            allergyImageSrc: e.target.srcset,
            openShowAllergyImageDialog: true
        });

    }

    handleViewImageClicked = (e) => {

        this.setState({
            ImageSrc: e.target.srcset,
            openShowHospitalRecordDialog: true
        });
    }

    handleCheckinActionClicked = () => {
        this.setState({
            openCheckinDialog: true,
        });
    }

    handleVitalActionClicked = () => {
        this.setState({
            openVitalDialog: true
        })
    }

    handleAllergyActionClicked = () => {
        this.setState({
            openAllergyDialog: true
        });
    }

    handlehospitalRecordActionClicked = () => {
        this.setState({
            openHospitalRecordDialog: true
        });
    }

    handlePatientServiceActionClicked = () => {
        this.setState({
            openServiceDialog: true
        });
    }

    handlePatientOtherInfoClicked = () => {
        this.setState({
            openOtherInfoDialog: true
        });
    }


    handleSuccessDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openOtherInfoDialog: false,
            openBirthDialog: false,
            openDeathDialog: false,
            openAlertDialog: true,
        });
    };

    handleErrorDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAllergyDialog: false,
            openImageDialog: false,
            openAddAppointmentDialog: false,
            openOtherInfoDialog: false,
            openUpdateAppointmentDialog: false,
            openBirthDialog: false,
            openDeathDialog: false,
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };

    onHandleErrorDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openBirthDialog: false,
            openDeathDialog: false,
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };

    onHandleSuccessDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openBirthDialog: false,
            openDeathDialog: false,
            openAlertDialog: true,
        });
    };

    closeAddAppointmentDialog = () => {
        this.setState({
            openAddAppointmentDialog: false,
        });
    };
    closeCheckInDialog = () => {
        this.setState({
            openCheckinDialog: false,
        });
    };
    closeVitalDialog = () => {
        this.setState({
            openVitalDialog: false,
        });
    };
    closeAllergyImageDialog = () => {
        this.setState({
            openShowAllergyImageDialog: false,
        });
    };
    closeHospitalRecordViewDialog = () => {
        this.setState({
            openShowHospitalRecordDialog: false,
        });
    };

    closeAllergyDialog = () => {
        this.setState({
            openAllergyDialog: false,
        });
    };
    closeHospitalRecordDialog = () => {
        this.setState({
            openHospitalRecordDialog: false,
        });
    };

    closeServiceDialog = () => {
        this.setState({
            openServiceDialog: false,
        });
        window.location.reload();
    };

    closeOtherInfoDialog = () => {
        this.setState({
            openOtherInfoDialog: false,
        });
    };

    closeAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.reload();
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };
    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    handleBirthClicked = () => {
        this.setState({
            openBirthDialog: true
        });
    }

    handleDeathClicked = () => {
        this.setState({
            openDeathDialog: true
        });
    }
    closeBirthDialog = () => {
        this.setState({
            openBirthDialog: false,
        });
    };
    closeDeathDialog = () => {
        this.setState({
            openDeathDialog: false,
        });
    };

    render() {
        let count = 0;
        const isDeceased = this.state.status === "Deceased";
        return this.state.isLoading ? (
            <div className="patientprofilepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="patientprofilepage">
                <div className="main_section">
                    <FormPrompt
                        openDialog={this.state.openAddAppointmentDialog}
                        title="Add New Appointment"
                        onSetOpenDialog={this.closeAddAppointmentDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AddAppointment
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                            patientWallet={this.state.walletAmount}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openAllergyDialog}
                        title="Add New Allergy"
                        onSetOpenDialog={this.closeAllergyDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AddAllergy
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                        />
                    </FormPrompt>
                    <FormPrompt
                        openDialog={this.state.openBirthDialog}
                        title="Upload Birth Certificate"
                        onSetOpenDialog={this.closeBirthDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <Birth
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.onHandleSuccessDialog}
                            handleErrorDialog={this.onHandleErrorDialog}
                            patientData={this.props.patientDetails}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openHospitalRecordDialog}
                        title="Add Previous Hospital Record"
                        onSetOpenDialog={this.closeHospitalRecordDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AddMedicalRecord
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openOtherInfoDialog}
                        title="Add Patient Other Info"
                        onSetOpenDialog={this.closeOtherInfoDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AddPatientOtherInfo
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openShowAllergyImageDialog}
                        title="Allergy Reaction Image"
                        onSetOpenDialog={this.closeAllergyImageDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <img srcSet={this.state.allergyImageSrc} alt="Allergy Image"/>
                    </FormPrompt>


                    <FormPrompt
                        openDialog={this.state.openShowHospitalRecordDialog}
                        title="Patient Imaging"
                        onSetOpenDialog={this.closeHospitalRecordViewDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <img srcSet={this.state.ImageSrc} alt="Imaging"/>
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openCheckinDialog}
                        title="CHECK IN PATIENT"
                        onSetOpenDialog={this.closeCheckInDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AppointmentCheckIn
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                            patientAppointment={this.state.appointmentList}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openCheckinDialog}
                        title="CHECK IN PATIENT"
                        onSetOpenDialog={this.closeCheckInDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <AppointmentCheckIn
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                            patientAppointment={this.state.appointmentList}
                        />
                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openVitalDialog}
                        title="CAPTURE VITALS"
                        onSetOpenDialog={this.closeVitalDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <CaptureVital
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                            patientAppointment={this.state.appointmentList}
                        />
                    </FormPrompt>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <FormPrompt
                        openDialog={this.state.openServiceDialog}
                        title="SERVICES"
                        onSetOpenDialog={this.closeServiceDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                        isFullWidth={true}
                    >
                        <ServiceRequest patient_id={this.state.patientSerial} patient_name={this.state.patientName} patient_wallet={this.state.walletAmount} money_format={this.currencyConverter} />

                    </FormPrompt>

                    <FormPrompt
                        openDialog={this.state.openDeathDialog}
                        title="Upload Death Certificate"
                        onSetOpenDialog={this.closeDeathDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <DeathCert
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.props.patientDetails}
                        />
                    </FormPrompt>


                    <div className="topheader">
                        <Navigator title="Patient Profile" />
                    </div>

                    <div className="row">
                        <div className="col-sm-2">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="text-secondary mb-1">{this.state.patientSerial}</strong>
                                </div>
                                <div className="d-flex flex-column align-items-center text-center">
                                    <div className="profileimage">
                                        <div className="patientlistpage mt-2">
                                            <img
                                                className="patient_passport img-fluid"
                                                srcSet={this.state.passport}
                                                alt="profileImage"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <h4>{this.state.firstName} {this.state.middleName} {this.state.surname}</h4>
                                        <p className="text-muted font-size-sm mb-0 pb-0">Last visit: {
                                            this.formatDate(this.state.lastVisit)
                                        }</p>
                                        <small className={this.state.status === "Deceased" ? `badge badge-danger text-light mb-2` :  `badge badge-success text-light mb-2`}> Status: {
                                            this.state.status === "Deceased" ? this.state.status : "Active"
                                        }</small><br/>
                                        <Link to={isDeceased ? "" : "/patient/update"}>
                                            <button
                                                onClick={async () => {
                                                    const sendData = {
                                                        ...this.props.patientDetails,
                                                        personId: this.state.patientEntryID,
                                                    };
                                                    this.props.setOnUpdatePatientDetails(sendData);
                                                }}
                                                type="button"
                                                className="btn btn-outline-primary mb-2"
                                            >
                                                Update Info <i className="fa fa-edit" aria-hidden="true"/>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-5">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Phone Number</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.phoneNumber} {this.state.altPhoneNumber ? ' | ' + this.state.altPhoneNumber : ''}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Date of Birth</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.dateOfBirth ? this.formatDate(this.state.dateOfBirth) +  `  (${calculateAge(this.state.dateOfBirth)}  ${calculateAge(this.state.dateOfBirth) > 1 ?  "Years Old" : "Year Old"})` : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Blood Group</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.bloodGroup ? this.state.bloodGroup : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Gender</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.gender ? this.state.gender : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Marital Status</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.maritalStatus ? this.state.maritalStatus : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Next of Kin</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            Name: {this.state.nextOfKinName ? this.state.nextOfKinName : 'N/A'}
                                            <br/>
                                            Phone: {this.state.nextOfKinPhone ? this.state.nextOfKinPhone : 'N/A'}
                                            <br/>
                                            Relationship: {this.state.nextOfKinRelationship ? this.state.nextOfKinRelationship : 'N/A'}
                                            <br/>

                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Address</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.residentialAddress ? this.state.residentialAddress : 'N/A'}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="card mb-3">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="wallet">WALLET: {this.state.walletAmountConverter}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handleAppointmentActionClicked}
                                            actionName="Add Appointment"
                                            actionIcon="fa fa-calendar fa-2x text-info"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handleCheckinActionClicked}
                                            actionName="Check In Patient"
                                            actionIcon="fa fa-check fa-2x text-info"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handleVitalActionClicked}
                                            actionName="Capture Vital"
                                            actionIcon="fa fa-vial fa-2x text-info"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handleAllergyActionClicked}
                                            actionName="Add Allergy"
                                            actionIcon="fa fa-medkit fa-2x text-info"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        {
                                            isDeceased ?
                                                <PatientActionButton
                                                    actionName="Admission"
                                                    actionIcon="fa fa-procedures fa-2x text-info"
                                                />
                                                :
                                                <Link to={`/admission/patient/${this.state.patientEntryID}`} style={{ textDecoration: 'none' }}>
                                                    <PatientActionButton
                                                        actionName="Admission"
                                                        actionIcon="fa fa-procedures fa-2x text-info"
                                                    />
                                                </Link>
                                        }

                                    </div>

                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handlehospitalRecordActionClicked}
                                            actionName="Previous Hospital Record"
                                            actionIcon="fa fa-file fa-2x text-info"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handlePatientServiceActionClicked}
                                            actionName="Service Request"
                                            actionIcon="fa fa-cog fa-2x text-info"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handlePatientOtherInfoClicked}
                                            actionName="Other Info"
                                            actionIcon="fa fa-file fa-2x text-info"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handleBirthClicked}
                                            actionName="Birth Certificate"
                                            actionIcon="fa fa-file-pdf fa-2x text-info"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <PatientActionButton
                                            handleClick={isDeceased ? () => {} : this.handleDeathClicked}
                                            actionName="Death Certificate"
                                            actionIcon="fa fa-file-excel fa-2x text-info"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr/>

                    <Tabs>
                        <TabList>
                            <Tab>Appointments</Tab>
                            <Tab>Allergens</Tab>
                            <Tab>Vitals</Tab>
                            <Tab>Dependants</Tab>
                            <Tab>Payments</Tab>
                            <Tab>Services</Tab>
                            <Tab>Previous Hospital Record</Tab>
                            <Tab>Birth/Death Certificate</Tab>
                            <Tab>Other Information</Tab>
                        </TabList>

                        <TabPanel>
                            <PatientAppointmentList openUpdateModal={this.handleUpdateAppointmentActionClicked} appointmentList={this.state.appointmentList}
                                                    formatDate={this.formatDate}/>
                        </TabPanel>
                        <TabPanel>
                            <PatientAllergyList allergicList={this.state.allergicList} handleOnClick={this.handleViewAllergyImageClicked} formatDate={this.formatDate}/>
                        </TabPanel>
                        <TabPanel>
                            <PatientVitalList vitalList={this.state.vitalList} formatDate={this.formatDate}/>
                        </TabPanel>

                        <TabPanel>
                            <PatientDependantList patientDetail={this.state.patientDetails} dependantList={this.state.dependantList} formatDate={this.formatDate} />
                        </TabPanel>
                        <TabPanel>
                            <PatientPaymentList patientDetail={this.state.patientDetails} paymentList={this.state.paymentList} moneyFormat={this.currencyConverter} formatDate={this.formatDate} />
                        </TabPanel>
                        <TabPanel>
                            <PatientServiceList service_list={this.state.servicesList} money_format={this.currencyConverter} />
                        </TabPanel>
                        <TabPanel>
                            <PatientMedicalRecordList medicalRecordList={this.state.medicalRecordList} handleOnClick={this.handleViewImageClicked} formatDate={this.formatDate}/>
                        </TabPanel>
                        <TabPanel>
                            <BirthAndDeathCertificate birthCertificate={this.state.birthCertificate}  deathCertificate={this.state.deathCertificate} handleOnClick={this.handleViewImageClicked} formatDate={this.formatDate}/>
                        </TabPanel>
                        <TabPanel>
                            <PatientOtherInfo otherInfo={this.props.patientDetails} />
                        </TabPanel>
                    </Tabs>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
        return {
            patientDetails: state.patientDetails,
            doctorDetails: state.loginDetails,
        };
    };
const mapDispatchToProps = (dispatch) => {
        return {
            setOnPatientDetails: (p) => {
                dispatch(setpersonDetails(p));
            },
            setOnUpdatePatientDetails: (p) => {
                dispatch(setUpdatePatientDetails(p));
            },
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);
