import React, {Component} from "react";
import "./dashboard.css";
import LineChart from "../Chart/linechart";
import DougHuntChart from "../Chart/doughuntchart";
import HorizontalBarchart from "../Chart/horizontalbarchart";
import {serverLink, hospitalCode} from "../../resources/url";
import axios from "axios";
import YearlyChart from "../Chart/yearlychart";
import {connect} from "react-redux";

class DashboardPage extends Component {
    constructor(props) {
        const currentDate = new Date();
        const start_year = 2021;
        const currentYear = currentDate.getFullYear();
        let year_object = {};
        for (let i = start_year; i <= currentYear; i++) {
            year_object[i] = 0;
        }

        super(props);
        this.state = {
            isLoading: false,
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            patients: 0,
            accountant: 0,
            deathReport: 0,
            doctors: 0,
            laboratorist: 0,
            nurses: 0,
            operationReport: 0,
            payrollList: 0,
            pharmacists: 0,
            receptionist: 0,
            birthReport: 0,

            month: {
                jan: 0,
                feb: 0,
                march: 0,
                april: 0,
                may: 0,
                jun: 0,
                july: 0,
                aug: 0,
                sep: 0,
                oct: 0,
                nov: 0,
                des: 0,
            },

            year: year_object
        };
    }

    // async componentDidMount() {
    //     await this.getAllDocCount();
    // }
    //
    // async getAllDocCount() {
    //     this.setState({
    //         isLoading: true,
    //     });
    //
    //     await fetch(`${this.state.serverLink}report/dashboard/${this.state.hospitalCode}`, this.props.doctorDetails.token)
    //         .then(res => res.json())
    //         .then(result => {
    //             this.state.patients = result.patients
    //             this.state.doctors = result.doctors
    //             this.state.nurses = result.nurses
    //             this.state.laboratorist = result.laboratory
    //             this.state.pharmacists = result.pharmacy
    //             this.state.receptionist = result.admin_staff
    //             this.state.accountant = result.accountant
    //         });
    //
    //     await fetch(`${this.state.serverLink}patient/yearly/dashboard/chart/${this.state.hospitalCode}`, this.props.doctorDetails.token)
    //         .then(res => res.json())
    //         .then(result => {
    //             const currentDate = new Date();
    //             const currentYear = currentDate.getFullYear();
    //
    //             if (result.length > 0) {
    //
    //                 this.setState({
    //                     month: {
    //                         ...this.state.month,
    //                         jan: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 0).length,
    //                         feb: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 1).length,
    //                         march: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 2).length,
    //                         april: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 3).length,
    //                         may: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 4).length,
    //                         jun: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 5).length,
    //                         july: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 6).length,
    //                         aug: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 7).length,
    //                         sep: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 8).length,
    //                         oct: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 9).length,
    //                         nov: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 10).length,
    //                         des: result.filter(item => new Date(item.submittedOn).getFullYear() === currentYear && new Date(item.submittedOn).getMonth() === 11).length,
    //                     },
    //
    //                 })
    //
    //                 for (const year in this.state.year) {
    //                     this.setState({
    //                         year: {
    //                             ...this.state.year,
    //                             [year]: result.filter(item => new Date(item.submittedOn).getFullYear() === parseInt(year)).length
    //                         }
    //                     })
    //                 }
    //             }
    //         });
    //
    //     this.setState({
    //         isLoading: false,
    //     });
    // }

    render() {
        return (
            <div className="dashboardpage">
                <div className="topheader">
                    <ul>
                        <li>
                            <i
                                className="fa fa-dashboard fa-2x"
                                aria-hidden="true"
                            />
                        </li>
                        <li>
                            <span>Dashboard</span>
                        </li>
                    </ul>
                </div>

                <iframe
                    style={{background: "#F1F5F4", border: "none", borderRadius: "2px", boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)", height: "2000px", width: "100%"}}
                    src="https://charts.mongodb.com/charts-hospital-management-syste-qlpts/embed/dashboards?id=6336b4bf-2fd3-4d61-83d0-e24bc832e99a&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=scale"
                />

                {/*<div className="first_section">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-sm-4">*/}
                {/*            {" "}*/}
                {/*            <div className="box">*/}
                {/*                {" "}*/}
                {/*                <div className="box_containt">*/}
                {/*                    <h1*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}
                {/*                            color: "#BA79CB",*/}
                {/*                            fontSize: "30px",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {this.state.patients}*/}
                {/*                    </h1>*/}
                {/*                    /!* <hr /> *!/*/}
                {/*                    <span*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}

                {/*                            color: "#BA79CB",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*    Patients*/}
                {/*  </span>*/}
                {/*                </div>*/}
                {/*                <i className="fa fa-user fa-4x" aria-hidden="true"></i>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-sm-4">*/}
                {/*            {" "}*/}
                {/*            <div className="box">*/}
                {/*                {" "}*/}
                {/*                <div className="box_containt">*/}
                {/*                    <h1*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}
                {/*                            color: "#FFA812",*/}
                {/*                            fontSize: "30px",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {this.state.doctors}*/}
                {/*                    </h1>*/}
                {/*                    /!* <hr /> *!/*/}
                {/*                    <span*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}

                {/*                            color: "#FFA812",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*    Doctors*/}
                {/*  </span>*/}
                {/*                </div>*/}
                {/*                <i className="fa fa-user-md fa-4x" aria-hidden="true"></i>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-sm-4">*/}
                {/*            {" "}*/}
                {/*            <div className="box">*/}
                {/*                {" "}*/}
                {/*                <div className="box_containt">*/}
                {/*                    <h1*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}
                {/*                            color: "#00A65A",*/}
                {/*                            fontSize: "30px",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {this.state.nurses}*/}
                {/*                    </h1>*/}
                {/*                    /!* <hr /> *!/*/}
                {/*                    <span*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}
                {/*                            color: "#00A65A",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*    Nurses*/}
                {/*  </span>*/}
                {/*                </div>*/}
                {/*                <i className="fa fa-female fa-4x" aria-hidden="true"></i>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-sm-6">*/}
                {/*            {" "}*/}
                {/*            <div className="box">*/}
                {/*                {" "}*/}
                {/*                <div className="box_containt">*/}
                {/*                    <h1*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}
                {/*                            color: "#F56954",*/}
                {/*                            fontSize: "30px",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {this.state.pharmacists}*/}
                {/*                    </h1>*/}
                {/*                    /!* <hr /> *!/*/}
                {/*                    <span*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}
                {/*                            color: "#F56954",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*    Pharmacist*/}
                {/*  </span>*/}
                {/*                </div>*/}
                {/*                <i className="fa fa-medkit fa-4x" aria-hidden="true"></i>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-sm-6">*/}
                {/*            {" "}*/}
                {/*            <div className="box">*/}
                {/*                {" "}*/}
                {/*                <div className="box_containt">*/}
                {/*                    <h1*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}
                {/*                            color: "#00B29E",*/}
                {/*                            fontSize: "30px",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {this.state.laboratorist}*/}
                {/*                    </h1>*/}
                {/*                    /!* <hr /> *!/*/}
                {/*                    <span*/}
                {/*                        style={{*/}
                {/*                            fontWeight: "700",*/}
                {/*                            color: "#00B29E",*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*    Laboratorist*/}
                {/*  </span>*/}
                {/*                </div>*/}
                {/*                <i className="fa fa-flask fa-4x" aria-hidden="true"></i>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}
                {/*<div className="second_section">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-lg-6">*/}
                {/*            <div className="wrap_chart">*/}
                {/*                <LineChart month={this.state.month}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-lg-6">*/}
                {/*            <div className="wrap_chart">*/}
                {/*                <YearlyChart year={this.state.year}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
        // this.state.isLoading ? (
        //     <div className="dashboardpage">
        //         <i className="fas fa-spinner fa-pulse fa-2x "/>
        //     </div>
        // ) :

    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(DashboardPage);
