import React, { Component } from "react";
import "./finduser.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setpersonDetails } from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import { serverLink, hospitalCode } from "../../../resources/url";
import Navigator from "../../Settings/navigation/navigation";

class FindUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serverLink: serverLink + "user/",
      hospitalCode: localStorage.getItem("hospitalCode"),
      searchText: "",
      isLoading: true,
      limit: 50,

      isLoadMoredata: false,

      totalNumOfPatient: null,
      noMoreDataText: "",

      openErrorDialog: false,

      userList: [],
      isSearching: false,
      isSearchDataShow: false,

      selectedPatientName: "",
      selectedPatientId: "",
    };
  }
  componentDidMount() {
    this.onSetTotalNumOfUsers();
  }

  async fetchUsers(limit) {
    this.setState({ isLoadMoredata: true });
    await fetch(
      `${this.state.serverLink}all/${this.state.hospitalCode}/${limit}`, this.props.doctorDetails.token
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userList: data,
          isLoadMoredata: false,
          isLoading: false,
        });
      });
  }

  showMoreUsers = () => {
    if (this.state.limit <= this.state.totalNumOfPatient) {
      const limit = this.state.limit + 10;
      this.setState({ limit: limit });
      this.fetchUsers(limit);
    } else {
      this.setState({
        noMoreDataText: "No More Users",
      });
    }
  };

  async onSetTotalNumOfUsers() {
    this.setState({ isLoading: true });

    await fetch(
      `${this.state.serverLink}count/${this.state.hospitalCode}`, this.props.doctorDetails.token
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ totalNumOfPatient: data, isLoading: false });
      });
  }

  handleSearch = async () => {
    if (this.state.searchText !== "") {
      this.setState({
        isLoading: true,
      });
      const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");

      await fetch(
        `${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.length === 0) {
            this.setState({
              isSearching: false,
              isLoading: false,
              openErrorDialog: true,
            });
          } else {
            this.setState({
              userList: data,
              isSearching: false,
              isLoading: false,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  closeErrorDialog = () => {
    this.setState({
      openErrorDialog: false,
    });
  };

  render() {
    let count = 0;
    return this.state.isLoading ? (
      <div className="userlistpage">
        <i className="fas fa-spinner fa-pulse fa-2x "/>
      </div>
    ) : (
      <div className="userlistpage">
        <div className="main_section">
          <ErrorDialogBox
            openDialog={this.state.openErrorDialog}
            onSetOpenDialog={this.closeErrorDialog}
            title="Record Not Found"
            des="User record not found. Please try again!"
          />

          <div className="topheader">
            <Navigator title="Find Users" />
          </div>
          <hr />
          <div className="top_section">
            <div className="wrap">
              <ul>
                <li>
                  <div className="search">
                    <input
                      type="text"
                      className="searchTerm"
                      placeholder="Search user by first, middle, surname, email, phone number or user ID"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.handleSearch();
                        }
                      }}
                      onChange={(e) => {
                        this.setState({
                          searchText: e.target.value,
                        });
                      }}
                    />

                    <button
                      onClick={this.handleSearch}
                      type="submit"
                      className="searchButton"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </li>
                <li style={{ fontSize: "12px" }}> #</li>
                <li style={{ fontSize: "12px" }}>
                  {this.state.userList.length}{" "}
                </li>
              </ul>
            </div>
          </div>

          <table className="table table-striped">
            <thead className="thead tablehead">
              <tr>
                <th scope="col">#</th>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Sex</th>
                <th scope="col">Role</th>
                <th scope="col">Mobile</th>
                <th scope="col">Email</th>
                <th scope="col">Specialisation</th>
                <th scope="col">Option</th>
              </tr>
            </thead>
            {this.state.isSearching ? (
              <i className="fas fa-spinner fa-pulse fa-2x "></i>
            ) : this.state.userList.length === 0 ? (
              <tbody>
                <tr>
                  <td>No User Found</td>
                </tr>
              </tbody>
            ) : (
              <tbody className="tablebody">
                {this.state.userList &&
                  this.state.userList.map((p) => {
                    count++;
                    return (
                      <tr key={p._id}>
                        <td className="align-middle">{count}</td>
                        <td className="align-middle">{p.userID}</td>
                        <td className="align-middle">
                          {p.firstName + " " + p.middleName + " " + p.surname}
                        </td>
                        <td className="align-middle">{p.gender}</td>
                        <td className="align-middle">{p.role}</td>
                        <td className="align-middle">
                          {" "}
                          {p.phoneNumber === "" ? "N/A" : p.phoneNumber}
                        </td>
                        {
                          <td className="align-middle">
                            {" "}
                            {p.emailAddress === "" ? "N/A" : p.emailAddress}
                          </td>
                        }
                        <td className="align-middle">
                          {p.specialisation.length < 1
                            ? "N/A"
                            : p.specialisation.map((spec, index) => {
                                return spec.value === undefined ? (
                                  <div key={index}>
                                    <span className="badge badge-primary">
                                      {spec}
                                    </span>
                                    <br />
                                  </div>
                                ) : (
                                  <div>
                                    <span className="badge badge-primary">
                                      {spec.value}
                                    </span>
                                    <br />
                                  </div>
                                );
                              })}
                        </td>
                        <td className="align-middle">
                          <Link to="/staff/update">
                            <button
                              onClick={async () => {
                                const sendData = {
                                  ...p,
                                  userId: p._id,
                                };
                                this.props.setOnUserDetails(sendData);
                              }}
                              type="button"
                              className="btn btn-success"
                            >
                              <i className="fa fa-edit" aria-hidden="true"></i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>

          <div className="loadmoredatasection">
            {this.state.isLoadMoredata ? (
              <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
            ) : (
              <div className="nomoredatatext">{this.state.noMoreDataText}</div>
            )}
            {this.state.userList.length === 0 ? null : this.state
                .isSearchDataShow ? null : (
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => this.showMoreUsers()}
              >
                Show More
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDisptachToProps = (dispatch) => {
  return {
    setOnUserDetails: (p) => {
      dispatch(setpersonDetails(p));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    doctorDetails: state.loginDetails,
  };
};

export default connect(mapStateToProps, mapDisptachToProps)(FindUser);
