import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./addpatientotherinfo.css";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";
import PatientOtherInfoForm from "./patientotherinfoform";
import {connect} from "react-redux";

class AddPatientOtherInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            date: null,
            isLoading: false,
            patientSerial: this.props.patientData.patientSerial,
            patientFullName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                this.props.patientData.surname,

            formData: {
                patientSerial: this.props.patientData.patientSerial,
                familyType: "",
                parentChildren: "",
                positionInFamily: "",
                activities: "",
                exercise: "",
                lifestyle: "",
                leisureTime: "",
                smoking: "",
                alcohol: "",
                diet: "",
                hobbies: "",
                educationLevel: "",
                medicalCondition: "",
                previousAdmission: "",
            },

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        })
        this.onAddOtherInfo();
    };

    async onAddOtherInfo() {
        let submit_btn = document.getElementById('SubmissionBtn');
        submit_btn.innerHTML = `Submitting ...`
        submit_btn.setAttribute('disabled', 'disabled');


        let sendData = {
            ...this.state.formData,
            submittedBy: this.props.loginDetails.fullName,
        };

        axios.patch(`${this.state.serverLink}patient/other_info/add`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                submit_btn.innerHTML = `Save`;
                submit_btn.disbled = false;
                if(response === "success") {
                    this.props.handleSuccessDialog('ADDED', 'Patient Information Added Successfully');
                }  else if (response === "error"){
                    this.props.handleErrorDialog('ERROR', 'An error occur adding the information. Please try again!');
                } else {
                    this.props.handleErrorDialog('ERROR', response);
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                submit_btn.innerHTML = `Save`;
                submit_btn.disbled = false;
                this.props.handleErrorDialog('ERROR', err);
            });
    }

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };

    onEdit = (e) => {
        const formData = this.state.formData;

        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <PatientOtherInfoForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                patientSerial={this.state.patientSerial}
                                patientName={this.state.patientFullName}
                                data={this.state.formData}
                                otherInfo={this.props.patientData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(AddPatientOtherInfo);
