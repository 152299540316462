import React, { Component } from "react";
import { hospitalCode, serverLink } from "../../../resources/url";
import "./roomstatus.css";
import {loginValidation} from "../../../context/loginvalidation";
import {connect} from "react-redux";
import Navigator from "../../Settings/navigation/navigation";
import DataTable from "../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../resources/constants";

class RoomStatus extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"hospital_room/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 20,

            isLoadMoreData: false,

            totalNumOfRooms: null,
            noMoreDataText: "",

            roomData: [],

            isSearching: false,
            isSearchDataShow: false,

            selectedRoom: "",

            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }
    componentDidMount() {
        this.fetchRooms();
    }

    async fetchRooms() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    roomData: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    showTable = () => {

        try {
            return this.state.roomData.map((room, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{room.roomName}</td>
                        <td>{room.extensionNo}</td>
                        <td>{room.typeName}</td>
                        <td>{room.status}</td>
                        <td>{room.blockName === "" ? "N/A" : room.blockName}</td>
                        <td>{room.bedCapacity === "" ? "N/A" : room.bedCapacity}</td>
                        <td>{room.bedOccupied === "" ? "N/A" : room.bedOccupied}</td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    render() {
        const header = ["S/N", "Room Name",  "Extension No", "Room Type", "Room Status", "Block Name", "Bed Capacity", "Bed Occupied"];
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Room Status"/>
                    </div>
                    <hr/>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="page-pretitle">
                                    </div>
                                    <h3 className="card-title">Room Status List</h3>
                                </div>
                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">

                                    </div>
                                </div>
                            </div>
                            <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')}
                                       body={this.showTable()} title={`Room Status Report`}/>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(RoomStatus);
