import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { setpersonDetails } from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import {connect} from "react-redux";
import AppointmentForm from "../appointmentform/appointmentform";
import {Link} from "@material-ui/core";
import { serverLink, hospitalCode } from "../../../resources/url";
import Navigator from "../../Settings/navigation/navigation";


class UpdateAppointment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSubmitting: 'Save',
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            date: this.props.appointmentDetails.appointmentDate,
            isLoading: false,
            patientSerial: this.props.appointmentDetails.patientSerial,
            patientFullName: this.props.appointmentDetails.patientName,

            formData: {
                _id: this.props.appointmentDetails._id,
                patientSerial: this.props.appointmentDetails.patientSerial,
                patientName: this.props.appointmentDetails.patientName,
                appointmentDetail: this.props.appointmentDetails.appointmentDetail,
                appointmentDate: this.props.appointmentDetails.appointmentDate,
                appointmentPreviousDate: this.props.appointmentDetails.appointmentDate,
                appointmentTime: this.props.appointmentDetails.appointmentTime,
                appointmentPreviousTime: this.props.appointmentDetails.appointmentTime,
                doctorID: this.props.appointmentDetails.doctorID,
                doctorPreviousID: this.props.appointmentDetails.doctorID,
                doctorName: this.props.appointmentDetails.doctorName,
                serviceItemName: this.props.appointmentDetails.consultationType,
                serviceID: this.props.appointmentDetails.consultationTypeID,
                appointmentStatus: this.props.appointmentDetails.appointmentStatus,
                appointmentDepartment: this.props.appointmentDetails.appointmentDepartment,
                hospitalCode: this.props.appointmentDetails.hospitalCode,
                isNotifiedPatient: this.props.appointmentDetails.isNotifiedPatient,
                submittedBy: this.props.appointmentDetails.submittedBy,
                appointmentFee: ""
            },
            departmentList: "",
            doctorList: [{value: this.props.appointmentDetails.doctorID, label: this.props.appointmentDetails.doctorName}],
            serviceList: "",
            serviceData: "",
            serviceDataSelect: [{value: this.props.appointmentDetails.consultationTypeID, label: this.props.appointmentDetails.consultationType}],
            messageDescription: "",
            patientRecord: "",
            doctorSlots: "",
            errorMessage: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            allowAppointmentUpdate: false,

            selectedItemID: "",
            selectedItemName: "",
        };
    }

    componentDidMount() {
       this.onGetDepartments();
       this.getServiceList();
       this.getServiceData();
       this.onGetDepartmentDoctors(this.props.appointmentDetails.appointmentDepartment);
       this.onFetchDoctorSchedule(this.props.appointmentDetails.doctorID, this.props.appointmentDetails.appointmentDate);
       this.onCheckIfAppointmentIsActive()
    }

    //============ GET PATIENT SERVICE LIST STARTS ====================
    async getServiceList () {
        await axios.get(`${serverLink}service/all/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                const result_set = data.filter(item => item.serviceName.includes('Consultation'));
                if (data.length > 0) {
                    let spec = [];
                    result_set.map((sp) => {
                        spec.push({value: sp._id, label: sp.serviceName})
                    })

                    this.setState({
                        serviceList: spec
                    })
                }
            })
            .catch(error => {
                this.setState({
                    messageType: 'error',
                    errorMessage: 'Error connecting to server to fetch service list'
                });
            })
    }
    //============ GET PATIENT SERVICE LIST END ====================

    //============ GET PATIENT SERVICE DATA STARTS ====================
    async getServiceData () {
        await axios.get(`${serverLink}service/service_data_list`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0)
                    this.setState({
                        serviceData: data
                    })
            })
            .catch(error => {
                this.setState({
                    messageType: 'error',
                    errorMessage: 'Error connecting to server to fetch service list'
                });
            })
    }

    onServiceChange = (e) => {
        const service_type = e.value;
        const service_data = this.state.serviceData;
        if (service_data.length > 0) {
            let record = service_data.filter(item => item.serviceID === service_type);
            this.setState({
                ...this.state.formData,
                serviceType: service_type
            });
            if (record.length > 0) {
                let spec = [];
                record.map((sp) => {
                    spec.push({value: sp._id, data: sp.serviceAmount, label: sp.serviceItemName})
                })
                this.setState({
                    serviceDataSelect: spec
                })
            }
        }
    }

    onServiceItemChange = (e) => {
        const formData = this.state.formData;
        const service_item_id = e.value;
        const service_amount = e.data;
        const service_item_name = e.label;

        if (service_item_id === "")
            return false;

        this.setState({
            formData: {
                ...formData,
                appointmentFee: service_amount,
                consultationAmount: this.currencyConverter(service_amount),
                serviceItemName: service_item_name,
                serviceID: service_item_id
            },
        });

    }

    onSpecialisationChange = (e) => {
        const formData = this.state.formData;
        let specialisation = e.value;
        let appointment_amount = e.data;

        this.setState({
            doctorSlots: ""
        })

        if(specialisation !== "" && specialisation !== 'N/A') {
            //GET ALL THE DOCTORS UNDER THE DEPARTMENT
            axios.get(`${this.state.serverLink}doctor/department_doctors/${this.state.hospitalCode}/${specialisation}`, this.props.doctorDetails.token)
                .then(doctors => {
                    let spec = [];
                    if (doctors.data.length > 0) {
                        doctors.data.map((sp) => {
                            spec.push({value: sp.userID, label: `${sp.firstName} ${sp.middleName} ${sp.surname}`})
                        })
                        this.setState({
                            doctorList: spec
                        })
                    } else {
                        this.setState({
                            doctorList: []
                        })
                    }

                    // let select = document.getElementById('doctorID');
                    // let length = select.options.length;
                    // for (let i = length-1; i >= 1; i--) {
                    //     select.options[i] = null;
                    // }
                    //
                    // if(doctors.data.length > 0) {
                    //     doctors.data.map((doctor) => {
                    //         let doctor_name = `${doctor.firstName} ${doctor.middleName} ${doctor.surname}`;
                    //         let opt = document.createElement('option');
                    //         opt.value = doctor.userID
                    //         opt.innerHTML = doctor_name
                    //         select.appendChild(opt)
                    //     });
                    // }
                })
                .catch(err => {
                    console.log("ERROR: ",err);
                })
        }else {
            this.setState({
                doctorList: [],
            })
        }

        this.setState({
            formData: {
                ...formData,
                appointmentDepartment: specialisation
            },
        });

    }

    onDoctorChange = (e) => {
        this.setState({
            doctorSlots: ""
        })
        if (e.value === '')
            return false;

        const formData = this.state.formData;

        this.setState({
            formData: {
                ...formData,
                doctorID: e.value,
                doctorName: e.label
            },
        });
    }
    //============ GET PATIENT SERVICE LIST END ====================


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
            isSubmitting: 'submitting'
        })
        this.onUpdateAppointment();
    };

    async onUpdateAppointment() {
        let sendData = {
            ...this.state.formData
        };

        if(this.state.formData.appointmentDepartment === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'
            });
            this.state.messageTitle = 'ERROR'
            this.state.messageDescription = 'Please Select the Appointment Department/Specialisation'
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.doctorID === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'
            });
            this.state.messageTitle = 'ERROR'
            this.state.messageDescription = 'Please Select the Appointment Doctor'
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.appointmentDate === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'
            });
            this.state.messageTitle = 'ERROR'
            this.state.messageDescription = 'Please Select the Appointment Date'
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.appointmentTime === "") {
            this.setState({
                isLoading: false,
                isSubmitting: 'Save'
            });
            this.state.messageTitle = 'ERROR'
            this.state.messageDescription = 'Please Select the Appointment Time'
            this.handleErrorDialog();
            return false;
        }


        this.setState({
            isLoading: false,
        });


        axios.patch(`${this.state.serverLink}appointment/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                this.setState({
                    isSubmitting: 'Save'
                });
                if(response === "success") {
                    this.state.messageTitle = 'ADDED'
                    this.state.messageDescription = 'Appointment Added Successfully'
                    this.handleSuccessDialog();
                } else if (response === "failed") {
                    this.state.messageTitle = 'FAILED'
                    this.state.messageDescription = 'Something went wrong saving the appointment. Please try again!'
                    this.handleErrorDialog();
                } else if (response === "error"){
                    this.state.messageTitle = 'ERROR'
                    this.state.messageDescription = 'An error occur adding the appointment. Please try again!'
                    this.handleErrorDialog();
                } else {
                    this.state.messageTitle = 'ERROR'
                    this.state.messageDescription = response
                    this.handleErrorDialog();
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                this.state.messageTitle = 'ERROR'
                this.state.messageDescription = err
                this.handleErrorDialog();
            });
    }

    onGetDepartments() {
        axios.get(`${this.state.serverLink}specialisation/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(specialisation => {
                if(specialisation.data.length < 1) {
                    this.setState({
                        departmentList: ""
                    })

                } else {
                    let spec = [];
                    specialisation.data.map((sp) => {
                        spec.push({value: sp.specialisationName, label: sp.specialisationName})
                    })
                    this.setState({
                        departmentList: spec
                    })
                }
            })
            .catch(err => {
                this.setState({
                    departmentList: null
                })
            })
    }

    onGetDepartmentDoctors(department) {
        axios.get(`${this.state.serverLink}user/department_doctors/${this.state.hospitalCode}/${department}`, this.props.doctorDetails.token)
            .then(doctors => {
                let spec = [];
                if (doctors.data.length > 0) {
                    doctors.data.map((sp) => {
                        spec.push({value: sp.userID, label: `${sp.firstName} ${sp.middleName} ${sp.surname}`})
                    })
                    this.setState({
                        doctorList: spec
                    })
                } else {
                    this.setState({
                        doctorList: []
                    })
                }

                // let select = document.getElementById('doctorID');
                // let length = select.options.length;
                // for (let i = length-1; i >= 1; i--) {
                //     select.options[i] = null;
                // }
                //
                // if(doctors.data.length > 0) {
                //     doctors.data.map((doctor) => {
                //         let doctor_name = `${doctor.firstName} ${doctor.middleName} ${doctor.surname}`;
                //         let opt = document.createElement('option');
                //         opt.value = doctor.userID
                //         opt.innerHTML = doctor_name
                //         select.appendChild(opt)
                //     });
                // }
            })
            .catch(err => {
                console.log("ERROR: ",err);
            })
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()}
                    
                    `
        } else {
            return '--'
        }
    };

    onFetchDoctorSchedule = (doctorId, appointment_date) => {
        const appointmentDate = new Date(appointment_date);
        let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const appointment_day = days[appointmentDate.getDay()]

        axios.get(`${this.state.serverLink}doctor_schedule/${this.state.hospitalCode}/${doctorId}`, this.props.doctorDetails.token)
            .then(result => {
                let schedules = result.data;

                if(schedules === null) {
                    this.setState({
                        errorMessage: "No Schedule Found for the selected doctor",
                        doctorSlots: ""
                    })
                } else {
                    this.setState({
                        errorMessage: ""
                    })

                    let slots = schedules.scheduleData[0][appointment_day];

                    if(typeof slots !== "undefined") {
                        this.setState({
                            errorMessage: "",
                            doctorSlots: slots.slots,
                        })
                        this.state.formData.appointmentDay = appointment_day

                    } else {
                        this.setState({
                            errorMessage: "The Selected Doctor doesn't have a schedule on "+appointment_day.toUpperCase(),
                            doctorSlots: ""
                        })
                    }

                }

                this.setState({
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleChange = (date) => {
        if (date !== null) {
            console.log(date)
            const appointmentDate = new Date(date);

            this.setState({
                date: appointmentDate,
                formData: {
                    ...this.state.formData,
                    appointmentDate: `${appointmentDate.getFullYear()}-${appointmentDate.getMonth() + 1 < 10 ? '0'+(appointmentDate.getMonth() + 1) : appointmentDate.getMonth() + 1}-${appointmentDate.getDate() < 10 ? '0'+appointmentDate.getDate() : appointmentDate.getDate()}`,
                },
            });

            this.onFetchDoctorSchedule(this.state.formData.doctorID, date)

        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    appointmentDate: date,
                },
            });
        }
    };

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    // onSpecialisationChange = (e) => {
    //     const formData = this.state.formData;
    //     let specialisation = e.value;
    //     let appointment_amount = e.data;
    //
    //     this.setState({
    //         doctorSlots: ""
    //     })
    //
    //     if(specialisation !== "" && specialisation !== 'N/A') {
    //         this.state.formData.appointmentFee = appointment_amount;
    //         this.state.formData.consultationAmount = this.currencyConverter(appointment_amount);
    //
    //         //GET ALL THE DOCTORS UNDER THE DEPARTMENT
    //         axios.get(`${this.state.serverLink}doctor/department_doctors/${this.state.hospitalCode}/${specialisation}`, this.props.doctorDetails.token)
    //             .then(doctors => {
    //                 let select = document.getElementById('doctorID');
    //                 let length = select.options.length;
    //                 for (let i = length-1; i >= 1; i--) {
    //                     select.options[i] = null;
    //                 }
    //
    //                 if(doctors.data.length > 0) {
    //                     doctors.data.map((doctor) => {
    //                         let doctor_name = `${doctor.firstName} ${doctor.middleName} ${doctor.surname}`;
    //                         let opt = document.createElement('option');
    //                         opt.value = doctor.userID
    //                         opt.innerHTML = doctor_name
    //                         select.appendChild(opt)
    //                     });
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log("ERROR: ",err);
    //             })
    //     }else {
    //         this.state.formData.appointmentFee = ""
    //     }
    //
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             appointmentDepartment: specialisation
    //         },
    //     });
    //
    // }

    onEdit = (e) => {
        const formData = this.state.formData;

        if(e.target.name === "doctorID") {
            this.setState({
                doctorSlots: ""
            })
            let index = e.nativeEvent.target.selectedIndex;
            this.state.formData.doctorName = e.nativeEvent.target[index].text
        }

        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        this.getPatientData();
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    async getPatientData() {
        this.setState({
            isLoading: true
        })
        await axios.get(`${this.state.serverLink}patient/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false
                })
                const sendData = {
                    ...result.data,
                    personId: result.data._id,
                };
                this.props.setOnPatientDetails(sendData);
                window.location.href = '/patient/profile'
            })
    }

    onCheckIfAppointmentIsActive = () => {
        let appointment_date = new Date(this.props.appointmentDetails.appointmentDate);
        let today = new Date()
        today.setHours(0,0,0,0)
        if(appointment_date >= today) {
            this.setState({
                allowAppointmentUpdate: true
            })
        }
    }

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <div className="topheader">
                        <Navigator title="Update Appointment" />
                    </div>
                    <hr/>

                    <div className="row">
                        <div className="col-sm">
                            {
                                this.state.allowAppointmentUpdate ?
                                    <AppointmentForm
                                        handleSubmit={this.handleSubmit}
                                        onEdit={this.onEdit}
                                        onSpecialisationChange={this.onSpecialisationChange}
                                        date={this.state.date}
                                        handleChange={this.handleChange}
                                        patientSerial={this.state.patientSerial}
                                        appointmentName={this.state.patientFullName}
                                        appointmentData={this.state.formData}
                                        departmentList={this.state.departmentList}
                                        doctorList={this.state.doctorList}
                                        serviceList={this.state.serviceList}
                                        serviceData={this.state.serviceDataSelect}
                                        serviceDataLoading={this.state.serviceData === ''}
                                        onServiceChange={this.onServiceChange}
                                        onServiceItemChange={this.onServiceItemChange}
                                        onDoctorChange={this.onDoctorChange}
                                        doctorSlots={this.state.doctorSlots}
                                        appointmentDate={this.state.formData.appointmentDate}
                                        errorMessage={this.state.errorMessage}
                                        formType="update"
                                        isSubmitting={this.state.isSubmitting}
                                    />
                                    :
                                    <span className="alert alert-danger">
                                        Sorry, you can't update the appointment as the appointment date has passed! Kindly book another appointment
                                        <button className="btn btn-info btn-sm"
                                                onClick={async () => {
                                                    this.getPatientData()
                                        }}>here</button>
                                    </span>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appointmentDetails: state.personDetails,
        doctorDetails: state.loginDetails,
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDisptachToProps)(UpdateAppointment);
