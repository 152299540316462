import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../../Dashboard/utils/dashboardcard";
import "./inpatientdashboard.css";
import {connect} from "react-redux";
import {loginValidation} from "../../../../context/loginvalidation";
import Navigator from "../../../Settings/navigation/navigation";

class InpatientDashboard extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
    }

    render() {
        return (
            <div className="dashboardpage">
                <div className="topheader">
                    <Navigator title="Room Availability" />
                </div>


                <div className="first_section">
                    <div className="row">
                        <div className="col-sm">
                            {" "}
                            <Link to="/inpatient/room/available">
                                <DashboardCard name="Available" icon="fa fa-check fa-4x" count="" />
                            </Link>
                        </div>
                        <div className="col-sm">
                            {" "}
                            <Link to="/inpatient/room/occupied">
                                <DashboardCard name="Occupied" icon="fa fa-store-alt-slash fa-4x" count="" />
                            </Link>
                        </div>

                        <div className="col-sm">
                            {" "}
                            <Link to="/inpatient/room/undermaintenance">
                                <DashboardCard name="Under Maintenance" icon="fa fa-tools fa-4x" count="" />
                            </Link>
                        </div>

                        <div className="col-sm">
                            {" "}
                            <Link to="/inpatient/room/allrooms">
                                <DashboardCard name="All Rooms" icon="fa fa-hospital-alt fa-4x" count="" />
                            </Link>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails
    };
};

export default connect(mapStateToProps, null)(InpatientDashboard);
