import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import { hospitalCode, serverLink } from "../../../resources/url";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import "./doctorprofile.css";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";


class DoctorProfile extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+ "doctor/",
            hospitalCode: hospitalCode,
            doctorID: localStorage.getItem('userID'),
            isLoading: true,
            appointmentLimit: 10,
            doctorEntryID: "",
            title: "",
            firstName: "",
            middleName: "",
            surname: "",
            emailAddress: "",
            phoneNumber: "",
            altPhoneNumber: "",
            gender: "",
            role: "",
            dateOfFirstAppointment: "",
            dateOfRenewedContract: "",
            employmentStatus: "",
            status: "",
            dateOfLastLogin: "",
            dateOfFirstLogin: "",
            specialisation: "",
            userID: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            isCloseDialogAppear: true,

            messageTitle: "",
            messageDescription: "",

            doctorDetails: [],
        };
    }

    componentDidMount() {
        this.fetchDoctorDetails();
    }

    async fetchDoctorDetails() {
        const userId = this.state.doctorID;
        await fetch(`${this.state.serverLink}profile/${this.state.hospitalCode}/${userId}`, this.props.doctorLogin.token)
            .then(res => res.json())
            .then(data => {
                this.setState( {
                    doctorEntryID: data.doctorEntryID,
                    title:  data.title,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    surname: data.surname,
                    emailAddress: data.emailAddress,
                    phoneNumber: data.phoneNumber,
                    altPhoneNumber: data.altPhoneNumber,
                    gender: data.gender,
                    role: data.role,
                    dateOfFirstAppointment: data.dateOfFirstAppointment,
                    dateOfRenewedContract: data.dateOfRenewedContract,
                    employmentStatus: data.employmentStatus,
                    status: data.status,
                    dateOfLastLogin: data.dateOfLastLogin,
                    dateOfFirstLogin: data.dateOfFirstLogin,
                    specialisation: data.specialisation,
                    userID: data.userID,
                    isLoading: false,
                });
            });
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()}
                    
                    `
        } else {
            return '--'
        }
    };

    handleConsultationActionClicked = () => {
        let path = `../list/doctorlist.jsx`;
        let history = useNavigate();
        history.push(path);
    }

    handleSuccessDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openAlertDialog: true,
        });
    };

    handleErrorDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAllergyDialog: false,
            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    
    closeAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.reload();
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="doctorprofilepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="doctorprofilepage">
                <div className="main_section">
                   

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <div className="topheader">
                        <Navigator title="Doctor Profile" />
                    </div>

                    <div className="row">
                        <div className="col-sm-2">
                            <div className="card">
                                
                                <div className="d-flex flex-column align-items-center text-center">
                                    
                                    <div className="mt-3">
                                        <h4>{this.state.firstName} {this.state.middleName} {this.state.surname}</h4>
                                        <p className="text-muted font-size-sm">Status: {
                                            this.state.status
                                        }</p>
                                        <hr/>
                                        <h5>Specialisations</h5><hr />
                                        {
                                            this.state.specialisation.length > 0 ?
                                            <ul>

                                            {this.state.specialisation.map((value, key) => (
                                                <li key={key} className="text-secondary text-left" ><i className="fa fa-angle-right ml-1"/> {value}</li>
                                            ))}
                                        </ul>
                                        : 'N/A'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-9">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Phone Number</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.phoneNumber} {this.state.altPhoneNumber ? ' | ' + this.state.altPhoneNumber : ''}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Email Address</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.emailAddress ? this.state.emailAddress : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"> Gender</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.gender ? this.state.gender : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Role</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.role ? this.state.role : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Employment Status</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.employmentStatus ? this.state.employmentStatus : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Date of First Appointment</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.dateOfFirstAppointment ? this.formatDate(this.state.dateOfFirstAppointment) : 'N/A'}
                                            

                                        </div>
                                    </div>
                                     <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">DR ID</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.userID ? this.state.userID : 'N/A'}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                         
                    </div>

                   

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
        
    return {
        doctorDetails: state.personDetails,
        permissionData: state.permissionDetails,
        doctorLogin: state.loginDetails,
    };
    
};



export default connect(mapStateToProps, null)(DoctorProfile);


