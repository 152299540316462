import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import "./confirmdialogbox.css";
import JoditEditor from "jodit-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialogBox(props) {
  const handleClose = () => {
    props.onSetOpenDialog();
  };


  const editorConfig = {
    readonly: false,
    height: 200
  };


  return (
    <Dialog
      className="confirmDialogBox"
      open={props.openDailog}
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title ">
        <div className="title">{<h6> {props.title}</h6>}</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.isLoading ? (
            <i className="fas fa-spinner fa-pulse fa-2x"></i>
          ) : (
            <>
              <span className="col-md-12 mb-3 des">{props.des}</span><br/>
              {
                props.showComment ?
                <div className="col-md-12 mb-3">
                <label htmlFor="description">Consultant Comment (If any) </label>
                <JoditEditor
                value={""}
                config={editorConfig}
                onBlur={props.descriptionHandler}
                onChange={(newContent) => {}}
                />
                </div>
                    : ""
              }

            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.isLoading ? null : (
          <div>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => props.handleConfirmOkBtn()}
            >
              {props.isDecision === true ? 'Yes, Approve' : 'Yes'}
            </button>
            <button
              onClick={() => handleClose()}
              type="button"
              className="btn btn-danger"
            >
              {props.isDecision === true ? 'No, Reject' : 'No'}
            </button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
