import React, { Component } from "react";
import "./plansubscriptionform.css";

class PlanSubscriptionForm extends Component {

    render() {
        return (
            <form onSubmit={this.props.handleSubmit} id="plan-form">
                <div className="container">

                    {
                        this.props.errorMessage !== "" ?
                            <div className="alert alert-danger text-center col-md-12">{this.props.errorMessage}</div> : ""
                    }
                    {
                        this.props.loadingMsg === true ?
                            <div className="alert alert-success text-center col-md-12">
                                Please wait...
                            </div> : ""
                    }
                    {
                        this.props.successMessage !== "" ?
                            <div className="alert alert-success text-center col-md-12">
                                {this.props.successMessage}
                            </div> : ""
                    }
                    <hr/>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientSerial">Patient ID</label>
                            <input
                                name="patientSerial"
                                type="text"
                                className="form-control"
                                id="patientSerial"
                                disabled="disabled"
                                value={this.props.patientSerial}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientName">Patient Name</label>
                            <input
                                name="patientName"
                                type="text"
                                className="form-control"
                                id="patientName"
                                disabled="disabled"
                                value={this.props.patientName}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label htmlFor="planName">Subscription Plan</label>
                            <select
                                name="planName"
                                className="custom-select"
                                id="planName"
                                value={this.props.formData.planName}
                                onChange={this.props.onEdit}
                            >
                                <option value="">Select Plan</option>
                                {
                                    this.props.planList && this.props.planList.map((plan) => {
                                        if(plan.planName !== "")
                                            return (
                                                <option key={plan._id} message={plan._id} desc={plan.planDescription} amount={plan.amount} specialisttype={plan.specialistType} companyname={plan.companyName} duration={plan.duration} value={plan.planName}>{plan.planName}</option>
                                            )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    {
                        this.props.formData.planName === "" ? '' :

                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="companyName">Company Name</label>
                                    <input
                                        name="companyName"
                                        type="text"
                                        className="form-control"
                                        id="companyName"
                                        value={this.props.formData.companyName}
                                        disabled="disabled"
                                        onChange={this.props.onEdit}
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="amount">Price</label>
                                    <input
                                        name="planPrice"
                                        type="text"
                                        className="form-control"
                                        id="planPrice"
                                        value={this.props.formData.amount}
                                        disabled="disabled"
                                        onChange={this.props.onEdit}
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="paymentInterval">Duration</label>
                                    <input
                                        name="paymentInterval"
                                        type="text"
                                        className="form-control"
                                        id="paymentInterval"
                                        value={this.props.formData.duration}
                                        disabled="disabled"
                                        onChange={this.props.onEdit}
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="planType">SpecialistType</label>
                                    <input
                                        name="planType"
                                        type="text"
                                        className="form-control"
                                        id="planType"
                                        value={this.props.formData.specialistType}
                                        disabled="disabled"
                                        onChange={this.props.onEdit}
                                        required
                                    />
                                </div>

                            </div>
                    }
                </div>

                <div className="form-row">
                    <button className="btn btn-success btn-block savebtn" type="submit">
                        Subscribe Patient
                    </button>
                </div>
            </form>
        );
    }
}

export default PlanSubscriptionForm;
