import React, {useEffect, useState} from "react";
import "./servicesdashboard.css";
import Navigator from "../../navigation/navigation";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import axios from "axios";
import {serverLink, hospitalCode} from "../../../../resources/url";
import FormPrompt from "../../../DialogBoxes/formprompt";
import {connect} from "react-redux";
import {setServiceDetails} from "../../../../actions/setpersondetailsaction";

function ServiceDashboard(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [columns] = useState([
        {
            name: "Service Name",
            selector: row => `${row.serviceName}`,
            sortable: true,
            width: '30%'
        },
        {
            name: "Service Description",
            selector: row => `${row.serviceDescription !== "" ? row.serviceDescription : '--' }`,
            sortable: true
        },
        {
            name: "Added By",
            selector: row => `${row.submittedBy}`,
            sortable: true
        },
        {
            name: "Added On",
            selector: row => `${formatDateAndTime(row.submittedOn)}`,
            sortable: true
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <Link to={'/settings/service/'+row._id}>
                        <button onClick={async () => {props.setOnServiceDetails(row)}}
                                type="button"
                                className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-eye" aria-hidden="true"/>
                        </button>
                    </Link>
                    <button
                        onClick={() => onOpenUpdateModal(row)}
                        type="button"
                        className="btn btn-info btn-sm"
                    >
                        <i className="fa fa-pencil" aria-hidden="true"/>
                    </button>
                </>
            ),
        }
    ]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        serviceName: "",
        serviceDescription: "",
        hospitalCode: hospitalCode,
        serviceID: "",
        submittedBy: props.doctorDetails.fullName
    });
    const [message, setMessage] = useState({
        type: "",
        message: ""
    });
    const formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const day = user_date.getDate() < 10 ? "0"+user_date.getDate() : user_date.getDate();
            const hours = user_date.getHours() < 10 ? "0"+user_date.getHours() : user_date.getHours();
            const min = user_date.getMinutes() < 10 ? "0"+user_date.getMinutes() : user_date.getMinutes();
            const second = user_date.getSeconds() < 10 ? "0"+user_date.getSeconds() : user_date.getSeconds();

            return `${day}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} : ${hours}:${min}:${second}`
        } else {
            return '--'
        }
    };

    const getServiceList = async () => {
        await axios.get(`${serverLink}service/all/${hospitalCode}`, props.doctorDetails.token)
            .then(response => {
                if (isLoading) {
                    setData(response.data);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.log("SERVICES NETWORK ERROR", error)
            })
    }
    useEffect(() => {
        getServiceList();
    },[])

    const onOpenModal = () => {
        setMessage({type: "", message: ""});
        setFormData({
            ...formData,
            serviceName: "",
            serviceDescription: "",
            serviceID: ""
        })
        setOpenModal(true);
    }

    const onOpenUpdateModal = (row) => {
        setMessage({type: "", message: ""});
        setFormData({
            ...formData,
            serviceName: row.serviceName,
            serviceDescription: row.serviceDescription,
            serviceID: row._id
        })
        setOpenModal(true);
    }

    const closeModal = () => {
        setOpenModal(false);
    }

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value
        })
    }

    const onSubmit = () => {
        setMessage({
            type: "info",
            message: "Submitting..."
        });

        if (formData.serviceName.trim() === "") {
            setMessage({
                type: "danger",
                message: "Service Name Can't be Empty"
            });
            return false;
        }

        if (formData.serviceID === "") {
            axios.post(`${serverLink}service/add_service`, formData, props.doctorDetails.token)
                .then(response => {
                    const message = response.data.message;
                    if (message === 'exist')
                        setMessage({type: "danger", message: "Service Already Exist"});
                    else if (message === 'success') {
                        setMessage({type: "success", message: "Service Added"});
                        setTimeout(() => {
                            setOpenModal(false)
                            setIsLoading(true);
                            getServiceList();
                        }, 2000)
                    }
                    else
                        setMessage({type: "danger", message: "Error occurred. Please try again!"});
                })
                .catch(error => {
                    console.log("NETWORK ERROR", error);
                    setMessage({type: "danger", message: "Network error occurred. Please try again!"});
                })
        } else {
            axios.patch(`${serverLink}service/update_service`, formData, props.doctorDetails.token)
                .then(result => {
                    const message = result.data.message;
                    if (message === 'success') {
                        setMessage({type: "success", message: "Service Updated"});
                        setTimeout(() => {
                            setOpenModal(false)
                            setIsLoading(true);
                            getServiceList();
                        }, 2000)
                    }
                    else
                        setMessage({type: "danger", message: "Error occurred. Please try again!"});
                })
                .catch(error => {
                    console.log("NETWORK ERROR", error);
                    setMessage({type: "danger", message: "Network error occurred. Please try again!"});
                })
        }
    }

    const tableData = {
        columns,
        data,
        print: false,
        exportHeaders: true,
        filterPlaceholder: 'Search service'
    };

    return isLoading ? (
        <div className="basepage">
            <i className="fas fa-spinner fa-pulse fa-2x "/>
        </div>
    ) : (
        <div className="basepage">
            <div className="main_section">

                <FormPrompt
                    style={{ position: 'relative', zIndex: '3' }}
                    openDialog={openModal}
                    title="Service Form"
                    onSetOpenDialog={closeModal}
                    isCloseBtnAppear={true}
                >
                    {
                        message.message !== "" &&
                        <div className={`alert alert-${message.type}`}>{message.message}</div>
                    }
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="ServiceName">Service Name</label>
                                <input type="text" className={"form-control"} placeholder={"Service Name"} id={"serviceName"} onChange={onEdit} value={formData.serviceName}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="ServiceName">Service Description</label>
                                <input type="text" className={"form-control"} placeholder={"Service Description"} id={"serviceDescription"} onChange={onEdit} value={formData.serviceDescription}/>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" id={"serviceID"} value={formData.serviceID}/>

                    <button className={"btn btn-block btn-primary"} onClick={onSubmit}>Submit</button>

                </FormPrompt>

                <div className="topheader">
                    <Navigator title="Services" />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>

                    <button type="button" className="btn btn-warning" onClick={onOpenModal}>+ Add</button>
                </div>
                <hr />
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover/>
                </DataTableExtensions>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnServiceDetails: (p) => {
            dispatch(setServiceDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDashboard);
