import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    setpersonDetails
} from "../../../actions/setpersondetailsaction";
import { hospitalCode, serverLink } from "../../../resources/url";
import "./imagingrequest.css";
import {loginValidation} from "../../../context/loginvalidation";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Navigator from "../../Settings/navigation/navigation";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import {formatDateAndTime} from "../../../resources/constants";

class ImagingRequest extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"imaging/",
            hospitalCode: hospitalCode,
            isLoading: true,

            isSearching: false,
            isSearchDataShow: false,

            selectedDrugCategory: "",
            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false,
            columns: [
                {
                    name: "S/N",
                    selector: row => `${row.count}`,
                    sortable: true,
                    width: '7%'
                },
                {
                    name: "Patient Name",
                    selector: row => `${row.rawData.patientName}`,
                    sortable: true,
                    width: '15%'
                },
                {
                    name: "Patient Serial",
                    selector: row => `${row.rawData.patientSerial}`,
                    sortable: true,
                    width: '10%'
                },
                {
                    name: "Lab Name",
                    selector: row => `${row.rawData.labName}`,
                    sortable: true,
                    width: '15%'
                },
                {
                    name: "Image Name",
                    selector: row => `${row.rawData.imageName}`,
                    sortable: true,
                    width: '15%'
                },
                {
                    name: "Price",
                    selector: row => `${row.rawData.price}`,
                    sortable: true,
                    width: '10%'
                },
                {
                    name: "Status",
                    selector: row => `${row.rawData.status}`,
                    sortable: true,
                    width: '10%'
                },
                {
                    name: "Date Requested",
                    selector: row => `${formatDateAndTime(row.rawData.submittedOn, "date")}`,
                    sortable: true,
                    width: '10%'
                },

               this.props.IsViewOnly ?  {} : {
                    name: "Action",
                    cell: (row) => (
                        <>
                            {
                                row.rawData.status !== "cancelled" ?
                                    <Link to={`/imaging/profile/${row.rawData._id}`}>
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                const imagingData = {
                                                    ...row.rawData,
                                                };
                                                this.props.setOnPatientDetails(imagingData);
                                            }}
                                            className="btn btn-primary btn-sm"
                                        >

                                            <i className="fa fa-edit" aria-hidden="true"/>
                                        </button>
                                    </Link> : ''
                            }
                            {
                                row.rawData.status === 'pending' ? <button
                                    type="button"
                                    onClick={() => {this.handleOnCancelRequestClick(row.rawData)}}
                                    className="btn btn-danger btn-sm"
                                >
                                    <i className="fa fa-times" title={"Cancel Request"} aria-hidden="true"/>
                                </button> : ''
                            }
                        </>
                    ),
                    width: '10%',
                },

            ],
            data: [],
            cancelData: "",
            onOpenCancelModal: false,
            cancelMessage: "Are you sure you want to cancel the selected image request?"
        };
    }
    componentDidMount() {
        this.fetchImagingRequest();
    }

    fetchImagingRequest = async () => {
        await axios.get(`${this.state.serverLink}request/pending/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(data => {
                data.data.map((data, index) => {
                    this.setState({
                        data: [...this.state.data,
                            {
                                count: index+1,
                                rawData: data,
                            }
                        ],
                    });
                })
                this.setState({
                    isLoading: false,
                });
            });
    }

    handleOnCancelRequestClick = (row) => {
        this.setState({
            onOpenCancelModal: true,
            cancelData: row
        })
    }

    handleOnCancelRequest = async () => {
        this.setState({
            cancelMessage: <span className={"alert alert-info"}>Cancelling Request... Please wait!</span>
        });

        const sendData = {id: this.state.cancelData._id, imageAmount: this.state.cancelData.price,
            patientSerial: this.state.cancelData.patientSerial,
            paymentStatus: typeof this.state.cancelData.paymentStatus !== "undefined" ? this.state.cancelData.paymentStatus : 'unpaid',
            updatedBy: this.props.loginDetails.userID}
        await axios.patch(`${this.state.serverLink}request/cancel`, sendData, this.props.loginDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        cancelMessage: <span className={"alert alert-success"}>Request Cancelled Successfully</span>
                    });
                    this.fetchImagingRequest();
                    const object = this;
                    setTimeout(function () {
                        object.closeConfirmDialog();
                        object.setState({
                            cancelMessage: "Are you sure you want to cancel the selected image request?",
                            cancelData: ""

                        })
                    }, 1000)

                } else {
                    this.setState({
                        cancelMessage: <span className={"alert alert-danger"}>Something went wrong cancelling request. Please check your network and try again!</span>
                    });
                }
            })
            .catch()
    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
            onOpenCancelModal: false
        });
    };



    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search Imaging Request',

        };
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <ConfirmDialogBox
                    openDailog={this.state.onOpenCancelModal}
                    onSetOpenDialog={this.closeConfirmDialog}
                    handleConfirmOkBtn={this.handleOnCancelRequest}
                    isLoading={false}
                    isDecision={true}
                    title="CANCEL REQUEST?"
                    des={ this.state.cancelMessage }
                />
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Imaging Request" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                        </div>

                    </div>
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            className="tablehead"
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagingRequest);
