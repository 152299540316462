import React, {Component} from "react";
import {connect} from "react-redux";
import "./groupmembers.css";
import axios from "axios";
import {serverLink, hospitalCode} from "../../../../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import Navigator from "../../../navigation/navigation";

class GroupMembers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            groupMembers: [],
            systemUsers: [],
            columns: [
                {
                    name: "Full Name",
                    selector: row => `${row.title} ${row.fullName}`,
                    sortable: true
                },
                {
                    name: "User ID",
                    selector: row => `${row.userID}`,
                    sortable: true
                },
                {
                    name: "Added By",
                    selector: row => `${row.addedBy}`,
                    sortable: true
                },
                {
                    name: "Added Date",
                    selector: row => `${row.addedDate}`,
                    sortable: true
                },
                {
                    name: "Status",
                    selector: row => row.status,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <input type="checkbox"
                               checked={row.addedBy !== '--'}
                               id={JSON.stringify(row.rowData)}
                            onChange={row.addedBy === '--' ? this.onAddPermission : this.onRemovePermission}/>
                    ),
                }
            ],
            openErrorDialog: false,
            openAlertDialog: false,
            messageDescription: '',


        }
    }

    componentDidMount() {
        this.getUserData();
    }

    async getUserData () {
        await axios.get(`${serverLink}settings/group/users/${hospitalCode}/${this.props.groupData.groupName}`, this.props.loginData.token)
            .then(result => {
                this.setState({groupMembers: result.data})
            })
            .catch(error => {
                console.log("ERROR FETCHING MEMBERS")
            });

        await axios.get(`${serverLink}user/all/${hospitalCode}`, this.props.loginData.token)
            .then(result => {
                const users = result.data;
                if (users.length > 0) {
                    const group_members = this.state.groupMembers;
                    users.map((val, index) => {
                        const filter = group_members.filter(member => member.userID === val.userID);

                        const rowData = filter.length > 0 ? val['memberID'] = filter[0]._id : val;
                        this.setState({
                            systemUsers: [...this.state.systemUsers,
                                {
                                    serialNo: (index + 1),
                                    userID: val.userID,
                                    title: val.title,
                                    fullName: `${val.firstName} ${val.middleName} ${val.surname}`,
                                    addedBy: filter.length > 0 ? filter[0].submittedBy : '--',
                                    addedDate: filter.length > 0 ? this.formatDateAndTime(filter[0].submittedOn) : '--',
                                    status: filter.length > 0 ? 'Member' : 'Not Added',
                                    rowData: val
                                }
                            ]

                        })
                    })

                }
                this.setState({isLoading: false})
            })
            .catch(error => {
                console.log("ERROR FETCHING USERS")
            });

    }

    onAddPermission = (row) => {
        let user_data = JSON.parse(row.target.id);
        const sendData = {
            userID: user_data.userID,
            groupName: this.props.groupData.groupName,
            hospitalCode: hospitalCode,
            userFullName: `${user_data.firstName} ${user_data.middleName} ${user_data.surname}`,
            submittedBy: `${this.props.loginData.firstName} ${this.props.loginData.middleName} ${this.props.loginData.surname}`
        }

        this.setState({isLoading: true})

        axios.post(`${serverLink}settings/group/user/add`, sendData, this.props.loginData.token)
            .then(response => {
                this.setState({isLoading: false, systemUsers: []})

                if (response.data.message === 'success')
                    this.setState({
                        openAlertDialog: true,
                        messageDescription: `${this.props.groupData.groupName} Permission added for ${sendData.userFullName}`
                    })
                else
                    this.setState({
                        openErrorDialog: true,
                        messageDescription: `${this.props.groupData.groupName} Permission not added for ${sendData.userFullName} ==> ${response.data.message}`
                    })

                this.getUserData();
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    openErrorDialog: true,
                    messageDescription: `${this.props.groupData.groupName} Permission not added for ${sendData.userFullName}`
                })
            })

    }

    onRemovePermission = (row) => {
        let user_data = JSON.parse(row.target.id);
        const full_name = `${user_data.firstName} ${user_data.middleName} ${user_data.surname}`
        this.setState({isLoading: true})

        axios.delete(`${serverLink}settings/group/user/delete/${user_data.memberID}`, this.props.loginData.token)
            .then(message => {
                this.setState({isLoading: false, systemUsers: []})

                if (message.data.message === 'success')
                    this.setState({
                        openAlertDialog: true,
                        messageDescription: `${this.props.groupData.groupName} Permission removed for ${full_name}`
                    })
                else
                    this.setState({
                        openErrorDialog: true,
                        messageDescription: `${this.props.groupData.groupName} Permission not removed for ${full_name} ==> ${message.data.message}`
                    })

                this.getUserData();
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    openErrorDialog: true,
                    messageDescription: `${this.props.groupData.groupName} Permission not removed for ${full_name}`
                })
            })

    }

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()} : 
                    ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}
                    
                    `
        } else {
            return '--'
        }
    };

    closeDialog = () => {
        this.setState({
            openErrorDialog: false,
            openAlertDialog: false,
            messageDescription: ''
        })
    }

    render() {
        const columns = this.state.columns;
        const data = this.state.systemUsers;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search users'
        };

        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">
                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeDialog}
                        title="PERMISSION"
                        des={this.state.messageDescription}
                    />

                    <div className="topheader">
                        <Navigator title={`${this.props.groupData.groupName} Members`} />
                    </div>
                    <hr />

                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        groupData: state.reportDetails,
        loginData: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(GroupMembers);
