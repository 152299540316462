import React, { Component } from "react";
import axios from "axios";
import {serverLink, hospitalCode} from "../../../../../resources/url";
import "./grouplist";
import FormPrompt from "../../../../DialogBoxes/formprompt";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {setReportDetails} from "../../../../../actions/setpersondetailsaction";
import Navigator from "../../../navigation/navigation";

class GroupList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            groupList: [],
            onOpenAddModal: false,
            message: '',
            isAdd: true,
            oldGroupName: '',
            groupID: ''
        }
    }

    componentDidMount() {
        this.getAllGroupList();
    }

    async getAllGroupList () {
        await axios.get(`${serverLink}settings/group/all/${hospitalCode}`, this.props.userData.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                    groupList: result.data
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR FETCHING GROUP DATA')
            })
    }

    addNewGroup = (e) => {
        e.preventDefault();
        this.setState({
            message: <p className="alert alert-info">Please wait...</p>
        })
        const group_name = document.getElementById('addGroupName').value;

        if (group_name === '') {
            this.setState({
                message: <p className="alert alert-danger">Please enter the group name</p>
            })
            return false;
        }

        if (this.state.isAdd) {
            const sendData = {
                groupName: group_name,
                submittedBy: `${this.props.userData.firstName} ${this.props.userData.middleName} ${this.props.userData.surname}`,
                hospitalCode: hospitalCode
            }

            axios.post(`${serverLink}settings/group/add`, sendData, this.props.userData.token)
                .then(response => {
                    const message = response.data.message;
                    if (message === 'group exist')
                        this.setState({
                            message: <p className="alert alert-danger">Group with the same name already exist</p>
                        })
                    else {
                        document.getElementById('addGroupName').value = ''
                        this.setState({
                            message: <p className="alert alert-success">Group added successfully</p>
                        })
                        this.getAllGroupList();

                    }
                })
                .catch(error => {
                    console.log('NETWORK ERROR: ADDING GROUP')
                })
        }
        else {
            const sendData = {
                groupName: group_name,
                id: this.state.groupID,
                updatedBy: `${this.props.userData.firstName} ${this.props.userData.middleName} ${this.props.userData.surname}`,
                oldGroupName: this.state.oldGroupName,
                hospitalCode: hospitalCode
            }

            axios.patch(`${serverLink}settings/group/update`, sendData, this.props.userData.token)
                .then(response => {
                    const message = response.data.message;
                    if (message === 'success' || message === '') {
                        document.getElementById('addGroupName').value = ''
                        this.setState({
                            message: <p className="alert alert-success">Group added successfully</p>,
                            oldGroupName: '',
                            groupID: ''
                        })
                        this.getAllGroupList();
                        this.onCloseDialog();

                    }
                })
                .catch(error => {
                    console.log('NETWORK ERROR: ADDING GROUP')
                })
        }

    }

    onOpenAddModal = () => {
        this.setState({onOpenAddModal: true, message: '', isAdd: true})
    }

    onOpenUpdateModal = (groupName, groupID) => {
        document.getElementById('addGroupName').value = groupName
        this.setState({onOpenAddModal: true, message: '', isAdd: false, oldGroupName: groupName, groupID: groupID})
    }

    onCloseDialog = () => {
        document.getElementById('addGroupName').value = ''
        this.setState({
            onOpenAddModal: false
        })
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `${user_date.getDate()}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}`
        } else {
            return '--'
        }
    };

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">

                    <div className="topheader">
                        <Navigator title="Groups" />
                    </div>
                    <hr />

                    <div className="top_section">
                        <FormPrompt
                            openDialog = {this.state.onOpenAddModal}
                            title = {this.state.isAdd ? 'Add New Group' : 'Update New Group'}
                            onSetOpenDialog={this.onCloseDialog}
                            isCloseBtnAppear = {true}
                        >
                            <form onSubmit={this.addNewGroup}>
                                <div className="form-group">
                                    <label htmlFor="GroupName">Group Name</label>
                                    <input type="text" className="form-control" id="addGroupName" name="addGroupName" placeholder="Enter the Group Name"/>
                                </div>
                                <button type="submit" className="btn btn-primary btn-block">{this.state.isAdd ? 'Add Group' : 'Update Group'}</button>
                                {this.state.message}
                            </form>

                        </FormPrompt>
                        <div className="wrap"/>
                        <button
                            onClick={this.onOpenAddModal}
                            type="button"
                            className="btn btn-warning"
                        > <i className="fa fa-plus"/> </button>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Group Name</th>
                            <th scope="col">Added By</th>
                            <th scope="col">Added Date</th>
                            <th scope="col">Updated By</th>
                            <th scope="col">Updated Date</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Members</th>
                            <th scope="col">Permissions</th>
                            <th scope="col">Services</th>
                        </tr>
                        </thead>
                        {
                            this.state.groupList.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Group Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.groupList &&
                                this.state.groupList.map((p) => {
                                    count++;
                                    let addedDate = new Date(p.submittedOn);
                                    const dateAdded = `${addedDate.getDate()}/${addedDate.getMonth() + 1}/${addedDate.getFullYear()}`;
                                    return (
                                        <tr key={p._id}>
                                            <td className="align-middle">{count}</td>
                                            <td className="align-middle">
                                                {p.groupName}
                                            </td>
                                            <td className="align-middle">
                                                {p.submittedBy}
                                            </td>
                                            <td className="align-middle">
                                                {this.formatDate(p.submittedOn)}
                                            </td>
                                            <td className="align-middle">
                                                {p.updatedBy !== '' ? p.updatedBy : '--'}
                                            </td>
                                            <td className="align-middle">
                                                {this.formatDate(p.updatedOn)}
                                            </td>
                                            <td className="align-middle">
                                                <button type="button" className="btn btn-success" onClick={() => this.onOpenUpdateModal(p.groupName, p._id)}>
                                                    <i className="fa fa-edit" aria-hidden="true"/>
                                                </button>
                                            </td>
                                            <td className="align-middle">
                                                <Link to="/settings/group/members">
                                                    <button type="button" className="btn btn-primary" onClick={() => {this.props.setOnReportDetails(p)}}>
                                                        <i className="fa fa-eye" aria-hidden="true"/>
                                                    </button>
                                                </Link>
                                            </td>
                                            <td className="align-middle">
                                                <Link to="/settings/group/permission">
                                                    <button type="button" className="btn btn-primary" onClick={() => {this.props.setOnReportDetails(p)}}>
                                                        <i className="fa fa-unlock" aria-hidden="true"/>
                                                    </button>
                                                </Link>
                                            </td>
                                            <td className="align-middle">
                                                <Link to="/settings/services/permission">
                                                    <button type="button" className="btn btn-info" onClick={() => {this.props.setOnReportDetails(p)}}>
                                                        <i className="fa fa-lock" aria-hidden="true"/>
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>

                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.loginDetails,
        permissionData: state.permissionDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnReportDetails: (p) => {
            dispatch(setReportDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
