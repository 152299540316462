import React from "react";

function PatientServiceList(props) {
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>S/N</th>
                    <th>Service</th>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                    <th>Status</th>
                </tr>
            </thead>

            <tbody>
            {
                props.service_list.length > 0 &&
                    props.service_list.map((item, index) => {
                        return <tr key={index}>
                            <td>{(index+1)}</td>
                            <td>{item.serviceName}</td>
                            <td>{item.itemName}</td>
                            <td>{item.itemQty}</td>
                            <td>{props.money_format(item.itemPrice)}</td>
                            <td>{props.money_format(item.itemTotal)}</td>
                            <td>{item.status}</td>
                        </tr>
                    })
            }
            </tbody>
        </table>
    )
}

export default PatientServiceList;