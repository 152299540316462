import axios from "axios";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { hospitalCode, serverLink } from "../../../resources/url";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import "./changepassword.css";
import {connect} from "react-redux";
import ChangePasswordForm from "./changepasswordform/changepasswordform";


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                username: this.props.loginDetails.userID,
                password: "",
                newPassword: "",
                confirmPassword: "",
            },

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onChangePassword();
    };


    async onChangePassword() {
        let sendData = {
            ...this.state.formData
        };

        if(this.state.formData.password === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Your Old Password"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.newPassword === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter New Password"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.confirmPassword === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Confirm Password"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.newPassword !== this.state.formData.confirmPassword) {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Password Mismatch"
            this.handleErrorDialog();
            return false;
        }

        await axios.post(`${serverLink}user/login`, sendData)
            .then(login => {

                if (login.data.message === 'success') {
                    const data = login.data.user;
                    const form_data = {id: data._id, newPassword: this.state.formData.newPassword}

                     axios.patch(`${this.state.serverLink}user/password/update`, form_data, this.props.doctorDetails.token)
                        .then(result => {
                            this.setState({
                                isLoading: false,
                            });
                            const response = result.data.message;
                            if (response === "error") {
                                this.state.messageDescription = "Something went wrong changing your password. Please try again!";
                                this.handleErrorDialog();
                            } else if (response === "success"){
                                this.state.messageDescription = "PASSWORD CHANGED SUCCESSFULLY";
                                this.handleSuccessDialog();
                            } else {
                                this.state.messageDescription = "Failed to change your password!";
                                this.handleErrorDialog();
                            }
                        })
                        .catch(err => {
                            this.setState({
                                isLoading: false,
                            });
                            console.error('ERROR', err);
                        });


                } else {
                    this.setState({isLoading: false});
                    this.state.messageDescription = "Incorrect old password!";
                    this.handleErrorDialog();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    disableBtn: "",
                    invalid: "",
                });
            });

    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/user/password/update';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Password Changed"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <ChangePasswordForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Change Password"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(ChangePassword);