import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./dashboard.css";
import { loginValidation } from "../../../context/loginvalidation";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";
import MenuIcon from "../../Dashboard/utils/menu-icon";
import {
    FaFilePrescription,
    FaPills,
    FaPrescription,
    FaWarehouse
} from "react-icons/fa";


const PharmacyDashboard = ({ permissionData, doctorDetails }) => {
    const [loading, setLoading] = useState(true);
    const [drugInventory, setDrugInventory] = useState(0);
    const [manufacturers, setManufacturer] = useState(0);
    const [pendingPrescription, setPendingPrescription] = useState(0);
    const [completedPrescription, setCompletedPrescription] = useState(0);

    useEffect(() => {
        if (loginValidation(window.location.pathname, permissionData) !== 'permitted') {
            window.location.href = '/';
        }
        fetchDetails();
    }, []);

    const fetchDetails = async () => {
        try {
            const dashboard_response = await axios.get(`${serverLink}report/pharmacy/dashboard/${hospitalCode}`, doctorDetails.token);
            const prescription = dashboard_response.data.prescription;
            const drug = dashboard_response.data.drug;
            const manufacturer = dashboard_response.data.manufacturer;

            //Set Count
            setPendingPrescription(prescription.filter(e => e.paymentStatus === 'unpaid').length)
            setCompletedPrescription(prescription.filter(e => e.paymentStatus === 'paid').length)
            setManufacturer(manufacturer.length)
            setDrugInventory(drug.length)

            //Set Chart Data

            setLoading(false)
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    }

    return (loading ? (
                <div className="dashboardpage">
                    <i className="fas fa-spinner fa-pulse fa-2x "/>
                </div>
            ) :
        <div className="dashboardpage">
            <div className="topheader">
                <ul>
                    <li>
                        <i
                            className="fa fa-book-medical fa-2x"
                            aria-hidden="true"
                        />
                    </li>
                    <li>
                        <span>Pharmacy Dashboard</span>
                    </li>
                </ul>
            </div>

            <div className="first_section">
                <div className="row">
                    <div className="col-md-3">
                        <MenuIcon name="Drug Inventory" icon={<FaPills size={40}/>} count={drugInventory}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Manufacturers" icon={<FaWarehouse size={40}/>} count={manufacturers}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Pending Prescription" icon={<FaPrescription size={40}/>} count={pendingPrescription}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Completed Prescription" icon={<FaFilePrescription size={40}/>} count={completedPrescription}/>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="col-md-12 mt-3 mb-0" style={{borderBottom: '1px solid #CCCCCC', borderTop: '1px solid #CCCCCC', padding: '5px'}}>
                            <h2><b>Menu</b></h2>
                        </div>
                    </div>

                    {permissionData &&
                        permissionData.map((perm, index) => {
                            if (perm.mainMenuName === 'Pharmacy' && perm.mainMenuLink === null) {
                                return (
                                    <div key={index} className="col-sm-4">
                                        {" "}
                                        <Link
                                            to={perm.subMenuLink}
                                        >
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(PharmacyDashboard);
