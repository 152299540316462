import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./capturevital.css";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";

class CaptureVital extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,
            patientSerial: this.props.patientData.patientSerial,
            patientFullName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                this.props.patientData.surname,

            formData: {
                patientSerial: this.props.patientData.patientSerial,
                patientName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                    this.props.patientData.surname,
                appointmentID: "",
                respiration: "",
                pulse: "",
                bloodPressure: "",
                temperature: "",
                hospitalCode: hospitalCode,
                temperature_unit: "C",
                weight: "",
                weight_unit: "KG",
                height: "",
                height_unit: "M",
                bmi: "",
                bsa: "",
                spo2: "",
                timeTaken: "",
                dateTaken: "",
                remark: "",
                checkInBy: `${this.props.nurseDetail.firstName} ${this.props.nurseDetail.middleName} ${this.props.nurseDetail.surname}`,
                submittedBy: `${this.props.nurseDetail.firstName} ${this.props.nurseDetail.middleName} ${this.props.nurseDetail.surname}`,
                _id: ""
            },
            errorMessage: ""
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const sendData = {
            ...this.state.formData
        }
        if(this.state.formData.pulse === "" && this.state.formData.temperature === "" && this.state.formData.respiration === "" &&
            this.state.formData.bloodPressure === "" && this.state.formData.weight === "" &&
            this.state.formData.height === "" &&  this.state.formData.bmi === "" &&
            this.state.formData.bsa === "" && this.state.formData.spo2 === "")
        {
            this.setState({
                errorMessage: <p className="text-danger text-center">You have to fill at least one vital before submitting</p>,
                isLoading: false
            })
            return false;
        } else {
            this.setState({
                errorMessage: ""
            })
        }

        axios.post(`${this.state.serverLink}vital/add`, sendData, this.props.nurseDetail.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "success") {
                    this.setState({
                        errorMessage: <p className="alert alert-success text-center">Vital Added</p>
                    })
                    setTimeout(function () {
                        window.location.reload();
                    },2000)

                } else {
                    this.setState({
                        errorMessage: <p className="text-danger text-center">Something went wrong adding vital. Please check your connection and try again</p>
                    })
                }
            })
            .catch(err => {
                console.log(err)
            });

    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            {this.state.errorMessage}
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="patientSerial">Patient ID</label>
                                        <input
                                            name="patientSerial"
                                            type="text"
                                            className="form-control"
                                            id="patientSerial"
                                            disabled
                                            value={this.state.patientSerial}
                                            onChange={this.onEdit}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="patientSerial">Patient Name</label>
                                        <input
                                            name="patientSerial"
                                            type="text"
                                            className="form-control"
                                            id="patientSerial"
                                            disabled
                                            value={this.state.patientFullName}
                                            onChange={this.onEdit}
                                            required
                                        />
                                    </div>
                                </div>

                                        <div className="form-row">
                                            {
                                                this.props.patientAppointment.length > 0 &&
                                                    <div className="col-md-6 mb-3">

                                                        <label htmlFor="appointmentID">Select the Checked in
                                                            Appointment</label>
                                                        <select
                                                            name="appointmentID"
                                                            className="custom-select"
                                                            id="appointmentID"
                                                            onChange={this.onEdit}
                                                        >
                                                            <option value="">Select Appointment</option>
                                                            {
                                                                this.props.patientAppointment.map((appt) => {
                                                                    if (appt.appointmentStatus === 'Checked In')
                                                                        return <option key={appt._id} value={appt._id}>
                                                                            Department: {appt.appointmentDepartment} =>
                                                                            Doctor: {appt.doctorName} =>
                                                                            Time: {appt.appointmentTime}
                                                                        </option>
                                                                })
                                                            }

                                                        </select>
                                                    </div>
                                            }

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="pulse">Pulse</label>
                                                <input
                                                    type="text"
                                                    id="pulse"
                                                    name="pulse"
                                                    className="form-control"
                                                    placeholder="Pulse"
                                                    value={this.state.formData.pulse}
                                                    onChange={this.onEdit}
                                                />
                                            </div>
                                        </div>


                                <div className="form-row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="bloodPressure">Blood Pressure</label>
                                        <input
                                            type="text"
                                            id="bloodPressure"
                                            name="bloodPressure"
                                            className="form-control"
                                            placeholder="Blood Pressure"
                                            value={this.state.formData.bloodPressure}
                                            onChange={this.onEdit}
                                        />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="temperature">Temperature</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                id="temperature"
                                                name="temperature"
                                                className="form-control"
                                                placeholder="Patient Temperature"
                                                value={this.state.formData.temperature}
                                                onChange={this.onEdit}
                                            />
                                            <span className="input-group-btn">
                                              <select
                                                  className="btn"
                                                  id="temperature_unit"
                                                  name="temperature_unit"
                                                  defaultValue={this.state.formData.temperature_unit}
                                                  onChange={this.onEdit}
                                              >
                                                  <option value="C">Celsius</option>
                                                  <option value="F">Fahrenheit</option>
                                              </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="height">Height</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                id="height"
                                                name="height"
                                                className="form-control"
                                                placeholder="Patient Height"
                                                value={this.state.formData.height}
                                                onChange={this.onEdit}
                                            />
                                            <span className="input-group-btn">
                                              <select
                                                  className="btn"
                                                  id="height_unit"
                                                  name="height_unit"
                                                  defaultValue={this.state.formData.height_unit}
                                                  onChange={this.onEdit}
                                              >
                                                  <option value="M">Meter</option>
                                                  <option value="CM">Centimeter</option>
                                              </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="weight">Weight</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                id="weight"
                                                name="weight"
                                                className="form-control"
                                                placeholder="Patient Weight"
                                                value={this.state.formData.weight}
                                                onChange={this.onEdit}
                                            />
                                            <span className="input-group-btn">
                                              <select
                                                  className="btn"
                                                  id="weight_unit"
                                                  name="weight_unit"
                                                  defaultValue={this.state.formData.weight_unit}
                                                  onChange={this.onEdit}
                                              >
                                                  <option value="KG">Kilogram</option>
                                                  <option value="LB">Pound</option>
                                              </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="bmi">BMI</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                id="bmi"
                                                name="bmi"
                                                className="form-control"
                                                placeholder="Patient BMI"
                                                value={this.state.formData.bmi}
                                                onChange={this.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="bsa">BSA</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                id="bsa"
                                                name="bsa"
                                                className="form-control"
                                                placeholder="Patient BSA"
                                                value={this.state.formData.bsa}
                                                onChange={this.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="spo2">SPO2</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                id="spo2"
                                                name="spo2"
                                                max={100}
                                                className="form-control"
                                                placeholder="Patient SPO2 (in percentage)"
                                                value={this.state.formData.spo2}
                                                onChange={this.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="respiration">Respiration</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                id="respiration"
                                                name="respiration"
                                                className="form-control"
                                                placeholder="Patient respiration"
                                                value={this.state.formData.respiration}
                                                onChange={this.onEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="respiration">Vital Taken Date and Time</label>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <input
                                                        type="date"
                                                        required
                                                        id="dateTaken"
                                                        name="dateTaken"
                                                        className="form-control"
                                                        value={this.state.formData.dateTaken}
                                                        onChange={this.onEdit}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="time"
                                                        required
                                                        id="timeTaken"
                                                        name="timeTaken"
                                                        className="form-control"
                                                        value={this.state.formData.timeTaken}
                                                        onChange={this.onEdit}
                                                    />
                                                </div>
                                            </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="respiration">Remark</label>
                                        <textarea rows={3} cols={3} className="form-control" id="remark" name="remark" value={this.state.formData.remark} onChange={this.onEdit} />
                                    </div>

                                </div>
                                <button type="submit" className="btn btn-success btn-block">Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        nurseDetail: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(CaptureVital);
