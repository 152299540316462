import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hospitalCode, serverLink } from "../../../../resources/url";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import PlanSubscriptionForm from "../plansubscriptionform/plansubscriptionform";
import "./plansubscription.css";

class PlanSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,
            patientSerial: this.props.patientSerial,
            patientName: this.props.patientName,

            formData: {
                patientSerial: this.props.patientSerial,
                patientName: this.props.patientName,
                planName: "",
                companyName: "",
                planID: "",
                amount: "",
                duration: "",
                expiryDate: "",
                planDescription: "",
                specialistType: "",
                hospitalCode: "",
                submittedBy: "",
            },

            planList: [],
            amount: "",
            duration: "",
            errorMessage: "",
            successMessage: "",
            loadingMsg: false,

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            messageTitle: "",
            messageDescription: "",
        };
    }

    componentDidMount() {
        this.state.formData.submittedBy = localStorage.getItem('userID');
        this.state.formData.hospitalCode = this.state.hospitalCode;
        this.fetchPlan();
        document.getElementById("plan-form").reset();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.onSubscribePatient();
    };

    async onSubscribePatient() {
        let sendData = {
            ...this.state.formData
        };
        this.setState({
            loadingMsg: true,
            errorMessage: "",
            successMessage:""
        });
        if(this.state.formData.planName === "") {
            this.setState({
                loadingMsg: false,
            });
            this.handleErrorDialog('ERROR', 'Please Select the Plan Name');
            this.state.messageDescription = 'Please Select the Plan Name';
            return false;
        }

        axios.post(`${this.state.serverLink}insurance/subscription/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                    loadingMsg: false,
                });
                const response = result.data.message;
                if(response === "plan subscription exist") {
                    this.setState({
                        loadingMsg: false,
                        errorMessage: "You are already subscribed to this plan",
                    });
                } else if (response === "error") {
                    this.setState({
                        loadingMsg: false,
                        errorMessage: "Something went wrong subscribing to plan. Please try again!",
                    });
                } else if (response === "success"){
                    this.setState({
                        loadingMsg: false,
                        successMessage: "PLAN SUBSCRIPTION SUCCESSFULLY",
                    });
                } else {
                    this.setState({
                        loadingMsg: false,
                        errorMessage: "Failed to subscribe to plan!",
                    });
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    };

    async fetchPlan() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}insurance/plan/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    planList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }


    onEdit = (e) => {
        const formData = this.state.formData;
        const patientName = this.props.patientName;
        const patientSerial = this.props.patientSerial;

        if(e.target.name === "planName") {
            let index = e.nativeEvent.target.selectedIndex;
            let duration = e.nativeEvent.target[index].getAttribute("duration");
            let insuranceCompany = e.nativeEvent.target[index].getAttribute("companyname");
            let amount = e.nativeEvent.target[index].getAttribute("amount");
            let planName = e.nativeEvent.target[index].getAttribute('value');
            let planID = e.nativeEvent.target[index].getAttribute("message");
            let specialistType = e.nativeEvent.target[index].getAttribute("specialisttype").split(",");
            let desc = e.nativeEvent.target[index].getAttribute("desc");
            const current_data = new Date();
            const today = new Date(current_data.getFullYear(), current_data.getMonth(), current_data.getDate())
            today.setMonth(today.getMonth()+parseInt(duration))

            formData.expiryDate = today;
            formData.amount = amount;
            formData.planName = planName;
            formData.planID = planID;
            formData.specialistType = specialistType;
            formData.duration = duration;
            formData.planDescription = desc;
            formData.companyName = insuranceCompany;
            formData.patientName = patientName;
            formData.patientSerial = patientSerial;
        }
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };
    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="plansubscriptionpage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="plansubscriptionpage">
                <div className="container main_section">
                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Patient Suscribed"
                        des={this.state.messageDescription}
                    />
                    <div className="row">
                        <div className="col-sm">
                            <PlanSubscriptionForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                patientSerial={this.props.patientSerial}
                                patientName={this.props.patientName}
                                successMessage={this.state.successMessage}
                                errorMessage={this.state.errorMessage}
                                loadingMsg={this.state.loadingMsg}
                                planList={this.state.planList}
                                formData={this.state.formData}
                                formType="add"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default PlanSubscription;
const mapStateToProps = (state) => {
    return {
        patientData: state.personDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(PlanSubscription);
