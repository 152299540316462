import React, {Component} from "react";

import "./consultationprofile.css";
import {connect} from "react-redux";
import { serverLink, hospitalCode } from "../../../../resources/url";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import axios from "axios";
import FormPrompt from "../../../DialogBoxes/formprompt";
import ConsultationScheduleForm from "../consultationform/consultationform";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import {loginValidation} from "../../../../context/loginvalidation";
import Navigator from "../../../Settings/navigation/navigation";

class DoctorConsultationProfile extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            submittedBy: localStorage.getItem('userID'),
            isLoading: false,
            doctorID: this.props.doctorData.userID,
            doctorName: `${this.props.doctorData.firstName} ${this.props.doctorData.middleName} ${this.props.doctorData.surname}`,
            scheduleData: [],
            scheduleID: "",

            formData: {
                monday: {},
                tuesday: {},
                wednesday: {},
                thursday: {},
                friday: {},
                saturday: {},
                sunday: {}
            },

            openAddScheduleDialog: false,
            openUpdateScheduleDialog: false,
            isCloseDialogAppear: true,

            openAlertDialog: false,
            openErrorDialog: false,



            errorMessage: "",
            messageTitle: "",
            messageDescription: ""
        }
    }

    componentDidMount() {
        this.fetchDoctorConsultationRecord();
    }

    async fetchDoctorConsultationRecord () {
        this.setState({
            isLoading: true
        })
        await axios.get(`${this.state.serverLink}doctor_schedule/${this.state.hospitalCode}/${this.state.doctorID}` , this.props.doctorDetails.token)
            .then(result => {
                if (result.data != null) {
                    this.setState({
                        scheduleData: result.data.scheduleData,
                        scheduleID: result.data._id
                    })

                    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
                    days.map((day) => {
                        result.data.scheduleData.map((dataset) => {
                            if(typeof dataset[day] != "undefined") {
                                this.state.formData[day].duration = dataset[day].duration
                                this.state.formData[day].startTime = dataset[day].startTime
                                this.state.formData[day].endTime = dataset[day].endTime
                                this.state.formData[day].numberOfSlots = dataset[day].numberOfSlots
                                this.state.formData[day].slots = dataset[day].slots
                            }
                        })
                    })
                }



                this.setState({
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    onAddSchedule = () => {
        this.setState({
            openAddScheduleDialog: true
        })
    }

    onUpdateSchedule = () => {
        this.setState({
            openUpdateScheduleDialog: true
        })
    }


    onCloseAddSchedule = () => {
        this.setState({
            openAddScheduleDialog: false
        })
    }

    onCloseUpdateSchedule = () => {
        this.setState({
            openUpdateScheduleDialog: false
        })
    }

    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    handleAddScheduleSubmit = (e) => {
        e.preventDefault();

        const  sendData = {
            doctorID: this.state.doctorID,
            doctorName: this.state.doctorName,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy,
            scheduleData: this.state.formData
        }

        this.setState({
            openAddScheduleDialog: false,
            //isLoading: true
        });

        axios.post(`${serverLink}doctor_schedule/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                console.log(result)
                if (result.data.message === 'success') {
                    this.setState({
                        isLoading: false,
                        messageTitle: 'Schedule Added',
                        messageDescription: 'Doctor schedule added successfully',
                        openAlertDialog: true

                    });
                } else {
                    this.setState({
                        isLoading: false,
                        messageTitle: 'ERROR',
                        messageDescription: 'Doctor schedule not added. Please refresh and try again. Alternatively, check your internet connection!',
                        openErrorDialog: true

                    });

                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    messageTitle: 'CONNECTION ERROR',
                    messageDescription: 'Doctor schedule not added. Please refresh and try again. Alternatively, check your internet connection!',
                    openErrorDialog: true

                });
            })

    }

    handleUpdateScheduleSubmit = (e) => {
        e.preventDefault();

        const  sendData = {
            id: this.state.scheduleID,
            doctorID: this.state.doctorID,
            doctorName: this.state.doctorName,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy,
            scheduleData: this.state.formData
        }

        this.setState({
            openUpdateScheduleDialog: false,
            //isLoading: true
        });

        axios.patch(`${serverLink}doctor_schedule/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    this.setState({
                        isLoading: false,
                        messageTitle: 'Schedule Updated',
                        messageDescription: 'Doctor schedule updated successfully',
                        openAlertDialog: true

                    });
                } else {
                    this.setState({
                        isLoading: false,
                        messageTitle: 'ERROR',
                        messageDescription: 'Doctor schedule not updated. Please refresh and try again. Alternatively, check your internet connection!',
                        openErrorDialog: true

                    });

                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    messageTitle: 'CONNECTION ERROR',
                    messageDescription: 'Doctor schedule not updated. Please refresh and try again. Alternatively, check your internet connection!',
                    openErrorDialog: true

                });
            })

    }

    closeAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
        });
        this.fetchDoctorConsultationRecord()
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    getTimeInterval = (startTime, endTime) => {
        let diff =(startTime.getTime() - endTime.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    onConstructArrayData = (event, day, action_type) => {
        const element = event.target.name
        const duration = this.state.formData[day].duration

        if (element === day+'_duration') {
            this.state.formData[day].duration = event.target.value
            this.state.formData[day].endTime = ''
            this.state.formData[day].numberOfSlots = 0
        } else if (element === day+'_startTime'){
            //ADD START TIME TO THE START TIME STATE
            this.state.formData[day].startTime = event.target.value
        }
        else {
            //SHOW ERROR IF USER DID NOT SELECT START TIME FIRST
            if (typeof this.state.formData[day].startTime == "undefined") {
                this.setState({
                    errorMessage: "Please select start time first",
                })
            }
            else {
                this.state.formData[day].endTime = event.target.value;
                let startTime = new Date("01/01/2007 " + this.state.formData[day].startTime);
                let endTime = new Date("01/01/2007 " + this.state.formData[day].endTime);

                if (startTime >= endTime) {
                    this.setState({
                        errorMessage: "Start time cannot be greater than or equal to the end time",
                    })
                    this.state.formData[day].endTime = '';
                }

                if (typeof this.state.formData[day].duration == "undefined") {
                    this.setState({
                        errorMessage: "Please select appointment duration first",
                    })
                    this.state.formData[day].endTime = '';
                }
                else {
                    let appointment_min_interval = this.getTimeInterval(startTime, endTime);
                    let duration_data_set = 0;

                    //DURATION IS HOURS
                    if (duration.length === 1) {
                        duration_data_set = appointment_min_interval / (parseInt(duration)*60)

                    }
                    else {
                        //DURATION IS MINUTES
                        duration_data_set = appointment_min_interval / parseInt(duration);
                    }
                    this.state.formData[day].numberOfSlots = Math.round(duration_data_set)


                    let slots = [];
                    //GET ALL THE BOOKED SLOTS
                    if(action_type === 'update') {
                        if(typeof this.state.formData[day].slots != 'undefined') {
                            this.state.formData[day].slots.map((slot) => {
                                if(slot.status === 'booked') {
                                    slots.push(slot)
                                }
                            })
                        }
                    }

                    //GET CURRENT START TIME
                    let start_slot_time = this.state.formData[day].startTime;
                    [...Array(Math.round(duration_data_set))].map((number, index) => {
                        let slot = "";
                        if(duration.length === 1)
                            slot = new Date(startTime.getTime() + parseInt(duration)*60*(index+1)*60000);
                        else
                            slot = new Date(startTime.getTime() + parseInt(duration)*(index+1)*60000);

                        let end_time_min_checker = slot.getMinutes() < 10 ? "0"+slot.getMinutes() : slot.getMinutes()
                        let end_slot_time = `${slot.getHours()}:${end_time_min_checker}`;
                        let slot_data = `${start_slot_time} - ${end_slot_time}`;
                        slots.push({slot: slot_data, status: 'available'});

                        start_slot_time = end_slot_time;
                    })

                    if (action_type === 'add')
                        this.state.formData[day].slots = slots
                    else {

                        this.state.formData[day].slots = slots.reduce((acc, current) => {
                            const x = acc.find(item => item.slot === current.slot);
                            if (!x) {
                                return acc.concat([current]);
                            } else {
                                return acc;
                            }
                        }, [])
                    }

                }

            }

        }

    }

    onEdit = (e) => {
        let type = this.state.openAddScheduleDialog ? 'add' : 'update'
        const element = e.target.name;
        this.setState({
            errorMessage: ""
        });

        if (element.includes('monday_')) {
            this.onConstructArrayData(e, 'monday', type)
        }

        if (element.includes('tuesday_')) {
            this.onConstructArrayData(e, 'tuesday', type)
        }

        if (element.includes('wednesday_')) {
            this.onConstructArrayData(e, 'wednesday', type)
        }

        if (element.includes('thursday_')) {
            this.onConstructArrayData(e, 'thursday', type)
        }

        if (element.includes('friday_')) {
            this.onConstructArrayData(e, 'friday', type)
        }

        if (element.includes('saturday_')) {
            this.onConstructArrayData(e, 'saturday', type)
        }

        if (element.includes('sunday_')) {
            this.onConstructArrayData(e, 'sunday', type)
        }
    }



    render() {
        const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">
                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />


                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    {/*ADD SCHEDULE FORM*/}
                    <FormPrompt
                        openDialog={this.state.openAddScheduleDialog}
                        title="Add Doctor Schedule"
                        onSetOpenDialog={this.onCloseAddSchedule}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <ConsultationScheduleForm
                            handleSubmit = {this.handleAddScheduleSubmit}
                            onEdit = {this.onEdit}
                            scheduleData = {this.state.formData}
                            errorMessage={this.state.errorMessage}
                        >

                        </ConsultationScheduleForm>

                    </FormPrompt>

                    {/*UPDATE SCHEDULE FORM*/}
                    <FormPrompt
                        openDialog={this.state.openUpdateScheduleDialog}
                        title="Update Doctor Schedule"
                        onSetOpenDialog={this.onCloseUpdateSchedule}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <ConsultationScheduleForm
                            handleSubmit = {this.handleUpdateScheduleSubmit}
                            onEdit = {this.onEdit}
                            scheduleData = {this.state.formData}
                            errorMessage={this.state.errorMessage}
                        >

                        </ConsultationScheduleForm>

                    </FormPrompt>

                    <div className="topheader">
                        <Navigator title={`${this.state.doctorName}'s Consultation Calender`} />
                        <span className="badge badge-primary text-right">AVAILABLE</span> <span className="badge badge-danger">BOOKED</span>
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">

                        </div>

                        {this.state.scheduleData.length > 0 ?
                            <button type="button" onClick={this.onUpdateSchedule} className="btn btn-warning" > + Update Schedule </button> :
                            <button type="button" onClick={this.onAddSchedule} className="btn btn-warning" > + Add Schedule </button>
                        }

                    </div>

                    <div>
                        {this.state.scheduleData.length < 1 ?
                            <span className="alert alert-info">No consultation record found for {this.state.doctorName}. Use add schedule above to add</span>
                            :

                            <div className="row pt-3">
                                <div className="col-md-12">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Day</th>
                                                <th>Duration</th>
                                                <th>Start</th>
                                                <th>End</th>
                                                <th>Num of Slots</th>
                                                <th>Slots</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            days.map((day, index) => {
                                                return (
                                                    <tr key={(index+1)} id={(index+1)}>
                                                        <td className="text-capitalize">{day}</td>
                                                        <td>{typeof this.state.formData[day].duration != 'undefined' ?
                                                            this.state.formData[day].duration.length === 1 ?
                                                                `${this.state.formData[day].duration} hour` :
                                                                `${this.state.formData[day].duration} mins`

                                                            : '--'}</td>
                                                        <td>{typeof this.state.formData[day].startTime != 'undefined' ? this.state.formData[day].startTime : '--'}</td>
                                                        <td>{typeof this.state.formData[day].endTime != 'undefined' ? this.state.formData[day].endTime : '--'}</td>
                                                        <td>{typeof this.state.formData[day].numberOfSlots != 'undefined' ? this.state.formData[day].numberOfSlots : '--'}</td>
                                                        <td>
                                                            {
                                                                typeof this.state.formData[day].slots != 'undefined'  ?
                                                                    this.state.formData[day].slots.map((slot, index) => {
                                                                        let color = slot.status === 'available' ? 'badge badge-primary' : 'badge badge-danger';
                                                                        return (<div key={index}>
                                                                            <span key={index}
                                                                                className={color}

                                                                            >{slot.slot}</span>
                                                                            { Number.isInteger((index+1) / 5) ? <br/> : '' }
                                                                        </div>)
                                                                    })

                                                                    :
                                                                    '--'
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                                
                                            })
                                            
                                        }
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>


                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        doctorData: state.personDetails,
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(DoctorConsultationProfile);

