import React, {Component} from "react";
import "./addspecialisation.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import SpecialisationForm from "../specialisationform/specialisationform";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";


class AddSpecialisation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"specialisation/",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                specialisationName: "",
                specialisationDescription: "",
                consultationFee: 0,
                submittedBy: ""
            },

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.state.submittedBy = localStorage.getItem("userID");
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddSpecialisation();
    };


    async onAddSpecialisation() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy
        };

        if(this.state.formData.specialisationName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Specialisation Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.consultationFee === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Consultation Fee"
            this.handleErrorDialog();
            return false;
        }

        axios.post(`${this.state.serverLink}add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "specialisation exist") {
                    this.state.messageDescription = "A specialisation with the same name already exist";
                    this.handleErrorDialog();
                } else if (response === "error") {
                    this.state.messageDescription = "Something went wrong adding the specialisation. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.state.messageDescription = "SPECIALISATION ADDED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to upload the specialisation details!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
       window.location.href = '/specialisation/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Specialisation Added"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <SpecialisationForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Add Specialisation"
                            ></SpecialisationForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AddSpecialisation);
