import React, {Component} from "react";
import "./update-manufacturer.css";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import { connect } from "react-redux";
import ItemManufacturerForm from "../manufacturer-form/manufacturer-form";
import {showAlert} from "../../../../Common/sweetalert/sweetalert";


class UpdateItemManufacturer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"finance/",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.ManufacturerData._id,
                manufacturerName:  this.props.ManufacturerData.manufacturerName,
                oldManufacturerName: this.props.ManufacturerData.manufacturerName,
                description:  this.props.ManufacturerData.description,
                address: this.props.ManufacturerData.address,
                phoneNumber:  this.props.ManufacturerData.phoneNumber,
                emailAddress:  this.props.ManufacturerData.emailAddress,
                updatedBy: ""
            },

        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: localStorage.getItem("userID")
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateManufacturer();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdateManufacturer() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy
        };

        if(this.state.formData.manufacturerName.trim() === "") {
            this.setState({
                isLoading: false,
            });
            showAlert("EMPTY FIELD", "Please Enter the Manufacturer Name", "error");
            return false;
        }





        axios.patch(`${this.state.serverLink}manufacturer/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    showAlert("MANUFACTURER UPDATED", "MANUFACTURER UPDATED SUCCESSFULLY", "success").then(()=>{
                        window.location.href = '/finance/inventory/manufacturer';
                    });
                } else {
                    showAlert("ERROR", "Something went wrong updating your record.", "error");
                    return false;
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
                showAlert("ERROR", "Request not submitted. Check your console for more info", "error");
                return false;

            });
    }

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <ItemManufacturerForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Manufacturer"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ManufacturerData: state.generalDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateItemManufacturer);
