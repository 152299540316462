import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hospitalCode, serverLink } from "../../../../resources/url";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import DrugInventoryForm from "../../druginventory/druginventoryform/druginventoryform";
import "./updatedruginventory.css";


class UpdateDrugInventory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

             formData: {
                id: this.props.drugData._id,
                drugName: this.props.drugData.drugName,
                manufacturerName: this.props.drugData.manufacturerName,
                drugPrice: this.props.drugData.drugPrice,
                drugQuantity: this.props.drugData.drugQuantity,
                drugQuantityUsed: this.props.drugData.drugQuantityUsed,
                updatedBy: ""
            },


            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: localStorage.getItem("userID")
            
        });
    
        
    }


    
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateDrug();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

   
    async onUpdateDrug() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy
        };

        if(this.state.formData.drugName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Drug Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.drugQuantityUsed === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Quantity  Used"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.manufacturerName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select Manufacturer"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.drugPrice === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Price"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.drugQuantity === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Quantity"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.drugQuantityUsed === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Accurate Drug Quantity used"
            this.handleErrorDialog();
            return false;
        }
        if(this.state.formData.drugQuantityUsed > this.state.formData.drugQuantity) {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Drug Quantity used cant be greater than Drug Quantity"
            this.handleErrorDialog();
            return false;
        }



        axios.patch(`${this.state.serverLink}pharmacy/drug/inventory/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "DRUG UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/pharmacy/drug/inventory/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Drug Inventory Updated"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <DrugInventoryForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Drug Inventory"                                
                            ></DrugInventoryForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        drugData: state.personDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateDrugInventory);
