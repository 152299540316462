import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hospitalCode, serverLink } from "../../../../resources/url";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import AddPackageForm from "../../package/packageform/packageform";
import "./updatepackage.css";


class UpdatePackage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"hospital_package/",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.packageData._id,
                packageName:  this.props.packageData.packageName,
                oldPackageName: this.props.packageData.packageName,
                amount: this.props.packageData.amount,
                duration: this.props.packageData.duration,
                status: this.props.packageData.status,
                description: this.props.packageData.description,
                updatedBy: ""
            },

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: localStorage.getItem("userID")
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdate();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdate() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.formData.updatedBy
        };

        if(this.state.formData.packageName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Package Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.amount === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Package Amount"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.duration === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Package Duration"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.status === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Package Status"
            this.handleErrorDialog();
            return false;
        }

        axios.patch(`${this.state.serverLink}/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "PACKAGE UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/hospital/package/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Package Updated"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>


                    <div className="row">
                        <div className="col-sm">
                            <AddPackageForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Package"
                            ></AddPackageForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        packageData: state.personDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdatePackage);
