import React from "react";

const MenuIcon = ({name, icon, count}) => {
  return  <div className="box" style={{ backgroundColor: "#ffffff" }}>
      {" "}
      <div className="">
          <span style={{fontWeight: "800", color: "#5f6e77", fontSize: '12px'}}>  {name} </span>
          <h1
              style={{
                  fontWeight: "700",
                  color: "#2d3b44",
                  fontSize: "30px",
              }}
          >
              {count}
          </h1>
      </div>

      <i style={{color: "#1f2123"}} aria-hidden="true">{icon}</i>
  </div>
}
export default MenuIcon;