import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { logger } from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "./reducers/rootreducer";
import {serverStatus} from "./resources/url";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ForgottenPassword from "./components/Login/forgotten-password";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store;
if (serverStatus === 'Dev') {
    store = createStore(
        rootReducer
        , composeEnhancers(applyMiddleware(logger, thunkMiddleware))
    );
}else {
    store = createStore(
        rootReducer
        , composeEnhancers(applyMiddleware(thunkMiddleware))
    );
}


const persistor = persistStore(store);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
        <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
