import React, { useState, useEffect } from 'react';
import axios from "axios";
import {serverLink} from "../../../resources/url";
import {connect} from "react-redux";

function IcdComponent(props) {
    const icdApiUrl = "https://id.who.int/icd/entity";

    const [token, setToken] = useState('');
    const [icdData, setIcdData] = useState('');

    const fetchToken = async () => {
        await axios.get(`${serverLink}icd/fetch/token`, props.doctorDetails.token)
            .then(res => {
                console.log(res.data.access_token)
                setToken(res.data.access_token);
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        // Function to fetch OAuth2 token
        fetchToken();
    }, []);

    useEffect(() => {
        const fetchIcdData = async () => {
            try {
                console.log(token)
                const response = await fetch(icdApiUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                        'Accept-Language': 'en',
                        'API-Version': 'v2'
                    },
                });

                const data = await response.text();
                console.log(data)
                setIcdData(data);
            } catch (error) {
                console.error('Error fetching ICD data:', error);
            }
        };

        if (token) {
            fetchIcdData();
        }
    }, [token]);

    useEffect(() => {
        axios.get('https://id.who.int/icd/entity/448895267')
            .then(res => {
                console.log('DATA====')
                console.log(res)
            })
            .catch(err => {
                console.log("ERR", err)
            })
    },[token])

    return (
        <div>
            <h1>ICD Data</h1>
            <pre>{icdData}</pre>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(IcdComponent);