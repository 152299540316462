import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import "../base.css";
import Navigator from "../../../Settings/navigation/navigation";
import {hospitalCode, serverLink} from "../../../../resources/url";
import FormPrompt from "../../../DialogBoxes/formprompt";
import { toast } from "react-toastify";
import ReportTable from "../../../ReusableComponents/ReportTable";
import {formatDateAndTime2} from "../../../../resources/constants";

function InventoryLocation(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const columns = ["S/N", "Location Name", "Description", "Last Updated By", "Last Updated On", "Edit"];
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({_id: "", locationName: "", description: "", hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})
    const [oldLocation, setOldLocation] = useState("")
    const toggleModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const toggleEditModal = () => {
        setOpenModal(true)
    }

    const closeModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const resetFormData = () => {
        setOldLocation("")
        setFormData({_id: "", locationName: "", description: "", hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})
    }

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value
        });
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();

        if (formData.locationName === '') {
            toast.error("Please enter the location name")
            return false;
        }

        toast.dark("Submitting...")

        if (formData._id === "") {
            await axios.post(`${serverLink}finance/location/add`, formData, props.loginDetails.token).then(res => {
                if (res.data.message === 'success'){
                    toast.success("Location Added Successfully");
                    closeModal();
                    getData();
                }
                else if (res.data.message === 'exist')
                    toast.error("Location Already Exist")
                else
                    toast.error("Network issue. Please check your network and try again!")

            }).catch(err => {
                toast.error("Connection failure. Please check your network and try again!")
            })
        } else {
            const sendData = {
                ...formData,
                oldLocation: oldLocation
            }
            await axios.patch(`${serverLink}finance/location/update`, sendData, props.loginDetails.token).then(res => {
                if (res.data.message === 'success') {
                    toast.success("Location Updated Successfully")
                    closeModal();
                    getData();
                }
                else if (res.data.message === 'exist')
                    toast.error("Location Already Exist")
                else
                    toast.error("Network issue. Please check your network and try again!")

            }).catch(err => {
                toast.error("Connection failure. Please check your network and try again!")
            })
        }

    }

    const getData = async () => {
        //LOCATION LIST
        await axios.get(`${serverLink}finance/location/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            let rows = []
            if (res.data.length > 0) {
                res.data.map((r, index) => {
                    rows.push([(index+1), r.locationName, r.description, r.updatedBy, formatDateAndTime2(r.updatedOn),
                        <button className={"btn btn-sm btn-info"} onClick={() => handleOnEdit(r)}>Edit</button>])
                })
            }
            setData(rows)
            setIsLoading(false)
        }).catch(error => {
            console.log("ERROR FETCHING ITEMS")
        })
    }

    useEffect(() => {
        getData();
    },[])

    const handleOnEdit = (dt) => {
        setFormData({
            ...formData,
            _id: dt._id,
            locationName: dt.locationName,
            description: dt.description,
        })
        setOldLocation(dt.locationName)
        toggleEditModal();

    }


    return isLoading ? (<div className="baselistpage">
        <i className="fas fa-spinner fa-pulse fa-2x "/>
    </div>) : (
        <div className="baselistpage">
            <FormPrompt
                openDialog={openModal}
                title="Manage Location Form"
                onSetOpenDialog={closeModal}
                isCloseBtnAppear={true}
            >
                <form onSubmit={onSubmitForm}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="locationName">Enter Location Name</label>
                                <input type="text" className="form-control" id="locationName" value={formData.locationName} onChange={onEdit}/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="description">Enter Location Description</label>
                                <input type="text" className="form-control" id="description" value={formData.description} onChange={onEdit}/>
                            </div>
                        </div>


                        <button type="submit" className="btn btn-primary btn-block">Submit</button>


                    </div>
                </form>
            </FormPrompt>
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Inventory Locations" />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>
                    <button type="button" className="btn btn-warning" onClick={toggleModal}>  + Add Location </button>
                </div>
                <ReportTable columns={columns} data={data} title={"Inventory Location List"} height={"600px"} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(InventoryLocation);
