import React, { Component } from "react";
import "./specialisationform.css";
import Navigator from "../../Settings/navigation/navigation";

class SpecialisationForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="specialisationName">Specialisation Name <span className="text-danger">*</span></label>
                                            <input
                                                name="specialisationName"
                                                type="text"
                                                className="form-control"
                                                id="specialisationName"
                                                value={this.props.data.specialisationName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="consultationFee">Consultation Fee <span className="text-danger">*</span></label>
                                            <input
                                                name="consultationFee"
                                                type="number"
                                                className="form-control"
                                                id="consultationFee"
                                                value={this.props.data.consultationFee}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="specialisationDescription">Specialisation Description </label>
                                            <textarea
                                                name="specialisationDescription"
                                                className="form-control"
                                                id="specialisationDescription"
                                                defaultValue={this.props.data.specialisationDescription}
                                                onChange={this.props.onEdit}
                                                rows="3"
                                            ></textarea>
                                        </div>
                                    </div>

                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpecialisationForm;
