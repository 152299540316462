import React, { Component } from "react";
import "./subscribepatient.css";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import {serverLink, hospitalCode} from "../../../../resources/url";
import PatientSearchList from "../../utils/patientlist";
import FormPrompt from "../../../DialogBoxes/formprompt";
import PlanSubscription from "../plansubscription/plansubscription"
import Navigator from "../../../Settings/navigation/navigation";

class FindPatientsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"patient/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: false,
            limit: 50,

            isLoadMoredata: false,

            totalNumOfPrescription: null,
            noMoreDataText: "",

            openErrorDialog: false,
            openAddPlanDialog: false,
            isCloseDialogAppear: true,

            patientList: [],
            selectedPatient: "",
            isSearching: false,
            isSearchDataShow: false,

            selectedPatientName: "",
            selectedPatientId: "",
            patientName: "",
            patientSerial: "",
        };
    }

    showMorePatient = () => {
        if (this.state.limit <= this.state.totalNumOfPrescription) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
        } else {
            this.setState({
                noMoreDataText: "No More Patient",
            });
        }
    };

    handleAddPlanActionClicked = (p) => {
        this.state.patientSerial = p.patientSerial
        this.state.patientName = `${p.firstName} ${p.middleName} ${p.surname}`
        this.setState({
            openAddPlanDialog: true,
        });
    }

    closeAddPlanDialog = () => {
        this.setState({
            selectedPatient: "",
            openAddPlanDialog: false,
        });
    };

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");

             await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            patientList: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };


    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="searchPatientPage">
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            </div>
        ) : (
            <div className="searchPatientPage">
                <div className="main_section">
                    <FormPrompt
                        openDialog={this.state.openAddPlanDialog}
                        title="Plan Subscription"
                        onSetOpenDialog={this.closeAddPlanDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <PlanSubscription
                            setCloseBtnAppear={this.setCloseBtnAppear}
                            handleSuccessDialog={this.handleSuccessDialog}
                            handleErrorDialog={this.handleErrorDialog}
                            patientData={this.state.selectedPatient}
                            patientSerial={this.state.patientSerial}
                            patientName={this.state.patientName}
                        ></PlanSubscription>
                    </FormPrompt>

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Patient record not found. Please try again!"
                    ></ErrorDialogBox>

                    <div className="topheader">
                        <Navigator title="Search Patient" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search patient by patient name"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.patientList.length}{" "}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <PatientSearchList isSearching={this.state.isSearching} patientList={this.state.patientList} setOnPatientDetails={this.props.setOnPatientDetails} onChange={this.handleAddPlanActionClicked} />


                    <div className="loadmoredatasection">
                        {this.state.isLoadMoredata ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.patientList.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMorePatient()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));

        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FindPatientsList);
