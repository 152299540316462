import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hospitalCode, serverLink } from "../../../../resources/url";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import DrugSubCategoryForm from "../drugsubcategoryform/drugsubcategoryform";
import "./updatedrugsubcategory.css";


class UpdateDrugSubCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"pharmacy/",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.drugCategoryData._id,
                categoryName:  this.props.drugCategoryData.categoryName,
                subCategoryName: this.props.drugCategoryData.subCategoryName,
                oldSubCategoryName: this.props.drugCategoryData.subCategoryName,
                description: this.props.drugCategoryData.description,
                hospitalCode: hospitalCode,
                updatedBy: ""
            },


            messageTitle: "",
            messageDescription: "",
            drugCategoryList: [],

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: this.props.loginDetails.fullName
        });
        this.fetchDrugCategory()
    }

    async fetchDrugCategory() {
        await fetch(`${this.state.serverLink}drug/category/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugCategoryList: data,
                });
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdate();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdate() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.formData.updatedBy
        };

        if(this.state.formData.subCategoryName.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Drug Category Name"
            this.handleErrorDialog();
            return false;
        }



        axios.patch(`${this.state.serverLink}drug/category/sub/update`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "DRUG CATEGORY UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/pharmacy/drug/subcategory/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Drug Sub Category Updated"
                        des={this.state.messageDescription}
                    />

    
                    <div className="row">
                        <div className="col-sm">
                            <DrugSubCategoryForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Drug Sub-Category"
                                drugCategoryList = {this.state.drugCategoryList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        drugCategoryData: state.personDetails,
        loginDetails: state.loginDetails
    };
};
export default connect(mapStateToProps, null)(UpdateDrugSubCategory);
