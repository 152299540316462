import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { setpersonDetails } from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import {connect} from "react-redux";
import { serverLink, hospitalCode } from "../../../../resources/url";
import UpdateProfileForm from "../user-profile-form/user-profile-form";
import {toast} from "react-toastify";

class UpdateProfile extends Component {
    constructor(props) {
        super(props);

        let specialisation = [];
        this.props.userDetails.specialisation.map((spec) => {
            specialisation.push({value: spec, label: spec})
        });

        this.state = {
            serverLink: serverLink+"user/",
            hospitalCode: hospitalCode,
            date: null,
            isLoading: false,
            imagefile: "",
            htmlelement: `${serverLink}public/uploads/users_uploads/${this.props.userDetails.passport}`,

            userEntryID: this.props.userDetails._id,
            formData: {
                id: this.props.userDetails._id,
                userID: this.props.userDetails.userID,
                passport: this.props.userDetails.passport,
                image_name: this.props.userDetails.passport,
                linkedin: this.props.userDetails.linkedin,
                twitter: this.props.userDetails.twitter,
                googleScholar: this.props.userDetails.googleScholar,
                academia: this.props.userDetails.academia,
                researchGate: this.props.userDetails.researchGate,
                experience: this.props.userDetails.experience,
                aboutDoctor: this.props.userDetails.aboutDoctor,
            },


            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateUser();
    };



    async onUpdateUser() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            id: this.state.userEntryID
        };


        axios.patch(`${this.state.serverLink}profile/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', this.state.formData.id)
                        axios.patch(`${this.state.serverLink}profile/upload`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.state.messageDescription = "Profile updated successfully";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = response;
                    this.handleErrorDialog();
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = err;
                this.handleErrorDialog();
            });
    }

    descriptionHandler = (event) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                aboutDoctor: event
            },
        });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    onImageChange = (event) => {
        const formData = this.state.formData;
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 2000000) {
                toast.error("max file size is 2mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: file,
                    htmlelement: e.target.result,
                    formData: {
                        ...formData,
                        image_name: file.name,
                        view: file.mozFullPath,

                    },
                });
            };
        }
    };


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/website/profile';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="addpersonpage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="addpersonpage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Profile Updated"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <UpdateProfileForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Profile"
                                descriptionHandler={this.descriptionHandler}
                                onImageChange={this.onImageChange}
                                image={this.state.htmlelement}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.personDetails,
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnpersonDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDisptachToProps)(UpdateProfile);
