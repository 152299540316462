import React, { Component } from "react";
import "./slider-form.css";
import Navigator from "../../../Settings/navigation/navigation";

class SliderForm extends Component {
    render() {

        const editorConfig = {
            readonly: false,
            height: 200
        };

        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6  mb-3">
                                            <label htmlFor="title"> Title <span className="text-danger">*</span></label>
                                            <input
                                                name="title"
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                value={this.props.data.title}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >
                                        <div className="col-md-6  mb-3">
                                            <label htmlFor="caption"> Caption </label>
                                            <input
                                                name="caption"
                                                type="text"
                                                className="form-control"
                                                id="caption"
                                                value={this.props.data.caption}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >
                                        <div className="col-md-6  mb-3">
                                            <label htmlFor="btnName"> Button Name </label>
                                            <input
                                                name="btnName"
                                                type="text"
                                                className="form-control"
                                                id="btnName"
                                                value={this.props.data.btnName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >
                                        <div className="col-md-6  mb-3">
                                            <label htmlFor="btnLink"> Button Link </label>
                                            <input
                                                name="btnLink"
                                                type="text"
                                                className="form-control"
                                                id="btnLink"
                                                value={this.props.data.btnLink}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="imagePosition">Image Position</label>
                                            <select
                                                name="imagePosition"
                                                id="imagePosition"
                                                className="custom-select"
                                                value={this.props.data.imagePosition}
                                                onChange={this.props.onEdit}>
                                                <option value="">Select Image Position</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6   mb-3" >
                                            <label className="required fs-5 fw-bold mb-2">Image</label>
                                            <div className="mb-3">
                                                <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                                    <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                                        document.getElementById("photo").click()
                                                    }}>
                                                        <div className="dz-message needsclick">
                                                            <i className="bi bi-image text-primary fs-3x"/>
                                                            <div className="ms-4 col-md-9">
                                                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                                <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                            </div>
                                                            <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={150} height={150} srcSet={this.props.image !== "" ? this.props.image : ""}/> : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="alert-danger"> Max of 2MB file is allowed!</span>
                                            </div>
                                            <input type="file"  id="photo" name={"photo"}  accept={"image/*"} onChange={this.props.onImageChange} hidden />
                                        </div>
                                    </div>


                                    <div className="col-md-6 offset-sm-3 mb-3">
                                        <button className="btn btn-success btn-block update_btn" type="submit">
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SliderForm;
