import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./appointmentcheckin.css";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";

class AppointmentCheckIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,
            patientSerial: this.props.patientData.patientSerial,
            patientFullName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                this.props.patientData.surname,

            formData: {
                patientSerial: this.props.patientData.patientSerial,
                patientName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+            
                    this.props.patientData.surname,
                appointmentStatus: "Checked In",
                checkInTime: "",
                checkInBy: "",
                _id: ""
            },
        };
    }

    componentDidMount() {
        this.state.formData.checkInBy = localStorage.getItem('userID');
    }

    async appointmentCheckIn(e) {
        const today = new Date()
        let sendData = {
            ...this.state.formData,
            checkInTime: today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
            _id: e._id
        };


        axios.patch(`${this.state.serverLink}appointment/check_in`, sendData, this.props.doctorDetails.token)
            .then()
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "success") {
                    window.location.reload();
                } else {
                    console.log(result.data)
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                this.props.handleErrorDialog('ERROR', err);
            });
    }


    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()}
                    
                    `
        } else {
            return '--'
        }
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="patientSerial">Patient ID</label>
                                    <input
                                        name="patientSerial"
                                        type="text"
                                        className="form-control"
                                        id="patientSerial"
                                        disabled
                                        value={this.state.patientSerial}
                                        onChange={this.onEdit}
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="patientSerial">Patient Name</label>
                                    <input
                                        name="patientSerial"
                                        type="text"
                                        className="form-control"
                                        id="patientSerial"
                                        disabled
                                        value={this.state.patientFullName}
                                        onChange={this.onEdit}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    {this.props.patientAppointment.length === 0 ? <div className="alert alert-danger">NO APPOINTMENT FOUND FOR THE SELECTED PATIENT</div> :     
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>Department</th>
                                                <th>Doctor</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>--</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.props.patientAppointment.map((appt) => {
                                                if(appt.appointmentStatus === 'Scheduled') {
                                                    const appointment_date = new Date(appt.appointmentDate);
                                                    const current_date = new Date();
                                                    let action = "";
                                                    if(appointment_date.getDate() === current_date.getDate() &&
                                                        appointment_date.getMonth() === current_date.getMonth() &&
                                                        appointment_date.getFullYear() === current_date.getFullYear())
                                                        action = <button className="btn btn-success btn-sm" onClick={() => {this.appointmentCheckIn(appt)}}>Check in</button>


                                                    return <tr key={appt._id}>
                                                        <td>{appt.appointmentDepartment}</td>
                                                        <td>{appt.doctorName}</td>
                                                        <td>{this.formatDate(appt.appointmentDate)}</td>
                                                        <td>{appt.appointmentTime}</td>
                                                        <td>{action}</td>
                                                    </tr>
                                                }
                                            })}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AppointmentCheckIn);
