import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {
    BrowserRouter as Routers, Link, Route, Routes, useNavigate
} from "react-router-dom";
// import { useIdleTimer } from 'react-idle-timer'
import {setLogInDetails, setPermissionDetails} from "../actions/setpersondetailsaction";
import AddAppointment from "./Appointment/addappointment/addappointment";
import FindAppointment from "./Appointment/findappointment/findappointment";
import UpdateAppointment from "./Appointment/updateappointment/updateappointment";
import Dashboard from "./Dashboard/dashboard";
import AddDepartment from "./Department/adddepartment/adddepartment";
//DEPARTMENT IMPORT
import DepartmentList from "./Department/departmentlist/departmentlist";
import UpdateDepartment from "./Department/updatedepartment/updatedepartment";
import Consultation from "./Doctors/consultation/consultation";
import ConsultationPage from "./Doctors/consultationpage/consultationpage";
import DoctorDashboard from "./Doctors/dashboard/doctordashboard";
//DOCTOR MODULES IMPORTS
import DoctorList from "./Doctors/list/doctorlist";
import DoctorProfile from "./Doctors/profile/doctorprofile";
//HOSPITAL MODULES IMPORT
import AddHospital from "./Hospital/addhospital/addhospital";
import HospitalList from "./Hospital/hospitallist/hospitallist";
import FindPatientsList from "./Hospital/insurance/addpatient/subscribepatient";

//INSURANCE MODULE IMPORT
import InsuranceDashboard from './Hospital/insurance/dashboard/dashboard';
import AddInsuranceCompany from "./Hospital/insurance/insurancecompany/addinsurancecompany/addinsurancecompany";
import InsuranceCompanyList from "./Hospital/insurance/insurancecompany/insurancecompanylist/insurancecompanylist";
import UpdateInsuranceCompany
    from "./Hospital/insurance/insurancecompany/updateinsurancecompany/updateinsurancecompany";
import AddPlan from "./Hospital/insurance/plans/addplan/addplan";
import PlansList from './Hospital/insurance/plans/planlist/planlist';
import UpdatePlan from "./Hospital/insurance/plans/updateplan/updateplan";
import ClaimsList from "./Hospital/insurance/claims/claimlist";
//PACKAGE MODULE IMPORT
import PackageDashboard from "./Hospital/package/packagedashboard/packagedashboard";
import PackageSubscribers from "./Hospital/package/packagesubscribers/packagesubscribers";
import AddPackage from "./Hospital/package/addpackage/addpackage";
import PackagesList from "./Hospital/package/listpackage/listpackage";
import UpdatePackage from "./Hospital/package/updatepackage/updatepackage";
//HOSPITAL ROOM MODULES IMPORT
//HOSPITAL ROOM TYPE MODULES IMPORT
import UpdateHospital from "./Hospital/updatehospital/updatehospital";
import AddLab from "./Laboratory/lab/addlab/addlab";
//LABORATORY MODULE IMPORT
import LabList from "./Laboratory/lab/lablist/lablist";
import UpdateLab from "./Laboratory/lab/updatelab/updatelab";
//LABORATORY IMPORT
import LabDashboard from "./Laboratory/labdashboard/labdashboard";
import SearchPatient from "./Laboratory/labrequest/labrequest";
import LabRequestProcessingPage from "./Laboratory/labrequestprocessing/processingpage/processingpage";
import AddLabSpecimen from "./Laboratory/labspecimen/addspecimen/addspecimen";
import SpecimenList from "./Laboratory/labspecimen/specimenlist/specimenlist";
import UpdateSpecimen from "./Laboratory/labspecimen/updatespecimen/updatespecimen";
import AddLabTest from "./Laboratory/labtest/addtest/addtest";
import LabTestList from "./Laboratory/labtest/testlist/testlist";
import UpdateLabTest from "./Laboratory/labtest/updatetest/updatetest";
import PatientSpecimen from "./Laboratory/patientspecimen/patientspecimen";
//NURSE MODULE IMPORT
import NurseDashboard from "./Nurses/dashboard/nursedashboard";
import AllRooms from "./Nurses/inpatient/allrooms/allrooms";
import AvailableRooms from "./Nurses/inpatient/availablerooms/availablerooms";
//INPATIENT IMPORT
import InpatientDashboard from "./Nurses/inpatient/inpatientdashboard/inpatientdashboard";
import OccupiedRooms from "./Nurses/inpatient/occupiedrooms/occupiedrooms";
import RoomsUnderMaintenance from "./Nurses/inpatient/roomsundermaintenance/roomsundermaintenance";
import AddPatientDependant from "./Patients/addpatientdependant/addpatientdependant";
//PATIENT MODULE IMPORT
import FindPatient from "./Patients/findpatient/findpatient";
import PatientList from "./Patients/patientlist/patientlist";
import PatientProfile from "./Patients/patientprofile/patientprofile";
import RegisterPatient from "./Patients/registerpatient/registerpatient";
import UpdatePatient from "./Patients/updatepatient/updatepatient";
import PharmacyDashboard from "./Pharmacy/dashboard/dashboard";
import AddDrug from "./Pharmacy/drug/adddrug/adddrug";
//PHARMACY DRUG MODULE IMPORT
import DrugList from "./Pharmacy/drug/druglist/druglist";
import UpdateDrug from "./Pharmacy/drug/updatedrug/updatedrug";
import AddDrugCategory from "./Pharmacy/drugcategory/adddrugcategory/adddrugcategory";
//PHARMACY MODULE IMPORT
import DrugCategoryList from './Pharmacy/drugcategory/drugcategorylist/categorylist';
import UpdateDrugCategory from "./Pharmacy/drugcategory/updatedrugcategory/updatedrugcategory";
import AddDrugInventory from "./Pharmacy/druginventory/adddruginventory/adddruginventory";
import DrugInventoryList from "./Pharmacy/druginventory/druginventorylist/inventorylist";
import UpdateDrugInventory from "./Pharmacy/druginventory/updatedruginventory/updatedruginventory";
import AddDrugSubCategory from "./Pharmacy/drugsubcategory/adddrugsubcategory/adddrugsubcategory";
import DrugSubCategoryList from "./Pharmacy/drugsubcategory/drugsubcategorylist/categorylist";
import UpdateDrugSubCategory from "./Pharmacy/drugsubcategory/updatedrugsubcategory/updatedrugsubcategory";
import AddSubSubDrugCategory from "./Pharmacy/drugsubsubcategory/adddrugsubsubcategory/adddrugsubsubcategory";
import DrugSubSubCategoryList from "./Pharmacy/drugsubsubcategory/drugsubsubcategorylist/categorylist";
import UpdateDrugSubSubCategory from "./Pharmacy/drugsubsubcategory/updatesubsubdrugcategory/updatedrugsubsubcategory";
import AddManufacturer from "./Pharmacy/manufacturer/addmanufacturer/addmanufacturer";
//MANUFACTURER MODULE IMPORT
import ManufacturerList from './Pharmacy/manufacturer/manufacturerlist/manufacturerlist';
import UpdateManufacturer from "./Pharmacy/manufacturer/updatemanufacturer/updatemanufacturer";
import FindPatientPrescription from "./Pharmacy/prescription/prescriptionlist/prescription";
import PrescriptionDetails from "./Pharmacy/prescription/prescriptionpage/prescriptionpage";
import DoctorConsultationProfile from "./Schedule/consultation/consultationprofile/consultationprofile";
//SCHEDULER IMPORT
import ScheduleDoctorList from "./Schedule/consultation/doctorlist/doctorlist";
import "./sliderNavBar.css";
import AddSpecialisation from "./Specialisation/addspecialisation/addspecialisation";
//SPECIALISATION IMPORT
import SpecialisationList from "./Specialisation/specialisationlist/specialisationlist";
import UpdateSpecialisation from "./Specialisation/updatespecialisation/updatespecialisation";
import AddSystemUser from "./SystemUsers/adduser/adduser";
//SYSTEM USERS IMPORT
import FindUser from "./SystemUsers/finduser/finduser";
import UpdateSystemUser from "./SystemUsers/updateuser/updateuser";
import SystemUserList from "./SystemUsers/userlist/userlist";
import SearchLabRequest from "./Laboratory/searchrequest/searchrequest";
import LabTestResultTemplate from "./Laboratory/labtest/testtemplate/testtemplate";
import AdmissionDashboard from "./Admission/admissiondashboard/admissiondashboard";
import FindPatients from "./Admission/findpatient/findpatient";
import AdmissionPage from "./Admission/admissionpage/admissionpage";
import RoomStatus from "./Admission/roomstatus/roomstatus";
import AdmitPatient from "./Admission/admit/admit";
import AdmissionProfile from "./Admission/admissionprofile/admissionprofile";
import InPatient from "./Admission/inpatient/inpatient";
import SettingsDashboard from "./Settings/settingsdashboard/settingsdashboard";
import PatientDashboard from "./Patients/dashboard/patientdashboard";
import NurseCheckin from "./Nurses/checkin/nursecheckin";
import NurseCaptureVitals from "./Nurses/capturevitals/nursecapturevitals";
import ConsultationHistory from "./Doctors/consultationhistory/consultationhistory";
import PermissionDashboard from "./Settings/permissiondashboard/permissiondashboard";
import UserDashboard from "./Settings/userdashboard/userdashboard";
import AddMenu from "./Settings/permissiondashboard/menu/addmenu/addmenu";
import MenuList from "./Settings/permissiondashboard/menu/menulist/menulist";
import UpdateMainMenu from "./Settings/permissiondashboard/menu/updatemenu/updatemenu";
import SubMenuList from "./Settings/permissiondashboard/submenu/submenulist/submenulist";
import AddSubMenu from "./Settings/permissiondashboard/submenu/addsubmenu/addsubmenu";
import UpdateSubMenu from "./Settings/permissiondashboard/submenu/updatesubmenu/updatesubmenu";
import GroupList from "./Settings/permissiondashboard/group/list/grouplist";
import GroupMembers from "./Settings/permissiondashboard/group/groupmembers/groupmembers";
import GroupPermission from "./Settings/permissiondashboard/group/grouppermission/grouppermission";
import BuildingDashboard from "./Settings/buildingdashboard/buildingdashboard";
import BedList from "./Settings/buildingdashboard/bed/bedlist/bedlist";
import AddBed from "./Settings/buildingdashboard/bed/addbed/addbed";
import UpdateBed from "./Settings/buildingdashboard/bed/updatebed/updatebed";
import BlockList from "./Settings/buildingdashboard/block/list/blocklist";
import AddBlock from "./Settings/buildingdashboard/block/add/addblock";
import UpdateBlock from "./Settings/buildingdashboard/block/update/updateblock";
import RoomTypesList from "./Settings/buildingdashboard/roomtypes/list/roomtypeslist";
import AddRoomTypes from "./Settings/buildingdashboard/roomtypes/add/addroomtypes";
import UpdateRoomTypes from "./Settings/buildingdashboard/roomtypes/update/updateroomtypes";
import RoomList from "./Settings/buildingdashboard/room/list/roomlist";
import AddRoom from "./Settings/buildingdashboard/room/add/addroom";
import UpdateRoom from "./Settings/buildingdashboard/room/update/updateroom";
import ImagingDashboard from "./Imaging/dashboard/dashboard";
import LabRequestHistory from "./Laboratory/labrequesthistory/labrequesthistory";
import ExpiredDrug from "./Pharmacy/expireddrug/expireddrugs";
import ImagingList from "./Imaging/imagingtype/imaginglist";
import AddImaging from "./Imaging/imagingtype/addimaging/addimaging";
import ImagingLabList from "./Imaging/imaginglab/imaginglablist/imaginglablist";
import AddImagingLab from "./Imaging/imaginglab/addimaginglab/addimaginglab";
import ImagingProfile from "./Imaging/imagingprofile/imagingprofile";
import ImagingHistory from "./Imaging/imaginghistory/imaginghistory";
import ImagingRequest from "./Imaging/imagingrequest/imagingrequest";
import UpdateImaging from "./Imaging/imagingtype/updateimaging/updateimaging";
import UpdateImagingLab from "./Imaging/imaginglab/updatelabimaging/updatelabimaging";
import PatientPrescriptionHistory from "./Pharmacy/prescription/prescription_history/prescription_history";
import ChangePassword from "./SystemUsers/changepassword/changepassword";
import ServicesDashboard from "./Settings/services/dashboard/servicesdashboard";
import ServicePage from "./Settings/services/servicepage/servicepage";
import ServicePermission from "./Settings/services/permission/servicespermission";
import ServiceUserPage from "./Services/serviceuserpage/serviceuserpage";
import ServiceProfilePage from "./Services/serviceuserpage/serviceprofilepage";
import ServiceRequestPending from "./Services/servicerequestpage/servicerequestpending";
import ServiceRequestHistory from "./Services/servicerequestpage/servicerequesthistory";
import PatientCreditTopUp from "./Patients/rechargewallet/rechargewallet";
import AddDependant from "./Patients/addpatientdependant/addpatientdependant/addpatientdependant";
import ForgottenPassword from "./Login/forgotten-password";
import LoginPage from "./Login/login";
import WebsiteDashboard from "./Website/dashboard/website-dashboard";
import NewsList from "./Website/news/news-list/news-list";
import AddNews from "./Website/news/add-news/add-news";
import UpdateNews from "./Website/news/update-news/update-news";
import SliderList from "./Website/slider/slider-list/slider-list";
import AddSlider from "./Website/slider/add-slider/add-slider";
import UpdateSlider from "./Website/slider/update-slider/update-slider";
import Profile from "./Website/profile/profile";
import UpdateProfile from "./Website/profile/update-profile/update-profile";
import FinanceDashboard from "./Finance/dashboard/finance-dashboard";
import InventoryDashboard from "./Finance/inventory/dashboard/inventory-dashboard";
import Manufacturer from "./Finance/inventory/manufacturer/manufacturer";
import AddItemManufacturer from "./Finance/inventory/manufacturer/add-manufacturer/add-manufacturer";
import UpdateItemManufacturer from "./Finance/inventory/manufacturer/update-manufacturer/update-manufacturer";
import UpdateItemVendor from "./Finance/inventory/vendor/update-vendor/update-vendor";
import AddItemVendor from "./Finance/inventory/vendor/add-vendor/add-vendor";
import Vendor from "./Finance/inventory/vendor/vendor-list/vendor-list";
import UpdateItemCategory from "./Finance/inventory/item-category/update-item-category/update-item-category";
import AddItemCategory from "./Finance/inventory/item-category/add-item-category/add-item-category";
import ItemVendorForm from "./Finance/inventory/vendor/vendor-form/vendor-form";
import ItemCategoryList from "./Finance/inventory/item-category/item-category-list/item-category-list";
import ItemSubCategoryList from "./Finance/inventory/item-subcategory/item-sub-category-list/item-sub-category-list";
import AddItemSubCategory from "./Finance/inventory/item-subcategory/add-item-sub-category/add-item-sub-category";
import UpdateItemSubCategory
    from "./Finance/inventory/item-subcategory/update-item-sub-category/update-item-sub-category";
import VendorItemList from "./Finance/inventory/item/item-list/vendor-item-list";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InventoryLocation from "./Finance/inventory/inventory-location/inventory-location";
import InventoryAllocation from "./Finance/inventory/inventory/inventory-allocation";
import ItemDetails from "./Finance/inventory/item-allocation/item-allocation";
import LabRequestProcessingPageHod from "./Laboratory/labrequestprocessing/processingpage/processingpage-hod";
import ServiceDeskDashboard from "./ServiceDesk/dashboard/service-desk-dashboard";
import Gallery from "./Website/gallery/gallery";
import GalleryCategory from "./Website/gallery/gallery-category";
import GallerySubCategory from "./Website/gallery/gallery-sub-category";
import FrontDeskPatientProfile from "./ServiceDesk/patient/patient-profile";
import DoctorOutpatient from "./Doctors/outpatient-care/outpatient-care";
import ServiceDeskOutpatient from "./ServiceDesk/outpatient-care/outpatient-care";
import NurseOutpatient from "./Nurses/outpatient-care/outpatient-care";
import AccidentAndEmergency from "./Nurses/accident-and-emergency/accident-and-emergency";
import AddLabBottle from "./Laboratory/labbottle/addbottle/addbottle";
import UpdateBottle from "./Laboratory/labbottle/updatebottle/updatebottle";
import BottleList from "./Laboratory/labbottle/bottlelist/bottlelist";
import AddLabBottleVolume from "./Laboratory/labbottlevolume/addbottlevolume/addvolume";
import UpdateVolume from "./Laboratory/labbottlevolume/updatebottlevolume/updatevolume";
import VolumeList from "./Laboratory/labbottlevolume/bottlevolumelist/volumelist";
import PhysiotherapyDashboard from "./Physiotherapy/dashboard/physiotherapy-dashboard";
import PhysiotherapyOutpatient from "./Physiotherapy/outpatient-care/outpatient-care";
import DoctorsDashboard from "./Dashboard/doctor-dashboard";
import NursesDashboard from "./Dashboard/nurse-dashboard";
import PackageSubscription from "./Hospital/package/subscribepatient/subscribepatient";

function SideNavBar (props) {
    const SESSION_IDLE_MINUTES = 15;
    // const handleOnIdle = (event) => {
    //     console.log('user is idle', event)
    //     console.log('last active', getLastActiveTime())
    //     logout();
    // }
    //
    // const {getLastActiveTime } = useIdleTimer({
    //     timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
    //     onIdle: handleOnIdle,
    //     debounce: 500,
    // })

    const [addHamburgerClass, setAddHamburgerClass] = useState(false);
    const [selectedCat, setSelectedCat] = useState("");


    const toggleHamburger = () => {
        setAddHamburgerClass(!addHamburgerClass)
    };

    const logout = () => {
        const loginDetails = {
            isLoggedIn: false,
            firstName: "",
            middleName: "",
            surname: "",
            fullName: "",
            role: "",
            userID: ""
        };
        localStorage.removeItem('userID')
        localStorage.removeItem('hospitalCode')
        props.setOnLogInDetails(loginDetails);
        props.setOnPermissionDetails("");
        window.location = "/"
    };

    const setTitleActive = (selectedCat) => {
        setSelectedCat(selectedCat)
    };

    let boxClass = ["wrapper"];

    return (
        <div className={boxClass.join(" ")}>
            <Routers>
                <div className="top_navbar">
                    <div className="hamburger" onClick={toggleHamburger}>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                    <div className="top_menu">
                        <div className="logo">{
                            props.isHospitalParent === false ? props.hospitalName : 'Hospital Management'
                        }</div>
                        <ul>
                            <li onClick={logout}>
                                {" "}
                                <i className="fa fa-sign-out" aria-hidden="true"/>
                            </li>
                            {/*<li>*/}
                            {/*    <i className="fas fa-search"/>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <i className="fas fa-bell"/>*/}
                            {/*</li>*/}
                            <li>
                                <Link to={'/website/profile'}>
                                    <i className="fas fa-user"/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="sidebar">
                    <div className="noSubCat">
                        <ul>
                            <Link to="/">
                                <li
                                    className={
                                        selectedCat === "Dashboard" ? "active" : ""
                                    }
                                    onClick={() => setTitleActive("Dashboard")}
                                >
                                        <span className="icon">
                                          <i className="fa fa-home" aria-hidden="true"/>
                                        </span>
                                    <span className="title">Dashboard</span>
                                </li>
                            </Link>

                            {
                                props.permissionData && props.permissionData.map(perm => {
                                    if (perm.subMenuName === null) {
                                        return <Link key={perm._id} to={perm.mainMenuLink}>
                                            <li
                                                className={
                                                    selectedCat === perm.mainMenuName ? "active" : ""
                                                }
                                                onClick={() => setTitleActive(perm.mainMenuName)}
                                            >
                                                            <span className="icon">
                                                              <i className={`fa ${perm.mainMenuIcon}`} aria-hidden="true"/>
                                                            </span>
                                                <span className="title">{perm.mainMenuName}</span>
                                            </li>
                                        </Link>
                                    }
                                })
                            }
                            <Link to="/user/password/update">
                                <li
                                    className={
                                        selectedCat === "Password" ? "active" : ""
                                    }
                                    onClick={() => setTitleActive("Password")}
                                >
                                        <span className="icon">
                                          <i className="fa fa-lock" aria-hidden="true"/>
                                        </span>
                                    <span className="title">Change Password</span>
                                </li>
                            </Link>
                        </ul>
                    </div>
                </div>

                <div className="main_container">
                    <Routes>
                        <Route exact path="/" element={<Dashboard />} />
                        {/*PATIENT ROUTES*/}
                        <Route path="/patient/dashboard" element={<PatientDashboard />} />
                        <Route path="/patient/find"  element={<FindPatient />} />
                        <Route path="/patient/register" element={<RegisterPatient />} />
                        <Route path="/patient/update" element={<UpdatePatient />} />
                        <Route path="/patient/list" element={<PatientList isFront={false} />} />
                        <Route path="/patient/dependant/add" element={<AddPatientDependant />}  />
                        <Route path="/patient/add-dependant" element={<AddDependant />}  />
                        <Route path="/patient/profile" element={<PatientProfile />} />
                        <Route path="/patient/credit/recharge" element={<PatientCreditTopUp />} />

                        {/*NURSES ROUTES*/}
                        <Route path="/nurse/dashboard" element={<NurseDashboard />} />
                        <Route path="/nurse/checkin" element={<NurseCheckin />} />
                        <Route path="/nurse/capturevitals" element={<NurseCaptureVitals />} />
                        <Route path="/nurse/outpatient" element={<NurseOutpatient/>} />
                        <Route path="/nurse/consultations/:id" element={<ConsultationPage userType={"nurse"} />} />
                        <Route path="/nurse/accident-and-emergency" element={<AccidentAndEmergency/>} />


                        {/*SYSTEM USER ROUTES*/}
                        <Route path="/staff/find" element={<FindUser />} />
                        <Route path="/staff/register" element={<AddSystemUser />} />
                        <Route path="/staff/list" element={<SystemUserList />} />
                        <Route path="/staff/update" element={<UpdateSystemUser />} />

                        {/*DEPARTMENT ROUTES*/}
                        <Route path="/department/list" element={<DepartmentList />} />
                        <Route path="/department/add" element={<AddDepartment />} />
                        <Route path="/department/update" element={<UpdateDepartment />} />

                        {/*SPECIALISATION ROUTES*/}
                        <Route path="/specialisation/list" element={<SpecialisationList />} />
                        <Route path="/specialisation/add" element={<AddSpecialisation />} />
                        <Route path="/specialisation/update" element={<UpdateSpecialisation />} />

                        {/*APPOINTMENT ROUTES*/}
                        <Route path="/appointment/update" element={<UpdateAppointment />} />
                        <Route path="/appointment/list" element={<AddAppointment />} />
                        <Route path="/appointment/find" element={<FindAppointment />} />

                        {/*HOSPITAL ROUTES*/}

                        {/* Settings */}
                        <Route path="/settings/dashboard" element={<SettingsDashboard />} />
                        <Route path="/settings/menu/list" element={<MenuList />} />
                        <Route path="/settings/menu/add" element={<AddMenu />} />
                        <Route path="/settings/menu/update" element={<UpdateMainMenu />} />
                        <Route path="/settings/submenu/list" element={<SubMenuList />} />
                        <Route path="/settings/submenu/add" element={<AddSubMenu />} />
                        <Route path="/settings/submenu/update" element={<UpdateSubMenu />} />
                        <Route path="/settings/group/list" element={<GroupList />} />
                        <Route path="/settings/group/members" element={<GroupMembers />} />
                        <Route path="/settings/group/permission" element={<GroupPermission />} />
                        <Route path="/settings/permission/dashboard" element={<PermissionDashboard />} />
                        <Route path="/settings/building/dashboard" element={<BuildingDashboard />} />
                        <Route path="/settings/user/dashboard" element={<UserDashboard />} />
                        <Route path="/settings/user/system/users" element={<SystemUserList />} />
                        <Route path="/settings/user/system/add" element={<AddSystemUser />} />
                        <Route path="/settings/user/system/find" element={<FindUser />} />
                        <Route path="/hospital/register" element={<AddHospital />} />
                        <Route path="/hospital/list" element={<HospitalList />} />
                        <Route path="/hospital/update" element={<UpdateHospital />} />

                        {/* HOSPITAL BLOCK */}
                        <Route path="/hospital/block/list" element={<BlockList />} />
                        <Route path="/hospital/block/add" element={<AddBlock />} />
                        <Route path="/hospital/block/list" element={<BlockList />} />
                        <Route path="/hospital/block/update" element={<UpdateBlock />} />


                        {/* ROOM TYPE */}
                        <Route path="/hospital/room/type/list" element={<RoomTypesList />} />
                        <Route path="/hospital/room/type/add" element={<AddRoomTypes />} />
                        <Route path="/hospital/room/type/list" element={<RoomTypesList />} />
                        <Route path="/hospital/room/type/update" element={<UpdateRoomTypes />} />

                        {/* ROOM */}
                        <Route path="/hospital/room/list" element={<RoomList />} />
                        <Route path="/hospital/room/add" element={<AddRoom />} />
                        <Route path="/hospital/room/update" element={<UpdateRoom />} />

                        {/* BED */}
                        <Route path="/hospital/room/bed/list/:id" element={<BedList />} />
                        <Route path="/hospital/room/bed/add" element={<AddBed />} />
                        <Route path="/hospital/room/bed/update" element={<UpdateBed />} />

                        {/*INSURANCE ROUTES*/}
                        <Route path="/settings/insurance/dashboard" element={<h1 className="text-center">UNDER CONSTRUCTION</h1>} />
                        <Route path="/hospital/insurance/plans/list" element={<PlansList />} />
                        <Route path="/hospital/insurance/plans/add" element={<AddPlan />} />
                        <Route path="/hospital/insurance/plans/update" element={<UpdatePlan />} />
                        <Route path="/hospital/insurance/addpatients/list" element={<FindPatientsList />} />
                        <Route path="/hospital/insurance/company/list" element={<InsuranceCompanyList />} />
                        <Route path="/hospital/insurance/company/add" element={<AddInsuranceCompany />} />
                        <Route path="/hospital/insurance/company/update" element={<UpdateInsuranceCompany />} />
                        <Route path="/hospital/insurance/claims/list" element={<ClaimsList />} />

                        {/*SCHEDULER ROUTES*/}
                        <Route path="/schedule/consultation/doctors" element={<ScheduleDoctorList />} />
                        <Route path="/schedule/consultation/doctor/profile" element={<DoctorConsultationProfile />} />

                        {/*DOCTOR ROUTES*/}
                        <Route path="/doctor/dashboard" element={<DoctorDashboard />} />
                        <Route path="/doctor/profile" element={<DoctorProfile />} />
                        <Route path="/doctor/list" element={<DoctorList />} />
                        <Route path="/doctor/consultation/history" element={<ConsultationHistory />} />
                        <Route path="/doctor/consultation" element={<Consultation />} />
                        <Route path="/doctor/consultations/:id" element={<ConsultationPage userType={"doctor"} />} />
                        <Route path="/doctor/outpatient" element={<DoctorOutpatient />} />
                        <Route path="/doctor/accident-and-emergency" element={<AccidentAndEmergency/>} />

                        {/*PHARMACY ROUTES*/}
                        <Route path="/pharmacy/dashboard" element={<PharmacyDashboard />} />
                        <Route path="/pharmacy/drug/list" element={<DrugList />} />
                        <Route path="/pharmacy/drug/view" element={<DrugList />} />
                        <Route path="/pharmacy/drug/add" element={<AddDrug />} />
                        <Route path="/pharmacy/drug/update" element={<UpdateDrug />} />
                        <Route path="/pharmacy/drug/manufacturer/list" element={<ManufacturerList />} />
                        <Route path="/pharmacy/drug/manufacturer/add" element={<AddManufacturer />} />
                        <Route path="/pharmacy/drug/manufacturer/update" element={<UpdateManufacturer />} />
                        <Route path="/pharmacy/prescription/list" element={<FindPatientPrescription />} />
                        <Route path="/pharmacy/prescription/details" element={<PrescriptionDetails />} />
                        <Route path="/pharmacy/prescription/history" element={<PatientPrescriptionHistory />} />
                        <Route path="/pharmacy/drug/category/list" element={<DrugCategoryList />} />
                        <Route path="/pharmacy/drug/category/add" element={<AddDrugCategory />} />
                        <Route path="/pharmacy/drug/category/update" element={<UpdateDrugCategory />} />
                        <Route path="/pharmacy/drug/subcategory/list" element={<DrugSubCategoryList />} />
                        <Route path="/pharmacy/drug/subcategory/update" element={<UpdateDrugSubCategory />} />
                        <Route path="/pharmacy/drug/subcategory/add" element={<AddDrugSubCategory />} />
                        <Route path="/pharmacy/drug/category/sub/sub/list" element={<DrugSubSubCategoryList />} />
                        <Route path="/pharmacy/drug/category/sub/sub/add" element={<AddSubSubDrugCategory />} />
                        <Route path="/pharmacy/drug/category/sub/sub/update" element={<UpdateDrugSubSubCategory />} />
                        <Route path="/pharmacy/prescription" element={<Dashboard />} />
                        <Route path="/pharmacy/drug/expired" element={<ExpiredDrug />} />
                        <Route path="/pharmacy/drug/inventory/list" element={<DrugInventoryList />} />
                        <Route path="/pharmacy/drug/inventory/view" element={<DrugInventoryList />} />
                        <Route path="/pharamcy/inventory/update" element={<UpdateDrugInventory />} />
                        <Route path="/pharamcy/inventory/add" element={<AddDrugInventory />} />
                        <Route path="/settings/package/dashboard" element={<PackageDashboard />} />
                        <Route path="/hospital/package/list" element={<PackagesList />} />
                        <Route path="/hospital/package/add" element={<AddPackage />} />
                        <Route path="/hospital/package/update" element={<UpdatePackage />} />
                        <Route path="/hospital/package/subscribe" element={<PackageSubscription />} />
                        <Route path="/hospital/package/subscribers" element={<PackageSubscribers />} />

                        {/* LABORATORY */}
                        <Route path="/lab/dashboard" element={<LabDashboard />} />
                        <Route path="/lab/list" element={<LabList />} />
                        <Route path="/lab/add" element={<AddLab />} />
                        <Route path="/lab/update" element={<UpdateLab />} />

                        {/*LAB SPECIMEN*/}
                        <Route path="/lab/specimen/add" element={<AddLabSpecimen />} />
                        <Route path="/lab/specimen/update" element={<UpdateSpecimen />} />
                        <Route path="/lab/specimen/list" element={<SpecimenList />} />

                        {/*LAB BOTTLE*/}
                        <Route path="/lab/bottle/add" element={<AddLabBottle />} />
                        <Route path="/lab/bottle/update" element={<UpdateBottle />} />
                        <Route path="/lab/bottle/list" element={<BottleList />} />

                        {/*LAB BOTTLE VOLUME*/}
                        <Route path="/lab/volume/add" element={<AddLabBottleVolume />} />
                        <Route path="/lab/volume/update" element={<UpdateVolume />} />
                        <Route path="/lab/volume/list" element={<VolumeList />} />

                        {/*LAB TEST*/}
                        <Route path="/lab/test/add" element={<AddLabTest />} />
                        <Route path="/lab/test/update" element={<UpdateLabTest />} />
                        <Route path="/lab/test/list" element={<LabTestList />} />
                        <Route path="/lab/test/view" element={<LabTestList />} />
                        <Route path="/lab/test/requests" element={<SearchPatient />} />
                        <Route path="/lab/test/patient/:id" element={<PatientSpecimen />} />
                        <Route path="/lab/test/request/search" element={<SearchLabRequest />} />
                        <Route path="/lab/test/result/template/:id" element={<LabTestResultTemplate />} />
                        <Route path="/lab/test/request/history" element={<LabRequestHistory IsViewOnly={false} />} />
                        <Route path="/lab/test/request/:id" element={<LabRequestProcessingPage />} />
                        <Route path="/lab/test/requests/:id" element={<LabRequestProcessingPageHod />} />

                        {/* IN-PATIENT */}
                        <Route path="/inpatient/dashboard" element={<InPatient userType={"nurse"}  />} />
                        <Route path="/room/dashboard" element={<InpatientDashboard />} />
                        <Route path="/inpatient/room/available" element={<AvailableRooms />} />
                        <Route path="/inpatient/room/occupied" element={<OccupiedRooms />} />
                        <Route path="/inpatient/room/undermaintenance" element={<RoomsUnderMaintenance />} />
                        <Route path="/inpatient/room/allrooms" element={<AllRooms />} />

                        {/* ADMISSION */}
                        <Route path="/admission/dashboard" element={<AdmissionDashboard />} />
                        <Route path="/admission/profile/:id" element={<AdmissionProfile userType={"doctor"} />} />
                        <Route path="/admission/profile/nurse/:id" element={<AdmissionProfile userType={"nurse"} />} />
                        <Route path="/admission/profile/service-desk/:id" element={<AdmissionProfile userType={"service-desk"} />} />
                        <Route path="/admission/admit" element={<FindPatients />} />
                        <Route path="/admission/inpatients" element={<InPatient userType={"doctor"}  />} />
                        <Route path="/admission/room/status" element={<RoomStatus />} />
                        <Route path="/admission/patient/:id" element={<AdmissionPage />} />
                        <Route path="/admission/admit/patient/:id" element={<AdmissionPage />} />
                        <Route path="/admission/admit_page/:patient_id/:room_id" element={<AdmitPatient />} />

                        {/* Imaging */}
                        <Route path="/imaging/dashboard" element={<ImagingDashboard />} />
                        <Route path="/imaging/request" element={<ImagingRequest IsViewOnly={false} />} />
                        <Route path="/imaging/history" element={<ImagingHistory />} />
                        <Route path="/imaging/lab/add" element={<AddImagingLab />} />
                        <Route path="/imaging/lab/update" element={<UpdateImagingLab />} />
                        <Route path="/imaging/lab" element={<ImagingLabList />} />
                        <Route path="/imaging/type" element={<ImagingList IsView={false} />} />
                        <Route path="/imaging/type/view" element={<ImagingList IsView={true} />} />
                        <Route path="/imaging/add" element={<AddImaging />} />
                        <Route path="/imaging/update" element={<UpdateImaging />} />
                        <Route path="/imaging/update" element={<AddImaging />} />
                        <Route path="/imaging/profile/:id" element={<ImagingProfile />} />

                        {/* SETTINGS */}
                        <Route path="/settings/services" element={<ServicesDashboard />} />
                        <Route path="/settings/services/permission" element={<ServicePermission />} />
                        <Route path="/settings/service/:id" element={<ServicePage />} />
                        <Route path="/services/dashboard" element={<ServiceUserPage /> } />
                        <Route path="/services/profile/:id" element={<ServiceProfilePage /> } />
                        <Route path="/services/request/pending/:id" element={<ServiceRequestPending /> } />
                        <Route path="/services/request/history/:id" element={<ServiceRequestHistory /> } />
                        <Route path="/service-desk/accident-and-emergency" element={<AccidentAndEmergency/>} />

                        {/* WEBSITE */}
                        <Route path="/website/dashboard" element={<WebsiteDashboard />} />
                        <Route path="/website/slider" element={<SliderList />} />
                        <Route path="/website/slider/add" element={<AddSlider />} />
                        <Route path="/website/slider/update" element={<UpdateSlider />} />
                        <Route path="/website/news" element={<NewsList />} />
                        <Route path="/website/news/add" element={<AddNews />} />
                        <Route path="/website/news/update" element={<UpdateNews />} />
                        <Route path="/website/profile" element={<Profile />} />
                        <Route path="/website/profile/update" element={<UpdateProfile />} />
                        <Route path="/website/gallery" element={<Gallery />} />
                        <Route path="/website/gallery/category" element={<GalleryCategory />} />
                        <Route path="/website/gallery/category/sub" element={<GallerySubCategory />} />

                        {/* FINANCE */}
                        <Route path="/finance/dashboard" element={<FinanceDashboard />} />
                        <Route path="/finance/inventory" element={<InventoryDashboard />} />
                        <Route path="/finance/inventory/manufacturer" element={<Manufacturer />} />
                        <Route path="/finance/inventory/manufacturer/add" element={<AddItemManufacturer />} />
                        <Route path="/finance/inventory/manufacturer/update" element={<UpdateItemManufacturer />} />
                        <Route path="/finance/inventory/vendor" element={<Vendor />} />
                        <Route path="/finance/inventory/vendor/add" element={<AddItemVendor />} />
                        <Route path="/finance/inventory/vendor/update" element={<UpdateItemVendor />} />
                        <Route path="/finance/inventory/item/category" element={<ItemCategoryList />} />
                        <Route path="/finance/inventory/item/category/add" element={<AddItemCategory />} />
                        <Route path="/finance/inventory/item/category/update" element={<UpdateItemCategory />} />
                        <Route path="/finance/inventory/item/category/sub" element={<ItemSubCategoryList />} />
                        <Route path="/finance/inventory/item/category/sub/add" element={<AddItemSubCategory />} />
                        <Route path="/finance/inventory/item/category/sub/update" element={<UpdateItemSubCategory />} />
                        <Route path="/finance/inventory/item" element={<VendorItemList />} />
                        <Route path="/finance/inventory/location" element={<InventoryLocation />} />
                        <Route path="/finance/inventory/dashboard" element={<InventoryAllocation />} />
                        <Route path="/finance/inventory/item/profile/:slug" element={<ItemDetails />} />

                        {/*PHYSIOTHERAPY ROUTES*/}
                        <Route path="/physiotherapy/dashboard" element={<PhysiotherapyDashboard />} />
                        <Route path="/physiotherapy/outpatient" element={<PhysiotherapyOutpatient/>} />
                        <Route path="/physiotherapy/consultation" element={<Consultation />} />
                        <Route path="/physiotherapy/inpatients" element={<InPatient userType={"doctor"}  />} />

                        {/*SERVICE DESK*/}
                        <Route path="/service-desk/dashboard" element={<ServiceDeskDashboard />} />
                        <Route path="/service-desk/prescription/history" element={<PatientPrescriptionHistory />} />
                        <Route path="/service-desk/imaging/request" element={<ImagingRequest IsViewOnly={true} />} />
                        <Route path="/service-desk/lab/test/request/history" element={<LabRequestHistory IsViewOnly={true} />} />
                        <Route path="/service-desk/patient/list" element={<PatientList isFront={true} />} />
                        <Route path="/service-desk/patient/profile" element={<FrontDeskPatientProfile/>} />
                        <Route path="/service-desk/outpatient" element={<ServiceDeskOutpatient />} />
                        <Route path="/service-desk/consultations/:id" element={<ConsultationPage userType={"service-desk"} />} />
                        <Route path="/service-desk/inpatient" element={<InPatient userType={"service-desk"}  />} />
                        <Route path="/service-desk/accident-and-emergency" element={<AccidentAndEmergency/>} />

                        {/* CHANGE PASSWORD */}
                        <Route path="/user/password/update" element={<ChangePassword />} />
                        <Route path="/forgotten-password" element={<ForgottenPassword />} />
                        <Route path="/dash" element={<DoctorsDashboard />} />
                        <Route path="/dash2" element={<NursesDashboard />} />

                        <Route  path="/" element={<Dashboard />} />
                    </Routes>

                </div>

            </Routers>
            <ToastContainer />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLogInDetails: (p) => {
            dispatch(setLogInDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p))
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideNavBar);
