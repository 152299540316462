import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setpersonDetails } from "../../../../actions/setpersondetailsaction";
import DashboardCard from "../../../Dashboard/utils/dashboardcard";
import "./packagedashboard.css";
import {loginValidation} from "../../../../context/loginvalidation";
import Navigator from "../../../Settings/navigation/navigation";

class PackageDashboard extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
    }

    render() {
        return (
            <div className="dashboardpage">
                <div className="topheader">
                    <Navigator title="" />
                    <ul>
                        <li>
                            <i
                                className="fa fa-gift fa-2x"
                                aria-hidden="true"
                            />
                        </li>
                        <li>
                            <span>Package Dashboard</span>
                        </li>
                    </ul>
                </div>


                <div className="first_section">
                    <div className="row">
                        <div className="col-sm">
                            {" "}
                            <Link to="/hospital/package/list">
                                <DashboardCard name="Package" icon="fa fa-plus fa-4x" count="" />
                            </Link>
                        </div>
                        <div className="col-sm">
                            {" "}
                            <Link to="/hospital/package/subscribe">
                                <DashboardCard name="Add Subscriber" icon="fa fa-file-medical fa-4x" count="" />
                            </Link>
                        </div>

                        <div className="col-sm">
                            {" "}
                            <Link to="/hospital/package/subscribers">
                                <DashboardCard name="Subscribers" icon="fa fa-check-circle fa-4x" count="" />
                            </Link>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnUpdateHospital: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageDashboard);
