import React, {Component} from "react";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";

class PatientAllergyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            openAreYouSure: false,
            allergyMessage: "",
            allergyID: ""
        }
    }

    DeleteAllergy = (e) => {
        this.setState({
            allergyMessage: "Are you sure you want delete the "+e.allergyName+" allergy?",
            openAreYouSure: true,
            allergyID: e._id
        })
    }

    handleDelete = () => {
        axios.delete(`${this.state.serverLink}allergy/delete/${this.state.allergyID}`, this.props.doctorDetails.token)
            .then(result => {
                if(result.data.deletedCount === 1) {
                    this.setState({
                        openAreYouSure: false
                    })
                    window.location.reload();
                }
            })
    }

    closeDeleteDialog = () => {
        this.setState({
            openAreYouSure: false,
        });
    };

    render() {
        let count = 0;
        return <div className="card mb-3">
            <div className="card-header">
                Allergies
            </div>
            <ConfirmDialogBox
                openDailog={this.state.openAreYouSure}
                onSetOpenDialog={this.closeDeleteDialog}
                title="Are you sure"
                des={this.state.allergyMessage}
                handleConfirmOkBtn={this.handleDelete}
            >

            </ConfirmDialogBox>
            <div className="card-body">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Allergy Name</th>
                        <th>Medication</th>
                        <th>Last Attack Date</th>
                        <th>Duration Before Subsiding</th>
                        <th>Description</th>
                        <th>Image</th>
                        <th>--</th>
                    </tr>
                    </thead>
                    { this.props.allergicList.length === 0 ?
                        (
                            <tbody>
                                <tr><td>No Allergic Found</td></tr>
                            </tbody>
                        )
                        :
                        (
                            <tbody>
                            {this.props.allergicList.map((allergy) => {
                                count++;
                                return (
                                    <tr key={allergy._id}>
                                        <td>{count}</td>
                                        <td>{allergy.allergyName}</td>
                                        <td>{allergy.allergyMedication}</td>
                                        <td>{this.props.formatDate(allergy.lastAttackDate)}</td>
                                        <td>{allergy.durationBeforeSubsiding}</td>
                                        <td>{allergy.allergyDescription}</td>
                                        <td className="align-middle">
                                            {!this.props.isFront ? allergy.reactionImage === "" ? (
                                                <div className="userbg">
                                                    <i className="fa fa-file-excel fa-2x"/>
                                                </div>
                                            ) :  (
                                                <img
                                                    className="image img-fluid"
                                                    width="50px"
                                                    alt=""
                                                    srcSet={allergy.reactionImage.includes("simplefileupload") ? allergy.reactionImage : serverLink + 'public/uploads/patient_allergy/'+allergy.reactionImage}
                                                    onClick={this.props.handleOnClick}
                                                />
                                            ) : "--"}
                                        </td>
                                        <td>{!this.props.isFront ?  <i onClick={() => this.DeleteAllergy(allergy)} className="fa fa-trash text-danger"/> : "--"}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        )
                    }
                </table>
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(PatientAllergyList);
