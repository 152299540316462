import React, {Component} from "react";
import "./processingpage.css";
import {serverLink, hospitalCode, calculateAge, uploadAPI} from "../../../../resources/url";
import axios from "axios";
import BarcodeImage from "../../../Barcode/barcode";
import PatientActionButton from "../../../Patients/utils/patientaction";
import FormPrompt from "../../../DialogBoxes/formprompt";
import ChoosePayment from "../../../Finance/choosepayment/choosepayment";
import {connect} from "react-redux";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import {formatDateAndTime} from "../../../../resources/constants";
import Navigator from "../../../Settings/navigation/navigation";
import SimpleFileUpload from "react-simple-file-upload";
import {toast} from "react-toastify";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import PatientVitalList from "../../../Patients/utils/patientvitals";

class LabRequestProcessingPageHod extends Component {
    constructor(props) {
        super(props);
        let page_url = window.location.pathname;
        let page_id = page_url.split('/');
        let id = page_id[4];

        this.state = {
            serverLink: serverLink,
            patientSerial: "",
            isLoading: true,
            request_id: id,
            testData: '',
            patientData: '',
            paymentData: '',
            openTakeSample: false,
            openEnterResult: false,
            onResultData: '',
            description: '',
            imagefile: "",
            htmlelement: "",
            image_name: "",
            resultAttachmentURL: '',
            onresultAttachedSuccessfulURL: '',
            actionError: false,
            actionSubmitting: false,
            actionSuccess: false,
            actionMessage: '',
            isCloseDialogAppear: true,
            openClearPayment: false,
            onConfirmResultModal: false,
            hideResultCard: false,
            patientAllergy: [],
            vitalList: [],
            visitNotes: [],
            tasks: [],
            vitals: [],
            allergy: [],
            prescription: [],
            visitNoteList: [],
            graph_label: [],
            temp_val: [],
            bp_systolic_val: [],
            bp_diastolic_val: [],
            height_val: [],
            weight_val: [],
        }

    }

    componentWillMount() {
        this.fetchTestData();
        this.getAppontmentData();
    }
    async getAppontmentData() {
        //PATIENT ALLERGEN
        await axios.get(`${serverLink}allergy/${hospitalCode}/${this.state.testData.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    allergy: result
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        await axios.get(`${serverLink}vital/${hospitalCode}/${this.state.testData.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;

                this.setState({
                    vitals: result
                })

                if (result.length > 0) {
                    let graph_label = [];
                    let temp_val = [];
                    let weight_val = [];
                    let height_val = [];
                    let bp_systolic_val = [];
                    let bp_diastolic_val = [];
                    result.map((v, index) => {
                        if (index < 5) {
                            graph_label.push(this.formatDate(v.submittedOn))

                            //TEMPERATURE GRAPH GENERATOR
                            if (v.temperature !== '') {
                                if (v.temperature_unit !== 'C') {
                                    let fToCel = (parseFloat(v.temperature) - 32) * 5 / 9;
                                    temp_val.push(fToCel.toFixed(1));
                                } else {
                                    temp_val.push(parseFloat(v.temperature));
                                }
                            } else {
                                temp_val.push(0);
                            }

                            //HEIGHT GRAPH GENERATOR
                            if (v.height !== '') {
                                let height = parseFloat(v.height)
                                if (v.height_unit !== 'M') {
                                    let CMTom = height * 0.01;
                                    height_val.push(parseFloat(CMTom.toFixed(2)));
                                } else {
                                    height_val.push(parseFloat(height.toFixed(2)));
                                }
                            } else {
                                height_val.push(0);
                            }

                            //WEIGHT GRAPH GENERATOR
                            if (v.weight !== '') {
                                if (v.weight_unit !== 'KG') {
                                    let LBToKG = parseFloat(v.weight) * 0.453592;
                                    weight_val.push(parseFloat(LBToKG.toFixed(1)));
                                } else {
                                    weight_val.push(parseFloat(v.weight));
                                }
                            } else {
                                weight_val.push(0);
                            }

                            //BLOOD PRESSURE GRAPH GENERATOR
                            if (v.bloodPressure !== '') {
                                const bp = v.bloodPressure.split('/');
                                bp_systolic_val.push(parseInt(bp[0]));
                                bp_diastolic_val.push(parseInt(bp[1]));
                            } else {
                                bp_systolic_val.push(0);
                                bp_diastolic_val.push(0);
                            }

                        }
                    })

                    this.setState({
                        graph_label: graph_label.reverse(),
                        temp_val: temp_val.reverse(),
                        height_val: height_val.reverse(),
                        weight_val: weight_val.reverse(),
                        bp_systolic_val: bp_systolic_val.reverse(),
                        bp_diastolic_val: bp_diastolic_val.reverse()
                    })

                }
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        await axios.get(`${serverLink}appointment/consultation/doctorNote/${hospitalCode}/${this.state.testData.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                const note = result.data;
                this.setState({
                    visitNotes: note,
                    doctorNote: note.filter(item => item.appointmentID === this.state.appointmentID),
                    isLoading: false
                });
            })

    }


    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            return `${user_date.getDate()}/${monthNames[user_date.getMonth()]}/${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()} : 
                    ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}
                    
                    `
        } else {
            return '--'
        }
    };

    moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    async fetchTestData () {
        // ========================== LABORATORY TEST DATA ==================================
        await axios.get(`${serverLink}laboratory/test/request/${this.state.request_id}`, this.props.doctorDetails.token)
            .then(result => {
                const request = result.data;
                console.log(request.specimen[0].items)
                if (typeof request.paymentStatus === 'undefined') {
                    window.location.href = '/lab/test/requests'
                } else {
                    this.setState({
                        testData: request
                    })
                }
            })
            .catch(error => {
                console.log(error)
                window.location.href = '/lab/test/requests'
            });

        // ========================== PATIENT DATA ==================================
        await axios.get(`${serverLink}patient/${this.state.testData.hospitalCode}/${this.state.testData.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                const request = result.data;
                this.setState({
                    patientData: request
                })
            })
            .catch(error => {
                console.log(error)
                window.location.href = '/lab/test/requests'
            });



        // ========================== LAB PAYMENT DATA ==================================
        await axios.get(`${serverLink}payment/test/request/${this.state.request_id}`, this.props.doctorDetails.token)
            .then(result => {
                const request = result.data[0];
                this.setState({
                    isLoading: false,
                    paymentData: request
                })
            })
            .catch(error => {
                console.log(error)
            });
    }

    onOpenTakeSample = () => {
        this.state.testData.testStatus !== 'result confirmed' &&
        this.setState({
            openTakeSample: true
        })
    }

    handlePaymentResponse = () => {
        this.fetchTestData();
        this.closeDialog();
    }

    handleSampleSubmission = () => {

        const sendData = {
            specimenTakeBy: this.props.doctorDetails.userID,
            specimenTakeByName: this.props.doctorDetails.fullName,
            testStatus: 'specimen taken',
            specimenTakenDate: new Date(),
            id: this.state.request_id,
            testResult: '',
            testAttachment: '',
            resultEnteredBy: '',
            resultEnteredName: '',
            resultEnteredDate: '',
            resultConfirmedBy: '',
            resultConfirmedByName: '',
            resultConfirmedDate: ''
        }

        axios.patch(`${serverLink}laboratory/test/request/specimen`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    this.setState({
                        actionSubmitting: false,
                        actionError: false,
                        actionSuccess: true
                    })
                    this.fetchTestData();

                    const object = this;
                    setTimeout(function () {
                        object.setState({
                            actionSubmitting: false,
                            actionError: false,
                            actionSuccess: false,
                            openTakeSample: false
                        })
                    }, 1000)
                }
            })
            .catch(error => {
                console.log('Error submitting sample', error)
                this.setState({
                    actionSubmitting: false,
                    actionError: true,
                    actionSuccess: false
                })
            })

    }

    onResultAttachmentChange = (e) => {
        if (e !== '') {
            this.setState({
                resultAttachmentURL: e,
            });
        }
        // if (e.target.files && e.target.files[0]) {
        //     this.setState({
        //         resultAttachmentURL: e.target.files[0],
        //     });
        // }
    }

    onOpenEnterResult = () => {
        if (this.state.testData.testStatus !== 'requested' && this.state.testData.testStatus !== 'result confirmed') {
            this.setState({
                actionError: false,
                actionSuccess: false,
                actionSubmitting: false,
                actionMessage: '',
                openEnterResult: true,
                onResultData: this.state.testData.testResult
            })
        }
    }

    handleOnSubmitResult = (e) => {
        e.preventDefault();
        const attachment = this.state.resultAttachmentURL;
        this.setState({
            actionError: false,
            actionSuccess: false,
            actionSubmitting: true,
            actionMessage: ''
        })
        this.onUploadTestResult(attachment)

        // if (attachment !== '') {
        //     const formData = new FormData();
        //     formData.append('testAttachment', attachment);
        //     axios.post(`${serverLink}laboratory/test/request/result/attachment/upload`, formData, this.props.doctorDetails.token)
        //         .then(result => {
        //             if (result.status === 200) {
        //                 this.onUploadTestResult(result.data.testAttachment)
        //             } else {
        //                 this.setState({
        //                     actionError: true,
        //                     actionSubmitting: false,
        //                     actionMessage: 'Error uploading the test attachment file. Only pdf, or image format is allowed'
        //                 });
        //                 return false;
        //             }
        //         })
        //         .catch(error => {
        //             this.setState({
        //                 actionError: true,
        //                 actionSubmitting: false,
        //                 actionMessage: 'Error uploading the test attachment file. Only pdf, or image format is allowed'
        //             });
        //         })
        // } else {
        //     this.onUploadTestResult('')
        // }
    }

    onUploadTestResult (resultAttachment) {
        const sendData = {
            specimen: this.state.testData.specimen,
            testAttachment: resultAttachment,
            testStatus: 'result entered',
            resultEnteredBy: this.props.doctorDetails.userID,
            resultEnteredName: this.props.doctorDetails.fullName,
            resultEnteredDate: new Date(),
            id: this.state.request_id,
        }

        axios.patch(`${serverLink}laboratory/test/request/result/upload`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', this.state.request_id)
                        axios.patch(`${serverLink}laboratory/test/request/result/uploadFile`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.setState({
                        actionSuccess: true,
                        actionSubmitting: false
                    });

                    const object = this
                    setTimeout(function () {
                        object.setState({
                            actionSuccess: false,
                            actionSubmitting: false,
                            openEnterResult: false
                        });
                        object.fetchTestData();
                    },2000)


                }
            })
            .catch()

    }

    onConfirmResultModel = () => {
        this.setState({
            onConfirmResultModal: true
        })
    }

    onConfirmResult = () => {
        const sendData = {
            testStatus: 'result confirmed',
            id: this.state.request_id,
            description: this.state.description,
            resultConfirmedBy: this.props.doctorDetails.userID,
            resultConfirmedByName: this.props.doctorDetails.fullName,
            resultConfirmedDate: new Date()
        }
        this.confirmResultHandler(sendData)
    }

    onRejectResult = () => {
        const sendData = {
            testStatus: 'result rejected',
            id: this.state.request_id,
            resultConfirmedBy: this.props.doctorDetails.userID,
            resultConfirmedByName: this.props.doctorDetails.fullName,
            resultConfirmedDate: new Date()
        }
        this.confirmResultHandler(sendData)
    }

    confirmResultHandler = (sendData) => {
        this.setState({
            isLoading: true
        })
        axios.patch(`${serverLink}laboratory/test/request/result/confirm`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success')
                    this.fetchTestData()
            })
            .catch(error => {
                console.log("CONFIRMED RESULT FAILED", error)
            })

        this.setState({
            isLoading: false,
            onConfirmResultModal: false
        })
    }

    closeDialog = () => {
        this.setState({
            openTakeSample: false,
            openEnterResult: false,
            openClearPayment: false,
            onConfirmResultModal: false
        });
    };

    printBarcode = () => {
        this.handleSampleSubmission();
        let content = document.getElementById("BarcodeID");
        let pri = document.getElementById("iframePrint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    onEditResult = (e) => {
        const target = e.target;
        const item_type = target.getAttribute('item_type');
        const item_id = target.getAttribute('item_id');
        const item = this.state.testData.specimen[0].items.filter(item => item.item_id === item_id);
        if (item_type === 'single') {
            item[0].result = target.value;
            if (!isNaN(target.value))
                item[0].result_type = 'integer';
            else
                item[0].result_type = 'string';
        } else {
            const ref_counter = target.getAttribute('ref_counter');
            const index = target.getAttribute('index');
            const selector = item[0].ref_data[index];

            if (ref_counter === 'single') {
                selector.result = target.value;
                if (!isNaN(target.value))
                    selector.result_type = 'integer';
                else
                    selector.result_type = 'string';
            } else {
                selector.ref_item[target.getAttribute('index_many')].result = target.value;
                if (!isNaN(target.value))
                    selector.ref_item[target.getAttribute('index_many')].result_type = 'integer';
                else
                    selector.ref_item[target.getAttribute('index_many')].result_type = 'string';
            }

        }
    }

    confirmHandler = (event) => {
        this.setState({
            description: event
        });
    }

    onImageChange = (event) => {
        const formData = this.state.formData;
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "application/pdf" ) {
            } else {
                toast.error("Only .pdf, .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 5000000) {
                toast.error("max file size is 5mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: file,
                    htmlelement: e.target.result,
                    image_name: file.name,
                });
            };
        }
    };


    render() {
        const editorConfig = {
            readonly: false,
            height: 400
        };

        let testStatus = '';
        if (this.state.testData.testStatus === 'requested')
            testStatus = <span className="badge badge-warning">{this.state.testData.testStatus}</span>
        else if (this.state.testData.testStatus === 'specimen taken')
            testStatus = <span className="badge badge-secondary">{this.state.testData.testStatus}</span>
        else if (this.state.testData.testStatus === 'result entered')
            testStatus = <span className="badge badge-info">{this.state.testData.testStatus}</span>
        else if (this.state.testData.testStatus === 'result confirmed')
            testStatus = <span className="badge badge-success">{this.state.testData.testStatus}</span>
        else
            testStatus = <span className="badge badge-danger">{this.state.testData.testStatus}</span>

        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">

                <FormPrompt
                    style={{ position: 'relative', zIndex: '3' }}
                    openDialog={this.state.openTakeSample}
                    title="Take Sample"
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    {
                        this.state.testData.paymentStatus === 'unpaid' ?
                            <>
                                <h4>Clear Payment to Take a sample</h4>
                                <ChoosePayment
                                    patientID={this.state.patientData.patientSerial}
                                    patientName={`${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`}
                                    walletAmount={this.state.patientData.walletAmount}
                                    MoneyConverter={this.moneyFormat}
                                    paymentFor="Lab"
                                    itemID={this.state.request_id}
                                    itemName={this.state.testData.testName}
                                    itemAmount={this.state.testData.testAmount}
                                    handlePaymentSuccess={this.handlePaymentResponse}
                                />
                            </>
                            :
                            <div>
                                <div className="form-row">
                                    <div className="col-12">
                                        <table className="table table-striped">
                                            <tbody>
                                            <tr>
                                                <th>Specimen</th>
                                                <td>{this.state.testData.specimen[0].specimenName}</td>
                                            </tr>
                                            <tr>
                                                <th>Bottle</th>
                                                <td>{this.state.testData.specimen[0].specimenBottle}</td>
                                            </tr>
                                            <tr>
                                                <th>Volume</th>
                                                <td>{this.state.testData.specimen[0].specimenBottleVolume}</td>
                                            </tr>
                                            <tr>
                                                <th>Preparation</th>
                                                <td>{this.state.testData.specimen[0].specimenPatientPreparation !== '' ? this.state.testData.specimen[0].specimenPatientPreparation : '--'}</td>
                                            </tr>
                                            <tr>
                                                <th>Turn Around</th>
                                                <td>{this.state.testData.specimen[0].specimenTurnAroundTime}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <hr/>

                                {
                                    this.state.testData.paymentStatus === 'unpaid' ?
                                        <div className="text-center"><h1 className="badge badge-danger">CAPTURE PAYMENT BEFORE TAKING SAMPLE</h1></div> :
                                        this.state.testData.testStatus === 'requested' || this.state.testData.testStatus === 'specimen taken' || this.state.testData.testStatus === 'result rejected' ?
                                            <button className="btn btn-primary btn-block" onClick={this.printBarcode}>Submit</button> :
                                            <div className="text-center"><h1 className="badge badge-danger">RESULT ALREADY ENTERED/CONFIRMED!</h1></div>
                                }

                            </div>

                    }

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openEnterResult}
                    title="Enter Test Result"
                    isFullWidth={false}
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <form onSubmit={this.handleOnSubmitResult}>

                        <div className="alert alert-warning">
                            <i className="fa fa-warning" /> Please note: once submitted, it will overwrite the previous entered result. This action cannot be undo!
                        </div>
                        {
                            this.state.testData.specimen[0].items.map((item, index) => {
                                return <div key={index} className="form-row mt-3 mb-3">
                                    <div className="col-12">
                                        <h3>{item.item_name} ({item.si_unit})</h3>
                                        {
                                            item.si_unit === 'Other' ?
                                                <>
                                                    <input type="text" className="form-control" required="required" placeholder={"Enter the test result"} onChange={this.onEditResult} item_id={item.item_id} item_type={item.ref_interval}/>
                                                </> :
                                                <>
                                                    {
                                                        item.ref_interval === 'single' ?
                                                            <>
                                                                <input type="text" className="form-control" required="required" onChange={this.onEditResult} item_id={item.item_id} item_type={item.ref_interval}/>
                                                                <span className="badge badge-info col-md-6">Min Ref: {item.ref_interval_min}</span>
                                                                <span className="badge badge-success col-md-6">Max Ref: {item.ref_interval_max}</span>
                                                            </> :
                                                            item.ref_data.map((ref, index_val) => {
                                                                if (ref.ref_counter === 'single') {
                                                                    return <div key={index_val} className="mt-3">
                                                                        <label htmlFor="">{ref.ref_name}</label>
                                                                        <input type="text" className="form-control" required="required" onChange={this.onEditResult} item_id={item.item_id} item_type={item.ref_interval} index={index_val} ref_counter={ref.ref_counter} />
                                                                        <span className="badge badge-info col-md-6">Min Ref: {ref.ref_interval_min}</span>
                                                                        <span className="badge badge-success col-md-6">Max Ref: {ref.ref_interval_max}</span>
                                                                    </div>
                                                                } else {
                                                                    return <div key={index_val}>
                                                                        <hr/>
                                                                        <h4>{ref.ref_title}</h4>
                                                                        {
                                                                            ref.ref_item.map((pp, ii) => {
                                                                                return <div key={ii} className="mt-3">
                                                                                    <label htmlFor="">{pp.ref_name} ({(ii+1)})</label>
                                                                                    <input type="text" className="form-control" required="required" onChange={this.onEditResult} item_id={item.item_id} item_type={item.ref_interval} ref_counter={ref.ref_counter} index={index_val} index_many={ii} />
                                                                                    <span className="badge badge-info col-md-6">Min Ref: {pp.ref_interval_min}</span>
                                                                                    <span className="badge badge-success col-md-6">Max Ref: {pp.ref_interval_max}</span>
                                                                                </div>
                                                                            })
                                                                        }

                                                                    </div>
                                                                }

                                                            })
                                                    }
                                                </>
                                        }



                                    </div>
                                </div>
                            })
                        }
                        <div className="mb-3" >
                            <label className="required fs-5 fw-bold mb-2">Image</label>
                            <div className="mb-3">
                                <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                    <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                        document.getElementById("photo").click()
                                    }}>
                                        <div className="row">
                                            <i className="bi bi-image text-primary fs-3x"/>
                                            <div className="ms-4 col-md-9">
                                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.state.image_name !== "" ? this.state.image_name : "Only .pdf, .jpg, .png, .jpeg are allowed"}</span>
                                            </div>
                                            <div className="col-md-2">{this.state.htmlelement !== "" ? <img className="img-thumbnail" width={350} height={350} srcSet={this.state.htmlelement !== "" ? this.state.htmlelement : ""}/> : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <span className="alert-danger"> Max of 5MB file is allowed!</span>
                            </div>
                            <input type="file"  id="photo" name={"photo"}  accept={"image/*, .pdf"} onChange={this.onImageChange} hidden />
                        </div>

                        {
                            this.state.actionSuccess === true ?
                                <p className="alert alert-success">Result Entered Successfully</p> : ''
                        }
                        {
                            this.state.actionError === true ?
                                <p className="alert alert-danger">{this.state.actionMessage}</p> : ''
                        }
                        {
                            this.state.actionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }


                        {
                            this.state.testData.testStatus !== 'confirmed' && this.state.testData.testStatus !== 'result confirmed' ?
                                <button className="btn btn-lg btn-block btn-info" type="submit">Submit Result</button>
                                : <div className="text-center"><h1 className="badge badge-danger">RESULT ALREADY CONFIRMED!</h1></div>
                        }
                    </form>
                </FormPrompt>

                <ConfirmDialogBox
                    openDailog={this.state.onConfirmResultModal}
                    onSetOpenDialog={this.onRejectResult}
                    handleConfirmOkBtn={this.onConfirmResult}
                    descriptionHandler={this.confirmHandler}
                    showComment={true}
                    isLoading={false}
                    isDecision={true}
                    title="CONFIRM TEST RESULT?"
                    des={ "Use the buttons below to either approve or reject test!" }
                />

                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Lab Request Page" />
                    </div>
                    <hr/>
                    <div className="">
                        <Tabs>
                            <TabList>
                                <Tab>Lab Request</Tab>
                                <Tab>Doctor Note</Tab>
                                <Tab>Allergens</Tab>
                                <Tab>Vitals Report</Tab>
                            </TabList>

                            {/*Active Lab Request*/}
                            <TabPanel>
                                <div className="second_section">
                                    <iframe id="iframePrint" style={{ height: "0px", width: "0px", position: "absolute" }} />
                                    <div id="BarcodeID">
                                        <BarcodeImage value={this.state.testData.requestSerial} height={40} width={1.4}/>
                                    </div>


                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    Action
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <PatientActionButton
                                                            handleClick={this.onOpenTakeSample}
                                                            actionName="Take Sample"
                                                            actionIcon="fa fa-vials fa-4x text-primary"
                                                        />
                                                    </div>

                                                    <div className="col-6">
                                                        <PatientActionButton
                                                            handleClick={this.onOpenEnterResult}
                                                            actionName="Enter Result"
                                                            actionIcon="fa fa-flask fa-4x text-danger"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    Patient Info
                                                </div>
                                                <div className="card-body">
                                                    <table className="table table-striped">
                                                        <tbody>
                                                        <tr>
                                                            <td>Patient Serial</td>
                                                            <td>{this.state.patientData.patientSerial}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Patient Name</td>
                                                            <td>{this.state.patientData.firstName} {this.state.patientData.middleName} {this.state.patientData.surname}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Blood Group</td>
                                                            <td>{this.state.patientData.bloodGroup}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Date of Birth</td>
                                                            <td>{this.state.patientData.dateOfBirth ? this.formatDate(this.state.patientData.dateOfBirth) +  `  (${calculateAge(this.state.patientData.dateOfBirth)}  ${calculateAge(this.state.patientData.dateOfBirth ) > 1 ?  "Years Old" : "Year Old"})` : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone Number</td>
                                                            <td>{this.state.patientData.phoneNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gender</td>
                                                            <td>{this.state.patientData.gender}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Marital Status</td>
                                                            <td>{this.state.patientData.maritalStatus}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Next of Kin</td>
                                                            <td>
                                                                {this.state.patientData.nextOfKinName}
                                                                <br/>
                                                                {this.state.patientData.nextOfKinRelationship}
                                                                <br/>
                                                                {this.state.patientData.nextOfKinPhone}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Religion</td>
                                                            <td>{this.state.patientData.religion}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Occupation</td>
                                                            <td>{this.state.patientData.occupation}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Wallet</td>
                                                            <td>{this.moneyFormat(this.state.patientData.walletAmount)}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    Test Info
                                                </div>
                                                <div className="card-body">
                                                    <table className="table table-striped">
                                                        <tbody>
                                                        <tr>
                                                            <td>Test Name</td>
                                                            <td>{this.state.testData.testName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Specimen</td>
                                                            <td>{this.state.testData.specimen[0].specimenName}</td>
                                                        </tr>
                                                        {
                                                            this.state.testData.testStatus !== 'requested' &&
                                                            <tr>
                                                                <td>Taken By</td>
                                                                <td>
                                                                    {this.state.testData.specimenTakeByName}
                                                                    <br/>
                                                                    {this.formatDateAndTime(this.state.testData.specimenTakenDate)}
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td>Bottle</td>
                                                            <td>{this.state.testData.specimen[0].specimenBottle}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Volume</td>
                                                            <td>{this.state.testData.specimen[0].specimenBottleVolume}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Turn Around</td>
                                                            <td>{this.state.testData.specimen[0].specimenTurnAroundTime}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Preparation</td>
                                                            <td>{this.state.testData.specimen[0].specimenPatientPreparation}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Amount</td>
                                                            <td>{this.moneyFormat(this.state.testData.testAmount)} </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Payment Status</td>
                                                            <td>{this.state.testData.paymentStatus === 'unpaid' ?
                                                                <span className="badge badge-danger">Unpaid</span> :
                                                                <span className="badge badge-success">Paid</span>
                                                            }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Requested Date</td>
                                                            <td>{this.formatDateAndTime(this.state.testData.requestDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Test Status</td>
                                                            <td>{testStatus}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        {
                                            this.state.testData.paymentStatus === 'paid' ?
                                                <div className="col-6">
                                                    <div className="card mb-3">
                                                        <div className="card-header">
                                                            Payment Info
                                                        </div>
                                                        <div className="card-body">
                                                            <table className="table table-striped">
                                                                <tbody>
                                                                <tr>
                                                                    <td>Amount Paid</td>
                                                                    <td>
                                                                        {
                                                                            this.state.paymentData ?
                                                                                this.moneyFormat(this.state.paymentData.paymentAmount) : '--'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Payment Method</td>
                                                                    <td>
                                                                        From: {
                                                                        this.state.paymentData ?
                                                                            this.state.paymentData.paymentMethod : '--'
                                                                    }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Payment Date</td>
                                                                    <td>
                                                                        {
                                                                            this.state.paymentData ?
                                                                                this.formatDateAndTime(this.state.paymentData.submittedOn) : '--'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Processed By</td>
                                                                    <td>
                                                                        {
                                                                            this.state.paymentData ?
                                                                                this.state.paymentData.submittedByName : '--'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Deducted From</td>
                                                                    <td>
                                                                        {
                                                                            this.state.paymentData ?
                                                                                this.state.paymentData.deductionFromName : '--'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className="col-6"><p className="text-center alert alert-warning">Payment Not Made Yet <i className="fa fa-warning"/></p></div>
                                        }

                                        {
                                            this.state.testData.resultEnteredName !== "" && typeof this.state.testData.resultEnteredName !== "undefined" ?
                                                <div className="col-6">
                                                    <div className="card mb-3">
                                                        <div className="card-header">
                                                            Test Result Info
                                                        </div>
                                                        <div className="card-body">
                                                            <table className="table table-striped">
                                                                <tbody>
                                                                <tr>
                                                                    <td>Result Attachment</td>
                                                                    <td>{this.state.testData.testAttachment !== '' ?
                                                                        <a href={this.state.testData.testAttachment.includes("simplefileupload") ? this.state.testData.testAttachment : `${serverLink}public/uploads/lab_result/${this.state.testData.testAttachment}`} className="btn btn-sm btn-success">View <i className="fa fa-eye" /></a> : '--'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Result Entered By</td>
                                                                    <td>{this.state.testData.resultEnteredName} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Result Entered Date</td>
                                                                    <td>{this.formatDateAndTime(this.state.testData.resultEnteredDate)} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Result Confirmed By</td>
                                                                    <td>{this.state.testData.resultConfirmedByName ? this.state.testData.resultConfirmedByName : '--'} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Result Confirmed Date</td>
                                                                    <td>{this.state.testData.resultConfirmedDate ? this.formatDateAndTime(this.state.testData.resultConfirmedDate) : '--'} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Status</td>
                                                                    <td>{testStatus} </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Consultant Comment</td>
                                                                    <td> <span  dangerouslySetInnerHTML={{ __html: this.state.testData.description ?? "--" }}></span> </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className="col-4"><p className="text-center alert alert-warning">No Result Yet <i className="fa fa-warning"/></p></div>
                                        }

                                        {
                                            this.state.hideResultCard ? "" :
                                                this.state.testData.resultEnteredName !== "" && typeof this.state.testData.resultEnteredName !== "undefined" ?
                                                    <div className="col-12">
                                                        <div className="card mb-3">
                                                            <div className="card-header">
                                                                Result Data
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>S/N</th>
                                                                            <th>Item</th>
                                                                            <th>SI Unit</th>
                                                                            <th>Reference Interval</th>
                                                                            <th>Result</th>
                                                                            <th>Decision</th>
                                                                        </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                        {
                                                                            this.state.testData.specimen[0].items.map((item, index) => {
                                                                                return <tr key={index}>
                                                                                    <td>{(index+1)}</td>
                                                                                    {
                                                                                        item.ref_interval === 'single' ?
                                                                                            <>
                                                                                                <td>{item.item_name}</td>
                                                                                                <td>{item.si_unit}</td>
                                                                                                <td>{item.ref_interval_min} - {item.ref_interval_max}</td>
                                                                                                <td>{item.result}</td>
                                                                                                <td>
                                                                                                    {
                                                                                                        item.result_type === 'integer' ?
                                                                                                            item.result >= item.ref_interval_min && item.result <= item.ref_interval_max  ?
                                                                                                                <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                            : '--'
                                                                                                    }
                                                                                                </td>
                                                                                            </> :
                                                                                            <>
                                                                                                <td>{item.item_name}</td>
                                                                                                <td>{item.si_unit}</td>
                                                                                                <td colSpan={3}>
                                                                                                    {
                                                                                                        item.ref_data.map((ref, index_val) => {
                                                                                                            if (ref.ref_counter === 'single') {
                                                                                                                return <div className="table-responsive"><table className="table table-striped" key={index_val}>
                                                                                                                    <thead>
                                                                                                                    <tr>
                                                                                                                        <th>Item</th>
                                                                                                                        <th>Reference Interval</th>
                                                                                                                        <th>Result</th>
                                                                                                                        <th>Decision</th>
                                                                                                                    </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                    <tr>
                                                                                                                        <td>{ref.ref_name}</td>
                                                                                                                        <td>{ref.ref_interval_min} - {ref.ref_interval_max}</td>
                                                                                                                        <td>{ref.result}</td>
                                                                                                                        <td>
                                                                                                                            {
                                                                                                                                ref.result_type === 'integer' ?
                                                                                                                                    ref.result >= ref.ref_interval_min && ref.result <= ref.ref_interval_max  ?
                                                                                                                                        <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                                        <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                                                    : '--'
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    </tbody>
                                                                                                                </table></div>

                                                                                                            }
                                                                                                            else {
                                                                                                                return <div key={index_val}>
                                                                                                                    <h4>{ref.ref_title}</h4>
                                                                                                                    <div className="table-responsive"><table className="table table-striped">
                                                                                                                        <thead>
                                                                                                                        <tr>
                                                                                                                            <th>Item</th>
                                                                                                                            <th>Reference Interval</th>
                                                                                                                            <th>Result</th>
                                                                                                                            <th>Decision</th>
                                                                                                                        </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                        {
                                                                                                                            ref.ref_item.map((pp, ii) => {
                                                                                                                                return <tr key={ii}>
                                                                                                                                    <td>{pp.ref_name}</td>
                                                                                                                                    <td>{pp.ref_interval_min} - {pp.ref_interval_max}</td>
                                                                                                                                    <td>{pp.result}</td>
                                                                                                                                    <td>
                                                                                                                                        {
                                                                                                                                            pp.result_type === 'integer' ?
                                                                                                                                                pp.result >= pp.ref_interval_min && pp.result <= pp.ref_interval_max  ?
                                                                                                                                                    <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                                                    <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                                                                : '--'
                                                                                                                                        }
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                            })
                                                                                                                        }

                                                                                                                        </tbody>
                                                                                                                    </table></div>
                                                                                                                </div>
                                                                                                            }

                                                                                                        })
                                                                                                    }
                                                                                                </td>
                                                                                                <td></td>
                                                                                                <td></td>
                                                                                            </>
                                                                                    }

                                                                                </tr>
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                    {
                                                                        this.state.testData.testStatus !== 'result confirmed' && <button className="btn btn-primary btn-block" onClick={this.onConfirmResultModel}><i className="fa fa-check"/> Confirm Test Result</button>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    : <div className="col-4"><p className="text-center alert alert-warning">No Result Yet <i className="fa fa-warning"/></p></div>
                                        }
                                    </div>
                                </div>
                            </TabPanel>

                            {/*Visit Note*/}
                            <TabPanel>
                                <div>
                                    {
                                        this.state.visitNotes.length > 0 ?

                                            <Accordion>
                                                {
                                                    this.state.visitNotes.map((p, index) => {
                                                        return <AccordionItem key={index}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Doctor: {p.doctorName} => {formatDateAndTime(p.submittedOn, 'date')}
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <h5>
                                                                    Doctor: {p.doctorName}
                                                                    {/*<Link to={`#`}>*/}
                                                                    {/*    <span className="float-right">view</span>*/}
                                                                    {/*</Link>*/}
                                                                </h5>
                                                                <h4>NOTE</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Subjective</th>
                                                                            <th>Objective</th>
                                                                            <th>Assessment</th>
                                                                            <th>Plan</th>
                                                                            <th>Other 1</th>
                                                                            <th>Other 2</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note1}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note2}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note3}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note4}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note5}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note6}} /></td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    })
                                                }

                                            </Accordion>
                                            :
                                            <div className="p-3">
                                                <span className="alert alert-info">No Previous Note Found</span>
                                            </div>
                                    }
                                </div>

                            </TabPanel>

                            {/*Allergens*/}
                            <TabPanel>
                                {
                                    this.state.allergy.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Name</th>
                                                <th>Last Attack Date</th>
                                                <th>Medication</th>
                                                <th>Duration Before Subsiding</th>
                                                <th>Description</th>
                                                <th>Image</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.allergy.map((allergy, index) => {
                                                    return <tr key={allergy._id}>
                                                        <td>{index+1}</td>
                                                        <td>{allergy.allergyName}</td>
                                                        <td>{this.formatDate(allergy.lastAttackDate)}</td>
                                                        <td>{allergy.allergyMedication}</td>
                                                        <td>{allergy.durationBeforeSubsiding}</td>
                                                        <td>{allergy.allergyDescription}</td>
                                                        <td>
                                                            {allergy.reactionImage === "" ? (
                                                                <i className="fa fa-file-excel fa-2x"></i>
                                                            ) : (
                                                                <img
                                                                    className="image img-fluid"
                                                                    width="50px"
                                                                    alt=""
                                                                    srcSet={!allergy.reactionImage || allergy.reactionImage?.includes('simplefileupload') ? allergy.reactionImage : serverLink + 'public/uploads/patient_allergy/'+allergy.reactionImage}
                                                                    // onClick={this.handleViewAllergyImageClicked}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Record Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Vitals Report*/}
                            <TabPanel>
                                <PatientVitalList vitalList={this.state.vitals.sort((a, b) => new Date(b.submittedOn) - new Date(a.submittedOn))} formatDate={this.formatDate}/>
                            </TabPanel>
                        </Tabs>
                    </div>




                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(LabRequestProcessingPageHod);
