import React, {Component} from "react";
import {Link} from "react-router-dom";
import {
    setpersonDetails
} from "../../../actions/setpersondetailsaction";
import {connect} from "react-redux";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";

class PatientPaymentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            patientSerial: this.props.patientDetail.patientSerial,
        }
    }


    checkDataSet = (val) => {
        if(val === ''){
            return 'N/A';
        }else if(typeof val === 'undefined'){
            return 'N/A';
        }else{
            return val;
        }
    }

    render() {
        let count = 0;
        return <div className="card mb-3">

            <div className="card-header">
                Payment History

            </div>
            <div className="card-body table-responsive">
                {
                    typeof this.props.paymentList === 'undefined' ?
                        <div>No Payment Found!</div> :
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Payment Purpose</th>
                                <th>Payment Method</th>
                                <th>Amount Due</th>
                                <th>Amount Paid</th>
                                <th>Balance</th>
                                <th>Status</th>
                                <th>Payment Date</th>
                            </tr>
                            </thead>
                            { this.props.paymentList.length === 0 ?
                                (
                                    <tbody>
                                    <tr><td colSpan={10} className="text-center">No Payment Found</td></tr>
                                    </tbody>
                                )
                                :
                                (
                                    <tbody>
                                    {this.props.paymentList.map((payment) => {
                                        count++;
                                        return (
                                            <tr key={payment._id}>
                                                <td>{count}</td>
                                                <td>{this.checkDataSet(payment.paymentFor)}</td>
                                                <td>{this.checkDataSet(payment.paymentMethod)}</td>
                                                <td>{this.checkDataSet(payment.amountDue)}</td>
                                                <td>{this.checkDataSet(this.props.moneyFormat(payment.paymentAmount))}</td>
                                                <td>{this.checkDataSet(payment.paymentBalance)}</td>
                                                <td>{this.checkDataSet(payment.paymentStatus)}</td>
                                                <td>{this.checkDataSet(this.props.formatDate(payment.submittedOn)) }</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                )
                            }
                        </table>
                }

            </div>
        </div>
    }
}

export default PatientPaymentList;
