import React, {Component} from "react";
import "./addpatientdependant.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import { serverLink, hospitalCode } from "../../../../resources/url";
import StateData from "../../../../resources/state_and_lga.json"
import {connect} from "react-redux";
import CountryData from "../../../../resources/country.json";
import PatientDependantForm from "./addpatientdependantform";

class AddDependant extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const dateOfBirth = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();

        this.state = {
            serverLink: serverLink+"patient/",
            hospitalCode: hospitalCode,
            imageAvatar: "",
            imagefile: "",
            date: null,
            isLoading: false,
            htmlelement: <i className="fa fa-user fa-8x" aria-hidden="true"/>,

            formData: {
                title: "",
                firstName: "",
                middleName: "",
                surname: "",
                gender: "",
                mainPatientSerial: this.props.patientDetails.patientSerial,
                phoneNumber: this.props.patientDetails.phoneNumber,
                altPhoneNumber: "",
                dateOfBirth: null,
                bloodGroup: "",
                emailAddress: this.props.patientDetails.emailAddress,
                maritalStatus: "",
                religion: "",
                occupation: "",
                residentialAddress: "",
                status: "",
                nationality: "",
                state: "",
                lga: "",
                ward: "",
                zipCode: "",
                officeAddress: "",
                passport: "",
                submittedBy: "",
                nextOfKinName: "",
                nextOfKinPhone: "",
                nextOfKinRelationship: ""
            },

            stateList: [],
            lgaList: [],

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentWillMount() {
        this.stateData()
    }

    componentDidMount() {
        this.state.submittedBy = localStorage.getItem('userID');
    }

    stateData = () => {
        StateData.map(state => {
            this.state.stateList.push(state.state)
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddPatient();
    };

    async onAddPatient() {
        if(this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Title"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.firstName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient First Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.surname === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient Surname"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.gender === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Gender"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.dateOfBirth === "" || this.state.formData.dateOfBirth === null) {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Date of Birth"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.bloodGroup === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Blood Group"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.maritalStatus === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Marital Status"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.religion === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Religion"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.nextOfKinName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient Next of Kin Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.nextOfKinPhone === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Patient Next of Kin Phone Number"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.nextOfKinRelationship === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Next of Kin Relationship"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.residentialAddress === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Residential Address"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.nationality === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient Nationality"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.state === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient State"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.lga === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Patient LGA"
            this.handleErrorDialog();
            return false;
        }

        if (this.state.imagefile !== "") {
            const formData = new FormData();
            formData.append('passport', this.state.imagefile);

            await axios.post(`${this.state.serverLink}uploadPatientPassport`)
                .then(res => { // then print response status
                    if(res.status === 200) {
                        const formData = this.state.formData;
                        this.setState({
                            formData: {
                                ...formData,
                                passport: res.data.passport
                            }
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        isLoading: false,
                    });
                    this.state.messageDescription = "Patient passport is not uploaded. Please try again!"
                    this.handleErrorDialog();
                    return false;
                })
        }

        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy
        };

        axios.post(`${this.state.serverLink}dependant/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "error") {
                    this.state.messageDescription = "Something went wrong adding the dependent. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.state.messageDescription = "PATIENT ID: "+result.data.patient_id;
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to upload the patient image!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    handleChange = (date) => {
        if (date !== null) {
            const birthDate = new Date(date);

            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfBirth: `
                    ${birthDate.getFullYear()}-
                    ${birthDate.getMonth() + 1}-
                    ${birthDate.getDate()}
                    `,
                },

                startDate: date,
            });
        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfBirth: date,
                },

                startDate: date,
            });
        }
    };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                imagefile: event.target.files[0],
            });

            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    imageAvatar: e.target.result,
                    htmlelement: (
                        <div className="addpersonpage">
                            <img
                                className="netimage"
                                srcSet={e.target.result}
                                alt="profileImage"
                            />
                        </div>
                    ),
                });
            };
            reader.readAsDataURL(event.target.files[0]);
            this.setState({});
        }
    };
    onImageRemove = () => {
        this.setState({
            imagefile: "",
            imageAvatar: "",
            htmlelement: (
                <div className="addpersonpage">
                    <i className="fa fa-user fa-8x" aria-hidden="true"/>
                </div>
            ),
        });
    };
    onEdit = (e) => {
        const formData = this.state.formData;

        if (e.target.name === 'nationality') {
            this.state.stateList = [];
            this.state.lgaList = [];
            this.state.formData.state = '';
            if (e.target.value === 'Nigeria') {
                this.stateData();
            } else {
                StateData.map(state => {
                    if (state.state === 'Non-Nigerian')
                        this.state.stateList.push(state.state)
                });
            }
        }


        if(e.target.name === 'state') {
            this.state.lgaList = [];
            StateData.map(state => {
                if(state.state === e.target.value) {
                    state.lgas.map(lga => {
                        this.state.lgaList.push(lga)
                    })
                }
            })
        }
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/patient/profile';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        const today = new Date();
        const dateOfBirth = `${today.getFullYear()}-${(today.getMonth()+1) < 10 ? '0'+(today.getMonth()+1) : (today.getMonth()+1)}-${today.getDate() < 10 ? '0'+today.getDate() : today.getDate()}`;
        return this.state.isLoading ? (
            <div className="addpersonpage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="addpersonpage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Patient Dependent Added"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <PatientDependantForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title={`Add Dependant for ${this.props.patientDetails.firstName} ${this.props.patientDetails.middleName} ${this.props.patientDetails.surname}`}
                                date={this.state.date}
                                htmlelement={this.state.htmlelement}
                                handleChange={this.handleChange}
                                onImageRemove={this.onImageRemove}
                                onImageChange={this.onImageChange}
                                stateList={this.state.stateList}
                                lgaList={this.state.lgaList}
                                nationalityList={CountryData}
                                dateOfBirth={dateOfBirth}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        patientDetails: state.patientDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(AddDependant);

