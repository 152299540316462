import React, { Component } from "react";
import "./labform.css";
import Navigator from "../../../Settings/navigation/navigation";

class AddLabForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="laboratoryName">Laboratory Name <span className="text-danger">*</span></label>
                                            <input
                                                name="laboratoryName"
                                                type="text"
                                                className="form-control"
                                                id="laboratoryName"
                                                value={this.props.data.laboratoryName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="labType">Laboratory Type <span className="text-danger">*</span></label>
                                            <input
                                                name="labType"
                                                type="text"
                                                className="form-control"
                                                id="labType"
                                                value={this.props.data.labType}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="block">Block Name <span className="text-danger">*</span> </label>
                                            <select
                                                name="block"
                                                id="block"
                                                className="custom-select"
                                                value={this.props.data.block}
                                                onChange={this.props.onEdit}>
                                                <option>Select Block</option>
                                                {
                                                    this.props.blockList.map((block, index) => {
                                                        return <option key={index} data={block._id} value={block.blockName}>{block.blockName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="laboratoryAddress">Laboratory Address</label>
                                            <input
                                                name="laboratoryAddress"
                                                type="text"
                                                className="form-control"
                                                id="laboratoryAddress"
                                                value={this.props.data.laboratoryAddress}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                    </div>


                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddLabForm;
