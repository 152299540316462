import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./buildingdashboard.css";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../navigation/navigation";

class BuildingDashboard extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
    }


    render() {
        return <div className="dashboardpage">
            <div className="topheader">
                <Navigator title="Building Dashboard" />
            </div>

            <div className="first_section">
                <div className="row">
                    <div className="col-sm">
                        {" "}
                        <Link to="/hospital/block/list">
                            <DashboardCard name="Blocks" icon="fa fa-house-user fa-4x" count="" />
                        </Link>
                    </div>
                    <div className="col-sm">
                        {" "}
                        <Link to="/hospital/room/type/list">
                            <DashboardCard name="Room Types" icon="fa fa-tag fa-4x" count={""} />
                        </Link>
                    </div>
                    <div className="col-sm">
                        {" "}
                        <Link to="/hospital/room/list">
                            <DashboardCard name="Rooms" icon="fa fa-bed fa-4x" count={""}/>
                        </Link>
                    </div>
                </div>


            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails
    };
};
export default connect(mapStateToProps, null)(BuildingDashboard);
