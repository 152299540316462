import React, {Component} from "react";
import {Link} from "react-router-dom";
import { serverLink, hospitalCode } from "../../../resources/url";

class PatientSearchList extends Component {
    constructor() {
        super();
        this.state = {
            serverLink: serverLink,
        }
    }

    render() {
        let count = 0;

        return <table className="table table-striped">
            <thead className="thead tablehead">
            <tr>
                <th scope="col">#</th>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Email</th>
                <th scope="col">Option</th>
            </tr>
            </thead>
            {this.props.isSearching ? (
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            ) : this.props.patientList.length === 0 ? (
                <tbody>
                <tr>
                    <td colSpan={6} className="text-center">No Patient Found</td>
                </tr>
                </tbody>
            ) : (
                <tbody className="tablebody">
                {this.props.patientList &&
                this.props.patientList.map((p) => {
                    count++;
                    return (

                        <tr key={p._id}>
                            <td className="align-middle">{count}</td>
                            <td className="align-middle">
                                {p.patientSerial}
                            </td>
                            <td className="align-middle">
                                {p.firstName + " "+ p.middleName + " " + p.surname}
                            </td>
                            <td className="align-middle">
                                {" "}
                                {p.phoneNumber === "" ? "N/A" : p.phoneNumber}
                            </td>
                            {<td className="align-middle">
                                {" "}
                                {p.emailAddress == "" ? "N/A" : p.emailAddress}
                            </td>}
                            <td className="align-middle">
                                    <button
                                        onClick={() => this.props.onChange(p)}
                                        type="button"
                                        className="btn btn-success"
                                    >
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                    </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            )}
        </table>
    }
}

export default PatientSearchList;
