import React, { Component } from "react";
import "./addpatientimagingform.css";
import SimpleFileUpload from "react-simple-file-upload";
import {uploadAPI} from "../../../resources/url";

class AddPatientImagingForm extends Component {
     onDelete = (e, item) => {
        let files = this.props.fileList
        let filter = files.filter(e => e.ImageName !== item.ImageName);
        this.props.updateFileList(filter);
    }
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="">
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientSerial">Patient ID</label>
                            <input
                                name="patientSerial"
                                type="text"
                                className="form-control"
                                id="patientSerial"
                                disabled
                                value={this.props.patientSerial}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientName">Patient Name</label>
                            <input
                                name="patientName"
                                type="text"
                                className="form-control"
                                id="patientName"
                                disabled
                                value={this.props.patientName}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mb-3" >
                        <label htmlFor="imageFiles" className="selectimage">Upload Result</label>
                        <div className="mb-3">
                            <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                    document.getElementById("photo").click()
                                }}>
                                    <div className="row">
                                        <i className="bi bi-image text-primary fs-3x"/>
                                        <div className="ms-4 col-md-9">
                                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                            <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .pdf, .jpg, .png, .jpeg are allowed"}</span>
                                        </div>
                                        <div className="col-md-2">{this.props.htmlelement !== "" ? <img className="img-thumbnail" width={350} height={350} srcSet={this.props.htmlelement !== "" ? this.props.htmlelement : ""}/> : ""}</div>
                                    </div>
                                </div>
                            </div>
                            <span className="alert-danger"> Max of 5MB file is allowed!</span>
                        </div>
                        <input type="file"  id="photo"  name={"photo"}  onChange={this.props.onImageChange} hidden multiple />
                    </div>

                </div>
                {
                    <div className="form-group col-md-12">
                        <div className="row">
                            {
                                this.props.fileList.map((item, index)=>{
                                    return  <div key={index} className="form-group mb-1 col-md-12">
                                        <div className='border rounded p-2'>
                                            <div className='d-flex flex-column flex-md-row'>
                                                <span className="fa fa-file fa-2x"></span>
                                                <div className="w-100">
                                                    <small className='text-muted' style={{marginLeft: '15px'}}>{item.ImageName}</small>

                                                    <p className='ml-4'>
                                                        <a href='#' onClick={e => e.preventDefault()}>
                                                            {`${item.ImageSize} MB`}
                                                        </a>
                                                    </p>
                                                </div>
                                                <div className="float-right">
                                                    <button type="button" className='btn btn-danger btn-sm float-end'
                                                            onClick={(e) => this.onDelete(e, item)}><span className="fa fa-trash"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                }

                <button className="btn btn-success btn-block savebtn" id="SubmissionBtn" type="submit">
                    Save
                </button>
            </form>
        );
    }
}

export default AddPatientImagingForm;
