import React, { Component } from "react";
import "./manufacturerlist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails, setReportDetails
} from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import { serverLink, hospitalCode } from "../../../../resources/url";
import {loginValidation} from "../../../../context/loginvalidation";
import Navigator from "../../../Settings/navigation/navigation";
import DataTable from "../../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../../resources/constants";

class ManufacturerList extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"pharmacy/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 20,

            isLoadMoreData: false,

            totalNumOfManufacturers: null,
            noMoreDataText: "",

            manufacturerList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedManufacturerName: "",
            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchManufacturers(this.state.limit);
    }

    async fetchManufacturers(limit) {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}manufacturer/all/${this.state.hospitalCode}/${limit}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    manufacturerList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };


    showTable = () => {

        try {
            return this.state.manufacturerList.map((m, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{m.manufacturerName}</td>
                        <td>{m.description === "" ? "N/A" : m.description }</td>
                        <td>{m.phoneNumber === "" ? "N/A" : m.phoneNumber }</td>
                        <td>{m.emailAddress === "" ? "N/A" : m.emailAddress }</td>
                        <td>{m.address === "" ? "N/A" : m.address }</td>
                        <td>
                            <Link to="/pharmacy/drug/manufacturer/update">
                                <button
                                    onClick={async () => {
                                        const ManufacturerData = {
                                            ...m,
                                            manufacturerId: m._id,
                                        };
                                        this.props.setOnsetReportDetails(ManufacturerData);
                                    }}
                                    type="button"
                                    className="btn btn-success"
                                >
                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </Link>
                        </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    render() {
        const header = ["S/N", "Manufacturer Name",  "Block Description", "Phone Number", "Email Address", "Address", "Option"];
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Manufacturer" />
                </div>
                <hr />
            </div>

            <ConfirmDialogBox
                openDailog={this.state.openConfirmDialog}
                onSetOpenDailog={this.closeConfirmDialog}
                handleConfirmOkBtn={this.deleteSpecialisation}
                isLoading={this.state.isDeleting}
                title="Delete"
                des={
                    "Are you sure to delete " +
                    this.state.selectedManufacturerName +
                    " " +
                    "details"
                }
            />

            <ErrorDialogBox
                openDialog={this.state.openErrorDialog}
                onSetOpenDialog={this.closeErrorDialog}
                title="Record Not Found"
                des="Manufacturer record not found. Please try again!"
            />

            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h3 className="card-title">Manufacturer List</h3>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    <Link to="/pharmacy/drug/manufacturer/add">
                                        <button
                                            type="button"
                                            className="btn btn-warning"
                                        >
                                            + Add Manufacturer
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')} body={this.showTable()} title={`Manufacturer Report`} />
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnsetReportDetails: (p) => {
            dispatch(setReportDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerList);
