import React, { Component } from "react";
import "./addmedicalrecordform.css";
import SimpleFileUpload from "react-simple-file-upload";
import {uploadAPI} from "../../../resources/url";

class AddMedicalRecordForm extends Component {

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="">
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientSerial">Patient ID</label>
                            <input
                                name="patientSerial"
                                type="text"
                                className="form-control"
                                id="patientSerial"
                                disabled
                                value={this.props.patientSerial}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientName">Patient Name</label>
                            <input
                                name="patientName"
                                type="text"
                                className="form-control"
                                id="patientName"
                                disabled
                                value={this.props.patientName}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label htmlFor="title">Title <span className="text-danger">*</span></label>
                            <input
                                name="title"
                                type="text"
                                className="form-control"
                                id="title"
                                value={this.props.data.title}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                            name="description"
                            className="form-control"
                            id="description"
                            defaultValue={this.props.data.description}
                            onChange={this.props.onEdit}
                            rows="3"
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mb-3" >
                        <label htmlFor="imageFiles" className="selectimage">Upload </label>
                        <div className="mb-3">
                            <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                    document.getElementById("photo").click()
                                }}>
                                    <div className="row">
                                        <i className="bi bi-image text-primary fs-3x"/>
                                        <div className="ms-4 col-md-9">
                                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                            <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .pdf, .jpg, .png, .jpeg are allowed"}</span>
                                        </div>
                                        <div className="col-md-2">{this.props.htmlelement !== "" ? <img className="img-thumbnail" width={350} height={350} srcSet={this.props.htmlelement !== "" ? this.props.htmlelement : ""}/> : ""}</div>
                                    </div>
                                </div>
                            </div>
                            <span className="alert-danger"> Max of 1MB file is allowed!</span>
                        </div>
                        <input type="file"  id="photo" name={"photo"}  accept={"image/*, .pdf"} onChange={this.props.onImageChange} hidden />
                    </div>
                </div>

                <button className="btn btn-success btn-block savebtn" id="SubmissionBtn" type="submit">
                    Save
                </button>
            </form>
        );
    }
}

export default AddMedicalRecordForm;
