import React, { Component } from "react";
import "./blocklist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails
} from "../../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../../../DialogBoxes/confirmDialogbox";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import Navigator from "../../../navigation/navigation";

class BlockList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"hospital_block/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 20,

            isLoadMoreData: false,

            totalNumOfBlocks: null,
            noMoreDataText: "",

            blockList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedBlockName: "",

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchBlocks(this.state.limit);
    }

    async fetchBlocks(limit) {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}/${limit}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    blockList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    showMoreBlocks = () => {
        if (this.state.limit <= this.state.totalNumOfPatient) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchBlocks(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Blocks",
            });
        }
    };



 
    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase();

            await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            blockList: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    formatMoney = (amount) => {
        // Create our number formatter.
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <ConfirmDialogBox
                        openDailog={this.state.openConfirmDialog}
                        onSetOpenDailog={this.closeConfirmDialog}
                        handleConfirmOkBtn={this.deleteSpecialisation}
                        isLoading={this.state.isDeleting}
                        title="Delete"
                        des={
                            "Are you sure to delete " +
                            this.state.selectedBlockName +
                            " " +
                            "details"
                        }
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Block record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Block" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search block by name"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.blockList.length}{" "}
                                </li>
                            </ul>
                        </div>

                        <Link to="/hospital/block/add">
                            <button
                                type="button"
                                className="btn btn-warning"
                            >
                                + Add Block
                            </button>
                        </Link>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Block Name</th>
                            <th scope="col">Extension Number</th>
                            <th scope="col">Block Description</th>
                            <th scope="col">Option</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "></i>
                        ) : this.state.blockList.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Block Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.blockList &&
                            this.state.blockList.map((p) => {
                                count++;
                                return (
                                    <tr key={p._id}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {p.blockName}
                                        </td>
                                        <td className="align-middle">
                                            {p.extensionNo === "" ? "N/A" : p.extensionNo }
                                        </td>
                                        <td className="align-middle">
                                            {p.description === "" ? "N/A" : p.description }
                                        </td>
                                        <td className="align-middle">
                                            <Link to="/hospital/block/update">
                                                <button
                                                    onClick={async () => {
                                                        const blockData = {
                                                            ...p,
                                                            blockId: p._id,
                                                        };
                                                        this.props.setOnPatientDetails(blockData);
                                                    }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>

                    <div className="loadmoredatasection">
                        {this.state.isLoadMoreData ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.blockList.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMoreBlocks()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, mapDisptachToProps)(BlockList);
