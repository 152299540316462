import React, {Component} from "react";
import "./update-item-sub-category.css";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import { connect } from "react-redux";
import {showAlert} from "../../../../Common/sweetalert/sweetalert";
import ItemSubCategoryForm from "../item-sub-category-form/item-sub-category-form";

class UpdateItemSubCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.itemData._id,
                categoryName:  this.props.itemData.categoryName,
                subCategoryName:  this.props.itemData.subCategoryName,
                oldSubCategoryName: this.props.itemData.subCategoryName,
                description: this.props.itemData.description,
                hospitalCode: hospitalCode,
                updatedBy: ""
            },
            updatedBy: ""
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: this.props.loginDetails.fullName
        });
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdate();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdate() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy
        };

        if(this.state.formData.categoryName.trim() === "") {
            this.setState({
                isLoading: false,
            });
            showAlert("EMPTY FIELD", "Please Enter the Item Category Name", "error");
            return false;
        }


        if(this.state.formData.subCategoryName.trim() === "") {
            this.setState({
                isLoading: false,
            });
            showAlert("EMPTY FIELD", "Please Enter the Item Sub Category Name", "error");
            return false;
        }



        axios.patch(`${this.state.serverLink}finance/item/category/sub/update`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    showAlert("SUB CATEGORY UPDATED ", "ITEM SUB CATEGORY UPDATED SUCCESSFULLY", "success").then(()=>{
                        window.location.href = '/finance/inventory/item/category/sub';
                    });
                    return false;
                } else {
                    showAlert("ERROR", "Something went wrong adding the item category. Please try again!", "error");
                    return false;
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                showAlert("ERROR", "Request not submitted. Check your console for more info", "error");
                return false;
            });
    }

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <ItemSubCategoryForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Item Sub Category"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        itemData: state.generalDetails,
        loginDetails: state.loginDetails
    };
};
export default connect(mapStateToProps, null)(UpdateItemSubCategory);
