import React, {Component} from "react";
import "./adddepartment.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import DepartmentForm from "../departmentform/departmentform";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";


class AddDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"department/",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                departmentName: "",
                departmentDescription: "",
                headOfDepartmentID: "",
                headOfDepartmentName: "",
                emailAddress: "",
                phoneNumber: "",
                extensionNo: "",
                submittedBy: ""
            },

            userList: [],

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.state.submittedBy = localStorage.getItem('userID');
        this.onGetStaffList();
    }

    onGetStaffList () {
        axios.get(`${serverLink}user/all/${this.state.hospitalCode}/1000`, this.props.doctorDetails.token)
            .then(staff => {
                this.setState({
                    userList: staff.data
                })
            })
            .catch(error => {
                console.log(error)

            })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddDepartment();
    };


    async onAddDepartment() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy
        };

        if(this.state.formData.departmentName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Department Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.headOfDepartmentID === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Department Head"
            this.handleErrorDialog();
            return false;
        }

        axios.post(`${this.state.serverLink}add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "department exist") {
                    this.state.messageDescription = "A department with the same name already exist";
                    this.handleErrorDialog();
                } else if (response === "error") {
                    this.state.messageDescription = "Something went wrong adding the department. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.state.messageDescription = "DEPARTMENT ADDED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to upload the department details!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;

        if (e.target.name === 'headOfDepartmentID') {
            let index = e.nativeEvent.target.selectedIndex;
            this.state.formData.headOfDepartmentName = e.nativeEvent.target[index].text
        }

        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/department/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="adddepartmentpage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="adddepartmentpage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Department Added"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <DepartmentForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                userList={this.state.userList}
                                title="Add Department"
                            ></DepartmentForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AddDepartment);
