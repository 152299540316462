import React, { Component } from "react";
import "./patientotherinfoform.css";

class PatientOtherInfoForm extends Component {

    render() {
        let otherInfo = [];
        if (typeof(this.props.otherInfo.otherInfo) === 'undefined') {
            otherInfo = [];
        }else if (this.props.otherInfo.otherInfo.length > 0){
            otherInfo = this.props.otherInfo.otherInfo;
        }else{
            otherInfo = [];
        }
        return (
<div className="basepage">
<div className="container main_section">
    {
        otherInfo.length > 0 ? <h6 className="text-center text-success">Other information have been captured</h6> :
            <div className="row">
                <div className="col-sm">
                    <form onSubmit={this.props.handleSubmit}>
                        <div className="">
                            <h6 style={{color: '#11283f', fontWeight: 'bold'}}>Family History</h6>
                            <hr/>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="familyType">Type of family </label>
                                    <select
                                        name="familyType"
                                        id="familyType"
                                        className="custom-select"
                                        value={this.props.data.familyType}
                                        onChange={this.props.onEdit}>
                                        <option>Select type of family</option>
                                        <option value="Monogamous">Monogamous</option>
                                        <option value="Polygamous">Polygamous</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="parentChildren">No. of children Parent have </label>
                                    <input
                                        name="parentChildren"
                                        type="text"
                                        className="form-control"
                                        id="parentChildren"
                                        value={this.props.parentChildren}
                                        onChange={this.props.onEdit}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="positionInFamily">Position in the family </label>
                                    <input
                                        name="positionInFamily"
                                        type="text"
                                        className="form-control"
                                        id="positionInFamily"
                                        value={this.props.positionInFamily}
                                        onChange={this.props.onEdit}
                                    />
                                </div>
                            </div>

                            <hr/>
                            <h6 style={{color: '#11283f', fontWeight: 'bold'}}>Social History</h6>
                            <hr/>
                            <div className="form-row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="activities">Activities</label>
                                    <input
                                        name="activities"
                                        type="text"
                                        className="form-control"
                                        id="activities"
                                        value={this.props.activities}
                                        onChange={this.props.onEdit}
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="exercise">Exercise </label>
                                    <select
                                        name="exercise"
                                        id="exercise"
                                        className="custom-select"
                                        value={this.props.data.exercise}
                                        onChange={this.props.onEdit}>
                                        <option>Select exercise level</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Yearly">Yearly</option>
                                        <option value="None">None</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="lifestyle">Life Style </label>
                                    <input
                                        name="lifestyle"
                                        type="text"
                                        className="form-control"
                                        id="lifestyle"
                                        value={this.props.lifestyle}
                                        onChange={this.props.onEdit}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="leisureTime">Leisure Time</label>
                                    <input
                                        name="leisureTime"
                                        type="text"
                                        className="form-control"
                                        id="activities"
                                        value={this.props.leisureTime}
                                        onChange={this.props.onEdit}
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="smoking">Do you smoking? </label>
                                    <select
                                        name="smoking"
                                        id="smoking"
                                        className="custom-select"
                                        value={this.props.data.smoking}
                                        onChange={this.props.onEdit}>
                                        <option>Select option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="alcohol">Do you drink alcohol? </label>
                                    <select
                                        name="alcohol"
                                        id="alcohol"
                                        className="custom-select"
                                        value={this.props.data.alcohol}
                                        onChange={this.props.onEdit}>
                                        <option>Select option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="diet">Diet</label>
                                    <select
                                        name="diet"
                                        id="diet"
                                        className="custom-select"
                                        value={this.props.data.diet}
                                        onChange={this.props.onEdit}>
                                        <option>Select your diet</option>
                                        <option value="Atkins">Atkins</option>
                                        <option value="Zone">Zone</option>
                                        <option value="Ketogenic">Ketogenic</option>
                                        <option value="Vegetarian">Vegetarian</option>
                                        <option value="Vegan">Vegan</option>
                                        <option value="Weight Watchers">Weight Watchers</option>
                                        <option value="South Beach">South Beach</option>
                                        <option value="Raw Food">Raw Food</option>
                                        <option value="Mediterranean">Mediterranean</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="hobbies">Hobbies </label>
                                    <input
                                        name="hobbies"
                                        type="text"
                                        className="form-control"
                                        id="hobbies"
                                        value={this.props.hobbies}
                                        onChange={this.props.onEdit}
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="educationLevel">Education Level </label>
                                    <select
                                        name="educationLevel"
                                        id="educationLevel"
                                        className="custom-select"
                                        value={this.props.data.educationLevel}
                                        onChange={this.props.onEdit}>
                                        <option>Select education level</option>
                                        <option value="Primary School">Primary School</option>
                                        <option value="Secondary School">Secondary School</option>
                                        <option value="Tertiary Institution">Tertiary Institution</option>
                                        <option value="None">None</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <hr/>
                        <h6 style={{color: '#11283f', fontWeight: 'bold'}}>Previous Medical History</h6>
                        <hr/>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="medicalCondition">Have you had any medical condition before?</label>
                                <select
                                    name="medicalCondition"
                                    id="medicalCondition"
                                    className="custom-select"
                                    value={this.props.data.medicalCondition}
                                    onChange={this.props.onEdit}>
                                    <option>Select option</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="exercise">Have you ever been admitted in the hospital? </label>
                                <select
                                    name="previousAdmission"
                                    id="previousAdmission"
                                    className="custom-select"
                                    value={this.props.data.previousAdmission}
                                    onChange={this.props.onEdit}>
                                    <option>Select option</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>

                        </div>

                        <button className="btn btn-success btn-block savebtn" id="SubmissionBtn" type="submit">
                            Save
                        </button>
                    </form>
                </div>
            </div>
    }
</div>
</div>
        );
    }
}

export default PatientOtherInfoForm;
