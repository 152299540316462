import React, { Component } from 'react';
import { serverLink, hospitalCode } from "../../../resources/url";
import axios from "axios";
import {connect} from "react-redux";

class ChoosePayment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            package: [],
            insurance: [],
            paymentAmount: '',
            paymentMethod: '',
            messageType: '',
            errorMessage: '',
            isPackageSelected: false,
            isInsuranceSelected: false,
            packageDescription: '',
            insuranceDescription: '',
            selectedItemID: '',
            selectedItemName: ''
        }
        this.getPatientPackage();
    }

    //============ GET PATIENT PACKAGES STARTS ====================
    async getPatientPackage () {
        await axios.get(`${serverLink}hospital_package/patient_active_package/${this.props.patientID}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0)
                    this.setState({
                        package: data
                    })
            })
            .catch(error => {
                this.setState({
                    messageType: 'error',
                    errorMessage: 'Error connecting to server to fetch package details'
                });
            })
    }
    //============ GET PATIENT PACKAGES END ====================

    handleChange = (e) => {
        this.setState({
            messageType: '',
            isPackageSelected: false,
            isInsuranceSelected: false,
            packageDescription: '',
            insuranceDescription: '',
        })

        let amount = e.target.value;
        let index = e.nativeEvent.target.selectedIndex;
        let payment_method = e.nativeEvent.target[index].getAttribute("data");
        let description = e.nativeEvent.target[index].getAttribute("description");

        if (amount !== '') {
            this.setState({
                paymentAmount: amount,
                paymentMethod: payment_method
            })
        } else {
            this.setState({
                paymentAmount: '',
                paymentMethod: ''
            })
        }

        if (payment_method === 'package')
            this.setState({
                isPackageSelected: true,
                packageDescription: description,
                selectedItemID: amount,
                selectedItemName: e.nativeEvent.target[index].getAttribute("package_name")
            })
        else if (payment_method === 'insurance')
            this.setState({
                isInsuranceSelected: true
            })
    }

    handleSubmit = () => {
        const item_amount = parseInt(this.props.itemAmount);
        const paymentMethod = this.state.paymentMethod;
        let paymentAmount = 0;

        if (this.state.paymentAmount === '') {
            this.setState({
                messageType: 'error',
                errorMessage: 'Please select the payment option before submitting'
            });
            return;
        } else {
            this.setState({
                messageType: '',
                errorMessage: ''
            });
        }

        if (paymentMethod === 'Wallet') {
            if (item_amount > parseInt(this.props.walletAmount))
            {
                this.setState({
                    messageType: 'error',
                    errorMessage: 'Insufficient funds in wallet!!!'
                })
                return
            } else {
                paymentAmount = item_amount;
            }
        }

        const sendData = {
            patientSerial: this.props.patientID,
            patientName: this.props.patientName,
            paymentFor: this.props.paymentFor,
            itemName: this.props.itemName,
            paymentAmount: paymentAmount,
            paymentStatus: 'paid',
            paymentType: 'Debit',
            hospitalCode: hospitalCode,
            amountDue: item_amount,
            paymentMethod: this.state.paymentMethod,
            submittedBy: this.props.doctorDetails.userID,
            submittedByName: this.props.doctorDetails.fullName
        }

        if (this.props.paymentFor === 'Lab')
            sendData.labID = this.props.itemID
        else if (this.props.paymentFor === 'Pharmacy')
            sendData.prescriptionID = this.props.itemID
        else if (this.props.paymentFor === 'Appointment')
            sendData.appointmentID = this.props.itemID
        else if (this.props.paymentFor === 'Imaging')
            sendData.imagingID = this.props.itemID
        else if (this.props.paymentFor === 'Admission')
            sendData.admissionID = 'temp'
        else if (this.props.paymentFor === 'Service')
            sendData.requestID = this.props.itemID
        else if (this.props.paymentFor === 'Admission Discharge')
            sendData.admissionID = this.props.itemID

        if (paymentMethod === 'Wallet') {
            sendData.deductionFromID = 'Wallet'
            sendData.deductionFromName = 'Wallet'
        }else if (paymentMethod === 'package') {
            sendData.deductionFromID = this.state.selectedItemID //Package ID
            sendData.deductionFromName = this.state.selectedItemName //Package Name
        }

        this.setState({
            messageType: 'submitting'
        });

        axios.post(`${serverLink}payment/process_payment`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    this.setState({
                        messageType: 'success'
                    })

                    const object = this;
                    setTimeout(function () {
                        if (object.props.changeStatus){
                             object.props.changeStatus();
                        }
                        object.props.handlePaymentSuccess()
                    },3000)
                }

                else
                    this.setState({
                        messageType: 'error',
                        errorMessage: 'Something went wrong processing your payment. Please try again!'
                    })
            })
            .catch(error => {
                console.log("Payment failed", error)
                this.setState({
                    messageType: 'error',
                    errorMessage: 'Payment failed. Connection to the server was lost! Please try again.'
                })
            })
    }

    render() {
        return (
            <>
                {
                    this.state.messageType === 'success' ?
                        <p className="text-center"><span className="alert alert-success">Payment Successful</span></p> : ''
                }
                {
                    this.state.messageType === 'submitting' ?
                        <p className="text-center"><span className="alert alert-info">Processing payment. Please wait...</span></p> : ''
                }
                {
                    this.state.messageType === 'error' ?
                        <p className="text-center"><span className="alert alert-danger">{this.state.errorMessage}</span></p> : ''
                }
                <div className="row mb-3">
                    <select name="paymentSelectOption" id="paymentSelectOption" className="custom-select" onChange={this.handleChange}>
                        <option value="">Select Payment Option</option>
                        <option value={this.props.walletAmount} data="Wallet">Wallet ({this.props.MoneyConverter(this.props.walletAmount)})</option>
                        {
                            this.state.package.length > 0 ?
                                this.state.package.map((p, index) => {
                                    return <option value={p.packageID} package_name={p.packageName}  data="package" description={p.packageDescription} key={index}>{p.packageName} ({this.props.MoneyConverter(p.amount)})</option>
                                }) : ''
                        }
                    </select>
                </div>
                {
                    this.state.isPackageSelected ?
                        <p className="text-center"><span style={{ fontSize: "16px" }} className="badge badge-primary">Description: {this.state.packageDescription}</span> </p> : ''
                }
                <div className="row">
                    {
                        this.state.messageType !== 'success' && this.state.messageType !== 'submitting' ?
                            <button className="btn btn-info btn-block" onClick={this.handleSubmit}>Pay <i className="fa fa-money" /></button>
                            : <p className="text-center"><span className="alert alert-primary">Processing!!!</span></p>
                    }
                </div>
            </>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
        permissionData: state.permissionDetails
    };
};

export default connect(mapStateToProps, null)(ChoosePayment);
