import React, { Component } from "react";
import "./manufacturer-form.css";
import Navigator from "../../../../Settings/navigation/navigation";

class ItemManufacturerForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="manufacturerName">Manufacturer Name <span className="text-danger">*</span></label>
                                            <input
                                                name="manufacturerName"
                                                type="text"
                                                className="form-control"
                                                id="manufacturerName"
                                                value={this.props.data.manufacturerName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="phoneNumber">Phone Number </label>
                                            <input
                                                name="phoneNumber"
                                                type="tel"
                                                className="form-control"
                                                id="phoneNumber"
                                                value={this.props.data.phoneNumber}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="emailAddress">Email Address </label>
                                            <input
                                                name="emailAddress"
                                                type="email"
                                                className="form-control"
                                                id="emailAddress"
                                                value={this.props.data.emailAddress}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="address">Manufacturer Location <span className="text-danger">*</span></label>
                                            <input
                                                name="address"
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                value={this.props.data.address}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="description">Description </label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                                id="description"
                                                value={this.props.data.description}
                                                onChange={this.props.onEdit}
                                                rows="3"
                                            >{this.props.data.description}</textarea>
                                        </div>
                                    </div>

                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemManufacturerForm;
