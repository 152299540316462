import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../../Dashboard/utils/dashboardcard";
import "./inventory-dashboard.css";
import Navigator from "../../../Settings/navigation/navigation";

class InventoryDashboard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="dashboardpage">
                <div className="topheader">
                    <Navigator title="Inventory Dashboard" />
                </div>


                <div className="first_section">
                    <div className="row">
                       <div className="col-sm-4">
                            <Link to="/finance/inventory/manufacturer">
                                <DashboardCard name="Manufacturer" icon={`fa fa-industry fa-4x`} count="" />
                            </Link>
                        </div>
                        <div className="col-sm-4">
                            <Link to="/finance/inventory/vendor">
                                <DashboardCard name="Vendor" icon={`fa fa-store fa-4x`} count="" />
                            </Link>
                        </div>
                        <div className="col-sm-4">
                            <Link to="/finance/inventory/item/category">
                                <DashboardCard name="Category" icon={`fa fa-file fa-4x`} count="" />
                            </Link>
                        </div>
                        <div className="col-sm-4">
                            <Link to="/finance/inventory/item/category/sub">
                                <DashboardCard name="Sub Category" icon={`fa fa-file-o fa-4x`} count="" />
                            </Link>
                        </div>
                        <div className="col-sm-4">
                            <Link to="/finance/inventory/item">
                                <DashboardCard name="Item" icon={`fa fa-bars fa-4x`} count="" />
                            </Link>
                        </div>
                        <div className="col-sm-4">
                            <Link to="/finance/inventory/location">
                                <DashboardCard name="Location" icon={`fa fa-arrow-circle-o-right fa-4x`} count="" />
                            </Link>
                        </div>
                        <div className="col-sm-6">
                            <Link to="/finance/inventory/dashboard">
                                <DashboardCard name="Inventory" icon={`fa fa-warehouse fa-4x`} count="" />
                            </Link>
                        </div>
                        <div className="col-sm-6">
                            <Link to="/finance/inventory/maintenance">
                                <DashboardCard name="Maintenance" icon={`fa fa-cogs fa-4x`} count="" />
                            </Link>
                        </div>



                    </div>
                </div>
            </div>
        )
    }
}
export default InventoryDashboard;

