import React, {Component, useState} from "react";
import "./servicespermission.css";
import Navigator from "../../navigation/navigation";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import axios from "axios";
import {serverLink, hospitalCode} from "../../../../resources/url";
import FormPrompt from "../../../DialogBoxes/formprompt";
import {connect} from "react-redux";
import {setServiceDetails} from "../../../../actions/setpersondetailsaction";

class ServicePermission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            dataSet: [],
            columns: [
                {
                    name: "Service Name",
                    selector: row => `${row.serviceName}`,
                    sortable: true,
                    width: '30%'
                },
                {
                    name: "Service Description",
                    selector: row => `${row.serviceDescription !== "" ? row.serviceDescription : '--' }`,
                    sortable: true
                },
                {
                    name: "Added By",
                    selector: row => `${row.submittedBy}`,
                    sortable: true
                },
                {
                    name: "Added On",
                    selector: row => `${row.submittedOn}`,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        row.permissionID === "--" ?
                            <button onClick={async () => {this.onAddPermission(row)}}
                                    type="button"
                                    className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-plus text-white" aria-hidden="true"/>
                            </button> :
                            <button
                                onClick={() => this.onRemovePermission(row)}
                                type="button"
                                className="btn btn-danger btn-sm"
                            >
                                <i className="fa fa-times" aria-hidden="true"/>
                            </button>

                    ),
                }
            ],

        }
    }

    componentDidMount() {
        this.getServiceList();
    }

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const day = user_date.getDate() < 10 ? "0"+user_date.getDate() : user_date.getDate();
            const hours = user_date.getHours() < 10 ? "0"+user_date.getHours() : user_date.getHours();
            const min = user_date.getMinutes() < 10 ? "0"+user_date.getMinutes() : user_date.getMinutes();
            const second = user_date.getSeconds() < 10 ? "0"+user_date.getSeconds() : user_date.getSeconds();

            return `${day}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} : ${hours}:${min}:${second}`
        } else {
            return '--'
        }
    };

    getServiceList = async () => {
        await axios.get(`${serverLink}service/all/${hospitalCode}`, this.props.doctorDetails.token)
            .then(response => {
                this.setState({
                    data: response.data
                })
            })
            .catch(error => {
                console.log("SERVICES NETWORK ERROR", error)
            })

        axios.get(`${serverLink}service/service_group_permission/${this.props.groupData._id}`, this.props.doctorDetails.token)
            .then(response => {
                if (this.state.data.length > 0) {
                    let result_set = [];
                    this.state.data.map(result => {
                        const permission_list = response.data;
                        const record = permission_list.filter(item => item.serviceID === result._id);
                        result_set.push({
                            serviceID: result._id,
                            serviceName: result.serviceName,
                            serviceDescription: result.serviceDescription,
                            submittedBy: record.length > 0 ? record[0].submittedBy : '--',
                            submittedOn: record.length > 0 ? this.formatDateAndTime(record[0].submittedOn) : '--',
                            groupID: this.props.groupData._id,
                            groupName: this.props.groupData.groupName,
                            permissionID: record.length > 0 ? record[0]._id : '--',
                        })
                    })
                    this.setState({
                        isLoading: false,
                        dataSet: result_set
                    })
                }

            })
            .catch(error => {
                console.log("SERVICES PERMISSION NETWORK ERROR", error)
            })
    }

    onAddPermission = (e) => {
        const sendData = {
            serviceID: e.serviceID,
            serviceName: e.serviceName,
            groupID: this.props.groupData._id,
            groupName: this.props.groupData.groupName,
            hospitalCode: hospitalCode,
            submittedBy: this.props.doctorDetails.fullName
        }

        axios.post(`${serverLink}service/add_service_permission`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    this.getServiceList();
                }
            })
            .catch(error => {
                console.log("NETWORK ERROR ADDING PERMISSION", error)
            })
    }

    onRemovePermission = (e) => {
        axios.delete(`${serverLink}service/permission/delete/${e.permissionID}`, this.props.doctorDetails.token)
            .then(message => {
                if (message.data.message === 'success')
                    this.getServiceList();
            })
            .catch(error => {
                console.log("NETWORK ERROR", error)
            })
    }

    render() {
        const columns = this.state.columns;
        const data = this.state.dataSet;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search service'
        };

        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">

                    <div className="topheader">
                        <Navigator title={`${this.props.groupData.groupName} Service Permission`} />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap"/>
                    </div>
                    <hr />
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }

}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
        groupData: state.reportDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnServiceDetails: (p) => {
            dispatch(setServiceDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePermission);
