import React, {Component} from "react";
import {connect} from "react-redux";
import "./grouppermission.css";
import axios from "axios";
import {serverLink, hospitalCode} from "../../../../../resources/url";
import 'react-data-table-component-extensions/dist/index.css';
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import Navigator from "../../../navigation/navigation";

class GroupPermission extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            groupPermission: [],
            mainMenuList: [],
            subMenuList: [],
            openErrorDialog: false,
            openAlertDialog: false,
            messageDescription: '',

        }
    }

    componentDidMount() {
        this.getPermissionData();
    }

    async getPermissionData () {
        await axios.get(`${serverLink}settings/group/permission/all/${hospitalCode}/${this.props.groupData.groupName}`, this.props.loginData.token)
            .then(result => {
                this.setState({groupPermission: result.data})
            })
            .catch(error => {
                console.log("ERROR FETCHING GROUP PERMISSION")
            });

        await axios.get(`${serverLink}settings/main_menu/all/${hospitalCode}`, this.props.loginData.token)
            .then(result => {
                this.setState({mainMenuList: result.data})
            })
            .catch(error => {
                console.log("ERROR FETCHING MAIN MENU")
            });

        await axios.get(`${serverLink}settings/sub_menu/all/${hospitalCode}`, this.props.loginData.token)
            .then(result => {
                this.setState({subMenuList: result.data, isLoading: false})
            })
            .catch(error => {
                console.log("ERROR FETCHING USERS")
            });

    }

    onAddPermission = (row) => {
        let group_permission = JSON.parse(row.target.id);
        let sendData = {
            groupName: this.props.groupData.groupName,
            hospitalCode: hospitalCode,
            submittedBy: `${this.props.loginData.firstName} ${this.props.loginData.middleName} ${this.props.loginData.surname}`
        }

        if (!group_permission.subMenuName) {
            //Main Menu Permission
            sendData.mainMenuName = group_permission.menuName
            sendData.mainMenuIcon = group_permission.menuIcon
            sendData.mainMenuLink = group_permission.menuLink
            sendData.subMenuName = null
            sendData.subMenuLink = null
            sendData.subMenuIcon = null

        } else {
            //Sub Menu Permission
            sendData.mainMenuName = group_permission.mainMenuName
            sendData.mainMenuIcon = null
            sendData.mainMenuLink = null
            sendData.subMenuName = group_permission.subMenuName
            sendData.subMenuLink = group_permission.subMenuLink
            sendData.subMenuIcon = group_permission.subMenuIcon
        }

        // this.setState({isLoading: true})

        axios.post(`${serverLink}settings/group/permission/add`, sendData, this.props.loginData.token)
            .then(response => {
                this.setState({isLoading: false})

                if (response.data.message === 'success')
                    this.setState({
                        // openAlertDialog: true,
                        messageDescription: `${this.props.groupData.groupName} Permission added successfully`
                    })
                else
                    this.setState({
                        openErrorDialog: true,
                        messageDescription: `${this.props.groupData.groupName} Permission not added, reason ==> ${response.data.message}`
                    })

                this.getPermissionData();
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    openErrorDialog: true,
                    messageDescription: `${this.props.groupData.groupName} Permission not added`
                })
            })

    }

    onRemovePermission = (row) => {
        let group_permission = JSON.parse(row.target.id);
        let itemToDelete;

        if (group_permission.menuName)
            itemToDelete = this.state.groupPermission.filter(item =>
                item.mainMenuName === group_permission.menuName && item.subMenuName === null
            )
        else
            itemToDelete = this.state.groupPermission.filter(item =>
                item.mainMenuName === group_permission.mainMenuName && item.subMenuName === group_permission.subMenuName
            )

        this.setState({isLoading: true})

        axios.delete(`${serverLink}settings/group/permission/delete/${itemToDelete[0]._id}`, this.props.loginData.token)
            .then(message => {
                this.setState({isLoading: false})

                if (message.data.message === 'success')
                    this.setState({
                        openAlertDialog: true,
                        messageDescription: `${this.props.groupData.groupName} Permission removed`
                    })
                else
                    this.setState({
                        openErrorDialog: true,
                        messageDescription: `${this.props.groupData.groupName} Permission not removed for. Reason ==> ${message.data.message}`
                    })

                this.getPermissionData();
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    openErrorDialog: true,
                    messageDescription: `${this.props.groupData.groupName} Permission not removed`
                })
            })

    }

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()} : 
                    ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}
                    
                    `
        } else {
            return '--'
        }
    };

    closeDialog = () => {
        this.setState({
            openErrorDialog: false,
            openAlertDialog: false,
            messageDescription: ''
        })
    }

    render() {

        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">
                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeDialog}
                        title="PERMISSION"
                        des={this.state.messageDescription}
                    />

                    <div className="topheader">
                        <Navigator title={`${this.props.groupData.groupName} Permission`} />
                    </div>
                    <hr />

                    {
                        this.state.mainMenuList && this.state.mainMenuList.map((menu) => {
                            //Filter sub menu based on the current menu
                            const sub_menus = this.state.subMenuList.filter(sub => sub.mainMenuName === menu.menuName);

                            //Filter the current menu to check if it exist in the permission
                            const check_menu_permission = this.state.groupPermission.filter(perm =>
                                perm.mainMenuName === menu.menuName && perm.subMenuName == null
                            )

                            return (
                                <div key={menu._id}>
                                    <h2 className="text-center">{menu.menuName}</h2>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Icon</th>
                                                <th>Menu Name</th>
                                                <th>Added By</th>
                                                <th>Added Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            menu.menuLink !== '#' ?
                                                <tr>
                                                    <td><i className={`fa ${menu.menuIcon}`}/></td>
                                                    <td>{menu.menuName}</td>
                                                    <td>{check_menu_permission.length > 0 ? check_menu_permission[0].submittedBy : '--'}</td>
                                                    <td>{check_menu_permission.length > 0 ? this.formatDateAndTime(check_menu_permission[0].submittedOn) : '--'}</td>
                                                    <td>
                                                        <input type="checkbox"
                                                               checked={check_menu_permission.length > 0}
                                                               id={JSON.stringify(menu)}
                                                               onChange={check_menu_permission.length < 1 ? this.onAddPermission : this.onRemovePermission}
                                                        />
                                                    </td>
                                                </tr> : <></>
                                        }

                                        {
                                            sub_menus && sub_menus.map((sub) => {
                                                //Filter the sub menu to check if it exist in the permission
                                                const check_sub_permission = this.state.groupPermission.filter(perm =>
                                                    perm.mainMenuName === sub.mainMenuName && perm.subMenuName === sub.subMenuName
                                                )
                                                return (
                                                    <tr key={sub._id}>
                                                        <td><i className={`fa ${sub.subMenuIcon}`}/></td>
                                                        <td>{sub.subMenuName}</td>
                                                        <td>{check_sub_permission.length > 0 ? check_sub_permission[0].submittedBy : '--'}</td>
                                                        <td>{check_sub_permission.length > 0 ? this.formatDateAndTime(check_sub_permission[0].submittedOn) : '--'}</td>
                                                        <td>
                                                            <input type="checkbox"
                                                                   checked={check_sub_permission.length > 0}
                                                                   id={JSON.stringify(sub)}
                                                                   onChange={check_sub_permission.length < 1 ? this.onAddPermission : this.onRemovePermission}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        </tbody>
                                    </table>
                                </div>

                            )
                        })
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        groupData: state.reportDetails,
        loginData: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(GroupPermission);
