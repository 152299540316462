import React, {Component} from "react";
import {Link} from "react-router-dom";
import {
    seAppointmentDetails,
    setAppointmentDetails,
    setpersonDetails,
    setReportDetails
} from "../../../actions/setpersondetailsaction";
import {connect} from "react-redux";

class PatientAppointmentList extends Component {
    render() {
        let count = 0;
        let action = '';
        return <div className="card mb-3">
            <div className="card-header">
                Appointments
            </div>
            <div className="card-body table-responsive">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Department</th>
                        <th>Type</th>
                        <th>Doctor</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Status</th>
                        <th>Details</th>
                        <th>--</th>
                    </tr>
                    </thead>
                    { this.props.appointmentList.length === 0 ?
                        (
                            <tbody>
                                <tr><td>No Appointment Found</td></tr>
                            </tbody>
                        )
                        :
                        (
                            <tbody>
                            {this.props.appointmentList.map((appointment) => {
                                count++;
                                if(appointment.appointmentStatus === 'Scheduled')
                                    action = <Link to="/appointment/update">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={async () => {
                                                        const sendData = {
                                                            ...appointment,
                                                        };
                                                        this.props.setOnAppointmentDetails(sendData);
                                                    }}
                                                >edit <i className="fa fa-pencil"></i></button>
                                            </Link>
                                else if(appointment.appointmentStatus === 'Checked In')
                                    action = <Link to={`/doctor/consultations/${appointment._id}`}>
                                        <button className="btn btn-success btn-sm">view <i className="fa fa-eye"/></button>
                                    </Link>
                                else
                                    action = '--'


                                return (
                                    <tr key={appointment._id}>
                                        <td>{count}</td>
                                        <td>{appointment.appointmentDepartment}</td>
                                        <td>{appointment.consultationType}</td>
                                        <td>{appointment.doctorName}</td>
                                        <td>{this.props.formatDate(appointment.appointmentDate)}</td>
                                        <td>{appointment.appointmentTime}</td>
                                        <td>{appointment.appointmentStatus}</td>
                                        <td>{appointment.appointmentDetail}</td>
                                        <td>{!this.props.isFront ? action : <></>}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        )
                    }
                </table>
            </div>
        </div>
    }
}

const mapDispatchAppointmentToProps = (dispatch) => {
        return {
            setOnAppointmentDetails: (p) => {
                dispatch(setpersonDetails(p));
            },
        };
    }
;
export default connect(null, mapDispatchAppointmentToProps)(PatientAppointmentList);
