import React, {Component} from "react";
import "./updateroom.css";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import RoomForm from "../roomform/roomform";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import { connect } from "react-redux";


class UpdateRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.roomData._id,
                roomName: this.props.roomData.roomName,
                typeName: this.props.roomData.typeName,
                blockName:  this.props.roomData.blockName,
                roomAmount: this.props.roomData.roomAmount,
                oldRoomName: this.props.roomData.roomName,
                bedCapacity: this.props.roomData.bedCapacity,
                roomManager: this.props.roomData.roomManager,
                description:  this.props.roomData.description,
                address: this.props.roomData.address,
                extensionNo:  this.props.roomData.extensionNo,
                phoneNumber:  this.props.roomData.phoneNumber,
                emailAddress:  this.props.roomData.emailAddress,
                updatedBy: ""
            },

            roomTypesList: [],
            blockList: [],
            blockData: [],
            limit: 20,
            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: localStorage.getItem("userID")
        });
        this.fetchRoomTypes();
        this.fetchBlocks(this.state.limit);
    }

    async fetchRoomTypes() {
        this.setState({ isLoadMoreData: true });
        const fetchedDataList = await fetch(`${this.state.serverLink}hospital_room_type/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    roomTypesList: data,
                });
            });
    }


    async fetchBlocks(limit) {
        this.setState({ isLoadMoreData: true });
        const fetchedDataList = await fetch(`${this.state.serverLink}hospital_block/all/${this.state.hospitalCode}/${limit}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    blockList: data,
                });
            });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateBlock();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdateBlock() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy
        };

        if(this.state.formData.blockName.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Room Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.extensionNo.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Room Extension Number"
            this.handleErrorDialog();
            return false;
        }

       
    

      
        axios.patch(`${this.state.serverLink}hospital_room/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                 if (response === "success"){
                    this.state.messageDescription = "ROOM UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/hospital/room/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Room Updated"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <RoomForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Room"
                                blockList={this.state.blockList}
                                roomTypesList={this.state.roomTypesList}
                            ></RoomForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        roomData: state.personDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateRoom);
