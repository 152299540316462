import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink, hospitalCode, calculateAge} from "../../../resources/url";
import PatientActionButton from "../../Patients/utils/patientaction";
import {Link} from "react-router-dom";
import BarcodeImage from "../../Barcode/barcode";
import ChoosePayment from "../../Finance/choosepayment/choosepayment";
import FormPrompt from "../../DialogBoxes/formprompt";
import AddPatientImaging from "../../Patients/addpatientimaging/addpatientimaging";
import Navigator from "../../Settings/navigation/navigation";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import PatientVitalList from "../../Patients/utils/patientvitals";
import {toast} from "react-toastify";

function ImagingProfile(props) {
    let page_url = window.location.pathname;
    let page_id = page_url.split('/');
    const [requestID] = useState(page_id[3]);
    const [isLoading, setIsLoading] = useState(true);
    const [imagingData, setImagingData] = useState([]);
    const [patientData, setPatientData] = useState("");
    const [openResultModal, setOpenResultModal] = useState(false);
    const [openClearPaymentModal, setOpenClearPaymentModal] = useState(false);
    const [onError, setError] = useState("");
    const [patientSerial, setPatientSerial] = useState("");
    const [visitNotes, setVisitNotes] = useState([]);
    const [vitals, setVitals] = useState([]);
    const [allergy, setAllergy] = useState([]);




    const getImagingRecord = async () => {
        await axios.get(`${serverLink}imaging/request/single/${requestID}`, props.doctorDetails.token)
            .then(response => {
                const result = response.data;
                if (result.length > 0) {
                    if (isLoading) {
                        setImagingData(result[0]);
                        setPatientSerial(result[0].patientSerial)
                        getAppontmentData(result[0].patientSerial);
                    }
                }
                else {
                    window.location.href = "/imaging/dashboard";
                }
            })
            .catch(error => {
                console.log("NETWORK ERROR", error)
            })

        if (patientSerial !== "") {
            await axios.get(`${serverLink}patient/${hospitalCode}/${patientSerial}`, props.doctorDetails.token)
                .then(result => {
                    if (isLoading) {
                        setPatientData(result.data);
                        setIsLoading(false);
                    }

                })
                .catch(error => {
                    console.log(error)
                    //window.location.href = '/imaging/dashboard'
                });
        }



    }

    getImagingRecord();

   const getAppontmentData = async (patient) => {
        //PATIENT ALLERGEN
        await axios.get(`${serverLink}allergy/${hospitalCode}/${patient}`, props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                setAllergy(result)
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        await axios.get(`${serverLink}vital/${hospitalCode}/${patient}`, props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                setVitals(result)
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        await axios.get(`${serverLink}appointment/consultation/doctorNote/${hospitalCode}/${patient}`, props.doctorDetails.token)
            .then(result => {
                const note = result.data;
                setVisitNotes(note)
                setIsLoading(false)
            })

    }


    const formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()} : 
                    ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}
                    
                    `
        } else {
            return '--'
        }
    };

    const formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `${user_date.getDate()}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    const moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    const onOpenResultModal = () => {
        setError("");
        if (imagingData.status === 'cancelled')
            setError(`Request was cancelled by ${imagingData.updatedBy}`);
        else {
            if (imagingData.paymentStatus === 'unpaid')
                setError("Please clear payment first");
            else
                setOpenResultModal(true)
        }

    }

    const onOpenClearPaymentModal = () => {
        setError("");
        if (imagingData.status === 'cancelled')
            setError(`Request was cancelled by ${imagingData.updatedBy}`);
        else {
            if (imagingData.paymentStatus !== 'unpaid')
                setError("Payment was made already");
            else
                setOpenClearPaymentModal(true)
        }

    }

    const onCloseModal = () => {
        setIsLoading(true);
        setOpenResultModal(false);
        setOpenClearPaymentModal(false);
        getImagingRecord();
    }

    return isLoading ? (
        <div className="basepage">
            <i className="fas fa-spinner fa-pulse fa-2x"/>
        </div>
    ) :
        (
            <div className="basepage">
                <FormPrompt
                    style={{ position: 'relative', zIndex: '3' }}
                    openDialog={openClearPaymentModal}
                    title="Clear Payment"
                    onSetOpenDialog={onCloseModal}
                    isCloseBtnAppear={true}
                >
                    <ChoosePayment
                        patientID={imagingData.patientSerial}
                        patientName={imagingData.patientName}
                        walletAmount={patientData.walletAmount}
                        MoneyConverter={moneyFormat}
                        paymentFor="Imaging"
                        itemID={requestID}
                        itemName={imagingData.imageName}
                        itemAmount={imagingData.price}
                        handlePaymentSuccess={onCloseModal}
                    />

                </FormPrompt>

                <FormPrompt
                    openDialog={openResultModal}
                    title="Upload Result"
                    onSetOpenDialog={onCloseModal}
                    isCloseBtnAppear={true}
                >
                    <AddPatientImaging
                        setCloseBtnAppear={true}
                        onCloseModal={onCloseModal}
                        patientData={patientData}
                        doctorName= {props.doctorDetails.fullName}
                        requestID= {requestID}
                    />
                </FormPrompt>

                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Imaging Page" />
                    </div>
                    <hr/>
                    <div className="">
                        <Tabs>
                            <TabList>
                                <Tab>Imaging Request</Tab>
                                <Tab>Doctor Note</Tab>
                                <Tab>Allergens</Tab>
                                <Tab>Vitals Report</Tab>
                            </TabList>

                            {/*Active Imaging Request*/}
                            <TabPanel>
                                <div className="second_section">
                                    <iframe id="iframePrint" style={{ height: "0px", width: "0px", position: "absolute" }} />
                                    <div id="BarcodeID">
                                        <BarcodeImage value={imagingData._id} height={80} width={1.4}/>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    Patient Info
                                                </div>
                                                <div className="card-body">
                                                    <table className="table table-striped">
                                                        <tbody>
                                                        <tr>
                                                            <td>Patient Serial</td>
                                                            <td>{imagingData.patientSerial}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Patient Name</td>
                                                            <td>{imagingData.patientName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone Number</td>
                                                            <td>{patientData.phoneNumber}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Date of Birth</td>
                                                            <td>{formatDate(patientData.dateOfBirth)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gender</td>
                                                            <td>{patientData.gender}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Wallet</td>
                                                            <td>{moneyFormat(patientData.walletAmount)}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    Request Info
                                                </div>
                                                <div className="card-body">
                                                    <table className="table table-striped">
                                                        <tbody>
                                                        <tr>
                                                            <td>Lab</td>
                                                            <td>{imagingData.labName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Imaging</td>
                                                            <td>{imagingData.imageName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Requested By</td>
                                                            <td>{imagingData.doctorName}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Requested Date</td>
                                                            <td>{formatDateAndTime(imagingData.submittedOn)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Status</td>
                                                            <td>
                                                                {
                                                                    imagingData.status === 'pending' ?
                                                                        <span className="badge badge-warning">Pending</span> :
                                                                        imagingData.status === 'cancelled' ? <span className="badge badge-danger">Cancelled</span> :
                                                                            <span className="badge badge-info">Result Entered</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Payment Status</td>
                                                            <td>
                                                                {
                                                                    imagingData.paymentStatus === 'unpaid' ?
                                                                        <span className="badge badge-danger">Unpaid</span>
                                                                        : <span className="badge badge-success">Paid</span>
                                                                }
                                                            </td>
                                                        </tr>

                                                        {
                                                            imagingData.status === 'result entered' &&
                                                            <>
                                                                <tr>
                                                                    <td>Result</td>
                                                                    <td>
                                                                        {
                                                                            !imagingData.result ? <></> :
                                                                                <a href={imagingData.result.includes("simplefileupload") ? imagingData.result : `${serverLink}public/uploads/patient_imaging/${imagingData.result}`} className="btn btn-primary btn-sm mr-1 mb-2">view</a>
                                                                        }
                                                                        {
                                                                            !imagingData.resultList ? <></> :  imagingData.resultList.map((item, index)=>{
                                                                                return(
                                                                                    <a href={`${serverLink}public/uploads/patient_imaging/${item}`} className="btn btn-primary btn-sm mr-1 mb-2">Result {index +1}</a>
                                                                                )
                                                                            })
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Entered By</td>
                                                                    <td>{imagingData.resultEnteredBy}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Entered Date</td>
                                                                    <td>{formatDateAndTime(imagingData.resultEnteredDate)}</td>
                                                                </tr>
                                                            </>
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    Action
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        onError !== "" &&
                                                        <div className="col-12">
                                                            <div className="alert alert-danger">{onError}</div>
                                                        </div>
                                                    }
                                                    <PatientActionButton
                                                        handleClick={onOpenClearPaymentModal}
                                                        actionName="Clear Payment"
                                                        actionIcon="fa fa-vials fa-4x text-primary"
                                                    />
                                                    <PatientActionButton
                                                        handleClick={onOpenResultModal}
                                                        actionName="Upload Result"
                                                        actionIcon="fa fa-flask fa-4x text-danger"
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </TabPanel>

                            {/*Visit Note*/}
                            <TabPanel>
                                <div>
                                    {
                                        visitNotes.length > 0 ?

                                            <Accordion>
                                                {
                                                    visitNotes.map((p, index) => {
                                                        return <AccordionItem key={index}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Doctor: {p.doctorName} => {formatDateAndTime(p.submittedOn, 'date')}
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <h5>
                                                                    Doctor: {p.doctorName}
                                                                    {/*<Link to={`#`}>*/}
                                                                    {/*    <span className="float-right">view</span>*/}
                                                                    {/*</Link>*/}
                                                                </h5>
                                                                <h4>NOTE</h4>
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Subjective</th>
                                                                            <th>Objective</th>
                                                                            <th>Assessment</th>
                                                                            <th>Plan</th>
                                                                            <th>Other 1</th>
                                                                            <th>Other 2</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note1}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note2}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note3}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note4}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note5}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note6}} /></td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    })
                                                }

                                            </Accordion>
                                            :
                                            <div className="p-3">
                                                <span className="alert alert-info">No Previous Note Found</span>
                                            </div>
                                    }
                                </div>

                            </TabPanel>

                            {/*Allergens*/}
                            <TabPanel>
                                {
                                    allergy.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Name</th>
                                                <th>Last Attack Date</th>
                                                <th>Medication</th>
                                                <th>Duration Before Subsiding</th>
                                                <th>Description</th>
                                                <th>Image</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                allergy.map((allergy, index) => {
                                                    return <tr key={allergy._id}>
                                                        <td>{index+1}</td>
                                                        <td>{allergy.allergyName}</td>
                                                        <td>{formatDate(allergy.lastAttackDate)}</td>
                                                        <td>{allergy.allergyMedication}</td>
                                                        <td>{allergy.durationBeforeSubsiding}</td>
                                                        <td>{allergy.allergyDescription}</td>
                                                        <td>
                                                            {allergy.reactionImage === "" ? (
                                                                <i className="fa fa-file-excel fa-2x"></i>
                                                            ) : (
                                                                <img
                                                                    className="image img-fluid"
                                                                    width="50px"
                                                                    alt=""
                                                                    srcSet={!allergy.reactionImage || allergy.reactionImage?.includes('simplefileupload') ? allergy.reactionImage : serverLink + 'public/uploads/patient_allergy/'+allergy.reactionImage}
                                                                    // onClick={this.handleViewAllergyImageClicked}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Record Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Vitals Report*/}
                            <TabPanel>
                                <PatientVitalList vitalList={vitals.sort((a, b) => new Date(b.submittedOn) - new Date(a.submittedOn))} formatDate={formatDate}/>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(ImagingProfile);
