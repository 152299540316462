import React, { Component } from "react";
import "./physiotherapy-dashboard.css";
import { serverLink, hospitalCode } from "../../../resources/url";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import axios from "axios";
import {setpersonDetails} from "../../../actions/setpersondetailsaction";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import {loginValidation} from "../../../context/loginvalidation";

class PhysiotherapyDashboard extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            isLoading: true,
            doctorData: "",
            doctorID: localStorage.getItem('userID')
        }
    }

    componentWillMount() {
        this.DoctorRecord();
    }

    async DoctorRecord () {
        await axios.get(`${serverLink}doctor/profile/${hospitalCode}/${this.state.doctorID}`, this.props.doctorDetails.token)
            .then(data => {
                if (data.data.length === 0) {
                    window.location.href = '/'
                }
                this.setState({
                    isLoading: false,
                    doctorData: data.data
                })

            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return this.state.isLoading ? (
            <div className="dashboardpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="dashboardpage">
                <div className="topheader">
                    <ul>
                        <li>
                            <i
                                className="fa fa-user-nurse fa-2x"
                                aria-hidden="true"
                            />
                        </li>
                        <li>
                            <span>{this.state.doctorData.title} {this.state.doctorData.firstName}  {this.state.doctorData.middleName}  {this.state.doctorData.surname}'s Dashboard</span>
                        </li>
                    </ul>
                </div>

                <div className="first_section">
                    <div className="row">

                        {
                            this.props.permissionData && this.props.permissionData.map((perm, index) => {
                                if (perm.mainMenuName === 'Physiotherapy' && perm.subMenuName !== null) {
                                    return <div key={index} className="col-sm-4">
                                        {" "}
                                        <Link
                                            to={perm.subMenuLink}
                                            onClick={async () => {
                                                const sendData = {
                                                    ...this.state.doctorData
                                                };
                                                this.props.setOnUserDetails(sendData);
                                            }}
                                        >
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                }

                            })
                        }

                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnUserDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysiotherapyDashboard);
