import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Select from "react-select";
import "./userform.css";
import Navigator from "../../Settings/navigation/navigation";

class UserForm extends Component {
    render() {
        return (
            <div className="editd_person_details_formpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="title">Title <span className="text-danger">*</span></label>
                                            <select
                                                name="title"
                                                className="custom-select"
                                                id="title"
                                                value={this.props.data.title}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>Mr</option>
                                                <option>Mrs</option>
                                                <option>Mss</option>
                                                <option>Ms</option>
                                                <option>Dr</option>
                                                <option>Capt</option>
                                                <option>Prof</option>
                                                <option>HRH</option>
                                            </select>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="userID">Staff ID <span className="text-danger">*</span></label>
                                            <input
                                                name="userID"
                                                type="text"
                                                className="form-control"
                                                id="userID"
                                                value={this.props.data.userID}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="firstName">First Name <span className="text-danger">*</span></label>
                                            <input
                                                name="firstName"
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                value={this.props.data.firstName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="middleName">Middle Name</label>
                                            <input
                                                name="middleName"
                                                type="text"
                                                className="form-control"
                                                id="middleName"
                                                value={this.props.data.middleName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="surname">Surname <span className="text-danger">*</span></label>
                                            <input
                                                name="surname"
                                                type="text"
                                                className="form-control"
                                                id="surname"
                                                value={this.props.data.surname}
                                                onChange={this.props.onEdit}

                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="gender">Gender <span className="text-danger">*</span></label>
                                            <select
                                                name="gender"
                                                className="custom-select"
                                                id="gender"
                                                value={this.props.data.gender}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>Male</option>
                                                <option>Female</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="phoneNumber">Phone Number <span className="text-danger">*</span></label>
                                            <input
                                                name="phoneNumber"
                                                type="text"
                                                className="form-control"
                                                id="phoneNumber"
                                                value={this.props.data.phoneNumber}
                                                onChange={this.props.onEdit}

                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="emailAddress">Email Address</label>
                                            <input
                                                name="emailAddress"
                                                type="email"
                                                className="form-control"
                                                id="emailAddress"
                                                value={this.props.data.emailAddress}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="dateOfFirstAppointment">Date of First Appointment <span className="text-danger">*</span></label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    style={{
                                                        padding: "0px 10px",
                                                        border: "1px solid rgb(197, 197, 197)",
                                                    }}
                                                    name="dateOfFirstAppointment"
                                                    id="dateOfFirstAppointment"
                                                    className=" form-control"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    value={this.props.dateOfFirstAppointment}
                                                    onChange={this.props.handleDateOfFirstAppointmentChange}
                                                    autoComplete="off"
                                                    format="dd/MM/yyyy"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="dateOfRenewedContract">Date of Renewed Contract </label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    style={{
                                                        padding: "0px 10px",
                                                        border: "1px solid rgb(197, 197, 197)",
                                                    }}
                                                    name="dateOfRenewedContract"
                                                    id="dateOfRenewedContract"
                                                    className=" form-control"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    value={this.props.dateOfRenewedContract}
                                                    onChange={this.props.handleDateOfRenewedContractChange}
                                                    autoComplete="off"
                                                    format="dd/MM/yyyy"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="employmentStatus">Employment Status <span className="text-danger">*</span></label>
                                            <select
                                                name="employmentStatus"
                                                type="text"
                                                className="form-control"
                                                id="employmentStatus"
                                                value={this.props.data.employmentStatus}
                                                onChange={this.props.onEdit}
                                            >
                                                <option></option>
                                                <option>Full Time</option>
                                                <option>Part Time</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="role">Staff Role <span className="text-danger">*</span></label>
                                            <select
                                                name="role"
                                                className="custom-select"
                                                id="role"
                                                value={this.props.data.role}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                {
                                                    this.props.departmentList && this.props.departmentList.map((dept) => {
                                                        return (
                                                            <option key={dept._id}>{dept.departmentName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="specialisation">Specialisation <span className="text-danger">*</span></label>
                                            <Select
                                                name="specialisation"
                                                isMulti
                                                isSearchable
                                                defaultValue={this.props.data.specialisation}
                                                onChange={this.props.onSpecialisationChange}
                                                id="specialisation"
                                                options={this.props.specialisationList}
                                            />
                                            {/*<select*/}
                                            {/*    name="role"*/}
                                            {/*    className="custom-select"*/}
                                            {/*    id="role"*/}
                                            {/*    value={this.props.data.specialisation}*/}
                                            {/*    onChange={this.props.onEdit}*/}

                                            {/*>*/}
                                            {/*    <option>N/A</option>*/}
                                            {/*</select>*/}
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="status">Status <span className="text-danger">*</span></label>
                                            <select
                                                name="status"
                                                className="custom-select"
                                                id="status"
                                                value={this.props.data.status}
                                                onChange={this.props.onEdit}

                                            >
                                                <option></option>
                                                <option>Active</option>
                                                <option>Inactive</option>
                                            </select>
                                        </div>


                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="administratorFolderNumber">Administrator Folder Number</label>
                                            <input
                                                type="text"
                                                name="administratorFolderNumber"
                                                className="form-control"
                                                id="administratorFolderNumber"
                                                value={this.props.data.administratorFolderNumber}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="administratorFolderLocation">Administrator Folder Location</label>
                                            <input
                                                name="administratorFolderLocation"
                                                type="text"
                                                className="form-control"
                                                id="administratorFolderLocation"
                                                value={this.props.data.administratorFolderLocation}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserForm;
