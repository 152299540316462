import React, { Component } from "react";
import "./news-form.css";
import Navigator from "../../../Settings/navigation/navigation";
import JoditEditor from "jodit-react";

class NewsForm extends Component {
    render() {

        const editorConfig = {
            readonly: false,
            height: 200
        };

        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <form onSubmit={this.props.handleSubmit}>
                        <div className="first_section">
                            <div className="row">

                                <div className="col-md-12 mb-3">
                                    <label htmlFor="title"> Title <span className="text-danger">*</span></label>
                                    <input
                                        name="title"
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        value={this.props.data.title}
                                        onChange={this.props.onEdit}
                                    />
                                </div >

                                <div className="col-md-12 mb-3">
                                    <label htmlFor="status">Status</label>
                                    <select
                                        name="status"
                                        id="status"
                                        className="custom-select"
                                        value={this.props.data.status}
                                        onChange={this.props.onEdit}>
                                        <option value="">Select Status</option>
                                        <option value="published">Publish</option>
                                        <option value="draft">Draft</option>
                                    </select>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label htmlFor="description">Description </label>
                                    {/*<textarea*/}
                                    {/*    name="description"*/}
                                    {/*    className="form-control"*/}
                                    {/*    id="description"*/}
                                    {/*    value={this.props.data.description}*/}
                                    {/*    onChange={this.props.onEdit}*/}
                                    {/*    rows="3"*/}
                                    {/*>{this.props.data.description}</textarea>*/}
                                    <JoditEditor
                                        value={this.props.data.description}
                                        config={editorConfig}
                                        onBlur={this.props.descriptionHandler}
                                        onChange={(newContent) => {}}
                                    />
                                </div>
                                <div className="col-md-12   mb-3" >
                                    <label className="required fs-5 fw-bold mb-2">Image</label>
                                    <div className="mb-3">
                                        <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                            <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                                document.getElementById("photo").click()
                                            }}>
                                                <div className="dz-message needsclick">
                                                    <i className="bi bi-image text-primary fs-3x"/>
                                                    <div className="ms-4 col-md-9">
                                                        <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                        <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                    </div>
                                                    <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={150} height={150} srcSet={this.props.image !== "" ? this.props.image : ""}/> : ""}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="alert-danger"> Max of 2MB file is allowed!</span>
                                    </div>
                                    <input type="file"  id="photo" name={"photo"}  accept={"image/*"} onChange={this.props.onImageChange} hidden />
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <button className="btn btn-success btn-block update_btn" type="submit">
                                    Save
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        );
    }
}

export default NewsForm;
