import React, { Component } from "react";
import "./addpatientdependant.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import { serverLink, hospitalCode } from "../../../resources/url";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import Navigator from "../../Settings/navigation/navigation";

class AddPatientDependant extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"patient/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: false,
            limit: 1000,

            patientData: this.props.patientDetails,

            patientSerial: this.props.patientDetails.patientSerial,

            openAlertDialog: false,
            messageTitle: "",
            messageDescription: "",

            openErrorDialog: false,
            errorMessage: "",

            patientlist: [],
            isSearching: false,
            isSearchDataShow: false,

        };
    }

    componentDidMount() {
        this.fetchPatient()
    }


    async fetchPatient() {
        await fetch(`${this.state.serverLink}${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientData: data,
                    isLoading: false,
                });
            });
    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");

            const fetchedDataList = await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            errorMessage: "Patient record not found. Please try again!",
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            patientlist: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    onAddDependant = (patient) => {
        this.setState({
            isLoading: true
        });

        const sendData = {
            ...patient,
            mainPatientSerial: this.state.patientSerial
        }

        axios.patch(`${this.state.serverLink}add_dependant`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    this.fetchPatient();
                    this.setState({
                        isLoading: false,
                        messageTitle: "SUCCESSFUL",
                        messageDescription: "Patient added as dependant",
                        openAlertDialog: true,
                    })

                } else {
                    this.setState({
                        isLoading: false,
                        errorMessage: "Something went wrong with your network. Kindly check your connection and try again!",
                        openErrorDialog: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false,
                    errorMessage: error,
                    openErrorDialog: true
                })
            })
    }

    handleSubmit = async  (patient) => {
        const dependant_list = this.state.patientData.dependantList;

        if (patient.patientSerial === this.state.patientSerial) {
            this.setState({
                errorMessage: "You cannot add a patient as it's dependant!",
                openErrorDialog: true
            })
            return
        }

        if (typeof dependant_list === "undefined") {
            this.onAddDependant(patient)

        } else {
            if (dependant_list.length === 0) {
                this.onAddDependant(patient)

            } else {
                let check_error = 0;
                dependant_list.find(item => {
                    if (item.patientSerial === patient.patientSerial) {
                        this.setState({
                            errorMessage: "The patient you are trying add already exist as a dependant to the selected patient!",
                            openErrorDialog: true
                        })
                        check_error = 1;
                    }
                });

                if (check_error === 0) {
                    this.onAddDependant(patient)
                }

            }
        }

    }


    closeAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
        });
    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="patientlistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="patientlistpage">
                <div className="main_section">

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des={this.state.errorMessage}
                    />

                    <div className="topheader">
                        <Navigator title={`Add Dependant for ${this.props.patientDetails.firstName} ${this.props.patientDetails.surname}`} />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search patient by first, middle, surname, email, phone number or patient ID"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.patientlist.length}{" "}
                                </li>
                            </ul>
                        </div>
                        <Link to="/patient/profile">
                            <button
                                type="button"
                                onClick={async () => {
                                    this.props.setOnPatientDetails(this.state.patientData);
                                }}
                                className="btn btn-info"
                            >
                                + Return to Patient Profile
                            </button>
                        </Link>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Profile</th>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Sex</th>
                            <th scope="col">Date of Birth</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email</th>
                            <th scope="col">State</th>
                            <th scope="col">LGA</th>
                            <th scope="col">BG</th>
                            <th scope="col">Add</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "></i>
                        ) : this.state.patientlist.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Patient Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.patientlist &&
                            this.state.patientlist.map((p) => {
                                count++;

                                let dateOfBirthDate = new Date(p.dateOfBirth);
                                const dob = `${dateOfBirthDate.getDate()}/${
                                    dateOfBirthDate.getMonth() + 1
                                }/${dateOfBirthDate.getFullYear()}`;

                                return (
                                    <tr key={p._id}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {p.passport === "" ? (
                                                <div className="userbg">
                                                    <i className="fa fa-user fa-2x"></i>
                                                </div>
                                            ) : (
                                                <img className="image" alt="" srcSet={p.passport} />
                                            )}
                                        </td>
                                        <td className="align-middle">
                                            {p.patientSerial}
                                        </td>
                                        <td className="align-middle">
                                            {p.firstName + " "+ p.middleName + " " + p.surname}
                                        </td>
                                        <td className="align-middle">{p.gender}</td>
                                        <td className="align-middle">
                                            {p.dateOfBirth === "" ? "N/A" : dob}
                                        </td>
                                        <td className="align-middle">
                                            {" "}
                                            {p.phoneNumber === "" ? "N/A" : p.phoneNumber}
                                        </td>
                                        {<td className="align-middle">
                                            {" "}
                                            {p.emailAddress === "" ? "N/A" : p.emailAddress}
                                        </td>}
                                        <td className="align-middle">
                                            {p.state === "" ? "N/A" : p.state}
                                        </td>
                                        <td className="align-middle">
                                            {p.lga === "" ? "N/A" : p.lga}
                                        </td>
                                        <td className="align-middle">
                                            { p.bloodGroup }
                                        </td>
                                        <td className="align-middle">
                                            <button
                                                onClick={async () => {
                                                    this.handleSubmit(p)
                                                }}
                                                type="button"
                                                className="btn btn-primary"
                                            >
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
        return {
            patientDetails: state.personDetails,
            doctorDetails: state.loginDetails,
        };
    };

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientDependant);
