import React, { Component } from "react";
import { Link } from "react-router-dom";
import { serverLink } from "../../../../../resources/url";

class PlanTable extends Component {
    constructor() {
        super();

    }

    render() {
        let count = 0;

        return (
        <table className="table table-striped">
            <thead className="thead tablehead">
            <tr>
                <th scope="col">#</th>
                <th scope="col">Insurance Company</th>
                <th scope="col">Plan</th>
                <th scope="col">Type</th>
                <th scope="col">Amount</th>
                <th scope="col">Duration</th>
                <th scope="col">Specialist</th>
                <th scope="col">Update Plan</th>
               
            </tr>
            </thead>
            {this.props.isSearching ? (
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            ) : this.props.planList.length === 0 ? (
                <tbody>
                <tr>
                    <td>No Plan Found</td>
                </tr>
                </tbody>
            ) : (
                <tbody className="tablebody">
                {this.props.planList &&
                this.props.planList.map((p, k) => {
                    count++;

                   
                    return (

                        <tr key={k}>
                            <td className="align-middle">{count}</td>
                            <td className="align-middle">
                                {p.companyName}
                            </td>
                            <td className="align-middle">
                                {p.planName}
                            </td>
                            <td className="align-middle">
                                {p.planType}
                            </td>
                            <td className="align-middle">
                                {p.amount}
                            </td>
                            <td className="align-middle">
                                {p.duration}
                            </td>
                            <td className="align-middle">
                                {" "}
                                {p.specialistType.map((items, key) => <div key={key} className="badge badge-info mr-1"> { items } </div> )}
                            </td>
                            
                            <td className="align-middle">
                                <Link to="/hospital/insurance/plans/update">
                                    <button
                                        onClick={async () => {
                                            const sendData = {
                                                ...p,
                                                personId: p._id,
                                            };
                                            this.props.setOnPatientDetails(sendData);
                                        }}
                                        type="button"
                                        className="btn btn-success"
                                    >
                                       
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                    </button>
                                </Link>
                               
                            </td>
                            
                        </tr>
                    );
                })}
                </tbody>
            )}
        </table>
        )
    }
}

export default PlanTable;
