import React, { Component } from "react";
import "./druglist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails
} from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import { serverLink, hospitalCode } from "../../../../resources/url";
import {loginValidation} from "../../../../context/loginvalidation";
import Navigator from "../../../Settings/navigation/navigation";
import axios from "axios";
import DataTable from "../../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../../resources/constants";

class DrugList extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"pharmacy/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            isLoadMoreData: false,
            access: window.location.href.split("/")[window.location.href.split("/").length - 1],

            totalNumOfDrugs: null,
            noMoreDataText: "",

            drugList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedDrugName: "",

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchDrugs();
    }

    async fetchDrugs() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}drug/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });

            });
    }

    showTable = () => {

        try {
            return this.state.drugList.map((drug, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{drug.drugName}</td>
                        <td>{drug.categoryName}</td>
                        <td>{drug.manufacturerName}</td>
                        <td>{drug.drugPrice === "" ? "N/A" : drug.drugPrice }</td>
                        <td>{drug.drugQuantity === "" ? "N/A" : drug.drugQuantity }</td>
                        <td>{drug.drugDescription === "" ? "N/A" : drug.drugDescription }</td>
                        <td>
                            {this.state.access === 'list' ?
                                <Link to="/pharmacy/drug/update">
                                    <button
                                        onClick={async () => {
                                            const drugData = {
                                                ...drug,
                                                drugId: drug._id,
                                            };
                                            this.props.setOnPatientDetails(drugData);
                                        }}
                                        type="button"
                                        className="btn btn-success"
                                    >
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                    </button>
                                </Link> : <>--</>
                            }
                                </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        const header = ["S/N", "Drug Name",  "Drug Category", "Drug Manufacturer", "Drug Price", "Drug Quantity",  "Drug Description", "Option"];
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Drug" />
                    </div>
                    <hr />
                </div>

                <ConfirmDialogBox
                    openDailog={this.state.openConfirmDialog}
                    onSetOpenDailog={this.closeConfirmDialog}
                    handleConfirmOkBtn={this.deleteSpecialisation}
                    isLoading={this.state.isDeleting}
                    title="Delete"
                    des={
                        "Are you sure to delete " +
                        this.state.selectedDrugName +
                        " " +
                        "details"
                    }
                />

                <ErrorDialogBox
                    openDialog={this.state.openErrorDialog}
                    onSetOpenDialog={this.closeErrorDialog}
                    title="Record Not Found"
                    des="Drug record not found. Please try again!"
                />

            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h3 className="card-title">Drug List</h3>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    {this.state.access === 'list' ?  <Link to="/pharmacy/drug/add">
                                        <button
                                            type="button"
                                            className="btn btn-warning"
                                        >
                                            + Add Drug
                                        </button>
                                    </Link> : <></> }
                                </div>
                            </div>
                        </div>
                        <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')} body={this.showTable()} title={`Drug Report`} />
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugList);
