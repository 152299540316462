import React, { Component } from "react";
import "./planform.css";
import Select from "react-select";
import Navigator from "../../../../Settings/navigation/navigation";

class PlanForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            months:  Array(25 - 1).fill().map((d, i) => i + 1),
        }
    }
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="planName">Plan<span className="text-danger">*</span></label>
                                           <input 
                                                type="text"
                                                name="planName"
                                                id="planName"
                                                className="custom-select"
                                                value={this.props.data.planName}
                                                onChange={this.props.onEdit}
                                                
                                           
                                           />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="duration"> Package Duration (in months)<span className="text-danger">*</span></label>
                                            <select
                                                name="duration"
                                                className="custom-select"
                                                id="duration"
                                                value={this.props.data.duration}
                                                onChange={this.props.onEdit}
                                            >
                                                <option value="">Select Duration</option>
                                                {
                                                    this.state.months && this.state.months.map((duration, key) => {
                                                        if(duration !== [])
                                                            return (
                                                                <option key={key} value={duration}>{duration}</option>
                                                            )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="planType">Plan Type<span className="text-danger">*</span> </label>
                                             <select
                                                name="planType"
                                                id="planType"
                                                className="custom-select"
                                                value={this.props.data.planType}
                                                onChange={this.props.onEdit}>
                                                <option>Select Type</option>
                                                <option value="General">General</option>
                                                <option value="Specialist">Specialist</option>
                                               
                                           
                                           
                                           </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="specialistType">Specialisation <span className="text-danger">*</span></label>
                                            <Select
                                                name="specialistType"
                                                isMulti
                                                isSearchable
                                                defaultValue={this.props.data.specialistType}
                                                onChange={this.props.onSpecialisationChange}
                                                options={this.props.specialistsList}
                                            />
                                        </div>
                                        
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="insuranceCompanyList">Insurance Company<span className="text-danger">*</span> </label>
                                            <select
                                                name="companyName"
                                                id="companyName"
                                                className="custom-select"
                                                value={this.props.data.companyName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Company</option>
                                                { this.props.insuranceCompanyList.map(company => {
                                                        return <option key={company._id} data={company._id} value={company.companyName}>{company.companyName}</option>                                                 })
                                                }
                                            
                                                
                                  
                                            </select>

                                            
                                        </div>     
                                        
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="amount">Amount<span className="text-danger">*</span></label>
                                            <input
                                                name="amount"
                                                type="number"
                                                className="form-control"
                                                id="amount"
                                                value={this.props.data.amount}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="amount">Plan Description<span className="text-danger"></span></label>
                                            <textarea
                                                name="planDescription"
                                                type="text"
                                                className="form-control"
                                                id="planDescription"
                                                value={this.props.data.planDescription}
                                                onChange={this.props.onEdit}>
                                            </textarea>
                                        </div>
                                        
                                    </div>
                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                  {this.props.btnMessage}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlanForm;
