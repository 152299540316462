import React, {Component, useState} from "react";
import "./login.css";
import axios from "axios";
import {serverLink, hospitalCode, AccessTokenString} from "../../resources/url";
import {setLogInDetails, setPermissionDetails} from "../../actions/setpersondetailsaction";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableBtn: "",
      username: "",
      password: "",
      invalid: "invalid",
      userRecord: "",
      permissionGroup: []
    };
  }

  login = async (e) => {
    this.setState({
      disableBtn: "disable",
      invalid: "invalid"
    });
    e.preventDefault();

    const sendData = {
      username: this.state.username,
      password: this.state.password
    }

    await axios.post(`${serverLink}user/login`, sendData)
        .then(login => {
          if (login.data.message === 'success') {
            const data = login.data.user;
            localStorage.setItem("hospitalCode", data.hospitalCode);
            localStorage.setItem('userID', data.userID);

            const loginDetails = {
              isLoggedIn: true,
              firstName: data.firstName,
              middleName: data.middleName,
              surname: data.surname,
              fullName: `${data.firstName} ${data.middleName} ${data.surname}`,
              role: data.role,
              userID: data.userID,
              token: {headers: {"buh-token" : login.data.token}}
            };

            this.setState({
              // disableBtn: "",
              // invalid: "invalid",
              userRecord: loginDetails
            });

          } else {
            this.setState({
              disableBtn: "",
              invalid: "",
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({
            disableBtn: "",
            invalid: "",
          });
        });

    if (this.state.userRecord !== "") {
      await axios.get(`${serverLink}settings/group/user/${hospitalCode}/${this.state.userRecord.userID}`, this.state.userRecord.token)
          .then(record => {
                this.setState({permissionGroup: record.data})
          }).catch(error => {
            console.log('ERROR FETCHING USER RECORDS')
          });


      let permission_list = [];
      this.state.permissionGroup.length > 0 ?
          this.state.permissionGroup.map(async (group, index) => {
        await axios.get(`${serverLink}settings/group/permission/all/${hospitalCode}/${group.groupName}`, this.state.userRecord.token)
            .then(record => {
              if (record.data.length > 0) {
                record.data.map(async perm => {
                  await permission_list.push(perm);
                })
              }

              if (index === this.state.permissionGroup.length-1) {
                this.setState({
                  disableBtn: "",
                  invalid: "invalid"
                });
                const uniquePermission = permission_list.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.mainMenuName === value.mainMenuName && t.subMenuName === value.subMenuName
                        ))
                )
                this.props.setOnPermissionDetails(uniquePermission);
                this.props.setOnLogInDetails(this.state.userRecord);
              }

            })
            .catch(error => {
              console.log('ERROR FETCHING USER RECORDS')
            });
      }) : this.props.setOnLogInDetails(this.state.userRecord);

    }



  };
  onForgotPassword = () => {
    window.location.href ="/forgotten-password'";
  }
  onEdit = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div className="login_page">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-12 p-0 first_section">
              <div className="box">
                <div className="from_section">
                  {
                    this.props.isHospitalParent === false ? <img srcSet={this.props.hospitalLogo} width={50} alt=""/> : <i className="fa fa-user-md" aria-hidden="true"/>
                  }
                  <ul>
                    <li>
                      <i className="fa fa-user-md" aria-hidden="true"/>
                    </li>
                    <h3 style={{ color: "white" }}>
                      {
                        this.props.isHospitalParent === false ? this.props.hospitalName : 'Hospital Management'
                      }
                      </h3>
                    <li/>
                  </ul>

                  <div className="col-lg-6">
                    <form onSubmit={this.login}>
                      <input
                          name="username"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Enter your Email Address or User ID"
                          onChange={this.onEdit}
                          autoComplete="off"
                          required
                      />
                      <input
                          className="form-control form-control-lg"
                          type="password"
                          placeholder="Enter your Password"
                          name="password"
                          onChange={this.onEdit}
                          required
                      />
                      <p className={this.state.invalid}>
                        Invalid login or password
                      </p>
                      <button
                          type="submit"
                          className="btn btn-info"
                          disabled={this.state.disableBtn}
                      >
                        Login
                        <i className="fa fa-unlock" aria-hidden="true"/>
                      </button>
                      <Link className="float-right mt-2" to={'/forgotten-password'}>Forgot Password?</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLogInDetails: (p) => {
      dispatch(setLogInDetails(p));
    },
    setOnPermissionDetails: (p) => {
      dispatch(setPermissionDetails(p))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
