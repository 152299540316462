import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import "../base.css";
import Navigator from "../../../Settings/navigation/navigation";
import {hospitalCode, serverLink} from "../../../../resources/url";
import FormPrompt from "../../../DialogBoxes/formprompt";
import { toast } from "react-toastify";
import ReportTable from "../../../ReusableComponents/ReportTable";
import {Link, useLocation, useParams} from "react-router-dom"
import {setGeneralDetails} from "../../../../actions/setpersondetailsaction";
import Select from "react-select";
function ItemDetails(props) {
    const { slug } = useParams();
    if (slug === "") window.location.href = '/';
    let location = useLocation();

    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [staff, setStaff] = useState([]);
    const [department, setDepartment] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [itemList, setItemList] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const columns = ["Manufacturer", "Vendor", "Category", "Sub_Cat.", "Qty"];
    const columns2 = ["UserID", "Name", "Department", "Location", "Qty", "Allocated By"];
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        inventoryID: "", itemID: "", selectedItem: "", selectedItem2: "", itemName: "", manufacturerName: "", vendorName: "", userName: "", userName2: "", userID: "", department: "", department2: "",
        categoryName: "", subCategoryName: "", photo: "", unitPrice: '', quantity: 0, availableQuantity: 0,
        locationName: "", description:"", hospitalCode: hospitalCode, allocatedBy: props.loginDetails.fullName
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    const toggleModal = () => {
        setOpenModal(true)
    }

    const closeModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const resetFormData = () => {
        setFormData({inventoryID: "", itemID: "",  selectedItem: "",  selectedItem2: "", itemName: "", manufacturerName: "", vendorName: "", userName: "", userName2: "",  userID: "", department: "", department2: "",
            categoryName: "", subCategoryName: "", photo: "", unitPrice: 0, quantity: 0, availableQuantity: 0,
            locationName: "", description:"", hospitalCode: hospitalCode, allocatedBy: props.loginDetails.fullName})
        props.setOnGeneralDetails([])
    }

    const onEdit = (e) => {
        const id = e.target.id;
        let value = e.target.value;

        if (id === 'quantity' || id === 'unitPrice')
            value = parseInt(value)

        setFormData({
            ...formData,
            [id]: value
        });
    }

    const onStaffChange = (e) => {
        setFormData({
            ...formData,
            userID: e.value,
            userName: e.label.split("-")[0],
            userName2: e,
        })
    }

    const onDepartmentChange = (e) => {
        setFormData({
            ...formData,
            department: e.value,
            department2: e,
        })
    }

    const onItemChange = (e) => {
        const inventoryID = e.inventoryID;
        let item = inventoryList.filter((e, i)=> e._id === inventoryID)
        props.setOnGeneralDetails(item)
        setFormData({
            ...formData,
            selectedItem: e.value,
            selectedItem2: e,
        })
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)

        if (formData.quantity > formData.availableQuantity) {
            toast.error("Quantity given is more than the stock available")
            setIsSubmitting(false)
            return false;
        }

    if (formData.userName === '') {
            toast.error("Please select a user")
            setIsSubmitting(false)
            return false;
        }

        if (formData.department === '') {
            toast.error("Please select user department")
            setIsSubmitting(false)
            return false;
        }

        if (formData.quantity === '' || formData.quantity < 1) {
            toast.error("Item given quantity must be > 0")
            setIsSubmitting(false)
            return false;
        }

        if (formData.locationName === '') {
            toast.error("Please select storage location")
            setIsSubmitting(false)
            return false;
        }


        toast.dark("Submitting...")

        await axios.post(`${serverLink}finance/allocation/add`, formData, props.loginDetails.token).then(res => {
            if (res.data.message === 'success'){
                toast.success("Item Given Successfully");
                setIsSubmitting(false)
                closeModal();
                getData();
            }
            else {
                setIsSubmitting(false)
                toast.error("Network issue. Please check your network and try again!")
            }

        }).catch(err => {
            setIsSubmitting(false)
            toast.error("Connection failure. Please check your network and try again!")
        })

    }

    const getData = async () => {

        //INVENTORY LIST
        await axios.get(`${serverLink}finance/inventory/item/${slug}/${hospitalCode}`, props.loginDetails.token).then(res => {
            let rows = []
            let itemRows = []
                res.data.inventory.map((row) => {
                    itemRows.push({ value: row.itemName, label: row.itemName +" ("+row.quantity+") ", inventoryID: row._id})
                });
                setInventory(itemRows)
                setInventoryList(res.data.inventory)
                //SET INVENTORY ITEMS
                setItemList(res.data.item)
                res.data.inventory.map(r => {
                    rows.push([ r.manufacturerName, r.vendorName, r.categoryName, r.subCategoryName, r.quantity
                    ])
                })
                setData(rows)
                //SET ALLOCATED ITEMS
                let rows2 = []
                res.data.allocation.map(r => {
                    rows2.push([ r.userID, r.userName, r.department, r.locationName, r.quantity, r.allocatedBy
                    ])
                })
                setData2(rows2)
                setIsLoading(false)
            }).catch(error => {
                console.log("ERROR FETCHING ITEMS")
            })

            //LOCATION LIST
            await axios.get(`${serverLink}finance/location/all/${hospitalCode}`, props.loginDetails.token).then(res => {
                setLocationList(res.data)
            }).catch(error => {
                console.log("ERROR FETCHING ITEMS")
            })

            //STAFF LIST
            await axios.get(`${serverLink}user/all/${hospitalCode}`, props.loginDetails.token).then((result) => {
                    let rows = []
                    if (result.data.length > 0) {
                        result.data.map((row) => {
                            rows.push({ value: row.userID, label: row.firstName +" "+  row.middleName +" "+ row.surname + " - (" + row.userID + ")" })
                        });
                        setStaff(rows)
                    }

                });

            //Department LIST
            await axios.get(`${serverLink}department/all/${hospitalCode}`, props.loginDetails.token).then((result) => {
                    let rows = []
                    if (result.data.length > 0) {
                        result.data.map((row) => {
                            rows.push({ value: row.departmentName, label: row.departmentName})
                        });
                        setDepartment(rows)
                    }

                });

    }

    useEffect(() => {
        getData();
    },[])

    useEffect(() => {
        setFormData({
            ...formData,
            inventoryID: props.generalDetails[0]?._id,
            itemID: props.generalDetails[0]?.itemID,
            itemName: props.generalDetails[0]?.itemName,
            manufacturerName: props.generalDetails[0]?.manufacturerName,
            categoryName: props.generalDetails[0]?.categoryName,
            subCategoryName: props.generalDetails[0]?.subCategoryName,
            vendorName: props.generalDetails[0]?.vendorName,
            availableQuantity: props.generalDetails[0]?.quantity,
            photo: props.generalDetails[0]?.photo,

        })
    },[JSON.stringify(props.generalDetails)])

    const handleOnReceive = () => {
        resetFormData()
        toggleModal();
    }

    return isLoading ? (<div className="baselistpage">
        <i className="fas fa-spinner fa-pulse fa-2x "/>
    </div>) : (
        <div className="baselistpage">
            <FormPrompt
                openDialog={openModal}
                title="Allocate Item Form"
                onSetOpenDialog={closeModal}
                isCloseBtnAppear={true}
            >
                <form onSubmit={onSubmitForm}>
                    {
                        props.generalDetails.length > 0 ?
                            <div className="row table-responsive">
                                <table className="table table-striped">
                                    <tbody>
                                    <tr>
                                        <th>ITEM NAME</th>
                                        <td>{formData.itemName}</td>
                                    </tr>
                                    <tr>
                                        <th>MANUFACTURER</th>
                                        <td>{formData.manufacturerName}</td>
                                    </tr>
                                    <tr>
                                        <th>VENDOR</th>
                                        <td>{formData.vendorName}</td>
                                    </tr>
                                    <tr>
                                        <th>CATEGORY</th>
                                        <td>{formData.categoryName}</td>
                                    </tr>
                                    <tr>
                                        <th>SUB CATEGORY</th>
                                        <td>{formData.subCategoryName}</td>
                                    </tr>
                                    <tr>
                                        <th>AVAILABLE QUANTITY</th>
                                        <td className={formData.availableQuantity > 0 ? "text-success" : "text-black"}>{formData.availableQuantity}</td>
                                    </tr>
                                    <tr>
                                        <th>PHOTO</th>
                                        <td><img src={formData.photo} width={100} height={100}/></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            <></>

                    }

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="selectedItem">Select Item</label>
                                <Select
                                    name="selectedItem"
                                    value={formData.selectedItem2}
                                    onChange={(e, i)=>onItemChange(e)}
                                    options={inventory}
                                    placeholder="select Item"
                                />
                                {/*<select id="selectedItem" className="form-control" onChange={onEdit} value={formData.selectedItem}>*/}
                                {/*    <option value=""/>*/}
                                {/*    {*/}
                                {/*        inventoryList.length > 0 && inventoryList.map(item => {*/}
                                {/*            return <option key={item._id} data={item._id} value={item.itemName}>{item.itemName} - ({item.quantity})</option>*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*</select>*/}
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="unitPrice">Select Staff</label>
                                    <Select
                                        name="userName"
                                        value={formData.userName2}
                                        onChange={(e, i)=>onStaffChange(e)}
                                        options={staff}
                                        placeholder="select Staff"
                                    />
                        </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="unitPrice">Select Department</label>
                                <Select
                                    name="department"
                                    value={formData.department2}
                                    onChange={(e, i)=>onDepartmentChange(e)}
                                    options={department}
                                    placeholder="select department"
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="quantity">Enter Quantity Given</label>
                                <input type="number" className="form-control" id="quantity" value={formData.quantity} onChange={onEdit}/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="locationName">Select Storage Location</label>
                                <select id="locationName" className="form-control" onChange={onEdit} value={formData.locationName}>
                                    <option value=""/>
                                    {
                                        locationList.length > 0 && locationList.map(item => {
                                            return <option key={item._id} value={item.locationName}>{item.locationName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="alert alert-warning col-md-12" role="alert">
                            <h4 className="alert-heading">Warning!</h4>
                            <p>Are you sure you want to allocate the following item to <b>{formData.userName}</b> ?</p>
                            <span>ITEM NAME: {formData.itemName}</span> <br/>
                            <span>QUANTITY: {formData.quantity}</span> <br/>
                            <span>STORE LOCATION: {formData.locationName}</span> <br/>
                            <hr />
                            <p className="mb-0">Please note, once submitted, you can't delete or alter this record!</p>
                        </div>

                        {!isSubmitting && <button type="submit" className="btn btn-primary btn-block">Submit</button>}


                    </div>
                </form>
            </FormPrompt>
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Inventory Details" />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>
                </div>
                <div className="row">
                    <div className="col-md-9 mb-5">
                        <div className="card">
                            <div className="card-body">
                               <h3> {itemList?.itemName} ({itemList?.quantityAvailable})</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3  mb-5 btn pt-0" onClick={() => handleOnReceive()}>
                        <div className="card bg-secondary text-white shadow">
                            <div className="card-body">
                                <h3> Allocate Item</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h4>Inventory Item List</h4>
                        <ReportTable columns={columns} data={data} title={""} height={"500px"} />
                    </div>
                    <div className="col-md-6">
                        <h4>Item Allocations</h4>
                        <ReportTable columns={columns2} data={data2} title={""} height={"500px"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        generalDetails: state.generalDetails,
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);
