import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import "../base.css";
import Navigator from "../../../Settings/navigation/navigation";
import {hospitalCode, serverLink} from "../../../../resources/url";
import FormPrompt from "../../../DialogBoxes/formprompt";
import { toast } from "react-toastify";
import ReportTable from "../../../ReusableComponents/ReportTable";
import SimpleFileUpload from "react-simple-file-upload";
import {uploadAPI} from "../../../../resources/url";
import JoditEditor from "jodit-react";
import {Link} from "react-router-dom"
import {currencyConverter} from "../../../../resources/constants";
function InventoryAllocation(props) {
    const [data, setData] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const columns = ["Item Name", "Manufacturer", "Vendor", "Category", "Sub Category", "Qty", "Receive", "View"];
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        itemID: "", itemName: "", manufacturerName: "", vendorName: "",
        categoryName: "", subCategoryName: "", photo: "", unitPrice: '', quantity: '', quantityAvailable: 0,
        locationName: "", description:"", hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    const toggleModal = () => {
        setOpenModal(true)
    }

    const closeModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const resetFormData = () => {
        setFormData({itemID: "", itemName: "", manufacturerName: "", vendorName: "",
            categoryName: "", subCategoryName: "", photo: "", unitPrice: 0, quantity: 0, quantityAvailable: 0,
            locationName: "", description:"", hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})
    }

    const onEdit = (e) => {
        const id = e.target.id;
        let value = e.target.value;
        if (id === 'quantity' || id === 'unitPrice')
            value = parseInt(value)

        setFormData({
            ...formData,
            [id]: value
        });
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)

        if (formData.quantity === '' || parseInt(formData.quantity) < 1) {
            toast.error("Item receive quantity must be > 0")
            setIsSubmitting(false)
            return false;
        }
        if (formData.unitPrice === '' || parseInt(formData.unitPrice) < 1) {
            toast.error("Please enter the unit price")
            setIsSubmitting(false)
            return false;
        }
        if (formData.locationName === '') {
            toast.error("Please select storage location")
            setIsSubmitting(false)
            return false;
        }

        toast.dark("Submitting...")

        await axios.post(`${serverLink}finance/inventory/add`, formData, props.loginDetails.token).then(res => {
            if (res.data.message === 'success'){
                toast.success("Item Received Successfully");
                setIsSubmitting(false)
                closeModal();
                getData();
            }
            else {
                setIsSubmitting(false)
                toast.error("Network issue. Please check your network and try again!")
            }

        }).catch(err => {
            setIsSubmitting(false)
            toast.error("Connection failure. Please check your network and try again!")
        })

    }

    const getData = async () => {
        //ITEM LIST
        await axios.get(`${serverLink}finance/item/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            let rows = []
            if (res.data.length > 0) {
                res.data.map(r => {
                    rows.push([r.itemName, r.manufacturerName, r.vendorName, r.categoryName, r.subCategoryName, r.quantityAvailable,
                        <button className={"btn btn-sm btn-info"} onClick={() => handleOnReceive(r)}><i className="fa fa-plus" /></button>,
                        <Link to={`/finance/inventory/item/profile/${r._id}`}><button className={"btn btn-sm btn-success"} onClick={() => handleOnReceive(r)}><i className="fa fa-eye" /></button></Link>,
                    ])
                })
            }
            setData(rows)
            setIsLoading(false)
        }).catch(error => {
            console.log("ERROR FETCHING ITEMS")
        })

        //LOCATION LIST
        await axios.get(`${serverLink}finance/location/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            setLocationList(res.data)
        }).catch(error => {
            console.log("ERROR FETCHING ITEMS")
        })

        //VENDOR LIST
        await axios.get(`${serverLink}finance/vendor/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            setVendorList(res.data)
        }).catch(error => {
            console.log("ERROR FETCHING VENDORS")
        })

    }

    useEffect(() => {
        getData();
    },[])

    const handleOnReceive = (dt) => {
        setFormData({
            ...formData,
            itemID: dt._id,
            itemName: dt.itemName,
            manufacturerName: dt.manufacturerName,
            categoryName: dt.categoryName,
            subCategoryName: dt.subCategoryName,
            vendorName: dt.vendorName,
            quantityAvailable: dt. quantityAvailable,

        })
        toggleModal();
    }

    const descriptionHandler = (event) => {
      setFormData({
          ...formData,
          description: event
      })
    }
    const onImageUpload = (event) => {
        if (event !== "") {
            setFormData({
                ...formData,
                photo: event
            })
        }
    }

    const editorConfig = {
        readonly: false,
        height: 200
    };


    return isLoading ? (<div className="baselistpage">
        <i className="fas fa-spinner fa-pulse fa-2x "/>
    </div>) : (
        <div className="baselistpage">
            <FormPrompt
                openDialog={openModal}
                title="Item Receive Form"
                onSetOpenDialog={closeModal}
                isCloseBtnAppear={true}
            >
                <form onSubmit={onSubmitForm}>
                    <div className="row table-responsive">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th>ITEM NAME</th>
                                    <td>{formData.itemName}</td>
                                </tr>
                                <tr>
                                    <th>MANUFACTURER</th>
                                    <td>{formData.manufacturerName}</td>
                                </tr>
                                <tr>
                                    <th>CATEGORY</th>
                                    <td>{formData.categoryName}</td>
                                </tr>
                                <tr>
                                    <th>SUB CATEGORY</th>
                                    <td>{formData.subCategoryName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="vendorName">Select Vendor</label>
                                <select id="vendorName" className="form-control" onChange={onEdit} value={formData.vendorName}>
                                    <option value=""/>
                                    {
                                        vendorList.length > 0 && vendorList.map(item => {
                                            return <option key={item._id} value={item.vendorName}>{item.vendorName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="quantity">Enter Item Quantity</label>
                                <input type="number" className="form-control" id="quantity" value={formData.quantity} onChange={onEdit}/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="unitPrice">Enter Item Unit Price</label>
                                <input type="number" className="form-control" id="unitPrice" value={formData.unitPrice} onChange={onEdit}/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="locationName">Select Storage Location</label>
                                <select id="locationName" className="form-control" onChange={onEdit} value={formData.locationName}>
                                    <option value=""/>
                                    {
                                        locationList.length > 0 && locationList.map(item => {
                                            return <option key={item._id} value={item.locationName}>{item.locationName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="description">Enter Item Description</label>
                                <JoditEditor
                                    value={formData.description}
                                    config={editorConfig}
                                    onBlur={descriptionHandler}
                                    onChange={(newContent) => {}}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="description">Item Photo</label>
                                <SimpleFileUpload
                                    apiKey={uploadAPI}
                                    tag="buh-inventory-item"
                                    onSuccess={onImageUpload}
                                    preview="true"
                                    width="100%"
                                    // height="100"
                                    accepted="image/*"
                                    maxFileSize={2}
                                    buttonClass="upload-button"
                                    buttonText="Upload Images"
                                />
                                <span className="badge bg-danger">Max of 2MB file is allowed!</span>

                            </div>
                        </div>

                        <div className="alert alert-warning col-md-12" role="alert">
                            <h4 className="alert-heading">Warning!</h4>
                            <p>Are you sure you want to receive the following item?</p>
                            <span>ITEM NAME: {formData.itemName}</span> <br/>
                            <span>QUANTITY: {formData.quantity}</span> <br/>
                            <span>UNIT PRICE: {formData.unitPrice !== '' && currencyConverter(formData.unitPrice)}</span> <br/>
                            <span>STORE LOCATION: {formData.locationName}</span> <br/>
                            <span>VENDOR: {formData.vendorName}</span><br/>
                            <span>TOTAL: {formData.unitPrice !== '' && formData.quantity !== '' && currencyConverter(formData.unitPrice*formData.quantity)}</span>
                            <hr />
                            <p className="mb-0">Please note, once submitted, you can't delete or alter this record!</p>
                        </div>

                        {!isSubmitting && <button type="submit" className="btn btn-primary btn-block">Submit</button>}


                    </div>
                </form>
            </FormPrompt>
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Inventory Dashboard" />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>
                </div>
                <ReportTable columns={columns} data={data} title={""} height={"600px"} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(InventoryAllocation);
