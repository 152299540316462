import {setServiceDetails} from "../actions/setpersondetailsaction";

export const hospitalDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_HOSPITAL_DETAILS_DETAILS":
      return action.payload;

    default:
      return state;
  }
};
export const patientProfileDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_PATIENT_PROFILE_DETAILS":
      return action.payload;

    default:
      return state;
  }
};
export const generalDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_GENERAL_DETAILS":
      return action.payload;

    default:
      return state;
  }
};
export const serviceDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_SERVICE_DETAILS":
      return action.payload;

    default:
      return state;
  }
};
export const updatePatientDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_UPDATE_PATIENT_DETAILS":
      return action.payload;

    default:
      return state;
  }
};

