export const setpersonDetails = (p) => {
  return {
    type: "SET_PERSONDETAILS_DETAILS",
    payload: p,
  };
};
export const setReportDetails = (p) => {
  return {
    type: "SET_REPORT_DETAILS",
    payload: p,
  };
};
export const setLogInDetails = (p) => {
  return {
    type: "SET_LOGIN_DETAILS",
    payload: p,
  };
};
export const setAppointmentDetails = (p) => {
  return {
    type: "SET_APPOINTMENT_DETAILS",
    payload: p,
  };
};
export const setBlockDetails = (p) => {
  return {
    type: "SET_BLOCK_DETAILS",
    payload: p,
  };
};
export const setPermissionDetails = (p) => {
  return {
    type: "SET_PERMISSION_DETAILS",
    payload: p,
  };
};
export const setPatientListDetails = (p) => {
  return {
    type: "SET_PATIENT_LIST_DETAILS",
    payload: p,
  };
};
export const setConsultationListDetails = (p) => {
  return {
    type: "SET_CONSULTATION_LIST_DETAILS",
    payload: p,
  };
};
export const setHospitalDetails = (p) => {
  return {
    type: "SET_HOSPITAL_DETAILS_DETAILS",
    payload: p,
  };
};
export const setServiceDetails = (p) => {
  return {
    type: "SET_SERVICE_DETAILS",
    payload: p,
  };
};
export const setUpdatePatientDetails = (p) => {
  return {
    type: "SET_UPDATE_PATIENT_DETAILS",
    payload: p,
  };
};
export const setPatientProfileDetails = (p) => {
  return {
    type: "SET_PATIENT_PROFILE_DETAILS",
    payload: p,
  };
};
export const setGeneralDetails = (p) => {
  return {
    type: "SET_GENERAL_DETAILS",
    payload: p,
  };
};
