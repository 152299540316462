import React, {Component} from "react";
import {Link} from "react-router-dom";
import { serverLink, hospitalCode } from "../../../resources/url";

class PatientPrescriptionList extends Component {
    constructor() {
        super();
        this.state = {
            serverLink: serverLink,
        }
    }

    render() {
        let count = 0;

        return <table className="table table-striped">
            <thead className="thead tablehead">
            <tr>
                <th scope="col">#</th>
                <th scope="col">PatientID</th>
                <th scope="col">Patient Name</th>
                <th scope="col">Doctor Name</th>
                <th scope="col">Amount Paid</th>
                <th scope="col">Balance</th>
                <th scope="col">Total</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
            </tr>
            </thead>
            {this.props.isSearching ? (
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            ) : this.props.prescriptionList.length === 0 ? (
                <tbody>
                <tr>
                    <td>No Prescription Found</td>
                </tr>
                </tbody>
            ) : (
                <tbody className="tablebody">
                {this.props.prescriptionList &&
                this.props.prescriptionList.map((p) => {
                    count++;
                    let prescriptionDate = new Date(p.submittedDate);
                    const date = `${prescriptionDate.getDate()}/${
                        prescriptionDate.getMonth() + 1
                    }/${prescriptionDate.getFullYear()}`;

                    return (

                        <tr key={p._id}>
                            <td className="align-middle">{count}</td>

                            <td className="align-middle">
                                {p.patientSerial}
                            </td>
                            <td className="align-middle">
                                {p.patientName}
                            </td>
                            <td className="align-middle">{p.doctorName}</td>
                            <td className="align-middle">{p.amountPaid.toLocaleString('en-NG')}</td>
                            <td className="align-middle">{(p.prescriptionAmount - p.amountPaid).toLocaleString('en-NG')}</td>
                            <td className="align-middle">{p.prescriptionAmount.toLocaleString('en-NG')}</td>
                            <td className="align-middle text-danger">{p.paymentStatus}</td>
                            <td className="align-middle">
                                {p.submittedDate === "" ? "N/A" : date}
                            </td>
                           
                            <td className="align-middle">
                                <Link to="/pharmacy/prescription/details">
                                    <button
                                        onClick={async () => {
                                            const sendData = {
                                                ...p,
                                                prescriptionDataList: p.prescriptionDetail.filter(e => e.status === 'unpaid')
                                            };
                                            this.props.setOnPatientDetails(sendData);
                                        }}
                                        type="button"
                                        className="btn btn-success"
                                    >
                                        <i className="fa fa-eye" aria-hidden="true"/>
                                    </button>
                                </Link>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            )}
        </table>
    }
}

export default PatientPrescriptionList;
