import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import "../base.css";
import Navigator from "../../Settings/navigation/navigation";
import {hospitalCode, serverLink, uploadAPI} from "../../../resources/url";
import FormPrompt from "../../DialogBoxes/formprompt";
import { toast } from "react-toastify";
import ReportTable from "../../ReusableComponents/ReportTable";
import {Link} from "react-router-dom";
import SimpleFileUpload from "react-simple-file-upload";
import {f} from "react-select/dist/index-4bd03571.esm";

function Gallery(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const columns = ["S/N", "Title", "Image", "Category", "Sub Category", "InsertedBy", "Edit"];
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [selectedSubCategoryList, setSelectedSubCategoryList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({id: "", image: "", image_name: "", htmlelement: "", title: "", categoryID: "", categoryName: "", subCategoryID: "", subCategoryName: "", hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})
    const toggleModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const toggleEditModal = () => {
        setOpenModal(true)
    }

    const closeModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const resetFormData = () => {
        setFormData({id: "", image: "", image_name: "", htmlelement: "", title: "",  categoryID: "", categoryName: "", subCategoryID: "", subCategoryName: "", hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})
    }

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        if (id === "subCategoryName"){
            let index = e.nativeEvent.target.selectedIndex;
            let subCategoryID =  e.nativeEvent.target[index].getAttribute('sub_cat_id');
            setFormData({
                ...formData,
                [id]: value,
                subCategoryID: subCategoryID,
            });
            return false;
        }
        if (id === "categoryName"){
            setSelectedSubCategoryList(subCategoryList.filter(i => i.categoryName === value))
            let index = e.nativeEvent.target.selectedIndex;
            let categoryID =  e.nativeEvent.target[index].getAttribute('cat_id');
            setFormData({
                ...formData,
                [id]: value,
                categoryID: categoryID,
            });
            return false;
        }
        setFormData({
            ...formData,
            [id]: value
        });

    }

    const onSubmitForm = async (e) => {
        e.preventDefault();

        if (formData.title === '') {
            toast.error("Please enter the image title")
            return false;
        }

        if (formData.categoryName === '') {
            toast.error("Please select the category")
            return false;
        }

        if (formData.image === '') {
            toast.error("Please select the image")
            return false;
        }

        toast.dark("Submitting...")

        if (formData.id === "") {
            await axios.post(`${serverLink}website/gallery/add`, formData, props.loginDetails.token).then(res => {
                if (res.data.message === 'success'){
                    const id = res.data.entry_id;
                    if (formData.image !== "") {
                        const formData1 = new FormData();
                        formData1.append('photo', formData.image);
                        formData1.append('entry_id', id)
                        axios.patch(`${serverLink}website/gallery/upload`, formData1)
                            .then(result => {

                            })
                            .catch(err => {
                                console.error('ERROR', err);
                            });
                    }
                    toast.success("Gallery Added Successfully");
                    closeModal();
                    getData();
                }
                else if (res.data.message === 'exist')
                    toast.error("Gallery Already Exist")
                else
                    toast.error("Network issue. Please check your network and try again!")

            }).catch(err => {
                toast.error("Connection failure. Please check your network and try again!")
            })
        } else {
            const sendData = {
                ...formData,
            }
            await axios.patch(`${serverLink}website/gallery/update`, sendData, props.loginDetails.token).then(res => {
                if (res.data.message === 'success') {
                    if (formData.image !== "") {
                        const formData1 = new FormData();
                        formData1.append('photo', formData.image);
                        formData1.append('entry_id', sendData.id)
                        axios.patch(`${serverLink}website/gallery/upload`, formData1)
                            .then(result => {

                            })
                            .catch(err => {
                                console.error('ERROR', err);
                            });
                    }
                    toast.success("Gallery Updated Successfully")
                    closeModal();
                    getData();
                }
                else if (res.data.message === 'exist')
                    toast.error("Item Already Exist")
                else
                    toast.error("Network issue. Please check your network and try again!")

            }).catch(err => {
                toast.error("Connection failure. Please check your network and try again!")
            })
        }

    }

    const getData = async () => {
        let sub_category_list = []
        //SUB CATEGORY LIST
        await axios.get(`${serverLink}website/gallery/category/sub/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            setSubCategoryList(res.data)
            sub_category_list = res.data
        }).catch(error => {
            console.log("ERROR FETCHING SUB CATEGORY")
        })

        //GALLERY LIST
        await axios.get(`${serverLink}website/gallery/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            let rows = []
            if (res.data.length > 0) {
                res.data.map((r, index) => {
                    rows.push([(index+1), r.title, <img src={`${serverLink}public/uploads/gallery/${r.image}`} width={80} height={80} className="img-thumbnail" alt=""/>, r.categoryName, r.subCategoryName, r.submittedBy,
                        <button className={"btn btn-sm btn-info"} onClick={() => handleOnEdit(r, sub_category_list)}>Edit</button>])
                })
            }
            setData(rows)
            setIsLoading(false)
        }).catch(error => {
            console.log("ERROR FETCHING ITEMS")
        })

        //CATEGORY LIST
        await axios.get(`${serverLink}website/gallery/category/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            setCategoryList(res.data)
        }).catch(error => {
            console.log("ERROR FETCHING CATEGORY")
        })

    }

    useEffect(() => {
        getData();
    },[])

    const handleOnEdit = (dt, sub_category_list) => {
        setSelectedSubCategoryList(sub_category_list.filter(i => i.categoryName === dt.categoryName))
        setFormData({
            ...formData,
            id: dt._id,
            title: dt.title,
            // image: dt.image,
            image_name: dt.image,
            htmlelement: `${serverLink}public/uploads/gallery/${dt.image}`,
            categoryID: dt.categoryID,
            categoryName: dt.categoryName,
            subCategoryID: dt.subCategoryID,
            subCategoryName: dt.subCategoryName,
        })
        toggleEditModal();
    }

    const  onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 5000000) {
                toast.error("max file size is 2mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                setFormData({
                    ...formData,
                    image: file,
                    imagefile: file,
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                })
            };
        }
    };


    return isLoading ? (<div className="baselistpage">
        <i className="fas fa-spinner fa-pulse fa-2x "/>
    </div>) : (
        <div className="baselistpage">
            <FormPrompt
                openDialog={openModal}
                title="Manage Gallery Form"
                onSetOpenDialog={closeModal}
                isCloseBtnAppear={true}
            >
                <form onSubmit={onSubmitForm}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="title">Enter Image Title</label>
                                <input type="text" className="form-control" id="title" value={formData.title} onChange={onEdit}/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="categoryName">Select Category</label>
                                <select id="categoryName" className="form-control" onChange={onEdit} value={formData.categoryName}>
                                    <option value=""></option>
                                    {
                                        categoryList.length > 0 && categoryList.map(item => {
                                            return <option key={item._id} cat_id={item._id} value={item.categoryName}>{item.categoryName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="subCategoryName">Select Sub Category</label>
                                <select id="subCategoryName" className="form-control" onChange={onEdit} value={formData.subCategoryName}>
                                    <option value=""></option>
                                    {
                                        selectedSubCategoryList.length > 0 && selectedSubCategoryList.map(item => {
                                            return <option key={item._id} sub_cat_id={item._id} value={item.subCategoryName}>{item.subCategoryName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12   mb-3" >
                            <label className="required fs-5 fw-bold mb-2">Image</label>
                            <div className="mb-3">
                                <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                    <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                        document.getElementById("photo").click()
                                    }}>
                                        <div className="dz-message needsclick">
                                            <i className="bi bi-image text-primary fs-3x"/>
                                            <div className="ms-4 col-md-9">
                                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                <span className="fs-7 fw-semibold text-gray-400 text-info"> {formData.image_name !== "" ? formData.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                            </div>
                                            <div className="col-md-2">{formData.htmlelement !== "" ? <img className="img-thumbnail" width={150} height={150} srcSet={formData.htmlelement !== "" ? formData.htmlelement : ""}/> : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <span className="alert-danger"> Max of 5MB file is allowed!</span>
                            </div>
                            <input type="file"  id="photo" name={"photo"}  accept={"image/*"} onChange={onImageChange} hidden />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Submit</button>
                    </div>
                </form>
            </FormPrompt>
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Gallery" />
                    <div className="col-md-3">
                        <Link to="/website/gallery/category" className="btn btn-primary mb-2 mr-3"> Gallery Category </Link>
                        <Link to="/website/gallery/category/sub" className="btn btn-primary mb-2">  Gallery Sub Category</Link>
                    </div>
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>
                    <button type="button" className="btn btn-warning mb-2" onClick={toggleModal}>  + Add Gallery </button>
                </div>
                <ReportTable columns={columns} data={data} title={"Gallery"} height={"600px"} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(Gallery);
