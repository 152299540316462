import React, {Component, useEffect} from "react";
import "./adddrug.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import DrugForm from "../drugform/drugform";
import { serverLink, hospitalCode } from "../../../../resources/url";
import {connect} from "react-redux";


class AddDrug extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink ,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                drugName: "",
                categoryName: "",
                manufacturerName: "",
                manufactureDate: "",
                drugStockPrice: "",
                drugPrice: "",
                drugQuantity: "",
                packSize: "",
                hospitalCode: "",
                drugQRCode: "",
                drugDescription: "",
                expiryDate: "",
                submittedBy: ""
            },

            drugCategoryList: [],
            drugSubCategoryList: [],
            drugSubSubCategoryList: [],

            drugSubCategoryData: [],
            drugSubSubCategoryData: [],
            manufacturerList: [],
            limit: 100,

            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.state.submittedBy = this.props.loginDetails.fullName;
        this.fetchDrugCategory();
        this.fetchDrugSubCategory();
        this.fetchDrugSubSubCategory();
        this.fetchManufacturer(this.state.limit);
    }

    async fetchDrugCategory() {
        await fetch(`${this.state.serverLink}pharmacy/drug/category/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugCategoryList: data,
                });
            });
    }

    async fetchDrugSubCategory() {
        await fetch(`${this.state.serverLink}pharmacy/drug/category/sub/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugSubCategoryList: data,
                });
            });
    }

    async fetchDrugSubSubCategory() {
        await fetch(`${this.state.serverLink}pharmacy/drug/category/sub/sub/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugSubSubCategoryList: data,
                });
            });
    }


    async fetchManufacturer(limit) {
       await fetch(`${this.state.serverLink}pharmacy/manufacturer/all/${this.state.hospitalCode}/${limit}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    manufacturerList: data,
                });
            });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddDrug();
    };


    async onAddDrug() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy
        };

        if(this.state.formData.drugName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Drug Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.categoryName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select Drug Category"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.drugStockPrice === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Price"
            this.handleErrorDialog();
            return false;
        }


        if(this.state.formData.drugPrice === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Sales Price"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.drugQuantity === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Quantity"
            this.handleErrorDialog();
            return false;
        }


        axios.post(`${this.state.serverLink}pharmacy/drug/add`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "drug exist") {
                    this.state.messageDescription = "A drug with the same name already exist";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.state.messageDescription = "DRUG ADDED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to upload the drug details!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;
        // if(e.target.name === 'categoryName') {
        //     let index = e.nativeEvent.target.selectedIndex;
        //     const categoryName = e.nativeEvent.target[index].getAttribute('data');
        //     let new_subCategory = this.state.drugSubCategoryData.filter(e=> e.categoryName === categoryName);
        //     this.setState({
        //         drugSubCategoryList: new_subCategory,
        //         formData: {
        //             ...formData,
        //             [e.target.name]: e.target.value,
        //         },
        //     });
        //
        // }
        //
        // if(e.target.name === 'subCategoryName') {
        //     let index = e.nativeEvent.target.selectedIndex;
        //     const subCategoryName = e.nativeEvent.target[index].getAttribute('data');
        //     let new_subSubCategory = this.state.drugSubSubCategoryData.filter(e=> e.subCategoryName === subCategoryName);
        //
        //     this.setState({
        //         drugSubSubCategoryList: new_subSubCategory,
        //         formData: {
        //             ...formData,
        //             [e.target.name]: e.target.value,
        //         },
        //     });
        //
        // }
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/pharmacy/drug/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="drug Added"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <DrugForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Add Drug"
                                manufacturerList={this.state.manufacturerList}
                                drugCategoryList={this.state.drugCategoryList}
                                drugSubCategoryList={this.state.drugSubCategoryList}
                                drugSubSubCategoryList={this.state.drugSubSubCategoryList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(AddDrug);
