import React, { Component } from "react";
import "./hospitalform.css";
import Navigator from "../../Settings/navigation/navigation";
import SimpleFileUpload from "react-simple-file-upload";
import {uploadAPI} from "../../../resources/url";

class HospitalForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="hospitalName">Hospital Name <span className="text-danger">*</span></label>
                                            <input
                                                name="hospitalName"
                                                type="text"
                                                className="form-control"
                                                id="hospitalName"
                                                value={this.props.data.hospitalName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="hospitalAddress">Hospital Address <span className="text-danger">*</span></label>
                                            <input
                                                name="hospitalAddress"
                                                type="text"
                                                className="form-control"
                                                id="hospitalAddress"
                                                value={this.props.data.hospitalAddress}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="phoneNumber">Hospital Phone Number <span className="text-danger">*</span></label>
                                            <input
                                                name="phoneNumber"
                                                type="tel"
                                                className="form-control"
                                                id="phoneNumber"
                                                value={this.props.data.phoneNumber}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="emailAddress">Hospital Email Address <span className="text-danger">*</span></label>
                                            <input
                                                name="emailAddress"
                                                type="email"
                                                className="form-control"
                                                id="emailAddress"
                                                value={this.props.data.emailAddress}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="color">Hospital Color </label>
                                            <input
                                                name="color"
                                                type="color"
                                                className="form-control"
                                                id="color"
                                                value={this.props.data.color}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="hospitalState">Hospital State <span className="text-danger">*</span> </label>
                                            <select
                                                name="hospitalState"
                                                id="hospitalState"
                                                className="custom-select"
                                                value={this.props.data.hospitalState}
                                                onChange={this.props.onEdit}>
                                                <option></option>
                                                {
                                                    this.props.stateList.map((state, index) => {
                                                        return <option key={index} value={state.state} state_code={state.code}>{state.state}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="hospitalLGA">Hospital LGA <span className="text-danger">*</span> </label>
                                            <select
                                                name="hospitalLGA"
                                                id="hospitalLGA"
                                                className="custom-select"
                                                value={this.props.data.hospitalLGA}
                                                onChange={this.props.onEdit}>
                                                <option></option>
                                                {
                                                    this.props.lgaList.map((lga, index) => {
                                                        return <option key={index} value={lga}>{lga}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="geoLong">Hospital Longitude</label>
                                            <input
                                                name="geoLong"
                                                type="text"
                                                className="form-control"
                                                id="geoLong"
                                                value={this.props.data.geoLong}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="geoLat">Hospital Latitude </label>
                                            <input
                                                name="geoLat"
                                                type="text"
                                                className="form-control"
                                                id="geoLat"
                                                value={this.props.data.geoLat}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="container">
                                        <div className="row second_section">
                                            {/*<div className="clo-sm-6">*/}
                                            {/*    <div className="profileimage">{this.props.htmlelement}</div>*/}
                                            {/*</div>*/}

                                            <div className="col-sm-12 btn_section">
                                                <label htmlFor="files" className="selectimage mb-3">
                                                    Upload Image <span className="text-danger">*</span>
                                                </label>

                                                <SimpleFileUpload
                                                    apiKey={uploadAPI}
                                                    tag="buh-logo"
                                                    onSuccess={this.props.onImageChange}
                                                    preview="true"
                                                    // width="100%"
                                                    // height="100"
                                                    accepted="image/*"
                                                    maxFileSize={1}
                                                    buttonClass="upload-button"
                                                    buttonText="Upload Images"
                                                />
                                                <span className="badge bg-danger">Max of 1MB file is allowed!</span>

                                                {/*<input*/}
                                                {/*    name="avatarimage"*/}
                                                {/*    id="files"*/}
                                                {/*    type="file"*/}
                                                {/*    accept="image/*"*/}
                                                {/*    onChange={this.props.onImageChange}*/}
                                                {/*/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HospitalForm;
