import React, { Component } from "react";
import "./addspecimenform.css";
import Navigator from "../../../Settings/navigation/navigation";

class AddSpecimenForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="laboratoryName">Specimen Name <span className="text-danger">*</span></label>
                                            <input
                                                name="specimenName"
                                                type="text"
                                                className="form-control"
                                                id="specimenName"
                                                value={this.props.data.specimenName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                    </div>

                                </div>
                                <div className=" col-md-6 offset-sm-3 ">
                                    <button className="btn btn-success btn-block savebtn" type="submit">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddSpecimenForm;
