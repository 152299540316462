const status = "Live";
export const serverLink =
    status === "Dev"
        ? "http://localhost:4480/"
        : "https://api.bazeuniversityhospital.com:5001/";

export const hospitalCode = 'BUH';
export const serverStatus = status;
export const uploadAPI = "305e0ddf925a3fc5dd34d314f8230349";

export const calculateAge = (birthday) => {
    let getAge = Date.now() - new Date(birthday).getTime();
    let ageDate = new Date(getAge); // milliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970) ;
}


