import React, {useEffect, useState} from "react";
import "./subscribepatient.css";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../../actions/setpersondetailsaction";
import Navigator from "../../../Settings/navigation/navigation";
import {serverLink, hospitalCode} from "../../../../resources/url";
import axios from "axios";
import Select from "react-select";
import {moneyFormat} from "../../../../resources/constants";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";

function PackageSubscription (props) {
    const [isLoading, setIsLoading] = useState(true);
    const [packageList, setPackageList] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [packageSelectList, setPackageSelectList] = useState([])
    const [patientSelectList, setPatientSelectList] = useState([])
    const [formData, setFormData] = useState({
        packageName: '', packageID: '', patientSerial: '', patientName: '', status: 'active', amount: 0, duration: 0, expiryDate: '', walletAmount: 0,
        hospitalCode: hospitalCode, submittedBy: props.doctorDetails.fullName, updatedBy: props.doctorDetails.fullName
    });
    const months = Array(25 - 1).fill().map((d, i) => i + 1)
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [messageDescription, setMessageDescription] = useState("")

    const getData = async () => {
        await axios.get(`${serverLink}hospital_package/fetch/subscription/data/${hospitalCode}`, props.doctorDetails.token)
            .then(res => {
                const package_list = res.data.packageData
                const patient_list = res.data.patientData
                setPackageList(package_list);
                setPatientList(patient_list);

                if (package_list.length > 0) {
                    let packs = []
                    package_list.map(l => {
                        packs.push({label: l.packageName, value: l._id, for: 'package'})
                    })
                    setPackageSelectList(packs)
                }
                if (patient_list.length > 0) {
                    let patients = []
                    patient_list.map(l => {
                        patients.push({label: `${l.firstName} ${l.middleName} ${l.surname} (${l.patientSerial})`, value: l.patientSerial, for:'patient'})
                    })
                    setPatientSelectList(patients)
                }

                setIsLoading(false)
            })
            .catch(err => {
                console.log('Err:',  err)
            })
    }

    const onEdit = (e) => {
        if (e.for === 'package') {
            const selected_package = packageList.filter(r => r._id === e.value)[0]
            setFormData({
                ...formData,
                packageID: selected_package._id, packageName: selected_package.packageName, amount: selected_package.amount
            })
        } else {
            const selected_patient = patientList.filter(r => r.patientSerial === e.value)[0]
            setFormData({
                ...formData,
                patientName: `${selected_patient.firstName} ${selected_patient.middleName} ${selected_patient.surname}`, patientSerial: selected_patient.patientSerial, walletAmount: selected_patient.walletAmount
            })
        }
    }

    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.packageID === '') { setShowError(true); setMessageDescription('Please Select Package'); return false; }
        if (formData.patientSerial === '') { setShowError(true); setMessageDescription('Please Select Patient'); return false; }
        if (formData.duration === 0) { setShowError(true); setMessageDescription('Please Select Duration'); return false; }
        if (formData.expiryDate === '') { setShowError(true); setMessageDescription('Please Select Expiry Date'); return false; }
        if (formData.walletAmount < formData.amount) { setShowError(true); setMessageDescription('Insufficient Wallet Balance'); return false; }
        setIsLoading(true)

        await axios.post(`${serverLink}hospital_package/subscription/add`, formData, props.doctorDetails.token)
            .then(res => {
                setIsLoading(false)
                if (res.data.message === 'success') {
                    setShowSuccess(true)
                    setMessageDescription('SUBSCRIPTION SUCCESSFULLY');
                    setTimeout(() => {
                        window.location.href = '/hospital/package/subscribers';
                    }, 3000)
                } else {
                    setShowError(true)
                    setMessageDescription(res.data.message);
                }
            })
            .catch(err => {
                console.log('Err:',  err)
                setIsLoading(false)
            })
    }

    const closeModal = () => {
      setShowError(false);
      setShowSuccess(false);
    }

    useEffect(() => {
        getData();
    }, []);

    return isLoading ? (
        <div className="searchPatientPage">
            <i className="fas fa-spinner fa-pulse fa-2x "/>
        </div>
    ) : (
        <div className="searchPatientPage">
            <ErrorDialogBox
                openDialog={showError}
                onSetOpenDialog={closeModal}
                title="ERROR"
                des={messageDescription}
            ></ErrorDialogBox>

            <AlertDialogBox
                openDailog={showSuccess}
                onSetOpenDailog={closeModal}
                title="SUCCESSFUL"
                des={messageDescription}
            ></AlertDialogBox>
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Subscribe Patient to Package"/>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={handleSubmit}>
                            <div className="first_section offset-2 col-md-8">
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="packageName"> Select a Package <span
                                            className="text-danger">*</span></label>
                                        <Select
                                            name="packageName"
                                            id="packageName"
                                            isSearchable
                                            defaultValue={formData.packageID}
                                            onChange={onEdit}
                                            options={packageSelectList}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="patientSerial"> Select a Patient <span
                                            className="text-danger">*</span></label>
                                        <Select
                                            name="patientSerial"
                                            id="patientSerial"
                                            isSearchable
                                            defaultValue={formData.patientSerial}
                                            onChange={onEdit}
                                            options={patientSelectList}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="amount"> Package Amount <span
                                            className="text-danger">*</span></label>
                                        <input name="amount" type="text" className="form-control" id="amount"
                                               value={moneyFormat(formData.amount)} readOnly/>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="amount"> Patient Wallet Amount <span
                                            className="text-danger">*</span></label>
                                        <input name="amount" type="text" className="form-control" id="amount"
                                               value={moneyFormat(formData.walletAmount)} readOnly/>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="duration"> Subscription Duration (in months)<span
                                            className="text-danger">*</span></label>
                                        <select name="duration" className="custom-select" id="duration"

                                                value={formData.duration} onChange={onChange}>
                                            <option value="">Select Duration</option>
                                            {
                                                months && months.map((duration, key) => {
                                                    if (duration !== [])
                                                        return (
                                                            <option key={key} value={duration}>{duration}</option>
                                                        )
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="expiryDate"> Expiration Date <span className="text-danger">*</span></label>
                                        <input name="expiryDate" type="date" className="form-control" id="expiryDate" value={formData.expiryDate}  onChange={onChange}/>
                                    </div>

                                </div>

                                <div className="">

                                </div>
                                <div className="col-md-12 mb-3">
                                    <button className="btn btn-success btn-block update_btn" type="submit">
                                        Save
                                    </button>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));

        },
    };
};
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PackageSubscription);
