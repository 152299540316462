import React, { Component } from "react";
import "./addsubmenuform.css";
import Navigator from "../../../navigation/navigation";

class AddSubMenuForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="subMenuName">Sub Menu Name <span className="text-danger">*</span></label>
                                            <input
                                                name="subMenuName"
                                                type="text"
                                                className="form-control"
                                                id="subMenuName"
                                                value={this.props.data.subMenuName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="menuName">Main Menu Name <span className="text-danger">*</span></label>
                                            <select
                                                name="menuName"
                                                id="menuName"
                                                className="custom-select"
                                                value={this.props.data.menuName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Main Menu</option>
                                                {
                                                    this.props.mainMenuList.map((menu, index) => {
                                                        return <option key={index} data={menu._id} value={menu.menuName}>{menu.menuName}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="SubMenuLink">Sub Menu Link </label>
                                            <input
                                                name="SubMenuLink"
                                                type="text"
                                                className="form-control"
                                                id="SubMenuLink"
                                                value={this.props.data.SubMenuLink}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="subMenuIcon">Sub Menu Icon </label>
                                            <input
                                                name="subMenuIcon"
                                                type="text"
                                                className="form-control"
                                                id="subMenuIcon"
                                                value={this.props.data.subMenuIcon}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>


                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddSubMenuForm;
