import React, { Component } from "react";
import "./consultationform.css";

class ConsultationScheduleForm extends Component {

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <p className="text-danger">{this.props.errorMessage}</p>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Day</th>
                            <th>Appointment Duration</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Slots</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                className="form-control"
                                name="monday_day"
                                disabled
                                value="Monday"
                            />
                        </td>
                        <td>
                            <select
                                name="monday_duration"
                                className="custom-select"
                                onChange={this.props.onEdit}
                                defaultValue={this.props.scheduleData.monday.duration}
                            >
                                <option value="">Select Duration</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="1">1 hour</option>
                                <option value="2">2 hours</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                name="monday_startTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.monday.startTime || ''}
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                id="monday_endTime"
                                name="monday_endTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.monday.endTime || ''}
                            />
                        </td>
                        <td>
                            <span className="badge badge-success">{this.props.scheduleData.monday.numberOfSlots || 0}</span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <input
                                type="text"
                                className="form-control"
                                name="tuesday_day"
                                disabled
                                value="Tuesday"
                            />
                        </td>
                        <td>
                            <select
                                name="tuesday_duration"
                                className="custom-select"
                                onChange={this.props.onEdit}
                                defaultValue={this.props.scheduleData.tuesday.duration}
                            >
                                <option value="">Select Duration</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="1">1 hour</option>
                                <option value="2">2 hours</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                name="tuesday_startTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.tuesday.startTime || ''}
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                id="tuesday_endTime"
                                name="tuesday_endTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.tuesday.endTime || ''}
                            />
                        </td>
                        <td>
                            <span className="badge badge-success">{this.props.scheduleData.tuesday.numberOfSlots || 0}</span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <input
                                type="text"
                                className="form-control"
                                name="wednesday_day"
                                disabled
                                value="Wednesday"
                            />
                        </td>
                        <td>
                            <select
                                name="wednesday_duration"
                                className="custom-select"
                                onChange={this.props.onEdit}
                                defaultValue={this.props.scheduleData.wednesday.duration}
                            >
                                <option value="">Select Duration</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="1">1 hour</option>
                                <option value="2">2 hours</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                name="wednesday_startTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.wednesday.startTime || ''}
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                id="wednesday_endTime"
                                name="wednesday_endTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.wednesday.endTime || ''}
                            />
                        </td>
                        <td>
                            <span className="badge badge-success">{this.props.scheduleData.wednesday.numberOfSlots || 0}</span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <input
                                type="text"
                                className="form-control"
                                name="thursday_day"
                                disabled
                                value="Thursday"
                            />
                        </td>
                        <td>
                            <select
                                name="thursday_duration"
                                className="custom-select"
                                onChange={this.props.onEdit}
                                defaultValue={this.props.scheduleData.thursday.duration}
                            >
                                <option value="">Select Duration</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="1">1 hour</option>
                                <option value="2">2 hours</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                name="thursday_startTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.thursday.startTime || ''}
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                id="thursday_endTime"
                                name="thursday_endTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.thursday.endTime || ''}
                            />
                        </td>
                        <td>
                            <span className="badge badge-success">{this.props.scheduleData.thursday.numberOfSlots || 0}</span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <input
                                type="text"
                                className="form-control"
                                name="friday_day"
                                disabled
                                value="Friday"
                            />
                        </td>
                        <td>
                            <select
                                name="friday_duration"
                                className="custom-select"
                                onChange={this.props.onEdit}
                                defaultValue={this.props.scheduleData.friday.duration}
                            >
                                <option value="">Select Duration</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="1">1 hour</option>
                                <option value="2">2 hours</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                name="friday_startTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.friday.startTime || ''}
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                id="friday_endTime"
                                name="friday_endTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.friday.endTime || ''}
                            />
                        </td>
                        <td>
                            <span className="badge badge-success">{this.props.scheduleData.friday.numberOfSlots || 0}</span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <input
                                type="text"
                                className="form-control"
                                name="saturday_day"
                                disabled
                                value="Saturday"
                            />
                        </td>
                        <td>
                            <select
                                name="saturday_duration"
                                className="custom-select"
                                onChange={this.props.onEdit}
                                defaultValue={this.props.scheduleData.saturday.duration}
                            >
                                <option value="">Select Duration</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="1">1 hour</option>
                                <option value="2">2 hours</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                name="saturday_startTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.saturday.startTime || ''}
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                id="saturday_endTime"
                                name="saturday_endTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.saturday.endTime || ''}
                            />
                        </td>
                        <td>
                            <span className="badge badge-success">{this.props.scheduleData.saturday.numberOfSlots || 0}</span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <input
                                type="text"
                                className="form-control"
                                name="sunday_day"
                                disabled
                                value="Sunday"
                            />
                        </td>
                        <td>
                            <select
                                name="sunday_duration"
                                className="custom-select"
                                onChange={this.props.onEdit}
                                defaultValue={this.props.scheduleData.sunday.duration}
                            >
                                <option value="">Select Duration</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="1">1 hour</option>
                                <option value="2">2 hours</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                name="sunday_startTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.sunday.startTime || ''}
                            />
                        </td>
                        <td>
                            <input
                                type="time"
                                className="form-control"
                                id="sunday_endTime"
                                name="sunday_endTime"
                                onChange={this.props.onEdit}
                                value={this.props.scheduleData.sunday.endTime || ''}
                            />
                        </td>
                        <td>
                            <span className="badge badge-success">{this.props.scheduleData.sunday.numberOfSlots || 0}</span>
                        </td>
                    </tr>

                    </tbody>
                </table>

                <button className="btn btn-success btn-block savebtn" type="submit">
                    Save
                </button>
            </form>
        );
    }
}

export default ConsultationScheduleForm;
