import React, {useState} from "react";

function SpecimenItem(props) {
    const [refIntervalHolder, setRefIntervalHolder] = useState('single');
    const [refInterval1Holder, setRefInterval1Holder] = useState([]);
    const [refInterval2Holder, setRefInterval2Holder] = useState([]);
    const [SIUnit, setSIUnit] = useState("");
    const referIntervalChange = (e) => {
        props.onEdit(e);
        setRefIntervalHolder(e.target.value)
    }

    const refInterval1Change = (e) => {
        const value = e.target.value;
        if (value === 'single')
            setRefInterval1Holder(value);
        else if (value === 'two')
            setRefInterval1Holder([1,2]);
        else if (value === 'three')
            setRefInterval1Holder([1,2,3]);
        else
            setRefInterval1Holder([1,2,3,4]);

        props.onEdit(e);
    }

    const refInterval2Change = (e) => {
        const value = e.target.value;
        if (value === 'single')
            setRefInterval2Holder(value);
        else if (value === 'two')
            setRefInterval2Holder([1,2]);
        else if (value === 'three')
            setRefInterval2Holder([1,2,3]);
        else
            setRefInterval2Holder([1,2,3,4]);
        props.onEdit(e);
    }

    const onEditSIUnit = (e) => {
        props.onEdit(e)
        setSIUnit(e.target.value)
    }

    return <>
        <div className="form-row mb-3">
            <div className="col-md-10 offset-1 table-responsive">
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th>Item Name {props.index > 1 && <i className="fa fa-trash text-danger" data={props.item_id} onClick={props.remove}/> } </th>
                        <td><input type="text" name="SpecimenItemName" className="form-control" required="required" placeholder={"Sodium"} onChange={props.onEdit} defaultValue={props.data !== '' ? props.data.item_name : ''} item_id={props.item_id} specimen_id={props.specimen_id}/></td>
                    </tr>
                    <tr>
                        <th>SI Unit</th>
                        <td>
                            <select name="SpecimenItemSIUnit" className="form-control" defaultValue={props.data !== '' ? props.data.si_unit : ''} onChange={onEditSIUnit}>
                                <option value="" item_id={props.item_id} specimen_id={props.specimen_id}>Select SI Unit</option>
                                <option value="FI" item_id={props.item_id} specimen_id={props.specimen_id}>FI</option>
                                <option value="FL" item_id={props.item_id} specimen_id={props.specimen_id}>FL</option>
                                <option value="g/l" item_id={props.item_id} specimen_id={props.specimen_id}>g/l</option>
                                <option value="IU/l" item_id={props.item_id} specimen_id={props.specimen_id}>IU/l</option>
                                <option value="IU/ml" item_id={props.item_id} specimen_id={props.specimen_id}>IU/ml</option>
                                <option value="mg/day" item_id={props.item_id} specimen_id={props.specimen_id}>mg/day</option>
                                <option value="mg/l" item_id={props.item_id} specimen_id={props.specimen_id}>mg/l</option>
                                <option value="mg/dl" item_id={props.item_id} specimen_id={props.specimen_id}>mg/dl</option>
                                <option value="mg/l" item_id={props.item_id} specimen_id={props.specimen_id}>mg/l</option>
                                <option value="ML" item_id={props.item_id} specimen_id={props.specimen_id}>ML</option>
                                <option value="ML/Unit" item_id={props.item_id} specimen_id={props.specimen_id}>ML/Unit</option>
                                <option value="mIU/l" item_id={props.item_id} specimen_id={props.specimen_id}>mIU/l</option>
                                <option value="mIU/ml" item_id={props.item_id} specimen_id={props.specimen_id}>mIU/ml</option>
                                <option value="MM/HR" item_id={props.item_id} specimen_id={props.specimen_id}>MM/HR</option>
                                <option value="mm³" item_id={props.item_id} specimen_id={props.specimen_id}>mm³</option>
                                <option value="mmol/l" item_id={props.item_id} specimen_id={props.specimen_id}>mmol/l</option>
                                <option value="nmol/l" item_id={props.item_id} specimen_id={props.specimen_id}>nmol/l</option>
                                <option value="ng/ml" item_id={props.item_id} specimen_id={props.specimen_id}>ng/ml</option>
                                <option value="pmol/l" item_id={props.item_id} specimen_id={props.specimen_id}>pmol/l</option>
                                <option value="pg" item_id={props.item_id} specimen_id={props.specimen_id}>pg</option>
                                <option value="pg/ml" item_id={props.item_id} specimen_id={props.specimen_id}>pg/ml</option>
                                <option value="Seconds" item_id={props.item_id} specimen_id={props.specimen_id}>Seconds</option>
                                <option value="X10⁹/L" item_id={props.item_id} specimen_id={props.specimen_id}>X10⁹/L</option>
                                <option value="X10¹²/L" item_id={props.item_id} specimen_id={props.specimen_id}>X10¹²/L</option>
                                <option value="U/l" item_id={props.item_id} specimen_id={props.specimen_id}>U/l</option>
                                <option value="Ug/l" item_id={props.item_id} specimen_id={props.specimen_id}>Ug/l</option>
                                <option value="Unit" item_id={props.item_id} specimen_id={props.specimen_id}>Unit</option>
                                <option value="%" item_id={props.item_id} specimen_id={props.specimen_id}>%</option>
                                <option value="μmol/l" item_id={props.item_id} specimen_id={props.specimen_id}>μmol/l</option>
                                <option value="μg/l" item_id={props.item_id} specimen_id={props.specimen_id}>μg/l</option>
                                <option value="Other" item_id={props.item_id} specimen_id={props.specimen_id}>Other</option>
                            </select>
                        </td>
                    </tr>

                    {
                        SIUnit !== 'Other' &&
                        <>
                            <tr>
                                <th>Reference Interval</th>
                                <td>
                                    <select name="ReferenceIntervalChecker" required="required" defaultValue={props.data !== '' ? props.data.ref_interval : ''} className="form-control" onChange={referIntervalChange}>
                                        <option value="single" item_id={props.item_id} specimen_id={props.specimen_id}>Single Entry</option>
                                        <option value="dual" item_id={props.item_id} specimen_id={props.specimen_id}>Dual Entry</option>
                                    </select>
                                </td>
                            </tr>

                            {
                                refIntervalHolder === 'single' &&
                                <tr>
                                    <th>Reference Interval Value</th>
                                    <td>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <input type="number" step="0.01" name="single_min_interval" className="form-control" required="required" placeholder={"Minimum interval"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}
                                                       defaultValue={props.data !== '' ?
                                                           props.data.ref_interval === 'single' ? props.data.ref_interval_min : ''
                                                           : ''}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="number" step="0.01" name="single_max_interval" className="form-control" required="required" placeholder="Maximum interval" item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}
                                                       defaultValue={props.data !== '' ?
                                                           props.data.ref_interval === 'single' ? props.data.ref_interval_max : ''
                                                           : ''}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </>
                    }
                    </tbody>
                </table>
                {
                    refIntervalHolder !== 'single' &&
                    <div>
                        <h4>Reference Interval</h4>
                        <div className="form-row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="form-group">
                                    <label htmlFor="">Ref Interval 1</label>
                                    <select name="RefInterval1" className="form-control" onChange={refInterval1Change}>
                                        <option value="single" item_id={props.item_id} specimen_id={props.specimen_id}>Select Option</option>
                                        <option value="single" item_id={props.item_id} specimen_id={props.specimen_id}>Single Entry</option>
                                        <option value="two" item_id={props.item_id} specimen_id={props.specimen_id}>Two Entries</option>
                                        <option value="three" item_id={props.item_id} specimen_id={props.specimen_id}>Three Entries</option>
                                        <option value="four" item_id={props.item_id} specimen_id={props.specimen_id}>Four Entries</option>
                                    </select>
                                </div>

                                {
                                    refInterval1Holder === 'single' ?
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="">Ref Interval Name</label>
                                                <input type="text" name="double_single_name" className={"form-control"} placeholder={"Enter The Ref Name"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <input type="number" step="0.01" name="double_single_min_interval" required="required" className="form-control" placeholder={"Minimum interval"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="number" step="0.01" name="double_single_max_interval" required="required" className="form-control" placeholder="Maximum interval" item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                </div>
                                            </div>
                                        </> :
                                        <>
                                            {
                                                refInterval1Holder.length > 1 &&
                                                <div className="form-group">
                                                    <label htmlFor="">Main Ref Title</label>
                                                    <input type="text" name="main_ref_title" className={"form-control"} placeholder={"Enter The Ref Title"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                </div>
                                            }
                                            {
                                                refInterval1Holder.map((item, index) => {
                                                    return <div key={index}>
                                                        <div className="form-group">
                                                            <label htmlFor="">Ref Interval Name {(index+1)}</label>
                                                            <input type="text" name="double_double_name" index={index} className={"form-control"} placeholder={"Enter The Ref Name"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="col-md-6">
                                                                <input type="number" step="0.01" name="double_double_min_interval" required="required" index={index} className="form-control" placeholder={"Minimum interval"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input type="number" step="0.01" name="double_double_max_interval" required="required" index={index} className="form-control" placeholder="Maximum interval" item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </div>
                                                })
                                            }
                                        </>



                                }
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="">Ref Interval 2</label>
                                    <select name="RefInterval2" className="form-control" onChange={refInterval2Change} disabled={refInterval1Holder.length === 0}>
                                        <option value="single" item_id={props.item_id} specimen_id={props.specimen_id}>Select Option</option>
                                        <option value="single" item_id={props.item_id} specimen_id={props.specimen_id}>One Entry</option>
                                        <option value="two" item_id={props.item_id} specimen_id={props.specimen_id}>Two Entries</option>
                                        <option value="three" item_id={props.item_id} specimen_id={props.specimen_id}>Three Entries</option>
                                        <option value="four" item_id={props.item_id} specimen_id={props.specimen_id}>Four Entries</option>
                                    </select>
                                </div>

                                {
                                    refInterval2Holder === 'single' ?
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="">Ref Interval Name</label>
                                                <input type="text" name="double_single_name2" className={"form-control"} placeholder={"Enter The Ref Name"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <input type="number" step="0.01" name="double_single_min_interval2" required="required" className="form-control" placeholder={"Minimum interval"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="number" step="0.01" name="double_single_max_interval2" required="required" className="form-control" placeholder="Maximum interval" item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                </div>
                                            </div>
                                        </> :
                                        <>
                                            {
                                                refInterval2Holder.length > 1 &&
                                                <div className="form-group">
                                                    <label htmlFor="">Main Ref Title</label>
                                                    <input type="text" name="main_ref_title2" className={"form-control"} placeholder={"Enter The Ref Title"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                </div>
                                            }
                                        {
                                            refInterval2Holder.map((item, index) => {
                                                return <div key={index}>
                                                    <div className="form-group">
                                                        <label htmlFor="">Ref Interval Name {(index+1)}</label>
                                                        <input type="text" name="double_double_name2" index={index} className={"form-control"} placeholder={"Enter The Ref Name"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <input type="number" step="0.01" name="double_double_min_interval2" required="required" index={index} className="form-control" placeholder={"Minimum interval"} item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input type="number" step="0.01" name="double_double_max_interval2" required="required" index={index} className="form-control" placeholder="Maximum interval" item_id={props.item_id} specimen_id={props.specimen_id} onChange={props.onEdit}/>
                                                        </div>
                                                    </div>
                                                    <hr/>
                                                </div>
                                            })
                                        }
                                        </>


                                }
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    </>
}

export default SpecimenItem;
