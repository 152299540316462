import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./dashboard.css";
import { connect } from "react-redux";
import { loginValidation } from "../../../context/loginvalidation";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";
import MenuIcon from "../../Dashboard/utils/menu-icon";
import {FaCodePullRequest, FaFlaskVial, FaX} from "react-icons/fa6";
import {FaBan, FaCheck, FaDownload, FaVials} from "react-icons/fa";
import ApexChart from "react-apexcharts";
import Chart from "react-google-charts";

const ImagingDashboard = ({ permissionData, doctorDetails }) => {
    const [loading, setLoading] = useState(true);
    const [apexSeries, setApexSeries] = useState([]);
    const [imagingStatus, setImagingStatus] = useState([])
    const [apexChartOption, setApexChartOption] = useState({});
    const [labs, setLabs] = useState(0);
    const [pending, setPending] = useState(0);
    const [resultEntered, setResultEntered] = useState(0);
    const [cancelled, setCancelled] = useState(0);


    useEffect(() => {
        if (loginValidation(window.location.pathname, permissionData) !== 'permitted') {
            window.location.href = '/';
        }
        fetchDetails();
    }, []);

    const fetchDetails = async () => {
        try {
            const dashboard_response = await axios.get(`${serverLink}report/imaging/dashboard/${hospitalCode}`, doctorDetails.token);
            const imagingRequest = dashboard_response.data.imaging;
            const lab = dashboard_response.data.lab;

            let imaging_status = ['Pending', 'Result Entered', 'Cancelled'];

            //Set Count
            setPending(imagingRequest.filter(e => e.status === 'requested').length)
            setResultEntered(imagingRequest.filter(e => e.status === 'result entered').length)
            setCancelled(imagingRequest.filter(e => e.status === 'cancelled').length)
            setLabs(lab.length)

            let statusCountData =  [];
            let statusData =  [];
            let imagingStatusData = [];

            //Imaging trend by status
            imaging_status.map((e, i) => {
                let count = imagingRequest.filter(item => item.status === e.toLowerCase()).length
                imagingStatusData.push([e, count]);
            })

            lab.map((e, i) => {
                let count = imagingRequest.filter(item => item.labName === e.labName).length
                statusCountData.push(count)
                statusData.push(e.labName)
            })

            setImagingStatus(imagingStatusData)

            //Imaging Request trend by gender
            setApexChartOption({
                ...apexChartOption,
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: statusData
                }
            })
            setApexSeries([{
                name: "Count",
                data: statusCountData
            }])

            setLoading(false)
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    }

    const imagingAnalyticsData = [["Status", "Count"], ...imagingStatus];
    const imagingAnalyticsOptions = {
        title: "Imaging Request By Status",
        pieHole: 0.3,
        is3D: false,
    };


    return ( loading ? (
                <div className="dashboardpage">
                    <i className="fas fa-spinner fa-pulse fa-2x "/>
                </div>
            ) :
        <div className="dashboardpage">
            <div className="topheader">
                <ul>
                    <li>
                        <i
                            className="fa fa-microscope fa-2x"
                            aria-hidden="true"
                        />
                    </li>
                    <li>
                        <span>Imaging Dashboard</span>
                    </li>
                </ul>
            </div>

            <div className="first_section">
                <div className="row">
                    <div className="col-md-3">
                        <MenuIcon name="Laboratories" icon={<FaFlaskVial size={40}/>} count={labs}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Pending Request" icon={<FaCodePullRequest size={40}/>} count={pending}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Result Entered" icon={<FaDownload size={40}/>} count={resultEntered}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Result Cancelled" icon={<FaX size={40}/>} count={cancelled}/>
                    </div>
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                <h6><b>Imaging Request Trend By Imaging Labs</b></h6>
                                {

                                    <ApexChart
                                        options={apexChartOption}
                                        series={apexSeries}
                                        type="bar"
                                        height={300}
                                        width="100%"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    imagingStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="310px"
                                        data={imagingAnalyticsData}
                                        options={imagingAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="col-md-12 mt-3 mb-0" style={{borderBottom: '1px solid #CCCCCC', borderTop: '1px solid #CCCCCC', padding: '5px'}}>
                            <h2><b>Menu</b></h2>
                        </div>
                    </div>

                    {permissionData &&
                        permissionData.map((perm, index) => {
                            if (perm.mainMenuName === 'Imaging' && perm.mainMenuLink === null) {
                                return (
                                    <div key={index} className="col-sm-12 col-md-6 ">
                                        {" "}
                                        <Link to={perm.subMenuLink}>
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(ImagingDashboard);
