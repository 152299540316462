import axios from "axios";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { hospitalCode, serverLink } from "../../../../resources/url";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import "./add-slider.css";
import {connect} from "react-redux";
import SliderForm from "../slider-form/slider-form";
import { toast } from "react-toastify";


class AddSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,
            imagefile: "",
            htmlelement: "",
            formData: {
                title: "",
                btnLink: "",
                btnName: "",
                caption: "",
                imagePosition: "",
                image: "",
                hospitalCode: hospitalCode,
                submittedBy: ""
            },

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.state.submittedBy = this.props.loginDetails.fullName;

    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddSlider();
    };


    async onAddSlider() {
        let sendData = {
            ...this.state.formData,
            submittedBy: this.state.submittedBy
        };

        if(this.state.imagefile === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Image"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.imagePosition === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Image Position"
            this.handleErrorDialog();
            return false;
        }


        axios.post(`${this.state.serverLink}website/slider/add`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const id = result.data.entry_id;
                const response = result.data.message;
                if(response === "slider exist") {
                    this.state.messageDescription = "A slider with the same title already exist";
                    this.handleErrorDialog();
                } else if (response === "error") {
                    this.state.messageDescription = "Something went wrong adding the slider. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', id)
                        axios.patch(`${this.state.serverLink}website/slider/upload`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.state.messageDescription = "SLIDER ADDED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to upload the slider details!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    onImageChange = (event) => {
        const formData = this.state.formData;
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 2000000) {
                toast.error("max file size is 2mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: file,
                    htmlelement: e.target.result,
                    formData: {
                        ...formData,
                        image_name: file.name,
                        view: file.mozFullPath,

                    },
                });
            };
        }
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/website/slider';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Slider Added"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <SliderForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                onImageChange={this.onImageChange}
                                image={this.state.htmlelement}
                                title="Add Slider"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(AddSlider);