import React, { Component } from "react";
import "./drugform.css";
import Navigator from "../../../Settings/navigation/navigation";

class DrugForm extends Component {
    constructor() {
        super();
    }
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="drugName">Drug Name <span className="text-danger">*</span></label>
                                            <input
                                                name="drugName"
                                                type="text"
                                                className="form-control"
                                                id="drugName"
                                                value={this.props.data.drugName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="categoryName">Drug Category<span className="text-danger">*</span> </label>
                                            <select
                                                name="categoryName"
                                                id="categoryName"
                                                className="custom-select"
                                                value={this.props.data.categoryName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Drug Category</option>
                                                {
                                                    this.props.drugCategoryList.map((category, index) => {
                                                        return <option key={index} data={category.categoryName} value={category.categoryName}>{category.categoryName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                       
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="subCategoryName">Drug Sub Category </label>
                                            <select
                                                name="subCategoryName"
                                                id="subCategoryName"
                                                className="custom-select"
                                                value={this.props.data.subCategoryName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Drug Sub Category</option>
                                                {
                                                    this.props.drugSubCategoryList.map((category, index) => {
                                                        return <option key={index} data={category.subCategoryName} value={category.subCategoryName}>{category.subCategoryName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="subSubCategoryName">Drug Sub Sub Category  </label>
                                            <select
                                                name="subSubCategoryName"
                                                id="subSubCategoryName"
                                                className="custom-select"
                                                value={this.props.data.subSubCategoryName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Drug Sub Sub Category</option>
                                                {
                                                    this.props.drugSubSubCategoryList.map((category, index) => {
                                                        return <option key={index} data={category.subSubCategoryName} value={category.subSubCategoryName}>{category.subSubCategoryName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="manufacturerName">Drug Manufacturer</label>
                                            <select
                                                name="manufacturerName"
                                                id="manufacturerName"
                                                className="custom-select"
                                                value={this.props.data.manufacturerName}
                                                onChange={this.props.onEdit}>
                                                <option>Select Manufacturer</option>
                                                {
                                                    this.props.manufacturerList.map((manufacturer, index) => {
                                                        return <option key={index} data={manufacturer._id} value={manufacturer.manufacturerName}>{manufacturer.manufacturerName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="manufactureDate">Manufacture Date</label>
                                            <input
                                                name="manufactureDate"
                                                type="date"
                                                className="form-control"
                                                id="manufactureDate"
                                                value={this.formatDate(this.props.data.manufactureDate)}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="expiryDate">Drug Expiry Date</label>
                                            <input
                                                name="expiryDate"
                                                type="date"
                                                className="form-control"
                                                id="expiryDate"
                                                value={this.formatDate(this.props.data.expiryDate)}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="drugStockPrice">Drug Price <span className="text-danger">*</span></label>
                                            <input
                                                name="drugStockPrice"
                                                type="number"
                                                className="form-control"
                                                id="drugStockPrice"
                                                value={this.props.data.drugStockPrice}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="drugPrice">Sales Price <span className="text-danger">*</span></label>
                                            <input
                                                name="drugPrice"
                                                type="number"
                                                className="form-control"
                                                id="drugPrice"
                                                value={this.props.data.drugPrice}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="drugQuantity">Drug Quantity<span className="text-danger">*</span></label>
                                            <input
                                                name="drugQuantity"
                                                type="number"
                                                className="form-control"
                                                id="drugQuantity"
                                                value={this.props.data.drugQuantity}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="packSize">Pack Size </label>
                                            <input
                                                name="packSize"
                                                type="number"
                                                className="form-control"
                                                id="packSize"
                                                value={this.props.data.packSize}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>


                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="drugDescription">Drug Description</label>
                                            <textarea
                                                name="drugDescription"
                                                className="form-control"
                                                id="drugDescription"
                                                value={this.props.data.drugDescription}
                                                onChange={this.props.onEdit}
                                                rows="3"
                                            >{this.props.data.drugDescription}</textarea>
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DrugForm;
