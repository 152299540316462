export const loginDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_LOGIN_DETAILS":
      return action.payload;

    default:
      return state;
  }
};

export const permissionDetailsReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_PERMISSION_DETAILS":
      return action.payload;

    default:
      return state;
  }
};
