import React, { Component } from "react";
import "./changepasswordform.css";
import Navigator from "../../../Settings/navigation/navigation";

class ChangePasswordForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="password"> Old Password <span className="text-danger">*</span></label>
                                            <input
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                value={this.props.data.password}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="newPassword"> New Password <span className="text-danger">*</span></label>
                                            <input
                                                name="newPassword"
                                                type="password"
                                                className="form-control"
                                                id="newPassword"
                                                value={this.props.data.newPassword}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >
                                        <div className="col-md-6 offset-sm-3 mb-3">
                                            <label htmlFor="confirmPassword"> Confirm Password <span className="text-danger">*</span></label>
                                            <input
                                                name="confirmPassword"
                                                type="password"
                                                className="form-control"
                                                id="confirmPassword"
                                                value={this.props.data.confirmPassword}
                                                onChange={this.props.onEdit}
                                            />
                                        </div >

                                    </div>
                                    <div className="col-md-6 offset-sm-3 mb-3">
                                        <button className="btn btn-success btn-block update_btn" type="submit">
                                            Change Password
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePasswordForm;
