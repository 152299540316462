import React, { Component } from "react";
import { Line } from "react-chartjs-2";

class YearlyChart extends Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const start_year = 2021;
    const currentYear = currentDate.getFullYear();
    let year_object = [];
    for (let i = start_year; i <= currentYear; i++) {
      year_object.push(i);
    }

    const data_array = [];
    year_object.map(year => {
      data_array.push(this.props.year[year])
    })


    this.state = {
      data: {
        labels: year_object,
        datasets: [
          {
            label: "Number Of Patients Over Year",
            data: data_array,
            // borderColor: ["rgba(54,162,235,0.2)"],
            borderColor: "#47DBB3",
            backgroundColor: "white",
            borderWidth: 4,

            // backgroundColor: ["rgba(54,162,235,0.2)"],
            pontBackgroundColor: "rgba(54,162,235,0.2)",
            pointBorderColor: "rgba(54,162,235,0.2)",
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: "Patient",
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    };
  }

  render() {
    return (
      <Line
        data={this.state.data}
        options={this.state.options}
        height={50}
        width={100}
      ></Line>
    );
  }
}

export default YearlyChart;
