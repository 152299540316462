import React, {Component} from "react";
import "./add-vendor.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import {connect} from "react-redux";
import {showAlert} from "../../../../Common/sweetalert/sweetalert";
import ItemVendorForm from "../vendor-form/vendor-form";


class AddItemVendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"finance/",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                vendorName: "",
                hospitalCode: "",
                description: "",
                address: "",
                phoneNumber: "",
                emailAddress: "",
                submittedBy: "",
            },
            submittedBy: ""
        };
    }

    componentDidMount() {
        this.state.submittedBy = this.props.loginDetails.fullName;
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAdd();
    };

    async onAdd() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy
        };

        if(this.state.formData.vendorName === "") {
            this.setState({
                isLoading: false,
            });
            showAlert("EMPTY FIELD", "Please Enter the Vendor Name", "error");
            return false;
        }

        axios.post(`${this.state.serverLink}vendor/add`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "exist") {
                    showAlert("ERROR", "A vendor with the same name already exist", "error");
                    return false;
                } else if (response === "error") {
                    showAlert("ERROR", "Something went wrong adding the vendor. Please try again!", "error");
                    return false;
                } else if (response === "success"){
                    showAlert("VENDOR ADDED", "VENDOR ADDED SUCCESSFULLY", "success").then(()=>{
                        window.location.href = '/finance/inventory/vendor';
                    });
                    return false;
                } else {
                    showAlert("ERROR", "Failed to upload the vendor details!", "error");
                    return false;
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };


    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <ItemVendorForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Add vendor"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(AddItemVendor);

