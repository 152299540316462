import React, { Component } from "react";
import { Line } from "react-chartjs-2";

class PatientChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: this.props.label,
        datasets: [
          {
            label: this.props.description,
            data: this.props.data,
            // borderColor: ["rgba(54,162,235,0.2)"],
            borderColor: "#47DBB3",
            backgroundColor: "white",
            borderWidth: 4,

            // backgroundColor: ["rgba(54,162,235,0.2)"],
            pontBackgroundColor: "rgba(54,162,235,0.2)",
            pointBorderColor: "rgba(54,162,235,0.2)",
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: this.props.title,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    };
  }

  render() {
    return (
      <Line
        data={this.state.data}
        options={this.state.options}
        height={50}
        width={100}
      ></Line>
    );
  }
}

export default PatientChart;
