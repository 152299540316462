import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./admissionprofile";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {serverLink, hospitalCode, calculateAge} from "../../../resources/url";
import axios from "axios";
import PatientActionButton from "../../Patients/utils/patientaction";
import PatientChart from "../../Chart/patientchart";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import FormPrompt from "../../DialogBoxes/formprompt";
import PatientBloodPressureChart from "../../Chart/patientbloodpressurechart";
import JoditEditor from "jodit-react";
import {connect} from "react-redux";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import {Rating} from "react-simple-star-rating";
import PatientImagingRequest from "../../Imaging/patientimagingrequest/patientimagingrequest";
import Navigator from "../../Settings/navigation/navigation";
import ServiceRequest from "../../Services/servicerequest/servicerequest";
import PatientServiceList from "../../Services/patientservicelist/patientservicelist";
import {FaRegLaughBeam, FaSmile, FaRegMeh, FaSadCry, FaFrown} from "react-icons/fa";
import ChoosePayment from "../../Finance/choosepayment/choosepayment";
import AddAllergy from "../../Patients/addpatientallergy/addpatientallergy";
import OnlineConsultationForm from "../../Doctors/consultationpage/online-consultation-form";
import CaptureVital from "../../Appointment/capturevital/capturevital";
import PatientVitalList from "../../Patients/utils/patientvitals";
import ECTReactComponent from "../../Doctors/consultationpage/ECTReactComponent";
import ICDtable from "../../Doctors/consultationpage/view/icdtable";
import PatientAllergyList from "../../Patients/utils/patientallergies";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import {checkImageURL, defaultImage, imageExists} from "../../../resources/constants";
import doctor from "../../../Images/doctor.jpg";
import woman_doctor from "../../../Images/woman-doctor.jpg";

class AdmissionProfile extends Component {
    constructor(props) {
        super(props);
        let page_url = window.location.pathname;
        let page_id = page_url.split('/');
        let id = page_id[page_url.split('/').length - 1];
        this.state = {
            isLoading: true,
            admission_id: id,
            admissionData: '',
            admissionStatus: '',
            patientData: '',
            patientSerial: '',
            patient: '',
            access: this.props.userType,
            drugList: [],
            vitalList: [],
            allergicList: [],
            columns: [
                {
                    name: "Drug Name",
                    selector: row => `${row.drugName}`,
                    sortable: true,
                    width: '50%',
                },
                {
                    name: "Category",
                    selector: row => `${row.categoryName}`,
                    sortable: true
                },
                {
                    name: "Price",
                    selector: row => `${row.drugPrice}`,
                    sortable: true
                },
                {
                    name: "Quantity",
                    selector: row => `${row.drugQuantity}`,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <button
                            onClick={() => {this.addDrug(row.rowData)}}
                            type="button"
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-notes-medical" aria-hidden="true"/>
                        </button>
                    ),
                }
            ],
            doctorNotes: [],
            nurseNotes: [],
            nurseObservationNotes: [],
            tasks: [],
            vitals: '',
            prescription: [],
            prescriptionItemList: [],
            note1: '',
            note2: '',
            note3: '',
            note4: '',
            note5: '',
            note6: '',
            allergyImageSrc: '',

            labTestList: [],
            labTestColumns: [
                {
                    name: "Test Name",
                    selector: row => `${row.testName}`,
                    sortable: true
                },
                {
                    name: "Specimen",
                    selector: row => row.specimen.map((item, index) => { return <div key={index}>{item.specimenName} (Amount => {item.testAmount})</div> }),
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => row.specimen.map((item, index) => {
                        return <div key={index}>
                            <button
                                onClick={() => {this.addLabTest(row.rowData, index)}}
                                type="button"
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-vial" aria-hidden="true"> {item.specimenName} </i>
                            </button>
                        </div>


                    }),
                }
            ],
            labTestMessage: false,
            patientLabTestList: [],

            openShowAllergyImageDialog: false,
            isCloseDialogAppear: true,

            openDoctorNote: false,
            isNewNote: false,
            noteUpdateID: '',
            doctorNote1: '',
            doctorNote2: '',
            doctorNote3: '',
            doctorNote4: '',
            doctorNote5: '',
            doctorNote6: '',

            openDoctorTask: false,
            doctorTask: '',

            openNurseNote: false,
            isNewNurseNote: false,
            noteNurseUpdateID: '',
            nurseNote1: '',
            nurseNote2: '',
            nurseNote3: '',
            nurseNote4: '',
            nurseNote5: '',

            openNurseObservationNote: false,
            isNewNurseObservationNote: false,
            nurseObservationNote1: "",
            nurseObservationNote2: "",
            nurseObservationNote3: "",
            nurseObservationNote4: "",

            openPrescriptionModal: false,
            openErrorDialog: false,
            messageDescription: '',
            messageTitle: '',

            openAddDrugModal: false,
            prescriptionAmount: 0,
            drugDose: 0,
            drugName: '',
            drugQuantity: '',
            drugPrice: '',
            selectedDrug: '',
            prescriptionDetail: '',
            prescriptionQuantity: 1,
            prescriptionSubmitting: false,
            prescriptionSuccess: false,
            prescriptionError: false,
            openFinishDialog: false,

            openLabTestModal: false,
            labTestSubmitting: false,
            labTestSuccess: false,
            labTestError: false,

            graph_label: [],
            temp_val: [],
            bp_systolic_val: [],
            bp_diastolic_val: [],
            height_val: [],
            weight_val: [],

            openNurseAssessmentModal: false,

            openPainAssessmentModal: false,
            painRating: 1,
            painRatingName: 'No Pain',
            painComment: "",
            painAssessmentList: [],

            openNurseAssessmentSubmissionModal: false,
            nurseSelectedDrug: "",
            nurseSelectedDrugIndex: 0,
            nurseSelectedDrugTimeTaken: "",
            nurseSelectedDrugDoseGiven: "",
            openVitalDialog: false,

            imagingList: [],
            openImageModal: false,

            openServiceDialog: false,
            servicesList: [],
            walletAmount: 0,
            openICD11Note: false,
            icd11List: [],
            openAllergyDialog: false,
            openAlertDialog: false,

            dischargeNote: "",
            onOpenCancelModal: false,
            cancelData: "",
            cancelMessage: "Are you sure you want to cancel the selected test request?",

            onOpenCancelPrescriptionModal: false,
            cancelPrescriptionData: "",
            cancelPrescriptionItem: "",
            cancelPrescriptionMessage: "Are you sure you want to cancel the selected prescription?",
            onOpenImagingCancelModal: false,
            cancelImagingData: "",
            cancelImagingMessage: "Are you sure you want to cancel the selected Imaging Request?",
        }
    }

    componentWillMount() {
        this.getAdmissionData();
    }

    async getAdmissionData () {

        //ADMISSION RECORDS
        await axios.get(`${serverLink}patient_admission/${this.state.admission_id}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                if (typeof result.status === 'undefined') {
                    window.location.href = '/admission/dashboard'
                }
                this.setState({
                    admissionData: result,
                    patientSerial: result.patientSerial,
                    patient: result.patientName,
                    admissionStatus: result.status,
                    amountIncurred: result.roomAmount
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR APPOINTMENT FETCH',error)
            });

        //PATIENT RECORDS
        await axios.get(`${serverLink}patient/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;
                this.setState({
                    patientData: result,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log('NETWORK ERROR PATIENT FETCH', error)
            })


        //PATIENT VISIT NOTES
        this.getDoctorNote();

        //NOTE OBSERVATION NOTES
        this.getNurseObservationNote();

        //Prescriptions
        this.getPatientPrescription();

        //Tasks
        this.getPatientTasks();

        //Lab Tests
        this.getPatientLabTest();

        //Imaging
        this.getPatientImaging();

        //Allergies
        this.getPatientAllergies();


        await axios.get(`${serverLink}vital/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(data => {
                const result = data.data;

                this.setState({
                    vitalList: result,
                    vitals: result
                })

                if (result.length > 0) {
                    let graph_label = [];
                    let temp_val = [];
                    let weight_val = [];
                    let height_val = [];
                    let bp_systolic_val = [];
                    let bp_diastolic_val = [];
                    result.map((v, index) => {
                        if (index < 5) {
                            graph_label.push(this.formatDate(v.submittedOn))

                            //TEMPERATURE GRAPH GENERATOR
                            if (v.temperature !== '') {
                                if (v.temperature_unit !== 'C') {
                                    let fToCel = (parseFloat(v.temperature) - 32) * 5 / 9;
                                    temp_val.push(fToCel.toFixed(1));
                                } else {
                                    temp_val.push(parseFloat(v.temperature));
                                }
                            } else {
                                temp_val.push(0);
                            }

                            //HEIGHT GRAPH GENERATOR
                            if (v.height !== '') {
                                let height = parseFloat(v.height)
                                if (v.height_unit !== 'M') {
                                    let CMTom = height * 0.01;
                                    height_val.push(parseFloat(CMTom.toFixed(2)));
                                } else {
                                    height_val.push(parseFloat(height.toFixed(2)));
                                }
                            } else {
                                height_val.push(0);
                            }

                            //WEIGHT GRAPH GENERATOR
                            if (v.weight !== '') {
                                if (v.weight_unit !== 'KG') {
                                    let LBToKG = parseFloat(v.weight) * 0.453592;
                                    weight_val.push(parseFloat(LBToKG.toFixed(1)));
                                } else {
                                    weight_val.push(parseFloat(v.weight));
                                }
                            } else {
                                weight_val.push(0);
                            }

                            //BLOOD PRESSURE GRAPH GENERATOR
                            if (v.bloodPressure !== '') {
                                const bp = v.bloodPressure.split('/');
                                bp_systolic_val.push(parseInt(bp[0]));
                                bp_diastolic_val.push(parseInt(bp[1]));
                            } else {
                                bp_systolic_val.push(0);
                                bp_diastolic_val.push(0);
                            }

                        }
                    })

                    this.setState({
                        graph_label: graph_label.reverse(),
                        temp_val: temp_val.reverse(),
                        height_val: height_val.reverse(),
                        weight_val: weight_val.reverse(),
                        bp_systolic_val: bp_systolic_val.reverse(),
                        bp_diastolic_val: bp_diastolic_val.reverse()
                    })

                }
            })
            .catch(error => {
                console.log('NETWORK ERROR ALLERGY FETCH', error)
            })

        this.getPatientPainAssessment();

        await axios.get(`${serverLink}patient/current_wallet/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(wallet => {
                this.setState({
                    walletAmount: wallet.data.walletAmount
                })
            })
            .catch(err => {
                console.log(err)
            })

        this.getServicesList();

        if (this.state.admissionData.status !== 'discharged') {
            //UPDATE INCURRED AMOUNT
            await axios.patch(`${serverLink}patient_admission/update_incurred_amount`, {admissionID: this.state.admission_id}, this.props.doctorDetails.token).then().catch(err => { console.log('INCURRED AMOUNT NOT UPDATED') })
        }
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            return `${user_date.getDate()}/${monthNames[user_date.getMonth()]}/${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            return `${user_date.getDate()}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} : ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}`
        } else {
            return '--'
        }
    };

    moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    handleViewAllergyImageClicked = (e) => {

        this.setState({
            allergyImageSrc: e.target.srcset,
            openShowAllergyImageDialog: true
        });
    }

    // ====================== DOCTOR NOTE FUNCTIONS START =======================
    onOpenDoctorNote = () => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openDoctorNote: true,
                isNewNote: true,
                doctorNote1: "",
                doctorNote2: "",
                doctorNote3: "",
                doctorNote4: "",
                doctorNote5: "",
                doctorNote6: "",
            });
        }
    }
    onOpenUpdateDoctorNote = (note) => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openDoctorNote: true,
                isNewNote: false,
                doctorNote1: note.note1,
                doctorNote2: note.note2,
                doctorNote3: note.note3,
                doctorNote4: note.note4,
                doctorNote5: note.note5,
                doctorNote6: note.note6,
                noteUpdateID: note._id
            })
        }
    }
    async getDoctorNote () {
        await axios.get(`${serverLink}patient_admission/doctorNote/${hospitalCode}/${this.state.admission_id}`, this.props.doctorDetails.token)
            .then(result => {
                const note = result.data;
                this.setState({
                    isLoading: false,
                    doctorNotes: note.filter(item => item.isDoctor === true),
                    nurseNotes: note.filter(item => item.isDoctor === false)
                });

            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH ADMISSION NOTE:", error)
            })
    }
    doctorNote1Handler = (event) => {this.setState({ doctorNote1: event})}
    doctorNote2Handler = (event) => {this.setState({ doctorNote2: event})}
    doctorNote3Handler = (event) => {this.setState({ doctorNote3: event})}
    doctorNote4Handler = (event) => {this.setState({ doctorNote4: event})}
    doctorNote5Handler = (event) => {this.setState({ doctorNote5: event})}
    doctorNote6Handler = (event) => {this.setState({ doctorNote6: event})}
    dischargeNoteHandler = (event) => {this.setState({ dischargeNote: event})}
    submitDoctorNote = () => {
        this.setState({
            openDoctorNote: false
        });

        if (this.state.doctorNote1 === "" && this.state.doctorNote2 === "" && this.state.doctorNote3 === ""
            && this.state.doctorNote4 === "" && this.state.doctorNote5 === ""&& this.state.doctorNote6 === "") {
            return false;
        }


        if (!this.state.isNewNote) {
            //UPDATE ADMISSION NOTE
            const sendData = {
                doctorNote1: this.state.doctorNote1,
                doctorNote2: this.state.doctorNote2,
                doctorNote3: this.state.doctorNote3,
                doctorNote4: this.state.doctorNote4,
                doctorNote5: this.state.doctorNote5,
                doctorNote6: this.state.doctorNote6,
                updatedBy: this.props.doctorDetails.userID,
                _id: this.state.noteUpdateID,
                hospitalCode: hospitalCode,
            }
            axios.patch(`${serverLink}patient_admission/updateNote`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.getDoctorNote()
                    }
                })
                .catch(error => {
                    console.log('DOCTOR NOTE NOT UPDATED', error)
                })
        }
        else {
            //ADD DOCTOR NOTE
            const sendData = {
                patientSerial: this.state.patientSerial,
                hospitalCode: hospitalCode,
                admissionID: this.state.admission_id,
                doctorNote1: this.state.doctorNote1,
                doctorNote2: this.state.doctorNote2,
                doctorNote3: this.state.doctorNote3,
                doctorNote4: this.state.doctorNote4,
                doctorNote5: this.state.doctorNote5,
                doctorNote6: this.state.doctorNote6,
                doctorID: this.props.doctorDetails.userID,
                doctorName: this.props.doctorDetails.fullName,
                submittedBy: this.props.doctorDetails.userID
            }
            axios.post(`${serverLink}patient_admission/addNote`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.getDoctorNote()
                    }
                })
                .catch(error => {
                    console.log('ADMISSION NOTE NOT ADDED', error)
                })
        }
    }
    // ====================== DOCTOR NOTE FUNCTIONS END ==========================

    // ====================== Nurse Care Plan FUNCTIONS START =======================
    onOpenNurseNote = () => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openNurseNote: true,
                isNewNurseNote: true,
                nurseNote1: "",
                nurseNote2: "",
                nurseNote3: "",
                nurseNote4: "",
                nurseNote5: "",
            });
        }
    }
    onOpenUpdateNurseNote = (note) => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openNurseNote: true,
                isNewNurseNote: false,
                nurseNote1: note.note1,
                nurseNote2: note.note2,
                nurseNote3: note.note3,
                nurseNote4: note.note4,
                nurseNote5: note.note5,
                noteUpdateID: note._id
            })
        }
    }

    nurseNote1Handler = (event) => {
        this.setState({ nurseNote1: event})
    }
    nurseNote2Handler = (event) => {
        this.setState({ nurseNote2: event})
    }
    nurseNote3Handler = (event) => {
        this.setState({ nurseNote3: event})
    }
    nurseNote4Handler = (event) => {
        this.setState({ nurseNote4: event})
    }
    nurseNote5Handler = (event) => {
        this.setState({ nurseNote5: event})
    }

    submitNurseNote = () => {
        this.closeDialog();
        if (this.state.nurseNote1 === "" && this.state.nurseNote3 === "" && this.state.nurseNote3 === "" && this.state.nurseNote4 === "" && this.state.nurseNote5 === "") {
            return false;
        }

        if (!this.state.isNewNurseNote) {
            //UPDATE ADMISSION NOTE
            const sendData = {
                nurseNote1: this.state.nurseNote1,
                nurseNote2: this.state.nurseNote2,
                nurseNote3: this.state.nurseNote3,
                nurseNote4: this.state.nurseNote4,
                nurseNote5: this.state.nurseNote5,
                updatedBy: this.props.doctorDetails.userID,
                _id: this.state.noteUpdateID,
                hospitalCode: hospitalCode,
            }
            axios.patch(`${serverLink}patient_admission/updateNurseNote`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.getDoctorNote()
                    }
                })
                .catch(error => {
                    console.log('NURSE CARE PLAN NOT UPDATED', error)
                })
        }
        else {
            //ADD DOCTOR NOTE
            const sendData = {
                patientSerial: this.state.patientSerial,
                hospitalCode: hospitalCode,
                admissionID: this.state.admission_id,
                nurseNote1: this.state.nurseNote1,
                nurseNote2: this.state.nurseNote2,
                nurseNote3: this.state.nurseNote3,
                nurseNote4: this.state.nurseNote4,
                nurseNote5: this.state.nurseNote5,
                doctorID: this.props.doctorDetails.userID,
                doctorName: this.props.doctorDetails.fullName,
                submittedBy: this.props.doctorDetails.userID
            }

            axios.post(`${serverLink}patient_admission/addNurseNote`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.getDoctorNote()
                    }
                })
                .catch(error => {
                    console.log('ADMISSION NOTE NOT ADDED', error)
                })
        }
    }
    // ====================== NURSE CARE PLAN FUNCTIONS END ==========================


    // ====================== Nurse OBSERVATION FUNCTIONS START =======================
    onOpenNurseObservationNote = () => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openNurseObservationNote: true,
                isNewNurseObservationNote: true,
                nurseObservationNote1: "",
                nurseObservationNote2: "",
                nurseObservationNote3: "",
                nurseObservationNote4: "",
            });
        }
    }
    onOpenUpdateNurseObservationNote = (note) => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openNurseObservationNote: true,
                isNewNurseObservationNote: false,
                nurseObservationNote1: note.note1,
                nurseObservationNote2: note.note2,
                nurseObservationNote3: note.note3,
                nurseObservationNote4: note.note4,
                observationNoteUpdateID: note._id
            })
        }
    }
    async getNurseObservationNote () {
        await axios.get(`${serverLink}patient_admission/nurse/note/${hospitalCode}/${this.state.admission_id}`, this.props.doctorDetails.token)
            .then(result => {
                const note = result.data;
                this.setState({
                    isLoading: false,
                    nurseObservationNotes: note
                });

            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH NURSE NOTE:", error)
            })
    }

    nurseObservationNote1Handler = (event) => {
        this.setState({ nurseObservationNote1: event})
    }
    nurseObservationNote2Handler = (event) => {
        this.setState({ nurseObservationNote2: event})
    }
    nurseObservationNote3Handler = (event) => {
        this.setState({ nurseObservationNote3: event})
    }
    nurseObservationNote4Handler = (event) => {
        this.setState({ nurseObservationNote4: event})
    }

    submitNurseObservationNote = () => {
        this.closeDialog();
        if (this.state.nurseObservationNote1 === "" && this.state.nurseObservationNote2 === "" && this.state.nurseObservationNote3 === "" && this.state.nurseObservationNote4 === "") {
            return false;
        }

        if (!this.state.isNewNurseObservationNote) {
            //UPDATE NURSE OBSERVATION NOTE
            const sendData = {
                nurseNote1: this.state.nurseObservationNote1,
                nurseNote2: this.state.nurseObservationNote2,
                nurseNote3: this.state.nurseObservationNote3,
                nurseNote4: this.state.nurseObservationNote4,
                updatedBy: this.props.doctorDetails.userID,
                _id: this.state.observationNoteUpdateID,
                hospitalCode: hospitalCode,
            }
            axios.patch(`${serverLink}patient_admission/nurse/note/update`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.getNurseObservationNote()
                    }
                })
                .catch(error => {
                    console.log('NURSE OBSERVATION NOTE NOT UPDATED', error)
                })
        }
        else {
            //ADD NURSE OBSERVATION NOTE
            const sendData = {
                patientSerial: this.state.patientSerial,
                hospitalCode: hospitalCode,
                admissionID: this.state.admission_id,
                nurseNote1: this.state.nurseObservationNote1,
                nurseNote2: this.state.nurseObservationNote2,
                nurseNote3: this.state.nurseObservationNote3,
                nurseNote4: this.state.nurseObservationNote4,
                doctorID: this.props.doctorDetails.userID,
                doctorName: this.props.doctorDetails.fullName,
                submittedBy: this.props.doctorDetails.userID
            }

            axios.post(`${serverLink}patient_admission/nurse/note/add`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    const response = result.data.message;
                    if (response === 'success') {
                        this.getNurseObservationNote()
                    }
                })
                .catch(error => {
                    console.log('NURSE OBSERVATION NOTE NOT ADDED', error)
                })
        }
    }
    // ====================== NURSE OBSERVATION FUNCTIONS END ==========================

    // ======================== DOCTOR TASK START ================================
    onOpenDoctorTask = () => {
        document.getElementById('AddTaskForm').reset();
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openDoctorTask: true,
                prescriptionError: false,
                prescriptionSuccess: false,
                prescriptionSubmitting: false,
            });
        }
    }
    async getPatientTasks () {
        await axios.get(`${serverLink}patient_admission/doctorTask/${hospitalCode}/${this.state.admission_id}`, this.props.doctorDetails.token)
            .then(result => {
                const task = result.data;
                this.setState({
                    isLoading: false,
                    tasks: task,
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH CONSULTATION TASK:", error)
            })
    }
    submitAdmissionTask = (event) => {
        event.preventDefault();
        const task = document.getElementById('doctorTask').value;
        this.setState({
            prescriptionError: false,
            prescriptionSuccess: false,
            prescriptionSubmitting: true,
        });
        const sendData = {
            patientSerial: this.state.patientSerial,
            hospitalCode: hospitalCode,
            admissionID: this.state.admission_id,
            task: task,
            doctorID: this.props.doctorDetails.userID,
            doctorName: this.props.doctorDetails.fullName,
            submittedBy: this.props.doctorDetails.userID
        }

        axios.post(`${serverLink}patient_admission/addTask`, sendData, this.props.doctorDetails.token)
            .then(result => {
                const response = result.data.message;
                if (response === 'success') {
                    this.getPatientTasks();
                    this.setState({
                        prescriptionSuccess: true,
                        prescriptionSubmitting: false,
                    });

                    const object = this;
                    setTimeout(function () {
                        object.setState({
                            openDoctorTask: false
                        })

                    },2000)

                }
            })
            .catch(error => {
                console.log('ADMISSION TASK NOT ADDED', error)
                this.setState({
                    prescriptionError: true,
                    prescriptionSubmitting: false,
                })
            })
    }
    onChangeAdmissionTaskStatus = (task) => {
        const sendData = {
            taskStatus: task.taskStatus === 'incomplete' ? 'completed' : 'incomplete',
            submittedBy: this.props.doctorDetails.userID,
            id: task._id
        }

        axios.patch(`${serverLink}patient_admission/updateTask`, sendData, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.message === 'success') {
                    this.getPatientTasks();
                }
            })
            .catch(error => {
                console.log("NETWORK ERROR UPDATE CLINICAL TASK:", error)
            })

    }
    // ====================== DOCTOR TASK END ======================================

    // ===================== DRUG FUNCTIONS START ==================================
    onLoadDrugs = () => {
        axios.get(`${serverLink}pharmacy/drugs/active/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    data.map(val => {
                        this.setState({
                            drugList: [...this.state.drugList,
                                {
                                    id: val._id,
                                    drugName: val.drugName,
                                    categoryName: val.categoryName,
                                    manufacturerName: val.manufacturerName,
                                    expiryDate: this.formatDate(val.expiryDate),
                                    drugPrice: this.moneyFormat(val.drugPrice),
                                    drugQuantity: val.drugQuantity,
                                    rowData: val
                                }
                            ]
                        })
                    })
                    this.setState({
                        openPrescriptionModal: true,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        messageTitle: 'NO DRUGS FOUND',
                        messageDescription: 'Drugs cannot be found in the pharmacy',
                        isLoading: false,
                        openErrorDialog: true
                    })
                }
            })
            .catch(error => {
                console.log('NETWORK ERROR FETCH DRUG DATA', error)
            })
    }
    onOpenPrescriptionModal =() => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                isLoading: true
            })

            if (this.state.drugList.length > 0) {
                this.setState({
                    isLoading: false,
                    openPrescriptionModal: true
                })
            } else {
                this.onLoadDrugs();
            }

        }
    }
    addDrug = (drug) => {
        this.setState({
            openPrescriptionModal: false,
            openAddDrugModal: true,
            drugName: drug.drugName,
            drugPrice: drug.drugPrice,
            drugQuantity: drug.drugQuantity,
            prescriptionSubmitting: false,
            prescriptionSuccess: false,
            prescriptionError: false,
            prescriptionDetail: '',
            prescriptionQuantity: 1
        })
    }
    prescribeDrug = (e) => {
        e.preventDefault();
        this.setState({
            prescriptionSubmitting: true,
            prescriptionSuccess: false,
            prescriptionError: false
        });
        const prescription = document.getElementById('prescriptionDetail').value;
        const dose = document.getElementById('prescriptionDose').value;
        const frequency = document.getElementById('prescriptionFrequency').value;
        const quantity = parseInt(document.getElementById('prescriptionQuantity').value);
        const drugName = this.state.drugName;
        const drugPrice = this.state.drugPrice;
        const prescriptionAmount = quantity*drugPrice;

        const drugData = {drugName: drugName, drugQuantity: quantity, drugAmount: drugPrice, drugDose: dose, doseFrequency: frequency, prescription: prescription, status: 'unpaid'}

        if (this.state.prescription.length < 1) {
            //ADD
            const sendData = {
                patientSerial: this.state.admissionData.patientSerial,
                patientName: this.state.admissionData.patientName,
                doctorID: this.props.doctorDetails.userID,
                doctorName: this.props.doctorDetails.fullName,
                prescriptionDetail: [drugData],
                prescriptionAmount: prescriptionAmount,
                hospitalCode: hospitalCode,
                admissionID: this.state.admission_id
            }
            axios.post(`${serverLink}pharmacy/admission/prescription/add`, sendData, this.props.doctorDetails.token)
                .then(result => {
                    if (result.data.message === 'success') {
                        document.getElementById('PrescriptionForm').reset()
                        this.getPatientPrescription();
                        this.setState({
                            prescriptionSubmitting: false,
                            prescriptionSuccess: true,
                            prescriptionError: false,
                            prescriptionDetail: '',
                            prescriptionQuantity: 1
                        })

                        let object = this
                        setTimeout(function () {
                            object.closeAddDrugDialog();
                        },2000)
                    } else {
                        this.setState({
                            prescriptionSubmitting: false,
                            prescriptionSuccess: false,
                            prescriptionError: true
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        prescriptionSubmitting: false,
                        prescriptionSuccess: false,
                        prescriptionError: true
                    })
                })
        } else  {
            //UPDATE
            const sendData = {
                patientSerial: this.state.admissionData.patientSerial,
                updatedBy: this.props.doctorDetails.userID,
                prescriptionDetail: [...this.state.prescription[0].prescriptionDetail, drugData],
                prescriptionAmount: prescriptionAmount+this.state.prescription[0].prescriptionAmount,
                hospitalCode: hospitalCode,
                admissionID: this.state.admission_id
            }

            axios.patch(`${serverLink}pharmacy/admission/prescription/update`, sendData, this.props.doctorDetails.token)
                .then(data => {
                    if (data.data.message === 'success') {
                        document.getElementById('PrescriptionForm').reset()
                        this.getPatientPrescription();
                        this.setState({
                            prescriptionSubmitting: false,
                            prescriptionSuccess: true,
                            prescriptionError: false,
                            prescriptionDetail: '',
                            prescriptionQuantity: 1
                        })

                        let object = this
                        setTimeout(function () {
                            object.closeAddDrugDialog()
                        },2000)
                    } else {
                        this.setState({
                            prescriptionSubmitting: false,
                            prescriptionSuccess: false,
                            prescriptionError: true
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        prescriptionSubmitting: false,
                        prescriptionSuccess: false,
                        prescriptionError: true
                    })
                })
        }

    }
    async getPatientPrescription () {
        await axios.get(`${serverLink}pharmacy/admission/prescription/${hospitalCode}/${this.state.admission_id}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                this.setState({
                    isLoading: false,
                    prescription: data
                });
                if (data.length > 0) {
                    this.setState({
                        prescriptionItemList: data[0].prescriptionDetail.reverse()
                    })
                }
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH PRESCRIPTION:", error)
            })
    }
    // ===================== DRUG FUNCTIONS ENDS ====================================

    // ====================== LAB FUNCTIONS START ================================
    onLoadLabTest = () => {
        axios.get(`${serverLink}laboratory/test/active/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    data.map(val => {
                        this.setState({
                            labTestList: [...this.state.labTestList,
                                {
                                    id: val._id,
                                    labName: val.laboratoryName,
                                    testName: val.testName,
                                    specimen: val.specimen,
                                    rowData: val
                                }
                            ]
                        })
                    })
                    this.setState({
                        openLabTestModal: true,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        messageTitle: 'NO TEST FOUND',
                        messageDescription: 'Test cannot be found in the lab',
                        isLoading: false,
                        openErrorDialog: true
                    })
                }
            })
            .catch(error => {
                console.log('NETWORK ERROR FETCH TEST DATA', error)
            })
    }

    onOpenLabTestModal =() => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                isLoading: true
            })

            if (this.state.labTestList.length > 0) {
                this.setState({
                    isLoading: false,
                    openLabTestModal: true
                })
            } else {
                this.onLoadLabTest();
            }
        }
    }

    addLabTest = (test, index) => {
        this.setState({
            labTestMessage: 'submitting'
        });
        const sendData = {
            requestSerial: Math.floor(100000 + Math.random() * 9000000000000000),
            requestID: this.state.admission_id,
            laboratoryName: test.laboratoryName,
            specimen: test.specimen[index],
            testAmount: test.specimen[index].testAmount,
            testID: test._id,
            testResultTemplate: test.testResultTemplate,
            testName: test.testName,
            patientSerial: this.state.admissionData.patientSerial,
            patientName: this.state.admissionData.patientName,
            doctorID: this.props.doctorDetails.userID,
            doctorName: this.props.doctorDetails.fullName,
            submittedBy: this.props.doctorDetails.userID,
            submittedByName: this.props.doctorDetails.fullName,
            hospitalCode: hospitalCode,
        }

        axios.post(`${serverLink}laboratory/test/request/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    labTestMessage: result.data.message
                })

                const object = this
                this.getAdmissionData();
                setTimeout(function () {
                    object.setState({
                        labTestMessage: false
                    })
                }, 3000)
            })
            .catch(error => {
                this.setState({
                    labTestMessage: 'failed'
                })
            })
    }

    async getPatientLabTest () {
        await axios.get(`${serverLink}laboratory/test/request/admission/${this.state.admission_id}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                this.setState({
                    patientLabTestList: data,
                    isLoading: false
                });

            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH PATIENT TESTS:", error)
            })
    }
    // ====================== LAB  FUNCTIONS ENDS ===============================

    // ================== NURSE ASSESSMENT START ================================
    onOpenNurseAssessment = () => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openNurseAssessmentModal: true
            })
        }
    }

    onOpenNurseAssessmentSubmission = (item, index) => {
        this.setState({
            openNurseAssessmentModal: false,
            openNurseAssessmentSubmissionModal: true,
            nurseSelectedDrug: item,
            nurseSelectedDrugIndex: index,
            nurseSelectedDrugTimeTaken: "",
            nurseSelectedDrugDoseGiven: "",
            prescriptionSuccess: false,
            prescriptionError: false,
            prescriptionSubmitting: false
        });
    }

    onEditNurseAssessment = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        if (name === 'timeTaken')
            this.setState({ nurseSelectedDrugTimeTaken: value })
        else
            this.setState({ nurseSelectedDrugDoseGiven: value })
    }

    onSubmitNurseAssessment = (e) => {
        e.preventDefault();
        this.setState({
            prescriptionSuccess: false,
            prescriptionError: false,
            prescriptionSubmitting: true
        })
        const time_taken = this.state.nurseSelectedDrugTimeTaken;
        const dose_given = this.state.nurseSelectedDrugDoseGiven;
        let prescription_data = this.state.prescription[0].prescriptionDetail[this.state.nurseSelectedDrugIndex];
        if (typeof  prescription_data.assessment === "undefined")
            prescription_data.assessment = [];

        prescription_data.assessment.push(
            {
                timeTaken: time_taken, doseGive:  dose_given,
                submittedBy: this.props.doctorDetails.fullName, submittedDate: this.formatDateAndTime(new Date())
            }
        );

        const sendData = {
            _id: this.state.prescription[0]._id,
            prescriptionDetail: this.state.prescription[0].prescriptionDetail
        }

        axios.patch(`${serverLink}pharmacy/admission/prescription_assessment`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    this.setState({
                        prescriptionSuccess: true,
                        prescriptionError: false,
                        prescriptionSubmitting: false
                    })
                    this.getPatientPrescription();
                    setTimeout(() => {
                        this.setState({
                            openNurseAssessmentModal: true,
                            openNurseAssessmentSubmissionModal: false,
                        })
                    },1000)
                }
                else
                    this.setState({
                        prescriptionSuccess: false,
                        prescriptionError: true,
                        prescriptionSubmitting: false
                    })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    prescriptionSuccess: false,
                    prescriptionError: true,
                    prescriptionSubmitting: false
                })
            })

        console.log(sendData)
    }

    onCloseNurseAssessment = () => {
        this.setState({
            openNurseAssessmentModal: false,
        })
    }
    onCloseNurseAssessmentSubmission = () => {
        this.setState({
            openNurseAssessmentModal: true,
            openNurseAssessmentSubmissionModal: false
        })
    }
    // ================= NURSE ASSESSMENT END ==================================

    // =================== PAIN ASSESSMENT START =================================
    onOpenPainAssessment = () => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openPainAssessmentModal: true,
                prescriptionSubmitting: false,
                prescriptionError: false,
                prescriptionSuccess: false
            })
        }

    }
    onClosePainAssessment = () => {
        this.setState({
            painRating: 1,
            painRatingName: 'No Pain',
            openPainAssessmentModal: false,
            painComment: ""
        })
    }

    handlePainAssessmentRating = (e) => {
        let pain_rating = '';
        if (e === 1)
            pain_rating = 'No Pain';
        else if (e === 2)
            pain_rating = 'Mild Pain';
        else if (e === 3)
            pain_rating = 'Moderate Pain';
        else if (e === 4)
            pain_rating = 'Severe Pain';
        else
            pain_rating = 'Very Severe Pain';

        this.setState({
            painRating: e,
            painRatingName: pain_rating
        })
    }

    onEditPainComment = (e) => {
        this.setState({
            painComment: e.target.value
        })
    }

    onSubmitPainAssessment = (e) => {
        e.preventDefault();
        if (this.state.painRating > 3 && this.state.painComment.trim() === "") {
            this.setState({
                prescriptionError: true,
                prescriptionSubmitting: false
            });
            return false;
        }

        this.setState({
            prescriptionSubmitting: true,
            prescriptionError: false
        });

        const sendData = {
            patientSerial: this.state.patientData.patientSerial,
            hospitalCode: hospitalCode,
            admissionID: this.state.admission_id,
            painRating: this.state.painRating/1,
            painRatingName: this.state.painRatingName,
            painComment: this.state.painComment,
            nurseID: this.props.doctorDetails.userID,
            nurseName: this.props.doctorDetails.fullName,
            submittedBy: this.props.doctorDetails.userID
        }

        axios.post(`${serverLink}patient_admission/addPainAssessment`, sendData, this.props.doctorDetails.token)
            .then(res => {
                const response = res.data.message;
                if (response === 'success') {
                    this.setState({
                        prescriptionSubmitting: false,
                        prescriptionError: false,
                        prescriptionSuccess: true
                    });
                    this.getPatientPainAssessment();
                } else {
                    this.setState({
                        prescriptionSubmitting: false,
                        prescriptionError: true,
                        prescriptionSuccess: false
                    });
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    prescriptionSubmitting: false,
                    prescriptionError: true,
                    prescriptionSuccess: false
                });
            })
    }

    async getPatientPainAssessment () {
        await axios.get(`${serverLink}patient_admission/painAssessment/${hospitalCode}/${this.state.admission_id}`, this.props.doctorDetails.token)
            .then(result => {
                const assessment = result.data;
                this.setState({
                    isLoading: false,
                    painAssessmentList: assessment,
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH CONSULTATION PAIN ASSESSMENT:", error)
            })
    }

    // =================== PAIN ASSESSMENT ENDS ==============================

    // ===================== IMAGING START ======================================
    async getPatientImaging () {
        await axios.get(`${serverLink}imaging/patient/request/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const imaging = result.data;
                this.setState({
                    isLoading: false,
                    imagingList: imaging,
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH CONSULTATION IMAGING:", error)
            })
    }

    onOpenImageModal = () => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openImageModal: true
            })
        }
    }

    onImageModalClose = () => {
        this.setState({
            openImageModal: false
        });
        this.getPatientImaging();
    }
    // ==================== IMAGING END =========================================

    closeDialog = () => {
        this.setState({
            openShowAllergyImageDialog: false,
            openDoctorNote: false,
            openNurseNote: false,
            openNurseObservationNote: false,
            openPrescriptionModal: false,
            openLabTestModal: false,
            openErrorDialog: false,
            openDoctorTask: false,
            openFinishDialog: false,
            labTestMessage: false,
            openPainAssessmentModal: false
        });
    };

    closeAddDrugDialog = () => {
        this.setState({
            openAddDrugModal: false,
            openPrescriptionModal: true
        });
    };

    openFinishDialog = () => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openFinishDialog: true
            });
        }
    }

    handleDrugQuantityChange = (e) => {
        this.setState({
            drugDose: parseInt(e.target.value)
        })
    }

    //================= SERVICES =====================
    onOpenServiceModal = () => {
        if (this.state.admissionData.status === 'active') {
            this.setState({
                openServiceDialog: true
            });
        }

    }

    closeServiceDialog = () => {
        this.setState({
            openServiceDialog: false,
        });
        window.location.reload();
    };

    getServicesList = () => {
        axios.get(`${serverLink}service/request/patient/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    servicesList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    //=============== END SERVICES ==================

    //============== HANDLE PAYMENT RESPOND ============
    handlePaymentResponse = () => {
        window.location.reload();
    }
    //============== END HANDLE PAYMENT RESPOND =========

    //=============== CAPTURE VITALS==================
    handleVitalActionClicked = () => {
        this.setState({
            openVitalDialog: true
        })
    }
    closeVitalDialog = () => {
        this.setState({
            openVitalDialog: false,
        });
    };

    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    handleSuccessDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openOtherInfoDialog: false,
            openAlertDialog: true,
        });
        window.location.reload();
    };

    handleErrorDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAllergyDialog: false,
            openImageDialog: false,
            openAddAppointmentDialog: false,
            openOtherInfoDialog: false,
            openUpdateAppointmentDialog: false,
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };

    async getPatientICDCodes () {
        await axios.get(`${serverLink}icd/patient/${this.state.patientSerial}/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    icd11List: result.data,
                });
            })
            .catch(error => {
                console.log("NETWORK ERROR FETCH PATIENT ICD CODES:", error)
            })
    }
    closeICDCodeDialog = () => {
        this.getPatientICDCodes();
        this.setState({
            openICD11Note: false
        })
    }

    onOpenICD11Note = () => {
        if (this.state.admissionData.status !== 'Completed') {
            this.setState({
                openICD11Note: true
            });
        }
    }

    getPatientAllergies = () => {
        axios.get(`${serverLink}allergy/${hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    allergicList: result.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleAllergyActionClicked = () => {
        this.setState({
            openAllergyDialog: true
        });
    }

    closeAllergyImageDialog = () => {
        this.setState({
            openShowAllergyImageDialog: false,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
    };

    closeAllergyDialog = () => {
        this.setState({
            openAllergyDialog: false,
        });
    };

    handleSuccessDialogPopup = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAllergyDialog: false,
            openAlertDialog: true,
        });
        this.getPatientAllergies();
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
            onOpenCancelModal: false,
            onOpenCancelPrescriptionModal: false,
            onOpenImagingCancelModal: false
        });
    };

    handleOnCancelRequestClick = (row) => {
        this.setState({
            onOpenCancelModal: true,
            cancelData: row
        })
    }

    handleOnCancelRequest = async () => {
        this.setState({
            cancelMessage: <span className={"alert alert-info"}>Cancelling Request... Please wait!</span>
        });

        const sendData = {id: this.state.cancelData, updatedBy: this.props.doctorDetails.userID}
        await axios.patch(`${serverLink}laboratory/test/request/cancel`, sendData, this.props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        cancelMessage: <span className={"alert alert-success"}>Request Cancelled Successfully</span>
                    });
                    this.getPatientLabTest();
                    const object = this;
                    setTimeout(function () {
                        object.closeErrorDialog();
                    }, 1000)

                } else {
                    this.setState({
                        cancelMessage: <span className={"alert alert-danger"}>Something went wrong cancelling request. Please check your network and try again!</span>
                    });
                }
            })
            .catch()
    }


    handleOnCancelPrescriptionRequestClick = (prescription, item) => {
        this.setState({
            onOpenCancelPrescriptionModal: true,
            cancelPrescriptionData: prescription,
            cancelPrescriptionItem: item,
        })
    }
    handleOnCancelPrescriptionRequest = async () => {
        const sendData = this.state.cancelPrescriptionData;
        const itemData = this.state.cancelPrescriptionItem;
        let filteredItems = sendData.prescriptionDetail.filter(e => e.drugName !== itemData.drugName);
        let prescriptionAmount = 0;
        let formData = {
            ...sendData,
            id: sendData._id,
            cartItems: [],
            updatedBy: this.props.doctorDetails.userID
        };

        filteredItems.push({
            drugName: itemData.drugName,
            drugQuantity: parseInt(itemData.drugQuantity),
            drugAmount: itemData.drugAmount,
            drugDose: itemData.drugDose,
            doseFrequency: itemData.doseFrequency,
            prescription: itemData.prescription,
            status: "canceled",
        })
        filteredItems.map(e => { formData.cartItems.push(e) })
        filteredItems.filter(e=>e.status !== "canceled").map((item, index) => {
            prescriptionAmount +=+ parseFloat(item.drugAmount) * parseInt(item.drugQuantity);
        })

        let fd = {  ...formData, prescriptionAmount: prescriptionAmount, paymentStatus: prescriptionAmount === 0 ? "canceled" : "unpaid"}

        this.setState({
            cancelPrescriptionMessage: <span className={"alert alert-info"}>Cancelling Prescription... Please wait!</span>
        });

        await axios.patch(`${serverLink}pharmacy/prescription/items/update`, fd, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.setState({cancelPrescriptionMessage: <span className={"alert alert-success"}>Prescription Cancelled Successfully</span>});
                    this.getPatientPrescription();
                    const object = this;
                    setTimeout(function () {
                        object.closeErrorDialog();
                    }, 1000)
                } else {
                    this.setState({
                        cancelPrescriptionMessage: <span className={"alert alert-danger"}>Something went wrong cancelling prescription.</span>
                    });
                }
            })
            .catch(err => {
                this.setState({
                    cancelPrescriptionMessage: <span className={"alert alert-danger"}>Something went wrong cancelling prescription. Please check your network and try again!</span>
                });
                console.error('ERROR', err);
            });

    }

    handleOnCancelImagingRequestClick = (row) => {
        this.setState({
            onOpenImagingCancelModal: true,
            cancelImagingData: row
        })
    }

    handleOnCancelImagingRequest = async () => {
        this.setState({
            cancelImagingMessage: <span className={"alert alert-info"}>Cancelling Request... Please wait!</span>
        });

        const sendData = {id: this.state.cancelImagingData._id, imageAmount: this.state.cancelImagingData.price,
            patientSerial: this.state.cancelImagingData.patientSerial,
            paymentStatus: typeof this.state.cancelImagingData.paymentStatus !== "undefined" ? this.state.cancelImagingData.paymentStatus : 'unpaid',
            updatedBy: this.props.doctorDetails.userID}
        await axios.patch(`${serverLink}imaging/request/cancel`, sendData, this.props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        cancelImagingMessage: <span className={"alert alert-success"}>Imaging Request Cancelled Successfully</span>
                    });
                    this.fetchImagingRequest();
                    const object = this;
                    setTimeout(function () {
                        object.closeErrorDialog();
                        object.setState({
                            cancelImagingMessage: "Are you sure you want to cancel the selected image request?",
                            cancelImagingData: ""

                        })
                    }, 1000)

                } else {
                    this.setState({
                        cancelImagingMessage: <span className={"alert alert-danger"}>Something went wrong cancelling request. Please check your network and try again!</span>
                    });
                }
            })
            .catch()
    }

    render() {

        let prescriptionTotal = 0;
        let roomIncurredTotal = this.state.admissionData.status !== 'discharged' ? this.state.admissionData.incurredAmount - this.state.admissionData.roomAmount : 0;
        let labIncurredTotal = 0;
        let prescriptionIncurredTotal = 0;
        let imagingIncurredTotal = 0;
        let totalAmountDue = 0;
        let appointmentDate = this.state.admissionData.admittedOn;
        let patientData = {patientSerial: this.state.patientSerial, firstName: this.state.patient, middleName: '', surname: ''}

        const editorConfig = {
            readonly: false,
            height: 200
        };
        const editorConfigNurse = {
            readonly: false,
            height: 200
        };
        const editorConfigNurseObservation = {
            readonly: false,
            height: 200
        };

        let columns = this.state.columns;
        let data = this.state.drugList;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search drugs',
            "export": false
        };

        columns = this.state.labTestColumns;
        data = this.state.labTestList;
        const labTableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search Test',
            "export": false
        };

        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <ConfirmDialogBox
                    openDailog={this.state.onOpenImagingCancelModal}
                    onSetOpenDialog={this.closeErrorDialog}
                    handleConfirmOkBtn={this.handleOnCancelImagingRequest}
                    isLoading={false}
                    isDecision={true}
                    title="CANCEL IMAGING REQUEST?"
                    des={ this.state.cancelImagingMessage}
                />

                <ConfirmDialogBox
                    openDailog={this.state.onOpenCancelPrescriptionModal}
                    onSetOpenDialog={this.closeErrorDialog}
                    handleConfirmOkBtn={this.handleOnCancelPrescriptionRequest}
                    isLoading={false}
                    isDecision={true}
                    title="CANCEL PRESCRIPTION?"
                    des={ this.state.cancelPrescriptionMessage }
                />
                <ConfirmDialogBox
                    openDailog={this.state.onOpenCancelModal}
                    onSetOpenDialog={this.closeErrorDialog}
                    handleConfirmOkBtn={this.handleOnCancelRequest}
                    isLoading={false}
                    isDecision={true}
                    title="CANCEL REQUEST?"
                    des={ this.state.cancelMessage }
                />

                <AlertDialogBox
                    openDailog={this.state.openAlertDialog}
                    onSetOpenDailog={this.closeAlertDialog}
                    title={this.state.messageTitle}
                    des={this.state.messageDescription}
                />
                <ErrorDialogBox
                    openDialog={this.state.openErrorDialog}
                    onSetOpenDialog={this.closeDialog}
                    title={this.state.messageTitle}
                    des={this.state.messageDescription}
                />

                {/*<ConfirmDialogBox*/}
                {/*    openDailog={this.state.openFinishDialog}*/}
                {/*    onSetOpenDialog={this.closeDialog}*/}
                {/*    handleConfirmOkBtn={this.onDischargeAdmission}*/}
                {/*    isLoading={false}*/}
                {/*    title="DISCHARGE PATIENT?"*/}
                {/*    des={ "Are you sure you want to discharge the patient?" }*/}
                {/*/>*/}

                <FormPrompt
                    openDialog={this.state.openShowAllergyImageDialog}
                    title="Allergy Reaction Image"
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <img srcSet={this.state.allergyImageSrc} alt="Allergy Image"/>
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openDoctorNote}
                    title="Doctor Note"
                    isFullWidth={true}
                    onSetOpenDialog={this.submitDoctorNote}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <h3>Subjective</h3>
                    <JoditEditor
                        value={this.state.doctorNote1}
                        config={editorConfig}
                        onBlur={this.doctorNote1Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>
                    <h3>Objective</h3>
                    <JoditEditor
                        value={this.state.doctorNote2}
                        config={editorConfig}
                        onBlur={this.doctorNote2Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>
                    <h3>Assessment</h3>
                    <JoditEditor
                        value={this.state.doctorNote3}
                        config={editorConfig}
                        onBlur={this.doctorNote3Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>
                    <h3>Plan</h3>
                    <JoditEditor
                        value={this.state.doctorNote4}
                        config={editorConfig}
                        onBlur={this.doctorNote4Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>
                    <h3>Other 1</h3>
                    <JoditEditor
                        value={this.state.doctorNote5}
                        config={editorConfig}
                        onBlur={this.doctorNote5Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>Other 2</h3>
                    <JoditEditor
                        value={this.state.doctorNote6}
                        config={editorConfig}
                        onBlur={this.doctorNote6Handler}
                        onChange={(newContent) => {}}
                    />
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openNurseNote}
                    title="Nurse Care Plan"
                    isFullWidth={true}
                    onSetOpenDialog={this.submitNurseNote}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <h3>NURSING DIAGNOSIS</h3>
                    <JoditEditor
                        value={this.state.nurseNote1}
                        config={editorConfigNurse}
                        onBlur={this.nurseNote1Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>NURSING OBJECTIVES</h3>
                    <JoditEditor
                        value={this.state.nurseNote2}
                        config={editorConfigNurse}
                        onBlur={this.nurseNote2Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>NURSING INTERVENTION</h3>
                    <JoditEditor
                        value={this.state.nurseNote3}
                        config={editorConfigNurse}
                        onBlur={this.nurseNote3Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>SCIENTIFIC RATIONALE</h3>
                    <JoditEditor
                        value={this.state.nurseNote4}
                        config={editorConfigNurse}
                        onBlur={this.nurseNote4Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>EVALUATION </h3>
                    <JoditEditor
                        value={this.state.nurseNote5}
                        config={editorConfigNurse}
                        onBlur={this.nurseNote5Handler}
                        onChange={(newContent) => {}}
                    />

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openNurseObservationNote}
                    title="Nurse Note"
                    isFullWidth={true}
                    onSetOpenDialog={this.submitNurseObservationNote}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <h3>ADMISSION NOTE</h3>
                    <JoditEditor
                        value={this.state.nurseObservationNote1}
                        config={editorConfigNurseObservation}
                        onBlur={this.nurseObservationNote1Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>MORNING SHIFT REPORT</h3>
                    <JoditEditor
                        value={this.state.nurseObservationNote2}
                        config={editorConfigNurseObservation}
                        onBlur={this.nurseObservationNote2Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>AFTERNOON SHIFT REPORT</h3>
                    <JoditEditor
                        value={this.state.nurseObservationNote3}
                        config={editorConfigNurseObservation}
                        onBlur={this.nurseObservationNote3Handler}
                        onChange={(newContent) => {}}
                    />
                    <hr/>

                    <h3>NIGHT SHIFT REPORT</h3>
                    <JoditEditor
                        value={this.state.nurseObservationNote4}
                        config={editorConfigNurseObservation}
                        onBlur={this.nurseObservationNote4Handler}
                        onChange={(newContent) => {}}
                    />
                </FormPrompt>


                <FormPrompt
                    openDialog={this.state.openVitalDialog}
                    title="CAPTURE VITALS"
                    onSetOpenDialog={this.closeVitalDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <CaptureVital
                        setCloseBtnAppear={this.setCloseBtnAppear}
                        handleSuccessDialog={this.handleSuccessDialog}
                        handleErrorDialog={this.handleErrorDialog}
                        patientData={this.state.patientData}
                        patientAppointment={[]}
                    />
                </FormPrompt>

                <FormPrompt
                    style={{ position: 'relative', zIndex: '3' }}
                    openDialog={this.state.openAddDrugModal}
                    title="Prescribe Drug Form"
                    onSetOpenDialog={this.closeAddDrugDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <form onSubmit={this.prescribeDrug} id="PrescriptionForm">
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Drug Prescribed Successfully</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Drug Not Prescribed. Please Try Again!</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }
                        <div className="form-row">
                            <label htmlFor="drugName">Drug Name</label>
                            <input type="text" className="form-control" readOnly={true} value={this.state.drugName}/>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="drugPrice">Drug Price</label>
                                <input type="number" className="form-control" readOnly={true} value={this.state.drugPrice}/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="drugQuantity">Drug Quantity</label>
                                <input type="number" className="form-control" readOnly={true} value={this.state.drugQuantity}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="prescriptionQuantity">Prescription Quantity</label>
                                <input type="number" required id="prescriptionQuantity" name="prescriptionQuantity" onChange={this.handleDrugQuantityChange} className="form-control" min={1} max={this.state.drugQuantity} required="required"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="prescriptionDose">Dose</label>
                                <select required name="prescriptionDose" id="prescriptionDose" className="custom-select">
                                    <option value="">Select Option</option>
                                    <option value="0.5">1/2</option>
                                    {
                                        this.state.drugDose > 0 ?
                                            [...Array(this.state.drugDose)].map((dose, index) => {
                                                return <option key={index} value={index+1}>{index+1}</option>
                                            })
                                            : ''
                                    }
                                    <option value="0.2">0.2ML</option>
                                    <option value="0.5">0.5ML</option>
                                    <option value="1">1ML</option>
                                    <option value="1.5">1.5ML</option>
                                    <option value="2">2ML</option>
                                    <option value="5">5ML</option>
                                    <option value="10">10ML</option>

                                </select>
                            </div>

                            <div className="col-6">
                                <label htmlFor="prescriptionFrequency">Frequency</label>
                                <select required name="prescriptionFrequency" id="prescriptionFrequency"
                                        className="custom-select">
                                    <option value="">Select Option</option>
                                    <option value="Every 4 Hours">Every 4 Hours</option>
                                    <option value="Every 6 Hours">Every 6 Hours</option>
                                    <option value="Every 8 Hours">Every 8 Hours</option>
                                    <option value="Every 12 Hours">Every 12 Hours</option>
                                    <option value="Once">Once</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Annually">Annually</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="prescriptionDetail">Prescription Details</label>
                                <input type="text" id="prescriptionDetail" placeholder="1 tablet a day, duration, etc." name="prescriptionDetail" className="form-control" defaultValue={this.state.prescriptionDetail}/>
                            </div>
                        </div>
                        <hr/>

                        <button className="btn btn-primary btn-block">Prescribe</button>
                    </form>

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openPrescriptionModal}
                    title="Drug List"
                    isFullWidth={true}
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="drugName"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openLabTestModal}
                    title="Lab Test List"
                    isFullWidth={true}
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >

                    {
                        this.state.labTestMessage === 'success' ?
                            <p className="text-center alert alert-success font-weight-bold">Test Added Successfully</p> : ''
                    }

                    {
                        this.state.labTestMessage === 'submitting' ?
                            <p className="text-center alert alert-info font-weight-bold">Please wait... Adding test to patient!</p> : ''
                    }

                    {
                        this.state.labTestMessage === 'failed' ?
                            <p className="text-center alert alert-danger font-weight-bold">Something went wrong adding the test. Please try again!</p> : ''
                    }

                    {
                        this.state.labTestMessage === 'exist' ?
                            <p className="text-center alert alert-warning font-weight-bold">The test you're trying to add was already added</p> : ''
                    }

                    <DataTableExtensions
                        {...labTableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="testName"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openDoctorTask}
                    title="Add TASK"
                    onSetOpenDialog={this.closeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <form onSubmit={this.submitAdmissionTask} id="AddTaskForm">
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Task Added Successfully</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Task Not Added. Please Try Again!</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }
                        <div className="form-row">
                            <label htmlFor="doctorTask">Add Clinical Task</label>
                            <input type="text" id="doctorTask" required className="form-control" placeholder="Enter the clinical task here" defaultValue={this.state.doctorTask}/>
                        </div>
                        <hr/>

                        <button className="btn btn-primary btn-block">Add Task</button>
                    </form>
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openPainAssessmentModal}
                    title="PAIN ASSESSMENT FORM"
                    onSetOpenDialog={this.onClosePainAssessment}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <form onSubmit={this.onSubmitPainAssessment} id="AddPainAssessmentForm" className="text-center">
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Pain Assessment Taken Successfully</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Please add a comment and try again! / Something went wrong processing...</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }

                        <Rating
                            onClick={this.handlePainAssessmentRating}
                            ratingValue={this.state.painRating}
                            fillColorArray={['#02b324', '#8cbf96','#f5e342', '#e04a4a','#c40202']}
                            customIcons={[
                                {icon: <FaRegLaughBeam size={100} /> },
                                {icon: <FaSmile size={100} /> },
                                {icon: <FaRegMeh size={100} /> },
                                {icon: <FaFrown size={100} /> },
                                {icon: <FaSadCry size={100} /> }
                            ]}
                             />

                        <h3>{this.state.painRatingName}</h3>

                        <div className="form-group">
                            <label htmlFor="">Comment (Optional)</label>
                            <input type="text" className="form-control" onChange={this.onEditPainComment} placeholder={"Enter patient comment here"} />
                        </div>

                        <hr/>

                        <button className="btn btn-primary btn-block" type={"submit"}>Submit</button>
                    </form>
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openNurseAssessmentModal}
                    title="NURSE ASSESSMENT FORM"
                    onSetOpenDialog={this.onCloseNurseAssessment}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                    isFullWidth={true}
                >
                    <h4>Prescriptions</h4>
                    {
                        this.state.prescription.length > 0 ?
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Drug Name</th>
                                    <th>Quantity</th>
                                    <th>Dose</th>
                                    <th>Prescription</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.prescription[0].prescriptionDetail.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{item.drugName}</td>
                                            <td>{item.drugQuantity}</td>
                                            <td>{item.drugDose} / {item.doseFrequency}</td>
                                            <td>{item.prescription !== '' ? item.prescription : '--' }</td>
                                            <td>
                                                <button
                                                    onClick={() => {this.onOpenNurseAssessmentSubmission(item, index)}}
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    <i className="fa fa-plus" aria-hidden="true" />
                                                </button>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table> :
                            <div className="p-3">
                                <span className="alert alert-info">No Prescription Found</span>
                            </div>
                    }


                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openNurseAssessmentSubmissionModal}
                    title={this.state.nurseSelectedDrug.drugName}
                    onSetOpenDialog={this.onCloseNurseAssessmentSubmission}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Drug</th>
                                <td>{this.state.nurseSelectedDrug.drugName}</td>
                            </tr>
                            <tr>
                                <th>Quantity</th>
                                <td>{this.state.nurseSelectedDrug.drugQuantity}</td>
                            </tr>
                            <tr>
                                <th>Dose</th>
                                <td>{this.state.nurseSelectedDrug.drugDose} / {this.state.nurseSelectedDrug.doseFrequency}</td>
                            </tr>
                            <tr>
                                <th>Prescription</th>
                                <td>
                                    {
                                        this.state.nurseSelectedDrug.prescription !== '' ?
                                            this.state.nurseSelectedDrug.prescription :
                                            '--'
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <form onSubmit={this.onSubmitNurseAssessment}>
                        <div className="form-row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Time taken</label>
                                    <select className="custom-select" value={this.state.nurseSelectedDrugTimeTaken} required name={"timeTaken"} onChange={this.onEditNurseAssessment}>
                                        <option value="">Select Option</option>
                                        <option value="Morning">Morning</option>
                                        <option value="Afternoon">Afternoon</option>
                                        <option value="Evening">Evening</option>
                                        <option value="Night">Night</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Dose Given</label>
                                    <select required name="doseGiven" value={this.state.nurseSelectedDrugDoseGiven} className="custom-select" onChange={this.onEditNurseAssessment}>
                                        <option value="">Select Option</option>
                                        <option value="0.5">1/2</option>
                                        {
                                            this.state.nurseSelectedDrug.drugDose > 0 ?
                                                [...Array(this.state.nurseSelectedDrug.drugDose)].map((dose, index) => {
                                                    return <option key={index} value={index+1}>{index+1}</option>
                                                })
                                                : ''
                                        }
                                        <option value="0.2ML">0.2ML</option>
                                        <option value="0.5ML">0.5ML</option>
                                        <option value="1ML">1ML</option>
                                        <option value="1.5ML">1.5ML</option>
                                        <option value="2ML">2ML</option>
                                        <option value="5ML">5ML</option>
                                        <option value="10ML">10ML</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.prescriptionSuccess === true ?
                                <p className="alert alert-success">Assessment Taken Successfully</p> : ''
                        }
                        {
                            this.state.prescriptionError === true ?
                                <p className="alert alert-danger">Something went wrong processing...</p> : ''
                        }
                        {
                            this.state.prescriptionSubmitting === true ?
                                <p className="alert alert-info">Processing. Please wait!</p> : ''
                        }
                        <div className="form-row">
                            <button className="btn btn-block btn-primary" type="submit">Submit</button>
                        </div>
                    </form>
                    {
                        this.state.prescription.length > 0 ?
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Time Given</th>
                                    <th>Dose Given</th>
                                    <th>Administered By</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    typeof this.state.prescription[0].prescriptionDetail[this.state.nurseSelectedDrugIndex].assessment !== "undefined" &&
                                    this.state.prescription[0].prescriptionDetail[this.state.nurseSelectedDrugIndex].assessment.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{item.timeTaken}</td>
                                            <td>{item.doseGive}</td>
                                            <td>{item.submittedBy}</td>
                                            <td>{item.submittedDate }</td>

                                        </tr>
                                    })
                                }
                                </tbody>
                            </table> :
                            <div className="p-3">
                                <span className="alert alert-info">No Assessment Found</span>
                            </div>
                    }


                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openImageModal}
                    title="IMAGING REQUEST"
                    onSetOpenDialog={this.onImageModalClose}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <PatientImagingRequest patientSerial={this.state.patientData.patientSerial} patientName={`${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`} requestType={"admission"} requestID={this.state.admission_id} />
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openServiceDialog}
                    title="SERVICES"
                    onSetOpenDialog={this.closeServiceDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                    isFullWidth={true}
                >
                    <ServiceRequest patient_id={this.state.patientData.patientSerial} patient_name={`${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`} patient_wallet={this.state.walletAmount} money_format={this.moneyFormat} />

                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openICD11Note}
                    title="Diagnosis (ICD 11)"
                    onSetOpenDialog={this.closeICDCodeDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                    // isFullWidth={true}
                >
                    {/*<IcdComponent />*/}
                    <ECTReactComponent
                        patientSerial={this.state.patientSerial}
                        patientName={`${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`}
                        action_id={this.state.appointment_id}
                        type={'consultation'}
                    />
                </FormPrompt>

                <FormPrompt
                    openDialog={this.state.openAllergyDialog}
                    title="Add New Allergy"
                    onSetOpenDialog={this.closeAllergyDialog}
                    isCloseBtnAppear={this.state.isCloseDialogAppear}
                >
                    <AddAllergy
                        setCloseBtnAppear={this.setCloseBtnAppear}
                        handleSuccessDialog={this.handleSuccessDialogPopup}
                        handleErrorDialog={this.handleErrorDialog}
                        patientData={patientData}
                    />
                </FormPrompt>


                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Admission Profile" />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-12 col-md-2">
                            <div className="card">
                                <div className="card-header">
                                    <strong className="text-secondary mb-1 text-center">{this.state.patientData.patientSerial}</strong>
                                </div>
                                <div className="d-flex flex-column align-items-center text-center">
                                    <div className="profileimage">
                                        <div className="patientlistpage mt-2">
                                            <img
                                                className="patient_passport img-fluid"
                                                srcSet={imageExists(`${serverLink}public/uploads/patient_passport/${this.state.patientData.passport}`) ? `${serverLink}public/uploads/patient_passport/${this.state.patientData.passport}` : this.state.patientData.gender === 'Male' ? doctor : woman_doctor}
                                                alt="profileImage"
                                            />
                                        </div>

                                    </div>
                                    <div className="mt-3">
                                        <h4>{this.state.patientData.firstName} {this.state.patientData.middleName} {this.state.patientData.surname}</h4>
                                        <p className="alert alert-success font-size-sm">Wallet: {
                                            this.moneyFormat(this.state.patientData.walletAmount)
                                        }</p>
                                    </div>
                                    <div className="mt-3">
                                        <p className="alert alert-success font-size-sm">Days Admitted: <br/>
                                            {
                                                this.state.admissionData.incurredAmount/this.state.admissionData.roomAmount
                                            }
                                            {
                                                this.state.admissionData.incurredAmount/this.state.admissionData.roomAmount > 1 ? ' Days' : ' Day'
                                            }
                                        </p>
                                    </div>
                                    <div className="mt-3">
                                        {
                                            this.state.admissionData.status !== 'discharged' ?
                                                <p className="alert alert-success font-size-sm">Amount: <br/>
                                                    {
                                                        this.moneyFormat(this.state.admissionData.incurredAmount)
                                                    }
                                                </p>
                                                :
                                                <p className="alert alert-info font-size-sm">DISCHARGED</p>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={this.state.access === "service-desk" ? "col-sm-12 col-md-10" : "col-sm-12 col-md-4"}>
                            <div className="card mb-3">
                                <div className="card-header">Admission</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Block Name</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.admissionData.blockName}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Room Name</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.admissionData.roomName}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Bed Name</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.admissionData.bedName}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Admitted Date</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.formatDate(this.state.admissionData.admittedOn)}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Date of Birth</h6>
                                        </div>
                                        <div className="col-sm-6 text-secondary">
                                            {this.state.patientData.dateOfBirth ? this.formatDate(this.state.patientData.dateOfBirth) +  `  (${calculateAge(this.state.patientData.dateOfBirth)}  ${calculateAge(this.state.patientData.dateOfBirth ) > 1 ?  "Years Old" : "Year Old"})` : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Blood Group</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.patientData.bloodGroup ? this.state.patientData.bloodGroup : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Next of Kin</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.patientData.nextOfKinName ? this.state.patientData.nextOfKinName : 'N/A'} <br/>
                                            {this.state.patientData.nextOfKinPhone ? this.state.patientData.nextOfKinPhone : 'N/A'} <br/>
                                            {this.state.patientData.nextOfKinRelationship ? this.state.patientData.nextOfKinRelationship : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h6 className="mb-0">Status</h6>
                                        </div>
                                        <div className="col-sm-7 text-secondary">
                                            {this.state.admissionData.status === 'active' ? <span className="badge badge-info">Active</span> : <span className="badge badge-success">Discharged</span>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {
                          this.state.access === "service-desk" ?
                            <></>
                              :
                              <div className="col-sm-12 col-md-6">
                                  <div className="card mb-3">
                                      <div className="card-header"> Request </div>
                                      <div className="row">
                                          {this.state.access === "nurse" || this.state.access === "service-desk"   ? <></> : <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenDoctorNote}
                                                  actionName="Doctor Note"
                                                  actionIcon="fa fa-user-md fa-4x text-secondary"
                                              />
                                          </div> }
                                          { this.state.access === "service-desk"|| this.state.access === "service-desk"   ? <></> : <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenICD11Note}
                                                  actionName="Diagnosis (ICD-11)"
                                                  actionIcon="fa fa-search fa-4x text-secondary"
                                              />
                                          </div> }
                                          {this.state.access === "doctor" || this.state.access === "service-desk"  ? <></> : <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenNurseObservationNote}
                                                  actionName="Nurse Note"
                                                  actionIcon="fa fa-edit fa-4x text-secondary"
                                              />
                                          </div> }
                                          {this.state.access === "doctor" || this.state.access === "service-desk"  ? <></> : <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenNurseNote}
                                                  actionName="Nurse Care Plan"
                                                  actionIcon="fa fa-user-nurse fa-4x text-secondary"
                                              />
                                          </div> }

                                          {this.state.access === "nurse" || this.state.access === "service-desk"   ? <></> :  <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenPrescriptionModal}
                                                  actionName="Prescription"
                                                  actionIcon="fa fa-prescription-bottle-alt fa-4x text-danger"
                                              />
                                          </div> }

                                          {this.state.access === "nurse" || this.state.access === "service-desk"   ? <></> :   <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenLabTestModal}
                                                  actionName="Lab"
                                                  actionIcon="fa fa-vial fa-4x text-danger"
                                              />
                                          </div> }

                                          {this.state.access === "doctor" || this.state.access === "service-desk"   ? <></> :  <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenNurseAssessment}
                                                  actionName="Prescription Assessment"
                                                  actionIcon="fa fa-check fa-4x text-success"
                                              />
                                          </div> }

                                          {this.state.access === "doctor" || this.state.access === "service-desk"   ? <></> :  <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenPainAssessment}
                                                  actionName="Pain Assessment"
                                                  actionIcon="fa fa-sad-tear fa-4x text-warning"
                                              />
                                          </div> }
                                          <div className="col-md-6">
                                              <PatientActionButton
                                                  handleClick={this.handleAllergyActionClicked}
                                                  actionName="Add Allergy"
                                                  actionIcon="fa fa-medkit fa-2x text-info"
                                              />
                                          </div>

                                          <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenDoctorTask}
                                                  actionName="Task"
                                                  actionIcon="fa fa-tasks fa-4x text-primary"
                                              />
                                          </div>

                                          {this.state.access === "nurse" || this.state.access === "service-desk"   ? <></> :  <div className="col-6">
                                              <PatientActionButton
                                                  actionName="Imaging"
                                                  handleClick={this.onOpenImageModal}
                                                  actionIcon="fa fa-image fa-4x text-primary"
                                              />
                                          </div> }
                                          {this.state.access === "doctor" || this.state.access === "service-desk"   ? <></> :  <div className="col-md-6">
                                              <PatientActionButton
                                                  handleClick={this.handleVitalActionClicked}
                                                  actionName="Capture Vitals"
                                                  actionIcon="fa fa-vial fa-2x text-info"
                                              />
                                          </div> }
                                          <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.onOpenServiceModal}
                                                  actionName="Service Request"
                                                  actionIcon="fa fa-cog fa-4x text-warning"
                                              />
                                          </div>
                                          {this.state.access === "nurse" || this.state.access === "service-desk" ? <></> :  <div className="col-6">
                                              <PatientActionButton
                                                  handleClick={this.openFinishDialog}
                                                  actionName="Discharge Patient"
                                                  actionIcon="fa fa-clipboard-check fa-4x text-warning"
                                              />
                                          </div> }
                                      </div>

                                  </div>

                              </div>
                        }
                    </div>

                    <hr/>
                    <div className="second_section">
                        <Tabs>
                            <TabList>
                                <Tab>Doctor Note</Tab>
                                <Tab>Diagnosis</Tab>
                                <Tab>Nurse Note</Tab>
                                <Tab>Nurse Care Plan</Tab>
                                <Tab>Allergens</Tab>
                                <Tab>Vitals</Tab>
                                <Tab>Vitals Report</Tab>
                                <Tab>Lab</Tab>
                                <Tab>Prescription</Tab>
                                <Tab>Nurse Assessment</Tab>
                                <Tab>Pain Assessment</Tab>
                                <Tab>Imaging</Tab>
                                <Tab>Clinical Tasks</Tab>
                                {this.state.access === "nurse" ? <></> : <Tab>Amount Incurred</Tab>}

                                <Tab>Services</Tab>
                            </TabList>

                            {/*Doctor Note*/}
                             <TabPanel>
                                {

                                    this.state.doctorNotes.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.doctorNotes.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                {this.formatDateAndTime(p.submittedOn)}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <h5>
                                                                Doctor: {p.doctorName}
                                                                {
                                                                    this.state.access === "nurse" || this.state.access === "service-desk"  ? <></> :
                                                                        <span className="float-right"><i className="fa fa-pencil" onClick={() => this.onOpenUpdateDoctorNote(p)} /></span>
                                                                }
                                                            </h5>
                                                            <h4>NOTE</h4>
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Subjective</th>
                                                                        <th>Objective</th>
                                                                        <th>Assessment</th>
                                                                        <th>Plan</th>
                                                                        <th>Other 1</th>
                                                                        <th>Other 2</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note1}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note2}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note3}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note4}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note5}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note6}} /></td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                })
                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Previous Note Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*ICD Code*/}
                            <TabPanel>
                                <ICDtable data={this.state.icd11List} userType={this.state.access} />
                            </TabPanel>

                            {/*Nurse Observation Note*/}
                            <TabPanel>
                                {
                                    this.state.nurseObservationNotes.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.nurseObservationNotes.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                {this.formatDateAndTime(p.submittedOn)}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <h5>
                                                                Nurse: {p.doctorName}
                                                                {
                                                                    this.state.access === "doctor" || this.state.access === "service-desk"  ? <></> :
                                                                        <span className="float-right"><i className="fa fa-pencil" onClick={() => this.onOpenUpdateNurseObservationNote(p)} /></span>

                                                                }
                                                            </h5>
                                                            <h4>NOTE</h4>
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Admission Note</th>
                                                                        <th>Morning Shift Report</th>
                                                                        <th>Afternoon Shift Report</th>
                                                                        <th>Night Shift Report</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note1}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note2}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note3}} /></td>
                                                                        <td><div dangerouslySetInnerHTML={{__html: p.note4}} /></td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                })
                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Previous Note Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Nurse Care Plan*/}
                            <TabPanel>
                                {
                                    this.state.nurseNotes.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.nurseNotes.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                {this.formatDateAndTime(p.submittedOn)}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <h5>
                                                                Nurse: {p.doctorName}
                                                                {
                                                                    this.state.access === "doctor" || this.state.access === "service-desk"  ? <></> :
                                                                        <span className="float-right"><i className="fa fa-pencil" onClick={() => this.onOpenUpdateNurseNote(p)} /></span>

                                                                }
                                                            </h5>
                                                            <h4>NOTE</h4>
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Diagnosis</th>
                                                                            <th>Objectives</th>
                                                                            <th>Intervention</th>
                                                                            <th>Scientific Rationale</th>
                                                                            <th>Evaluation</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note1}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note2}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note3}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note4}} /></td>
                                                                            <td><div dangerouslySetInnerHTML={{__html: p.note5}} /></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                })
                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Previous Note Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Allergy*/}
                            <TabPanel>
                                <PatientAllergyList allergicList={this.state.allergicList} handleOnClick={this.handleViewAllergyImageClicked} formatDate={this.formatDate}/>
                            </TabPanel>

                            {/*Vitals*/}
                            <TabPanel>
                                {
                                    this.state.vitals.length > 0 ?
                                        <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <span className="badge badge-info">Last Vitals were taken on: {this.formatDateAndTime(this.state.vitals[0].submittedOn)}</span>
                                                </div>
                                                <div className="col-3">
                                                    <DashboardCard
                                                        name="Temperature"
                                                        icon="fa fa-thermometer-half fa-4x "
                                                        count={this.state.vitals[0].temperature ?? 0 +'°'+ this.state.vitals[0].temperature_unit} />
                                                </div>
                                                <div className="col-3">
                                                    <DashboardCard
                                                        name="BP"
                                                        icon="fa fa-heartbeat fa-4x "
                                                        count={this.state.vitals[0].bloodPressure} />
                                                </div>
                                                <div className="col-3">
                                                    <DashboardCard
                                                        name="Weight"
                                                        icon="fa fa-weight fa-4x "
                                                        count={this.state.vitals[0].weight ?? 0 + this.state.vitals[0].weight_unit} />
                                                </div>
                                                <div className="col-3">
                                                    <DashboardCard
                                                        name="Height"
                                                        icon="fa fa-balance-scale fa-4x "
                                                        count={this.state.vitals[0].height ?? 0 + this.state.vitals[0].height_unit} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="wrap_chart">
                                                        <PatientChart title="Temperature" description="Patient Temperature Trend (C)" label={this.state.graph_label} data={this.state.temp_val}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="wrap_chart">
                                                        <PatientBloodPressureChart title="Blood Pressure" description="Patient BP Trend" label={this.state.graph_label} data1_label="Systolic" data1={this.state.bp_systolic_val} data2_label="Diastolic" data2={this.state.bp_diastolic_val}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="wrap_chart">
                                                        <PatientChart title="Height" description="Patient Height Trend (Meters)" label={this.state.graph_label} data={this.state.height_val}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="wrap_chart">
                                                        <PatientChart title="Weight" description="Patient Weight Trend (KG)" label={this.state.graph_label} data={this.state.weight_val}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Vital Was Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Vital Report*/}
                            <TabPanel>
                                <PatientVitalList vitalList={this.state.vitalList} formatDate={this.formatDate}/>
                            </TabPanel>

                            {/*Lab*/}
                            <TabPanel>
                                {
                                    this.state.patientLabTestList.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.patientLabTestList.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                Test: {p.testName} ~ Date: {this.formatDateAndTime(p.requestDate)} ~ Status:
                                                                {
                                                                    <span className={p.testStatus === 'requested' ? 'badge badge-warning' :
                                                                        p.testStatus === 'specimen taken' ? 'badger badge-info' : p.testStatus === 'result entered' || p.testStatus === 'result confirmed' ? 'badge badge-success' : 'badge badge-danger'
                                                                    }>{p.testStatus}</span>

                                                                }
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>Test ID</th>
                                                                    <td>{p.testID}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Test Name</th>
                                                                    <td>{p.testName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Test Status</th>
                                                                    <td>{p.testStatus}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Status</th>
                                                                    <td>{p.paymentStatus}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Requested By</th>
                                                                    <td>{p.doctorName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Requested Date</th>
                                                                    <td>{this.formatDateAndTime(p.requestDate)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Test Specimen</th>
                                                                    <td>{p.specimen[0].specimenName}</td>
                                                                </tr>
                                                                {
                                                                    p.testStatus !== "result confirmed" ?
                                                                        <tr>
                                                                            <th>Test Result</th>
                                                                            <td><span className="alert alert-info text-center">Result is not confirmed yet</span></td>
                                                                        </tr> :
                                                                        <>
                                                                            <tr>
                                                                                <th>Test Result</th>
                                                                                <td>
                                                                                    {
                                                                                        p.resultEnteredName !== "" && typeof p.resultEnteredName !== "undefined" &&
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                <tr>
                                                                                                    <th>S/N</th>
                                                                                                    <th>Item</th>
                                                                                                    <th>SI Unit</th>
                                                                                                    <th>Reference Interval</th>
                                                                                                    <th>Result</th>
                                                                                                    <th>Decision</th>
                                                                                                </tr>
                                                                                                </thead>

                                                                                                <tbody>
                                                                                                {
                                                                                                    p.specimen[0].items.map((item, index) => {
                                                                                                        return <tr key={index}>
                                                                                                            <td>{(index+1)}</td>
                                                                                                            {
                                                                                                                item.ref_interval === 'single' ?
                                                                                                                    <>
                                                                                                                        <td>{item.item_name}</td>
                                                                                                                        <td>{item.si_unit}</td>
                                                                                                                        <td>{item.ref_interval_min} - {item.ref_interval_max}</td>
                                                                                                                        <td>{item.result}</td>
                                                                                                                        <td>
                                                                                                                            {
                                                                                                                                item.result_type === 'integer' ?
                                                                                                                                    item.result >= item.ref_interval_min && item.result <= item.ref_interval_max  ?
                                                                                                                                        <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                                        <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                                                    : '--'
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                    </> :
                                                                                                                    <>
                                                                                                                        <td>{item.item_name}</td>
                                                                                                                        <td>{item.si_unit}</td>
                                                                                                                        <td colSpan={3}>
                                                                                                                            {
                                                                                                                                item.ref_data.map((ref, index_val) => {
                                                                                                                                    if (ref.ref_counter === 'single') {
                                                                                                                                        return <div key={index_val} className="table-responsive"><table className="table table-striped" key={index_val}>
                                                                                                                                            <thead>
                                                                                                                                            <tr>
                                                                                                                                                <th>Item</th>
                                                                                                                                                <th>Reference Interval</th>
                                                                                                                                                <th>Result</th>
                                                                                                                                                <th>Decision</th>
                                                                                                                                            </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td>{ref.ref_name}</td>
                                                                                                                                                <td>{ref.ref_interval_min} - {ref.ref_interval_max}</td>
                                                                                                                                                <td>{ref.result}</td>
                                                                                                                                                <td>
                                                                                                                                                    {
                                                                                                                                                        ref.result_type === 'integer' ?
                                                                                                                                                            ref.result >= ref.ref_interval_min && ref.result <= ref.ref_interval_max  ?
                                                                                                                                                                <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                                                                <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                                                                            : '--'
                                                                                                                                                    }
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table></div>

                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        return <div key={index_val}>
                                                                                                                                            <h4>{ref.ref_title}</h4>
                                                                                                                                            <div className="table-responsive"><table className="table table-striped">
                                                                                                                                                <thead>
                                                                                                                                                <tr>
                                                                                                                                                    <th>Item</th>
                                                                                                                                                    <th>Reference Interval</th>
                                                                                                                                                    <th>Result</th>
                                                                                                                                                    <th>Decision</th>
                                                                                                                                                </tr>
                                                                                                                                                </thead>
                                                                                                                                                <tbody>
                                                                                                                                                {
                                                                                                                                                    ref.ref_item.map((pp, ii) => {
                                                                                                                                                        return <tr key={ii}>
                                                                                                                                                            <td>{pp.ref_name}</td>
                                                                                                                                                            <td>{pp.ref_interval_min} - {pp.ref_interval_max}</td>
                                                                                                                                                            <td>{pp.result}</td>
                                                                                                                                                            <td>
                                                                                                                                                                {
                                                                                                                                                                    pp.result_type === 'integer' ?
                                                                                                                                                                        pp.result >= pp.ref_interval_min && pp.result <= pp.ref_interval_max  ?
                                                                                                                                                                            <span className="badge badge-success"><i className="fa fa-check" /></span> :
                                                                                                                                                                            <span className="badge badge-danger"><i className="fa fa-times" /></span>
                                                                                                                                                                        : '--'
                                                                                                                                                                }
                                                                                                                                                            </td>

                                                                                                                                                        </tr>
                                                                                                                                                    })
                                                                                                                                                }

                                                                                                                                                </tbody>
                                                                                                                                            </table></div>
                                                                                                                                        </div>
                                                                                                                                    }

                                                                                                                                })
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                        <td></td>
                                                                                                                        <td></td>
                                                                                                                    </>
                                                                                                            }

                                                                                                        </tr>
                                                                                                    })
                                                                                                }
                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Consultant Comment</th>
                                                                                <td>{!p.description ?  '--' : <span dangerouslySetInnerHTML={{__html: p.description}}></span>}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Result Attachment</th>
                                                                                <td>{p.testAttachment ? <a className="btn btn-sm btn-primary" target="_blank" href={this.testAttachment}>View</a> : '--'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Result Date</th>
                                                                                <td>{p.resultEnteredDate ? this.formatDateAndTime(p.resultEnteredDate) : '--'}</td>
                                                                            </tr>
                                                                        </>
                                                                }
                                                                {
                                                                    this.state.access === "nurse" || this.state.access === "service-desk"  ? <></> :
                                                                    p.testStatus === 'cancelled' || p.testStatus === 'result confirmed' || p.testStatus === 'result entered' ?
                                                                        <></>
                                                                        :
                                                                        <tr>
                                                                            <th>Action</th>
                                                                            <td><button className="btn btn-danger btn-sm"  onClick={() => {this.handleOnCancelRequestClick(p._id)}}>Cancel Request</button></td>
                                                                        </tr>
                                                                }
                                                                </thead>
                                                            </table>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>

                                                })

                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Lab Request Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Prescription*/}
                            <TabPanel>
                                {
                                    this.state.prescription.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.prescription.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                Doctor: {p.doctorName} ~ Date: {this.formatDateAndTime(p.submittedDate)}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>S/N</th>
                                                                    <th>Drug Name</th>
                                                                    <th>Quantity</th>
                                                                    <th>Dose</th>
                                                                    <th>Prescription</th>
                                                                    <th>Status</th>
                                                                    <th>Unit Price</th>
                                                                    <th>Total</th>
                                                                    <th>Date</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    p.prescriptionDetail.map((d, index) => {
                                                                        prescriptionTotal += d.drugAmount*d.drugQuantity;
                                                                        return <tr key={index}>
                                                                            <td>{index+1}</td>
                                                                            <td>{d.drugName}</td>
                                                                            <td>{d.drugQuantity}</td>
                                                                            <td>{d.drugDose} / {d.doseFrequency}</td>
                                                                            <td>{d.prescription}</td>
                                                                            <td>{d.status}</td>
                                                                            <td>{this.moneyFormat(d.drugAmount)}</td>
                                                                            <td>{this.moneyFormat(d.drugAmount*d.drugQuantity)}</td>
                                                                            <td>{this.formatDateAndTime(p.submittedDate)}</td>
                                                                            <td>{  this.state.access === "nurse" || this.state.access === "service-desk"  ? <>--</> : d.status === "canceled" || d.status === "paid" ? <>--</> : <button className="btn btn-danger btn-sm"  onClick={() => {this.handleOnCancelPrescriptionRequestClick(p, d)}}>Cancel Prescription</button>}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                                <tfoot>
                                                                <tr>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th>{this.moneyFormat(prescriptionTotal)}</th>
                                                                    <th></th>
                                                                </tr>
                                                                </tfoot>
                                                            </table>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>

                                                    prescriptionTotal = 0;
                                                })

                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Prescription Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Nurse Assessment*/}
                            <TabPanel>
                                {
                                    this.state.prescription.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>Drug</th>
                                                <th>Qty</th>
                                                <th>Dose</th>
                                                <th>Assessment</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.prescription[0].prescriptionDetail.map((item, index) => {
                                                    return <tr key={item}>
                                                        <td>{item.drugName}</td>
                                                        <td>{item.drugQuantity}</td>
                                                        <td>{item.drugDose} / {item.doseFrequency}</td>
                                                        <td>
                                                            {
                                                                typeof item.assessment !== "undefined" ?
                                                                    <table className="table table-hover">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Time Given</th>
                                                                            <th>Dose Given</th>
                                                                            <th>Administered By</th>
                                                                            <th>Date</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            item.assessment.map((item, index) => {
                                                                                return <tr key={index}>
                                                                                    <td>{item.timeTaken}</td>
                                                                                    <td>{item.doseGive}</td>
                                                                                    <td>{item.submittedBy}</td>
                                                                                    <td>{item.submittedDate }</td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    <div className="p-3">
                                                                        <span className="alert alert-info">No Assessment Found</span>
                                                                    </div>
                                                            }


                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Prescription Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Pain Assessment*/}
                            <TabPanel>
                                {
                                    this.state.painAssessmentList.length > 0 ?
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>Rating</th>
                                                        <th>Comment</th>
                                                        <th>Taken By</th>
                                                        <th>Taken On</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.painAssessmentList.map((item, index) => {
                                                        return <tr key={index}>
                                                            <td>{(index+1)}</td>
                                                            <td>{item.painRatingName}</td>
                                                            <td>{item.painComment}</td>
                                                            <td>{item.nurseName}</td>
                                                            <td>{this.formatDateAndTime(item.submittedOn)}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Pain Assessment Record Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Imaging*/}
                            <TabPanel>
                                {
                                    this.state.imagingList.length > 0 ?
                                        <Accordion>
                                            {
                                                this.state.imagingList.map((p, index) => {
                                                    return <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                Image: {p.imageName} ~ Date: {this.formatDateAndTime(p.submittedOn)} ~ Status:
                                                                {
                                                                    <span className={p.status === 'pending' ? 'badge badge-warning' :  'badge badge-success'}>{p.status}</span>
                                                                }
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>Imaging ID</th>
                                                                    <td>{p._id}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Imaging Name</th>
                                                                    <td>{p.imageName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Test Status</th>
                                                                    <td>{p.status}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Payment Status</th>
                                                                    <td>{p.paymentStatus}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Requested By</th>
                                                                    <td>{p.doctorName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Requested Date</th>
                                                                    <td>{this.formatDateAndTime(p.submittedOn)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>Image Result</th>
                                                                    <td>
                                                                        {
                                                                            p.result ? <a className="btn btn-sm btn-primary" target="_blank" href={`${serverLink}public/uploads/patient_imaging/${p.result}`}>View</a> : '--'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Result Added By</th>
                                                                    <td>{p.resultEnteredBy ? p.resultEnteredBy : '--'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Result Added Date</th>
                                                                    <td>{p.resultEnteredBy ? this.formatDateAndTime(p.resultEnteredDate) : '--'}</td>
                                                                </tr>
                                                                {
                                                                    this.state.access === "nurse" || this.state.access === "service-desk"  ? <></> :
                                                                        p.paymentStatus !== 'paid' && (p.status !== 'cancelled' && p.status !== 'result entered') ? (
                                                                            <tr>
                                                                                <th>Action</th>
                                                                                <td><button className="btn btn-danger btn-sm" onClick={() => { this.handleOnCancelImagingRequestClick(p) }}>Cancel Request</button></td>
                                                                            </tr>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                }
                                                                </thead>
                                                            </table>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>

                                                })

                                            }

                                        </Accordion>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Image Request Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Clinical Tasks*/}
                            <TabPanel>
                                {
                                    this.state.tasks.length > 0 ?
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Task</th>
                                                <th>Added By</th>
                                                <th>Added Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.tasks.map((task, index) => {
                                                    return <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{task.task}</td>
                                                        <td>{task.doctorName}</td>
                                                        <td>{this.formatDate(task.submittedOn)}</td>
                                                        <td>
                                                            {
                                                                task.taskStatus === 'incomplete' ?
                                                                    <span className="badge badge-danger">{task.taskStatus}</span> :
                                                                    <span className="badge badge-success">{task.taskStatus}</span>
                                                            }
                                                        </td>
                                                        <td>

                                                            <label>
                                                                {task.taskStatus === 'incomplete' ? 'Mark Complete' : 'Mark Incomplete'}
                                                                <input
                                                                    name="isGoing"
                                                                    type="checkbox"
                                                                    checked={true}
                                                                    onChange={() => {this.onChangeAdmissionTaskStatus(task)}}
                                                                />
                                                            </label>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                        :
                                        <div className="p-3">
                                            <span className="alert alert-info">No Task Found</span>
                                        </div>
                                }
                            </TabPanel>

                            {/*Amount Incurred*/}
                            {
                                this.state.access === "nurse" ? <></>
                                    :
                                    <TabPanel>
                                        <h3>Room Fees</h3>
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>Room Name</th>
                                                <th>Room Amount / Day</th>
                                                <th>Number of Days</th>
                                                <th>Amount Incurred</th>
                                                <th>Amount Paid</th>
                                                <th>Amount Due</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{this.state.admissionData.roomName}</td>
                                                <td>{this.moneyFormat(this.state.admissionData.roomAmount)}</td>
                                                <td>
                                                    {
                                                        this.state.admissionData.incurredAmount/this.state.admissionData.roomAmount
                                                    }
                                                    {
                                                        this.state.admissionData.incurredAmount/this.state.admissionData.roomAmount > 1 ? ' Days' : ' Day'
                                                    }
                                                </td>
                                                <td>{ this.moneyFormat(this.state.admissionData.incurredAmount) }</td>
                                                <td>{this.moneyFormat(this.state.admissionData.roomAmount)}</td>
                                                <td>{this.moneyFormat(roomIncurredTotal)}</td>
                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <th/><th/><th/><th/>
                                                <th>Amount Due</th>
                                                <th>{this.moneyFormat(roomIncurredTotal)}</th>
                                            </tr>
                                            </tfoot>
                                        </table>

                                        <h3>Lab Fees</h3>
                                        {
                                            this.state.patientLabTestList.length > 0 ?
                                                <table className="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Item Name</th>
                                                        <th>Request Date</th>
                                                        <th>Test Status</th>
                                                        <th>Requested By</th>
                                                        <th>Payment Status</th>
                                                        <th>Test Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.patientLabTestList.map((lab, index) => {
                                                            if (lab.paymentStatus !== 'paid' && (lab.testStatus !== "result rejected" || lab.testStatus !== "cancelled"))
                                                                labIncurredTotal += lab.testAmount;

                                                            return <tr key={index}>
                                                                <td>{lab.testName}</td>
                                                                <td>{this.formatDate(lab.requestDate)}</td>
                                                                <td>{lab.testStatus}</td>
                                                                <td>{lab.doctorName}</td>
                                                                <td>{lab.paymentStatus}</td>
                                                                <td>{this.moneyFormat(lab.testAmount)}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                    <tfoot>
                                                    <tr>
                                                        <th/><th/><th/><th/>
                                                        <th>Amount Due</th>
                                                        <th>{this.moneyFormat(labIncurredTotal)}</th>
                                                    </tr>
                                                    </tfoot>
                                                </table>  :
                                                <div className="p-3">
                                                    <span className="alert alert-info">No Lab Request Found</span>
                                                </div>

                                        }

                                        <h3>Prescription Fees</h3>
                                        {
                                            this.state.prescription.length > 0 ?
                                                <table className="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Item Name</th>
                                                        <th>Quantity</th>
                                                        <th>Dose</th>
                                                        <th>Other Instruction</th>
                                                        <th>Prescription Date</th>
                                                        <th>Payment Status</th>
                                                        <th>Drug Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.prescription[0].prescriptionDetail.map((drug, index) => {
                                                            if (drug.status !== 'paid' && drug.status !== "canceled")
                                                                prescriptionIncurredTotal += drug.drugQuantity*drug.drugAmount;

                                                            return <tr key={index}>
                                                                <td>{drug.drugName}</td>
                                                                <td>{drug.drugQuantity}</td>
                                                                <td>{`${drug.drugDose} ${drug.doseFrequency}`}</td>
                                                                <td>{drug.prescription}</td>
                                                                <td>{this.formatDateAndTime(appointmentDate)}</td>
                                                                <td>{drug.status}</td>
                                                                <td>{this.moneyFormat(drug.drugQuantity*drug.drugAmount)}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                    <tfoot>
                                                    <tr>
                                                        <th/><th/><th/><th/>
                                                        <th>Amount Due</th>
                                                        <th>{this.moneyFormat(prescriptionIncurredTotal)}</th>
                                                    </tr>
                                                    </tfoot>
                                                </table>  :
                                                <div className="p-3">
                                                    <span className="alert alert-info">No Prescription Found</span>
                                                </div>

                                        }

                                        <h3>Imaging Fees</h3>
                                        {
                                            this.state.imagingList.length > 0 ?
                                                <table className="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Imaging Name</th>
                                                        <th>Requested By</th>
                                                        <th>Requested Date</th>
                                                        <th>Status</th>
                                                        <th>Payment Status</th>
                                                        <th>Request Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.imagingList.map((imaging, index) => {
                                                            if (imaging.paymentStatus !== 'paid' && imaging.status !=="cancelled")
                                                                imagingIncurredTotal += imaging.price;

                                                            return <tr key={index}>
                                                                <td>{imaging.imageName}</td>
                                                                <td>{imaging.doctorName}</td>
                                                                <td>{this.formatDateAndTime(imaging.submittedOn)}</td>
                                                                <td>{imaging.status}</td>
                                                                <td>{imaging.paymentStatus}</td>
                                                                <td>{this.moneyFormat(imaging.price)}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                    <tfoot>
                                                    <tr>
                                                        <th/><th/><th/><th/>
                                                        <th>Amount Due</th>
                                                        <th>{this.moneyFormat(imagingIncurredTotal)}</th>
                                                    </tr>
                                                    </tfoot>
                                                </table>  :
                                                <div className="p-3">
                                                    <span className="alert alert-info">No Imaging Found</span>
                                                </div>

                                        }

                                        <h1>TOTAL</h1>
                                        <div className="alert alert-danger font-weight-bold text-center">
                                            TOTAL AMOUNT DUE: {this.moneyFormat(roomIncurredTotal+labIncurredTotal+prescriptionIncurredTotal+imagingIncurredTotal)}
                                        </div>


                                    </TabPanel>
                            }


                            <TabPanel>
                                <PatientServiceList service_list={this.state.servicesList} money_format={this.moneyFormat} />
                            </TabPanel>

                        </Tabs>
                    </div>


                    <FormPrompt
                        openDialog={this.state.openFinishDialog}
                        title="DISCHARGE FORM"
                        onSetOpenDialog={this.closeDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <h1>TOTAL</h1>
                        <div className="alert alert-info font-weight-bold text-center">
                            TOTAL AMOUNT DUE: {this.moneyFormat(roomIncurredTotal+labIncurredTotal+prescriptionIncurredTotal+imagingIncurredTotal)}
                        </div>

                        <ChoosePayment
                            patientID={this.state.patientData.patientSerial}
                            patientName={`${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`}
                            walletAmount={this.state.patientData.walletAmount}
                            MoneyConverter={this.moneyFormat}
                            paymentFor="Admission Discharge"
                            itemID={this.state.admission_id}
                            itemName={"Admission Discharge"}
                            itemAmount={roomIncurredTotal+labIncurredTotal+prescriptionIncurredTotal+imagingIncurredTotal}
                            handlePaymentSuccess={this.handlePaymentResponse}
                        />
                    </FormPrompt>



                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AdmissionProfile);
