import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    setpersonDetails
} from "../../../../actions/setpersondetailsaction";
import { hospitalCode, serverLink } from "../../../../resources/url";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import "./categorylist.css";
import {loginValidation} from "../../../../context/loginvalidation";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Navigator from "../../../Settings/navigation/navigation";

class DrugSubSubCategoryList extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"pharmacy/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            columns: [
                {
                    name: "S/N",
                    selector: row => `${row.count}`,
                    sortable: true,
                    width: '7%'
                },
                {
                    name: "Drug Category",
                    selector: row => `${row.rawData.categoryName}`,
                    sortable: true,
                    width: '20%'
                },
                {
                    name: "Drug Sub Category",
                    selector: row => `${row.rawData.subCategoryName}`,
                    sortable: true,
                    width: '20%'
                },
                {
                    name: "Drug Sub Sub Category",
                    selector: row => `${row.rawData.subSubCategoryName}`,
                    sortable: true,
                    width: '20%'
                },
                {
                    name: "Description",
                    selector: row => `${row.rawData.description === '' ? 'N/A' :  row.rawData.description}`,
                    sortable: true,
                    width: '25%'
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to="/pharmacy/drug/category/sub/sub/update">
                            <button
                                type="button"
                                onClick={async () => {
                                    const drugCategoryData = {
                                        ...row.rawData,
                                    };
                                    this.props.setOnPatientDetails(drugCategoryData);
                                }}
                                className="btn btn-primary btn-sm"
                            >

                                <i className="fa fa-edit" aria-hidden="true"/>
                            </button>
                        </Link>
                    ),
                    width: '8%',
                },

            ],
            data: [],

            selectedDrugCategory: "",
            selectedDrugCategoryId: "",

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchDrugSubSubCategory();
    }

    async fetchDrugSubSubCategory() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}drug/category/sub/sub/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                data.map((data, index) => {
                    this.setState({
                        data: [...this.state.data,
                            {
                                count: index+1,
                                rawData: data,
                            }
                        ],
                    });
                })
                this.setState({
                    isLoading: false,
                });
            });
    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };


    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search Drug Sub Category',

        };
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <ConfirmDialogBox
                        openDailog={this.state.openConfirmDialog}
                        onSetOpenDailog={this.closeConfirmDialog}
                        handleConfirmOkBtn={this.deleteSpecialisation}
                        isLoading={this.state.isDeleting}
                        title="Delete"
                        des={
                            "Are you sure to delete " +
                            this.state.selectedDrugCategory +
                            " " +
                            "details"
                        }
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Drug category not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Drug Sub Sub-Category" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                        </div>

                        <Link to="/pharmacy/drug/category/sub/sub/add">
                            <button
                                type="button"
                                className="btn btn-warning"
                            >
                                + Add Drug Sub Sub-Category
                            </button>
                        </Link>
                    </div>
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            className="tablehead"
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DrugSubSubCategoryList);
