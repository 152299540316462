import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./birth-certificate.css";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import BirthCertificateForm from "./birth-certificate-form";

class Birth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            date: null,
            imaging: "",
            imagefile: "",
            htmlelement: "",
            isLoading: false,
            patientSerial: this.props.patientData.patientSerial,
            patientFullName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                this.props.patientData.surname,

            formData: {
                patientSerial: this.props.patientData.patientSerial,
                patientName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                    this.props.patientData.surname,
                hospitalCode: "",
                babyName: "",
                mother: "",
                father: "",
                dateOfBirth: "",
                timeOfBirth: "",
                placeOfBirth: "",
                weightAtBirth: "",
                certificateFile: "",
                deliveredBy: "",
                submittedBy: "",
                image_name: "",
            },

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    onHandleSubmit = (e) => {
        e.preventDefault();
        if (this.state.imagefile === ""){
            toast.error("Please upload Certificate File");
            return false;
        }
        this.setState({
            isSubmitting: true
        })
        this.onUpload();
    };

    async onUpload() {
        let submit_btn = document.getElementById('SubmissionBtn');
        submit_btn.innerHTML = `Submitting ...`
        submit_btn.setAttribute('disabled', 'disabled');

        let sendData = {
            ...this.state.formData,
            babyName: this.state.patientFullName,
            submittedBy: localStorage.getItem('userID'),
            hospitalCode: this.state.hospitalCode
        };

        axios.post(`${this.state.serverLink}patient/birth-certificate/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                submit_btn.innerHTML = `Save`;
                submit_btn.disbled = false;
                if(response === "success") {
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('file', this.state.imagefile);
                        formData.append('entry_id', result.data.id)
                        axios.patch(`${this.state.serverLink}patient/upload-birth-certificate`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.props.handleSuccessDialog('ADDED', 'Birth Certificate Added Successfully');
                }  else if (response === "exist"){
                    this.props.handleErrorDialog('ERROR', 'Birth Certificate Already Exist');
                }  else if (response === "error"){
                    this.props.handleErrorDialog('ERROR', 'An error occur adding the file. Please try again!');
                } else {
                    this.props.handleErrorDialog('ERROR', "An error occur. Please try again!");
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                submit_btn.innerHTML = `Save`;
                submit_btn.disbled = false;
                this.props.handleErrorDialog('ERROR', err);
            });
    }

    onImageChanges = (event) => {
        const formData = this.state.formData;
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg"  || file.type === "application/pdf" ) {
            } else {
                toast.error("Only .pdf, .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 1000000) {
                toast.error("max file size is 1mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                console.log(file.name)
                this.setState({
                    imagefile: file,
                    htmlelement: e.target.result,
                    formData: {
                        ...formData,
                        image_name: file.name,
                    }
                });
            };
        }
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };

    onImageRemoval = () => {
        this.setState({
            imageFile: "",
            imaging: "",
            htmlelement: (
                <i className="fa fa-user fa-8x" aria-hidden="true"/>
            ),
        });
    };

    onEdit = (e) => {
        const formData = this.state.formData;

        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage" key={"birth"}>
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <BirthCertificateForm
                                onHandleSubmit={this.onHandleSubmit}
                                onEdit={this.onEdit}
                                patientSerial={this.state.patientSerial}
                                patientName={this.state.patientFullName}
                                data={this.state.formData}
                                htmlelement={this.state.htmlelement}
                                onImageRemoval={this.onImageRemoval}
                                onImageChanges={this.onImageChanges}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(Birth);
