import Logo from '../Images/user-profile-icon.png';
export const defaultImage = Logo
export const projectName = "Baze University Hospital";
export const formatDateAndTime = (date, option) => {
    if (date !== null) {
        const user_date = new Date(date);
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const day =
            user_date.getDate() < 10
                ? "0" + user_date.getDate()
                : user_date.getDate();
        const hour =
            user_date.getHours() < 10
                ? "0" + user_date.getHours()
                : user_date.getHours();
        const min =
            user_date.getMinutes() < 10
                ? "0" + user_date.getMinutes()
                : user_date.getMinutes();
        const sec =
            user_date.getSeconds() < 10
                ? "0" + user_date.getSeconds()
                : user_date.getSeconds();

        let date_string = "";
        if (option === "date_and_time")
            date_string = `${day}-${
                monthNames[user_date.getMonth()]
            }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
        else if (option === "date")
            date_string = `${day}-${
                monthNames[user_date.getMonth()]
            }-${user_date.getFullYear()}`;
        else if (option === "day") date_string = day;
        else if (option === "full_month")
            date_string = monthNames[user_date.getMonth()];
        else if (option === "short_month")
            date_string = monthNamesShort[user_date.getMonth()];
        else if (option === "year_only") date_string = user_date.getFullYear();

        return date_string;
    } else {
        return "--";
    }
};

export const formatDateAndTime2 = (date) => {
    if (date !== null) {
        const user_date = new Date(date);
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return `${user_date.getDate()}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} : ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}`
    } else {
        return '--'
    }
};

export const  formatTime = (timeString) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');

    // Create a new Date object and set the hours and minutes
    const formattedTime = new Date();
    formattedTime.setHours(parseInt(hours, 10));
    formattedTime.setMinutes(parseInt(minutes, 10));

    // Format the time into your desired format (e.g., 12-hour format with AM/PM)
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return formattedTime.toLocaleString('en-US', options);
}
export const currencyConverter = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });
    return formatter.format(amount)
}
export const checkImageURL = (url) => {
    if (!url) return false
    else {
        const pattern = new RegExp('^https?:\\/\\/.+\\.(png|jpg|jpeg|bmp|gif|webp)$', 'i');
        return pattern.test(url);
    }
};

export function currentDate(timestamp) {
    const currentDate = new Date(timestamp);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const imageExists = (url) => {
    const http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
};

export const moneyFormat = (amount) => {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    return formatter.format(amount);
}
