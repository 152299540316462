import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Select from "react-select";
import "./user-profile-form.css";
import Navigator from "../../../Settings/navigation/navigation";
import JoditEditor from "jodit-react";
import SimpleFileUpload from "react-simple-file-upload";
import {uploadAPI} from "../../../../resources/url";

class UpdateProfileForm extends Component {
    render() {
        const editorConfig = {
            readonly: false,
            height: 200
        };
        return (
            <div className="editd_person_details_formpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="linkedin">LinkedIn </label>
                                            <input
                                                name="linkedin"
                                                type="text"
                                                className="form-control"
                                                id="linkedin"
                                                value={this.props.data.linkedin}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="twitter">Twitter </label>
                                            <input
                                                name="twitter"
                                                type="text"
                                                className="form-control"
                                                id="twitter"
                                                value={this.props.data.twitter}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="googleScholar">Google Scholar </label>
                                            <input
                                                name="googleScholar"
                                                type="text"
                                                className="form-control"
                                                id="googleScholar"
                                                value={this.props.data.googleScholar}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="researchGate">Research Gate </label>
                                            <input
                                                name="researchGate"
                                                type="text"
                                                className="form-control"
                                                id="researchGate"
                                                value={this.props.data.researchGate}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="academia">Academia </label>
                                            <input
                                                name="academia"
                                                type="text"
                                                className="form-control"
                                                id="academia"
                                                value={this.props.data.academia}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="experience">Experience</label>
                                            <input
                                                name="experience"
                                                type="text"
                                                className="form-control"
                                                id="experience"
                                                value={this.props.data.experience}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="description">Biography </label>
                                            <JoditEditor
                                                value={this.props.data.aboutDoctor}
                                                config={editorConfig}
                                                onBlur={this.props.descriptionHandler}
                                                // onChange={this.props.descriptionHandler}
                                            />
                                        </div>
                                        <div className="col-md-6   mb-3" >
                                            <label className="required fs-5 fw-bold mb-2">Image</label>
                                            <div className="mb-3">
                                                <div className="fv-row mb-2" style={{border: '1px solid #eeeeee', padding: '10px', borderRadius: '5px'}}>
                                                    <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                                        document.getElementById("photo").click()
                                                    }}>
                                                        <div className="dz-message needsclick">
                                                            <i className="bi bi-image text-primary fs-3x"/>
                                                            <div className="ms-4 col-md-9">
                                                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                                <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                            </div>
                                                            <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={150} height={150} srcSet={this.props.image !== "" ? this.props.image : ""}/> : ""}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="alert-danger"> Max of 2MB file is allowed!</span>
                                            </div>
                                            <input type="file"  id="photo" name={"photo"}  accept={"image/*"} onChange={this.props.onImageChange} hidden />
                                        </div>

                                    </div>

                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdateProfileForm;
