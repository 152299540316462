import React, { Component } from "react";
import { hospitalCode, serverLink } from "../../../resources/url";
import "./inpatient.css";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";
import DataTable from "../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../resources/constants";


class InPatient extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 50,

            isLoadMoreData: false,

            totalNumOfPatient: null,
            noMoreDataText: "",

            patientList: [],

            isSearching: false,
            isSearchDataShow: false,
            
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }
    componentDidMount() {
        this.fetchAdmissionPatient();
    }

    async fetchAdmissionPatient() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}patient_admission/patient/list/active/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    showTable = () => {

        try {
            return this.state.patientList.map((p, index) => {
                let date = new Date(p.admittedOn);
                const admittedDate = `${date.getDate()}/${
                    date.getMonth() + 1
                }/${date.getFullYear()}`;
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{p.patientName}</td>
                        <td>{p.patientSerial}</td>
                        <td>{p.blockName === "" ? "N/A" : p.blockName}</td>
                        <td>{p.roomName}</td>
                        <td>{p.bedName === "" ? "N/A" : p.bedName}</td>
                        <td>{admittedDate === "" ? "N/A" : admittedDate}</td>
                        <td>
                            <Link to={`/admission/profile${this.props.userType === "nurse" ? '/nurse' : this.props.userType === "service-desk" ? '/service-desk' : ''}/`+p._id}>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                >
                                    <i className="fa fa-check-circle" aria-hidden="true"/>
                                </button>
                            </Link>
                        </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };



    render() {
        const header = ["S/N", "Patient Name",  "Patient Serial", "Block Name", "Room Name", "Bed Name",  "Admitted Date", "Option"];
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (<div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Inpatient List"/>
                    </div>
                    <hr/>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <h3 className="card-title">Patient List</h3>
                            <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')}
                                       body={this.showTable()} title={`Inpatient Report`}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(InPatient);
