import React, {Component} from "react";
import "./patientspecimen.css";
import 'react-tabs/style/react-tabs.css';
import {hospitalCode, serverLink} from "../../../resources/url";
import {Link} from "react-router-dom";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Navigator from "../../Settings/navigation/navigation";
import {connect} from "react-redux";

class PatientSpecimen extends Component {
    constructor(props) {
        super(props);

        let page_url = window.location.pathname;
        let page_id = page_url.split('/');
        let id = atob(page_id[4]);

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: true,

            patientSerial: id,
            patientDetails: [],
            patientAllergy: [],
            messageDescription: "",

            confirmedTest: [],
            ongoingTest: [],
            columns: [
                {
                    name: "#",
                    selector: row => `${row.index}`,
                    sortable: true
                },
                {
                    name: "Request ID",
                    selector: row => `${row.requestSerial}`,
                    sortable: true
                },
                {
                    name: "Test Name",
                    selector: row => `${row.testName}`,
                    sortable: true
                },
                {
                    name: "Amount",
                    selector: row => `${row.testAmount}`,
                    sortable: true
                },
                {
                    name: "Test Status",
                    selector: row => `${row.testStatus}`,
                    sortable: true
                },
                {
                    name: "Request Date",
                    selector: row => `${row.requestDate}`,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to={'/lab/test/requests/'+row._id}>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-notes-medical" aria-hidden="true"/>
                            </button>
                        </Link>
                    ),
                }
            ],
        };
    }

    componentDidMount() {
        this.fetchLabTestRequest();
        this.fetchPatient();
        this.fetchPatientAllergy();
    }

    async fetchLabTestRequest() {
        await fetch(`${serverLink}laboratory/test/request/all/${this.state.patientSerial}/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                if (data.length < 1) {
                    alert('No lab test request found for the selected patient')
                    window.location.href = '/lab/test/requests'
                    return false;
                }

                data.map((request, index) => {
                    if (request.testStatus !== 'result confirmed') {
                        this.setState({
                            ongoingTest: [...this.state.ongoingTest,
                                {
                                    index: index,
                                    _id: request._id,
                                    requestSerial: request.requestSerial,
                                    testName: request.testName,
                                    testAmount: this.moneyFormat(request.testAmount),
                                    labName: request.laboratoryName,
                                    paymentStatus: request.paymentStatus,
                                    testStatus: request.testStatus,
                                    requestDate: this.formatDateAndTime(request.requestDate)
                                }
                            ]
                        })
                    } else {
                        this.setState({
                            confirmedTest: [...this.state.confirmedTest,
                                {
                                    index: index,
                                    _id: request._id,
                                    requestSerial: request.requestSerial,
                                    testName: request.testName,
                                    testAmount: this.moneyFormat(request.testAmount),
                                    labName: request.laboratoryName,
                                    paymentStatus: request.paymentStatus,
                                    testStatus: request.testStatus,
                                    requestDate: this.formatDateAndTime(request.requestDate)
                                }
                            ]
                        })
                    }
                })

                this.setState({
                    isLoading: false
                });
            });
    }

    async fetchPatient() {
        await fetch(`${this.state.serverLink}patient/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientDetails: data,
                    isLoading: false,
                });
            });
    }


    async fetchPatientAllergy() {
        await fetch(`${this.state.serverLink}allergy/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientAllergy: data,
                    isLoading: false,
                });
            });
    }

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()} : 
                    ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}
                    
                    `
        } else {
            return '--'
        }
    };

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            return `${user_date.getDate()}/${monthNames[user_date.getMonth()]}/${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    render() {
        const columns = this.state.columns;
        let data = this.state.ongoingTest;
        const ongoingData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search test request'
        };

        data = this.state.confirmedTest;
        const confirmedData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search test request'
        };

        return this.state.isLoading ? (
            <div className="patientspecimenpage">
                <i className="fas fa-spinner fa-pulse fa-2x" />
            </div>
        ) : (
            <div className="patientspecimenpage">
                <div className="main_section">

                    <div className="topheader">
                        <Navigator title="Patient Test" />
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h4>Patient Details</h4>
                                    <table className="table table-bordered table-responsive-md">
                                        <tbody>
                                            <tr>
                                                <th>Patient ID</th>
                                                <td>{this.state.patientDetails.patientSerial ? this.state.patientDetails.patientSerial : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Patient Name</th>
                                                <td>
                                                    {
                                                        this.state.patientDetails.patientSerial ?
                                                            `${this.state.patientDetails.firstName} ${this.state.patientDetails.middleName} ${this.state.patientDetails.surname}`
                                                            : 'N/A'
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Phone Number</th>
                                                <td>{this.state.patientDetails.phoneNumber ? this.state.patientDetails.phoneNumber : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Date of Birth</th>
                                                <td>{this.state.patientDetails.dateOfBirth ? this.formatDate(this.state.patientDetails.dateOfBirth) : 'N/A'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h4>Patient Allergy</h4>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Allergy</th>
                                                <th>Medication</th>
                                                <th>Duration Before Subsiding</th>
                                                <th>Last Attack Date</th>
                                            </tr>
                                        </thead>
                                        {this.state.patientAllergy && this.state.patientAllergy.length < 1 ? (
                                            <tbody>
                                                <tr>
                                                    <td>No Allergy Found</td>
                                                </tr>
                                            </tbody >
                                        ) : ( <tbody className="tablebody">{this.state.patientAllergy && this.state.patientAllergy.map(allergy => {
                                                let lastAttackDate = new Date(allergy.lastAttackDate);
                                                const lastAttack = `${lastAttackDate.getDate()}/${
                                                    lastAttackDate.getMonth() + 1
                                                }/${lastAttackDate.getFullYear()}`;
                                                return (
                                                    <tr key={allergy._id}>
                                                        <td>{allergy.allergyName ? allergy.allergyName : 'N/A'}</td>
                                                        <td>{allergy.allergyMedication ? allergy.allergyMedication : 'N/A'}</td>
                                                        <td>{allergy.durationBeforeSubsiding ? allergy.durationBeforeSubsiding : 'N/A'}</td>
                                                        <td>{ lastAttack ? lastAttack : 'N/A'}</td>
                                                    </tr>
                                                );

                                            })
                                            }
                                        </tbody>
                                        ) }

                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                    <hr/>
                    <h5 className="text-center" style={{color: '#11283f'}}><b>Requested Test</b></h5>
                    <DataTableExtensions
                        {...ongoingData}
                    >
                        <DataTable
                            noHeader
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover />
                    </DataTableExtensions>

                    <hr/>
                    <h5 className="text-center" style={{color: '#11283f'}}><b>Completed Test</b></h5>
                    <DataTableExtensions
                        {...confirmedData}
                    >
                        <DataTable
                            noHeader
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(PatientSpecimen);
