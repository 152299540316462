import React, {Component, useEffect, useState} from "react";
import SideNavBar from "./components/sideNavBar";
import LoginPage from "./components/Login/login";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink, hospitalCode} from "./resources/url";
import {setHospitalDetails} from "./actions/setpersondetailsaction";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ForgottenPassword from "./components/Login/forgotten-password";

function App(props) {
    const [isParent, setIsParent] = useState(false)
    const [logo, setLogo] = useState("")
    const [hospitalName, setHospitalName] = useState("")
    const [color, setColor] = useState("")

    const onLoadHospitalData = async () => {
        await axios.get(`${serverLink}hospital/${hospitalCode}`)
            .then(result => {
                props.setOnUpdateHospital(result.data);
                setLogo(result.data.logo.includes("simplefileupload") ? result.data.logo : serverLink + 'public/uploads/hospital/' + result.data.logo)
                setColor(result.data.color)
                setHospitalName(result.data.hospitalName)
            })
            .catch(error => {
                console.log('Hospital Record not fetch', error)
            })
    }

    useEffect(() => {
        if (isParent === false)
            onLoadHospitalData();
    }, [])

    return (
        <div className="App">
            {
                props.loginDetails.isLoggedIn ?
                    <SideNavBar hospitalName={hospitalName} hospitalColor={color} hospitalLogo={logo}
                                isHospitalParent={isParent}/> :
                    <BrowserRouter>
                        <Routes>
                            <Route>
                                <Route path="/forgotten-password" element={<ForgottenPassword/>}/>
                                <Route path="/" element={<LoginPage hospitalName={hospitalName} hospitalColor={color}
                                                                    hospitalLogo={logo} isHospitalParent={isParent}/>
                                }/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
            }
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnUpdateHospital: (p) => {
            dispatch(setHospitalDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
