import React, {Component} from "react";
import "./admissionpage.css";
import 'react-tabs/style/react-tabs.css';
import {hospitalCode, serverLink} from "../../../resources/url";
import {Link} from "react-router-dom";
import roomImage from "../../../Images/room.jpg";
import 'react-data-table-component-extensions/dist/index.css';
import Navigator from "../../Settings/navigation/navigation";
import {connect} from "react-redux";

class AdmissionPage extends Component {
    constructor(props) {
        super(props);

        let page_url = window.location.pathname;
        let page_id = page_url.split('/');
        let id = page_id[3];

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: true,
            searchText: "",
            isSearching: false,
            isSearchDataShow: false,

            patientID: id,
            patientDetails: [],
            admissionHistory: [],
            hospitalRooms: [],
            messageDescription: "",
        };
    }

    componentDidMount() {
        this.fetchPatient();
        this.fetchRooms();
    }

    async fetchPatient() {
        await fetch(`${this.state.serverLink}patient/info/${this.state.hospitalCode}/${this.state.patientID}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientDetails: data,
                    isLoading: false,
                });
            });
    }

    async fetchRooms() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}hospital_room/available_for_booking/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    hospitalRooms: data,
                    isLoading: false,
                });
            });
    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase();

            await fetch(`${this.state.serverLink}hospital_room/search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            hospitalRooms: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            return `${user_date.getDate()}/${monthNames[user_date.getMonth()]}/${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    render() {
        return this.state.isLoading ? (
            <div className="admissionpage">
                <i className="fas fa-spinner fa-pulse fa-2x" />
            </div>
        ) : (
            <div className="admissionpage">
                <div className="main_section">

                    <div className="topheader">
                        <Navigator title="In-Patient Admission" />
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h4>Patient Details</h4>
                                    <table className="table table-bordered table-responsive-md">
                                        <tbody>
                                        <tr>
                                            <th>Patient ID</th>
                                            <td>{this.state.patientDetails.patientSerial ? this.state.patientDetails.patientSerial : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th>Patient Name</th>
                                            <td>
                                                {
                                                    this.state.patientDetails.patientSerial ?
                                                        `${this.state.patientDetails.firstName} ${this.state.patientDetails.middleName} ${this.state.patientDetails.surname}`
                                                        : 'N/A'
                                                }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h4>Admission History</h4>
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Room Name</th>
                                            <th>Room Type</th>
                                            <th>Block</th>
                                            <th>Admission Date</th>
                                        </tr>
                                        </thead>
                                        {this.state.admissionHistory && this.state.admissionHistory.length < 1 ? (
                                            <tbody>
                                            <tr>
                                                <td className="text-center" colSpan={4}>No Record Found</td>
                                            </tr>
                                            </tbody>
                                        ) : ( <tbody className="tablebody">{this.state.admissionHistory && this.state.admissionHistory.map(r => {
                                                let admissionDate = new Date(r.lastAttackDate);
                                                const lastAdmissionDate = `${admissionDate.getDate()}/${
                                                    admissionDate.getMonth() + 1
                                                }/${admissionDate.getFullYear()}`;
                                                return (
                                                    <tr key={r._id}>
                                                        <td>{r.roomName ? r.roomName : 'N/A'}</td>
                                                        <td>{r.typeName ? r.typeName : 'N/A'}</td>
                                                        <td>{r.blockName ? r.blockName : 'N/A'}</td>
                                                        <td>{ lastAdmissionDate ? lastAdmissionDate : 'N/A'}</td>
                                                    </tr>
                                                );

                                            })
                                            }
                                            </tbody>
                                        ) }

                                    </table>

                                </div>
                            </div>
                        </div>

                    </div>
                    <hr/>
                   <div className="row">
                       <div className="col-md-6">
                           <div className="topheader ">
                               <ul>
                                   <li>
                                       <i
                                           className="fa fa-arrow-circle-o-right fa-2x"
                                           aria-hidden="true"
                                       />
                                   </li>
                                   <li>
                                       <h5>Room Availability</h5>
                                   </li>
                               </ul>
                           </div>
                       </div>
                       <div className="col-md-6">
                           <div className="search">
                               <input
                                   type="text"
                                   className="searchTerm"
                                   placeholder="Search hospital room by name"
                                   onKeyDown={(e) => {
                                       if (e.key === "Enter") {
                                           this.handleSearch();
                                       }
                                   }}
                                   onChange={(e) => {
                                       this.setState({
                                           searchText: e.target.value,
                                       });
                                   }}
                               />

                               <button
                                   onClick={this.handleSearch}
                                   type="submit"
                                   className="searchButton"
                               >
                                   <i className="fa fa-search"></i>
                               </button>
                           </div>
                       </div>
                   </div>
                    <div className="col-md-12">
                            {this.state.hospitalRooms && this.state.hospitalRooms.length < 1 ? (
                                <h3>No Record Found</h3>
                            ) : ( <div className="row"> {
                                    this.state.hospitalRooms && this.state.hospitalRooms.map(room => {
                                        return (<div key={room._id} className="col-md-4">
                                        <div className="card" >
                                            <img src={roomImage} className="card-img-top" height="150" width="70" alt="..."/>
                                            <hr style={{border: "1px solid #cccccc"}} />
                                            <div className="card-body">
                                                <h5 className="card-title">Room {room.roomName}</h5>
                                                <table className="table sm">
                                                    <tbody>
                                                        <tr>
                                                            <th>Room Type:</th>
                                                            <td>{room.typeName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Block:</th>
                                                            <td>{room.blockName}</td>
                                                        </tr>

                                                        <tr>
                                                            <th>Num of Beds:</th>
                                                            <td>{room.bedCapacity}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Available Beds:</th>
                                                            <td>{room.bedCapacity - room.bedOccupied}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <Link to={"/admission/admit_page/" +this.state.patientDetails._id+"/"+room._id}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary form-control"
                                                    >
                                                        Admit Patient
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>)
                                    })
                                } </div>)
                            }


                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AdmissionPage);
