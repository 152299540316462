import React, { Component } from "react";
import "./serviceuserpage.css";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import Navigator from "../../Settings/navigation/navigation";
import axios from "axios";
import {serverLink} from "../../../resources/url";

class ServiceUserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            services: []
        }
    }

    componentDidMount() {
        this.getGroupServicesPermission();
    }

    getGroupServicesPermission = async () => {
        axios.get(`${serverLink}service/permission/group/group_name/${this.props.permissionData[0].groupName}`, this.props.doctorDetails.token)
            .then(response => {
                this.setState({
                    isLoading: false,
                    services: response.data
                })
            })
            .catch(error => {
                console.log("NETWORK ERROR", error)
            })
    }

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (<div className="dashboardpage">
            <div className="topheader">
                <Navigator title="Services" />
            </div>
            <div className="first_section">
                <div className="row">
                    {
                        this.state.services.length > 0 ?
                            this.state.services.map((perm, index) => {
                                return <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                                    <Link to={`/services/profile/${perm.serviceID}`}>
                                        <DashboardCard name={perm.serviceName} icon={`fa fa-cog fa-4x`} count="" />
                                    </Link>
                                </div>
                        }) : <h1>YOU'RE NOT PERMITTED TO VIEW THIS PAGE</h1>
                    }

                </div>

            </div>
        </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(ServiceUserPage);
