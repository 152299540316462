import React from 'react';
import { useNavigate } from 'react-router-dom';
export default function Navigator(props) {
    const navigate = useNavigate();
    return <ul>
            <li onClick={()=> navigate(-1)}>
                <i className="fa fa-arrow-circle-o-left fa-2x" aria-hidden="true"/>
            </li>
            <li>
                <h5>{props.title}</h5>
            </li>
    </ul>
}