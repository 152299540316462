import React, { Component } from "react";
import { connect } from "react-redux";
import './nursecheckin.css';
import {serverLink, hospitalCode} from "../../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import {loginValidation} from "../../../context/loginvalidation";
import FormPrompt from "../../DialogBoxes/formprompt";
import Navigator from "../../Settings/navigation/navigation";

class NurseCheckin extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            isLoading: true,
            doctorID: this.props.doctorData.userID,
            columns: [
                {
                    name: "Patient Name",
                    selector: row => `${row.patientName}`,
                    sortable: true
                },
                {
                    name: "Patient No",
                    selector: row => `${row.patientSerial}`,
                    sortable: true
                },
                {
                    name: "Department",
                    selector: row => `${row.appointmentDepartment}`,
                    sortable: true
                },
                {
                    name: "Appointment Time",
                    selector: row => `${row.appointmentTime}`,
                    sortable: true
                },
                {
                    name: "Check-in",
                    cell: (row) => (
                        <button
                            type="button"
                            onClick={() => this.onCheckInDialog(row.rowData)}
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-notes-medical" aria-hidden="true"/>
                        </button>
                    ),
                }
            ],
            data: [],
            isCloseDialogAppear: true,
            openCheckInDialog: false,
            selectedAppointment: '',
            message: ''

        };
    }
    componentDidMount() {
        this.getConsultationData();
    }

    async getConsultationData () {
        await axios.get(`${serverLink}doctor/consultation/nurse/today/${hospitalCode}/Scheduled`, this.props.doctorData.token)
            .then(result => {
                if (result.data.length > 0) {
                    let result_set = result.data;
                    result_set.map(val => {
                        this.setState({
                            data: [...this.state.data,
                                {
                                    id: val._id,
                                    patientName: val.patientName,
                                    patientSerial: val.patientSerial,
                                    appointmentDepartment: val.appointmentDepartment,
                                    appointmentTime: val.appointmentTime,
                                    checkInTime: val.checkInTime,
                                    appointmentStatus: val.appointmentStatus,
                                    rowData: val
                                }
                            ]
                        })
                    })
                }
                this.setState({
                    isLoading: false
                })
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    checkInPatient = (appointment) => {
        this.setState({
            message: ''
        });

        const today = new Date();
        const sendData = {
            patientSerial: appointment.patientSerial,
            patientName: appointment.patientName,
            appointmentStatus: "Checked In",
            checkInTime: today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
            checkInBy: this.props.doctorData.userID,
            _id: appointment._id
        }

        axios.patch(`${serverLink}appointment/check_in`, sendData, this.props.doctorData.token)
            .then(result => {
                const response = result.data.message;
                let message = '';
                if(response === "success") {
                    message = <p className="alert alert-success">Check-in successful</p>;
                } else {
                    message = <p className="alert alert-danger">Something went wrong check-in patient. Please try again!</p>
                }

                this.setState({message: message})
            })
            .catch(err => {
                this.setState({
                    message: <p className="alert alert-danger">Network error occurred. Please try again!</p>
                })

            });
    }

    onCheckInDialog = (appointment) => {
        this.setState({
            selectedAppointment: appointment,
            openCheckInDialog: true
        })
    }

    onCloseCheckInDialog = () => {
        window.location.reload();
        this.setState({
            openCheckInDialog: false,
            selectedAppointment: '',
            message: ''
        })
    }

    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: false,
            filterPlaceholder: 'Search patient'
        };

        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">
                    <FormPrompt
                        openDialog={this.state.openCheckInDialog}
                        title="CHECK IN FORM"
                        onSetOpenDialog={this.onCloseCheckInDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <p>Are you sure you want to check-in the following patient?</p>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th>Patient Serial</th>
                                    <td>{this.state.selectedAppointment.patientSerial}</td>
                                </tr>
                                <tr>
                                    <th>Patient Name</th>
                                    <td>{this.state.selectedAppointment.patientName}</td>
                                </tr>
                                <tr>
                                    <th>Department</th>
                                    <td>{this.state.selectedAppointment.appointmentDepartment}</td>
                                </tr>
                                <tr>
                                    <th>Doctor</th>
                                    <td>{this.state.selectedAppointment.doctorName}</td>
                                </tr>
                                <tr>
                                    <th>Time Slot</th>
                                    <td>{this.state.selectedAppointment.appointmentTime}</td>
                                </tr>
                            </tbody>
                        </table>
                        <button className="btn btn-primary btn-block btn-sm" onClick={() => this.checkInPatient(this.state.selectedAppointment)}>Check-In Patient</button>

                        {this.state.message}
                    </FormPrompt>

                    <div className="topheader">
                        <Navigator title="Check-in Patient" />
                    </div>
                    <hr />
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorData: state.loginDetails,
        permissionData: state.permissionDetails
    };
};

export default connect(mapStateToProps, null)(NurseCheckin);
