import React, {useEffect, useState} from "react";
import "./patientdashboard.css";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loginValidation } from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";
import MenuIcon from "../../Dashboard/utils/menu-icon";
import {FaBaby, FaBookDead, FaCalendarPlus, FaHospitalUser, FaImage, FaUserNurse, FaUsers} from "react-icons/fa";
import {FaBedPulse, FaNairaSign, FaUsersBetweenLines} from "react-icons/fa6";
import Chart from "react-google-charts";
import ApexChart from "react-apexcharts";
import {currencyConverter, currentDate, formatDateAndTime} from "../../../resources/constants";

const PatientDashboard = ({ permissionData, doctorDetails }) => {
    const [loading, setLoading] = useState(true);
    const [appointmentStatus, setAppointmentStatus] = useState([])
    const [admissionStatus, setAdmissionStatus] = useState([])
    const [patientGender, setPatientGender] = useState([])
    const [apexSeries, setApexSeries] = useState([]);
    const [apexChartOption, setApexChartOption] = useState({});
    const [inpatient, setInpatient] = useState(0)
    const [appointments, setAppointment] = useState(0)
    const [patientCount, setPatientCount] = useState(0)
    const [nullPhoto, setNullPhoto] = useState(0)
    const [deceased, setDeceased] = useState(0)
    const [newBorn, setNewBorn] = useState(0)
    const [wallet, setWallet] = useState(0)
    const [dependant, setDependant] = useState(0)


    useEffect(() => {
        if (loginValidation(window.location.pathname, permissionData) !== 'permitted') {
            window.location.href = '/';
        }
        fetchDetails();
    }, []);

    const fetchDetails = async () => {
        try {
            const dashboard_response = await axios.get(`${serverLink}report/patient/dashboard/${hospitalCode}`, doctorDetails.token);
            const appointment = dashboard_response.data.appointments;
            const admission = dashboard_response.data.admission;
            const patients = dashboard_response.data.patients;
            const deceased = dashboard_response.data.deceased;
            const birth = dashboard_response.data.birth;

            let appointment_status = ['Scheduled', 'Checked In', 'Vital Taken', 'Completed', 'Missed'];
            let admission_status = ['Active', 'Discharged'];
            let patient_gender = ['Male', 'Female'];
            let patient_status = ['Active', 'Deceased'];
            let _dependant = 0;

                //Set Count
            setInpatient(admission.filter(item => item.status === 'active').length)
            setAppointment(appointment.filter(item => item.appointmentStatus === 'Scheduled' || item.appointmentStatus === 'Checked In' || item.appointmentStatus === 'Vital Taken').length)
            setPatientCount(patients.length)
            setNullPhoto(patients.filter(item => !item.passport).length)
            setDeceased(patients.filter(item => item.status === 'Deceased').length)
            setWallet(patients.reduce((acc, item) => acc + parseFloat(item.walletAmount), 0));
            patients.forEach(item => {
                if (item.dependantList) {
                    _dependant += item.dependantList.length;
                }
            });
            setDependant(_dependant)
            setNewBorn(birth)
            setDeceased(deceased)

            //Initialize Data
            let appointmentStatusData =  [];
            let admissionStatusData = [];
            let genderData = [];
            let genderCountData = [];

            //Appointment trend by status
            appointment_status.map((e, i) => {
                let count = appointment.filter(item => item.appointmentStatus === e).length
                appointmentStatusData.push([e, count]);
            })

            //Admission trend by status
            admission_status.map((e, i) => {
                let count = admission.filter(item => item.status === e.toLowerCase()).length
                admissionStatusData.push([e, count]);
            })

            patient_gender.map((e, i) => {
                let count = patients.filter(item => item.gender.toString() === e.toString()).length
                genderCountData.push(count)
                genderData.push(e)
            })

            //Patient trend by gender
            setApexChartOption({
                ...apexChartOption,
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: genderData
                }
            })
            setApexSeries([{
                name: "Count",
                data: genderCountData
            }])


            //Set Chart Data
            setAppointmentStatus(appointmentStatusData)
            setAdmissionStatus(admissionStatusData)
            setPatientGender(genderData)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    }

    const appointmentAnalyticsData = [["Status", "Count"], ...appointmentStatus];
    const appointmentAnalyticsOptions = {
        title: "Appointment Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const genderAnalyticsData = [["Gender", "Count"], ...patientGender];
    const genderAnalyticsOptions = {
        title: "Nurse Trend By Gender",
        pieHole: 0.3,
        is3D: false,
    };


    return (loading ? (
                <div className="dashboardpage">
                    <i className="fas fa-spinner fa-pulse fa-2x "/>
                </div>
            ) :
        <div className="dashboardpage">
            <div className="topheader">
                <Navigator title="Patient Dashboard" />
            </div>

            <div className="first_section" style={{marginTop: '-30px'}}>
                <div className="row">
                    <div className="col-md-3">
                        <MenuIcon name="Patients" icon={<FaUsers size={40}/>} count={patientCount}/>
                    </div>
                        <div className="col-md-3">
                            <MenuIcon name={`Inpatients (${formatDateAndTime(currentDate(new Date()), 'date')})`} icon={<FaHospitalUser size={40}/>} count={inpatient}/>
                        </div>
                        <div className="col-md-3">
                            <MenuIcon name={`Outpatients (${formatDateAndTime(currentDate(new Date()), 'date')})`} icon={<FaCalendarPlus size={40}/>} count={appointments}/>
                        </div>
                        <div className="col-md-3">
                            <MenuIcon name="No Photo" icon={<FaImage size={40}/>} count={nullPhoto}/>
                        </div>
                        <div className="col-md-3">
                            <MenuIcon name="Wallet Balance" icon={<FaNairaSign size={40}/>} count={currencyConverter(wallet)}/>
                        </div>
                        <div className="col-md-3">
                            <MenuIcon name="Dependants" icon={<FaUsersBetweenLines size={40}/>} count={dependant}/>
                        </div>
                        <div className="col-md-3">
                            <MenuIcon name="Deceased Patients" icon={<FaBookDead size={40}/>} count={deceased}/>
                        </div>
                        <div className="col-md-3">
                            <MenuIcon name="New Born" icon={<FaBaby size={40}/>} count={newBorn}/>
                        </div>
                    {/*Chart Section*/}
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    appointmentStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="240px"
                                        data={appointmentAnalyticsData}
                                        options={appointmentAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                <h6><b>Patient Registration Trend By Gender</b></h6>
                                {

                                    <ApexChart
                                        options={apexChartOption}
                                        series={apexSeries}
                                        type="bar"
                                        height={200}
                                        width="100%"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="col-md-12 mt-3 mb-0" style={{borderBottom: '1px solid #CCCCCC', borderTop: '1px solid #CCCCCC', padding: '5px'}}>
                            <h2><b>Menu</b></h2>
                        </div>
                    </div>

                    {permissionData &&
                        permissionData.map((perm, index) => {
                            if (perm.mainMenuName === 'Patient' && perm.mainMenuLink === null) {
                                return (
                                    <div key={index} className="col-sm-6">
                                        {" "}
                                        <Link to={perm.subMenuLink}>
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(PatientDashboard);
