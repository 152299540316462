import React, { Component } from "react";
import "./lablist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails
} from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import { serverLink, hospitalCode } from "../../../../resources/url";
import {loginValidation} from "../../../../context/loginvalidation";
import Navigator from "../../../Settings/navigation/navigation";
import DataTable from "../../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../../resources/constants";

class LabList extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"laboratory/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 20,

            labList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedLabName: "",
            selectedLabId: "",

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchLabs();
    }

    async fetchLabs() {
        this.setState({ isLoadMoreData: true });
         await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    labList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    showTable = () => {
        try {
            return this.state.labList.map((lab, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{lab.laboratoryName}</td>
                        <td>{lab.labType}</td>
                        <td>{lab.laboratoryAddress === "" ? "N/A" : lab.laboratoryAddress}</td>
                        <td>{lab.block === "" ? "N/A" : lab.block }</td>
                        <td>
                            <Link to="/lab/update">
                                <button
                                    onClick={async () => {
                                        const labData = {
                                            ...lab,
                                            labId: lab._id,
                                        };
                                        this.props.setOnPatientDetails(labData);
                                    }}
                                    type="button"
                                    className="btn btn-success"
                                >
                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </Link>
                        </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };


    render() {
        const header = ["S/N", "Laboratory Name",  "Laboratory Type", "Laboratory Address", "Block", "Option"];
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Laboratory"/>
                    </div>
                    <hr/>
                </div>

                <ErrorDialogBox
                    openDialog={this.state.openErrorDialog}
                    onSetOpenDialog={this.closeErrorDialog}
                    title="Record Not Found"
                    des="lab record not found. Please try again!"
                />

                <div className="card">
                    <div className="card-body">
                        <div className="">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="page-pretitle">
                                    </div>
                                    <h3 className="card-title">Laboratory List</h3>
                                </div>
                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">
                                        <Link to="/lab/add">
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                            >
                                                + Add Laboratory
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')}
                                       body={this.showTable()} title={`Laboratory Report`}/>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabList);
