import React, { Component } from "react";
import "./searchrequest";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import {serverLink, hospitalCode} from "../../../resources/url";
import {loginValidation} from "../../../context/loginvalidation";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Navigator from "../../Settings/navigation/navigation";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
class SearchLabRequest extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            searchText: "",
            isLoading: true,
            openErrorDialog: false,
            columns: [
                {
                    name: "Patient Name",
                    selector: row => `${row.patientName}`,
                    sortable: true
                },
                {
                    name: "Patient No",
                    selector: row => `${row.patientSerial}`,
                    sortable: true
                },
                {
                    name: "Laboratory",
                    selector: row => `${row.laboratoryName}`,
                    sortable: true
                },
                {
                    name: "Test Name",
                    selector: row => `${row.testName}`,
                    sortable: true
                },
                {
                    name: "Requested By",
                    selector: row => `${row.doctorName}`,
                    sortable: true
                },
                {
                    name: "Requested Date",
                    selector: row => `${this.formatDateAndTime(row.submittedOn)}`,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <>
                            <Link to={'/lab/test/request/'+row.id}>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                >
                                    <i className="fa fa-notes-medical" title={"View Request"} aria-hidden="true"/>
                                </button>
                            </Link>
                            <button
                                type="button"
                                onClick={() => {this.handleOnCancelRequestClick(row.id)}}
                                className="btn btn-danger btn-sm"
                            >
                                <i className="fa fa-times" title={"Cancel Request"} aria-hidden="true"/>
                            </button>
                        </>

                    ),
                }
            ],
            data: [],
            isSearching: false,
            isSearchDataShow: false,
            onOpenCancelModal: false,
            cancelData: "",
            cancelMessage: "Are you sure you want to cancel the selected test request?"
        };
    }

    componentDidMount() {
        this.getPendingTestRequests();
    }

    async getPendingTestRequests () {
        await axios.get(`${serverLink}laboratory/test/request/pending/list/${hospitalCode}`, this.props.doctorDetails.token)
            .then(result => {
                const data = result.data;
                if (data.length > 0) {
                    data.map(val => {
                        this.setState({
                            data: [...this.state.data,
                                {
                                    id: val._id,
                                    patientName: val.patientName,
                                    patientSerial: val.patientSerial,
                                    laboratoryName: val.laboratoryName,
                                    testName: val.testName,
                                    doctorName: val.doctorName,
                                    submittedOn: val.submittedOn,
                                    rowData: val
                                }
                            ]
                        })
                    })
                }
                this.setState({
                    isLoading: false
                })
            })
            .catch(error => {
                console.log("NETWORK ERROR", error)
            })
    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: false,
                isSearching: true
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");


            await fetch(`${serverLink}laboratory/search/lab/request/${hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data === null) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                        return false;
                    } else {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                        });
                        window.location.href = '/lab/test/request/'+data._id
                    }
                });
        }
    };

    handleOnCancelRequestClick = (row) => {
        this.setState({
            onOpenCancelModal: true,
            cancelData: row
        })
    }

    handleOnCancelRequest = async () => {
        this.setState({
            cancelMessage: <span className={"alert alert-info"}>Cancelling Request... Please wait!</span>
        });

        const sendData = {id: this.state.cancelData, updatedBy: this.props.doctorDetails.userID}
        await axios.patch(`${serverLink}laboratory/test/request/cancel`, sendData, this.props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        cancelMessage: <span className={"alert alert-success"}>Request Cancelled Successfully</span>
                    });
                    this.getPendingTestRequests();
                    const object = this;
                    setTimeout(function () {
                        object.closeErrorDialog();
                    }, 1000)

                } else {
                    this.setState({
                        cancelMessage: <span className={"alert alert-danger"}>Something went wrong cancelling request. Please check your network and try again!</span>
                    });
                }
            })
            .catch()
    }

    formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()} : 
                    ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}
                    
                    `
        } else {
            return '--'
        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
            onOpenCancelModal: false
        });
    };

    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search test'
        };

        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <ConfirmDialogBox
                    openDailog={this.state.onOpenCancelModal}
                    onSetOpenDialog={this.closeErrorDialog}
                    handleConfirmOkBtn={this.handleOnCancelRequest}
                    isLoading={false}
                    isDecision={true}
                    title="CANCEL REQUEST?"
                    des={ this.state.cancelMessage }
                />
                <div className="main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="No record found for the inputted record!"
                        />

                    <div className="topheader">
                        <Navigator title="Search Test Request" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <div className="search">
                                <input
                                    type="text"
                                    className="searchTerm"
                                    placeholder="Scan the request barcode here"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            this.handleSearch();
                                        }
                                    }}
                                    onChange={(e) => {
                                        this.setState({
                                            searchText: e.target.value,
                                        });
                                    }}
                                />

                                <button
                                    onClick={this.handleSearch}
                                    type="submit"
                                    className="searchButton"
                                >
                                    <i className="fa fa-search" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <hr/>
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(SearchLabRequest);
