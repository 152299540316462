import React, { Component } from "react";
import { Line } from "react-chartjs-2";

class PatientBloodPressureChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: this.props.label,
        datasets: [
          {
            label: this.props.data1_label,
            data: this.props.data1,
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)"
          },
          {
            label: this.props.data2_label,
            data: this.props.data2,
            fill: false,
            borderColor: "#742774"
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: this.props.title,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    };
  }

  render() {
    return (
      <Line
        data={this.state.data}
        height={50}
        width={100}
      ></Line>
    );
  }
}

export default PatientBloodPressureChart;
