import React, {Component} from "react";
import {Link} from "react-router-dom";
import {
    setpersonDetails
} from "../../../actions/setpersondetailsaction";
import {connect} from "react-redux";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";

class PatientDependantList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            openAreYouSure: false,
            deleteMessage: "",
            patientSerial: this.props.patientDetail.patientSerial,
            dependantSerial: ""
        }
    }


    async fetchPatient() {
        await fetch(`${this.state.serverLink}patient/${this.state.hospitalCode}/${this.state.patientSerial}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.props.setOnPatientDetails(data);
                window.location = "/patient/profile";
            });
    }

    DeleteDependant = (e) => {
        this.setState({
            deleteMessage: "Are you sure you want delete the "+e.patientSerial+" dependant?",
            openAreYouSure: true,
            dependantSerial: e.patientSerial
        })
    }

    handleDelete = () => {
        const sendData = {
            patientSerial: this.state.patientSerial,
            dependantSerial: this.state.dependantSerial
        };

        axios.patch(`${this.state.serverLink}patient/delete_dependant`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if(result.data.message === 'success') {
                    this.setState({
                        openAreYouSure: false
                    })
                    this.fetchPatient()

                }
            })
    }

    closeDeleteDialog = () => {
        this.setState({
            openAreYouSure: false,
        });
    };

    render() {
        let count = 0;
        return <div className="card mb-3">
            <ConfirmDialogBox
                openDailog={this.state.openAreYouSure}
                onSetOpenDialog={this.closeDeleteDialog}
                title="Are you sure"
                des={this.state.deleteMessage}
                handleConfirmOkBtn={this.handleDelete}
            >

            </ConfirmDialogBox>
            <div className="card-header">
                Dependant List
                {
                    !this.props.isFront &&
                        <>
                            <Link to="/patient/dependant/add">
                                <button
                                    className="float-right btn btn-info btn-sm"
                                    onClick={async () => {
                                        this.props.setOnPatientDetails(this.props.patientDetail);
                                    }}

                                >Add New Dependant</button>
                            </Link>

                            <Link to="/patient/add-dependant" className={"mr-4"} style={{marginRight: '20px'}}>
                                <button
                                    className="float-right btn btn-primary btn-sm mr-3"
                                    onClick={async () => {
                                        this.props.setOnPatientDetails(this.props.patientDetail);
                                    }}

                                >Add New Dependant </button>
                            </Link></>
                }

            </div>
            <div className="card-body table-responsive">
                {
                    typeof this.props.dependantList === 'undefined' ?
                        <div>No Dependant Found!</div> :
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Dependant Name</th>
                                <th>Dependant ID</th>
                                <th>Phone Number</th>
                                <th>Email Address</th>
                                <th>Last Visit Date</th>
                                <th>--</th>
                            </tr>
                            </thead>
                            { this.props.dependantList.length === 0 ?
                                (
                                    <tbody>
                                    <tr><td>No Dependant Found</td></tr>
                                    </tbody>
                                )
                                :
                                (
                                    <tbody>
                                    {this.props.dependantList.map((dependant) => {
                                        count++;

                                        return (
                                            <tr key={dependant._id}>
                                                <td>{count}</td>
                                                <td>{dependant.firstName} {dependant.middleName} {dependant.surname}</td>
                                                <td>
                                                    {dependant.patientSerial}
                                                </td>
                                                <td>{dependant.phoneNumber}</td>
                                                <td>{dependant.emailAddress}</td>
                                                <td>{this.props.formatDate(dependant.lastVisit)}</td>
                                                <td>
                                                    {
                                                        !this.props.isFront && <button className="btn btn-danger btn-sm" onClick={() => this.DeleteDependant(dependant)}><i className="fa fa-trash"></i></button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                )
                            }
                        </table>
                }

            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};



const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientDependantList);
