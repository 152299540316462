import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    setpersonDetails
} from "../../../actions/setpersondetailsaction";
import { serverLink, hospitalCode } from "../../../resources/url";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import "./doctorlist.css";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";

class DoctorList extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
        this.state = {
            serverLink: serverLink+"doctor/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 50,

            isLoadMoredata: false,

            totalNumOfDoctor: null,
            noMoreDataText: "",

            openErrorDialog: false,

            doctorlist: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedDoctorName: "",
            selectedDoctorId: "",
        };
    }
    componentDidMount() {
        this.onSetTotalNumOfDoctors();
        this.fetchDoctor(this.state.limit);
       
    }

    async fetchDoctor(limit) {
        this.setState({ isLoadMoredata: true });
        await fetch(`${this.state.serverLink}limit/${this.state.hospitalCode}/${limit}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    doctorlist: data,
                    isLoadMoredata: false,
                    isLoading: false,
                });

            });
    }

    showMoreDoctor = () => {
        if (this.state.limit <= this.state.totalNumOfDoctor) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchDoctor(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Doctors",
            });
        }
    };

    async onSetTotalNumOfDoctors() {
        this.setState({ isLoading: true });
        await fetch(`${this.state.serverLink}count/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({ totalNumOfDoctor: data, isLoading: false });
            });
    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");
            await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {

                        this.setState({
                            doctorlist: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });
        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="doctorlistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="doctorlistpage">
                <div className="main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Doctor record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Doctor" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search doctor by first, middle, surname, email, phone number or doctor ID"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"/>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.doctorlist.length}{" "}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Name</th>
                            <th scope="col">Sex</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Status</th>
                            
                            
                            <th scope="col">Option</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "/>
                        ) : this.state.doctorlist.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Doctor Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            

                            <tbody className="tablebody">
                            {this.state.doctorlist &&
                            this.state.doctorlist.map((p) => {
                                count++;

                                let dateOfBirthDate = new Date(p.dateOfBirth);
                                const dob = `${dateOfBirthDate.getDate()}/${
                                    dateOfBirthDate.getMonth() + 1
                                }/${dateOfBirthDate.getFullYear()}`;

                                return (

                                    <tr key={p._id}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {p.title}
                                        </td>
                                        <td className="align-middle">
                                            {p.firstName + " "+ p.middleName + " " + p.surname}
                                        </td>
                                        <td className="align-middle">{p.gender}</td>
                                        <td className="align-middle">
                                            {" "}
                                            {p.phoneNumber === "" ? "N/A" : p.phoneNumber}
                                        </td>
                                        {<td className="align-middle">
                                            {" "}
                                            {p.emailAddress === "" ? "N/A" : p.emailAddress}
                                        </td>}
                                        <td className="align-middle">
                                            {p.role === "" ? "N/A" : p.role}
                                        </td>
                                        <td className="align-middle">
                                            {p.status === "" ? "N/A" : p.status}
                                        </td>
                                        
                                        <td className="align-middle">
                                            <Link to="profile">
                                                <button
                                                    onClick={async () => {
                                                        const sendData = {
                                                            ...p,
                                                            personId: p._id,
                                                        };
                                                        this.props.setOnDoctorDetails(sendData);
                                                    }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    <i className="fa fa-edit" aria-hidden="true"/>
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>

                    <div className="loadmoredatasection">
                        {this.state.isLoadMoredata ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"/>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.doctorlist.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMoreDoctor()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    
    return {
        setOnDoctorDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorList);
