import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import UserForm from "../userform/userform";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";

class AddSystemUser extends Component {
    constructor(props) {
        super(props);
        const today = new Date();
        const dateOfBirth = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();

        this.state = {
            serverLink: serverLink+"user/",
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                userID: "",
                title: "",
                firstName: "",
                middleName: "",
                surname: "",
                gender: "",
                phoneNumber: "",
                emailAddress: "",
                dateOfFirstAppointment: null,
                dateOfRenewedContract: null,
                employmentStatus: "",
                role: "",
                specialisation: [],
                password: "",
                status: "",
                administratorFolderNumber: "",
                administratorFolderLocation: "",
                dateOfLastLogin: null,
                dateOfFirstLogin: null,
                submittedBy: ""
            },
            departmentList: "",
            specialisationList: "",

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.state.submittedBy = localStorage.getItem('userID');
        this.onGetDepartments();
        this.onGetSpecialisation();
    }

    async onGetDepartments () {
        await axios.get(`${serverLink}department/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(department => {
                if(department.data.length < 1) {
                    this.setState({
                        departmentList: ""
                    })

                } else {
                    this.setState({
                        departmentList: department.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    departmentList: ""
                })
            })
    };
    async onGetSpecialisation () {
        await axios.get(`${serverLink}specialisation/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(spec => {
                if(spec.data.length < 1) {
                    this.setState({
                        specialisationList: ""
                    })

                } else {
                    let specialisation = [];
                    spec.data.map((sp) => {
                        specialisation.push({value: sp.specialisationName, label: sp.specialisationName})
                    })
                    this.setState({
                        specialisationList: specialisation
                    })
                }
            })
            .catch(err => {
                this.setState({
                    specialisationList: ""
                })
            })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        // this.setState({
        //     isLoading: true,
        // });
        this.onAddUser();
    };

    async onAddUser() {
        this.setState({
            isLoading: true,
        });
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy,
        };

        if(this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff Title"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.userID === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff ID"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.firstName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff First Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.surname === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff Surname"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.gender === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Gender"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.phoneNumber === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff Phone Number"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.dateOfFirstAppointment === "" || this.state.formData.dateOfFirstAppointment === null) {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Date of First Appointment"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.employmentStatus === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Employment Status"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.role === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Role"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.specialisation.length === 0) {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select at lease one specialisation. or N/A if none"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.status === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Status"
            this.handleErrorDialog();
            return false;
        }

        axios.post(`${this.state.serverLink}add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "user exist") {
                    this.state.messageDescription = "A staff with the same staff ID, email address or phone number already exist";
                    this.handleErrorDialog();
                } else if (response === "error") {
                    this.state.messageDescription = "Something went wrong adding the staff. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.state.messageDescription = "Staff Added Successfully";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to register staff. Please try again!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    handleDateOfFirstAppointmentChange = (date) => {
        if (date !== null) {
            const dateOfFirstAppointment = new Date(date);

            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfFirstAppointment: `${dateOfFirstAppointment.getFullYear()}-${dateOfFirstAppointment.getMonth() + 1}-${dateOfFirstAppointment.getDate()}`,
                },

                startDate: date,
            });
        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfFirstAppointment: date,
                },

                startDate: date,
            });
        }
    };

    handleDateOfRenewedContractChange = (date) => {
        if (date !== null) {
            const dateOfRenewedContract = new Date(date);

            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfRenewedContract: `${dateOfRenewedContract.getFullYear()}-${dateOfRenewedContract.getMonth() + 1}-${dateOfRenewedContract.getDate()}`,
                },

                startDate: date,
            });
        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfRenewedContract: date,
                },

                startDate: date,
            });
        }
    };


    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    onSpecialisationChange = (e) => {
        const formData = this.state.formData;
        let data = [];
        e.map((spec) => {
            data.push(spec.value)
        })
        this.setState({
            formData: { ...formData, specialisation: data }
        });
    }

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/staff/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="adduserpage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="adduserpage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="User Added"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <UserForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Add System User"
                                dateOfFirstAppointment={this.state.formData.dateOfFirstAppointment}
                                dateOfRenewedContract={this.state.formData.dateOfRenewedContract}
                                handleDateOfFirstAppointmentChange={this.handleDateOfFirstAppointmentChange}
                                handleDateOfRenewedContractChange={this.handleDateOfRenewedContractChange}
                                departmentList={this.state.departmentList}
                                specialisationList={this.state.specialisationList}
                                onSpecialisationChange={this.onSpecialisationChange}
                            ></UserForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AddSystemUser);

