import React, {Component} from "react";

class DashboardCard extends Component {
    render() {
        return <div className="box" style={{ backgroundColor: "#11283f" }}>
            {" "}
            <div className="box_containt">
                <h1
                    style={{
                        fontWeight: "700",
                        color: "#ffffff",
                        fontSize: "30px",
                    }}
                >
                    {this.props.count}
                </h1>
                <span style={{ fontWeight: "700", color: "#ffffff", }} >  {this.props.name} </span>
            </div>
            <i className={this.props.icon} style={{ color: "#ffffff" }} aria-hidden="true"/>
        </div>

}}
export default DashboardCard;
