import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { setpersonDetails } from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import {connect} from "react-redux";
import UserForm from "../userform/userform";
import { serverLink, hospitalCode } from "../../../resources/url";

class UpdateSystemUser extends Component {
    constructor(props) {
        super(props);

        let specialisation = [];
        this.props.userDetails.specialisation.map((spec) => {
            specialisation.push({value: spec, label: spec})
        });

        this.state = {
            serverLink: serverLink+"user/",
            hospitalCode: hospitalCode,
            date: null,
            isLoading: false,

            userEntryID: this.props.userDetails._id,
            specialisationData: specialisation,

            formData: {
                userID: this.props.userDetails.userID,
                title: this.props.userDetails.title,
                firstName: this.props.userDetails.firstName,
                middleName: this.props.userDetails.middleName,
                surname: this.props.userDetails.surname,
                gender: this.props.userDetails.gender,
                phoneNumber: this.props.userDetails.phoneNumber,
                emailAddress: this.props.userDetails.emailAddress,
                dateOfFirstAppointment: this.props.userDetails.dateOfFirstAppointment,
                dateOfRenewedContract: this.props.userDetails.dateOfRenewedContract,
                employmentStatus: this.props.userDetails.employmentStatus,
                role: this.props.userDetails.role,
                specialisation: specialisation,
                status: this.props.userDetails.status,
                administratorFolderNumber: this.props.userDetails.administratorFolderNumber,
                administratorFolderLocation: this.props.userDetails.administratorFolderLocation
            },
            departmentList: "",
            specialisationList: "",

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.onGetDepartments();
        this.onGetSpecialisation();
    }

    async onGetDepartments () {
        await axios.get(`${serverLink}department/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(department => {
                if(department.data.length < 1) {
                    this.setState({
                        departmentList: ""
                    })

                } else {
                    this.setState({
                        departmentList: department.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    departmentList: ""
                })
            })
    };
    async onGetSpecialisation () {
        await axios.get(`${serverLink}specialisation/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(spec => {
                if(spec.data.length < 1) {
                    this.setState({
                        specialisationList: ""
                    })

                } else {
                    let specialisation = [];
                    spec.data.map((sp) => {
                        specialisation.push({value: sp.specialisationName, label: sp.specialisationName})
                    })
                    this.setState({
                        specialisationList: specialisation
                    })
                }
            })
            .catch(err => {
                this.setState({
                    specialisationList: ""
                })
            })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateUser();
    };



    async onUpdateUser() {

        if(this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff Title"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.userID === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff ID"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.firstName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff First Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.surname === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff Surname"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.gender === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Gender"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.phoneNumber === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Staff Phone Number"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.dateOfFirstAppointment === "" || this.state.formData.dateOfFirstAppointment === null) {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Date of First Appointment"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.employmentStatus === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Employment Status"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.role === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Role"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.specialisation.length === 0) {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select at lease one specialisation. or N/A if none"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.status === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Staff Status"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.specialisationData === this.state.formData.specialisation) {
            let specialisation_array = [];
            this.state.specialisationData.map((spec) => {
                specialisation_array.push(spec.value)
            });
            this.state.formData.specialisation = specialisation_array;
        }
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            id: this.state.userEntryID
        };


        axios.patch(`${this.state.serverLink}update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "Staff record updated successfully";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = response;
                    this.handleErrorDialog();
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = err;
                this.handleErrorDialog();
            });
    }

    handleDateOfFirstAppointmentChange = (date) => {
        if (date !== null) {
            const dateOfFirstAppointment = new Date(date);

            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfFirstAppointment: `
                    ${dateOfFirstAppointment.getFullYear()}-
                    ${dateOfFirstAppointment.getMonth() + 1}-
                    ${dateOfFirstAppointment.getDate()}
                    `,
                },

                startDate: date,
            });
        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfFirstAppointment: date,
                },

                startDate: date,
            });
        }
    };

    handleDateOfRenewedContractChange = (date) => {
        if (date !== null) {
            const dateOfRenewedContract = new Date(date);

            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfRenewedContract: `
                    ${dateOfRenewedContract.getFullYear()}-
                    ${dateOfRenewedContract.getMonth() + 1}-
                    ${dateOfRenewedContract.getDate()}
                    `,
                },

                startDate: date,
            });
        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    dateOfRenewedContract: date,
                },

                startDate: date,
            });
        }
    };


    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    onSpecialisationChange = (e) => {
        const formData = this.state.formData;
        let data = [];
        e.map((spec) => {
            data.push(spec.value)
        })
        this.setState({
            formData: { ...formData, specialisation: data }
        });
    }

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/staff/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="addpersonpage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="addpersonpage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="User Updated"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <UserForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Patient"
                                dateOfFirstAppointment={this.state.formData.dateOfFirstAppointment}
                                dateOfRenewedContract={this.state.formData.dateOfRenewedContract}
                                handleDateOfFirstAppointmentChange={this.handleDateOfFirstAppointmentChange}
                                handleDateOfRenewedContractChange={this.handleDateOfRenewedContractChange}
                                departmentList={this.state.departmentList}
                                specialisationList={this.state.specialisationList}
                                onSpecialisationChange={this.onSpecialisationChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.personDetails,
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnpersonDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDisptachToProps)(UpdateSystemUser);
