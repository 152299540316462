import React, { Component } from "react";
import "./bedlist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import {setpersonDetails} from "../../../../../actions/setpersondetailsaction";
import Navigator from "../../../navigation/navigation";

class BedList extends Component {
    constructor(props) {
        super(props);

        let page_url = window.location.pathname;
        let page_id = page_url.split('/');
        let room = page_id[5];

        this.state = {
            serverLink: serverLink+"hospital_bed/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 20,

            isLoadMoreData: false,
            noMoreDataText: "",

            bedList: [],
            isSearching: false,
            isSearchDataShow: false,

            roomID: room,

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchBeds();
    }

    async fetchBeds() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}room/beds/all/${this.state.roomID}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    bedList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase();

            await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            bedList: data.filter((e) => e.roomName === this.state.roomName),
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };


    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Beds" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search bed by name"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.bedList.length}{" "}
                                </li>
                            </ul>
                        </div>

                        <Link to="/hospital/room/bed/add">
                            <button
                                type="button"
                                className="btn btn-warning"
                            >
                                + Add Bed
                            </button>
                        </Link>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Bed Name</th>
                            <th scope="col" className=" text-center ">Occupant</th>
                            <th scope="col">Bed Status</th>
                            <th scope="col">Option</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "></i>
                        ) : this.state.bedList.length === 0 ? (
                            <tbody>
                            <tr>
                                <td colSpan={7} className="text-center">No Room Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.bedList &&
                            this.state.bedList.map((p) => {
                                let status = p.status === 'available' ? 'text-success align-middle text-capitalize' :  p.status === 'under maintenance' ? 'text-warning align-middle text-capitalize' : 'text-danger align-middle text-capitalize';
                                count++;
                                return (
                                    <tr key={p._id}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {p.bedName}
                                        </td>

                                        <td className="align-middle text-center">
                                            {p.occupant.length === 0 ? "N/A"
                                                :
                                                <Link to={"/admission/profile/"+p.occupant[0].admissionID}>
                                               <button className="btn btn-primary w-50 h-25">{p.occupant[0].patientName} <br/>{p.occupant[0].patientSerial}</button>
                                                </Link>
                                            }
                                        </td>
                                        <td className={status}>
                                            {p.status}
                                        </td>
                                        <td className="align-middle">
                                            <Link to="/hospital/room/bed/update">
                                                <button
                                                    onClick={async () => {
                                                        const bedData = {
                                                            ...p,
                                                            bedId: p._id,
                                                        };
                                                        this.props.setOnPatientDetails(bedData);
                                                    }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>

                </div>
            </div>
        );
    }
}





const mapDisptachToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(BedList);
