import React, {Component} from "react";
import {Link} from "react-router-dom";
import { serverLink, hospitalCode } from "../../../../resources/url";

class RoomListTable extends Component {
    constructor() {
        super();
        this.state = {
            serverLink: serverLink+"hospital_room/",
        }
    }

    render() {
        let count = 0;
        return <table className="table table-striped">
            <thead className="thead tablehead">
            <tr>
                <th scope="col">#</th>
                <th scope="col">Room Name</th>
                <th scope="col">Extension No</th>
                <th scope="col">Room Type</th>
                <th scope="col">Room Status</th>
                <th scope="col">Block Name</th>
                <th scope="col">Bed Capacity</th>
                <th scope="col">Bed Occupied</th>
                <th scope="col">Option</th>
            </tr>
            </thead>
            {this.props.isSearching ? (
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            ) : this.props.roomData.length === 0 ? (
                <tbody>
                <tr>
                    <td>No Room Found</td>
                </tr>
                </tbody>
            ) : (
                <tbody className="tablebody">
                {this.props.roomData &&
                this.props.roomData.map((room) => {
                    count++;
                    return (
                        <tr key={room._id}>
                            <td className="align-middle">{count}</td>
                            <td className="align-middle">
                                {room.roomName}
                            </td>
                            <td className="align-middle">
                                {room.extensionNo}
                            </td>
                            <td className="align-middle">{room.typeName}</td>
                            <td className="align-middle">{room.status}</td>
                            <td className="align-middle">
                                {room.blockName === "" ? "N/A" : room.blockName}
                            </td>
                            <td className="align-middle">
                                {" "}
                                {room.bedCapacity === "" ? "N/A" : room.bedCapacity}
                            </td>
                            <td className="align-middle">
                                {" "}
                                {room.bedOccupied === "" ? "N/A" : room.bedOccupied}
                            </td>
                            <td className="align-middle">
                                <Link to={"/inpatient/room/details/"+room._id}>
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                    >
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                    </button>
                                </Link>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            )}
        </table>
    }
}

export default RoomListTable;
