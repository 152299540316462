import React, { Component } from "react";
import "./doctorlist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import { serverLink, hospitalCode } from "../../../../resources/url";
import Navigator from "../../../Settings/navigation/navigation";

class ScheduleDoctorList extends Component {
    constructor() {
        super();
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 50,

            isLoadMoreData: false,

            totalNumOfDoctors: null,
            noMoreDataText: "",

            openErrorDialog: false,

            doctorList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedDoctorName: "",
            selectedDoctorId: "",
        };
    }
    componentDidMount() {
        this.onSetTotalNumOfDoctors();
        this.fetchDoctors(this.state.limit);
    }

    async fetchDoctors(limit) {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}doctor/limit/${this.state.hospitalCode}/${limit}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    doctorList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            })
            .catch(err => {
                this.setState({
                    doctorList: null,
                    isLoadMoreData: false,
                    isLoading: false,
                })
            })
    }

    showMoreDoctors = () => {
        if (this.state.limit <= this.state.totalNumOfDoctors) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchDoctors(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Doctors",
            });
        }
    };

    async onSetTotalNumOfDoctors() {
        this.setState({ isLoading: true });

        await fetch(`${this.state.serverLink}doctor/count/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({ totalNumOfDoctors: data, isLoading: false });
            });
    }

    handleSearch = async () => {
        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");

            await fetch(`${this.state.serverLink}doctor/search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            doctorList: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Doctor record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Doctor's Consultation Scheduler" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search doctor by first, middle, surname, email, phone number, ID or specialisation"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.doctorList.length}{" "}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Sex</th>
                            <th scope="col">Role</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email</th>
                            <th scope="col">Specialisation</th>
                            <th scope="col">Option</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "></i>
                        ) : this.state.doctorList.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Doctor Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.doctorList &&
                            this.state.doctorList.map((p) => {
                                count++;

                                return (
                                    <tr key={p._id}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {p.userID}
                                        </td>
                                        <td className="align-middle">
                                            {p.firstName + " "+ p.middleName + " " + p.surname}
                                        </td>
                                        <td className="align-middle">{p.gender}</td>
                                        <td className="align-middle">{p.role}</td>
                                        <td className="align-middle">
                                            {" "}
                                            {p.phoneNumber === "" ? "N/A" : p.phoneNumber}
                                        </td>
                                        {<td className="align-middle">
                                            {" "}
                                            {p.emailAddress === "" ? "N/A" : p.emailAddress}
                                        </td>}
                                        <td className="align-middle">
                                            {
                                                p.specialisation.length < 1 ? "N/A" :
                                                    p.specialisation.map((spec, index) => {
                                                        return(<div key={index}><span className="badge badge-primary">{spec}</span><br/></div>)
                                                    })
                                            }
                                        </td>
                                        <td className="align-middle">
                                            <Link to="/schedule/consultation/doctor/profile">
                                                <button
                                                    onClick={async () => {
                                                        const sendData = {
                                                            ...p
                                                        };
                                                        this.props.setOnUserDetails(sendData);
                                                    }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    <i className="far fa-calendar-plus"></i>
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>

                    <div className="loadmoredatasection">
                        {this.state.isLoadMoreData ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.doctorList.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMoreDoctors()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnUserDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDoctorList);
