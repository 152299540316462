import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./labdashboard.css";
import { connect } from "react-redux";
import { loginValidation } from "../../../context/loginvalidation";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";
import MenuIcon from "../../Dashboard/utils/menu-icon";
import {FaBan, FaCheck, FaDownload, FaUsers, FaVials} from "react-icons/fa";
import {FaCodePullRequest, FaFlaskVial, FaX} from "react-icons/fa6";
import ApexChart from "react-apexcharts";

const LabDashboard = ({ permissionData, doctorDetails }) => {

    const [loading, setLoading] = useState(true);
    const [apexSeries, setApexSeries] = useState([]);
    const [apexChartOption, setApexChartOption] = useState({});
    const [labs, setLabs] = useState(0);
    const [requested, setRequested] = useState(0);
    const [specimenTaken, setSpecimenTaken] = useState(0);
    const [resultConfirm, setResultConfirm] = useState(0);
    const [resultEntered, setResultEntered] = useState(0);
    const [resultRejected, setResultRejected] = useState(0);
    const [cancelled, setCancelled] = useState(0);

    useEffect(() => {
        if (loginValidation(window.location.pathname, permissionData) !== 'permitted') {
            window.location.href = '/';
        }
        fetchDetails();
    }, []);

    const fetchDetails = async () => {
        try {
            const dashboard_response = await axios.get(`${serverLink}report/lab/dashboard/${hospitalCode}`, doctorDetails.token);
            const labRequest = dashboard_response.data.labRequest;
            const lab = dashboard_response.data.lab;
            let lab_status = ['Requested', 'Specimen Taken', 'Result Confirmed', 'Result Entered', 'Result Rejected', 'Cancelled'];

            //Set Count
            setRequested(labRequest.filter(e => e.testStatus === 'requested').length)
            setSpecimenTaken(labRequest.filter(e => e.testStatus === 'specimen taken').length)
            setResultConfirm(labRequest.filter(e => e.testStatus === 'result confirmed').length)
            setResultEntered(labRequest.filter(e => e.testStatus === 'result entered').length)
            setResultRejected(labRequest.filter(e => e.testStatus === 'result rejected').length)
            setCancelled(labRequest.filter(e => e.testStatus === 'cancelled').length)
            setLabs(lab.length)

            let statusCountData =  [];
            let statusData =  [];


            lab_status.map((e, i) => {
                let count = labRequest.filter(item => item.testStatus.toLowerCase()  === e.toLowerCase()).length
                statusCountData.push(count)
                statusData.push(e)
            })

            //Lab Request trend by gender
            setApexChartOption({
                ...apexChartOption,
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: statusData
                }
            })
            setApexSeries([{
                name: "Count",
                data: statusCountData
            }])

            setLoading(false)
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    }


    return (loading ? (
                <div className="dashboardpage">
                    <i className="fas fa-spinner fa-pulse fa-2x "/>
                </div>
            ) :
        <div className="dashboardpage">
            <div className="topheader">
                <ul>
                    <li>
                        <i
                            className="fa fa-flask fa-2x"
                            aria-hidden="true"
                        />
                    </li>
                    <li>
                        <span>Laboratory Dashboard</span>
                    </li>
                </ul>
            </div>

            <div className="first_section">
                <div className="row">
                    <div className="col-md-3">
                        <MenuIcon name="Laboratories" icon={<FaFlaskVial size={40}/>} count={labs}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Requested" icon={<FaCodePullRequest size={40}/>} count={requested}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Specimen Taken" icon={<FaVials size={40}/>} count={specimenTaken}/>
                    </div>
                    <div className="col-md-3">
                        <MenuIcon name="Result Confirmed" icon={<FaCheck size={40}/>} count={resultConfirm}/>
                    </div>
                    <div className="col-md-4">
                        <MenuIcon name="Result Entered" icon={<FaDownload size={40}/>} count={resultEntered}/>
                    </div>
                    <div className="col-md-4">
                        <MenuIcon name="Result Rejected" icon={<FaBan size={40}/>} count={resultRejected}/>
                    </div>
                    <div className="col-md-4">
                        <MenuIcon name="Result Cancelled" icon={<FaX size={40}/>} count={cancelled}/>
                    </div>

                    <div className="col-md-12 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                <h6><b>Lab Request Trend By Status</b></h6>
                                {

                                    <ApexChart
                                        options={apexChartOption}
                                        series={apexSeries}
                                        type="bar"
                                        height={300}
                                        width="100%"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="col-md-12 mt-3 mb-0" style={{borderBottom: '1px solid #CCCCCC', borderTop: '1px solid #CCCCCC', padding: '5px'}}>
                            <h2><b>Menu</b></h2>
                        </div>
                    </div>

                    {permissionData &&
                        permissionData.map((perm, index) => {
                            if (perm.mainMenuName === 'Laboratory' && perm.mainMenuLink === null) {
                                return (
                                    <div key={index} className="col-sm-4">
                                        {" "}
                                        <Link to={perm.subMenuLink}>
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(LabDashboard);
