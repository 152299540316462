import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    setpersonDetails
} from "../../../../../actions/setpersondetailsaction";
import { hospitalCode, serverLink } from "../../../../../resources/url";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import PlanTable from "./plantable";
import Navigator from "../../../../Settings/navigation/navigation";


class PlansList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"insurance/plan/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 50,

            isLoadMoredata: false,

            totalNumOfPlans: null,
            noMoreDataText: "",

            openErrorDialog: false,

            planList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedPlan: "",
            selectedPlanId: "",
        };
    }
    componentDidMount() {
        this.onSetTotalNumOfPlans();
        this.fetchPlans()
    }

   async fetchPlans() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    planList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    showMorePlan = () => {
       if (this.state.limit <= this.state.totalNumOfPlans) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchPackages();
        } else {
            this.setState({
                noMoreDataText: "No More Plans",
            });
        }
    };

    async onSetTotalNumOfPlans() {
        this.setState({ isLoading: true });

        await fetch(`${this.state.serverLink}count/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({ totalNumOfPlans: data, isLoading: false });
            });


    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");

          await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            planlist: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Plan record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Insurance Plans" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search plan by plan name"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.planList.length}{" "}
                                </li>
                            </ul>
                        </div>

                         <Link to="/hospital/insurance/plans/add">
                            <button
                                type="button"
                                className="btn btn-warning"
                            >
                                + Add Plan
                            </button>
                        </Link>


                    
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Insurance Company</th>
                            <th scope="col">Plan</th>
                            <th scope="col">Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Specialist</th>
                            <th scope="col">Update Plan</th>

                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "></i>
                        ) : this.state.planList.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Plan Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.planList &&
                            this.state.planList.map((p, k) => {
                                count++;


                                return (

                                    <tr key={k}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {p.companyName}
                                        </td>
                                        <td className="align-middle">
                                            {p.planName}
                                        </td>
                                        <td className="align-middle">
                                            {p.planType}
                                        </td>
                                        <td className="align-middle">
                                            {p.amount}
                                        </td>
                                        <td className="align-middle">
                                            {p.duration}
                                        </td>
                                        <td className="align-middle">
                                            {" "}
                                            {p.specialistType.map((items, key) => <div key={key} className="badge badge-info mr-1"> { items } </div> )}
                                        </td>

                                        <td className="align-middle">
                                            <Link to="/hospital/insurance/plans/update">
                                                <button
                                                    onClick={async () => {
                                                        const sendData = {
                                                            ...p,
                                                            personId: p._id,
                                                        };
                                                        this.props.setOnPatientDetails(sendData);
                                                    }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >

                                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                                </button>
                                            </Link>

                                        </td>

                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>
                    <div className="loadmoredatasection">
                        {this.state.isLoadMoredata ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.planList.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMorePlan()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};
export default connect(null, mapDispatchToProps)(PlansList);
