import React, { Component } from "react";
import "./addmenuform.css";
import Navigator from "../../../navigation/navigation";

class AddMenuForm extends Component {
    render() {
        return (
            <div className="baseformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="laboratoryName">Menu Name <span className="text-danger">*</span></label>
                                            <input
                                                name="menuName"
                                                type="text"
                                                className="form-control"
                                                id="menuName"
                                                value={this.props.data.menuName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="menuLink">Menu Link <span className="text-danger">*</span></label>
                                            <input
                                                name="menuLink"
                                                type="text"
                                                className="form-control"
                                                id="menuLink"
                                                value={this.props.data.menuLink}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="menuIcon">Menu Icon <span className="text-danger">*</span></label>
                                            <input
                                                name="menuIcon"
                                                type="text"
                                                className="form-control"
                                                id="menuIcon"
                                                value={this.props.data.menuIcon}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>


                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddMenuForm;
