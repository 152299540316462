import React, { Component } from "react";
import "./roomlist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails
} from "../../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import axios from "axios";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import Navigator from "../../../navigation/navigation";

class RoomList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"hospital_room/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 20,

            isLoadMoreData: false,
            totalNumOfRooms: null,
            noMoreDataText: "",

            roomList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedRoomName: "",
            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false,
            updatedBy: "",
        };
    }
    componentDidMount() {
        this.fetchRooms();
        this.setState({
            updatedBy: localStorage.getItem("userID")
        });
    }

    async fetchRooms() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    roomList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    showMoreRooms = () => {
        if (this.state.limit <= this.state.totalNumOfPatient) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchRooms(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Record",
            });
        }
    };

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase();

            await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            roomList: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    handleSubmit = (e) => {
        this.setState({
            isLoading: true,
        });
        this.onUpdateStatus(e);
    };

    async onUpdateStatus(e) {
        const sendData = {
            id: e._id,
            status: e.status === "available" ? "under maintenance" : "available",
            updatedBy: this.state.updatedBy
        };


        axios.patch(`${this.state.serverLink}status/update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "ROOM STATUS UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }


    moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        this.fetchRooms();
        // window.location.href = '/hospital/room/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };


    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };


    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Room Status Updated"
                        des={this.state.messageDescription}
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Room record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Room" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search room by name"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"/>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.roomList.length}{" "}
                                </li>
                            </ul>
                        </div>

                        <Link to="/hospital/room/add">
                            <button
                                type="button"
                                className="btn btn-warning"
                            >
                                + Add Room
                            </button>
                        </Link>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col"> Name</th>
                            <th scope="col"> Type</th>
                            <th scope="col"> Amount</th>
                            <th scope="col">Block </th>
                            <th scope="col"> Capacity</th>
                            <th scope="col"> Available Beds</th>
                            <th scope="col"> Manager</th>
                            <th scope="col">Status</th>
                            <th scope="col">Change Status</th>
                            <th scope="col"> Beds</th>
                            <th scope="col">Option</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "/>
                        ) : this.state.roomList.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Room Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.roomList &&
                            this.state.roomList.map((p) => {
                                let status = p.status === 'available' ? 'text-success align-middle text-capitalize' :  'text-danger align-middle text-capitalize';
                                let icon = p.status !== 'available' ? 'fa fa-ban fa-2x' :  'fa fa-check-circle fa-2x';
                                let btn = p.status !== 'available' ? 'btn btn-danger' :  'btn btn-success';
                                count++;
                                return (
                                    <tr key={p._id}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {p.roomName}
                                        </td>
                                        <td className="align-middle">
                                            {p.typeName}
                                        </td>
                                        <td className="align-middle">
                                            {this.moneyFormat(p.roomAmount)}
                                        </td>
                                        <td className="align-middle">
                                            {p.blockName}
                                        </td>
                                        <td className="align-middle">
                                            {p.bedCapacity === "" ? "N/A" : p.bedCapacity }
                                        </td>

                                        <td className="align-middle">
                                            {p.bedCapacity - p.bedOccupied }
                                        </td>
                                        <td className="align-middle">
                                            {p.roomManager === "" ? "N/A" : p.roomManager }
                                        </td>
                                        <td className={status}>
                                            {p.status === "" ? "N/A" : p.status }
                                        </td>
                                        <td className="align-middle">
                                            <button
                                                onClick={() => this.handleSubmit(p)}
                                                type="button"
                                                className={btn}
                                            >
                                                <i className={icon} aria-hidden="true"/>
                                            </button>
                                        </td>
                                        <td className="align-middle">
                                            <Link to={"/hospital/room/bed/list/"+p._id}>
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    <i className="fa fa-bed" aria-hidden="true"></i>
                                                </button>
                                            </Link>
                                        </td>

                                        <td className="align-middle">
                                            <Link to="/hospital/room/update">
                                                <button
                                                    onClick={async () => {
                                                        const roomData = {
                                                            ...p,
                                                            roomId: p._id,
                                                        };
                                                        this.props.setOnPatientDetails(roomData);
                                                    }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                                </button>
                                            </Link>
                                        </td>

                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>

                    <div className="loadmoredatasection">
                        {this.state.isLoadMoreData ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.roomList.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMoreRooms()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};



export default connect(mapStateToProps, mapDisptachToProps)(RoomList);
