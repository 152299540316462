import React, {useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";

function PatientImagingRequest(props) {
    const [labList, setLabList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [labImaging, setLabImaging] = useState([]);
    const [imagePrice, setImagePrice] = useState(0);
    const [imageID, setImageID] = useState("");
    const [selectedLab, setSelectedLab] = useState("");
    const [selectedImage, setSelectedImage] = useState("");
    const [actionState, setActionState] = useState("");
    const [message, setMessage] = useState("");
    const [isLoadingLab, setLoadingLab] = useState(true)
    const [isLoadingImaging, setLoadingImaging] = useState(true)

    const moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    const getLabList = () => {
        axios.get(`${serverLink}imaging/lab/list/${hospitalCode}`, props.doctorDetails.token)
            .then(result => {
                const response = result.data;
                if (response.length > 0 && isLoadingLab) {
                    setLabList(response);
                    setLoadingLab(false)
                }

            })
            .catch(error => {
                console.log("LAB LIST NETWORK ERROR", error)
            })
    }
    getLabList();

    const getImageList = () => {
        axios.get(`${serverLink}imaging/imaging/list/${hospitalCode}`, props.doctorDetails.token)
            .then(result => {
                const response = result.data;
                if (response.length > 0 && isLoadingImaging) {
                    setImageList(response);
                    setLoadingImaging(false)
                }

            })
            .catch(error => {
                console.log("LAB LIST NETWORK ERROR", error)
            })
    }
    getImageList();

    const onEdit = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setMessage("");
        setActionState("");

        if (name === 'LabName') {
            setLabImaging(imageList.filter(item => item.labName === value));
            setSelectedLab(value)
        } else {
            setSelectedImage(value);
            let index = e.target.selectedIndex;
            let optionElement = e.target.childNodes[index]
            const price = optionElement.getAttribute('price');
            const image_id = optionElement.getAttribute('image_id');
            setImagePrice(parseFloat(price))
            setImageID(image_id);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        setActionState("");

        if (selectedLab === "") {
            setMessage("Lab is required");
            setActionState("danger");
            return false;
        }

        if (selectedImage === "") {
            setMessage("Imaging is required");
            setActionState("danger");
            return false;
        }

        setMessage("Processing... Please wait!");
        setActionState("info");

        const sendData = {
            labName: selectedLab,
            imageName: selectedImage,
            imageID: imageID,
            price: imagePrice,
            patientSerial: props.patientSerial,
            patientName: props.patientName,
            doctorID: props.doctorDetails.userID,
            doctorName: props.doctorDetails.fullName,
            requestType: props.requestType,
            requestID: props.requestID,
            hospitalCode: hospitalCode,
            submittedBy: props.doctorDetails.userID
        }
        console.log(sendData)
        axios.post(`${serverLink}imaging/request/add`, sendData, props.doctorDetails.token)
            .then(response => {
                const message = response.data.message;
                if (message === 'success') {
                    setMessage("Request Successful");
                    setActionState("success");
                }
                else if (message === 'exist') {
                    setMessage("Patient has a pending imaging request");
                    setActionState("warning");
                }
                else {
                    setMessage("Something went wrong! Please try again.");
                    setActionState("danger");
                }
            })
            .catch()
    }

    return <>
        <form onSubmit={handleSubmit}>
            {
                actionState !== '' &&
                <div className={`alert alert-${actionState}`}>{message}</div>
            }

            {
                imagePrice !== 0 &&
                <div className="alert alert-success">PRICE: {moneyFormat(imagePrice)}</div>
            }
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Lab Name</label>
                        <select name="LabName" className="custom-select" value={selectedLab} onChange={onEdit}>
                            <option value="">Select Lab</option>
                            {
                                labList.length > 0 &&
                                labList.map((lab, index) => {
                                    return <option key={index} data={lab._id} value={lab.labName}>{lab.labName}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">Imaging Name</label>
                        <select name="ImageName" className="custom-select" value={selectedImage} onChange={onEdit}>
                            <option value="" price={0}  image_id={0}>Select Image</option>
                            {
                                labImaging.length > 0 &&
                                labImaging.map((image, index) => {
                                    return <option key={index} data={image._id} price={image.price} image_id={image._id} value={image.imageName}>{image.imageName}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>

            <button type="submit" className="btn btn-primary btn-block">Request</button>
        </form>
    </>
}


const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(PatientImagingRequest);
