import React, {Component} from "react";
import "./updateinsurancecompany.css";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import InsuranceCompanyForm from "../insurancecompanyform/insurancecompanyform";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import { connect } from "react-redux";


class UpdateInsuranceCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"insurance/company/",
            hospitalCode: hospitalCode,
            isLoading: false,
            imagefile:"",

            formData: {
                id: this.props.insuranceCompanyData._id,
                companyName: this.props.insuranceCompanyData.companyName,
                oldCompanyName: this.props.insuranceCompanyData.companyName,
                emailAddress: this.props.insuranceCompanyData.emailAddress,
                address: this.props.insuranceCompanyData.address,
                phoneNumber: this.props.insuranceCompanyData.phoneNumber,
                logo: this.props.insuranceCompanyData.logo,
                updatedBy: "",
            },

            htmlElement: <i className="fa fa-user fa-8x" aria-hidden="true"></i>,
            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: localStorage.getItem("userID")
            
        });
    
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdate();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdate() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy
        };

        if(this.state.formData.companyName.trim() === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Insurance Company Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.address === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Company Address"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.emailAddress === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Insurance Company Email"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.phoneNumber === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Insurance Company Contact Number"
            this.handleErrorDialog();
            return false;
        }

        if (this.state.imagefile !== "") {
            const formData = new FormData();
            formData.append('logo', this.state.imagefile);

            await axios.post(`${this.state.serverLink}uploadCompanyLogo`, formData, this.props.doctorDetails.token)
                .then(res => { // then print response status
                    if(res.status === 200) {
                        const formData = this.state.formData;
                        this.setState({
                            formData: {
                                ...formData,
                                logo: res.data.logo
                            }
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        isLoading: false,
                    });
                    this.state.messageDescription = "Company Logo is not uploaded. Please try again!"
                    this.handleErrorDialog();
                    return false;
                })
        }



        axios.patch(`${this.state.serverLink}update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "INSURANCE COMPANY UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }

      onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                imagefile: event.target.files[0],
            });

            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    imageAvatar: e.target.result,
                    htmlElement: (
                        <div className="addpersonpage">
                            <img
                                className="netimage"
                                srcSet={e.target.result}
                                alt="profileImage"
                            />
                        </div>
                    ),
                });
            };
            reader.readAsDataURL(event.target.files[0]);
            this.setState({});
        }
    };
    onImageRemove = () => {
        this.setState({
            imagefile: "",
            imageAvatar: "",
            htmlElement: (
                <div className="addpersonpage">
                    <i className="fa fa-user fa-8x" aria-hidden="true"></i>
                </div>
            ),
        });
    };


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/hospital/insurance/company/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Insurance Company Updated"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <InsuranceCompanyForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Insurance Company"
                                htmlElement={this.state.htmlElement}
                                onImageChange={this.onImageChange}
                            ></InsuranceCompanyForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        insuranceCompanyData: state.personDetails,
        doctorDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateInsuranceCompany);
