import React, {Component, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./addpatientimaging.css";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";
import AddPatientImagingForm from "./addpatientimagingform";
import {connect} from "react-redux";
import {toast} from "react-toastify";

class AddPatientImaging extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            date: null,
            imaging: "",
            imageFile: "",
            htmlelement: "",
            isLoading: false,
            patientSerial: this.props.patientData.patientSerial,
            patientFullName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                this.props.patientData.surname,

            formData: {
                resultEnteredBy: this.props.doctorName,
                image_name: '',
                result: "",
                id: this.props.requestID
            },
            fileList: [],
            isSubmitting: false,
            isError: false,
            isSuccess: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        })
        this.onUploadPatientImaging();
    };

    async onUploadPatientImaging() {
        let submit_btn = document.getElementById('SubmissionBtn');
        submit_btn.innerHTML = `Submitting ...`
        submit_btn.setAttribute('disabled', 'disabled');

        if (this.state.imagefile === "") {
            this.setState({
                isError: true,
            });
            submit_btn.innerHTML = `Save`;
            submit_btn.disbled = false;
            return false;
        }


        let sendData = {
            ...this.state.formData
        };

        axios.patch(`${this.state.serverLink}imaging/request/upload/result`, sendData, this.props.doctorDetails.token)
            .then(result => {
                const response = result.data.message;
                submit_btn.innerHTML = `Save`;
                submit_btn.disbled = false;
                if(response === "success") {
                    if (this.state.fileList.length > 0) {
                        const formData = new FormData();
                        this.state.fileList.map(item => {
                            formData.append('image', item.ImagePath);
                            formData.append('entry_id',this.state.formData.id)
                            formData.append('fileList', this.state.fileList)
                        })
                        axios.patch(`${this.state.serverLink}imaging/uploadPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                                setTimeout(()=>{
                                    this.props.onCloseModal();
                                }, 3000)
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.setState({
                        isSuccess: true,
                    });
                }  else if (response === "error"){
                    this.setState({
                        isError: true,
                    });
                } else {
                    this.setState({
                        isError: true,
                    });
                }
            })
            .catch(err => {

                this.setState({
                    isError: true,
                });
                submit_btn.innerHTML = `Save`;
                submit_btn.disbled = false;
            });
    }

    onImageChange = (event) => {
        const formData = this.state.formData;
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "application/pdf" ) {
            } else {
                toast.error("Only .pdf, .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 5000000) {
                toast.error("max file size is 5mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: file,
                    htmlelement: e.target.result,
                    formData: {
                        ...formData,
                        image_name: file.name,
                    }
                });
            };
        }
    };

    onMultipleImageChange = async e => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            let fileData = [];
            for (const file of files) {
                const reader = new FileReader();
                const readFile = () => {
                    return new Promise((resolve, reject) => {
                        reader.onload = function () {
                            const data = {
                                ImagePath: file,
                                ImageName: file.name,
                                ImageDisplay: reader.result,
                                ImageSize: (file.size / 1048576).toFixed(2),
                            };
                            resolve(data);
                        };
                        reader.onerror = reject;
                        reader.readAsDataURL(file);
                    });
                };
                const data = await readFile();
                fileData.push(data);
            }
            this.setState({ fileList: [...this.state.fileList, ...fileData] });
        }
    };


    onImageRemove = () => {
        this.setState({
            imageFile: "",
            imaging: "",
            htmlelement: "",
        });
    };

    onEdit = (e) => {
        const formData = this.state.formData;

        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    updateFileList = (newFileList) => {
        this.setState({ fileList: newFileList });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    {
                        this.state.isSubmitting && <div className="alert alert-info">Submitting... Please wait</div>
                    }
                    {
                        this.state.isError && <div className="alert alert-danger">Something went wrong. Please try again!</div>
                    }
                    {
                        this.state.isSuccess && <div className="alert alert-success">Result Uploaded Successfully</div>
                    }
                    <div className="row">
                        <div className="col-sm">
                            <AddPatientImagingForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                patientSerial={this.state.patientSerial}
                                patientName={this.state.patientFullName}
                                data={this.state.formData}
                                htmlelement={this.state.htmlelement}
                                onImageRemove={this.onImageRemove}
                                onImageChange={this.onMultipleImageChange}
                                fileList={this.state.fileList}
                                updateFileList={this.updateFileList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AddPatientImaging);
