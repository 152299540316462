import React, {Component, useEffect} from "react";
import "./addmenu.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import AddMenuForm from "../addmenuform/addmenuform";
import {connect} from "react-redux";


class AddMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                menuName: "",
                menuIcon: "",
                menuLink: "",
                hospitalCode: "",
                submittedBy: ""
            },

            messageDescription: "",
            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.state.submittedBy = localStorage.getItem("userID");
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddMenu();
    };

    async onAddMenu() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            submittedBy: this.state.submittedBy
        };

        if(this.state.formData.menuName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Menu Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.menuIcon === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Menu Icon"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.menuLink === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Menu Link"
            this.handleErrorDialog();
            return false;
        }

        axios.post(`${this.state.serverLink}settings/main_menu/add`, sendData, this.props.loginData.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "menu exist") {
                    this.state.messageDescription = "A Menu with the same name already exist";
                    this.handleErrorDialog();
                } else if (response === "error") {
                    this.state.messageDescription = "Something went wrong adding the menu. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.state.messageDescription = "MAIN MENU ADDED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to upload the menu details!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/settings/menu/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Main Menu Added"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <AddMenuForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Add Main Menu"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        groupData: state.reportDetails,
        loginData: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(AddMenu);
