import React, {Component} from "react";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import axios from "axios";
import {Link} from "@material-ui/core";
import { serverLink, hospitalCode } from "../../../resources/url";
import {formatDateAndTime, formatTime} from "../../../resources/constants";

class PatientVitalList extends Component {
    constructor() {
        super();
        this.state = {
            serverLink: serverLink,
        }
    }

    render() {
        let count = 0;
        return <div className="card mb-3">
            <div className="card-header">
                Vitals
            </div>

            <div className="card-body">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>BP</th>
                        <th>Height</th>
                        <th>Weight</th>
                        <th>Temperature</th>
                        <th>Respiration</th>
                        <th>Pulse</th>
                        <th>BMI</th>
                        <th>BSA</th>
                        <th>SPO2</th>
                        <th>Remark</th>
                        <th>Taken On</th>
                        <th>Taken By</th>
                    </tr>
                    </thead>
                    { this.props.vitalList.length === 0 ?
                        (
                            <tbody>
                                <tr><td>No Vital Found</td></tr>
                            </tbody>
                        )
                        :
                        (
                            <tbody>
                            {this.props.vitalList.map((p) => {
                                count++;
                                return (
                                    <tr key={p._id}>
                                        <td>{count}</td>
                                        <td>{p.bloodPressure !== '' ? `${p.bloodPressure}${p.bloodPressure_unit}` : '--' }</td>
                                        <td>{p.height  ? `${p.height ?? '--'}${p.height_unit ?? ""}` : '--' }</td>
                                        <td>{p.weight ? `${p.weight ?? '--'}${p.weight_unit ?? ""}` : '--' }</td>
                                        <td>{p.temperature  ? `${p.temperature  ?? '--'}${p.temperature_unit ?? ""}` : '--' }</td>
                                        <td>{p.respiration  ? `${p.respiration}` : '--' }</td>
                                        <td>{p.pulse  ? `${p.pulse}` : '--' }</td>
                                        <td>{p.bmi === '' || p.bmi === null   ? '--' : `${p.bmi}${p.bmi_unit}` }</td>
                                        <td>{p.bsa !== '' ? p.bsa : '--' }</td>
                                        <td>{p.spo2 !== '' ? p.spo2 : '--' }%</td>
                                        <td>{!p.remark ? '--' : p.remark}</td>
                                        <td>{!p.dateTaken ? formatDateAndTime(p.submittedOn, "date_and_time") : formatDateAndTime(p.dateTaken, "date") + " => " + formatTime(p.timeTaken) }</td>
                                        <td>{!p.submittedBy ? '--' : p.submittedBy}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        )
                    }
                </table>
            </div>
        </div>
    }
}

export default PatientVitalList;
