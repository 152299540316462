import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import { hospitalCode, serverLink } from "../../../resources/url";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import "./profile.css";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";
import placeholder from "../../../Images/doctor.jpg";
import {setpersonDetails} from "../../../actions/setpersondetailsaction";
import {toast} from "react-toastify";
import {checkImageURL, defaultImage, imageExists} from "../../../resources/constants";
import doctor from "../../../Images/doctor.jpg";
import woman_doctor from "../../../Images/woman-doctor.jpg";


class Profile extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+ "doctor/",
            hospitalCode: hospitalCode,
            doctorID: localStorage.getItem('userID'),
            isLoading: true,
            appointmentLimit: 10,
            doctorEntryID: "",
            title: "",
            firstName: "",
            middleName: "",
            surname: "",
            emailAddress: "",
            phoneNumber: "",
            altPhoneNumber: "",
            gender: "",
            role: "",
            dateOfFirstAppointment: "",
            dateOfRenewedContract: "",
            employmentStatus: "",
            status: "",
            dateOfLastLogin: "",
            dateOfFirstLogin: "",
            specialisation: "",
            userID: "",
            imagefile: "",
            htmlelement: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            isCloseDialogAppear: true,

            messageTitle: "",
            messageDescription: "",

            doctorDetails: [],
        };
    }

    componentDidMount() {
        this.fetchDoctorDetails();
    }

    async fetchDoctorDetails() {
        const userId = this.state.doctorID;
        await fetch(`${this.state.serverLink}profile/${this.state.hospitalCode}/${userId}`, this.props.doctorLogin.token)
            .then(res => res.json())
            .then(data => {
                this.setState( {
                    doctorEntryID: data.doctorEntryID,
                    title:  data.title,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    surname: data.surname,
                    emailAddress: data.emailAddress,
                    phoneNumber: data.phoneNumber,
                    altPhoneNumber: data.altPhoneNumber,
                    gender: data.gender,
                    role: data.role,
                    dateOfFirstAppointment: data.dateOfFirstAppointment,
                    dateOfRenewedContract: data.dateOfRenewedContract,
                    employmentStatus: data.employmentStatus,
                    status: data.status,
                    dateOfLastLogin: data.dateOfLastLogin,
                    dateOfFirstLogin: data.dateOfFirstLogin,
                    specialisation: data.specialisation,
                    userID: data.userID,
                    passport: data.passport,
                    imagefile: "",
                    htmlelement: imageExists(`${serverLink}public/uploads/users_uploads/${data.passport}`) ? `${serverLink}public/uploads/users_uploads/${data.passport}` : data.gender === 'Male' ? doctor : woman_doctor,
                    experience: data.experience,
                    linkedin: data.linkedin,
                    twitter: data.twitter,
                    googleScholar: data.googleScholar,
                    academia: data.academia,
                    researchGate: data.researchGate,
                    aboutDoctor: data.aboutDoctor,
                    isLoading: false,
                });
                this.props.setOnUserDetails(data);
            });
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `
                    ${user_date.getDate()}-                    
                    ${monthNames[user_date.getMonth()]}-
                    ${user_date.getFullYear()}
                    
                    `
        } else {
            return '--'
        }
    };

    handleConsultationActionClicked = () => {
        let path = `../list/doctorlist.jsx`;
        let history = useNavigate();
        history.push(path);
    }

    handleSuccessDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openAlertDialog: true,
        });
    };

    handleErrorDialog = (title, message) => {
        this.setState({
            messageTitle: title,
            messageDescription: message,
            openAllergyDialog: false,
            openAddAppointmentDialog: false,
            openUpdateAppointmentDialog: false,
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    
    closeAlertDialog = () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.reload();
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    onImageChange = (event) => {
        const formData = this.state.formData;
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 2000000) {
                toast.error("max file size is 2mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: file,
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };


    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="doctorprofilepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="doctorprofilepage">
                <div className="main_section">
                   

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title={this.state.messageTitle}
                        des={this.state.messageDescription}
                    />

                    <div className="top_section topheader">
                        <Navigator title="Doctor Profile" />
                        <Link style={{marginRight: '100px'}} to="/website/profile/update">
                            <button
                                type="button"
                                className="btn btn-warning"
                                style={{backgroundColor: '#1370b5', color: 'white', border: '1px solid #1370b5'}}
                            >
                                 Update Profile
                            </button>
                        </Link>
                    </div>


                    <div className="row">
                        <div className="col-sm-2">
                            <div className="card">
                                
                                <div className="d-flex flex-column align-items-center text-center">
                                    
                                    <div className="">
                                        <img className="img-thumbnail img-fluid" style={{maxHeight: '300px'}} src={`${this.state.htmlelement}`}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-9">
                            <div className="card mb-3">
                                <div className="col-md-12">
                                    <h2 className="mb-1">{this.state.firstName} {this.state.middleName} {this.state.surname}</h2>
                                    <div className="mb-2" style={{fontSize: '20px', color: '#1370b5'}}>{this.state.specialisation.join(', ')}</div>
                                    <p className="text-justify mb-5">
                                        <div className="top_section2 mb-2">
                                            <small className="text-secondary p-0 m-0">About Doctor</small>

                                            <div className="text-secondary" style={{borderBottom: '1px solid #CCCCCC', marginTop: '-20px', width: '90%'}}>&nbsp;</div>
                                        </div>

                                        <span dangerouslySetInnerHTML={{__html: this.state.aboutDoctor}}/>
                                    </p>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0 font-weight-bold">Speciality</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {
                                                this.state.specialisation.length > 0 ?
                                                    <ul>

                                                        {this.state.specialisation.map((value, key) => (
                                                            <li key={key} className="text-secondary text-left" > {value}</li>
                                                        ))}
                                                    </ul>
                                                    : 'N/A'
                                            }
                                        </div>
                                    </div>

                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0  font-weight-bold">Phone Number</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.phoneNumber} {this.state.altPhoneNumber ? ' | ' + this.state.altPhoneNumber : ''}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0  font-weight-bold">Email Address</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.emailAddress ? this.state.emailAddress : 'N/A'}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0  font-weight-bold">Experience</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.experience ?? "N/A"}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0  font-weight-bold">LinkedIn</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.linkedin ?? "N/A"}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0  font-weight-bold">Google Scholar</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.googleScholar ?? "N/A"}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0  font-weight-bold">Research Gate</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.researchGate ?? "N/A"}
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="row mb-3">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0  font-weight-bold">Academia</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {this.state.academia ?? "N/A"}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                         
                    </div>

                   

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
        
    return {
        doctorDetails: state.personDetails,
        permissionData: state.permissionDetails,
        doctorLogin: state.loginDetails,
    };
    
};


const mapDispatchToProps = (dispatch) => {
    return {
        setOnUserDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);


