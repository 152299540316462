import React, {useState} from "react";
import "./updatetest.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import {hospitalCode, serverLink} from "../../../../resources/url";
import {connect} from "react-redux";
import SpecimenSection from "../modules/specimensection/specimensection";
import randomToken from "random-token";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import Navigator from "../../../Settings/navigation/navigation";
import {useNavigate} from "react-router-dom";

function UpdateLabTest (props) {

    const [loading, setLoading] = useState(true);
    const [isSpecimenList, setIsSpecimenList] = useState(true);
    const [specimenList, setSpecimenList] = useState([]);
    const [labList, setLabList] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [messageDescription, setMessageDescription] = useState("");
    const [testData, setTestData] = useState(
        {
            testID: props.labData._id,
            testName: props.labData.testName,
            laboratoryName: props.labData.laboratoryName,
            laboratoryID: props.labData.laboratoryID,
            hospitalCode: hospitalCode,
            updatedBy: `${props.loginData.firstName} ${props.loginData.middleName} ${props.loginData.surname}`,
            specimen: props.labData.specimen
        });
    const [specimenComponent, setSpecimenComponent] = useState(props.labData.specimen);

    const navigate = useNavigate();

    const fetchLabs = async () => {
        await axios.get(`${serverLink}laboratory/all/${hospitalCode}`, props.loginData.token)
            .then(data => {
                const result = data.data;
                if (loading) {
                    setLabList(result);
                    setLoading(false);
                }

            });

        await axios.get(`${serverLink}laboratory/specimen/all/${hospitalCode}`, props.loginData.token)
            .then(data => {
                const result = data.data;
                if (isSpecimenList) {
                    setSpecimenList(result)
                    setIsSpecimenList(false)
                }

            });
    }
    fetchLabs();

    const onEdit = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'laboratoryName') {
            let index = e.target.selectedIndex;
            let optionElement = e.target.childNodes[index]
            let sendData = {
                ...(testData),
                laboratoryName: value,
                laboratoryID: optionElement.getAttribute('data')
            }
            setTestData(sendData);
        }

        if (name === 'testName') {
            let sendData = {
                ...(testData),
                testName: value
            }
            setTestData(sendData);
        }

    };

    const onEditSpecimen = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'specimenName' || name === 'specimenBottle' || name === 'specimenBottleVolume' || name === 'specimenTurnAroundTime') {
            let index = e.target.selectedIndex;
            let optionElement = e.target.childNodes[index];
            let specimen_id = optionElement.getAttribute('data');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            if (name === 'specimenName')
                selectedSpecimen[0].specimenName = value;
            else if(name === 'specimenBottle')
                selectedSpecimen[0].specimenBottle = value;
            else if(name === 'specimenBottleVolume')
                selectedSpecimen[0].specimenBottleVolume = value;
            else
                selectedSpecimen[0].specimenTurnAroundTime = value;
        } else {
            let specimen_id = e.target.getAttribute('data');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            if (name === 'testAmount')
                selectedSpecimen[0].testAmount = parseFloat(value);
            else
                selectedSpecimen[0].specimenPatientPreparation = value;
        }

    }

    const onEditSpecimenItem = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === 'SpecimenItemName') {
            const specimen_id = e.target.getAttribute('specimen_id');
            const item_id = e.target.getAttribute('item_id');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            let selectedItem = selectedSpecimen[0].items.filter(item => item.item_id === item_id);
            if (name === 'SpecimenItemName')
                selectedItem[0].item_name = value;
        }

        if (name === 'SpecimenItemSIUnit' || name === 'ReferenceIntervalChecker' || name === 'RefInterval1' || name === 'RefInterval2') {
            let index = e.target.selectedIndex;
            let optionElement = e.target.childNodes[index]
            const specimen_id = optionElement.getAttribute('specimen_id');
            const item_id = optionElement.getAttribute('item_id');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            let selectedItem = selectedSpecimen[0].items.filter(item => item.item_id === item_id);
            if (name === 'SpecimenItemSIUnit')
                selectedItem[0].si_unit = value;
            if (name === 'ReferenceIntervalChecker') {
                selectedItem[0].ref_interval = value;
                if (value !== 'single') {
                    selectedItem[0].ref_interval_min = 0;
                    selectedItem[0].ref_interval_max = 0;
                } else {
                    selectedItem[0].ref_data = [];
                }
            }

            if (name === 'RefInterval1') {
                if (value !== '') {
                    if (selectedItem[0].ref_data.length === 0)
                        selectedItem[0].ref_data.push({ref_counter: value})
                    else {
                        selectedItem[0].ref_data[0] = {ref_counter: value};
                    }

                    if (value === 'two')
                        selectedItem[0].ref_data[0].ref_item = [{},{}];
                    if (value === 'three')
                        selectedItem[0].ref_data[0].ref_item = [{},{},{}];
                    if (value === 'four')
                        selectedItem[0].ref_data[0].ref_item = [{},{},{},{}];
                }
            }

            if (name === 'RefInterval2') {
                if (value !== '') {
                    if (selectedItem[0].ref_data.length === 1)
                        selectedItem[0].ref_data.push({ref_counter: value})
                    else
                        selectedItem[0].ref_data[1] = {ref_counter: value};

                    if (value === 'two')
                        selectedItem[0].ref_data[1].ref_item = [{},{}];
                    if (value === 'three')
                        selectedItem[0].ref_data[1].ref_item = [{},{},{}];
                    if (value === 'four')
                        selectedItem[0].ref_data[1].ref_item = [{},{},{},{}];
                }
            }

        }

        if (name === 'single_min_interval' || name === 'single_max_interval') {
            if (value === '')
                value = 0;

            const specimen_id = e.target.getAttribute('specimen_id');
            const item_id = e.target.getAttribute('item_id');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            let selectedItem = selectedSpecimen[0].items.filter(item => item.item_id === item_id);
            if (name === 'single_min_interval')
                selectedItem[0].ref_interval_min = parseFloat(value);
            else
                selectedItem[0].ref_interval_max = parseFloat(value);

        }

        if (name === 'main_ref_title' || name === 'main_ref_title2') {
            const specimen_id = e.target.getAttribute('specimen_id');
            const item_id = e.target.getAttribute('item_id');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            let selectedItem = selectedSpecimen[0].items.filter(item => item.item_id === item_id);
            if (name === 'single_min_interval')
                selectedItem[0].ref_data[0].ref_title = value;
            else
                selectedItem[0].ref_data[1].ref_title = value;
        }

        if (name === 'double_single_name' || name === 'double_single_min_interval' || name === 'double_single_max_interval') {
            const specimen_id = e.target.getAttribute('specimen_id');
            const item_id = e.target.getAttribute('item_id');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            let selectedItem = selectedSpecimen[0].items.filter(item => item.item_id === item_id);
            if (name === 'double_single_name')
                selectedItem[0].ref_data[0].ref_name = value;
            else if (name === 'double_single_min_interval')
                selectedItem[0].ref_data[0].ref_interval_min = parseFloat(value);
            else
                selectedItem[0].ref_data[0].ref_interval_max = parseFloat(value);
        }

        if (name === 'double_double_name' || name === 'double_double_min_interval' || name === 'double_double_max_interval' ) {
            const specimen_id = e.target.getAttribute('specimen_id');
            const item_id = e.target.getAttribute('item_id');
            const index = e.target.getAttribute('index');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            let selectedItem = selectedSpecimen[0].items.filter(item => item.item_id === item_id);

            if (name === 'double_double_name')
                selectedItem[0].ref_data[0].ref_item[index].ref_name = value;
            else if (name === 'double_double_min_interval')
                selectedItem[0].ref_data[0].ref_item[index].ref_interval_min = parseFloat(value);
            else
                selectedItem[0].ref_data[0].ref_item[index].ref_interval_max = parseFloat(value);
        }

        if (name === 'double_single_name2' || name === 'double_single_min_interval2' || name === 'double_single_max_interval2') {
            const specimen_id = e.target.getAttribute('specimen_id');
            const item_id = e.target.getAttribute('item_id');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            let selectedItem = selectedSpecimen[0].items.filter(item => item.item_id === item_id);
            if (name === 'double_single_name')
                selectedItem[0].ref_data[1].ref_name = value;
            else if (name === 'double_single_min_interval2')
                selectedItem[0].ref_data[1].ref_interval_min = parseFloat(value);
            else
                selectedItem[0].ref_data[1].ref_interval_max = parseFloat(value);
        }

        if (name === 'double_double_name2' || name === 'double_double_min_interval2' || name === 'double_double_max_interval2' ) {
            const specimen_id = e.target.getAttribute('specimen_id');
            const item_id = e.target.getAttribute('item_id');
            const index = e.target.getAttribute('index');
            let selectedSpecimen = testData.specimen.filter(item => item.specimen_id === specimen_id);
            let selectedItem = selectedSpecimen[0].items.filter(item => item.item_id === item_id);

            if (name === 'double_double_name2')
                selectedItem[0].ref_data[1].ref_item[index].ref_name = value;
            else if (name === 'double_double_min_interval2')
                selectedItem[0].ref_data[1].ref_item[index].ref_interval_min = parseFloat(value);
            else
                selectedItem[0].ref_data[1].ref_item[index].ref_interval_max = parseFloat(value);
        }


    }

    const addSpecimenComponent = () => {
        const specimen_id = randomToken(16);
        const initial_item_id = randomToken(10);
        setSpecimenComponent([...(specimenComponent), specimen_id]);
        let sendData = {
            ...(testData),
            specimen: [...testData.specimen, {specimen_id: specimen_id, taken_by: "", taken_on: "", items: [{item_id: initial_item_id, item_name: '', si_unit: '', ref_interval: 'single', ref_data: []}]}]
        }
        setTestData(sendData)
    }

    const removeSpecimen = (e) => {
        setSpecimenComponent(specimenComponent.filter(item => item !== e.target.id));
        const specimens = testData.specimen.filter(item => item.specimen_id !== e.target.id);
        let sendData = {
            ...(testData),
            specimen: specimens
        };
        setTestData(sendData);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessageDescription("Are you sure you want to update the selected test with the submitted parameters?");
        setOpenConfirmDialog(true);
    }

    const handleUploadToServer = async () => {
        setMessageDescription("Updating. Please wait...");

        await axios.patch(`${serverLink}laboratory/test/update`, testData, props.loginData.token)
            .then(result => {
                const response = result.data.message;
                if (response === 'success') {
                    setOpenConfirmDialog(false);
                    setMessageDescription("LAB UPDATED SUCCESSFULLY");
                    setOpenAlertDialog(true);
                    setTimeout(() => navigate("/lab/test/list"), 2000);
                }
                else  {
                    setOpenConfirmDialog(false);
                    setMessageDescription("Server network issue. Please check your internet connection");
                    setOpenErrorDialog(true);
                }
            })
            .catch(error => {
                console.log(error);
                setOpenConfirmDialog(false);
                setMessageDescription("Server network issue. Please check your internet connection");
                setOpenErrorDialog(true);
            })
    }

    const handleErrorDialog = () => {
        setOpenConfirmDialog(false);
        setOpenErrorDialog(true);
    };
    const handleSuccessDialog = () => {
        setOpenAlertDialog(true)
    };
    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true)
    };
    const closeAlertDialog= () => {
        setOpenAlertDialog(false);
    };
    const closeErrorDialog = () => {
        setOpenErrorDialog(false);
    };
    const closeConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    return isSpecimenList ? (
        <div className="basepage">
            <i className="fas fa-spinner fa-pulse fa-2x"/>
        </div>
    ) : (
        <div className="basepage">
            <div className="main_section">

                <ConfirmDialogBox
                    openDailog={openConfirmDialog}
                    onSetOpenDialog={closeConfirmDialog}
                    handleConfirmOkBtn={handleUploadToServer}
                    isLoading={false}
                    title="UPDATE TEST"
                    des={ messageDescription }
                />

                <ErrorDialogBox
                    openDialog={openErrorDialog}
                    onSetOpenDialog={closeErrorDialog}
                    title="ERROR"
                    des={messageDescription}
                />

                <AlertDialogBox
                    openDailog={openAlertDialog}
                    onSetOpenDailog={closeAlertDialog}
                    title="Laboratory Test Updated"
                    des={messageDescription}
                />

                <div className="topheader">
                    <Navigator title={"Update Test"} />
                </div>
                <hr />

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="laboratoryName">Laboratory Name <span className="text-danger">*</span></label>
                                    <select
                                        name="laboratoryName"
                                        id="laboratoryName"
                                        className="custom-select"
                                        defaultValue={testData.laboratoryName}
                                        required="required"
                                        onChange={onEdit}>
                                        <option value="">Select Laboratory</option>
                                        {
                                            labList.map((lab, index) => {
                                                return <option key={index} data={lab._id} value={lab.laboratoryName}>{lab.laboratoryName}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="testName">Test Name <span className="text-danger">*</span></label>
                                    <input
                                        name="testName"
                                        type="text"
                                        required="required"
                                        className="form-control"
                                        defaultValue={testData.testName}
                                        placeholder="Covid-19 Test"
                                        onChange={onEdit}
                                    />
                                </div>
                            </div>
                            <div id="specimen-container">
                                {
                                    specimenComponent.map((item, index) => {
                                        return <SpecimenSection index={(index+1)} id={item.specimen_id} key={index} remove={removeSpecimen} onEdit={onEditSpecimen} onEditItem={onEditSpecimenItem} specimen_list={specimenList} specimen_data={testData} set_data={setTestData} />
                                    })
                                }
                            </div>
                            <div className="form-row mb-5 mt-5" id="specimen-container">
                                <div className="col-md-12">
                                    <hr/>
                                    <button className="btn btn-info btn-sm" onClick={addSpecimenComponent}>Add Specimen</button>
                                    <hr/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <button className="btn btn-primary btn-block" type="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        labData: state.serviceDetails
    };
};

export default connect(mapStateToProps, null)(UpdateLabTest);
