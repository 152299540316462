import React, { Component } from "react";
import "./prescription_history.css";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import {serverLink, hospitalCode} from "../../../../resources/url";
import {loginValidation} from "../../../../context/loginvalidation";
import axios from "axios";
import {Link} from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Navigator from "../../../Settings/navigation/navigation";
import FormPrompt from "../../../DialogBoxes/formprompt";
import CaptureVital from "../../../Appointment/capturevital/capturevital";

class PatientPrescriptionHistory extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"pharmacy/",
            hospitalCode: hospitalCode,
            isCloseDialogAppear: true,
            openDetailsDialog: false,
            prescriptionDetails: [],
            paymentStatus: "",
            isLoading: false,
            columns: [
                {
                    name: "S/N",
                    selector: row => `${row.count}`,
                    sortable: true,
                    width: '8%'
                },
                {
                    name: "PatientID",
                    selector: row => `${row.rawData.patientSerial}`,
                    sortable: true,
                    width: '10%'
                },
                {
                    name: "Patient Name",
                    selector: row => `${row.rawData.patientName}`,
                    sortable: true,
                    width: '15%'
                },
                {
                    name: "Doctor Name",
                    selector: row => `${row.rawData.doctorName}`,
                    sortable: true,
                    width: '15%'
                },
                {
                    name: "Amount Paid",
                    selector: row => `${row.rawData.amountPaid.toLocaleString('en-NG')}`,
                    sortable: true,
                    width: '14%'
                },
                {
                    name: "Balance",
                    selector: row => `${(row.rawData.prescriptionAmount - row.rawData.amountPaid).toLocaleString('en-NG')}`,
                    sortable: true,
                    width: '9s%'
                },
                {
                    name: "Total",
                    selector: row => `${row.rawData.prescriptionAmount.toLocaleString('en-NG')}`,
                    sortable: true,
                    width: '10%'
                },
                {
                    name: "Date",
                    selector: row => {
                        // let prescriptionDate = new Date(row.rowData.submittedDate);
                        // const date = `${prescriptionDate.getDate()}/${
                        //     prescriptionDate.getMonth() + 1
                        // }/${prescriptionDate.getFullYear()}`;
                     return   `${row.rawData.submittedDate === "" ? "N/A" : row.rawData.submittedDate}`
                    },
                    sortable: true,
                    width: '10%'
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to="/pharmacy/prescription/history">
                            <button
                                type="button"
                                onClick={async () => {
                                    const historyData = {
                                        ...row.rawData,
                                    };
                                    this.setState({
                                        openDetailsDialog: true,
                                        prescriptionDetails: row.rawData.prescriptionDetail,
                                        paymentStatus: row.rawData.paymentStatus,
                                    })
                                    // this.props.setOnPatientDetails(historyData);
                                }}
                                className="btn btn-primary btn-sm"
                            >

                                <i className="fa fa-list" aria-hidden="true"/>
                            </button>
                        </Link>
                    ),
                    width: '8%',
                },

            ],
            data: []
        };
    }
    componentDidMount() {
        this.fetchPatientPrescriptions();
    }

    fetchPatientPrescriptions = async () => {
        await axios.get(`${this.state.serverLink}prescription/history/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(data => {
                data.data.map((data, index) => {
                    this.setState({
                        data: [...this.state.data,
                            {
                                count: index+1,
                                rawData: data,
                            }
                        ],
                    });
                })
                this.setState({
                    isLoading: false,
                });
            });
    }


    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeDetailsDialog = () => {
        this.setState({
            openDetailsDialog: false,
        });
    };


    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search patient prescriptions',

        };
        let count = 1;
        let total = 0;
        return this.state.isLoading ? (
            <div className="prescriptionListpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="prescriptionListpage">
                <div className="main_section">
                    <FormPrompt
                        openDialog={this.state.openDetailsDialog}
                        title="PRESCRIPTION DETAILS"
                        onSetOpenDialog={this.closeDetailsDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <table className="table table-striped" style={{fontSize: '12px'}}>
                            <thead className="thead tablehead">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Drug Name</th>
                                <th scope="col">Drug Dosage</th>
                                <th scope="col">Dose </th>
                                <th scope="col">Prescription</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Total</th>
                                {/*<th scope="col">Status</th>*/}
                            </tr>
                            </thead>
                            {this.state.prescriptionDetails.length === 0 ? (
                                <tbody>
                                <tr>
                                    <td>No Prescription Found</td>
                                </tr>
                                </tbody>
                            ) : (
                                <tbody className="tablebody">
                                {this.state.prescriptionDetails &&
                                    this.state.prescriptionDetails.map((p, i) => {
                                        count++;
                                        let totalAmount = p.drugQuantity * p.drugAmount;
                                        total+=totalAmount;
                                        let status = p.status === 'paid' ? 'text-success align-middle' : 'text-danger align-middle';
                                        return (

                                            <tr key={i}>
                                                <td className="align-middle">{count}</td>
                                                <td className="align-middle"  style={{width: '300px'}}>{p.drugName}</td>
                                                <td className="align-middle"> {p.drugDose}</td>
                                                <td className="align-middle" style={{width: '150px'}}> {p.doseFrequency}</td>
                                                <td className="align-middle" style={{width: '200px'}}> {p.prescription} </td>
                                                <td className="align-middle"> {p.drugQuantity}</td>
                                                <td className="align-middle">{p.drugAmount.toLocaleString('en-NG')}</td>
                                                <td className="align-middle">{totalAmount.toLocaleString('en-NG')}</td>
                                                {/*<td className={status}>{ p.status === 'paid' ? 'Paid' : 'UnPaid'}</td>*/}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </table>
                        <div className="col-sm-8 offset-sm-4">
                            <div className="row" style={{justifyContent: 'end'}}>

                                <div className="col-md-5 text-center text-uppercase" style={{backgroundColor: '#eeeeee', borderLeft: '3px solid black'}}>
                                    <span className="text-danger" style={{fontSize: '20px', fontWeight: 'bold'}}>Total</span><br/>
                                    <span style={{fontSize: '18px', fontWeight: 'bold'}}>{this.currencyConverter(total)}</span>
                                </div>
                            </div>
                        </div>
                    </FormPrompt>

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Prescription record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Find Patient Prescriptions" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap"> </div>
                    </div>

                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            className="tablehead"
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientPrescriptionHistory);
