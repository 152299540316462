import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./addpatientallergy.css";
import AllergyForm from "./allergyform";
import axios from "axios";
import { serverLink, hospitalCode } from "../../../resources/url";
import {connect} from "react-redux";
import {toast} from "react-toastify";

class AddAllergy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            date: null,
            imageAvatar: "",
            imagefile: "",
            htmlelement: "",
            isLoading: false,
            patientSerial: this.props.patientData.patientSerial,
            patientFullName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                this.props.patientData.surname,

            formData: {
                patientSerial: this.props.patientData.patientSerial,
                patientName: this.props.patientData.firstName + ' '+ this.props.patientData.middleName +' '+
                    this.props.patientData.surname,
                hospitalCode: "",
                allergyName: "",
                allergyDescription: "",
                reactionImage: "",
                allergyMedication: "",
                durationBeforeSubsiding: "",
                lastAttackDate: "",
                submittedBy: "",
                image_name: "",
            },

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isSubmitting: true
        })
        this.onAddAllergy();
    };

    async onAddAllergy() {
        let submit_btn = document.getElementById('SubmissionBtn');
        submit_btn.innerHTML = `Submitting ...`
        submit_btn.setAttribute('disabled', 'disabled');

        let sendData = {
            ...this.state.formData,
            submittedBy: localStorage.getItem('userID'),
            hospitalCode: this.state.hospitalCode
        };

        axios.post(`${this.state.serverLink}allergy/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                submit_btn.innerHTML = `Save`;
                submit_btn.disbled = false;
                if(response === "success") {
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('file', this.state.imagefile);
                        formData.append('entry_id', result.data.id)
                        axios.patch(`${this.state.serverLink}allergy/uploadAllergyPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.props.handleSuccessDialog('ADDED', 'Allergy Added Successfully');
                } else if (response === "exist") {
                    this.props.handleErrorDialog('EXIST', 'Allergy with the same name already exist!');
                } else if (response === "failed") {
                    this.props.handleErrorDialog('FAILED', 'Something went wrong saving the allergy. Please try again!');
                } else if (response === "error"){
                    this.props.handleErrorDialog('ERROR', 'An error occur adding the allergy. Please try again!');
                } else {
                    this.props.handleErrorDialog('ERROR', response);
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                submit_btn.innerHTML = `Save`;
                submit_btn.disbled = false;
                this.props.handleErrorDialog('ERROR', err);
            });
    }

    handleChange = (date) => {
        if (date !== null) {
            const allergyDate = new Date(date);

            this.setState({
                date: allergyDate,
                formData: {
                    ...this.state.formData,
                    lastAttackDate: `${allergyDate.getFullYear()}-${allergyDate.getMonth() + 1}-${allergyDate.getDate()}`,
                },
            });
        } else {
            this.setState({
                date: date,
                formData: {
                    ...this.state.formData,
                    lastAttackDate: date,
                },
            });
        }
    };

    onImageChange = (event) => {
        const formData = this.state.formData;
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg"  || file.type === "application/pdf" ) {
            } else {
                toast.error("Only .pdf, .png, .jpg and .jpeg format allowed!")
                return false;
            }
            if (file.size > 1000000) {
                toast.error("max file size is 1mb")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: file,
                    htmlelement: e.target.result,
                    formData: {
                        ...formData,
                        image_name: file.name,
                    }
                });
            };
        }
    };

    onImageRemove = () => {
        this.setState({
            imagefile: "",
            imageAvatar: "",
            htmlelement: (
                <i className="fa fa-user fa-8x" aria-hidden="true"/>
            ),
        });
    };

    onEdit = (e) => {
        const formData = this.state.formData;

        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">
                    <div className="row">
                        <div className="col-sm">
                            <AllergyForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                date={this.state.date}
                                handleChange={this.handleChange}
                                patientSerial={this.state.patientSerial}
                                patientName={this.state.patientFullName}
                                data={this.state.formData}
                                htmlelement={this.state.htmlelement}
                                onImageRemove={this.onImageRemove}
                                onImageChange={this.onImageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AddAllergy);
