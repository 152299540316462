import React, { Component } from "react";
import "./prescription.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import {serverLink, hospitalCode} from "../../../../resources/url";
import PatientPrescriptionList from "../../utils/patientprescriptionlist";
import {loginValidation} from "../../../../context/loginvalidation";
import Navigator from "../../../Settings/navigation/navigation";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import DataTable from "../../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../../resources/constants";

class FindPatientPrescription extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"pharmacy/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: false,
            limit: 300,

            isLoadMoredata: false,

            totalNumOfPrescription: null,
            noMoreDataText: "",

            openErrorDialog: false,

            prescriptionList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedPatientName: "",
            selectedPatientId: "",
        };
    }
    componentDidMount() {
        this.fetchPatientPrescriptions(300)
    }

    async fetchPatientPrescriptions(limit) {
        this.setState({ isLoading: true });
        await fetch(`${this.state.serverLink}prescription/patient/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    prescriptionList: data.filter(e => e.paymentStatus === 'unpaid').sort((a, b) => new Date(b.submittedDate) - new Date(a.submittedDate)),
                    isLoading: false,
                });
            });
    }


    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    showTable = () => {

        try {
            return this.state.prescriptionList.map((p, index) => {
                let prescriptionDate = new Date(p.submittedDate);
                const date = `${prescriptionDate.getDate()}/${
                    prescriptionDate.getMonth() + 1
                }/${prescriptionDate.getFullYear()}`;
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{p.patientSerial}</td>
                        <td>{p.patientName}</td>
                        <td>{p.doctorName}</td>
                        <td>{p.amountPaid.toLocaleString('en-NG')}</td>
                        <td>{(p.prescriptionAmount - p.amountPaid).toLocaleString('en-NG')}</td>
                        <td>{p.prescriptionAmount.toLocaleString('en-NG')}</td>
                        <td className="text-danger">{p.paymentStatus}</td>
                        <td>{p.submittedDate === "" ? "N/A" : date}</td>
                        <td>
                            <Link to="/pharmacy/prescription/details">
                                <button
                                    onClick={async () => {
                                        const sendData = {
                                            ...p,
                                            prescriptionDataList: p.prescriptionDetail.filter(e => e.status === 'unpaid')
                                        };
                                        this.props.setOnPatientDetails(sendData);
                                    }}
                                    type="button"
                                    className="btn btn-success"
                                >
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                </button>
                            </Link>
                        </td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    render() {
        const header = ["S/N", "PatientID",  "Patient Name", "Doctor Name", "Amount Paid (₦)", "Balance (₦)", "Total (₦)", "Status", "Date", "Option"];
        return this.state.isLoading ? (
            <div className="prescriptionListpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
        <div className="baselistpage">
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Find Patient Prescriptions" />
                </div>
                <hr />
            </div>

            <ErrorDialogBox
                openDialog={this.state.openErrorDialog}
                onSetOpenDialog={this.closeErrorDialog}
                title="Record Not Found"
                des="Prescription record not found. Please try again!"
            />
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h3 className="card-title">Patient Prescription List</h3>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                </div>
                            </div>
                        </div>
                        <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')} body={this.showTable()} title={`Patient Prescription Report`} />
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FindPatientPrescription);
