import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setPatientListDetails, setPatientProfileDetails,
    setpersonDetails
} from "../../../actions/setpersondetailsaction";

import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import { serverLink, hospitalCode } from "../../../resources/url";
import PatientListTable from "../utils/patientlisttable";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";

class PatientList extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            serverLink: serverLink+"patient/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 50,

            isLoadMoreData: false,

            totalNumOfPatient: null,
            noMoreDataText: "",

            openErrorDialog: false,

            patientList: [],
            patientListFilter: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedPatientName: "",
            selectedPatientId: "",
        };
    }
    componentDidMount() {
        this.onSetTotalNumOfPatients();
        this.fetchPatient(this.state.limit);
    }

    async fetchPatient(limit) {
        this.setState({ isLoadMoreData: true });

        if (this.props.patientListData === '') {
            await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    this.props.setOnPatientList(data);
                    this.setState({
                        patientList: data,
                        patientListFilter: data,
                        isLoadMoreData: false,
                        isLoading: false,
                    });
                });
        } else {
            this.setState({
                patientList: this.props.patientListData,
                patientListFilter: this.props.patientListData,
                isLoadMoreData: false,
                isLoading: false,
            });
            await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    this.props.setOnPatientList(data);
                });
        }
    }

    showMorePatient = () => {
        if (this.state.limit <= this.state.totalNumOfPatient) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchPatient(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Patients",
            });
        }
    };

    async onSetTotalNumOfPatients() {
        this.setState({ isLoading: true });
        await fetch(`${this.state.serverLink}count/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({ totalNumOfPatient: data, isLoading: false });
            });
    }

    handleSearch = async (e) => {
        const search = e.target.value.toLowerCase();
        const patient_list = this.state.patientListFilter.filter(e => e.firstName.toLowerCase().includes(search) || e.middleName.toLowerCase().includes(search) ||
            e.surname.toLowerCase().includes(search) || e.phoneNumber.toLowerCase().includes(search) || e.emailAddress.toLowerCase().includes(search) || e.patientSerial.toLowerCase().includes(search))
        this.setState({
            patientList: patient_list
        })
        // if (this.state.searchText !== "") {
        //     this.setState({
        //         isLoading: true,
        //     });
        //     const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");
        //     await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
        //         .then(res => res.json())
        //         .then(data => {
        //             if (data.length === 0) {
        //                 this.setState({
        //                     isSearching: false,
        //                     isLoading: false,
        //                     openErrorDialog: true,
        //                 });
        //             } else {
        //                 this.setState({
        //                     patientList: data,
        //                     isSearching: false,
        //                     isLoading: false,
        //                 });
        //             }
        //         });
        //
        // }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    render() {

        return this.state.isLoading ? (
            <div className="patientlistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="patientlistpage">
                <div className="main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Patient record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Patient" />
                    </div>
                    <hr />
                    <PatientListTable isFront={this.props.isFront} isSearching={this.state.isSearching} patientlist={this.state.patientList} setOnPatientDetails={this.props.setOnPatientDetails} />

                    {/*<div className="loadmoredatasection">*/}
                    {/*    {this.state.isLoadMoreData ? (*/}
                    {/*        <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"/>*/}
                    {/*    ) : (*/}
                    {/*        <div className="nomoredatatext">{this.state.noMoreDataText}</div>*/}
                    {/*    )}*/}
                    {/*    {this.state.patientList.length === 0 ? null : this.state*/}
                    {/*        .isSearchDataShow ? null : (*/}
                    {/*        <button*/}
                    {/*            type="button"*/}
                    {/*            className="btn btn-warning"*/}
                    {/*            onClick={() => this.showMorePatient()}*/}
                    {/*        >*/}
                    {/*            Show More*/}
                    {/*        </button>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        patientListData: state.patientListDetails,
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setPatientProfileDetails(p));
        },
        setOnPatientList: (p) => {
            dispatch(setPatientListDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
