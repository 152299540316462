import React, {useEffect, useState} from "react";
import "./servicerequestpage.css";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import axios from "axios";
import {serverLink, hospitalCode} from "../../../resources/url";
import {connect} from "react-redux";
import Navigator from "../../Settings/navigation/navigation";

function ServiceRequestPending(props) {
    let page_url = window.location.pathname;
    let page_id = page_url.split('/');
    let id = page_id[4];
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [columns] = useState([
        {
            name: "Service Name",
            selector: row => `${row.serviceName}`,
            sortable: true,
        },
        {
            name: "Item Name",
            selector: row => `${row.itemName}`,
            sortable: true,
            width: '30%'
        },
        {
            name: "Unit Price",
            selector: row => `${moneyFormat(row.itemPrice)}`,
            sortable: true
        },
        {
            name: "Qty",
            selector: row => `${row.itemQty}`,
            sortable: true
        },
        {
            name: "Requested On",
            selector: row => `${formatDateAndTime(row.submittedOn)}`,
            sortable: true
        },
        {
            name: "Action",
            cell: (row) => (
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => markComplete(row._id)}
                >
                    <i className="fa fa-check" aria-hidden="true" title={"Mark as completed"}/>
                </button>
            ),
        }
    ]);

    const formatDateAndTime = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const day = user_date.getDate() < 10 ? "0"+user_date.getDate() : user_date.getDate();

            return `${day}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()}`
        } else {
            return '--'
        }
    };

    const getServiceList = async () => {
        await axios.get(`${serverLink}service/request/pending/${id}`, props.doctorDetails.token)
            .then(response => {
                if (isLoading) {
                    setData(response.data);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.log("SERVICES NETWORK ERROR", error)
            })
    }

    const markComplete = async (id) => {
        const sendData = {
            requestID: id,
            submittedBy: props.doctorDetails.fullName
        }
        await axios.patch(`${serverLink}service/request/mark/complete`, sendData, props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success')
                    getServiceList()
            })
            .catch(error => {
                console.log("SERVICES NETWORK ERROR", error)
            })
    }
    useEffect(() => {
        getServiceList();
    },[]);

    const moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }



    const tableData = {
        columns,
        data,
        print: false,
        exportHeaders: true,
        filterPlaceholder: 'Search service'
    };

    return isLoading ? (
        <div className="basepage">
            <i className="fas fa-spinner fa-pulse fa-2x "/>
        </div>
    ) : (
        <div className="basepage">
            <div className="main_section">

                <div className="topheader">
                    <Navigator title="Pending Requests" />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>
                </div>
                <hr />
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover/>
                </DataTableExtensions>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};


export default connect(mapStateToProps, null)(ServiceRequestPending);
