import React, { Component } from "react";
import { connect } from "react-redux";
import './nursecapturevitals.css';
import {serverLink, hospitalCode} from "../../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import {loginValidation} from "../../../context/loginvalidation";
import FormPrompt from "../../DialogBoxes/formprompt";
import CaptureVital from "../../Appointment/capturevital/capturevital";
import Navigator from "../../Settings/navigation/navigation";

class NurseCaptureVitals extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';

        this.state = {
            isLoading: true,
            doctorID: this.props.doctorData.userID,
            columns: [
                {
                    name: "Patient Name",
                    selector: row => `${row.patientName}`,
                    sortable: true
                },
                {
                    name: "Patient No",
                    selector: row => `${row.patientSerial}`,
                    sortable: true
                },
                {
                    name: "Department",
                    selector: row => `${row.appointmentDepartment}`,
                    sortable: true
                },
                {
                    name: "Appointment Time",
                    selector: row => `${row.appointmentTime}`,
                    sortable: true
                },
                {
                    name: "Check-in On",
                    selector: row => `${row.checkInTime}`,
                    sortable: true
                },
                {
                    name: "Capture",
                    cell: (row) => (
                        <button
                            type="button"
                            onClick={() => this.onOpenDialog(row.rowData)}
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-notes-medical" aria-hidden="true"/>
                        </button>
                    ),
                }
            ],
            data: [],
            isCloseDialogAppear: true,
            openDialog: false,
            selectedAppointment: '',
            errorMessage: '',
            formData: {
                patientSerial: '',
                patientName: '',
                appointmentID: '',
                pulse: "",
                respiration: "",
                bloodPressure: "",
                temperature: "",
                hospitalCode: hospitalCode,
                temperature_unit: "C",
                weight: "",
                weight_unit: "KG",
                height: "",
                height_unit: "M",
                bmi: "",
                bsa: "",
                spo2: "",
                checkInBy: `${this.props.doctorData.firstName} ${this.props.doctorData.middleName} ${this.props.doctorData.surname}`,
                _id: ""
            },

        };
    }
    componentDidMount() {
        this.getCheckInData();
    }

    async getCheckInData () {
        await axios.get(`${serverLink}doctor/consultation/nurse/today/${hospitalCode}/Checked In`, this.props.doctorData.token)
            .then(result => {
                if (result.data.length > 0) {
                    let result_set = result.data;
                    result_set.map(val => {
                        this.setState({
                            data: [...this.state.data,
                                {
                                    id: val._id,
                                    patientName: val.patientName,
                                    patientSerial: val.patientSerial,
                                    appointmentDepartment: val.appointmentDepartment,
                                    appointmentTime: val.appointmentTime,
                                    checkInTime: val.checkInTime,
                                    appointmentStatus: val.appointmentStatus,
                                    rowData: val
                                }
                            ]
                        })
                    })
                }
                this.setState({
                    isLoading: false
                })
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const sendData = {
            ...this.state.formData
        }
        if(this.state.formData.temperature === "" && this.state.formData.respiration === "" &&
            this.state.formData.bloodPressure === "" && this.state.formData.weight === "" &&
            this.state.formData.height === "" &&  this.state.formData.bmi === "" &&
            this.state.formData.bsa === "" && this.state.formData.spo2 === "")
        {
            this.setState({
                errorMessage: <p className="text-danger text-center">You have to fill at least one vital before submitting</p>,
                isLoading: false
            })
            return false;
        } else {
            this.setState({
                errorMessage: ""
            })
        }

        axios.post(`${serverLink}vital/add`, sendData, this.props.doctorData.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "success") {
                    this.setState({
                        errorMessage: <p className="alert alert-success text-center">Vital Added</p>
                    })
                    setTimeout(function () {
                        window.location.reload();
                    },2000)

                } else {
                    this.setState({
                        errorMessage: <p className="text-danger text-center">Something went wrong adding vital. Please check your connection and try again</p>
                    })
                }
            })
            .catch(err => {
                console.log(err)
            });

    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    onOpenDialog = (appointment) => {
        const formData = this.state.formData;
        this.setState({
            selectedAppointment: appointment,
            formData: {
                ...formData,
                patientSerial: appointment.patientSerial,
                patientName: appointment.patientName,
                appointmentID: appointment._id,
            },
            openDialog: true
        })
    }

    onCloseDialog = () => {
        this.setState({
            openDialog: false,
            selectedAppointment: '',
            message: ''
        })
    }

    setCloseBtnAppear = () => {
        this.setState({
            isCloseDialogAppear: false,
        });
    };

    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: false,
            filterPlaceholder: 'Search patient'
        };

        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="basepage">
                <div className="main_section">
                    <FormPrompt
                        openDialog={this.state.openDialog}
                        title="CAPTURE VITALS"
                        onSetOpenDialog={this.onCloseDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        {this.state.errorMessage}
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="patientSerial">Patient ID</label>
                                    <input
                                        name="patientSerial"
                                        type="text"
                                        className="form-control"
                                        id="patientSerial"
                                        disabled
                                        value={this.state.formData.patientSerial}
                                        onChange={this.onEdit}
                                        required
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="patientSerial">Patient Name</label>
                                    <input
                                        name="patientSerial"
                                        type="text"
                                        className="form-control"
                                        id="patientSerial"
                                        disabled
                                        value={this.state.formData.patientName}
                                        onChange={this.onEdit}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-6 mb-3">

                                    <label htmlFor="appointmentID">Select the Checked in Appointment</label>
                                    <select
                                        name="appointmentID"
                                        className="custom-select"
                                        id="appointmentID"
                                        onChange={this.onEdit}
                                    >
                                        <option value={this.state.formData.appointmentID}>
                                            {`Department: ${this.state.selectedAppointment.appointmentDepartment} => Doctor: ${this.state.selectedAppointment.doctorName} => Time: ${this.state.selectedAppointment.appointmentTime}`}
                                        </option>
                                    </select>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="pulse">Pulse</label>
                                    <input
                                        type="text"
                                        id="pulse"
                                        name="pulse"
                                        className="form-control"
                                        placeholder="Pulse"
                                        value={this.state.formData.pulse}
                                        onChange={this.onEdit}
                                    />
                                </div>
                            </div>


                            <div className="form-row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="bloodPressure">Blood Pressure</label>
                                    <input
                                        type="text"
                                        id="bloodPressure"
                                        name="bloodPressure"
                                        className="form-control"
                                        placeholder="Blood Pressure"
                                        value={this.state.formData.bloodPressure}
                                        onChange={this.onEdit}
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="temperature">Temperature</label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            id="temperature"
                                            name="temperature"
                                            className="form-control"
                                            placeholder="Patient Temperature"
                                            value={this.state.formData.temperature}
                                            onChange={this.onEdit}
                                        />
                                        <span className="input-group-btn">
                                              <select
                                                  className="btn"
                                                  id="temperature_unit"
                                                  name="temperature_unit"
                                                  defaultValue={this.state.formData.temperature_unit}
                                                  onChange={this.onEdit}
                                              >
                                                  <option value="C">Celsius</option>
                                                  <option value="F">Fahrenheit</option>
                                              </select>
                                            </span>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="height">Height</label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            id="height"
                                            name="height"
                                            className="form-control"
                                            placeholder="Patient Height"
                                            value={this.state.formData.height}
                                            onChange={this.onEdit}
                                        />
                                        <span className="input-group-btn">
                                              <select
                                                  className="btn"
                                                  id="height_unit"
                                                  name="height_unit"
                                                  defaultValue={this.state.formData.height_unit}
                                                  onChange={this.onEdit}
                                              >
                                                  <option value="M">Meter</option>
                                                  <option value="CM">Centimeter</option>
                                              </select>
                                            </span>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="weight">Weight</label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            id="weight"
                                            name="weight"
                                            className="form-control"
                                            placeholder="Patient Weight"
                                            value={this.state.formData.weight}
                                            onChange={this.onEdit}
                                        />
                                        <span className="input-group-btn">
                                              <select
                                                  className="btn"
                                                  id="weight_unit"
                                                  name="weight_unit"
                                                  defaultValue={this.state.formData.weight_unit}
                                                  onChange={this.onEdit}
                                              >
                                                  <option value="KG">Kilogram</option>
                                                  <option value="LB">Pound</option>
                                              </select>
                                            </span>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="bmi">BMI</label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            id="bmi"
                                            name="bmi"
                                            className="form-control"
                                            placeholder="Patient BMI"
                                            value={this.state.formData.bmi}
                                            onChange={this.onEdit}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="bsa">BSA</label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            id="bsa"
                                            name="bsa"
                                            className="form-control"
                                            placeholder="Patient BSA"
                                            value={this.state.formData.bsa}
                                            onChange={this.onEdit}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="spo2">SPO2</label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            id="spo2"
                                            name="spo2"
                                            max={100}
                                            className="form-control"
                                            placeholder="Patient SPO2 (in percentage)"
                                            value={this.state.formData.spo2}
                                            onChange={this.onEdit}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="respiration">Respiration</label>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            id="respiration"
                                            name="respiration"
                                            className="form-control"
                                            placeholder="Patient respiration"
                                            value={this.state.formData.respiration}
                                            onChange={this.onEdit}
                                        />
                                    </div>
                                </div>

                            </div>
                            <button type="submit" className="btn btn-success btn-block">Save</button>
                        </form>
                    </FormPrompt>

                    <div className="topheader">
                        <Navigator title="Capture Appointment Vital" />
                    </div>
                    <hr />
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover/>
                    </DataTableExtensions>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorData: state.loginDetails,
        permissionData: state.permissionDetails
    };
};

export default connect(mapStateToProps, null)(NurseCaptureVitals);
