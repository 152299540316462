import React, { Component } from "react";
import "./expireddrugs.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails
} from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import { serverLink, hospitalCode } from "../../../resources/url";
import Navigator from "../../Settings/navigation/navigation";
import DataTable from "../../Common/data-table/data-table";
import {currentDate, formatDateAndTime} from "../../../resources/constants";

class ExpiredDrug extends Component {
    constructor(props) {
        super(props);

        // if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
        //     window.location.href = '/';

        this.state = {
            serverLink: serverLink+"pharmacy/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            isLoadMoreData: false,

            totalNumOfDrugs: null,
            noMoreDataText: "",

            drugList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedDrugName: "",

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchDrugs();
    }

    async fetchDrugs() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}drug/all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugList: data.filter((item) =>  new Date(item.manufactureDate).getTime() >= new Date(item.expiryDate).getTime()
                    ),
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    checkExpiryData = (dateManufacured, ExpiredDate) => {
        let manufatureDat = new Date(dateManufacured);
        let expiryData = new Date(ExpiredDate);
        if (manufatureDat.getTime() < expiryData.getTime())
            return <span className="badge badge-success" style={{fontSize: '14px'}}>Active</span>;
        else if (manufatureDat.getTime() > expiryData.getTime())
            return <span className="badge badge-danger" style={{fontSize: '14px'}}>Expired</span>;
        else
            return <span className="badge badge-warning" style={{fontSize: '14px'}}>One Day Left</span>;
    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    showTable = () => {
        try {
            return this.state.drugList.map((drug, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{drug.drugName}</td>
                        <td>{drug.categoryName}</td>
                        <td>{drug.manufacturerName}</td>
                        <td> {drug.drugPrice === "" ? "N/A" : drug.drugPrice }</td>
                        <td>{drug.drugQuantity === "" ? "N/A" : drug.drugQuantity }</td>
                        <td>{drug.drugDescription === "" ? "N/A" : this.checkExpiryData(drug.manufactureDate, drug.expiryDate) }</td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    render() {
        const header = ["S/N", "Drug Name",  "Drug Category", "Drug Manufacturer", "Drug Price", "Drug Quantity", "Status"];
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <div className="topheader">
                        <Navigator title="Expired Drugs"/>
                    </div>
                    <hr/>
                </div>

                <ErrorDialogBox
                    openDialog={this.state.openErrorDialog}
                    onSetOpenDialog={this.closeErrorDialog}
                    title="Record Not Found"
                    des="Drug record not found. Please try again!"
                />

                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="page-pretitle">
                                    </div>
                                    <h3 className="card-title">
                                        Expired Drugs List</h3>
                                </div>
                                <div className="col-auto ms-auto d-print-none">
                                    <div className="btn-list">

                                    </div>
                                </div>
                            </div>
                            <DataTable header={header} date={formatDateAndTime(currentDate(Date.now()), 'date')}
                                       body={this.showTable()} title={`Expired Drugs Report`}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredDrug);
