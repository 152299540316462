import React, { Component } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./online-consultation-form.css";

class OnlineConsultationForm extends Component {

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="">
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="patientSerial">Patient ID</label>
                            <input
                                name="patientSerial"
                                type="text"
                                className="form-control"
                                id="patientSerial"
                                disabled
                                value={this.props.patientSerial}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="appointmentName">Patient Name</label>
                            <input
                                name="appointmentName"
                                type="text"
                                className="form-control"
                                id="appointmentName"
                                disabled
                                value={this.props.patientName}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label htmlFor="consultationLink">Consultation Link <span className="text-danger">*</span></label>
                            <input
                                name="consultationLink"
                                type="text"
                                className="form-control"
                                id="consultationLink"
                                value={this.props.data.consultationLink}
                                onChange={this.props.onEdit}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="consultationDate">Consultation Date <span className="text-danger">*</span></label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    style={{
                                        padding: "0px 10px",
                                        border: "1px solid rgb(197, 197, 197)",
                                    }}
                                    name="consultationDate"
                                    className="  form-control"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    value={this.props.data.consultationDate}
                                    onChange={this.props.handleChange}
                                    autoComplete="off"
                                    format="dd/MM/yyyy"
                                    required
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="consultationTime">Consultation Time <span className="text-danger">*</span></label>
                            <input
                                name="consultationTime"
                                type="time"
                                className="form-control"
                                id="consultationTime"
                                value={this.props.data.consultationTime}
                                onChange={this.props.onEdit}
                            />
                        </div>
                    </div>
                </div>

                <button className="btn btn-success btn-block savebtn" type="submit">
                    Save
                </button>
            </form>
        );
    }
}

export default OnlineConsultationForm;
