import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import "../../base.css";
import Navigator from "../../../../Settings/navigation/navigation";
import {hospitalCode, serverLink} from "../../../../../resources/url";
import FormPrompt from "../../../../DialogBoxes/formprompt";
import { toast } from "react-toastify";
import ReportTable from "../../../../ReusableComponents/ReportTable";

function VendorItemList(props) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const columns = ["S/N", "Item Name", "Manufacturer", "Vendor", "Category", "Sub Category", "Edit"];
    const [manufacturerList, setManufacturerList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [selectedSubCategoryList, setSelectedSubCategoryList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({_id: "", itemName: "", manufacturerName: "", vendorName: "", categoryName: "", subCategoryName: "", hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})
    const [oldItemName, setOldItemName] = useState("")
    const toggleModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const toggleEditModal = () => {
        setOpenModal(true)
    }

    const closeModal = () => {
        resetFormData();
        setOpenModal(!openModal)
    }

    const resetFormData = () => {
        setOldItemName("")
        setFormData({_id: "", itemName: "", manufacturerName: "", vendorName: "", categoryName: "", subCategoryName: "", hospitalCode: hospitalCode, submittedBy: props.loginDetails.fullName})
    }

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value
        });
        if (id === 'categoryName')
            setSelectedSubCategoryList(subCategoryList.filter(i => i.categoryName === value))
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();

        if (formData.itemName === '') {
            toast.error("Please enter the item name")
            return false;
        }

        if (formData.manufacturerName === '') {
            toast.error("Please select the manufacturer")
            return false;
        }

        if (formData.categoryName === '') {
            toast.error("Please select the category")
            return false;
        }

        toast.dark("Submitting...")

        if (formData._id === "") {
            await axios.post(`${serverLink}finance/item/add`, formData, props.loginDetails.token).then(res => {
                if (res.data.message === 'success'){
                    toast.success("Item Added Successfully");
                    closeModal();
                    getData();
                }
                else if (res.data.message === 'exist')
                    toast.error("Item Already Exist")
                else
                    toast.error("Network issue. Please check your network and try again!")

            }).catch(err => {
                toast.error("Connection failure. Please check your network and try again!")
            })
        } else {
            const sendData = {
                ...formData,
                oldItemName: oldItemName
            }
            await axios.patch(`${serverLink}finance/item/update`, sendData, props.loginDetails.token).then(res => {
                if (res.data.message === 'success') {
                    toast.success("Item Updated Successfully")
                    closeModal();
                    getData();
                }
                else if (res.data.message === 'exist')
                    toast.error("Item Already Exist")
                else
                    toast.error("Network issue. Please check your network and try again!")

            }).catch(err => {
                toast.error("Connection failure. Please check your network and try again!")
            })
        }

    }

    const getData = async () => {
        let sub_category_list = []
        //SUB CATEGORY LIST
        await axios.get(`${serverLink}finance/item/category/sub/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            setSubCategoryList(res.data)
            sub_category_list = res.data
        }).catch(error => {
            console.log("ERROR FETCHING SUB CATEGORY")
        })

        //ITEM LIST
        await axios.get(`${serverLink}finance/item/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            let rows = []
            if (res.data.length > 0) {
                res.data.map((r, index) => {
                    rows.push([(index+1), r.itemName, r.manufacturerName, r.vendorName, r.categoryName, r.subCategoryName,
                        <button className={"btn btn-sm btn-info"} onClick={() => handleOnEdit(r, sub_category_list)}>Edit</button>])
                })
            }
            setData(rows)
            setIsLoading(false)
        }).catch(error => {
            console.log("ERROR FETCHING ITEMS")
        })

        //CATEGORY LIST
        await axios.get(`${serverLink}finance/item/category/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            setCategoryList(res.data)
        }).catch(error => {
            console.log("ERROR FETCHING CATEGORY")
        })

        //MANUFACTURER LIST
        await axios.get(`${serverLink}finance/manufacturer/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            setManufacturerList(res.data)
        }).catch(error => {
            console.log("ERROR FETCHING MANUFACTURERS")
        })

        //VENDOR LIST
        await axios.get(`${serverLink}finance/vendor/all/${hospitalCode}`, props.loginDetails.token).then(res => {
            setVendorList(res.data)
        }).catch(error => {
            console.log("ERROR FETCHING VENDORS")
        })

    }

    useEffect(() => {
        getData();
    },[])

    const handleOnEdit = (dt, sub_category_list) => {
        setSelectedSubCategoryList(sub_category_list.filter(i => i.categoryName === dt.categoryName))
        setFormData({
            ...formData,
            _id: dt._id,
            manufacturerName: dt.manufacturerName,
            categoryName: dt.categoryName,
            itemName: dt.itemName,
            subCategoryName: dt.subCategoryName,
            vendorName: dt.vendorName
        })
        setOldItemName(dt.itemName)
        toggleEditModal();

    }


    return isLoading ? (<div className="baselistpage">
        <i className="fas fa-spinner fa-pulse fa-2x "/>
    </div>) : (
        <div className="baselistpage">
            <FormPrompt
                openDialog={openModal}
                title="Manage Item Form"
                onSetOpenDialog={closeModal}
                isCloseBtnAppear={true}
            >
                <form onSubmit={onSubmitForm}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="itemName">Enter Item Name</label>
                                <input type="text" className="form-control" id="itemName" value={formData.itemName} onChange={onEdit}/>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="manufacturerName">Select Manufacturer</label>
                                <select id="manufacturerName" className="form-control" onChange={onEdit} value={formData.manufacturerName}>
                                    <option value=""></option>
                                    {
                                        manufacturerList.length > 0 && manufacturerList.map(item => {
                                            return <option key={item._id} value={item.manufacturerName}>{item.manufacturerName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="vendorName">Select Vendor</label>
                                <select id="vendorName" className="form-control" onChange={onEdit} value={formData.vendorName}>
                                    <option value=""></option>
                                    {
                                        vendorList.length > 0 && vendorList.map(item => {
                                            return <option key={item._id} value={item.vendorName}>{item.vendorName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="categoryName">Select Category</label>
                                <select id="categoryName" className="form-control" onChange={onEdit} value={formData.categoryName}>
                                    <option value=""></option>
                                    {
                                        categoryList.length > 0 && categoryList.map(item => {
                                            return <option key={item._id} value={item.categoryName}>{item.categoryName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="subCategoryName">Select Sub Category</label>
                                <select id="subCategoryName" className="form-control" onChange={onEdit} value={formData.subCategoryName}>
                                    <option value=""></option>
                                    {
                                        selectedSubCategoryList.length > 0 && selectedSubCategoryList.map(item => {
                                            return <option key={item._id} value={item.subCategoryName}>{item.subCategoryName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary btn-block">Submit</button>


                    </div>
                </form>
            </FormPrompt>
            <div className="main_section">
                <div className="topheader">
                    <Navigator title="Inventory Item" />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap"/>
                    <button type="button" className="btn btn-warning" onClick={toggleModal}>  + Add Item </button>
                </div>
                <ReportTable columns={columns} data={data} title={"Inventory Item List"} height={"600px"} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(VendorItemList);
