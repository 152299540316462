import React, {Component} from "react";
import "./updatedrug.css";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import DrugForm from "../drugform/drugform";
import { serverLink, hospitalCode } from "../../../../resources/url";
import { connect } from "react-redux";


class UpdateDrug extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink,
            hospitalCode: hospitalCode,
            isLoading: false,

            formData: {
                id: this.props.drugData._id,
                drugName: this.props.drugData.drugName,
                oldDrugName: this.props.drugData.drugName,
                categoryName: this.props.drugData.categoryName,
                subCategoryName: this.props.drugData.subCategoryName,
                subSubCategoryName: this.props.drugData.subSubCategoryName,
                manufacturerName: this.props.drugData.manufacturerName,
                manufactureDate: this.props.drugData.manufactureDate,
                drugStockPrice: this.props.drugData.drugStockPrice,
                drugPrice: this.props.drugData.drugPrice,
                drugQuantity: this.props.drugData.drugQuantity,
                packSize: this.props.drugData.packSize,
                drugDescription: this.props.drugData.drugDescription,
                expiryDate: this.props.drugData.expiryDate,
                updatedBy: ""
            },

            drugCategoryList: [],
            drugSubCategoryList: [],
            drugSubSubCategoryList: [],
            manufacturerList: [],
            limit: 100,
            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.setState({
            updatedBy: this.props.loginDetails.fullName
        });
        this.fetchDrugCategory();
        this.fetchDrugSubCategory();
        this.fetchDrugSubSubCategory();
        this.fetchManufacturer(this.state.limit);
    }

    async fetchDrugCategory() {
        await fetch(`${this.state.serverLink}pharmacy/drug/category/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugCategoryList: data,
                });
            });
    }

    async fetchDrugSubCategory() {
        await fetch(`${this.state.serverLink}pharmacy/drug/category/sub/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugSubCategoryList: data,
                });
            });
    }

    async fetchDrugSubSubCategory() {
        await fetch(`${this.state.serverLink}pharmacy/drug/category/sub/sub/all/${this.state.hospitalCode}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    drugSubSubCategoryList: data,
                });
            });
    }


    async fetchManufacturer(limit) {
        await fetch(`${this.state.serverLink}pharmacy/manufacturer/all/${this.state.hospitalCode}/${limit}`, this.props.loginDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    manufacturerList: data,
                });
            });
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateDrug();
    };

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    async onUpdateDrug() {
        const sendData = {
            ...this.state.formData,
            updatedBy: this.state.updatedBy
        };

        if(this.state.formData.drugName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Drug Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.categoryName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select Drug Category"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.drugStockPrice === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Price"
            this.handleErrorDialog();
            return false;
        }


        if(this.state.formData.drugPrice === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Sales Price"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.drugQuantity === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Drug Quantity"
            this.handleErrorDialog();
            return false;
        }



        axios.patch(`${this.state.serverLink}pharmacy/drug/update`, sendData, this.props.loginDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "DRUG UPDATED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Something went wrong updating your record.";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = "Request not submitted. Check your console for more info"
                this.handleErrorDialog();
                console.error('ERROR', err);
            });
    }


    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };

    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/pharmacy/drug/list';
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Drug Updated"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <DrugForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Drug"
                                manufacturerList={this.state.manufacturerList}
                                drugCategoryList={this.state.drugCategoryList}
                                drugSubCategoryList={this.state.drugSubCategoryList}
                                drugSubSubCategoryList={this.state.drugSubSubCategoryList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        drugData: state.personDetails,
        loginDetails: state.loginDetails
    };
};
export default connect(mapStateToProps, null)(UpdateDrug);
