import React, {useEffect, useState} from "react";
import "./dashboard.css";
import {serverLink, hospitalCode} from "../../resources/url";
import {connect} from "react-redux";
import Chart from "react-google-charts";
import {imageExists} from "../../resources/constants";
import axios from 'axios';
import doctor from '../../Images/doctor.jpg'
import woman_doctor from '../../Images/woman-doctor.jpg'
import MenuIcon from "./utils/menu-icon";
import {Link} from "react-router-dom";
import {FaCalendarCheck, FaCalendarPlus, FaHospitalUser, FaUserInjured} from "react-icons/fa";
const DoctorsDashboard = (props) => {
    const {doctorDetails} = props
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [appointmentStatus, setAppointmentStatus] = useState([])
    const [admissionStatus, setAdmissionStatus] = useState([])
    const [imagingStatus, setImagingStatus] = useState([])
    const [labStatus, setLabStatus] = useState([])
    const [inpatient, setInpatient] = useState(0)
    const [completedAppointment, setCompletedAppointment] = useState(0)
    const [upcomingAppointment, setUpcomingAppointment] = useState(0)
    const [referrals, setReferrals] = useState(0)

    useEffect(() => {
        fetchDoctorDetails();
    }, []);

    const fetchDoctorDetails = async () => {
        const userId = doctorDetails.userID;
        try {
            const response = await axios.get(`${serverLink}doctor/profile/${hospitalCode}/${userId}`, doctorDetails.token);
            const dashboard_response = await axios.get(`${serverLink}report/doctor/dashboard/${userId}/${hospitalCode}`, doctorDetails.token);
            const data = response.data;
            const appointment = dashboard_response.data.appointments;
            const admission = dashboard_response.data.admission;
            const lab = dashboard_response.data.lab;
            const imaging = dashboard_response.data.imaging;
            const prescription = dashboard_response.data.prescription;
            let appointment_status = ['Scheduled', 'Checked In', 'Vital Taken', 'Completed', 'Missed'];
            let admission_status = ['Active', 'Discharged'];
            let imaging_status = ['Pending', 'Result Entered', 'Cancelled'];
            let lab_status = ['Requested', 'Specimen Taken', 'Result Confirmed', 'Result Entered', 'Result Rejected', 'Cancelled'];

            //Set Count
            setInpatient(admission.filter(item => item.status === 'active').length)
            setCompletedAppointment(appointment.filter(item => item.appointmentStatus === 'Completed').length)
            setUpcomingAppointment(appointment.filter(item => item.appointmentStatus === 'Scheduled' || item.appointmentStatus === 'Checked In' || item.appointmentStatus === 'Vital Taken').length)

            //Initialize Data
            let appointmentStatusData = [];
            let admissionStatusData = [];
            let imagingStatusData = [];
            let labStatusData = [];

            //Appointment trend by status
            appointment_status.map((e, i) => {
                let count = appointment.filter(item => item.appointmentStatus === e).length
                appointmentStatusData.push([e, count]);
            })

            //Admission trend by status
            admission_status.map((e, i) => {
                let count = admission.filter(item => item.status === e.toLowerCase()).length
                admissionStatusData.push([e, count]);
            })

            //Imaging trend by status
            imaging_status.map((e, i) => {
                let count = imaging.filter(item => item.status === e.toLowerCase()).length
                imagingStatusData.push([e, count]);
            })

            //Lab trend by status
            lab_status.map((e, i) => {
                let count = lab.filter(item => item.testStatus === e.toLowerCase()).length
                labStatusData.push([e, count]);
            })


            setUser(data)

            //Set Chart Data
            setAppointmentStatus(appointmentStatusData)
            setAdmissionStatus(admissionStatusData)
            setImagingStatus(imagingStatusData)
            setLabStatus(labStatusData)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching doctor details:', error);
        }
    }


    const appointmentAnalyticsData = [["Status", "Count"], ...appointmentStatus];
    const appointmentAnalyticsOptions = {
        title: "Appointment Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const admissionAnalyticsData = [["Status", "Count"], ...admissionStatus];
    const admissionAnalyticsOptions = {
        title: "Admission Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const imagingAnalyticsData = [["Status", "Count"], ...imagingStatus];
    const imagingAnalyticsOptions = {
        title: "Imaging Request By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const labAnalyticsData = [["Status", "Count"], ...labStatus];
    const labAnalyticsOptions = {
        title: "Lab Request By Status",
        pieHole: 0.3,
        is3D: false,
    };


    return (
        loading ? (
                <div className="dashboardpage">
                    <i className="fas fa-spinner fa-pulse fa-2x "/>
                </div>
            ) :
            <div className="dashboardpage">
                <div className="topheader">
                    <ul>
                        <li>
                            <i
                                className="fa fa-dashboard fa-2x"
                                aria-hidden="true"
                            />
                        </li>
                        <li>
                            <span>Dashboard</span>
                        </li>
                    </ul>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="card bg-gradient" style={{backgroundColor: '#f6fbfc'}}>
                            <div className="card-body">
                                <center className="m-t-30">
                                    <img
                                        src={imageExists(`${serverLink}public/uploads/users_uploads/${user.passport}`) ? `${serverLink}public/uploads/users_uploads/${user.passport}` : user.gender === 'Male' ? doctor : woman_doctor}
                                        alt="passport"
                                        className="rounded-circle border mb-3"
                                        width="150"
                                        height="150"
                                    />
                                    <h5 className="card-title m-t-10">{user.firstName} {user.middleName} {user.surname}</h5>
                                    <h5 className="card-subtitle font-weight-bold text-dark">{user.userID}</h5>
                                    <Link to={"/website/profile"} className="mt-2">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm mb-2"
                                        >
                                            Update Profile <i className="fa fa-edit" aria-hidden="true"/>
                                        </button>
                                    </Link>
                                </center>
                                <div>
                                    <hr />
                                </div>
                                <div className="">
                                    <small className="text-muted">Gender</small>
                                    <h6>{user.gender}</h6>
                                    <hr/>
                                    <small className="text-muted">Role </small>
                                    <h6>{user.role}</h6>
                                    <hr/>
                                    <small className="text-muted">Specialisation</small>
                                    {
                                        user.specialisation.length > 0 ?
                                            <ul>

                                                {user.specialisation.map((value, key) => (
                                                    <li key={key} className="text-secondary text-left"> {value}</li>
                                                ))}
                                            </ul>
                                            : 'N/A'
                                    }
                                    <hr/>
                                    <small className="text-muted p-t-30 db">Phone</small>
                                    <h6>{user.phoneNumber}</h6>
                                    <hr/>
                                    <small className="text-muted">Email Address </small>
                                    <h6>{user.emailAddress ?? "N/A"}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="row first_section" style={{marginTop: '-30px'}}>
                            <div className="col-md-3">
                                    <MenuIcon name="Inpatients on Treatment" icon={<FaHospitalUser size={40}/>} count={inpatient}/>
                            </div>
                            <div className="col-md-3">
                                    <MenuIcon name="Completed Appointments" icon={<FaCalendarCheck size={40}/>} count={completedAppointment}/>
                            </div>
                            <div className="col-md-3">
                                    <MenuIcon name="Upcoming Appointments" icon={<FaCalendarPlus size={40}/>} count={upcomingAppointment}/>
                            </div>
                            <div className="col-md-3">
                                    <MenuIcon name="Referrals" icon={<FaUserInjured size={40}/>} count={referrals}/>
                            </div>

                            {/*Chart Section*/}
                            <div className="col-md-6 mb-3 mt-3">
                                <div className="card" style={{borderRadius: '10px'}}>
                                    <div className="card-body">
                                        {
                                            appointmentStatus.length > 0 &&
                                            <Chart
                                                chartType="PieChart"
                                                width="100%"
                                                height="310px"
                                                data={appointmentAnalyticsData}
                                                options={appointmentAnalyticsOptions}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3 mt-3">
                                <div className="card" style={{borderRadius: '10px'}}>
                                    <div className="card-body">
                                        {
                                            admissionStatus.length > 0 &&
                                            <Chart
                                                chartType="PieChart"
                                                width="100%"
                                                height="310px"
                                                data={admissionAnalyticsData}
                                                options={admissionAnalyticsOptions}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="card" style={{borderRadius: '10px'}}>
                                    <div className="card-body">
                                        {
                                            imagingStatus.length > 0 &&
                                            <Chart
                                                chartType="PieChart"
                                                width="100%"
                                                height="310px"
                                                data={imagingAnalyticsData}
                                                options={imagingAnalyticsOptions}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="card" style={{borderRadius: '10px'}}>
                                    <div className="card-body">
                                        {
                                            labStatus.length > 0 &&
                                            <Chart
                                                chartType="PieChart"
                                                width="100%"
                                                height="310px"
                                                data={labAnalyticsData}
                                                options={labAnalyticsOptions}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(DoctorsDashboard);
