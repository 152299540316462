import React, {Component, useEffect, useState} from "react";
import "./serviceuserpage.css";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import Navigator from "../../Settings/navigation/navigation";
import axios from "axios";
import {serverLink} from "../../../resources/url";

function ServiceProfilePage (props) {
    let page_url = window.location.pathname;
    let page_id = page_url.split('/');
    let id = page_id[3];
    const [service, setService] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const getServiceRecord = async () => {
        await axios.get(`${serverLink}service/service/single/${id}`, props.doctorDetails.token)
            .then(res => {
                const data = res.data;
                if (data.length === 0) {
                    window.location.href = '/';
                }
                setService(data[0]);
                setIsLoading(false);
            })
            .catch(err => {
                window.location.href = '/';
            })
    }
    useEffect(() => {
        getServiceRecord()
    },[])

    return isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        )
        :(
        <div className="dashboardpage">
            <div className="topheader">
                <Navigator title={service.serviceName} />
            </div>
            <div className="first_section">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <Link to={`/services/request/pending/${id}`}>
                            <DashboardCard name="New Request" icon={`fa fa-cog fa-4x`} count="" />
                        </Link>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <Link to={`/services/request/history/${id}`}>
                            <DashboardCard name="Request History" icon={`fa fa-cog fa-4x`} count="" />
                        </Link>
                    </div>

                </div>

            </div>
        </div>)
}


const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(ServiceProfilePage);
