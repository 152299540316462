import React, { Component } from "react";
import { hospitalCode, serverLink } from "../../../../resources/url";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import "./roomsundermaintenance.css";
import RoomListTable from "../utils/roomlisttable";
import Navigator from "../../../Settings/navigation/navigation";
import {connect} from "react-redux";

class RoomsUnderMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"hospital_room/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 20,

            isLoadMoreData: false,

            totalNumOfRooms: null,
            noMoreDataText: "",

            roomData: [],

            isSearching: false,
            isSearchDataShow: false,

            selectedRoom: "",

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchRooms();
    }

    async fetchRooms() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    roomData: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    showMore = () => {
        if (this.state.limit <= this.state.totalNumOfRooms) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchRooms();
        } else {
            this.setState({
                noMoreDataText: "No More Rooms",
            });
        }
    };

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase();

            await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            roomData: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });
        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };



    render() {
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <ConfirmDialogBox
                        openDailog={this.state.openConfirmDialog}
                        onSetOpenDailog={this.closeConfirmDialog}
                        handleConfirmOkBtn={this.deleteSpecialisation}
                        isLoading={this.state.isDeleting}
                        title="Delete"
                        des={
                            "Are you sure you want to delete " +
                            this.state.selectedRoom +
                            " " +
                            "details"
                        }
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Room not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Rooms Under maintenance" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search room by name"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.roomData.length}{" "}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <RoomListTable isSearching={this.state.isSearching} roomData={this.state.roomData.filter((filter) => filter.status === 'under maintenance' )}/>
                    <div className="loadmoredatasection">
                        {this.state.isLoadMoreData ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"></i>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.roomData.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMore()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails
    };
};

export default connect(mapStateToProps, null)(RoomsUnderMaintenance);
