import React, { Component } from "react";
import "./testtemplate";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import { serverLink, hospitalCode } from "../../../../resources/url";
import FormPrompt from "../../../DialogBoxes/formprompt";
import axios from "axios";
import Navigator from "../../../Settings/navigation/navigation";
import {connect} from "react-redux";

class LabTestResultTemplate extends Component {
    constructor(props) {
        super(props);
        let page_url = window.location.pathname;
        let page_id = page_url.split('/');
        let test_id = page_id[5];
        this.state = {
            serverLink: serverLink+"laboratory/",
            hospitalCode: hospitalCode,
            testID: test_id,
            isLoading: true,
            testData: "",
            selectedItem: "",
            openItemDialog: false,
            openDeleteItemDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false,
            isCloseDialogAppear: false,
            itemPosition: 30,
            submitMessage: "",
            isSubmitMessage: ""
        };

    }
    componentDidMount() {
        this.fetchLabTest();
    }

    async fetchLabTest() {
        await fetch(`${this.state.serverLink}test/test_data/${this.state.testID}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                if (data.testName) {
                    data.testResultTemplate.sort((a,b) => (a.ItemPosition > b.ItemPosition ? 1 : -1))
                    this.setState({
                        testData: data,
                        isLoading: false,
                        openItemDialog: false,
                        submitMessage: "",
                        isSubmitMessage: "",
                    });
                } else {
                    window.location.href = '/lab/test/list'
                }

            });
    }

    onAddTemplateItem = (e) => {
        e.preventDefault();
        const formData = {
            TestID: this.state.testID,
            ItemName: document.getElementById('ItemName').value,
            ItemType: document.getElementById('ItemType').value,
            DefaultValue: document.getElementById('ItemDefaultValue').value,
            ItemPosition: parseInt(document.getElementById('ItemPosition').value),
            ItemSerial: Math.floor(100000 + Math.random() * 9000000000000000)
        }

        axios.patch(`${this.state.serverLink}test/test_data/add_template`, formData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success')
                {
                    this.setState({
                        submitMessage: "Item added to your template",
                        isSubmitMessage: "success"
                    })
                    document.getElementById("TemplateItemForm").reset();
                    this.fetchLabTest();
                }
                else
                    this.setState({
                        submitMessage: "Something went wrong adding the item to your template. Please try again!",
                        isSubmitMessage: "error"
                    })
            })
            .catch(error => {
                this.setState({
                    submitMessage: "Something went wrong connecting to the server",
                    isSubmitMessage: "error"
                })
            })
    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    DeleteItem = (item) => {
        this.setState({
            selectedItem: item,
            openDeleteItemDialog: true
        })
    }

    handleItemDeletion = () => {
        const sendData = {
            TestID: this.state.selectedItem.TestID,
            ItemSerial: this.state.selectedItem.ItemSerial
        }

        axios.patch(`${this.state.serverLink}test/test_data/remove_template`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.message === 'success') {
                    this.fetchLabTest();
                    this.closeDialog();
                }
            })
            .catch(error => {

            })

    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openDeleteItemDialog: false,
            openItemDialog: false
        });
    };

    openTemplateItemDialog = () => {
        this.setState({
            openItemDialog: true
        })
    }

    render() {

        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "></i>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <FormPrompt
                        openDialog={this.state.openItemDialog}
                        title="ADD TEMPLATE"
                        onSetOpenDialog={this.closeDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        {
                            this.state.isSubmitMessage === 'error' ? <div className="alert alert-danger">{this.state.submitMessage}</div> : ''
                        }
                        {
                            this.state.isSubmitMessage === 'success' ? <div className="alert alert-success">{this.state.submitMessage}</div> : ''
                        }

                        <form id="TemplateItemForm" onSubmit={this.onAddTemplateItem}>
                            <div className="form-row mb-3">
                                <label htmlFor="ItemName">Item Name</label>
                                <input type="text" required={true} className="form-control" id="ItemName" name="ItemName"/>
                            </div>
                            <div className="form-row mb-3">
                                <label htmlFor="ItemType">Item Type</label>
                                <select required={true} name="ItemType" id="ItemType" className="form-control">
                                    <option value="text">Short Text</option>
                                    <option value="textarea">Long Text</option>
                                    <option value="number">Number</option>
                                </select>
                            </div>
                            <div className="form-row mb-3">
                                <label htmlFor="ItemDefaultValue">Default Value</label>
                                <input type="text" className="form-control" id="ItemDefaultValue" name="ItemDefaultValue"/>
                            </div>
                            <div className="form-row mb-3">
                                <label htmlFor="ItemPosition">Item Position on Template</label>
                                <select required={true} name="ItemPosition" id="ItemPosition" className="form-control">
                                    <option value="">Select Position</option>
                                    {
                                        Array.from(Array(this.state.itemPosition), (e, i) => {
                                            return <option key={i} value={i+1}>{i+1}</option>
                                        })
                                    }

                                </select>
                            </div>

                            <div className="form-row">
                                <div className="col-md-6">
                                    <button className="btn btn-danger btn-block btn-sm" onClick={this.closeDialog}>Close</button>
                                </div>
                                <div className="col-md-6">
                                    <button className="btn btn-primary btn-block btn-sm">Submit</button>
                                </div>
                            </div>
                        </form>
                    </FormPrompt>

                    <ConfirmDialogBox
                        openDailog={this.state.openDeleteItemDialog}
                        onSetOpenDailog={this.closeDialog}
                        handleConfirmOkBtn={this.handleItemDeletion}
                        isLoading={this.state.isLoading}
                        title="Delete Item"
                        des={
                            "Are you sure to delete " +
                            this.state.selectedItem.ItemName+"?"
                        }
                    />

                    <div className="topheader">
                        <Navigator title={`${this.state.testData.testName} Result Template`} />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap"/>
                        <button
                            onClick={this.openTemplateItemDialog}
                            type="button"
                            className="btn btn-warning"
                        >
                            + Add Template Item
                        </button>
                    </div>
                    <hr/>

                    {
                        this.state.testData.testResultTemplate.length === 0 ?
                            <div className="alert alert-danger">NO TEMPLATE FOUND</div> :
                            <table className="table table-striped">
                                <thead className="thead tablehead">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Item Name</th>
                                    <th scope="col">Item Type</th>
                                    <th scope="col">Default Value</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.testData.testResultTemplate.map((temp, index) => {
                                        return (
                                            <tr key={index}>
                                                <td scope="col">{temp.ItemPosition}</td>
                                                <td scope="col">{temp.ItemName}</td>
                                                <td scope="col">{temp.ItemType}</td>
                                                <td scope="col">{temp.DefaultValue !== '' ? temp.DefaultValue : '--'}</td>
                                                <td scope="col">
                                                    <button
                                                        onClick={() => {this.DeleteItem(temp)}}
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                    >
                                                        <i className="fa fa-trash" title={`Delete ${temp.ItemName}`} aria-hidden="true"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(LabTestResultTemplate);
