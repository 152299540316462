import React, {Component} from "react";
import './admit';
import {serverLink, hospitalCode} from "../../../resources/url";
import axios from "axios";
import ChoosePayment from "../../Finance/choosepayment/choosepayment";
import FormPrompt from "../../DialogBoxes/formprompt";
import { Link } from "react-router-dom";
import {loginValidation} from "../../../context/loginvalidation";
import {connect} from "react-redux";
import Navigator from "../../Settings/navigation/navigation";

class AdmitPatient extends Component {
    constructor(props) {
        super(props);

        let page_url = window.location.pathname;
        let page_id = page_url.split('/');
        let patient_id = page_id[3];
        let room_id = page_id[4];

        this.state = {
            isLoading: true,
            roomData: [],
            patientData: [],
            patientId: patient_id,
            roomId: room_id,
            bedData: [],
            selectedBed: '',
            openPayment: false,
            isCloseDialogAppear: true,
            isAdmittingType: '',
            isAdmittingMessage: '',
            admissionID: "",
            isPatientAdmitted: ""
        }
    }

    componentDidMount() {
        this.onLoadRoomRecord();
    }

    async onLoadRoomRecord () {
        await axios.get(`${serverLink}hospital_room/room_by_id/${this.state.roomId}`, this.props.doctorDetails.token)
            .then(data => {
                const roomRecord = data.data;
                if (roomRecord.length > 0)
                {
                    this.setState({ roomData: roomRecord[0] })
                }
                else
                {
                    window.location.href = '/'
                }

            })
            .catch(err => {
                console.log("NO INTERNET CONNECTION", err)
                window.location.href = '/'
            })

        await axios.get(`${serverLink}patient/info/${hospitalCode}/${this.state.patientId}`, this.props.doctorDetails.token)
            .then(data => {
                const patientRecord = data.data;
                if (typeof patientRecord.walletAmount !== 'undefined')
                {
                    this.setState({ patientData: patientRecord })
                }
                else
                {
                    window.location.href = '/'
                }
            })
            .catch(err => {
                console.log("NO INTERNET CONNECTION", err)
                window.location.href = '/'
            })

        await axios.get(`${serverLink}hospital_bed/room/beds/available/${this.state.roomId}`, this.props.doctorDetails.token)
            .then(data => {
                const bedRecord = data.data;
                this.setState({ bedData: bedRecord  })
            })
            .catch(err => {
                console.log("NO INTERNET CONNECTION", err)
                window.location.href = '/'
            })

        await axios.get(`${serverLink}patient_admission/patient/active/${hospitalCode}/${this.state.patientData.patientSerial}`, this.props.doctorDetails.token)
            .then(result => {
                if (result.data.length > 0) {
                    this.setState({isPatientAdmitted: result.data[0]._id})
                }
                this.setState({isLoading: false})
            })
            .catch(err => {
                console.log('FAILED TO LOAD ACTIVE PATIENT DATA', err)
            })

    }

    moneyFormat = (amount) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });

        return formatter.format(amount);
    }

    onSelectBed = (bed) => {
        this.setState({ selectedBed: bed })
    }

    handlePaymentResponse = async () => {
        this.setState({
            isAdmittingType: '',
            isAdmittingMessage: ''
        })

        const sendData = {
            patientName: `${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`,
            patientSerial: this.state.patientData.patientSerial,
            blockName: this.state.roomData.blockName,
            roomName: this.state.roomData.roomName,
            bedName: this.state.selectedBed.bedName,
            roomAmount: this.state.roomData.roomAmount,
            paymentStatus: 'paid',
            hospitalCode: hospitalCode,
            submittedBy: localStorage.getItem('userID')
        }
        await axios.post(`${serverLink}patient_admission/add`, sendData, this.props.doctorDetails.token)
            .then(result => {
                if (result.data[0].message === 'success') {
                    this.setState({
                        isAdmittingType: 'success',
                        isAdmittingMessage: 'Patient admission record saved. You will be redirected to patient profile. Please wait!!!',
                        admissionID: result.data[0].admissionID
                    })
                } else {
                    this.setState({
                        isAdmittingType: 'error',
                        isAdmittingMessage: 'Something went wrong admitting patient. Please check your network connection and try again!!!'
                    })
                    console.log('error')
                }
            })
            .catch(err => {
                console.log('INTERNET CONNECTION TO THE API ISSUE', err)
            })

        this.onUpdateAdmissionSettings()

        this.closePaymentDialog();
    }

    onUpdateAdmissionSettings = async () => {
        if (this.state.admissionID !== '')
        {
            const sendUpdate = {
                patientName: `${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`,
                patientSerial: this.state.patientData.patientSerial,
                blockName: this.state.roomData.blockName,
                roomName: this.state.roomData.roomName,
                bedName: this.state.selectedBed.bedName,
                roomID: this.state.roomData._id,
                hospitalCode: hospitalCode,
                admissionID: this.state.admissionID,
            }

            await axios.patch(`${serverLink}patient_admission/update_settings`, sendUpdate, this.props.doctorDetails.token)
                .then(data => {
                    if (data.data.message === 'success') {
                        this.setState({
                            isAdmittingType: 'success',
                            isAdmittingMessage: 'Patient admission record saved. You will be redirected to patient profile. Please wait!!!'
                        })
                        const object = this;
                        setTimeout(function () {
                            window.location.href = `/admission/profile/${object.state.admissionID}`
                        }, 3000)
                    } else
                        this.setState({
                            isAdmittingType: 'error',
                            isAdmittingMessage: 'Error updating patient admission settings'
                        })
                })
                .catch(err => {
                    console.log('INTERNET CONNECTION ERROR ON SETTINGS UPDATE', err)
                })
        }
    }

    openPaymentDialog = () => {
        this.setState({ openPayment: true })
    }

    closePaymentDialog = () => {
        this.setState({ openPayment: false })
    }

    render() {
        return this.state.isLoading ? (
            <div className="admissionpage">
                <i className="fas fa-spinner fa-pulse fa-2x" />
            </div>
        ) :
            (
                <div className="basepage">
                    <FormPrompt
                        style={{ position: 'relative', zIndex: '3' }}
                        openDialog={this.state.openPayment}
                        title="Admit Patient"
                        onSetOpenDialog={this.closePaymentDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <ChoosePayment
                            patientID={this.state.patientData.patientSerial}
                            patientName={`${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`}
                            walletAmount={this.state.patientData.walletAmount}
                            MoneyConverter={this.moneyFormat}
                            paymentFor="Admission"
                            itemID={this.state.selectedBed._id}
                            itemName={`${this.state.roomData.blockName}/${this.state.roomData.roomName}/${this.state.selectedBed.bedName}`}
                            itemAmount={this.state.roomData.roomAmount}
                            handlePaymentSuccess={this.handlePaymentResponse}
                        />
                    </FormPrompt>
                    <div className="main_section">
                        <div className="topheader">
                            <Navigator title="Admission Page" />
                        </div>
                        <hr/>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h4>Patient Details</h4>
                                        <table className="table table-bordered table-responsive-md">
                                            <tbody>
                                            <tr>
                                                <th>Patient ID</th>
                                                <td>{this.state.patientData.patientSerial ? this.state.patientData.patientSerial : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Patient Name</th>
                                                <td>
                                                    {
                                                        this.state.patientData.patientSerial ?
                                                            `${this.state.patientData.firstName} ${this.state.patientData.middleName} ${this.state.patientData.surname}`
                                                            : 'N/A'
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Phone Number</th>
                                                <td>{this.state.patientData.phoneNumber ? this.state.patientData.phoneNumber : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Blood Group</th>
                                                <td>{this.state.patientData.bloodGroup ? this.state.patientData.bloodGroup : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Next of Kin</th>
                                                <td>
                                                    Name: {this.state.patientData.nextOfKinName ? this.state.patientData.nextOfKinName : 'N/A'}
                                                    <br/>
                                                    Phone: {this.state.patientData.nextOfKinPhone ? this.state.patientData.nextOfKinPhone : 'N/A'}
                                                    <br/>
                                                    Relationship: {this.state.patientData.nextOfKinRelationship ? this.state.patientData.nextOfKinRelationship : 'N/A'}

                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Wallet Balance</th>
                                                <td>{ this.moneyFormat(this.state.patientData.walletAmount) }</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h4>Room Details</h4>
                                        <table className="table table-bordered table-responsive-md">
                                            <tbody>
                                            <tr>
                                                <th>Room Number</th>
                                                <td>{this.state.roomData.roomName ? this.state.roomData.roomName : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Room Type</th>
                                                <td>
                                                    {
                                                        this.state.roomData.patientSerial ? this.state.roomData.typeName : 'N/A'
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Block</th>
                                                <td>{this.state.roomData.blockName ? this.state.roomData.blockName : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Extension No</th>
                                                <td>{this.state.roomData.extensionNo ? this.state.roomData.extensionNo : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Bed Capacity</th>
                                                <td>{this.state.roomData.bedCapacity ? this.state.roomData.bedCapacity : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>No of Beds Available</th>
                                                <td>{this.state.roomData.bedCapacity ? this.state.roomData.bedCapacity - this.state.roomData.bedOccupied : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <th>Room Amount</th>
                                                <td>{ this.moneyFormat(this.state.roomData.roomAmount) } /Day</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <hr/>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h4>Beds</h4>
                                        {
                                            this.state.bedData.length > 0 ?
                                                <table className="table table-striped table-responsive-md">
                                                    <thead>
                                                    <tr>
                                                        <th>S/N</th>
                                                        <th>Bed Number</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.bedData.map((bed, index) => {
                                                            return <tr key={index}>
                                                                <td>{(index+1)}</td>
                                                                <td>{bed.bedName}</td>
                                                                <td>
                                                                    {
                                                                        this.state.isPatientAdmitted === '' ?
                                                                            <button className="btn btn-primary btn-sm" onClick={() => this.onSelectBed(bed)}> <i className="fa fa-bed"/> Admin Patient</button>
                                                                            : '--'

                                                                    }
                                                                </td>
                                                            </tr>
                                                        })
                                                    }

                                                    <tr>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                                : <div className="alert alert-danger">NO BED AVAILABLE FOR THE SELECTED ROOM</div>

                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h4>Admit Patient</h4>
                                        {
                                            this.state.isPatientAdmitted === '' ?
                                                this.state.selectedBed === '' ?
                                                    <div className="alert alert-warning">SELECT BED TO ADMIT PATIENT</div> :
                                                    <table className="table table-striped table-responsive-md">
                                                        <tbody>
                                                        <tr>
                                                            <th>Room Name</th>
                                                            <td>{this.state.roomData.roomName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Room Type</th>
                                                            <td>{this.state.roomData.typeName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Block Name</th>
                                                            <td>{this.state.roomData.blockName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Bed Number</th>
                                                            <td>{this.state.selectedBed.bedName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Amount Due</th>
                                                            <td>{this.moneyFormat(this.state.roomData.roomAmount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Action</th>
                                                            <td>
                                                                <button className="btn btn-success btn-block" onClick={this.openPaymentDialog}>Admit Now</button>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table> :

                                                <Link to={`/admission/profile/${this.state.isPatientAdmitted}`} style={{ textDecoration: 'none' }}>
                                                    <button className="btn btn-success btn-block" onClick={this.openPaymentDialog}>Patient Already Admitted</button>
                                                </Link>
                                        }

                                        {
                                            this.state.isAdmittingType !== '' ?
                                                <div className={
                                                    this.state.isAdmittingType === 'error' ? 'alert alert-danger' : 'alert alert-success'
                                                }>{this.state.isAdmittingMessage}</div>
                                                : ''
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
    }

}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(AdmitPatient);
