import React, { Component } from "react";
import "./submenulist.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    setpersonDetails
} from "../../../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import ConfirmDialogBox from "../../../../DialogBoxes/confirmDialogbox";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import Navigator from "../../../navigation/navigation";

class SubMenuList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink+"settings/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 20,

            subMenuList: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedSubMenuName: "",

            openErrorDialog: false,
            openConfirmDialog: false,
            isDeleting: false
        };
    }
    componentDidMount() {
        this.fetchSubMenu();
    }

    async fetchSubMenu() {
        this.setState({ isLoadMoreData: true });
        await fetch(`${this.state.serverLink}sub_menu/all/${this.state.hospitalCode}`, this.props.loginData.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    subMenuList: data,
                    isLoadMoreData: false,
                    isLoading: false,
                });
            });
    }

    showMoreSubmenu = () => {
        if (this.state.limit <= this.state.totalNumOfLabs) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchSubMenu(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Record",
            });
        }
    };


    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase();

            await fetch(`${this.state.serverLink}sub_menu/search/${this.state.hospitalCode}/${searchText}`, this.props.loginData.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            subMenuList: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };


    render() {
        let count = 0;
        return this.state.isLoading ? (
            <div className="baselistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="baselistpage">
                <div className="main_section">
                    <ConfirmDialogBox
                        openDailog={this.state.openConfirmDialog}
                        onSetOpenDailog={this.closeConfirmDialog}
                        handleConfirmOkBtn={this.deleteSpecialisation}
                        isLoading={this.state.isDeleting}
                        title="Delete"
                        des={
                            "Are you sure to delete " +
                            this.state.selectedSubMenuName +
                            " " +
                            "details"
                        }
                    />

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Sub menu record not found. Please try again!"
                    />

                    <div className="topheader">
                        <Navigator title="Sub Menu List" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search submenu by name"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"/>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.subMenuList.length}{" "}
                                </li>
                            </ul>
                        </div>

                        <Link to="/settings/submenu/add">
                            <button
                                type="button"
                                className="btn btn-warning"
                            >
                                + Add Menu
                            </button>
                        </Link>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Sub Menu Name</th>
                            <th scope="col">Main Menu Name</th>
                            <th scope="col">Sub Menu Link</th>
                            <th scope="col">Sub Menu Icon</th>
                            <th scope="col">Option</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "/>
                        ) : this.state.subMenuList.length === 0 ? (
                            <tbody>
                            <tr>
                                <td colSpan={6} className="text-center">No Sub Menu Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.subMenuList &&
                            this.state.subMenuList.map((menu) => {
                                count++;
                                return (
                                    <tr key={menu._id}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {menu.subMenuName}
                                        </td>
                                        <td className="align-middle">
                                            {menu.mainMenuName}
                                        </td>
                                        <td className="align-middle">
                                            {menu.subMenuLink}
                                        </td>
                                        <td className="align-middle">
                                            {menu.subMenuIcon}
                                        </td>
                                        <td className="align-middle">
                                            <Link to="/settings/submenu/update">
                                                <button
                                                    onClick={async () => {
                                                        const subMenuData = {
                                                            ...menu,
                                                            subMenuId: menu._id,
                                                        };
                                                        this.props.setOnPatientDetails(subMenuData);
                                                    }}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    <i className="fa fa-edit" aria-hidden="true"/>
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>

                    <div className="loadmoredatasection">
                        {this.state.isLoadMoreData ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"/>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.subMenuList.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMoreSubmenu()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        loginData: state.loginDetails
    };
};
export default connect(mapStateToProps, mapDisptachToProps)(SubMenuList);
