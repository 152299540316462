import React, {Component} from "react";
import { serverLink, hospitalCode} from "../../../resources/url";

class PatientOtherInfo extends Component {
    constructor() {
        super();
        this.state = {
            serverLink: serverLink,
        }
    }

    render() {
        let otherInfo = [];
        if (typeof(this.props.otherInfo.otherInfo) === 'undefined') {
             otherInfo = [];
        }else if (this.props.otherInfo.otherInfo.length > 0){
             otherInfo = this.props.otherInfo.otherInfo[0];
        }else{
             otherInfo = [];
        }


        return <div className="card mb-3">
            <div className="card-header">
                Patient Other Information
            </div>
            <div className="card-body">
                {
                    otherInfo.length < 1  ? <h4 className="text-center">No record found</h4> :
                        <div className="row">
                            <div className="col-md-12">
                                <h4 style={{color: '#11283f', fontWeight: 'bold'}}>Family History</h4>
                                <hr/>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Family Type</th>
                                        <th>No. of Children Family Have</th>
                                        <th>Position In The Family</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{otherInfo.familyType}</td>
                                        <td>{otherInfo.parentChildren}</td>
                                        <td>{otherInfo.positionInFamily}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4 style={{color: '#11283f', fontWeight: 'bold'}}>Social History</h4>
                                <hr/>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Activities</th>
                                        <th>Exercise</th>
                                        <th>Life Style</th>
                                        <th>Leisure Time</th>
                                        <th>Smoking</th>
                                        <th>Alcohol</th>
                                        <th>Diet</th>
                                        <th>Hobby</th>
                                        <th>Education Level</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{otherInfo.activities}</td>
                                        <td>{otherInfo.exercise}</td>
                                        <td>{otherInfo.lifestyle}</td>
                                        <td>{otherInfo.leisureTime}</td>
                                        <td>{otherInfo.smoking}</td>
                                        <td>{otherInfo.alcohol}</td>
                                        <td>{otherInfo.diet}</td>
                                        <td>{otherInfo.hobbies}</td>
                                        <td>{otherInfo.educationLevel}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4 style={{color: '#11283f', fontWeight: 'bold'}}>Previous Medical History</h4>
                                <hr/>
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Have you had any medical condition before?</th>
                                        <th>Have you ever been admitted in the hospital?</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{otherInfo.medicalCondition}</td>
                                        <td>{otherInfo.previousAdmission}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
            </div>
        </div>
    }
}

export default PatientOtherInfo;
