import React, {Component, useEffect} from "react";
import "./addsubmenu.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorDialogBox from "../../../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../../../DialogBoxes/alertdialogbox";
import { serverLink, hospitalCode } from "../../../../../resources/url";
import AddSubMenuForm from "../addsubmenuform/addsubmenuform";
import {connect} from "react-redux";


class AddSubMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"settings/",
            hospitalCode: hospitalCode,
            isLoading: false,
            mainMenuList: [],

            formData: {
                subMenuName: "",
                menuName: "",
                subMenuIcon: "",
                SubMenuLink: "",
                hospitalCode: hospitalCode,
                submittedBy: this.props.loginData.userID
            },

            messageDescription: "",
            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.fetchMainMenu();
    }

    async fetchMainMenu() {
        await fetch(`${this.state.serverLink}main_menu/all/${this.state.hospitalCode}`, this.props.loginData.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    mainMenuList: data,
                });
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onAddSubMenu();
    };

    async onAddSubMenu() {
        let sendData = {
            ...this.state.formData
        };

        if(this.state.formData.subMenuName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter Menu Name"
            this.handleErrorDialog();
            return false;
        }

        axios.post(`${this.state.serverLink}sub_menu/add`, sendData, this.props.loginData.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if(response === "sub menu exist") {
                    this.state.messageDescription = "A Sub Menu with the same name already exist";
                    this.handleErrorDialog();
                } else if (response === "error") {
                    this.state.messageDescription = "Something went wrong adding the sub menu. Please try again!";
                    this.handleErrorDialog();
                } else if (response === "success"){
                    this.state.messageDescription = "SUB MENU ADDED SUCCESSFULLY";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = "Failed to upload the sub menu details!";
                    this.handleErrorDialog();
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });


    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/settings/submenu/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="basepage">
                <i className="fas fa-spinner fa-pulse fa-2x"/>
            </div>
        ) : (
            <div className="basepage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    />

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Sub Menu Added"
                        des={this.state.messageDescription}
                    />

                    <div className="row">
                        <div className="col-sm">
                            <AddSubMenuForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Add Sub Menu"
                                mainMenuList={this.state.mainMenuList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(AddSubMenu);
