import React, {Component, useEffect, useState} from "react";
import { connect } from "react-redux";
import {
    setpersonDetails
} from "../../../../actions/setpersondetailsaction";
import { hospitalCode, serverLink } from "../../../../resources/url";
import ConfirmDialogBox from "../../../DialogBoxes/confirmDialogbox";
import ErrorDialogBox from "../../../DialogBoxes/errordailogbox";
import "./packagesubscribers.css";
import Navigator from "../../../Settings/navigation/navigation";
import axios from "axios";
import AlertDialogBox from "../../../DialogBoxes/alertdialogbox";
import {moneyFormat} from "../../../../resources/constants";

function PackageSubscribers (props) {

    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [subscribersList, setSubscribersList] = useState([]);
    const [subscribersListSearch, setSubscribersListSearch] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openConfirmText, setOpenConfirmText] = useState('');

    const fetchSubscribers = async () => {
        await fetch(`${serverLink}hospital_package/subscription/all/${hospitalCode}`, props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                setSubscribersList(data)
                setSubscribersListSearch(data)
                setIsLoading(false);
            });
    }

    const handleSearch = async () => {
        if (searchText !== "") {
            const filtered = subscribersListSearch.filter(e => e.patientSerial.includes(searchText));
            if (filtered.length === 0) {
                setOpenErrorDialog(true)
                setSubscribersList(subscribersListSearch)
            } else {
                setSubscribersList(filtered)
            }
        }
    };

    const handleStatusChange = async (data) => {
        const formData = {
            _id: data._id,
            status: data.status === 'active' ? 'inactive' : 'active',
            updatedBy: props.doctorDetails.fullName
        }

        await axios.patch(`${serverLink}hospital_package/subscription/status`, formData, props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    setOpenConfirmDialog(true);
                    setOpenConfirmText('Status Updated Successfully');
                    fetchSubscribers()
                }
            })
            .catch(err => {
                console.log("ERR:", err)
            })
    }

    const closeErrorDialog = () => {
        setOpenErrorDialog(false)
    };

    const closeConfirmDialog = () => {
        setOpenConfirmDialog(false)
    };

    useEffect(() => {
        fetchSubscribers();
    }, []);

    let count = 0;
    return isLoading ? (
        <div className="baselistpage">
            <i className="fas fa-spinner fa-pulse fa-2x "></i>
        </div>
    ) : (
        <div className="baselistpage">
            <div className="main_section">
                <AlertDialogBox
                    openDailog={openConfirmDialog}
                    onSetOpenDailog={closeConfirmDialog}
                    title="SUCCESSFUL"
                    des={openConfirmText}
                ></AlertDialogBox>

                <ErrorDialogBox
                    openDialog={openErrorDialog}
                    onSetOpenDialog={closeErrorDialog}
                    title="Record Not Found"
                    des="Record not found. Please try again!"
                />

                <div className="topheader">
                    <Navigator title="Package Subscribers" />
                </div>
                <hr />
                <div className="top_section">
                    <div className="wrap">
                        <ul>
                            <li>
                                <div className="search">
                                    <input type="text" className="searchTerm" placeholder="Search package subscribers by patient ID"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearch();
                                            }
                                        }}
                                        onChange={(e) => { setSearchText(e.target.value) }}
                                    />

                                    <button
                                        onClick={handleSearch}
                                        type="submit"
                                        className="searchButton"
                                    >
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </li>
                            <li style={{ fontSize: "12px" }}> #</li>
                            <li style={{ fontSize: "12px" }}>
                                {subscribersList.length}{" "}
                            </li>
                        </ul>
                    </div>

                </div>

                <table className="table table-striped">
                    <thead className="thead tablehead">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Patient ID</th>
                        <th scope="col">Patient Name</th>
                        <th scope="col">Package Subscribed</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Option</th>
                    </tr>
                    </thead>
                    {isSearching ? (
                        <i className="fas fa-spinner fa-pulse fa-2x "></i>
                    ) : subscribersList.length === 0 ? (
                        <tbody>
                        <tr>
                            <td>No Package Found</td>
                        </tr>
                        </tbody>
                    ) : (
                        <tbody className="tablebody">
                        {subscribersList &&
                            subscribersList.map((p) => {
                                count++;
                                return (
                                    <tr key={p._id}>
                                        <td className="align-middle">{count}</td>
                                        <td className="align-middle">
                                            {p.patientSerial}
                                        </td>
                                        <td className="align-middle">
                                            {p.patientName}
                                        </td>
                                        <td className="align-middle">
                                            {p.packageName}
                                        </td>
                                        <td className="align-middle">
                                            {moneyFormat(p.amount)}
                                        </td>
                                        <td className="align-middle">
                                            {p.duration}
                                        </td>
                                        <td className="align-middle">
                                            <button
                                                onClick={async () => handleStatusChange(p)}
                                                type="button"
                                                className={`btn ${p.status === 'active' ? 'btn-primary' : 'btn-danger'}`}
                                            >
                                                <i className={`fa ${p.status !== 'active' ? 'fa-times' : 'fa-check'}`} title={`${p.status === 'active' ? 'Deactivate' : 'Activate'}`} aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageSubscribers);
