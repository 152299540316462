import React, { Component } from "react";
import "./rechargewallet.css";
import { connect } from "react-redux";
import {
    setpersonDetails,
} from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import {serverLink, hospitalCode} from "../../../resources/url";
import PatientListTable from "../utils/patientlisttable";
import {loginValidation} from "../../../context/loginvalidation";
import Navigator from "../../Settings/navigation/navigation";
import {Link} from "react-router-dom";
import AddAllergy from "../addpatientallergy/addpatientallergy";
import FormPrompt from "../../DialogBoxes/formprompt";
import axios from "axios";

class PatientCreditTopUp extends Component {
    constructor(props) {
        super(props);

        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
        this.state = {
            serverLink: serverLink+"patient/",
            hospitalCode: hospitalCode,
            searchText: "",
            isLoading: true,
            limit: 50,

            isLoadMoredata: false,

            totalNumOfPatient: null,
            noMoreDataText: "",

            openErrorDialog: false,

            patientlist: [],
            isSearching: false,
            isSearchDataShow: false,

            selectedPatientName: "",
            selectedPatientId: "",

            openRechargeModal: false,
            isCloseDialogAppear: true,
            selectedPatient: "",

            walletRecharge: {
                patientSerial: "",
                patientFullName: "",
                paymentMethod: "",
                paymentReference: "",
                paymentDate: "",
                paymentAmount: '',
                hospitalCode: hospitalCode,
                paymentStatus: "successful",
                paymentType: "Credit",
                paymentFor: "Wallet TopUp",
                submittedBy: this.props.doctorDetails.userID,
                submittedByName: this.props.doctorDetails.fullName
            },
            message: ""
        };
    }
    componentDidMount() {
        this.onSetTotalNumOfPatients();
    }

    async fetchPatient(limit) {
        this.setState({ isLoadMoredata: true });
        const fetchedDataList = await fetch(`${this.state.serverLink}all/${this.state.hospitalCode}/${limit}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    patientlist: data,
                    isLoadMoredata: false,
                    isLoading: false,
                });
            });
    }

    showMorePatient = () => {
        if (this.state.limit <= this.state.totalNumOfPatient) {
            const limit = this.state.limit + 10;
            this.setState({ limit: limit });
            this.fetchPatient(limit);
        } else {
            this.setState({
                noMoreDataText: "No More Patients",
            });
        }
    };

    async onSetTotalNumOfPatients() {
        this.setState({ isLoading: true });

        const fetchedDataList = await fetch(`${this.state.serverLink}count/${this.state.hospitalCode}`, this.props.doctorDetails.token)
            .then(res => res.json())
            .then(data => {
                this.setState({ totalNumOfPatient: data, isLoading: false });
            });


    }

    handleSearch = async () => {

        if (this.state.searchText !== "") {
            this.setState({
                isLoading: true,
            });
            const searchText = this.state.searchText.toLowerCase().replace(/\s/g, "");

            await fetch(`${this.state.serverLink}search/${this.state.hospitalCode}/${searchText}`, this.props.doctorDetails.token)
                .then(res => res.json())
                .then(data => {
                    if (data.length === 0) {
                        this.setState({
                            isSearching: false,
                            isLoading: false,
                            openErrorDialog: true,
                        });
                    } else {
                        this.setState({
                            patientlist: data,
                            isSearching: false,
                            isLoading: false,
                        });
                    }
                });

        }
    };

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    onRecharge = (e) => {
        this.setState({
            selectedPatient: e,
            openRechargeModal: true,
            message: ''
        })
    }

    onEdit = (e) => {
        this.setState({
            walletRecharge: {
                ...this.state.walletRecharge,
                [e.target.id]: e.target.value,
                patientSerial: this.state.selectedPatient.patientSerial,
                patientFullName: this.state.selectedPatient.firstName + " "+ this.state.selectedPatient.middleName + " " + this.state.selectedPatient.surname
            }
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            message: <div className="col-md-12 pt-2 pb-3"><span className="alert alert-info">Submitting. Please wait!</span></div>
        })

        await axios.post(`${serverLink}payment/staff/record/payment`, this.state.walletRecharge, this.props.doctorDetails.token)
            .then(res => {
                if (res.data.message === 'success') {
                    this.setState({
                        message: <div className="col-md-12 pt-2 pb-3"><span className="alert alert-success">Wallet TopUp Successful</span></div>
                    })
                } else {
                    this.setState({
                        message: <div className="col-md-12 pt-2 pb-3"><span className="alert alert-danger">Network error. Please try again!</span></div>
                    })
                }
            })
            .catch(err => {
                this.setState({
                    message: <div className="col-md-12 pt-2 pb-3"><span className="alert alert-danger">Network error. Please check your connection and try again!</span></div>
                })
            })

    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
            openRechargeModal: false,
            selectedPatient: "",
            message: "",
            walletRecharge: {
                patientSerial: "",
                patientFullName: "",
                paymentMethod: "",
                paymentReference: "",
                paymentDate: "",
                paymentAmount: '',
                hospitalCode: hospitalCode,
                paymentStatus: "successful",
                paymentType: "Credit",
                paymentFor: "Wallet TopUp",
                submittedBy: this.props.doctorDetails.userID,
                submittedByName: this.props.doctorDetails.fullName
            }
        });
        this.handleSearch();
    };

    render() {
        const current_date = `${new Date().getFullYear()}-${new Date().getMonth() < 10 ? '0'+new Date().getMonth() : new Date().getMonth()}-${new Date().getDate() < 10 ? '0'+new Date().getDate() : new Date().getDate()}`;
        let count = 0;
        return this.state.isLoading ? (
            <div className="patientlistpage">
                <i className="fas fa-spinner fa-pulse fa-2x "/>
            </div>
        ) : (
            <div className="patientlistpage">
                <div className="main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="Record Not Found"
                        des="Patient record not found. Please try again!"
                    />

                    <FormPrompt
                        openDialog={this.state.openRechargeModal}
                        title="TopUp Patient Wallet"
                        onSetOpenDialog={this.closeErrorDialog}
                        isCloseBtnAppear={this.state.isCloseDialogAppear}
                    >
                        <form onSubmit={this.handleSubmit}>
                           <div className="table-responsive">
                               <table className="table table-striped">
                                   <tbody>
                                   <tr>
                                       <th>Patient Number</th>
                                       <td>{this.state.selectedPatient.patientSerial}</td>
                                   </tr>
                                   <tr>
                                       <th>Patient Name</th>
                                       <td>{`${this.state.selectedPatient.firstName} ${this.state.selectedPatient.middleName} ${this.state.selectedPatient.surname}`}</td>
                                   </tr>
                                   <tr>
                                       <th>Wallet Balance</th>
                                       <td>{this.currencyConverter(this.state.selectedPatient.walletAmount)}</td>
                                   </tr>
                                   </tbody>
                               </table>
                           </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="paymentMethod">Select Payment Method</label>
                                    <select name="paymentMethod" id="paymentMethod" onChange={this.onEdit} value={this.state.walletRecharge.paymentMethod} className="form-control">
                                        <option value="">Select Option</option>
                                        <option value="POS">POS</option>
                                        <option value="Transfer">Transfer</option>
                                        <option value="Cash Deposit">Cash Deposit</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="paymentReference">Enter Payment Reference</label>
                                    <input type="text" id="paymentReference" onChange={this.onEdit} value={this.state.walletRecharge.paymentReference} name="paymentReference" className="form-control"/>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="paymentDate">Select Payment Date</label>
                                    <input type="date" id="paymentDate" max={current_date} onChange={this.onEdit} value={this.state.walletRecharge.paymentDate} name="paymentDate" className="form-control"/>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="paymentAmount">Enter Amount Paid</label>
                                    <input type="number" step="0.01" onChange={this.onEdit} id="paymentAmount" value={this.state.walletRecharge.paymentAmount} name="paymentAmount" className="form-control"/>
                                </div>
                            </div>


                            {this.state.message}

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                            </div>

                            <div className="col-md-12">
                                <div className="alert alert-danger">Note: Once submitted, the transaction cannot be reverted! </div>
                            </div>
                        </form>

                    </FormPrompt>

                    <div className="topheader">
                        <Navigator title="Wallet TopUp" />
                    </div>
                    <hr />
                    <div className="top_section">
                        <div className="wrap">
                            <ul>
                                <li>
                                    <div className="search">
                                        <input
                                            type="text"
                                            className="searchTerm"
                                            placeholder="Search patient by first, middle, surname, email, phone number or patient ID"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSearch();
                                                }
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchText: e.target.value,
                                                });
                                            }}
                                        />

                                        <button
                                            onClick={this.handleSearch}
                                            type="submit"
                                            className="searchButton"
                                        >
                                            <i className="fa fa-search"/>
                                        </button>
                                    </div>
                                </li>
                                <li style={{ fontSize: "12px" }}> #</li>
                                <li style={{ fontSize: "12px" }}>
                                    {this.state.patientlist.length}{" "}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <table className="table table-striped">
                        <thead className="thead tablehead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Profile</th>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Sex</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email</th>
                            <th scope="col">Wallet</th>
                            <th scope="col">Recharge</th>
                        </tr>
                        </thead>
                        {this.state.isSearching ? (
                            <i className="fas fa-spinner fa-pulse fa-2x "/>
                        ) : this.state.patientlist.length === 0 ? (
                            <tbody>
                            <tr>
                                <td>No Patient Found</td>
                            </tr>
                            </tbody>
                        ) : (
                            <tbody className="tablebody">
                            {this.state.patientlist &&
                                this.state.patientlist.map((p) => {
                                    count++;

                                    let dateOfBirthDate = new Date(p.dateOfBirth);
                                    const dob = `${dateOfBirthDate.getDate()}/${
                                        dateOfBirthDate.getMonth() + 1
                                    }/${dateOfBirthDate.getFullYear()}`;

                                    return (

                                        <tr key={p._id}>
                                            <td className="align-middle">{count}</td>
                                            <td className="align-middle">
                                                {p.passport === "" ? (
                                                    <div className="userbg">
                                                        <i className="fa fa-user fa-2x"/>
                                                    </div>
                                                ) : (
                                                    <img className="image" alt="" srcSet={`${serverLink}public/uploads/patient_passport/${p.passport}`} />
                                                )}
                                            </td>
                                            <td className="align-middle">
                                                {p.patientSerial}
                                            </td>
                                            <td className="align-middle">
                                                {p.firstName + " "+ p.middleName + " " + p.surname}
                                            </td>
                                            <td className="align-middle">{p.gender}</td>
                                            <td className="align-middle">
                                                {" "}
                                                {p.phoneNumber === "" ? "N/A" : p.phoneNumber}
                                            </td>
                                            {<td className="align-middle">
                                                {" "}
                                                {p.emailAddress === "" ? "N/A" : p.emailAddress}
                                            </td>}
                                            {<td className="align-middle">
                                                {" "}
                                                {p.walletAmount === "" ? "N/A" : this.currencyConverter(p.walletAmount)}
                                            </td>}

                                            <td className="align-middle">
                                                <button
                                                    onClick={() => this.onRecharge(p)}
                                                    type="button"
                                                    className="btn btn-success"
                                                >
                                                    <i className="fa fa-money" aria-hidden="true"/>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>


                    <div className="loadmoredatasection">
                        {this.state.isLoadMoredata ? (
                            <i className="fas fa-spinner fa-pulse fa-2x loadmoredataspinner"/>
                        ) : (
                            <div className="nomoredatatext">{this.state.noMoreDataText}</div>
                        )}
                        {this.state.patientlist.length === 0 ? null : this.state
                            .isSearchDataShow ? null : (
                            <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.showMorePatient()}
                            >
                                Show More
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnPatientDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientCreditTopUp);
