import React, { useState, useEffect } from "react";
import "./doctordashboard.css";
import { serverLink, hospitalCode } from "../../../resources/url";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import axios from "axios";
import { setpersonDetails } from "../../../actions/setpersondetailsaction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loginValidation } from "../../../context/loginvalidation";
import MenuIcon from "../../Dashboard/utils/menu-icon";
import {FaCalendarCheck, FaCalendarPlus, FaHospitalUser, FaUserInjured} from "react-icons/fa";
import Chart from "react-google-charts";

const DoctorDashboard = ({ permissionData, doctorDetails, setOnUserDetails }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [doctorData, setDoctorData] = useState(null);
    const [doctorID] = useState(localStorage.getItem('userID'));
    const [appointmentStatus, setAppointmentStatus] = useState([])
    const [admissionStatus, setAdmissionStatus] = useState([])
    const [imagingStatus, setImagingStatus] = useState([])
    const [labStatus, setLabStatus] = useState([])
    const [inpatient, setInpatient] = useState(0)
    const [completedAppointment, setCompletedAppointment] = useState(0)
    const [upcomingAppointment, setUpcomingAppointment] = useState(0)
    const [referrals, setReferrals] = useState(0)

    useEffect(() => {

        if (loginValidation(window.location.pathname, permissionData) !== 'permitted') {
            window.location.href = '/';
        }
        const fetchData = async () => {
            try {
                const response = await axios.get(`${serverLink}doctor/profile/${hospitalCode}/${doctorID}`, doctorDetails.token);
                const data = response.data;
                if (data.length === 0 || data.role !== 'Doctor') {
                    // window.location.href = '/';
                }

                const dashboard_response = await axios.get(`${serverLink}report/doctor/dashboard/${doctorID}/${hospitalCode}`, doctorDetails.token);
                const appointment = dashboard_response.data.appointments;
                const admission = dashboard_response.data.admission;
                const lab = dashboard_response.data.lab;
                const imaging = dashboard_response.data.imaging;
                const prescription = dashboard_response.data.prescription;
                let appointment_status = ['Scheduled', 'Checked In', 'Vital Taken', 'Completed', 'Missed'];
                let admission_status = ['Active', 'Discharged'];
                let imaging_status = ['Pending', 'Result Entered', 'Cancelled'];
                let lab_status = ['Requested', 'Specimen Taken', 'Result Confirmed', 'Result Entered', 'Result Rejected', 'Cancelled'];

                //Set Count
                setInpatient(admission.filter(item => item.status === 'active').length)
                setCompletedAppointment(appointment.filter(item => item.appointmentStatus === 'Completed').length)
                setUpcomingAppointment(appointment.filter(item => item.appointmentStatus === 'Scheduled' || item.appointmentStatus === 'Checked In' || item.appointmentStatus === 'Vital Taken').length)

                //Initialize Data
                let appointmentStatusData = [];
                let admissionStatusData = [];
                let imagingStatusData = [];
                let labStatusData = [];

                //Appointment trend by status
                appointment_status.map((e, i) => {
                    let count = appointment.filter(item => item.appointmentStatus === e).length
                    appointmentStatusData.push([e, count]);
                })

                //Admission trend by status
                admission_status.map((e, i) => {
                    let count = admission.filter(item => item.status === e.toLowerCase()).length
                    admissionStatusData.push([e, count]);
                })

                //Imaging trend by status
                imaging_status.map((e, i) => {
                    let count = imaging.filter(item => item.status === e.toLowerCase()).length
                    imagingStatusData.push([e, count]);
                })

                //Lab trend by status
                lab_status.map((e, i) => {
                    let count = lab.filter(item => item.testStatus === e.toLowerCase()).length
                    labStatusData.push([e, count]);
                })


                //Set Chart Data
                setAppointmentStatus(appointmentStatusData)
                setAdmissionStatus(admissionStatusData)
                setImagingStatus(imagingStatusData)
                setLabStatus(labStatusData)

                setDoctorData(data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const appointmentAnalyticsData = [["Status", "Count"], ...appointmentStatus];
    const appointmentAnalyticsOptions = {
        title: "Appointment Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const admissionAnalyticsData = [["Status", "Count"], ...admissionStatus];
    const admissionAnalyticsOptions = {
        title: "Admission Trend By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const imagingAnalyticsData = [["Status", "Count"], ...imagingStatus];
    const imagingAnalyticsOptions = {
        title: "Imaging Request By Status",
        pieHole: 0.3,
        is3D: false,
    };

    const labAnalyticsData = [["Status", "Count"], ...labStatus];
    const labAnalyticsOptions = {
        title: "Lab Request By Status",
        pieHole: 0.3,
        is3D: false,
    };

    return isLoading ? (
        <div className="dashboardpage">
            <i className="fas fa-spinner fa-pulse fa-2x " />
        </div>
    ) : (
        <div className="dashboardpage">
            <div className="topheader">
                <ul>
                    <li>
                        <i
                            className="fa fa-user-md fa-2x"
                            aria-hidden="true"
                        />
                    </li>
                    <li>
                        <span>{doctorData.title} {doctorData.firstName}  {doctorData.middleName}  {doctorData.surname}'s Dashboard</span>
                    </li>
                </ul>
            </div>

            <div className="first_section" style={{marginTop: '-30px'}}>
                <div className="row">
                    <div className="col-md-3">
                            <MenuIcon name="Inpatients on Treatment" icon={<FaHospitalUser size={40}/>} count={inpatient}/>
                    </div>
                    <div className="col-md-3">
                            <MenuIcon name="Completed Appointments" icon={<FaCalendarCheck size={40}/>} count={completedAppointment}/>
                    </div>
                    <div className="col-md-3">
                            <MenuIcon name="Upcoming Appointments" icon={<FaCalendarPlus size={40}/>} count={upcomingAppointment}/>
                    </div>
                    <div className="col-md-3">
                            <MenuIcon name="Referrals" icon={<FaUserInjured size={40}/>} count={referrals}/>
                    </div>

                    {/*Chart Section*/}
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    appointmentStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="250px"
                                        data={appointmentAnalyticsData}
                                        options={appointmentAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3 mt-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    admissionStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="250px"
                                        data={admissionAnalyticsData}
                                        options={admissionAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    imagingStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="250px"
                                        data={imagingAnalyticsData}
                                        options={imagingAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="card" style={{borderRadius: '10px'}}>
                            <div className="card-body">
                                {
                                    labStatus.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="250px"
                                        data={labAnalyticsData}
                                        options={labAnalyticsOptions}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="col-md-12 mt-3 mb-0" style={{borderBottom: '1px solid #CCCCCC', borderTop: '1px solid #CCCCCC', padding: '5px'}}>
                            <h2><b>Menu</b></h2>
                        </div>
                    </div>

                    {permissionData &&
                        permissionData.map((perm, index) => {
                            if (perm.mainMenuName === 'Doctor' && perm.subMenuName !== null) {
                                return (
                                    <div key={index} className="col-sm-4">
                                        {" "}
                                        <Link
                                            to={perm.subMenuLink}
                                            onClick={async () => {
                                                const sendData = { ...doctorData };
                                                setOnUserDetails(sendData);
                                            }}
                                        >
                                            <DashboardCard name={perm.subMenuName} icon={`fa ${perm.subMenuIcon} fa-4x`} count="" />
                                        </Link>
                                    </div>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails,
        doctorDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnUserDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorDashboard);
