import React, { Component } from "react";
import "./departmentform.css";
import Navigator from "../../Settings/navigation/navigation";

class DepartmentForm extends Component {
    render() {
        return (
            <div className="departmentformpage">
                <div className="container main_section">
                    <div className="topheader">
                        <Navigator title={this.props.title} />
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.props.handleSubmit}>
                                <div className="first_section">
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="departmentName">Department Name <span className="text-danger">*</span></label>
                                            <input
                                                name="departmentName"
                                                type="text"
                                                className="form-control"
                                                id="departmentName"
                                                value={this.props.data.departmentName}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="headOfDepartmentID">Head of Department <span className="text-danger">*</span></label>
                                            <select
                                                name="headOfDepartmentID"
                                                id="headOfDepartmentID"
                                                className="custom-select"
                                                value={this.props.data.headOfDepartmentID}
                                                onChange={this.props.onEdit}
                                            >
                                                <option value="">Select Department Head</option>
                                                {
                                                    this.props.userList.map((staff, index) => {
                                                        return <option key={index} value={staff.userID}>
                                                            {staff.title} {staff.firstName} {staff.middleName} {staff.surname}
                                                        </option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-row">

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="emailAddress">Department Email Address </label>
                                            <input
                                                name="emailAddress"
                                                type="email"
                                                className="form-control"
                                                id="emailAddress"
                                                value={this.props.data.emailAddress}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="phoneNumber">Department Phone Number </label>
                                            <input
                                                name="phoneNumber"
                                                type="tel"
                                                className="form-control"
                                                id="phoneNumber"
                                                value={this.props.data.phoneNumber}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="extensionNo">Department Extension Number </label>
                                            <input
                                                name="extensionNo"
                                                type="number"
                                                className="form-control"
                                                id="extensionNo"
                                                value={this.props.data.extensionNo}
                                                onChange={this.props.onEdit}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="departmentDescription">Department Description </label>
                                            <textarea
                                                name="departmentDescription"
                                                className="form-control"
                                                id="departmentDescription"
                                                defaultValue={this.props.data.departmentDescription}
                                                onChange={this.props.onEdit}
                                                rows="3"
                                            ></textarea>
                                        </div>
                                    </div>

                                </div>

                                <button className="btn btn-success btn-block savebtn" type="submit">
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DepartmentForm;
