import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { setpersonDetails } from "../../../actions/setpersondetailsaction";
import ErrorDialogBox from "../../DialogBoxes/errordailogbox";
import axios from "axios";
import AlertDialogBox from "../../DialogBoxes/alertdialogbox";
import {connect} from "react-redux";
import DepartmentForm from "../departmentform/departmentform";
import { serverLink, hospitalCode } from "../../../resources/url";


class UpdateDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink+"department/",
            hospitalCode: hospitalCode,
            isLoading: false,

            departmentEntryID: this.props.departmentDetails._id,

            formData: {
                departmentName: this.props.departmentDetails.departmentName,
                headOfDepartmentID: this.props.departmentDetails.headOfDepartmentID,
                headOfDepartmentName: this.props.departmentDetails.headOfDepartmentName,
                emailAddress: this.props.departmentDetails.emailAddress,
                phoneNumber: this.props.departmentDetails.phoneNumber,
                extensionNo: this.props.departmentDetails.extensionNo,
                departmentDescription: this.props.departmentDetails.departmentDescription,
            },
            userList: [],

            messageTitle: "",
            messageDescription: "",

            openAlertDialog: false,
            openErrorDialog: false,
            openConfirmDialog: false,
        };
    }

    componentDidMount() {
        this.onGetStaffList();
    }

    onGetStaffList () {
        axios.get(`${serverLink}user/all/${this.state.hospitalCode}/1000`, this.props.doctorDetails.token)
            .then(staff => {
                this.setState({
                    userList: staff.data
                })
            })
            .catch(error => {
                console.log(error)

            })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        this.onUpdateDepartment();
    };

    async onUpdateDepartment() {
        let sendData = {
            ...this.state.formData,
            hospitalCode: this.state.hospitalCode,
            id: this.state.departmentEntryID
        };

        if(this.state.formData.departmentName === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Enter the Department Name"
            this.handleErrorDialog();
            return false;
        }

        if(this.state.formData.headOfDepartmentID === "") {
            this.setState({
                isLoading: false,
            });
            this.state.messageDescription = "Please Select the Department Head"
            this.handleErrorDialog();
            return false;
        }

        axios.patch(`${this.state.serverLink}update`, sendData, this.props.doctorDetails.token)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.state.messageDescription = "Department record updated successfully";
                    this.handleSuccessDialog();
                } else {
                    this.state.messageDescription = response;
                    this.handleErrorDialog();
                }
            })
            .catch(err => {

                this.setState({
                    isLoading: false,
                });
                this.state.messageDescription = err;
                this.handleErrorDialog();
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        if (e.target.name === 'headOfDepartmentID') {
            let index = e.nativeEvent.target.selectedIndex;
            this.state.formData.headOfDepartmentName = e.nativeEvent.target[index].text
        }
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleErrorDialog = () => {
        this.setState({
            openConfirmDialog: false,
            openErrorDialog: true,
        });
    };
    handleSuccessDialog = () => {
        this.setState({
            openAlertDialog: true,
        });
    };
    closeAlertDialog= () => {
        this.setState({
            openAlertDialog: false,
        });
        window.location.href = '/department/list';
    };
    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false,
        });
    };
    closeConfirmDialog = () => {
        this.setState({
            openConfirmDialog: false,
        });
    };

    render() {
        return this.state.isLoading ? (
            <div className="updatedepartmentpage">
                <i className="fas fa-spinner fa-pulse fa-2x"></i>
            </div>
        ) : (
            <div className="updatedepartmentpage">
                <div className="container main_section">

                    <ErrorDialogBox
                        openDialog={this.state.openErrorDialog}
                        onSetOpenDialog={this.closeErrorDialog}
                        title="ERROR"
                        des={this.state.messageDescription}
                    ></ErrorDialogBox>

                    <AlertDialogBox
                        openDailog={this.state.openAlertDialog}
                        onSetOpenDailog={this.closeAlertDialog}
                        title="Department Updated"
                        des={this.state.messageDescription}
                    ></AlertDialogBox>

                    <div className="row">
                        <div className="col-sm">
                            <DepartmentForm
                                handleSubmit={this.handleSubmit}
                                onEdit={this.onEdit}
                                data={this.state.formData}
                                title="Update Patient"
                                userList={this.state.userList}
                            ></DepartmentForm>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        departmentDetails: state.personDetails,
        doctorDetails: state.loginDetails,
    };
};
const mapDisptachToProps = (dispatch) => {
    return {
        setOnpersonDetails: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDisptachToProps)(UpdateDepartment);
