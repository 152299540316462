import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardCard from "../../Dashboard/utils/dashboardcard";
import "./permissiondashboard.css";
import {loginValidation} from "../../../context/loginvalidation";
import {connect} from "react-redux";
import Navigator from "../navigation/navigation";

class PermissionDashboard extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
    }

    render() {
        return (
            <div className="dashboardpage">
                <div className="topheader">
                    <Navigator title={"Permission Dashboard"} />
                </div>


                <div className="first_section">
                    <div className="row">
                        <div className="col-sm-4">
                            {" "}
                            <Link to="/settings/menu/list">
                                <DashboardCard name="Menu" icon="fa fa-bars fa-4x" count="" />
                            </Link>
                        </div>
                        <div className="col-sm-4">
                            {" "}
                            <Link to="/settings/submenu/list">
                                <DashboardCard name="Sub Menu" icon="fa fa-list fa-4x" count="" />
                            </Link>
                        </div>

                        <div className="col-sm-4">
                            {" "}
                            <Link to="/settings/group/list">
                                <DashboardCard name="Group" icon="fa fa-users fa-4x" count="" />
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionDetails
    };
};
export default connect(mapStateToProps, null)(PermissionDashboard);
