import React, {Component} from "react";
import ConfirmDialogBox from "../../DialogBoxes/confirmDialogbox";
import axios from "axios";
import { serverLink, hospitalCode} from "../../../resources/url";
import {connect} from "react-redux";

class PatientMedicalRecordList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverLink: serverLink,
            openAreYouSure: false,
            imagingMessage: "",
            imagingID: ""
        }
    }

    DeleteImaging = (e) => {
        this.setState({
            imagingMessage: "Are you sure you want delete this record?",
            openAreYouSure: true,
            imagingID: e._id
        })
    }

    handleDelete = () => {
        axios.delete(`${this.state.serverLink}patient/previous_medical_record/delete/${this.state.imagingID}`, this.props.doctorDetails.token)
            .then(result => {
                if(result.data.deletedCount === 1) {
                    this.setState({
                        openAreYouSure: false
                    })
                    window.location.reload();
                }
            })
    }

    closeDeleteDialog = () => {
        this.setState({
            openAreYouSure: false,
        });
    };

    render() {
        let count = 0;
        return <div className="card mb-3">
            <div className="card-header">
                Previous Hospital Record
            </div>
            <ConfirmDialogBox
                openDailog={this.state.openAreYouSure}
                onSetOpenDialog={this.closeDeleteDialog}
                title="Are you sure"
                des={this.state.imagingMessage}
                handleConfirmOkBtn={this.handleDelete}
            >

            </ConfirmDialogBox>
            <div className="card-body">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Date Added</th>
                        <th>File</th>
                        <th>--</th>
                    </tr>
                    </thead>
                    { this.props.medicalRecordList.length === 0 ?
                        (
                            <tbody>
                            <tr><td colSpan={6} className="text-center">No Medical Record Found</td></tr>
                            </tbody>
                        )
                        :
                        (
                            <tbody>
                            {this.props.medicalRecordList.map((r) => {
                                count++;
                                return (
                                    <tr key={r._id}>
                                        <td>{count}</td>
                                        <td>{r.title}</td>
                                        <td>{r.description}</td>
                                        <td>{this.props.formatDate(r.submittedOn)}</td>
                                        <td className="align-middle">
                                            {r.file === "" ? (
                                                <div className="userbg">
                                                    <i className="fa fa-file-excel fa-2x"/>
                                                </div>
                                            ) : ( r.file ?
                                                    <a className="btn btn-primary btn-sm" href={r.file ? r.file.includes("simplefileupload") ? r.file : serverLink + 'public/uploads/patient_previous_medical_record/'+r.file : ""}>
                                                    View CFile
                                                </a>
                                                    : "--"
                                            )}
                                        </td>
                                        <td><i onClick={() => this.DeleteImaging(r)} className="fa fa-trash text-danger"/></td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        )
                    }
                </table>
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        doctorDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(PatientMedicalRecordList);
