import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setpersonDetails } from "../../../../actions/setpersondetailsaction";
import DashboardCard from "../../../Dashboard/utils/dashboardcard";
import "./dashboard.css";
import {loginValidation} from "../../../../context/loginvalidation";

class InsuranceDashboard extends Component {
    constructor(props) {
        super(props);
        if (loginValidation(window.location.pathname, this.props.permissionData) !== 'permitted')
            window.location.href = '/';
    }

    render() {
        return (
            <div className="dashboardpage">
                <div className="topheader">
                    <ul>
                        <li>
                            <i
                                className="fa fa-shield fa-2x"
                                aria-hidden="true"
                            />
                        </li>
                        <li>
                            <span>Insurance Dashboard</span>
                        </li>
                    </ul>
                </div>


                <div className="first_section">
                    <div className="row">
                        <div className="col-sm">
                            {" "}
                            <Link to="/hospital/insurance/company/list">
                                <DashboardCard name="Insurance Companies" icon="fa fa-building-o fa-4x" count="" />
                            </Link>
                        </div>

                        <div className="col-sm">
                            {" "}
                            <Link to="/hospital/insurance/plans/list">
                                <DashboardCard name="Insurance Plans" icon="fa fa-tasks fa-4x" count="" />
                            </Link>
                        </div>
                        <div className="col-sm">
                            {" "}
                            <Link to="/hospital/insurance/addpatients/list">
                                <DashboardCard name="Subscribe Patients" icon="fa fa-file-medical fa-4x" count="" />
                            </Link>
                        </div>
                        <div className="col-sm">
                            {" "}
                            <Link to="/hospital/insurance/claims/list">
                                <DashboardCard name="Subscribed Patients" icon="fa fa-exchange fa-4x" count="" />
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnUpdateHospital: (p) => {
            dispatch(setpersonDetails(p));
        },
    };
};

export default connect(null, mapDispatchToProps)(InsuranceDashboard);
