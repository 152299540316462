import React, {useState} from "react";
import randomToken from "random-token";
import SpecimenItem from "../specimenitem/specimenitem";


function SpecimenSection (props) {
    const specimen_item_id = props.id;
    const current_specimen = props.specimen_data.specimen.filter(item => item.specimen_id === specimen_item_id);
    const [specimenItem, setSpecimenItem] = useState([current_specimen[0].items[0].item_id]);

    const addSpecimenItem = () => {
        const specimen_item_id = props.id;
        const initial_item_id = randomToken(10);
        setSpecimenItem([...(specimenItem), initial_item_id]);
        let active_specimen = props.specimen_data.specimen.filter(item => item.specimen_id === specimen_item_id);
        let specimen_items = active_specimen[0].items;
        specimen_items.push({item_id: initial_item_id, item_name: '', si_unit: '', ref_interval: 'single', ref_data: []});
    }

    const removeSpecimenItem = (e) => {
        const item_id = e.target.getAttribute('data');
        const new_items = specimenItem.filter(item => item !== item_id);
        setSpecimenItem(new_items);

        const specimen_item_id = props.id;
        let active_specimen = props.specimen_data.specimen.filter(item => item.specimen_id === specimen_item_id);
        let specimen_items = active_specimen[0].items;

        for (let i = 0; i < specimen_items.length; i++) {
            let obj = specimen_items[i];
            if (obj.item_id === item_id)
                specimen_items.splice(i, 1)
        }
    }

    return <>
        <hr/>

        <div className="form-row mb-3">
            <div className="col-md-12">
                <h4 className="text-center">Specimen {props.index}
                    {
                        props.index > 1 && <i className="fa fa-trash float-right text-danger" onClick={props.remove} id={props.id}/>
                    }

                </h4>
            </div>
        </div>

        <div className="form-row mb-3">
            <div className="col-md-4">
                <label htmlFor="specimenName">Specimen Name <span className="text-danger">*</span></label>
                <select name="specimenName" className="form-control"defaultValue={typeof current_specimen[0].specimenName !== 'undefined' ? current_specimen[0].specimenName : ''} onChange={props.onEdit} required="required">
                    <option value="" data={props.id}>Select Specimen</option>
                    {
                        props.specimen_list.map((spec, index) => {
                            return <option key={index} data={props.id} value={spec.specimenName}>{spec.specimenName}</option>
                        })
                    }

                </select>
            </div>

            <div className="col-md-4">
                <label htmlFor="testAmount">Test Amount <span className="text-danger">*</span></label>
                <input
                    name="testAmount"
                    type="number"
                    required="required"
                    defaultValue={typeof current_specimen[0].testAmount !== 'undefined' ? current_specimen[0].testAmount : ''}
                    data={props.id}
                    className="form-control"
                    placeholder="10000"
                    onChange={props.onEdit}
                />
            </div>

            <div className="col-md-4">
                <label htmlFor="specimenBottle">Specimen Bottle <span className="text-danger">*</span></label>
                <select name="specimenBottle" className="form-control" required="required" defaultValue={typeof current_specimen[0].specimenBottle !== 'undefined' ? current_specimen[0].specimenBottle : ''} onChange={props.onEdit}>
                    <option value="" data={props.id}>Select Bottle</option>
                    {
                        props.bottle_list.length > 0 &&
                        props.bottle_list.map((bottle, index) => {
                            return <option key={index} data={props.id} value={bottle.bottleName}>{bottle.bottleName}</option>
                        })
                    }
                </select>
            </div>

        </div>

        <div className="form-row mb-3">

            <div className="col-md-4">
                <label htmlFor="specimenBottleVolume">Specimen Bottle Volume <span className="text-danger">*</span></label>
                <select name="specimenBottleVolume" required="required" defaultValue={typeof current_specimen[0].specimenBottleVolume !== 'undefined' ? current_specimen[0].specimenBottleVolume : ''} className="form-control" onChange={props.onEdit}>
                    <option value="" data={props.id}>Select Bottle Volume</option>
                    {
                        props.volume_list.length > 0 &&
                        props.volume_list.map((vol, index) => {
                            return <option key={index} data={props.id} value={vol.volumeName}>{vol.volumeName}</option>
                        })
                    }
                </select>
            </div>

            <div className="col-md-4">
                <label htmlFor="specimenTurnAroundTime">Turn Around Time <span className="text-danger">*</span></label>
                <select name="specimenTurnAroundTime" required="required" defaultValue={typeof current_specimen[0].specimenTurnAroundTime !== 'undefined' ? current_specimen[0].specimenTurnAroundTime : ''} className="form-control" onChange={props.onEdit}>
                    <option value="" data={props.id}>Select Turn Around Time</option>
                    <option value="30 mins" data={props.id}>30 mins</option>
                    <option value="1 hr" data={props.id}>1 hr</option>
                    <option value="2 hrs" data={props.id}>2 hrs</option>
                    <option value="3 hrs" data={props.id}>3 hrs</option>
                    <option value="4 hrs" data={props.id}>4 hrs</option>
                    <option value="5 hrs" data={props.id}>5 hrs</option>
                    <option value="6 hrs" data={props.id}>6 hrs</option>
                    <option value="7 hrs" data={props.id}>7 hrs</option>
                    <option value="8 hrs" data={props.id}>8 hrs</option>
                    <option value="9 hrs" data={props.id}>9 hrs</option>
                    <option value="10 hrs" data={props.id}>10 hrs</option>
                    <option value="11 hrs" data={props.id}>11 hrs</option>
                    <option value="12 hrs" data={props.id}>12 hrs</option>
                    <option value="24 hrs" data={props.id}>24 hrs</option>
                    <option value="36 hrs" data={props.id}>36 hrs</option>
                    <option value="48 hrs" data={props.id}>48 hrs</option>
                    <option value="5 Days" data={props.id}>5 Days</option>
                    <option value="7 Days" data={props.id}>7 Days</option>
                    <option value="10 Days" data={props.id}>10 Days</option>
                    <option value="14 Days" data={props.id}>14 Days</option>
                    <option value="3 Weeks" data={props.id}>3 Weeks</option>
                    <option value="4 Weeks" data={props.id}>4 Weeks</option>
                    <option value="5 Weeks" data={props.id}>5 Weeks</option>
                    <option value="6 Weeks" data={props.id}>6 Weeks</option>
                    <option value="7 Weeks" data={props.id}>7 Weeks</option>
                    <option value="8 Weeks" data={props.id}>8 Weeks</option>

                </select>

            </div>

            <div className="col-md-4">
                <label htmlFor="specimenPatientPreparation"> Patient Preparation</label>
                <input
                    name="specimenPatientPreparation"
                    type="text"
                    data={props.id}
                    defaultValue={typeof current_specimen[0].specimenPatientPreparation !== 'undefined' ? current_specimen[0].specimenPatientPreparation : ''}
                    className="form-control"
                    placeholder="Overnight fasting period of 10-12hrs"
                    onChange={props.onEdit}
                />
            </div>

        </div>

        <div id="specimen-container">
            {
                typeof current_specimen[0].specimenName !== 'undefined' ?
                    current_specimen[0].items.map((item, index) => {
                        return <SpecimenItem index={(index+1)} data={item} specimen_id={props.id} item_id={item.item_id} key={index} remove={removeSpecimenItem} onEdit={props.onEditItem} />
                    })
                    :
                specimenItem.map((item, index) => {
                    return <SpecimenItem index={(index+1)} data={""} specimen_id={props.id} item_id={item} key={index} remove={removeSpecimenItem} onEdit={props.onEditItem} />
                })
            }
        </div>

        <div className="form-row mb-5 mt-5" id="specimen-container">
            <div className="col-md-12">
                <hr/>
                <button className="btn btn-primary btn-sm" onClick={addSpecimenItem}>Add Item</button>
                <hr/>
            </div>
        </div>
    </>
}

export default SpecimenSection;
